import { Card, CardHeader, CardPreview, CompoundButton } from "@fluentui/react-components";
import React from "react";
import "./welcome.page.scss";
import CopilotExampleImage from "../../assets/welcome/Copilot_example.png";
import DoEExampleImage from "../../assets/welcome/DoE_example.png";
import MSSExampleImage from "../../assets/welcome/MSS_example.png";
import WebFormExampleImage from "../../assets/welcome/Web_Form_example.png";
import { getDashboardSharingClient } from "../../services/dashboard.service";
import { ShareDefinitionSourceType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { ExperimentDetailsModal } from "../../ai-for-pfd/experiment-details-modal/experiment-details-modal.component";
import { CommingSoonModal } from "../../components/comming-soon-modal/comming-soon.modal";
import { FolderRegular, PeopleTeamRegular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { WebFormExampleModal } from "./webform-example.modal";
import { generalSettings } from "../../App";
import { ScenarioDetailsModal } from "../../excel-runner/all-user-scenarios/scenario-details-modal/scenario-details-modal.component";
import { HubConnection } from "@microsoft/signalr";
import { StudyDetailsModal } from "../../sensitivity-studies/all-user-studies/study-details-modal/study-details-modal.component";
import { useMsal } from "@azure/msal-react";

type WelcomePageProps = {
    hubConnection: HubConnection;
}

const DwimProSamplesUrls = {
    staging: "/files/examples/6e3bbda5-f1b1-42d9-8a19-aa0c08bac05f",
    production: "/files/examples/41c19907-a8e7-4e1e-89a0-db14f1b336ff"
}


export const WelcomePage: React.FC<WelcomePageProps> = (props) => {
    const { instance } = useMsal();

    const [showCopilotExampleModal, setShowCopilotExampleModal] = React.useState<boolean>(false);
    const [copilotExampleId, setCopilotExampleId] = React.useState<number>();

    const [showDoEExampleModal, setShowDoEExampleModal] = React.useState<boolean>(false);
    const [doeExampleId, setDoeExampleId] = React.useState<number>();
    const [showMssExampleModal, setShowMssExampleModal] = React.useState<boolean>(false);
    const [mssExampleId, setMssExampleId] = React.useState<number>();

    const [showWebFormExampleModal, setShowWebFormExampleModal] = React.useState<boolean>(false);

    const [showCommingSoonModal, setShowCommingSoonModal] = React.useState<boolean>(false);
    const [commingSoonTitle, setCommingSoonTitle] = React.useState<string>();
    const [commingSoonImage, setCommingSoonImage] = React.useState<any>();

    const [isLoading, loadingService] = useLoading();
    const [userLoggedIn, setUserLoggedIn] = React.useState<boolean>(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        const accounts = instance.getAllAccounts();
        setUserLoggedIn(accounts?.length > 0 ?? false);
    }, []);
    React.useEffect(() => {
        if(userLoggedIn){
            getCopilotExperiment();
            getDoEExperiment();
            getMssExperiment();
        }
        
    }, [userLoggedIn]);

    const getMssExperiment = () => {
        loadingService.showLoading("Loading examples...", async (hideMessage) => {
            try {
                const client = getDashboardSharingClient();

                const sharedDefinitions = await client.getPublicExamples(ShareDefinitionSourceType.MssStudy);
                const sharedDefinitionSourceIds = sharedDefinitions?.map(x => +x.sourceId) ?? [];
                if (!!generalSettings.mssExampleId) {
                    const exists = sharedDefinitionSourceIds.find(x => x === generalSettings.mssExampleId);
                    if (!!exists) {
                        setMssExampleId(generalSettings.mssExampleId);
                        return;
                    }
                }
                setMssExampleId(sharedDefinitionSourceIds?.[0] ?? undefined);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting MSS example study.`);
            } finally {
                hideMessage();
            }
        });
    }

    const getCopilotExperiment = () => {
        loadingService.showLoading("Loading examples...", async (hideMessage) => {
            try {
                const client = getDashboardSharingClient();

                const sharedDefinitions = await client.getPublicExamples(ShareDefinitionSourceType.CopilotExperiment);
                const sharedDefinitionSourceIds = sharedDefinitions?.map(x => +x.sourceId) ?? [];
                if (!!generalSettings.copilotExampleId) {
                    const exists = sharedDefinitionSourceIds.find(x => x === generalSettings.copilotExampleId);
                    if (!!exists) {
                        setCopilotExampleId(generalSettings.copilotExampleId);
                        return;
                    }
                }
                setCopilotExampleId(sharedDefinitionSourceIds?.[0] ?? undefined);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting copilot example experiment.`);
            } finally {
                hideMessage();
            }
        });
    }
    const getDoEExperiment = () => {
        loadingService.showLoading("Loading examples...", async (hideMessage) => {
            try {
                const client = getDashboardSharingClient();

                const sharedDefinitions = await client.getPublicExamples(ShareDefinitionSourceType.DoEExperiment);
                const sharedDefinitionSourceIds = sharedDefinitions?.map(x => +x.sourceId) ?? [];
                if (!!generalSettings.doeExampleId) {
                    const exists = sharedDefinitionSourceIds.find(x => x === generalSettings.doeExampleId);
                    if (!!exists) {
                        setDoeExampleId(generalSettings.doeExampleId);
                        return;
                    }
                }
                setDoeExampleId(sharedDefinitionSourceIds?.[0] ?? undefined);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting DoE example experiment.`);
            } finally {
                hideMessage();
            }
        });
    }

    const onCommingSoonClick = (example: string) => {
        switch (example) {
            default:
                break;


        }

    }

    return <div className='page-wrapper'>
        <div className='files-wrapper' style={{ width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", width: "100%" }}>
                <h1>Simulate 365 Dashboard</h1>
                <span>Work with flowsheets, smarter.</span>
            </div>
            <div>
                <h2>Manage your simulation files</h2>
                <div style={{ display: "flex", gap: "20px" }}>
                    <CompoundButton size={"small"} icon={<FolderRegular />} onClick={() => { navigate("/files"); }}>Upload your flowsheets</CompoundButton>
                    <CompoundButton size={"small"} icon={<PeopleTeamRegular />} onClick={() => { navigate("/contacts/my-contacts"); }}>Collaborate with colleagues</CompoundButton>
                    <CompoundButton size={"small"} icon={<FolderRegular />} onClick={() => { navigate("/files/examples"); }}>Use our Examples</CompoundButton>
                    <CompoundButton size={"small"} icon={<FolderRegular />} onClick={() => {
                        const DwsimProExampleUrl = generalSettings?.environment == "production" ? DwimProSamplesUrls.production : DwimProSamplesUrls.staging;
                        navigate(DwsimProExampleUrl);
                    }}>DWSIM Pro Samples</CompoundButton>
                </div>

            </div>
            <div>
                <h2>Discover our applications</h2>
                <div className="card-container" >
                    <Card className="card" onClick={() => { setShowCopilotExampleModal(true); }}>
                        <CardHeader header={<div className="card-header">
                            <h3>Flowsheet Copilot</h3>
                            <span>Generate and optimize DWSIM flowsheets using AI</span>
                        </div>} />
                        <CardPreview>
                            <img className="disable-visual-search" src={CopilotExampleImage} alt="Flowsheet Copilot example image" />
                        </CardPreview>
                    </Card>
                    <Card className="card" onClick={() => { setShowDoEExampleModal(true); }}>
                        <CardHeader header={<div className="card-header">
                            <h3>Design of Experiments (DoE)</h3>
                            <span>Create design cases for your flowsheet</span>
                        </div>} />

                        <CardPreview>
                            <img className="disable-visual-search" src={DoEExampleImage} alt="Design of Experiments example image" />
                        </CardPreview>
                    </Card>
                    <Card className="card" onClick={() => { setShowMssExampleModal(true); }}>
                        <CardHeader header={<div className="card-header">
                            <h3>Multivariate Sensitivity Study (MSS)</h3>
                            <span>Run a multidimensional sensitivity analysis for your flowsheet</span>
                        </div>} />
                        <CardPreview>
                            <img className="disable-visual-search" src={MSSExampleImage} alt="Multivariate Sensitivity Study example image" />
                        </CardPreview>
                    </Card>
                    <Card className="card" onClick={() => { setShowWebFormExampleModal(true); }}>
                        <CardHeader header={<div className="card-header">
                            <h3>Web Form</h3>
                            <span>Create a web calculator for your flowsheet</span>
                        </div>} />

                        <CardPreview>
                            <img className="disable-visual-search" src={WebFormExampleImage} alt="Web form example image" />
                        </CardPreview>
                    </Card>
                </div>
            </div>

        </div>
        {showCopilotExampleModal && copilotExampleId &&
            <ExperimentDetailsModal
                hubConnection={props.hubConnection}
                isOpened={showCopilotExampleModal}
                experimentId={copilotExampleId}
                onClose={() => { setShowCopilotExampleModal(false); }}
            />}

        {showDoEExampleModal && doeExampleId &&
            <ScenarioDetailsModal
                hubConnection={props.hubConnection}
                isOpened={showDoEExampleModal}
                scenarioId={doeExampleId}
                onClose={() => { setShowDoEExampleModal(false); }}
            />}

        {showWebFormExampleModal &&
            <WebFormExampleModal
                hubConnection={props.hubConnection}
                isOpened={true}
                onClose={() => { setShowWebFormExampleModal(false); }} />}

        {showMssExampleModal && <StudyDetailsModal
            studyId={mssExampleId}
            hubConnection={props.hubConnection}
            isOpened={showMssExampleModal}
            onClose={() => { setShowMssExampleModal(false); }} />}

        {showCommingSoonModal && commingSoonTitle && commingSoonImage &&
            <CommingSoonModal
                isOpened={true}
                title={commingSoonTitle}
                imageSrc={commingSoonImage}
                onClose={() => { setShowCommingSoonModal(false); setCommingSoonImage(undefined); setCommingSoonTitle(undefined); }}
            />}
    </div>
}