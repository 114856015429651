import { Field, Input, Label, Tab, TabList, Toolbar, ToolbarButton, tokens } from "@fluentui/react-components";
import { Alert } from "@fluentui/react-components/unstable";
import { InfoFilled, InfoRegular, Save20Regular } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from '../../components/breadcrumbs/breadcrumbs';
import { ThumbnailImage } from "../../files/thumbnail/thumbnail-image/thumbnail-image.component";
import { ThumbnailModal } from "../../files/thumbnail/thumbnail-modal/thumbnail-modal.component";
import { getFilesClient, getFiltersClient } from "../../services/dashboard.service";
import { GLOBAL_STYLES } from "../../styles";
import { BreadcrumbItem, FileModel, FilterPostModel, FilterQuery, FilterResultModel, InputFilterParameterPostModel, InputFilterParameterResultModel, OutputFilterParameterPostModel, OutputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { _copyAndSort } from "../../utils/helpers/array.helpers";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { emptyBreadcrumbs, FiltersRouteParams } from "../filters.component";
import "./edit-filter.styless.scss"
import { FilterIsValid, InputParametersAreValid, OutputParametersAreValid, ValidateFilterFields, ValidateInputParameters, ValidateOutputParameters, ValidateWebFormParameters, WebFormParametersAreValid } from "./edit-filter.validation";
import { InputParametersTab } from "./input-parameters-tab.component";
import { OutputParametersTab } from "./output-parameters-tab.component";
import { getFileExtension } from "../../files/file-type-icon/file-type-icon.helpers";
import { FilterWebForm } from "./filter-web-form.component";
import { featureFlagsSettings } from "../../App";


type EditFilterProps = {
    hubConnection?: HubConnection;
    fileUniqueId?: string;
    isModal?: boolean;
    onFilterCreated?: (filter: FilterResultModel) => void;

};

export const EditFilter: React.FC<EditFilterProps> = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [formSubmitted, SetFormSubmitted] = React.useState<boolean>(false);
    const [showThumbnailModal, setShowThumbnailModal] = React.useState<boolean>(false);
    const [filter, setFilter] = React.useState<FilterResultModel>({ name: "" } as FilterResultModel);
    const [selectedTab, setSelectedTab] = React.useState<string>("input-parameters");
    const routeParams = useParams<FiltersRouteParams>();
    const [searchParams] = useSearchParams();
    const cloneId = searchParams.get('cloneId');
    const navigate = useNavigate();
    const [isLoading, loadingService] = useLoading();
    const [hasInputParameterErrors, setHasInputParametersErrors] = React.useState<boolean>(false);
    const [hasOutputParameterErrors, setHasOutputParametersErrors] = React.useState<boolean>(false);
    const [hasWebFormErrors, setHasWebFormErrors] = React.useState<boolean>(false);

    useEffect(() => {
        getFile()
    }, []);

    useEffect(() => {
        if (selectedFile?.uniqueIdentifier) {
            getFilter(selectedFile.uniqueIdentifier!, parseInt(routeParams.filterId));

            if (cloneId) {
                getFilterData(selectedFile.uniqueIdentifier!, parseInt(cloneId));
            }
        }
    }, [selectedFile?.uniqueIdentifier, routeParams?.filterId, props.fileUniqueId]);

    React.useEffect(() => {
        if (formSubmitted) {

            console.log("Filter Form submitted!");
            const inputParamtersValidation = ValidateInputParameters(filter.inputFilterParameters);
            const outputFilterParametersValidation = ValidateOutputParameters(filter.inputFilterParameters, filter.outputFilterParameters);
            const webFormValidation = ValidateWebFormParameters(filter.inputFilterParameters, filter.enableWebForm);
            if (filter.inputFilterParameters.length == 0 || filter.outputFilterParameters.length == 0) {
                toast.error("At least 1 input and 1 output parameters are required!");
            }

            const inputParamsValid = InputParametersAreValid(inputParamtersValidation);
            const outputParamsValid = OutputParametersAreValid(outputFilterParametersValidation);
            const webFormValid = filter.enableWebForm ? WebFormParametersAreValid(webFormValidation) : true;

            if (!inputParamsValid || !outputParamsValid || !webFormValid) {
                toast.error("All fields must be valid.");

            }

            if (!inputParamsValid) {
                setHasInputParametersErrors(true);

            } else {
                setHasInputParametersErrors(false);

            }

            if (!outputParamsValid) {
                setHasOutputParametersErrors(true);

            } else {
                setHasOutputParametersErrors(false);
            }

            if (!webFormValid) {
                setHasWebFormErrors(true);

            } else {
                setHasWebFormErrors(false);
            }
        }


    }, [formSubmitted]);



    const getFilterData = async (fileUniqueId: string, id: number) => {
        loadingService.showLoading("Loading filter data...", async (hideMessage) => {
            try {
                const client = getFiltersClient();
                const cloneFilter = await client.getFilterLatest(fileUniqueId, id);
                console.log("cloneFilter", cloneFilter);
                const sortedInputFilterParameters = _copyAndSort<InputFilterParameterResultModel>(cloneFilter.inputFilterParameters ?? [], "order", false);
                const sortedOutputFilterParameters = _copyAndSort<OutputFilterParameterResultModel>(cloneFilter.outputFilterParameters ?? [], "order", false);

                console.log("cloneFilter oredered", sortedInputFilterParameters, sortedOutputFilterParameters);
                const filterData = {
                    ...cloneFilter, id: 0,
                    inputFilterParameters: sortedInputFilterParameters.map(inputParam => ({ ...inputParam, id: 0 })),
                    outputFilterParameters: sortedOutputFilterParameters.map(outputParam => ({ ...outputParam, id: 0 }))
                } as FilterResultModel;
                setFilter(filterData);
            }
            catch (error) {
                console.log("An error ocurred while trying to get flowsheet filter.", error);
            } finally {
                hideMessage();
            }
        });
    }

    const onValidateFilterName = (name: string): string => {
        console.log("onValidateFilterName called!", props);

        if ((!name || name.length == 0) && formSubmitted)
            return "Filter name is required!";

        return "";
    }

    const onNameChange = (ev: any, newValue: string) => {
        setFilter({ ...filter, name: newValue } as FilterResultModel);
    }
    const getFile = async () => {
        loadingService.showLoading("Loading file data...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                const fileUniqueId = !!props.fileUniqueId ? props.fileUniqueId : routeParams.uniquefileId!;
                const resp = await client.getFileLatest(fileUniqueId, true);
                if (resp) {
                    setSelectedFile(resp.file);
                    setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
                }
                return resp;
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting file information.");
                return null;
            } finally {
                hideMessage();
            }
        });
    }

    const getFilter = async (fileUniqueId: string, filterId?: number) => {
        loadingService.showLoading("Loading filter...", async (hideMessage) => {
            try {
                if (!filterId) {
                    setFilter({
                        inputFilterParameters: [],
                        outputFilterParameters: [],
                        isDeleted: false
                    } as unknown as FilterResultModel);
                } else {
                    const client = getFiltersClient();
                    const filterResp = await client.getFilterLatest(fileUniqueId, filterId);
                    console.log("filterResp", filterResp);
                    setFilter(filterResp);
                }

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting filter.");
            } finally {
                hideMessage();
            }
        });
    }

    const onSaveClick = async () => {
        loadingService.showLoading("Saving...", async (hideMessage) => {
            try {
                SetFormSubmitted(true);
                const validationResult = ValidateFilterFields(filter);
                console.log("Study validation result:", validationResult);
                if (!FilterIsValid(validationResult)) {
                    if (validationResult.inputParameters.length == 0 || validationResult.outputParameters.length == 0) {
                        toast.error("At least 1 input and 1 output parameters are required!");
                    }
                    return;
                }

                const client = getFiltersClient();
                let model = new FilterPostModel();
                model.id = filter.id;
                model.fileId = selectedFile?.id!;
                model.fileVersionNumber = selectedFile?.currentVersionNumber!;
                model.name = filter.name!;
                model.enableWebForm = filter.enableWebForm;
                model.webFormIntro = filter.webFormIntro;
                model.webFormOutputDescription = filter.webFormOutputDescription;
                model.webFormApplicationId = filter.webFormApplicationId;

                model.inputFilterParameters = [...(filter.inputFilterParameters?.map(x => new InputFilterParameterPostModel({ ...x as any })) ?? [])];
                model.outputFilterParameters = [...(filter.outputFilterParameters?.map(x => new OutputFilterParameterPostModel({ ...x as any })) ?? [])];

                // Convert numbers from strings to real decimal numbers
                // Needed because current ASP.NET Core doesn't accept quoted numbers
                if (model.inputFilterParameters && model.inputFilterParameters.length > 0) {
                    model.inputFilterParameters.forEach(p => {
                        if (p.fixedValue !== undefined && p.fixedValue !== null) {
                            p.fixedValue = parseFloat(p.fixedValue as any);
                        }
                    })
                }


                console.log(" filter, post model", filter, model);
                const filterResp = await client.createOrUpdateFilter(model);
                console.log("Create filter resp", filterResp);
                toast.success("Filter was saved successfully.");
                if (!props.isModal) {
                    navigate(`/files/${selectedFile?.uniqueIdentifier}/filters/${filterResp?.id}/details`);
                } else {
                    props.onFilterCreated?.(filterResp);
                }


            } catch (error) {
                processServerError(error, undefined, "An error occurred while creating / updating filter.");
            } finally {
                hideMessage();
            }
        });



    }

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        if (!props.isModal) {
            navigate(`/files/${parentDirectoryId ?? ""}`);
        }

    }
    const nameValidationMessage = onValidateFilterName(filter.name ?? "");

    return <div className="page-wrapper">
        <div className="filters-wrapper">
            <div className='toolbar__wrapper'>
                <Toolbar>
                    <ToolbarButton appearance='primary' onClick={onSaveClick}
                        icon={<Save20Regular />}>Save</ToolbarButton>
                    <LoadingIndicator loadingService={loadingService} />
                </Toolbar>
            </div>

            <div className='filters-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>
                    {breadcrumbs.map((item: BreadcrumbItem) => {
                        return <Breadcrumb
                            key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                            onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                    })}
                    {selectedFile &&
                        <Breadcrumb
                            key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                            onClick={() => { if (!props.isModal) { navigate(`/files/${selectedFile!.uniqueIdentifier!}/filters`); } }}
                        >{selectedFile.name}</Breadcrumb>}
                    <Breadcrumb key={`breadcrumb-filters`} active={true}>Filters</Breadcrumb>
                </Breadcrumbs>
            </div>

            <div className="name-field--wrapper">
                <Label htmlFor="input36">
                    Filter name:
                </Label>
                <Field
                    className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                    validationMessageIcon={null}
                    validationMessage={formSubmitted ? nameValidationMessage : undefined}
                    validationState={formSubmitted && nameValidationMessage ? "error" : "none"}>
                    <Input className={`name-field--input`}
                        value={filter.name}
                        style={{ width: "300px" }}
                        onChange={(ev, data) => onNameChange(ev, data.value)} />
                </Field>
            </div>
            {selectedFile && props.hubConnection &&
                <div className="thumbnail--wrapper">
                    <Label htmlFor="input36">
                        Thumbnail:
                    </Label>
                    <div style={{ maxWidth: "250px" }}>

                        <ThumbnailImage
                            fileUniqueIdentifier={selectedFile.uniqueIdentifier!}
                            fileId={selectedFile.id!}
                            fileVersionNumber={selectedFile.currentVersionNumber!}
                            fileExtension={getFileExtension(selectedFile.name)}
                            width={200}
                            onClick={() => { setShowThumbnailModal(true) }}
                            hubConnection={props.hubConnection} />
                    </div>
                </div>}

            <Alert intent="info" className="alert--info-global" style={{ marginTop: "10px", marginBottom: "10px" }} >
                To use parameters in formulas and functions you must define an alias for it. <br />
                Alias can contain only letters, numbers and underscore (_). It can't start with the number.
            </Alert>

            <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
                <Tab key="input-tab" value="input-parameters" className={hasInputParameterErrors ? "has-errors" : undefined} >Input parameters</Tab>
                <Tab key="output-tab" value="output-parameters" className={hasOutputParameterErrors ? "has-errors" : undefined}>Output parameters</Tab>
                {featureFlagsSettings.showFilterWebForm && <Tab key="web-form-tab" value="web-form" className={hasWebFormErrors ? "has-errors" : undefined} >Web Form</Tab>}

            </TabList>
            <div className="tab-content">
                {selectedTab == "input-parameters" &&
                    <InputParametersTab
                        isLoading={isLoading}
                        isFormSubmitted={formSubmitted}
                        filter={filter}
                        selectedFile={selectedFile}
                        setFilter={(data) => { setFilter(data); }} />
                }
                {selectedTab == "output-parameters" &&
                    <OutputParametersTab
                        isLoading={isLoading}
                        isFormSubmitted={formSubmitted}
                        filter={filter}
                        selectedFile={selectedFile}
                        setFilter={(data) => { setFilter(data); }} />}

                {selectedTab == "web-form" &&
                    <FilterWebForm
                        isLoading={isLoading}
                        isFormSubmitted={formSubmitted}
                        filter={filter}
                        selectedFile={selectedFile}
                        setFilter={(data) => { setFilter(data); }}
                    />}
            </div>

            {selectedFile && showThumbnailModal && <ThumbnailModal
                file={selectedFile}
                hubConnection={props.hubConnection!}
                isOpened={showThumbnailModal}
                onClose={() => setShowThumbnailModal(false)}
            />}

        </div>
    </div>
}