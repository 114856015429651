import { Button } from "@fluentui/react-components";
import React from "react";
import { CreateCopilotExperimentWizardModal, WizardApplication } from "../create-experiment-wizard/create-experiment-wizard.modal";
import { HubConnection } from "@microsoft/signalr";

type ApplicationTryNowButtonProps = {
    application: WizardApplication;
    hubConnection: HubConnection;
}

export const ApplicationTryNowButton: React.FC<ApplicationTryNowButtonProps> = (props) => {
    const [showCreateNewExperimentWizardModal, setShowCreateNewExperimentWizardModal] = React.useState<boolean>(false);

    return <>
        <Button appearance="primary" onClick={() => { setShowCreateNewExperimentWizardModal(true); }}>Try now</Button>
        {showCreateNewExperimentWizardModal &&
            <CreateCopilotExperimentWizardModal
                title={getWizardTitle(props.application)}
                hubConnection={props.hubConnection}
                application={props.application}
                isOpened={showCreateNewExperimentWizardModal}
                onClose={() => { setShowCreateNewExperimentWizardModal(false); }}
            />}
    </>

}
const getWizardTitle = (application: WizardApplication) => {
    switch (application) {
        case WizardApplication.Copilot:
            return "Create Flowsheet Copilot Experiment";
        case WizardApplication.DoE:
            return "Create Design of Experiment (DoE)";
        case WizardApplication.WebForm:
            return "Create Web Form";
        case WizardApplication.MSS:
            return "Create Multivariate Sensitivity Study (MSS)";
        case WizardApplication.OptimumTest:
            return "Create Optimum Test (OT)";

        default:
            return "-";
    }

}