
export const getSignificantFigures = (value: number, expectedLength: number) => {
    // If not an number, return initial value
    if (!isNumber(value)) {
        return value;
    }

    let isNegative = false;
    if (value < 0) {
        isNegative = true;
        value = Math.abs(value);
    }

    // Get length of positive part
    // Positive part are all numbers before decimal separator
    let length = getLengthOfPositivePart(value);

    if (length < expectedLength) {
        let decimalPart = value - Math.floor(value);

        while (true) {
            decimalPart *= 10
            if (decimalPart < 1 && decimalPart > 0) {
                expectedLength++;
            } else {
                break;
            }
        }

        if (isNegative) { value *= -1; }
        return prettyFloat(value, expectedLength - length, false);
    }
    else {
        if (isNegative) { value *= -1; }
        return Math.round(value);
    }
}



export const prettyFloat = (value, precision, localize) => {

   // value = value || "";
    precision = precision || 0;
    localize = localize || false;

    var rounded,
        trimmed;

    rounded = (!isNaN(precision) && parseInt(precision, 10) > 0)
        ? parseFloat(value).toFixed(parseInt(precision, 10))
        : value;

    trimmed = parseFloat(rounded).toString();

    if (localize && !isNaN(trimmed)) {
        return parseFloat(trimmed).toLocaleString();
    }

    return trimmed;

};

const getLengthOfPositivePart = (value) => {
    // Get length of positive part
    let length = Math.round(value).toString().length;

    // If value is negative, length is reduced by one because of minus sign
    if (value < 0)
        length = length - 1;

    // If value is between 0 and 1, length is reduced by one because zero is never significant digit
    if (value >= 0 && value < 1)
        length = length - 1;

    return length;
}

// Copied from https://codepen.io/grok/pen/LvOQbW?editors=0010
const isNumber = (value) => {
    // We will not coerce boolean to numbers, although we could.
    // We will not coerce strings to numbers, even though we could try.
    // Referencing https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
    if (typeof value !== 'number') {
        return false
    }

    // Consider this as the NaN check.
    // NaN is a number.
    // NaN has the unique property of never equaling itself.
    // Pulled this hack right off of MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isNaN
    if (value !== Number(value)) {
        return false
    }

    // At this point, we for sure have some sort of number.
    // But not all numbers are finite, and realistically we want finite numbers.
    if (Number.isFinite(value) === false) {
        return false
    }

    return true
}


