import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { toast } from "react-toastify";


type InformationDialogProps = {
    title: string;
    subText: string;
    isOpened?: boolean,
    cancelLabel?: string,
    onClose: () => void;
};

export const InformationDialog: React.FC<InformationDialogProps> = (props) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);



    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }

    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>{props.title}</DialogTitle>
                <DialogContent>
                    <p>{props.subText}</p>
                </DialogContent>
                <DialogActions>
                    <Button appearance="secondary" onClick={onModalClose}>{props.cancelLabel ? props.cancelLabel : "Close"}</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}