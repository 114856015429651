import React from "react";
import { FilterParameterType, FilterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { ParameterDisplay } from "../../components/parameter-display/parameter-display.component";
import { getParameterTypeText } from "../../filters/filter-utilities";

type EditDoeExperimentOutputParametersTabProps = {
    filter: FilterResultModel;
    isLoading: boolean;
};

export const EditDoeExperimentOutputParametersTab: React.FC<EditDoeExperimentOutputParametersTabProps> = (props) => {


    return <div>
        <div className="s365-table__wrapper">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell key="parameter" style={{ width: "400px" }} className='files-table__cell--bold'>Parameter</TableHeaderCell>
                        <TableHeaderCell key="alias" style={{ width: "220px" }} className='files-table__cell--bold'>Alias</TableHeaderCell>
                        <TableHeaderCell key="parameter-type" style={{ width: "180px" }} className='files-table__cell--bold'>Parameter Type</TableHeaderCell>
                        <TableHeaderCell key="value-formula-field" style={{ width: "250px" }} className='files-table__cell--bold'>Formula</TableHeaderCell>
                        <TableHeaderCell key="empty-field" ></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={props.isLoading}
                    columnCount={4} loadingMessage="Loading..."
                    itemCount={props.filter?.outputFilterParameters ? props.filter.outputFilterParameters.length : 0}
                    noItemsMessage="No items found.">

                    {props.filter && props.filter.outputFilterParameters &&
                        props.filter.outputFilterParameters.map((item, index) => {
                            return <TableRow key={`output-param-${index}`}>

                                <TableCell className="s365-table__cell--center-vertical" style={{ display: "flex", alignItems: "center" }}>
                                    {!!item.name && item.parameterType !== FilterParameterType.Formula ? <>
                                        <ParameterDisplay
                                            value={item.name}
                                            fileUniqueId={props.filter.fileUniqueIdentifier}
                                            versionNumber={props.filter.fileVersionNumber.toString()}
                                        />

                                    </> : "-"}

                                </TableCell>
                                <TableCell className="s365-table__cell--center-vertical">
                                    {item.alias}
                                </TableCell>
                                <TableCell className="s365-table__cell--center-vertical">{getParameterTypeText(item.parameterType)} </TableCell>
                                <TableCell className="s365-table__cell--center-vertical">{!!item.formula ? item.formula : "-"}</TableCell>
                                <TableCell key="empty" ></TableCell>
                            </TableRow>
                        })

                    }
                </TableBodyWithLoading>
            </Table>
        </div>


    </div>;
}
