import React from "react";
import ReactSelect, { ActionMeta, GroupBase, StylesConfig } from "react-select";
import { ComponentResponseModel } from "../../swagger-clients/ai-for-pfd-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import FlowsheetComponentsService from "../../services/flowsheet-components.service";


type DwsimComponentPickerProps = {
    value?: string;
    isDisabled?: boolean;
    // selected on other pickers
    selectedValues: string[];
    onChange: (component?: ComponentResponseModel) => void;

}

export const DwsimComponentPicker: React.FC<DwsimComponentPickerProps> = (props) => {

    const [selectedOption, setSelectedOption] = React.useState<SelectOption>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [components, setComponents] = React.useState<ComponentResponseModel[]>([]);



    React.useEffect(() => { getComponents(); }, []);

    React.useEffect(() => {
        if (!!props.value) {
            const selectedComponent = components.find(x => x.casNr.toLowerCase() == props.value.toLowerCase());

            if (!selectedComponent) {
                setSelectedOption(undefined);
                return;
            }

            setSelectedOption({ value: selectedComponent.casNr, label: selectedComponent.name });

        } else {
            setSelectedOption(undefined);
        }


    }, [props.value, components]);

    const getOptions = () => {
        const availableComponents = components.filter(x => props.selectedValues.indexOf(x.casNr) == -1);
        return availableComponents.map(x => ({ label: x.name, value: x.casNr } as SelectOption));
    }

    const getComponents = async () => {
        try {
            setIsLoading(true);
            const componentsResp = await FlowsheetComponentsService.getFlowsheetComponentsPromise();
            setComponents(componentsResp ?? []);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting components.");

        } finally {
            setIsLoading(false);
        }


    }

    const onParametersChanged = async (selectedOption: SelectOption, actionMeta: ActionMeta<SelectOption>) => {


        // on option selected
        if (actionMeta.action == "select-option") {
            const component = components.find(x => x.casNr == selectedOption.value);
            props.onChange(component);
        }

        // on tag removed

        if (actionMeta.action == "remove-value") {
            props.onChange();
        }

    }

    const options = React.useMemo(() => getOptions(), [components, props.selectedValues]);


    if (props.isDisabled) {
        return <span>{selectedOption?.label ?? "-"}</span>;
    }


    return <div style={{ width: "100%" }}>
        <ReactSelect
            value={selectedOption}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            menuPlacement={"auto"}
            isLoading={isLoading}
            isClearable={false}
            options={options}            
            isDisabled={props.isDisabled}
            onChange={onParametersChanged}
            styles={ParameterPickerStyless}
        />
    </div>
}

const ParameterPickerStyless: StylesConfig<SelectOption, false, GroupBase<SelectOption>> = {
    control: (styles) => ({ ...styles, minHeight: "30px" }),
    dropdownIndicator: (styles) => ({
        ...styles,
        paddingTop: 5,
        paddingBottom: 5,
    }),
    clearIndicator: (styles) => ({
        ...styles,
        paddingTop: 5,
        paddingBottom: 5,
    }),
    input: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: "14px"
    }),
    option: (styles) => ({
        ...styles,
        paddingTop: "4px",
        paddingBottom: "4px",
        lineHeight: "16px"
    }),
};

export type SelectOption = {
    value: string
    label: string

}