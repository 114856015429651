import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, Field, Input, TableColumnDefinition, TableColumnSizingOptions, createTableColumn, useTableFeatures, useTableColumnSizing_unstable } from "@fluentui/react-components";
import React from "react";
import { ParameterDisplay } from "../../../components/parameter-display/parameter-display.component";
import { TableBodyWithLoading } from "../../../components/table-body-with-loading/table-body-with-loading.component";
import { FileModel, FilterParameterType, FilterResultModel, OutputFilterParameterResultModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { ValidationResult } from "../../../utils/helpers/validation.helpers";
import { GLOBAL_STYLES } from "../../../styles";

type ScenarioOutputParametersTabProps = {
    outputParameters: OutputFilterParameterResultModel[],
    isFormSubmitted: boolean;
    isFormDisabled: boolean;
    values: {};
    isLoading: boolean;
    onChange: (filterParamId: number, column: string) => void;
    validationResult: ValidationResult;
    selectedFilter: FilterResultModel;
    selectedFilterDocument: FileModel;
}

export const ScenarioOutputParametersTab: React.FunctionComponent<ScenarioOutputParametersTabProps> = (props) => {
    const [columns, setColumns] = React.useState<TableColumnDefinition<OutputFilterParameterResultModel>[]>([]);

    React.useEffect(() => { const columnsResp = getColumns(); setColumns(columnsResp); }, []);

    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        parameter: {
            minWidth: 100,
            defaultWidth: 300,
        },
        alias: {
            minWidth: 100,
            defaultWidth: 200,
        },
        parameterType: {
            minWidth: 100,
            defaultWidth: 200
        },
        valueFormulaField: {
            minWidth: 100,
            defaultWidth: 200
        },
        column: {
            minWidth: 50,
            defaultWidth: 100

        }
    });

    const getColumns = () => {
        const columnsDef: TableColumnDefinition<OutputFilterParameterResultModel>[] = [
            createTableColumn<OutputFilterParameterResultModel>({
                columnId: "parameter",
                renderHeaderCell: () => <>Parameter</>,
                renderCell: (item: OutputFilterParameterResultModel) => {
                    return <ParameterDisplay
                        value={item.name}
                        fileUniqueId={props.selectedFilterDocument.uniqueIdentifier}
                        versionNumber={props.selectedFilterDocument.currentVersionNumber?.toString()} />;

                }
            }),
            createTableColumn<OutputFilterParameterResultModel>({
                columnId: "alias",
                renderHeaderCell: () => <>Alias</>,
                renderCell: (item: OutputFilterParameterResultModel) => {
                    return item.alias;

                }
            }),
            createTableColumn<OutputFilterParameterResultModel>({
                columnId: "parameterType",
                renderHeaderCell: () => <>Parameter Type</>,
                renderCell: (item: OutputFilterParameterResultModel) => {
                    return {
                        [FilterParameterType.ExpectedOutput]: "Column",
                        [FilterParameterType.Formula]: "Formula"
                    }[item.parameterType];
                }
            }),
            createTableColumn<OutputFilterParameterResultModel>({
                columnId: "valueFormulaField",
                renderHeaderCell: () => <>Formula / Fixed Value</>,
                renderCell: (item: OutputFilterParameterResultModel) => {

                    return <>
                        {item.parameterType == FilterParameterType.Formula && <span>{item.formula}</span>}
                        {item.parameterType != FilterParameterType.Formula && <span>-</span>}
                    </>;
                }
            }),
            createTableColumn<OutputFilterParameterResultModel>({
                columnId: "column",
                renderHeaderCell: () => <>Column</>,
                renderCell: (item: OutputFilterParameterResultModel) => {

                    return <Field
                    className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                        validationMessage={props.validationResult.getFieldValidationMessage(`op_${item.id}`, props.isFormSubmitted)}
                        validationState={props.isFormSubmitted && props.validationResult.getFieldValidationMessage(`op_${item.id}`, props.isFormSubmitted) ? "error" : "none"}>
                        <Input
                            type="text"
                            maxLength={5}
                            value={props.values[item.id]}
                            onChange={(ev, data) => { props.onChange(item.id, data.value) }} />
                    </Field>;

                }
            })
        ];

        return columnsDef;
    }
    const items = props.outputParameters ?? [];

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<OutputFilterParameterResultModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    return <div>

        <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
            <TableHeader>
                <TableRow key="table-header">
                    {columns.map((column) => (
                        <TableHeaderCell
                            onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            className={`table__cell--bold`}
                            key={column.columnId}
                            {...columnSizing_unstable.getTableHeaderCellProps(
                                column.columnId
                            )}
                        >
                            {column.renderHeaderCell()}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>

            <TableBodyWithLoading isLoading={props.isLoading}
                columnCount={6} loadingMessage="Loading..."
                itemCount={props.outputParameters ? props.outputParameters.length : 0}
                noItemsMessage={`Select filter to view parameters.`}>
                {props.outputParameters &&
                    props.outputParameters.map((item, index) => {
                        return <TableRow key={`output-param-${index}`}>
                            {columns.map((column) => (
                                <TableCell
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
    </div>;
}