import { WriteDataTo } from '../../../swagger-clients/excel-runner-api-clients.service';
import { Button, Checkbox, Field, Input, Label, Radio, RadioGroup } from "@fluentui/react-components";
import { Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions } from "@fluentui/react-components";
import { Alert, InfoButton } from "@fluentui/react-components/unstable";

import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { settings } from "../../../App";
import { LoadingButton } from "../../../components/loading-button/loading-button.component";
import { GLOBAL_STYLES } from "../../../styles";
import { FileModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { useLoading } from "../../../utils/loading-indicator.component";
import { GenerateExcelRequestModel, SensitivityStudyResultModel } from "../../../swagger-clients/sensitivity-studies-api-clients.service";
import { IChoiceGroupOption } from '../../../utils/shared.types';
import { isValidFileName } from '../../../utils/helpers/validation.helpers';

type WriteResultsDialogProps = {
    isOpened?: boolean,
    onSuccess: (data: IWriteResultsModel) => void,
    onClose?: () => void
};

type GenerateDataModel = {
    name: string,
    extension?: string
};

export const WriteResultsDialog: React.FC<WriteResultsDialogProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [isLoading, loadingService] = useLoading();
    const [isChecked, setIsChecked] = React.useState(false);
    const [writeDataTo, setWriteDataTo] = React.useState<string>(WriteDataTo.CurrentAsOverwrite.toString());
    const [filename, setFilename] = React.useState<string>("");
    const [filenameError, setFilenameError] = React.useState<string>("");


    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const onSaveClick = () => {

        if (writeDataTo !== WriteDataTo.CurrentAsOverwrite.toString()) {
            if (!filename || /(^$)|(\s+$)/.test(filename)) {
                setFilenameError("File name is required!");
                return;
            }
            if (!isValidFileName(filename)) {
                setFilenameError(`The file name can only contain letters, digits, space or following characters: . - _`);
                return;
            }
        }
        const response = {
            writeRowIds: isChecked,
            filename: `${filename}.xlsx`,
            writeDataTo: +writeDataTo as WriteDataTo

        } as IWriteResultsModel;
        props.onSuccess(response);
    }

    // write to historic was disabled due to request  by Luisa TP#11085
    const writeToOptions: IChoiceGroupOption[] = [
        { key: WriteDataTo.CurrentAsOverwrite.toString(), text: 'Write to current version and overwrite' },
        { key: WriteDataTo.CurrentAsNewFile.toString(), text: 'Write to current version and save as new file' }
        // ,{ key: WriteDataTo.HistoricVersionAsNewFile.toString(), text: 'Write to historic version and save as new file' }

    ];



    const onModalClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }



    return (<Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Write results</DialogTitle>
                <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                    <div className="input-form">
                        <div className="input-form-item">
                            <RadioGroup
                                className="input-field-radio"
                                value={writeDataTo}
                                onChange={(ev, data) => { setWriteDataTo(data.value); setFilename(""); setFilenameError(""); }}   >
                                {writeToOptions.map((option) => {
                                    return <Radio
                                        label={option.text}
                                        value={option.key?.toString()}
                                    />
                                })}
                            </RadioGroup>
                        </div>

                        {writeDataTo !== WriteDataTo.CurrentAsOverwrite.toString() &&
                            <div className="input-form-item" style={{ alignItems: "flex-start" }}>
                                <Label className='input-form-label'>File name</Label>
                                <Field
                                    className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                                    validationMessage={filenameError}
                                    validationState={!!filenameError ? "error" : "none"}>
                                    <Input
                                        value={filename}
                                        style={{ width: "300px" }}
                                        className={`input-field`}
                                        onChange={(ev, data) => { setFilename(data.value); setFilenameError(""); }} />
                                </Field>
                            </div>}

                        <div className="input-form-item">
                            <Checkbox label="Write Row IDs as comments" checked={isChecked} onChange={(ev, data) => { setIsChecked(!!data.checked); }} />
                            <InfoButton info={
                                <p style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                    Row IDs link your Excel rows to the calculated job from your Agent.<br />
                                    If you plan on calculating multiple Design of Experiments scenarios in the same Excel sheet,<br />
                                    do not add Row IDs as comments.</p>} />


                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button appearance="primary" onClick={() => onSaveClick()}>Yes</Button>
                    <Button appearance="secondary" onClick={() => props.onClose()}>Cancel</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}


export interface IWriteResultsModel {
    writeRowIds: boolean;
    writeDataTo: WriteDataTo;
    filename?: string;
}