import * as React from "react";
import { SimulatorType } from "../../swagger-clients/dispatcher-next-api-clients.service";
interface ISimulatorTypeComponentProps {
    value: number;
    style?: React.CSSProperties;
}

const mapping = {
    [SimulatorType.Chemcad7]: { text: "Chemcad 7", img: require("../../assets/filetype-icons/cc7.png") },
    [SimulatorType.Chemcad8]: { text: "Chemcad 8", img: require("../../assets/filetype-icons/ccsim.png") },
    [SimulatorType.DWSIM]: { text: "DWSIM Pro", img: require("../../assets/filetype-icons/dwsim_pro.png") }
}
const SimulatorTypeComponent: React.FunctionComponent<ISimulatorTypeComponentProps> = (props) => {

    const simulatorType = mapping[props.value];
    return simulatorType && <img src={simulatorType.img} title={simulatorType.text} alt={simulatorType.text} style={{...props.style}} />;
}

export default SimulatorTypeComponent;