import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, TabList, Tab, DialogActions, Label, RadioGroup, Radio, dividerClassNames } from "@fluentui/react-components";

import { Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";

import { Dismiss24Filled, EditRegular } from "@fluentui/react-icons";
import React from "react";
import { TableBodyWithLoading } from "../../../components/table-body-with-loading/table-body-with-loading.component";

import { getAgentGroupsClient, getApplicationsClient } from "../../../services/dispatcher.service";
import {  AgentGroupResponseModel, ApplicationResponseModel } from "../../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { useLoading } from "../../../utils/loading-indicator.component";
import { AgentRegistrationTokensTable } from "./agent-registration-tokens-table/agent-registration-tokens-table.component";
import { AgentShare } from "./agent-share/agent-share.component";
import "./details-agent-group-modal.styless.scss";
import ShowLocalTime from "../../../components/show-local-time/show-local-time.component";

type DetailsAgentGroupModalProps = {
    agentGroupId?: number;
    isOpened: boolean;
    onClose?: () => void;
    onEditClick: () => void;
}


export const DetailsAgentGroupModal: React.FC<DetailsAgentGroupModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [applications, setApplications] = React.useState<ApplicationResponseModel[]>([]);
    const [agentGroup, setAgentGroup] = React.useState<AgentGroupResponseModel>();
    const [isLoading, loadingService] = useLoading();
    const [selectedTab, setSelectedTab] = React.useState<string>("details");

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    const onInit = async () => {
        await getApplications();
        if (props.agentGroupId) {
            await getAgentGroup();
        }
    }

    React.useEffect(() => {
        onInit();
    }, []);

    const getAgentGroup = async () => {
        loadingService.showLoading("Getting agent group...", async (hideMessage) => {
            try {
                const client = getAgentGroupsClient();
                const agentGroupResp = await client.getAgentGroup(props.agentGroupId);
                //console.log("Loaded agent group", agentGroup);

                setAgentGroup(agentGroupResp);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get agent group.");
            }
            finally {
                hideMessage();
            }
        });
    }

    const getApplications = async () => {
        loadingService.showLoading("Getting applications...", async (hideMessage) => {
            try {
                const client = getApplicationsClient();
                const applicationsResp = await client.getApplications();
                setApplications(applicationsResp);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting applications.");
            }
            finally {
                hideMessage();
            }

        });
    }

    const getGroupRunApplicationList = (): string[] => {

        const groupApps = agentGroup && agentGroup.runApplications ? applications.filter(x => agentGroup.runApplications.indexOf(x.id) !== -1) : [];
        // console.log("getGroupRunApplicationList", agentGroup, applications, groupApps);
        return groupApps.map(x => x.name);
    }

    const applicationNames = getGroupRunApplicationList();

    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface style={{ maxWidth: "700px" }}>
            <DialogBody style={{ maxWidth: "inherit" }}>
                <DialogTitle action={<Button appearance="transparent" disabled={isLoading} onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <span>Agent Group: <i>{`${agentGroup?.name}`}</i></span>
                        <Button size="small"
                            appearance="subtle"
                            style={{ marginLeft: "var(--spacingHorizontalM)" }}
                            onClick={() => { props.onEditClick() }}
                            icon={<EditRegular />}> Edit</Button>
                    </div>
                </DialogTitle>
                <DialogContent className="details-agent-group" style={{ minHeight: "400px", height: "80vh" }}>
                    <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { setSelectedTab(data.value as string); }}>
                        <Tab key="details" value="details" >Details</Tab>
                        <Tab key="agents" value="agents" >Agents</Tab>
                        <Tab key="registration-tokens" value="registration-tokens">Registration Tokens</Tab>
                        <Tab key="share" value="share">Share</Tab>
                    </TabList>
                    <div className="tab-content">
                        {selectedTab == "details" &&
                            <div className="input-form">
                                <div className="input-form-item">
                                    <Label className="input-label">Name:</Label>
                                    <span>{agentGroup?.name}</span>

                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Run all jobs:</Label>
                                    <span>{agentGroup?.runAllJobs !== undefined ? (agentGroup?.runAllJobs ? "Yes" : "No") : "-"}</span>
                                </div>


                                {!agentGroup?.runAllJobs && <div className="input-form-item" style={{ marginBottom: "20px" }}>
                                    <Label className="input-label">Run applications:</Label>
                                    <span style={{ flex: 1, paddingLeft: "5px" }}>{applicationNames ? applicationNames.map((appName, index) => {
                                        const lastIndex = applicationNames.length - 1;
                                        return index == lastIndex ?
                                            <span style={{ display: "inline-block" }}> {appName} </span>
                                            :
                                            <span style={{ display: "inline-block" }}>{appName},&nbsp;</span>

                                    }) : ""}</span>

                                </div>
                                }
                                <div className="input-form-item">
                                    <Label className="input-label">Save calculated flowsheet:</Label>
                                    <span>{agentGroup?.saveCalculatedFlowsheets !== undefined ? (agentGroup?.saveCalculatedFlowsheets ? "Yes" : "No") : "-"}</span>


                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Disable if host busy:</Label>
                                    <span>{agentGroup?.disableIfHostIsBusy !== undefined ? (agentGroup?.disableIfHostIsBusy ? "Yes" : "No") : "-"}</span>
                                </div>

                                <div className="input-form-item" style={{ alignItems: "baseline" }}>
                                    <Label className="input-label">Licensing servers:</Label>
                                    <span style={{ flex: 1, paddingLeft: "5px" }}>{agentGroup && agentGroup.licensingServers ? agentGroup.licensingServers.map((server, index) => {
                                        const lastIndex = agentGroup.licensingServers.length - 1;
                                        return index == lastIndex ?
                                            <span style={{ display: "inline-block" }}> {server} </span>
                                            :
                                            <span style={{ display: "inline-block" }}>{server},&nbsp;</span>

                                    }) : ""}</span>
                                </div>
                            </div>
                        }
                        {selectedTab == "agents" && <div className="s365-table__wrapper">

                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell key="name" className='s365-table__cell--bold'>Name</TableHeaderCell>
                                        <TableHeaderCell key="created-at" className='s365-table__cell--bold column--center'>Created At</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBodyWithLoading isLoading={isLoading}
                                    columnCount={3} loadingMessage="Loading..."
                                    itemCount={agentGroup && agentGroup.agents ? agentGroup.agents.length : 0}
                                    noItemsMessage="No agents found.">
                                    {agentGroup && agentGroup.agents.length > 0 && agentGroup.agents.map((item, index) => {
                                        return <TableRow key={`agent-${item.id}`}  >
                                            <TableCell><span style={{ marginLeft: "10px" }}>{item.displayName}</span></TableCell>
                                            <TableCell className="column--center"><ShowLocalTime date={item.createdAt}  /></TableCell>
                                        </TableRow>
                                    })}
                                </TableBodyWithLoading>
                            </Table>


                        </div>}

                        {selectedTab == "registration-tokens" && <AgentRegistrationTokensTable agentGroupId={props.agentGroupId} />}
                        {selectedTab == "share" && <AgentShare agentGroupId={props.agentGroupId} agentName={agentGroup?.name} />}
                    </div>

                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}


