import React from "react";
import { ExperimentResponseModel } from "../../swagger-clients/ai-for-pfd-clients.service";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { ContextMenu, ContextMenuType } from "../../files/context-menu/context-menu.component";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "s365-dashboard-v2-file-picker/build/src/breadcrumbs/breadcrumbs";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { useNavigate } from "react-router-dom";
import { getExperimentsClient } from "../../services/ai-for-pfd.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { MenuItem, Table, TableCell, TableColumnDefinition, TableColumnSizingOptions, TableHeader, TableHeaderCell, TableRow, Toolbar, ToolbarButton, createTableColumn, useTableColumnSizing_unstable, useTableFeatures } from "@fluentui/react-components";
import { AddRegular, DocumentTextRegular } from "@fluentui/react-icons";
import ReactPaginate from "react-paginate";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import { ExperimentStatusComponent } from "../../components/experiment-status/experiment-status.component";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { getDashboardSharingClient } from "../../services/dashboard.service";
import { ShareDefinitionSourceType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { Alert } from "@fluentui/react-components/unstable";
import { ExperimentDetailsModal } from "../experiment-details-modal/experiment-details-modal.component";
import { CopilotExample } from "./copilot-example/copilot-example";
import { generalSettings } from "../../App";
import { CopilotExampleIds, CopilotExampleMappings } from "./example.settings";
import { CommingSoonModal } from "../../components/comming-soon-modal/comming-soon.modal";
import { CreateCopilotExperimentWizardModal, WizardApplication } from "../../components/create-experiment-wizard/create-experiment-wizard.modal";
import { HubConnection } from "@microsoft/signalr";

type AllUserExperimentsProps = {
    hubConnection: HubConnection;
}



export const AllUserExperiments: React.FC<AllUserExperimentsProps> = (props) => {

    const [selectedExperiment, setSelectedExperiment] = React.useState<ExperimentResponseModel>();
    const [experiments, setExperiments] = React.useState<ExperimentResponseModel[]>([]);
    const [showDeleteExperimentModal, setShowDeleteExperimentModal] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();
    const contextMenuRef = React.createRef<ContextMenuType>();
    const [currentPage, SetCurrentPage] = React.useState<number>(1);
    const [sizePerPage, SetSizePerPage] = React.useState<number>(25);
    const [totalRecords, setTotalRecords] = React.useState<number>(0);
    const [orderByIdDesc, setOrderByIdDesc] = React.useState<boolean>(true);
    const [exampleIds, setExampleIds] = React.useState<number[]>([]);
    const [showExperimentExampleModal, setShowExperimentExampleModal] = React.useState<boolean>(false);
    const [selectedExampleExperimentId, setSelectedExampleExperimentId] = React.useState<number>();
    const [showCreateNewExperimentWizardModal, setShowCreateNewExperimentWizardModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        getPublicExperiments();
    }, []);

    React.useEffect(() => {
        getExperiments();
    }, [currentPage, orderByIdDesc]);

    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        id: {
            defaultWidth: 100,
            minWidth: 50,
        },
        name: {
            minWidth: 100,
            defaultWidth: 300,
        },
        file: {
            minWidth: 200,
            defaultWidth: 400,
        },
        status: {
            minWidth: 100,
            defaultWidth: 200
        },
        created: {
            minWidth: 100,
            defaultWidth: 150
        }
    });


    const items = experiments ?? [];
    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<ExperimentResponseModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    const getPublicExperiments = () => {
        loadingService.showLoading("Loading examples...", async (hideMessage) => {
            try {
                const client = getDashboardSharingClient();

                const sharedDefinitions = await client.getPublicExamples(ShareDefinitionSourceType.CopilotExperiment);
                const sharedDefinitionSourceIds = sharedDefinitions?.map(x => +x.sourceId) ?? [];

                const environment = generalSettings.environment ?? "development";
                const CopilotExamples = CopilotExampleIds[environment] as number[];
                console.log("CopilotExamples", CopilotExamples);
                const differentThanDefaultExampleIds = sharedDefinitionSourceIds.filter(x => CopilotExamples.find(y => y == x) === undefined);
                setExampleIds([...CopilotExamples, ...differentThanDefaultExampleIds]);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting examples.`);
            } finally {
                hideMessage();
            }
        });
    }

    const getExperiments = async () => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getExperimentsClient();

                const experimentsResp = await client.getAllUserExperiments((currentPage - 1) * sizePerPage, sizePerPage, orderByIdDesc);
                setExperiments(experimentsResp?.experiments ?? []);
                setTotalRecords(experimentsResp?.totalRecords ?? 0);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting experiments.`);
            } finally {
                hideMessage();
            }
        });
    }



    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        menuItems.push(<MenuItem icon={<DocumentTextRegular />} key="details"
            onClick={() => navigate(`/files/${selectedExperiment?.flowsheetUniqueId}/copilot/details/${selectedExperiment?.id}`)} > Details</MenuItem>);


        return menuItems;
    }


    const totalPages = totalRecords > sizePerPage ? Math.ceil(totalRecords / sizePerPage) : 1;

    return <div className="content-wrapper">

        <div className='toolbar__wrapper'>
            <Toolbar>

                <ToolbarButton icon={<AddRegular />} appearance="primary" onClick={() => { setShowCreateNewExperimentWizardModal(true); }}>New Experiment</ToolbarButton>


                <LoadingIndicator loadingService={loadingService} />

            </Toolbar>
        </div>

        <div className='groups-wrapper__breadcrumbs-wrapper'>
            <Breadcrumbs>              
                <Breadcrumb key={`breadcrumb-applications`}>Applications</Breadcrumb>
                <Breadcrumb key={`breadcrumb-agents`} active={true}>Flowsheet Copilot</Breadcrumb>
            </Breadcrumbs>
        </div>

        <div id="examples" style={{ display: "flex", gap: 20 }} >
            {!!exampleIds && exampleIds.length > 0 && exampleIds.map((experimentId) => {

                return <CopilotExample
                    experimentId={experimentId}
                    onClick={() => {
                        setSelectedExampleExperimentId(experimentId);
                        setShowExperimentExampleModal(true);
                    }} />


            })}

        </div>

        <div className='s365-table__wrapper'>
            <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
                <TableHeader>
                    <TableRow>
                        {columns.map((column) => (
                            <TableHeaderCell
                                onDragStart={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className={`${isColumnCentered(column.columnId as string) ? 'column--center' : ''} s365-table__cell--bold`}
                                key={column.columnId}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}
                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={5} loadingMessage="Loading..."
                    itemCount={experiments ? experiments.length : 0}
                    noItemsMessage="No items found.">
                    {experiments && experiments.length > 0 && experiments.map((item) => {
                        return <TableRow
                            key={`study-${item.id}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/files/${item?.flowsheetUniqueId}/copilot/details/${item?.id}`)}
                            className={selectedExperiment && selectedExperiment.id == item.id ? "table-row-selected" : undefined}
                            onContextMenu={(ev) => { ev.preventDefault(); setSelectedExperiment(item); contextMenuRef.current?.showMenu(ev); }}>
                            {columns.map((column) => (
                                <TableCell
                                    className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    })}
                </TableBodyWithLoading>
            </Table>
            <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me page-item'}
                breakLinkClassName={'page-link'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}

                onPageChange={(page) => { console.log("Selected page:", page); SetCurrentPage(page.selected + 1); }}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </div>


        <ContextMenu ref={contextMenuRef} >
            {getContextItems()}
        </ContextMenu>

        {showExperimentExampleModal && selectedExampleExperimentId && selectedExampleExperimentId > 0 &&
            <ExperimentDetailsModal
                hubConnection={props.hubConnection}
                exampleName={CopilotExampleMappings[generalSettings?.environment ?? "development"]?.[selectedExampleExperimentId]?.title}
                isOpened={showExperimentExampleModal}
                experimentId={selectedExampleExperimentId}
                onClose={() => { setShowExperimentExampleModal(false); setSelectedExampleExperimentId(undefined); }}
            />}
        {showExperimentExampleModal && selectedExampleExperimentId && selectedExampleExperimentId < 0 &&
            <CommingSoonModal
                isOpened={true}
                title={CopilotExampleMappings[generalSettings?.environment ?? "development"]?.[selectedExampleExperimentId]?.title}
                imageSrc={CopilotExampleMappings[generalSettings?.environment ?? "development"]?.[selectedExampleExperimentId]?.image}
                onClose={() => { setShowExperimentExampleModal(false); setSelectedExampleExperimentId(undefined); }}
            />}

        {showCreateNewExperimentWizardModal &&
            <CreateCopilotExperimentWizardModal
                title="Create Flowsheet Copilot Experiment"
                hubConnection={props.hubConnection}
                application={WizardApplication.Copilot}
                isOpened={showCreateNewExperimentWizardModal}
                onClose={() => { setShowCreateNewExperimentWizardModal(false); }}
            />}
    </div>
}

const isColumnCentered = (columnId: string) => {

    switch (columnId) {
        case "id": return true;
        case "status": return true;
        case "created": return true;

        default: return false;
    }
}

const columns: TableColumnDefinition<ExperimentResponseModel>[] = [
    createTableColumn<ExperimentResponseModel>({
        columnId: "id",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>ID</div>,
        renderCell: (item: ExperimentResponseModel) => {

            return <div style={{ textAlign: "center" }}>{item.id}</div>;

        }
    }),
    createTableColumn<ExperimentResponseModel>({
        columnId: "name",
        renderHeaderCell: () => <>Name</>,
        renderCell: (item: ExperimentResponseModel) => {
            return item.name;
        }
    }),
    createTableColumn<ExperimentResponseModel>({
        columnId: "file",
        renderHeaderCell: () => <>File</>,
        renderCell: (item: ExperimentResponseModel) => {

            return <FileDisplayName fileUniqueIdentifier={item.flowsheetUniqueId} fileVersionNumber={+item.flowsheetVersion} />;

        }
    }),
    createTableColumn<ExperimentResponseModel>({
        columnId: "status",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>Status</div>,
        renderCell: (item: ExperimentResponseModel) => {

            return <ExperimentStatusComponent status={item.processingStatus} />;

        }
    }),
    createTableColumn<ExperimentResponseModel>({
        columnId: "created",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>Created</div>,
        renderCell: (item: ExperimentResponseModel) => {

            return <div style={{ textAlign: "center" }}><ShowLocalTime date={item.createdAt} /></div>;
        }
    }),

];