import { Button, Checkbox, Field, Input, Table, TableCell, TableHeader, TableHeaderCell, TableRow, TableSelectionCell, useTableFeatures, useTableSelection } from "@fluentui/react-components";
import React from "react";
import { ComponentResponseModel, SeparationMethodOption } from "../../../../swagger-clients/ai-for-pfd-clients.service";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { ComponentModel } from "../../edit-experiment.models";
import { IExperimentValidation } from "../../edit-experiment.validation";
import { GLOBAL_STYLES } from "../../../../styles";
import { DwsimComponentPicker } from "../../../../components/dwsim-component-picker/dwsim-component-picker.component";
import { DismissRegular } from "@fluentui/react-icons";

type EntrainerDistillationComponentProps = {
    separationMethod?: SeparationMethodOption;
    components: ComponentModel[];
    onChange?: (components: ComponentModel[]) => void;
    isDetails?: boolean;
    isFormSubmitted?: boolean;
    experimentValidation?: IExperimentValidation;
}

export const EntrainerDistillationComponent: React.FC<EntrainerDistillationComponentProps> = (props) => {


    const onSeparationCheckBoxClick = (componentUniqueId: string) => {
        const separationComponentsCount = props.components?.filter(x => x.isSeparation && x.casNumber != componentUniqueId)?.length ?? 0;
        if (separationComponentsCount == 2) {
            return;
        }
        const updatedComponents = props.components.map((component) => {
            if (component.casNumber == componentUniqueId) {
                const isSeparation = !component.isSeparation;

                return { ...component, isSeparation: isSeparation, isEntrainer: false, targetValue: undefined } as ComponentModel;
            }
            return component;
        });
        // console.log("Updated components", updatedComponents);
        props.onChange(updatedComponents);
    }

    const onEntrainerCheckBoxClick = (componentUniqueId: string) => {
        const updatedComponents = props.components.map((component) => {
            if (component.casNumber == componentUniqueId) {
                const isEntrainer = !component.isEntrainer;
                return { ...component, isEntrainer: isEntrainer, isSeparation: false, targetValue: undefined } as ComponentModel;
            }
            return { ...component, isEntrainer: false };
        });
        // console.log("Updated components", updatedComponents);
        props.onChange(updatedComponents);
    }
    const onInputValueChange = (componentUniqueId: string, value: string) => {
        const updatedComponents = props.components.map((component) => {
            if (component.casNumber == componentUniqueId) {
                return { ...component, targetValue: value as any } as ComponentModel;
            }
            return component;
        });
        // console.log("Updated components", updatedComponents);
        props.onChange(updatedComponents);
    }
    const isNameValid = (componentCasNr?: string) => {
        if (!isFormSubmitted || !experimentValidation || experimentValidation.components.length == 0) return true;

        return !!componentCasNr;
    }

    const getNameErrorMessage = (componentCasNr: string) => {
        if (!isFormSubmitted || !experimentValidation || experimentValidation.components.length == 0 || componentCasNr) return undefined;
        return "Component is required.";
    }

    const isValueValid = (componentCasNr: string) => {
        if (!isFormSubmitted || !experimentValidation || experimentValidation.components.length == 0) return true;

        const separationComponents = props.components.filter(x => x.isSeparation || x.isEntrainer);
        const componentIdex = separationComponents?.findIndex(x => x.casNumber == componentCasNr);
        if (componentIdex === undefined || componentIdex < 0) {
            return true;
        }

        if (!experimentValidation.components[componentIdex])
            return true;

        return experimentValidation.components[componentIdex].targetValue.isValid;
    }

    const getValueErrorMessage = (componentCasNr: string) => {
        if (!isFormSubmitted || !experimentValidation || experimentValidation.components.length == 0) return undefined;

        const separationComponents = props.components.filter(x => x.isSeparation || x.isEntrainer);
        const componentIdex = separationComponents?.findIndex(x => x.casNumber == componentCasNr);
        if (componentIdex === undefined || componentIdex < 0) {
            return undefined;
        }
        if (!experimentValidation.components[componentIdex])
            return undefined;

        return experimentValidation.components[componentIdex].targetValue.validationErrors[0];

    }

    const onAddComponentClick = () => {
        props.onChange([...props.components, {}]);
    }

    const onComponentChange = (index: number, value?: ComponentResponseModel) => {
        const updatedComponents = props.components.map((comp, i2) => {
            if (i2 == index) {
                return { displayName: value.name, casNumber: value.casNr } as ComponentModel;
            }
            return comp;
        });

        props.onChange(updatedComponents);

    }


    const onRemoveComponent = (index: number) => {
        const updatedComponents = [...props.components];
        updatedComponents.splice(index, 1);
        props.onChange(updatedComponents);

    }
    const selectedComponentsCas = props.components.map(x => x.casNumber) ?? [];

    const { isFormSubmitted, experimentValidation } = props;


    return <div>

        <Table>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell key="id" className='table__cell--bold' style={{ width: "300px" }}>Name</TableHeaderCell>
                    <TableHeaderCell key="name" className='table__cell--bold column--center' style={{ width: "200px" }}>CAS-Nr.</TableHeaderCell>
                    <TableHeaderCell key="separation" className='table__cell--bold column--center' style={{ width: "150px" }}>Separation</TableHeaderCell>
                    <TableHeaderCell key="Entrainer" className='table__cell--bold column--center' style={{ width: "150px" }}>Entrainer</TableHeaderCell>
                    <TableHeaderCell key="value" className='table__cell--bold column--center' style={{ width: "250px" }}>Desired Purity</TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={false}
                columnCount={6} loadingMessage="Loading..."
                itemCount={props.components ? props.components.length : 0}
                noItemsMessage="No components found.">
                {props.components && props.components.length > 0 && props.components.map((item, index) => {
                    return <TableRow key={`experiment-${item.casNumber}-${index}`} >
                        <TableCell> {!item.isDefault && !props.isDetails &&
                            <Field
                                className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                                validationMessage={getNameErrorMessage(item.casNumber)}
                                validationState={!isNameValid(item.casNumber) ? "error" : "none"}
                            >

                                <DwsimComponentPicker
                                    value={item.casNumber}
                                    selectedValues={selectedComponentsCas}
                                    isDisabled={props.isDetails}
                                    onChange={(value) => onComponentChange(index, value)}
                                />
                            </Field>}
                            {(!!item.isDefault || !!props.isDetails ) && <>{item.displayName}</>}</TableCell>
                        <TableCell className="column--center">{item.casNumber}</TableCell>
                        <TableCell className="column--center">
                            {props.isDetails && <span>{item.isSeparation ? "YES" : "NO"}</span>}
                            {!props.isDetails && <Checkbox
                                key={`separation-${item.casNumber}-${!!item.isSeparation}`}
                                checked={!!item.isSeparation}
                                disabled={props.isDetails}
                                onClick={() => { onSeparationCheckBoxClick(item.casNumber) }}
                            />}
                        </TableCell>
                        <TableCell className="column--center">
                            {props.isDetails && <span>{item.isEntrainer ? "YES" : "NO"}</span>}
                            {!props.isDetails && <Checkbox
                                key={`Entrainer-${item.casNumber}-${!!item.isEntrainer}`}
                                checked={!!item.isEntrainer}
                                disabled={props.isDetails}
                                onClick={() => { onEntrainerCheckBoxClick(item.casNumber) }}
                            />}
                        </TableCell>
                        <TableCell className="column--center">
                            {props.isDetails && <span>{item.targetValue}</span>}
                            {!props.isDetails && <Field className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`} validationMessage={getValueErrorMessage(item.casNumber)}
                                validationState={!isValueValid(item.casNumber) ? "error" : "none"}>
                                <Input
                                    type="number"
                                    min={0}
                                    max={1}
                                    step={0.05}
                                    disabled={!(item.isSeparation)}
                                    value={item.targetValue?.toString() ?? ""}
                                    onChange={(ev, data) => { onInputValueChange(item.casNumber, data.value) }}

                                />
                            </Field>
                            }
                        </TableCell>
                        <TableCell> {!item.isDefault && !props.isDetails &&
                            <Button style={{ color: "red" }}
                                appearance="transparent"
                                icon={<DismissRegular />}
                                onClick={() => { onRemoveComponent(index) }}
                            ></Button>}</TableCell>
                    </TableRow>
                })}
            </TableBodyWithLoading>
        </Table>
        {!props.isDetails && <Button style={{ marginTop: "var(--spacingVerticalM)" }} onClick={onAddComponentClick}>Add component</Button>}
    </div>
}