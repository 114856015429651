import { GroupTableResultModel } from "../swagger-clients/s365-dashboard-v2-api-clients.service";
import { getGroupsClient } from "./dashboard.service";


class UserGroupsService {
    private static __requestsCache: { [key: string]: Promise<GroupTableResultModel[]> } = {};

    public static getUserOwnedGroups() {
        // Create Promise for loading data, it it doesn't exist already
        let cacheKey = `user-groups`;
        if (UserGroupsService.__requestsCache[cacheKey] === undefined) {
            const client = getGroupsClient();

            UserGroupsService.__requestsCache[cacheKey] = client.getUserOwnedGroups();
        }

        return UserGroupsService.__requestsCache[cacheKey];
    }
}

export default UserGroupsService;