import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell } from "@fluentui/react-components";
import { ArrowDownloadRegular, Dismiss24Filled } from "@fluentui/react-icons";
import saveAs from "file-saver";
import React from "react";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { getFilesClient } from "../../services/dashboard.service";
import { GLOBAL_STYLES } from "../../styles";
import { FileVersionResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { FileTableItem } from "../models/file-table.models";

type FileHistoryModalProps = {
    file: FileTableItem;
    isOpened: boolean;
    onClose: () => void;
}

export const FileHistoryModal: React.FC<FileHistoryModalProps> = (props) => {

    const [fileVersions, setFileVersions] = React.useState<FileVersionResponseModel[]>([]);
    const [isLoading, loadingService] = useLoading();


    React.useEffect(() => { getFileVersions(); }, []);

    const getFileVersions = async () => {
        loadingService.showLoading("Getting file versions...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                const fileVersionsResp = await client.getFileVersions(props.file.uniqueIdentifier);
                setFileVersions(fileVersionsResp ?? []);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting file versions.");

            } finally {
                hideMessage();
            }
        });
    }

    const onDownloadFileVersionClick = async (fileVersion: FileVersionResponseModel) => {
        loadingService.showLoading("Downloading file...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                const fileResp = await client.downloadFile(props.file.uniqueIdentifier, fileVersion.version, null);
                saveAs(fileResp.data, props.file.name);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while downloading file with specific version.");
            } finally {
                hideMessage();
            }


        });
    }

    const onModalClose = () => {
        setOpen(false);

        if (props.onClose)
            props.onClose();
    }

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const [open, setOpen] = React.useState(false);

    const latestVersion = fileVersions?.[0]?.version ?? undefined;

    return (<Dialog  open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (data.open == false) {
            if (props.onClose)
                props.onClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "85vw" }}>
            <DialogBody style={{ height: "80vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>File history</DialogTitle>
                <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                    <div>
                        {!!latestVersion && <FileDisplayName fileUniqueIdentifier={props.file.uniqueIdentifier} fileVersionNumber={latestVersion} />}
                        <Table style={{ marginTop: "var(--spacingVerticalMNudge)" }}>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell key="name" style={{ width: "100px" }} className='s365-table__cell--bold column--center'>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <span>Version</span>
                                            <span>number</span>
                                        </div></TableHeaderCell>
                                    <TableHeaderCell key="created-at" className='s365-table__cell--bold column--center'>Modified</TableHeaderCell>
                                    <TableHeaderCell key="comment" className='s365-table__cell--bold column--center'>Comment</TableHeaderCell>
                                    <TableHeaderCell key="actions" className='s365-table__cell--bold'></TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBodyWithLoading isLoading={isLoading}
                                columnCount={3} loadingMessage="Loading..."
                                itemCount={fileVersions ? fileVersions.length : 0}
                                noItemsMessage="No items found.">
                                {fileVersions && fileVersions.length > 0 && fileVersions.map((item, index) => {
                                    return <TableRow key={`file-version-${item.id}`}>
                                        <TableCell className="column--center"><span>{item.version}</span></TableCell>
                                        <TableCell className="column--center"><ShowLocalTime date={item.createdAt} /></TableCell>
                                        <TableCell className="column--center">{item.comment}</TableCell>
                                        <TableCell style={{ textAlign: "end" }}><Button icon={<ArrowDownloadRegular />} disabled={isLoading} onClick={() => { onDownloadFileVersionClick(item) }}>Download</Button></TableCell>
                                    </TableRow>
                                })}
                            </TableBodyWithLoading>
                        </Table>
                    </div>

                </DialogContent>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}