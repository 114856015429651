import { Button, Label } from "@fluentui/react-components";
import { Dismiss20Regular } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React, { useState } from "react";
import { EditableTextAreaField } from "../../components/editable-textarea-field/editable-textarea-field.component";
import { getFilesClient } from "../../services/dashboard.service";
import { FileSystemEntityType, UpdateCommentPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingService } from "../../utils/loading-indicator.component";
import { CopyUniqueIdentifier } from "../copy-unique-identifier/copy-unique-identifier.component";
import { EditComment } from "../edit-comment/edit-comment.component";
import { FileTypeIcon } from "../file-type-icon/file-type-icon.component";
import { getFileExtension } from "../file-type-icon/file-type-icon.helpers";
import { FileTableItem } from "../models/file-table.models";
import { TagOption, TagPicker } from "../tag-picker/tag-picker.component";
import { ThumbnailImage } from "../thumbnail/thumbnail-image/thumbnail-image.component";
import { ThumbnailModal } from "../thumbnail/thumbnail-modal/thumbnail-modal.component";
import { flowsheetExtensions } from "../../global.variables";

type SidePanelProps = {
    isOpened: boolean;
    selectedItem: FileTableItem;
    fileTagDefaultOptions?: TagOption[];
    hubConnection: HubConnection;
    loadingService: LoadingService;
    readOnly?: boolean,
    hideCopyUniqueIdentifier?: boolean;
    onHide: () => void;
    onReloadFilesEvent: () => void;

}

export const SidePanel: React.FC<SidePanelProps> = (props) => {
    const [showThumbnailModal, setShowThumbnailModal] = useState<boolean>(false);

    const { selectedItem, fileTagDefaultOptions, loadingService, isOpened } = props;

    const showThumbnail = (file: FileTableItem) => {
        const extension = getFileExtension(file.name!);

        return extension && flowsheetExtensions.indexOf(extension) > -1;
    }



    //console.log("Editable text area props", props)

    return <>
        {isOpened && <div className='side-panel'>
            <div className="header">
                <div className='file'>
                    <FileTypeIcon filename={selectedItem.name!} isFolder={selectedItem.type == FileSystemEntityType.Directory} className='icon' />
                    <span className='title'>{selectedItem.name!}</span>
                </div>
                <Button
                    className='close-btn'
                    appearance='transparent'
                    icon={<Dismiss20Regular />}
                    size='small'
                    onClick={() => props.onHide()}></Button>
            </div>
            {selectedItem && selectedItem.type == FileSystemEntityType.File && !props.hideCopyUniqueIdentifier && <div className="panel-item">
                <CopyUniqueIdentifier file={selectedItem} />
            </div>}
            <div className="panel-item">
                <div className='tags' >
                    <div style={{ marginBottom: "var(--spacingVerticalMNudge)" }}>
                        <Label weight='semibold'>Tags</Label>
                    </div>
                    <div style={{ cursor: props.readOnly ? "not-allowed" : undefined }}>
                        <TagPicker
                            hasBoarder
                            hasRemove
                            disabled={props.readOnly}
                            placeholder={!props.readOnly ? 'Enter tags...' : ""}
                            id={selectedItem.id!}
                            fileType={selectedItem.type!}
                            fileUniqueIdentifier={selectedItem.uniqueIdentifier}
                            tags={selectedItem.tags}
                            defaultFileOptions={fileTagDefaultOptions}
                            styles={{ valueContainer: (provided) => { return { ...provided, backgroundColor: "white" } } }}
                            onTagAdded={() => { props.onReloadFilesEvent(); }}
                            onTagRemoved={() => { props.onReloadFilesEvent(); }}
                        />
                    </div>

                </div>
            </div>
            {showThumbnail(selectedItem) && props.hubConnection && <div className="panel-item">
                <Label weight='semibold'>Thumbnail</Label>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                    <ThumbnailImage
                        fileId={selectedItem.id!}
                        fileUniqueIdentifier={selectedItem.uniqueIdentifier!}
                        fileVersionNumber={selectedItem.currentVersionNumber!}
                        fileExtension={selectedItem ? getFileExtension(selectedItem.name) : ""}
                        hubConnection={props.hubConnection}
                        onClick={() => { setShowThumbnailModal(true) }} />
                </div>

            </div>}

            <div className="panel-item">
                <div style={{ marginBottom: "var(--spacingVerticalMNudge)" }}>
                    <Label>Comment</Label>
                </div>
                <EditComment
                    key="side-panel-comment"
                    file={selectedItem}
                    rows={5}
                    disabled={props.readOnly}
                    loadingService={props.loadingService}
                    onReloadFilesEvent={() => { props.onReloadFilesEvent() }}
                />
            </div>


        </div>
        }
        {selectedItem && showThumbnailModal && <ThumbnailModal
            file={selectedItem}
            hubConnection={props.hubConnection!}
            isOpened={showThumbnailModal}
            onClose={() => setShowThumbnailModal(false)}
        />}
    </>
}