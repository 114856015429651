import { Spinner } from "@fluentui/react-components";
import React from "react";
import { getSensitivityStudiesClient } from "../../../services/sensitivity-studies.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { GraphResult, ParameterInfo, SensitivityStudyResultModel } from "../../../swagger-clients/sensitivity-studies-api-clients.service";
import Plot from 'react-plotly.js';
import { IPlotly, IPlotlyData } from "../../../utils/shared.types";

type OptimumGraphsTabProps = {
    selectedStudy: SensitivityStudyResultModel;
}


export type OptimumGraphsTabType = {
    getGraphData(): void
}

export const OptimumGraphsTab = React.forwardRef<OptimumGraphsTabType, OptimumGraphsTabProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            getGraphData() {
                getGraphData();
            }
        }));

    const [charts, setCharts] = React.useState<IPlotly[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    React.useEffect(() => {

        getGraphData();
    }, []);

    const getGraphData = async () => {
        try {
            setIsLoading(true);
            const client = getSensitivityStudiesClient();
            const data = await client.getGraphData(props.selectedStudy.id, props.selectedStudy.currentVersionId);
            const mappedCharts = mapGraphResultToIPlotly(data);
            setCharts(mappedCharts);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while loading graph data.");

        } finally {
            setIsLoading(false);
        }
    }

    const getParamterName = (parameterInfo: ParameterInfo) => {
        let title = '';
        if (parameterInfo.unitOperation) {
            title = title + `U:${parameterInfo.unitOperation}`;
        }
        if (parameterInfo.stream) {
            title = title + ` S:${parameterInfo.stream}`;
        }
        if (parameterInfo.component) {
            title = title + ` C:${parameterInfo.component}`;
        }
        if (parameterInfo.parameterName) {
            title = title + ` P:${parameterInfo.parameterName}`;
        }
        if (parameterInfo.parameterUnit) {
            title = title + ` (${parameterInfo.parameterUnit})`;
        }
        return title;
    }

    const mapGraphResultToIPlotly = (data: GraphResult[]): IPlotly[] => {
        let result: IPlotly[] = [];


        result = data.map(graphData => {
            const title = getParamterName(graphData.parameterInfo);
            let plot: IPlotly = {
                data: [
                    {
                        x: graphData.data.map(x => x.x),
                        y: graphData.data.map(x => x.y),
                        type: 'scatter',
                        mode: 'lines+markers',
                        marker: { color: 'red' },
                        name: "All values"
                    }
                ],
                layout: {
                    autosize: true,
                    title: title
                }
            };
            //draw initial value scatter larger than other values
            const initialData = graphData.data.filter(x => x.isInitialValue == true);
            if (initialData && initialData.length > 0) {
                var initialChartData = {
                    x: [initialData[0].x],
                    y: [initialData[0].y],
                    type: 'scatter',
                    mode: 'markers',
                    marker: { color: 'blue', size: 12 },
                    name: "Initial value"

                } as IPlotlyData;


                plot.data.push(initialChartData);
            }


            return plot;
        });

        return result;
    }

    return <div>
        {isLoading && <Spinner size="large" />}
        {!isLoading && <div className="graphs" style={{ display: "flex", flexDirection: "column" }}>
            {
                charts.map(chart => {
                    return <Plot data={chart.data} layout={chart.layout} />
                })
            }
        </div>
        }
    </div>

})