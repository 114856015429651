import React from "react";
import { CalculationRunJobStatus } from "../../../swagger-clients/ai-for-pfd-clients.service";

type CalculationJobStatusProps = {
    status: CalculationRunJobStatus;
}

const getStatusTextAndColor = (status: CalculationRunJobStatus): string[] => {
    switch (status) {
        case CalculationRunJobStatus.Calculated: // Green
            return ['Converged', 'ForestGreen'];
        case CalculationRunJobStatus.Failed: // Red
            return ['Not Converged', 'DarkRed'];

        default:
            return [null, null];
    }
}

export const CalculationJobStatusComponent: React.FC<CalculationJobStatusProps> = (props) => {
    const [text, color] = getStatusTextAndColor(props.status);

    // Set color only if defined
    let styles: React.CSSProperties = {};
    if (!!color) {
        styles.color = color;
    }

    return <span style={styles}>{text}</span>;

}

