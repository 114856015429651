import { ChevronRightRegular, FolderRegular } from '@fluentui/react-icons';
import classNames from 'classnames';
import React from 'react';
import './breadcrumbs.scss';

type BreadcrumbsProps = {

    children?: JSX.Element[] | JSX.Element | any,
    className?: string
}

function Breadcrumbs(props: BreadcrumbsProps) {
    return (
        <div className={classNames('breadcrumbs', props.className)}>
            {props.children}
        </div>
    );
}

type BreadcrumbItemProps = {
    onClick?: () => void,
    active?: boolean,
    children?: string | JSX.Element,
    showIcon?: boolean
}

function BreadcrumbItem(props: BreadcrumbItemProps) {
    return <>
        <div className={classNames('breadcrumbs__item', { 'breadcrumbs__item--active': props.active, 'breadcrumbs__item--clickable': !props.active })}
            onClick={props.onClick}>{props.showIcon && <FolderRegular className='breadcrumbs__item-icon' style={{ marginRight: 'var(--spacingHorizontalXS)' }} />}{props.children}</div>
        <ChevronRightRegular className='breadcrumbs__chevron' />
    </>;
}



export { Breadcrumbs, BreadcrumbItem };