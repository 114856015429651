import React from "react";

type DataGridScrollableWrapperProps = {
    gridHeight?: string;
    columnsCount: number,
    columnWidth?: number,
    children: JSX.Element
}

export const DataGridScrollableWrapper = ({ columnsCount, columnWidth, gridHeight, children }: DataGridScrollableWrapperProps) => {
    const height = gridHeight ?? "90vh";
    return <div style={{ width: '100%', willChange: 'transform', overflowX: 'auto', height: height }}>
        <div style={{ width: `${columnsCount * (columnWidth || 100)}px` }}>{children}</div>
    </div>
}

export const DataGridScrollableHeaderStyles: React.CSSProperties = {
    position: 'sticky',
    top: '0px',
    background: 'var(--colorNeutralBackground2)',
    zIndex: 1000
}