import { Button, Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger, SelectionItemId, Spinner, TableColumnDefinition, TableColumnSizingOptions, TableSelectionCell, createTableColumn, useTableColumnSizing_unstable, useTableFeatures, useTableSelection } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from "@fluentui/react-components";
import { ArrowDownloadRegular, ChartPersonRegular, CopyRegular, DataScatterRegular, DataTrendingRegular, DeleteRegular, DocumentDataRegular, DocumentTextRegular, FolderArrowRightRegular, HistoryRegular, MailRegular, MoreVerticalRegular, OpenRegular, PeopleAddRegular, PulseRegular, RenameRegular, Star20Filled, StarAddRegular, StarDismissRegular, TaskListSquareAddRegular } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadFilesClient, getFilesClient, getUserToken } from "../../services/dashboard.service";
import { FileSystemEntityType, ToogleStaredPostModel, FileSortFields, ShareDefinitionSourceType, DownloadFilesPostModel, DownloadItemModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { AnalysisStatusIcon } from "../analysis-status/analysis-status-icon.component";
import { ContextMenu, ContextMenuType } from "../context-menu/context-menu.component";
import { FileTypeIcon } from "../file-type-icon/file-type-icon.component";
import { getFileExtension } from "../file-type-icon/file-type-icon.helpers";
import { FileTableColumn, FileTableItem } from "../models/file-table.models";
import { RenameFileModal } from "../rename-file-modal/rename-file-modal.component";
import { TagOption, TagPicker } from "../tag-picker/tag-picker.component";
import { saveAs } from "file-saver";
import { LoadingService } from "../../utils/loading-indicator.component";
import "./files-table.styless.scss";
import { DeleteFileModal } from "../delete-file-modal/delete-file-modal.component";
import { ExamsCountIcon, ExperimentCountIcon, FiltersCountIcon, OptimumTestsCountIcon, ScenariosCountIcon, SensitivityStudiesCountIcon } from "../../pages/FilesList/filters-count-icon";
import { dwsimExtensions, excelExtensions, flowsheetExtensions } from "../../global.variables";
import { MoveFileDialog } from "../move-file-dialog/move-file-dialog.component";
import { FileHistoryModal } from "../file-history-modal/file-history-modal.component";
import { EditComment } from "../edit-comment/edit-comment.component";
import OpenInS365AppModal from "../open-in-s365-app-modal/open-in-s365-app-modal";
import { featureFlagsSettings, generalSettings, settings } from "../../App";
import { ContactFormModal } from "../../components/contact-form-modal/contact-form-modal.component";
import { toast } from "react-toastify";
import { ShareFileModal } from "../share/share-file-modal.component";
import { CopyFileDialog } from "../copy-file-dialog/copy-file-dialog.component";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { UrlSettings } from "../../swagger-clients/s365-dashboard-v2-web-clients.service";
import { DwsimProLicenseCheckerModal } from "../../components/dwsim-pro-license-checker-modal/dwsim-pro-license-checker-modal.component";

import DwsimProIcon from "../../assets/images/DWSIM-Pro-Icon.png";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { DownloadFilesDialog } from "../download-files-dialog/download-files-dialog.component";


export type FilesTableSort = {
    field: FileSortFields,
    isDescending: boolean
}

type FilesTableProps = {
    files?: FileTableItem[];
    isMultiSelect?: boolean;
    hubConnection?: HubConnection;
    additionalColumns?: FileTableColumn[];
    showDeleteOption?: boolean;
    showShareOption?: boolean;
    showManageVersions?: boolean;
    isLoading?: boolean;
    loadingService: LoadingService;
    hideModifiedColumn?: boolean;
    hideAnalyzedColumn?: boolean;
    hideAnalyticsColumn?: boolean;
    hideTagsColumn?: boolean;
    hideContextMenu?: boolean;
    readonly?: boolean;
    disableNavigation?: boolean;
    disableSubmitForAnalysis?: boolean;
    disableOpenFile?: boolean;
    sortable?: boolean;
    sortBy?: FilesTableSort;
    onSortChange?: (data: FilesTableSort) => void;
    // used for router navigation, default: files

    // loading row 
    showLoadingRow?: boolean;

    basePagePath?: string;
    onNavigateTo?: (folder: FileTableItem) => void;
    onShowDetails: (item: FileTableItem) => void,
    contextMenuItems?: JSX.Element[],
    // needed for context menu
    scrollableElement?: React.MutableRefObject<HTMLElement>,
    onSelectedItemChanged: (item: FileTableItem | undefined) => void,
    onReloadFilesEvent: () => void,
    onShowTagInputsChanged: (showTagInputs: { [key: string]: boolean }) => void,
}

export const FilesTable: React.FC<FilesTableProps> = (props) => {

    const contextMenuRef = React.createRef<ContextMenuType>();

    // const [selectedRows, setSelectedRows] = React.useState(() => new Set<SelectionItemId>([]));
    const [fileTagDefaultOptions, setFileTagDefaultOptions] = React.useState<TagOption[]>([]);
    const [showRenameFileModal, setShowRenameFileModal] = React.useState<boolean>(false);
    const [showDeleteFileModal, setShowDeleteFileModal] = React.useState<boolean>(false);
    const [showMoveFileModal, setShowMoveFileModal] = React.useState<boolean>(false);
    const [showCopyFileModal, setShowCopyFileModal] = React.useState<boolean>(false);
    const [showManageVersionsModal, setShowManageVersionsModal] = React.useState<boolean>(false);
    const [showOpenInS365AppModal, setShowOpenInS365AppModal] = React.useState<boolean>(false);
    const [showSendToSupportModal, setShowSendToSupportModal] = React.useState<boolean>(false);
    const [showShareModal, setShowShareModal] = React.useState<boolean>(false);
    const [showDwsimProLicenseCheckerModal, setShowDwsimProLicenseCheckerModal] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const basePagePath = props.basePagePath ?? "/files";
    const [sortBy, setSortBy] = React.useState<FilesTableSort>(props.sortBy);

    const [downloadFilesRequestId, setDownloadFilesRequestId] = React.useState<string>();
    const [showDownloadFileProgressDialog, setShowDownloadFilesProgressDialog] = React.useState<boolean>(false);
    const items = props.files ? props.files : [];
    const [columns, setColumns] = React.useState<TableColumnDefinition<FileTableItem>[]>([]);


    const getAllColumns = () => {
        let columnsLocal: TableColumnDefinition<FileTableItem>[] = [];

        if (props.isMultiSelect) {
            columnsLocal.push(createTableColumn<FileTableItem>({
                columnId: "checkbox",
                renderHeaderCell: () => {
                    return <TableSelectionCell
                        checked={
                            allRowsSelected ? true : someRowsSelected ? "mixed" : false
                        }
                        onClick={(ev) => toggleAllRows(ev)}
                        checkboxIndicator={{ "aria-label": "Select all rows " }}
                    />
                }
            }));
        }

        columnsLocal.push(...[createTableColumn<FileTableItem>({
            columnId: "fileIcon",
            renderHeaderCell: () => <></>,
        }),
        createTableColumn<FileTableItem>({
            columnId: "name",
            renderHeaderCell: () => <>Name</>
        })]);


        if (!props.hideAnalyzedColumn) {
            columnsLocal.push(createTableColumn<FileTableItem>({
                columnId: "analyzed",
                renderHeaderCell: () => <>Analyzed</>,

            }));
        }
        if (!props.hideAnalyticsColumn) {
            columnsLocal.push(createTableColumn<FileTableItem>({
                columnId: "analytics",
                renderHeaderCell: () => <>Analytics</>,
            }));
        }

        if (!props.hideTagsColumn) {
            columnsLocal.push(createTableColumn<FileTableItem>({
                columnId: "tags",
                renderHeaderCell: () => <>Tags</>,
            }));
        }

        columnsLocal.push(createTableColumn<FileTableItem>({
            columnId: "comment",
            renderHeaderCell: () => <>Comment</>,
        }));


        if (!props.hideModifiedColumn) {
            columnsLocal.push(createTableColumn<FileTableItem>({
                columnId: "modifiedAt",
                renderHeaderCell: () => <>Modified</>,
            }));
        }

        if (!!props.additionalColumns && props.additionalColumns.length > 0) {
            const additionalColumns = props.additionalColumns.map(coldef => coldef.column);
            columnsLocal.push(...additionalColumns);
        }


        return columnsLocal;
    }




    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        checkbox: {
            idealWidth: 30,
            minWidth: 30,
        },
        fileIcon: {
            idealWidth: 30,
            minWidth: 30,
        },
        name: {
            minWidth: 100,
            defaultWidth: 400,
        },
        analyzed: {
            minWidth: 100,
            defaultWidth: 100,
        },
        analytics: {
            minWidth: 150,
            defaultWidth: 150,
        },
        tags: {
            minWidth: 100,
            defaultWidth: 300
        },
        comment: {
            minWidth: 100,
            defaultWidth: 300
        },
        modifiedAt: {
            minWidth: 100,
            defaultWidth: 100,
        },
        location: {
            minWidth: 150,
            defaultWidth: 300
        },
        shared: {
            minWidth: 100,
            defaultWidth: 150,
            idealWidth: 150
        },
        sharedBy: {
            minWidth: 200,
            defaultWidth: 300
        }

    });




    const {
        getRows,
        columnSizing_unstable,
        tableRef,
        selection: {
            toggleRow,
            selectRow,
            clearRows,
            isRowSelected,
            allRowsSelected,
            someRowsSelected,
            toggleAllRows,
            selectedRows
        },
    } = useTableFeatures(
        {
            columns,
            items,
            getRowId: (item: any) => (item.uniqueIdentifier)
        },
        [useTableColumnSizing_unstable({ columnSizingOptions }),
        useTableSelection({
            selectionMode: props.isMultiSelect ? "multiselect" : "single"
        })
        ]
    );
    React.useEffect(() => {
        const columnsLocal = getAllColumns();
        setColumns(columnsLocal);
    }, [selectedRows.size, props.additionalColumns]);

    const getFirstSelectedItemId = () => {
        return selectedRows.values().next().value;
    }

    const getItemById = (itemId: string) => {
        return props.files.find(x => x.uniqueIdentifier == itemId);
    }
    const getSelectedSingleItem = () => {
        const selectedItemId = !!selectedRows && selectedRows.size == 1 ? getFirstSelectedItemId() : undefined;
        if (!selectedItemId) {
            return undefined;
        }
        return getItemById(selectedItemId);
    }

    //Single-Double click diferentiation
    let timer = 0;
    const delay = 250;
    let prevent = false;

    React.useEffect(() => {

        const selectedItem = getSelectedSingleItem();
        if (selectedItem) {
            props.onSelectedItemChanged(selectedItem);
        }
    }, [selectedRows.size]);

    const setSelectedItem = (item: FileTableItem) => {

        const selected = isRowSelected(item.uniqueIdentifier);
        if (!selected) {
            clearRows({} as any);
            setTimeout(() => { selectRow(null, item.uniqueIdentifier); }, 100);
        }

    }
    const onSelectRowCheckboxClick = (item: FileTableItem) => {
        toggleRow(null, item.uniqueIdentifier);
    }



    const onSingleRowClick = (item: FileTableItem) => {
        //  console.log("Single click", item);

        timer = +setTimeout(() => {
            if (!prevent) {
                setSelectedItem(item);
            }
        }, delay);
        //  console.log("New timer", timer);

    }

    const onDoubleRowClick = (item: FileTableItem) => {
        console.log("Double click", timer);
        clearTimeout(timer);
        prevent = true;



        if (item.type == FileSystemEntityType.File) {
            const fileExtension = getFileExtension(item.name!);
            if (dwsimExtensions.indexOf(fileExtension ?? "") > -1) {
                setSelectedItem(item);
                if (!props.disableOpenFile)
                    setShowDwsimProLicenseCheckerModal(true);
            }
        } else {
            setTimeout(() => { clearRows(null); }, 500);
        }

        //  setShowDetails(false);
        if (!props.disableNavigation) {
            onNavigateTo(item);
        }

        setTimeout(() => {
            prevent = false;
        }, delay);

    }

    const onNavigateTo = (file: FileTableItem) => {
        if (file.type == FileSystemEntityType.Directory) {

            if (!!props.onNavigateTo) {
                props.onNavigateTo(file);
                return;
            }
            console.log("onNavigateTo called.");
            clearRows(null);
            navigate(`${basePagePath}/${file.uniqueIdentifier}`);
        }

    }

    const onDownloadFile = async () => {
        const selectedItem = getSelectedSingleItem();
        if (selectedItem) {
            try {
                const client = getFilesClient();
                var response = await client.downloadFile(selectedItem.uniqueIdentifier!, selectedItem.currentVersionNumber, selectedItem.name);

                saveAs(response.data, selectedItem.name);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while downloading file.");
            }
        }
    }

    const onDownloadFolderClick = async () => {
        try {
            const client = getDownloadFilesClient();
            const selectedItem = getSelectedSingleItem();
            const model = new DownloadFilesPostModel({
                items: [new DownloadItemModel({ uniqueIdentifier: selectedItem.uniqueIdentifier, isFolder: true })]
            });
            const downloadFilesResp = await client.submitRequestToDownloadFiles(model);
            if (!!downloadFilesResp && !!downloadFilesResp.requestId) {
                setDownloadFilesRequestId(downloadFilesResp.requestId);
                setShowDownloadFilesProgressDialog(true);
            }
        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to download folder.");
        }
    }

    const onDownloadMultipleFilesClick = async () => {
        try {
            const client = getDownloadFilesClient();
            const selectedUniqueIds = [...selectedRows];
            const selectedItems = props.files.filter(x => selectedUniqueIds.indexOf(x.uniqueIdentifier) > -1);
            const model = new DownloadFilesPostModel({
                items: selectedItems.map(x => new DownloadItemModel({ uniqueIdentifier: x.uniqueIdentifier, isFolder: x.type == FileSystemEntityType.Directory }))
            });
            const downloadFilesResp = await client.submitRequestToDownloadFiles(model);
            if (!!downloadFilesResp && !!downloadFilesResp.requestId) {
                setDownloadFilesRequestId(downloadFilesResp.requestId);
                setShowDownloadFilesProgressDialog(true);
            }
        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to download folder.");
        }
    }

    const onToogleStared = async () => {
        const selectedItem = getSelectedSingleItem();
        if (!selectedItem)
            return;
        props.loadingService.showLoading("Changing star status...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                await client.toogleFileStared(selectedItem.uniqueIdentifier, { fileType: selectedItem.type } as ToogleStaredPostModel);
                props.onReloadFilesEvent();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while changing starred status.");
            } finally {
                hideMessage();
            }

        });
    }
    const onOpenInBrowserClick = async () => {
        const userToken = await getUserToken();
        const selectedItem = getSelectedSingleItem();
        window.open(`${settings?.dashboardServiceUrl}/api/files/${selectedItem.uniqueIdentifier}/versions/${selectedItem.currentVersionNumber}/download/${encodeURIComponent(selectedItem.name)}?access_token=${userToken}`, '_blank');
    }

    const onOpenInDwsimProWebClick = () => {
        const selectedItem = getSelectedSingleItem();
        if (!selectedItem) return;

        window.open(`${settings.dwsimProWebSiteUrl}/files/${selectedItem.uniqueIdentifier}`, '_blank');
    }

    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        if (props.contextMenuItems) {
            return props.contextMenuItems;
        }
        // multiple items selected
        if (props.isMultiSelect && selectedRows.size > 1) {
            return [<MenuItem key="download" icon={<ArrowDownloadRegular />} onClick={() => onDownloadMultipleFilesClick()}>Download</MenuItem >];
        }

        const selectedItem = getSelectedSingleItem();
        //  console.log("getContextItems", selectedItem);
        if (!selectedItem)
            return [];

        if (selectedItem.type == FileSystemEntityType.File) {
            if (!!featureFlagsSettings.openLocalFile) {
                menuItems.push(<MenuItem key="open" icon={<OpenRegular />} onClick={() => setShowOpenInS365AppModal(true)}> Open in App</MenuItem >);
            }
            if (getFileExtension(selectedItem.name) === "pdf") {
                menuItems.push(<MenuItem key="open" icon={<OpenRegular />} onClick={() => onOpenInBrowserClick()}> Open in Browser</MenuItem >);
            }
        }

        if (selectedItem.type == FileSystemEntityType.File) {
            const fileExtension = getFileExtension(selectedItem.name!);
            if (dwsimExtensions.indexOf(fileExtension ?? "") > -1) {
                menuItems.push(<MenuItem key="open" icon={<img src={DwsimProIcon} />} onClick={() => setShowDwsimProLicenseCheckerModal(true)}> Open in DWSIM Pro</MenuItem >);
            }

            if (!!featureFlagsSettings.openInDwsimProWeb) {

                if (dwsimExtensions.indexOf(fileExtension ?? "") > -1) {
                    menuItems.push(<MenuItem key="open" icon={<OpenRegular />} onClick={() => onOpenInDwsimProWebClick()}> Open in DWSIM Pro web</MenuItem >);
                }
            }
        }


        if (props.showShareOption) {
            menuItems.push(
                <MenuItem key="share" icon={<PeopleAddRegular />} onClick={() => { setShowShareModal(true) }}> Share</MenuItem>
            );
        }

        menuItems.push(
            <MenuItem key="toogle-star" icon={!selectedItem.stared ? <StarAddRegular /> : <StarDismissRegular />} onClick={() => onToogleStared()}> {selectedItem.stared ? "Remove from Starred" : "Add to Starred"}</MenuItem>
        );

        menuItems.push(<MenuItem key="details" icon={<DocumentTextRegular />} onClick={() => props.onShowDetails(selectedItem)}> Details</MenuItem >);

        if (selectedItem.type == FileSystemEntityType.Directory) {
            menuItems.push(...[
                <MenuItem key="copy" icon={<CopyRegular />} onClick={() => setShowCopyFileModal(true)}> Make a copy</MenuItem >,
                <MenuItem key="move" icon={<FolderArrowRightRegular />} onClick={() => setShowMoveFileModal(true)}> Move to</MenuItem >,
                <MenuItem key="rename" icon={<RenameRegular />} onClick={() => setShowRenameFileModal(true)}> Rename</MenuItem >,
                <MenuItem key="download" icon={<ArrowDownloadRegular />} onClick={() => onDownloadFolderClick()}>Download</MenuItem >]);

            if (props.showDeleteOption) {
                menuItems.push(<MenuItem key="delete" icon={<DeleteRegular />} onClick={() => setShowDeleteFileModal(true)}>Remove</MenuItem >);
            }
        }

        if (selectedItem.type == FileSystemEntityType.File) {

            const fileExtension = getFileExtension(selectedItem.name!);

            if (flowsheetExtensions.indexOf(fileExtension ?? "") > -1) {
                menuItems.push(<MenuItem key="send-support-ticket" icon={<MailRegular />} onClick={() => setShowSendToSupportModal(true)}>Send to support</MenuItem >);
            }
            menuItems.push(<MenuItem key="manage-versions" icon={<HistoryRegular />} onClick={() => setShowManageVersionsModal(true)}>File history</MenuItem >);

            menuItems.push(<MenuDivider />);



            menuItems.push(...[
                <MenuItem key="copy" icon={<CopyRegular />} onClick={() => setShowCopyFileModal(true)} > Make a copy</MenuItem >,
                <MenuItem key="move" icon={<FolderArrowRightRegular />} onClick={() => setShowMoveFileModal(true)}> Move to</MenuItem >,
                <MenuItem key="rename" icon={<RenameRegular />} onClick={() => setShowRenameFileModal(true)}> Rename</MenuItem >]);

            menuItems.push(<MenuItem key="download" icon={<ArrowDownloadRegular />} onClick={onDownloadFile}> Download</MenuItem >);

            if (props.showDeleteOption) {
                menuItems.push(<MenuItem key="delete" icon={<DeleteRegular />} onClick={() => setShowDeleteFileModal(true)}>Remove</MenuItem >);
            }





            if (flowsheetExtensions.indexOf(fileExtension ?? "") > -1 || fileExtension?.indexOf("xlsx") > -1) {
                menuItems.push(<MenuDivider />);
            }

            if (flowsheetExtensions.indexOf(fileExtension ?? "") > -1) {
                menuItems.push(<MenuItem key="filter" icon={<TaskListSquareAddRegular />} onClick={() => navigate(`/files/${selectedItem.uniqueIdentifier}/filters`)}> Filter</MenuItem >);
                menuItems.push(<MenuItem key="doe" icon={<DocumentDataRegular />} onClick={() => navigate(`/files/${selectedItem.uniqueIdentifier}/doe`)}> Design of Experiments</MenuItem >);

                menuItems.push(<MenuItem key="sensitivity-study" icon={<DataScatterRegular />} onClick={() => navigate(`/files/${selectedItem.uniqueIdentifier}/sensitivity-studies`)}> Multivariate Sensitivity Study</MenuItem >);
                menuItems.push(<MenuItem key="optimum-tester" icon={<DataTrendingRegular />} onClick={() => navigate(`/files/${selectedItem.uniqueIdentifier}/optimum-tester`)}> Optimum Test</MenuItem >);

                if (!!featureFlagsSettings.showCopilot) {
                    menuItems.push(<MenuItem key="copilot" icon={<PulseRegular />} onClick={() => navigate(`/files/${selectedItem.uniqueIdentifier}/copilot`)}> Flowsheet Copilot</MenuItem >);
                }
            }

            if (fileExtension?.indexOf("xlsx") > -1) {
                menuItems.push(<MenuItem key="doe" icon={<DocumentDataRegular />} onClick={() => navigate(`/files/${selectedItem.uniqueIdentifier}/doe-xls`)}> Design of Experiments</MenuItem >);
                menuItems.push(<MenuItem key="exams" icon={<ChartPersonRegular />} onClick={() => navigate(`/files/${selectedItem.uniqueIdentifier}/exams`)}> Exams</MenuItem >);
            }
        }

        return menuItems;
    }

    const getSortDirection = (columnId: string) => {
        const field = getSortFieldByColumnId(columnId);
        if (field === undefined || !props.sortable || !sortBy || sortBy.field != field)
            return undefined;
        if (sortBy.isDescending)
            return "descending";
        else return "ascending";
    }

    const getSortFieldByColumnId = (columnId: string) => {

        switch (columnId) {
            case "name": return FileSortFields.Name;
            case "sharedAt": return FileSortFields.SharedAtUtc;
            case "modifiedAt": return FileSortFields.LastModifiedAt;
            default: return undefined;
        }
    }
    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "analyzed": return true;
            case "analytics": return true;
            case "deleted": return true;
            case "modifiedAt": return true;

            default: return false;
        }
    }

    const onColumnClick = (columnId: string) => {

        // console.log("onColumnClick", columnId);

        if (!props.sortable) return;
        const field = getSortFieldByColumnId(columnId);
        if (field === undefined) return;

        if (!sortBy || sortBy.field != field) {
            const sort = { field: field, isDescending: false };
            setSortBy(sort);

            props.onSortChange?.(sort);
        } else {
            const sort = { ...sortBy, isDescending: !sortBy.isDescending } as FilesTableSort;
            setSortBy(sort);
            props.onSortChange?.(sort);
        }
    }
    const getColumnCount = () => {
        let columnCount = 3;
        if (!props.hideAnalyzedColumn) columnCount++;
        if (!props.hideAnalyticsColumn) columnCount++;
        if (!props.hideTagsColumn) columnCount++;
        if (!props.hideModifiedColumn) columnCount++;
        if (props.additionalColumns) columnCount = columnCount + props.additionalColumns.length;
        return columnCount;
    }

    const rows = getRows((row) => {
        const selected = isRowSelected(row.item.uniqueIdentifier);
        return {
            ...row,
            rowId: row.item.uniqueIdentifier,
            onClick: (e: React.MouseEvent) => { e.preventDefault(); onSingleRowClick(row.item); },
            onDoubleClick: (ev) => { ev.preventDefault(); onDoubleRowClick(row.item) },
            onContextMenu: (ev) => { ev.preventDefault(); setSelectedItem(row.item); contextMenuRef.current?.showMenu(ev); },
            selected
        };
    });

    React.useEffect(() => {
        const selectedRowsLocal = [...selectedRows.values()];
        console.log("Selected row size changed:", selectedRows.size, selectedRowsLocal, allRowsSelected, someRowsSelected);
    }, [selectedRows.size]);
    // const allRowsSelected = React.useMemo(() => { return selectedRows.size > 0 && selectedRows.size == items.length; }, [items.length, selectedRows.size]);
    // const someRowsSelected = !allRowsSelected ? selectedRows.size > 0 : false;




    const columnCount = getColumnCount();

    // console.log("columns",columns);
    const selectedItem = !!selectedRows && selectedRows.size == 1 ? getSelectedSingleItem() : undefined;
    //  console.log("allRowsSelected,someRowsSelected", allRowsSelected, someRowsSelected);
    return <div className='files-table__wrapper'>

        <Table sortable ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()} >
            <TableHeader>
                <TableRow>
                    {columns.map((column) => (

                        <TableHeaderCell
                            className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                            onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            sortDirection={getSortDirection(column.columnId as string)}
                            onClick={() => { onColumnClick(column.columnId as string) }}
                            key={column.columnId}
                            {...columnSizing_unstable.getTableHeaderCellProps(
                                column.columnId
                            )}

                        >
                            {column.renderHeaderCell()}
                        </TableHeaderCell>


                    ))}
                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={props.isLoading}
                columnCount={columnCount} loadingMessage="Loading..."
                itemCount={!!items ? items.length : 0}
                noItemsMessage="No items found.">
                {!props.isLoading && rows.map(({ item: file, selected, onClick, onDoubleClick, onContextMenu }) => (
                    <TableRow
                        className={selected ? " table-row table-row-selected" : "table-row"}
                        key={`file-${file.type}-${file.id}`}
                        onClick={onClick}
                        onDoubleClick={onDoubleClick}
                        onContextMenu={onContextMenu}>
                        {props.isMultiSelect && <TableSelectionCell
                            subtle checked={selected}
                            {...columnSizing_unstable.getTableCellProps("checkbox")}
                            onClick={(ev) => { ev.stopPropagation(); onSelectRowCheckboxClick(file); }} />}

                        <TableCell {...columnSizing_unstable.getTableCellProps("fileIcon")}>
                            <div style={{ display: "flex", justifyContent: 'center' }}>
                                <FileTypeIcon filename={file.name!} isFolder={file.type == FileSystemEntityType.Directory} />
                            </div>
                        </TableCell>
                        <TableCell {...columnSizing_unstable.getTableCellProps("name")}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", wordBreak: "break-all" }}>
                                <span className={!props.disableNavigation ? 'table-file-name' : 'table-file-name-without-hover'}
                                    onClick={(ev) => { ev.preventDefault(); onDoubleRowClick(file); }} title={file.name}>
                                    {file.name}
                                    {file.stared && <Star20Filled style={{ marginLeft: "var(--spacingHorizontalS)", color: "gold" }} />}
                                </span>
                                <Button

                                    className="dots-button"
                                    appearance="transparent"
                                    icon={<MoreVerticalRegular />}
                                    onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); setSelectedItem(file); contextMenuRef.current?.showMenu(ev); }} />
                            </div>
                        </TableCell>
                        {!props.hideAnalyzedColumn && <TableCell className='files-table__cell--small' {...columnSizing_unstable.getTableCellProps("analyzed")}>
                            <AnalysisStatusIcon
                                readonly={props.disableSubmitForAnalysis}
                                hubConnection={props.hubConnection}
                                fileUniqueIdentifier={file.uniqueIdentifier!}
                                fileVersionNumber={file.currentVersionNumber!}
                                filename={file.name!} />
                        </TableCell>}
                        {!props.hideAnalyticsColumn && <TableCell className="column--center" {...columnSizing_unstable.getTableCellProps("analytics")}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <FiltersCountIcon fileUniqueId={file.uniqueIdentifier} filename={file.name} url={`/files/${file.uniqueIdentifier}/filters`} tooltip="Filters" />
                                <ScenariosCountIcon fileUniqueId={file.uniqueIdentifier} filename={file.name} url={`/files/${file.uniqueIdentifier}/${excelExtensions.indexOf(getFileExtension(file.name)) == -1 ? "doe" : "doe-xls"}`} tooltip="Design of Experiments" />
                                <SensitivityStudiesCountIcon fileUniqueId={file.uniqueIdentifier} filename={file.name} url={`/files/${file.uniqueIdentifier}/sensitivity-studies`} tooltip="Multivariate Sensitivity Study" />
                                <OptimumTestsCountIcon fileUniqueId={file.uniqueIdentifier} filename={file.name} url={`/files/${file.uniqueIdentifier}/optimum-tester`} tooltip="Optimum Test" />
                                <ExamsCountIcon fileUniqueId={file.uniqueIdentifier} filename={file.name} url={`/files/${file.uniqueIdentifier}/exams`} tooltip="Take Home Exam" />
                                {!!featureFlagsSettings.showCopilot && <ExperimentCountIcon fileUniqueId={file.uniqueIdentifier} filename={file.name} url={`/files/${file.uniqueIdentifier}/copilot`} tooltip="Flowsheet Copilot" />}
                            </div>
                        </TableCell>}
                        {!props.hideTagsColumn &&
                            <TableCell
                                onClick={() => props.onShowTagInputsChanged({ [`file_${file.type}_${file.id}`]: true })}
                                {...columnSizing_unstable.getTableCellProps("tags")}>

                                <TagPicker
                                    id={file.id!}
                                    fileType={file.type!}
                                    disabled={props.readonly}
                                    fileUniqueIdentifier={file.uniqueIdentifier}
                                    tags={file.tags}
                                    defaultFileOptions={fileTagDefaultOptions}
                                    onTagAdded={() => { props.onReloadFilesEvent(); }}
                                    onTagRemoved={() => { props.onReloadFilesEvent(); }}
                                />
                            </TableCell>}
                        <TableCell className='files-table__cell--small' {...columnSizing_unstable.getTableCellProps("comment")}>
                            <EditComment
                                key="files-table-comment"
                                file={file}
                                disabled={props.readonly}
                                showDetailsIn2Rows
                                rows={3}
                                loadingService={props.loadingService}
                                onReloadFilesEvent={() => { props.onReloadFilesEvent() }}
                            />
                        </TableCell>
                        {!props.hideModifiedColumn && <TableCell className='files-table__cell--small column--center' {...columnSizing_unstable.getTableCellProps("modifiedAt")}>
                            <ShowLocalTime date={file.lastModifiedAt} multiline />
                        </TableCell>}
                        {props.additionalColumns && props.additionalColumns.map((item) =>
                            <TableCell className={`${item.cellTextCentered ? 'column--center' : ''}`}>{item.cellValue ? item.cellValue(file) : undefined}</TableCell>)}

                    </TableRow>
                ))}

            </TableBodyWithLoading>
        </Table>

        {!props.hideContextMenu && <ContextMenu ref={contextMenuRef} hasIcons scrollableElement={props.scrollableElement}>
            {getContextItems()}
        </ContextMenu>}

        {showRenameFileModal &&
            selectedItem && <RenameFileModal
                isOpened={showRenameFileModal}
                currentName={selectedItem?.name!}
                id={selectedItem?.id!}
                isFolder={selectedItem?.type == FileSystemEntityType.Directory}
                onSuccess={() => { setShowRenameFileModal(false); props.onReloadFilesEvent(); }}
                onClose={() => setShowRenameFileModal(false)}
            />
        }
        {showDeleteFileModal &&
            selectedItem && <DeleteFileModal
                name={selectedItem.name}
                uniqueFileId={selectedItem.uniqueIdentifier}
                isOpened={showDeleteFileModal}
                fileType={selectedItem.type}
                isFolder={selectedItem?.type == FileSystemEntityType.Directory}
                onSuccess={() => { setShowDeleteFileModal(false); props.onReloadFilesEvent(); }}
                onClose={() => setShowDeleteFileModal(false)}
            />
        }

        {
            selectedItem && showMoveFileModal &&
            <MoveFileDialog
                fileToMove={selectedItem}
                isOpened={true}
                onClose={() => { setShowMoveFileModal(false); }}
                onSuccess={() => { setShowMoveFileModal(false); props.onReloadFilesEvent(); }}

            />
        }
        {
            selectedItem && showCopyFileModal &&
            <CopyFileDialog
                fileToCopy={selectedItem}
                isOpened={showCopyFileModal}
                onClose={() => { setShowCopyFileModal(false); }}
                onSuccess={() => {
                    toast.success(`Successfully copied ${selectedItem.type == FileSystemEntityType.File ? 'file' : 'folder'}.`);
                    setShowCopyFileModal(false);
                    props.onReloadFilesEvent();
                }}

            />
        }

        {
            selectedItem && showManageVersionsModal && <FileHistoryModal
                isOpened={true}
                file={selectedItem}
                onClose={() => { setShowManageVersionsModal(false); }}
            />
        }

        {
            selectedItem && showOpenInS365AppModal &&
            <OpenInS365AppModal
                isOpened={true}
                fileUniqueIdentifier={selectedItem.uniqueIdentifier}
                onClose={() => setShowOpenInS365AppModal(false)} />
        }
        {
            selectedItem && showSendToSupportModal &&
            <ContactFormModal file={selectedItem}
                onHide={() => { setShowSendToSupportModal(false); }}
                onSuccess={() => {
                    setShowSendToSupportModal(false);
                    toast.success("Successfully sent support ticket.");
                }} />
        }

        {
            selectedItem && showShareModal &&
            <ShareFileModal
                sourceType={selectedItem.type == FileSystemEntityType.File ? ShareDefinitionSourceType.File : ShareDefinitionSourceType.Directory}
                sourceName={selectedItem.name}
                sourceUniqueId={selectedItem.uniqueIdentifier}
                isOpened={showShareModal}
                onClose={() => { setShowShareModal(false); }}
            />
        }

        {selectedItem && showDwsimProLicenseCheckerModal &&
            <DwsimProLicenseCheckerModal
                isOpened={true}
                fileUniqueIdentifier={selectedItem.uniqueIdentifier}
                filename={selectedItem.name}
                onClose={() => { setShowDwsimProLicenseCheckerModal(false) }} />}
        {downloadFilesRequestId && showDownloadFileProgressDialog &&
            <DownloadFilesDialog
                downloadRequestId={downloadFilesRequestId}
                isFolder={selectedRows.size == 1}
                isOpened
                onClose={() => {
                    setDownloadFilesRequestId(undefined);
                    setShowDownloadFilesProgressDialog(false);
                }} />
        }

    </div >
}

