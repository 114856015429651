
import { Input, Select } from "@fluentui/react-components";
import * as React from "react";
import { ParameterDisplay } from "../../../components/parameter-display/parameter-display.component";
import { FileModel, FilterResultModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { IDropdownOption } from "../../../utils/shared.types";
import { SensitivityStudyResultModel } from "../../../swagger-clients/sensitivity-studies-api-clients.service";

import "./filter-parameter-picker.component.scss";
import ReactSelect, { ActionMeta, components, DropdownIndicatorProps, MultiValue, SingleValueProps } from "react-select";
import { TagOption } from "../../../components/paramater-picker-next/parameter-picker-next.component";
import { ChevronDown20Regular } from "@fluentui/react-icons";
interface IFilterParameterPickerProps {
    value?: IFilterParamData;
    selectedStudy?: SensitivityStudyResultModel;
    selectedFile: FileModel;
    selectedFilter: FilterResultModel;
    onChange?(data: IFilterParamData);
}

enum FilterOperation {
    Equals = 0,
    Between = 1
}

export interface IFilterParamData {
    parameterId: string;
    operation: FilterOperation;
    equalsValue?: string;
    endValue?: string;
}

const FilterParameterPicker: React.FC<IFilterParameterPickerProps> = (props, ref) => {



    const [selectedParameter, setSelectedParameter] = React.useState<string>(props.value?.parameterId ?? "-1");
    const [parameterOptions, setParameterOptions] = React.useState<TagOption[]>([{ value: "-1", label: "All" }]);
    const [selectedOperation, setSelectedOperation] = React.useState<FilterOperation>(props.value?.operation ?? FilterOperation.Equals);
    // equals and startValue depending on selectedOperation
    const [equalsValue, setEqualsValue] = React.useState<string>(props.value?.equalsValue ?? "");
    const [endValue, setEndValue] = React.useState<string>(props.value?.endValue ?? "");

    React.useEffect(() => {
        const newOptions = getOptions();
        setParameterOptions(newOptions);
    }, [props.selectedStudy]);


    React.useEffect(() => {
        setSelectedParameter(props.value?.parameterId ?? "-1");
        setSelectedOperation(props.value?.operation ?? FilterOperation.Equals);
        setEqualsValue(props.value?.equalsValue ?? "");
        setEndValue(props.value?.endValue ?? "");
    }, [props.value]);



    const onDataChanged = (data: IFilterParamData) => {
        props.onChange(data);
    }

    const getOptions = () => {
        let options: TagOption[] = [{ value: "-1", label: "All" }];

        props.selectedStudy.sensitivityStudyInputParameters.map(inParam => {
            options.push({ value: `in_${inParam.id}`, label: "notImportant" });
        });

        props.selectedStudy.sensitivityStudyOutputParameters.filter((x: any) => !x.isFilterFormula).map(outParam => {
            options.push({ value: `out_${outParam.id}`, label: "notImportant" });
        });

        return options;

    }

    const Option = (option: any): JSX.Element => {


        if (option.value == "-1") {
            return (
                <components.Option {...(option as any)}>
                    <span>{option.label}</span>
                </components.Option>
            );
        }

        const keySplit = option.value.toString().split('_');
        const isInputParam = keySplit[0] == "in";
        const id = keySplit[1];
        let name: any = undefined;
        if (isInputParam) {
            const param = props.selectedStudy.sensitivityStudyInputParameters.find(x => x.id == Number(id)) as any;
            name = param.inputFilterParameter.name;
        } else {
            const param = props.selectedStudy.sensitivityStudyOutputParameters.find(x => x.id == Number(id)) as any;
            name = param.outputFilterParameter.name;
        }

        return (
            <components.Option {...(option as any)}>
                <div className="filter-param-text">
                    <ParameterDisplay
                        value={name}
                        fileUniqueId={props.selectedFilter.fileUniqueIdentifier}
                        versionNumber={props.selectedFilter.fileVersionNumber.toString()}
                    />
                </div></components.Option>

        );
    }
    const onParametersChanged = async (selectedOption: TagOption | MultiValue<TagOption>, actionMeta: ActionMeta<TagOption>) => {
        if (!Array.isArray(selectedOperation)) {
            setSelectedParameter((selectedOption as TagOption).value.toString());
        }
        const value = (selectedOption as TagOption).value.toString();
        if (value == "-1") {
            setSelectedOperation(FilterOperation.Equals);
            setEndValue("");
            setEqualsValue("");
            onDataChanged({
                operation: FilterOperation.Equals,
                parameterId: undefined,
                endValue: "",
                equalsValue: ""
            } as IFilterParamData);
        } else {
            onDataChanged({
                operation: selectedOperation,
                parameterId: value,
                endValue: endValue,
                equalsValue: equalsValue
            } as IFilterParamData);
        }

    }

    const SingleValue = ({
        children,
        ...singleValueProps
    }: SingleValueProps<TagOption>) => {

        const option = parameterOptions.find(x => x.value == selectedParameter);

        if (option.value == "-1") {
            return (
                <components.SingleValue {...singleValueProps}>
                    <span>{option.label}</span>
                </components.SingleValue>
            );
        }

        const keySplit = option.value.toString().split('_');
        const isInputParam = keySplit[0] == "in";
        const id = keySplit[1];
        let name: any = undefined;
        if (isInputParam) {
            const param = props.selectedStudy.sensitivityStudyInputParameters.find(x => x.id == Number(id)) as any;
            name = param.inputFilterParameter.name;
        } else {
            const param = props.selectedStudy.sensitivityStudyOutputParameters.find(x => x.id == Number(id)) as any;
            name = param.outputFilterParameter.name;
        }

        return <components.SingleValue {...singleValueProps}>
            <div className="filter-param-text">
                <ParameterDisplay
                    value={name}
                    fileUniqueId={props.selectedFilter.fileUniqueIdentifier}
                    versionNumber={props.selectedFilter.fileVersionNumber.toString()}
                />
            </div>
        </components.SingleValue>
    }


    const DropdownIndicator = (
        props: DropdownIndicatorProps<TagOption, true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <ChevronDown20Regular />
            </components.DropdownIndicator>
        );
    };


    const operationOptions: IDropdownOption[] = [
        {
            key: FilterOperation.Equals, text: "Equals"
        },
        {
            key: FilterOperation.Between, text: "Between"
        }];

    const selectedParameterOption = parameterOptions.find(x => x.value == selectedParameter);

    return <div style={{ display: "flex", flexDirection: "row", marginRight: "10px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginRight: "10px" }}>Parameter:</span>
            <ReactSelect
                isSearchable={false}
                value={selectedParameterOption}
                isClearable={false}
                options={parameterOptions}

                styles={{
                    ...customStyles

                }
                }
                onChange={onParametersChanged}
                components={{ Option: Option, SingleValue: SingleValue, DropdownIndicator }}

            />
        </div>
        {selectedParameter !== "-1" && <>
            <Select
                style={{ alignSelf: "end", marginRight: "5px", minWidth: "100px" }}
                value={selectedOperation}
                onChange={(ev, data) => {
                    if (data.value == FilterOperation.Equals.toString()) {
                        setEndValue("");
                    }
                    setSelectedOperation(+data.value);
                    onDataChanged({
                        operation: +data.value,
                        parameterId: selectedParameter,
                        endValue: endValue,
                        equalsValue: equalsValue
                    } as IFilterParamData);
                }}
            >
                {operationOptions.map((option) => {
                    return <option value={option.key}>{option.text}</option>
                })}
            </Select>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginRight: "10px" }}>{selectedOperation == FilterOperation.Equals ? "Value" : "Start"}</span>
                <Input
                    type="number"
                    style={{ minWidth: "100px", marginRight: selectedOperation == FilterOperation.Between ? "5px" : "0px" }}
                    value={equalsValue}
                    onChange={(ev, data) => {
                        setEqualsValue(data.value);
                        onDataChanged({
                            operation: selectedOperation,
                            parameterId: selectedParameter,
                            endValue: endValue,
                            equalsValue: data.value
                        } as IFilterParamData);
                    }}
                />
            </div>
            {selectedOperation == FilterOperation.Between &&
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginRight: "10px" }}>End</span>
                    <Input
                        type="number"
                        value={endValue}
                        onChange={(ev, data) => {
                            setEndValue(data.value);
                            onDataChanged({
                                operation: selectedOperation,
                                parameterId: selectedParameter,
                                endValue: data.value,
                                equalsValue: equalsValue
                            } as IFilterParamData);
                        }}
                        style={{ minWidth: "100px" }}
                    />
                </div>


            }
        </>
        }



    </div >;
}

const customStyles = {
    container: (base, state) => ({
        ...base,
        marginRight: "10px",
        borderBottomStyle: "outset",
        borderBottomColor: "buttonborder",
        borderWidth: "0.1px",
        borderRadius: "4px"
    }),
    control: (base, state) => ({
        ...base,
        borderRadius: '4px',
        height: '32px',
        lineHeight: '32px',
        padding: '0',
        minHeight: "0",
        border: '1px solid #ccc',
        borderColor: "#ccc !important",
        boxShadow: "none !important",
        backgroundColor: 'white',
        minWidth: "300px"
    }),
    valueContainer: base => ({
        ...base,
        height: '31px',
        lineHeight: '31px',
        marginBottom: "5px",
        padding: "0",
        borderWidth: "0",
        paddingLeft: '12px',
    }),
    indicatorSeparator: base => ({
        ...base,
        display: "none"
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        padding: '6px',
        marginBottom: "5px",
        color: "var(--colorNeutralStrokeAccessible) !important"

    }),
    menu: base => ({
        ...base,
        borderRadius: '4px',
        padding: "0",
        margin: "0",
        backgroundColor: 'white',
        border: '1px solid #ccc',
    }),
    option: (base, state) => ({
        ...base,
        color: '#000',
        height: "32px",
        padding: '6px 8px',
        backgroundColor: state.isFocused ? '#f2f2f2' : state.isSelected ? undefined : 'white',
    })
};

export default FilterParameterPicker;