import { FilterParameterType } from "../swagger-clients/s365-dashboard-v2-api-clients.service";

export const getParameterTypeText = (parameterType?: FilterParameterType) => {


    switch (parameterType) {
        case FilterParameterType.ExpectedInput:
            return "Expected input";
        case FilterParameterType.ExpectedOutput:
            return "Expected output";
        case FilterParameterType.FixedValue:
            return "Fixed value";
        case FilterParameterType.Formula:
            return "Formula";
        default:
            return "";
    }

}