import { Tooltip } from "@fluentui/react-components";
import React from "react";
import { JobExecutionActionStatus } from "../../swagger-clients/dispatcher-next-api-clients.service";

interface IJobExecutionActionStatusProps {
    actionId?: string;
    value: JobExecutionActionStatus;
    errorMessage?: string;
}
const mapping = {
    [JobExecutionActionStatus.Failed]: { text: "Failed", color: "red" },
    [JobExecutionActionStatus.SuccessfullyExecuted]: { text: "Success", color: "green" },
    [JobExecutionActionStatus.NotExecuted]: { text: "Not executed", color: "gray" }


};

const JobExecutionActionStatusComponent: React.FunctionComponent<IJobExecutionActionStatusProps> = (props) => {

    const status = mapping[props.value];
    const onRenderPlainCard = (message: string): JSX.Element => {

        return <pre style={{ padding: "10px 25px" }}>{message}</pre>;
    };




    if (status && props.errorMessage) {
        return (<Tooltip relationship="description" content={props.errorMessage}>
            <div> <span style={{ color: status.color }} >{status.text}</span></div>
        </Tooltip>);
    } else {
        return <span style={{ color: status.color}} >{status.text}</span>;
    }



}

export default JobExecutionActionStatusComponent;