import { Button, Field, Link, Select, Tooltip } from "@fluentui/react-components";
import { InfoButton } from "@fluentui/react-components/unstable";
import { Info20Regular } from "@fluentui/react-icons";
import React from "react";
import { getApplicationsClient } from "../../services/dispatcher.service";
import { ApplicationResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { IDropdownOption } from "../../utils/shared.types";
import { EditApplicationModal } from "../edit-application-modal/edit-application-modal.component";
import "./application-picker.styless.scss";

type ApplicationPickerProps = {
    selectedApplicationId: string;
    onChange?(item: ApplicationResponseModel): void;
    style?: React.CSSProperties;
    className?: string;
    selectClassName?: string;
    defaultApplicationName?: string;
    required?: boolean;
    isSubmitted?: boolean;
    hideInfo?: boolean;
    hideCreateNew?: boolean;
    addDefaultOption?: boolean;
    disabled?: boolean;
}


export const ApplicationPicker: React.FC<ApplicationPickerProps> = (props) => {
    const [applications, setApplications] = React.useState<ApplicationResponseModel[]>([]);
    const [applicationOptions, setApplicationOptions] = React.useState<IDropdownOption[]>([]);
    const [showCreateApplicationModal, setShowCreateApplicationModal] = React.useState<boolean>(false);


    React.useEffect(() => {
        getApplications();
    }, []);



    const getApplications = async () => {
        try {
            const client = getApplicationsClient();
            const applicationsResp = await client.getApplications();

            const applicationOptions = applicationsResp.filter(x => !x.isDeleted)
                .map(app => ({ key: app!.id!.toString(), text: app.name } as IDropdownOption));

            const updatedApplicationOptions = props.addDefaultOption ? [{ key: "-1", text: "Select Application" }, ...applicationOptions] : applicationOptions;

            setApplications(applicationsResp);
            setApplicationOptions(updatedApplicationOptions);

            return applicationsResp;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting applications.");
        }

    }

    const onApplicationSelected = async (selectedKey: string) => {

        if (props.onChange) {

            const apps = await getApplications();
            const selectedApplication = apps?.find(x => x.id!.toString() == selectedKey);

            props.onChange(selectedApplication!);
        }


    }

    const onApplicationCreated = async (id: number) => {
        setShowCreateApplicationModal(false);
        onApplicationSelected(id.toString());

    }
    const errorMessage = props.required ? "Application is required." : "";
    const isValid = props.selectedApplicationId && (+props.selectedApplicationId) > 0;


    return <div style={{ display: "flex", flexDirection: "column", width: "100%", ...props.style }} >
        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
            <Field
                className={props.className}
                validationState={props.isSubmitted && !isValid ? "error" : "none"}
                validationMessage={props.isSubmitted && !isValid ? errorMessage : ""}>
                <Select
                    className={props.selectClassName}
                    style={{ width: "100%" }}
                    disabled={props.disabled}
                    value={props.selectedApplicationId?.toString()}
                    onChange={(ev, data) => { onApplicationSelected(data.value!) }}>
                    <option key={`application-picker-option-default`} value={undefined}>Select application</option>
                    {applicationOptions.map((option, index) => {
                        return <option key={`application-picker-option-${index}`} value={option.key?.toString()}>{option.text}</option>
                    })}
                </Select>

            </Field>
            {!props.hideInfo && <InfoButton info={<>You can set dispatcher settings in application.</>} />
            }

        </div>

        {!props.hideCreateNew ?
            applicationOptions.length == 0 ?
                <small>You don't have any Application, click <Link onClick={() => { setShowCreateApplicationModal(true); }} >here</Link> to create one.</small> :
                <small>Click <Link disabled={props.disabled} onClick={() => { setShowCreateApplicationModal(true); }} >here</Link> to create one.</small>
            : null
        }
        {
            showCreateApplicationModal &&
            <EditApplicationModal
                defaultName={props.defaultApplicationName}
                isOpened={true}
                onSuccess={onApplicationCreated}
                onClose={() => { setShowCreateApplicationModal(false); }} />
        }
    </div>;
}