import { useMsal } from "@azure/msal-react";
import { Avatar, Button } from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";
import React from "react";
import ReactSelect, { ActionMeta, components, MultiValue } from "react-select";
import { graphClient } from "../../services/msgraph.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { removeDuplicates } from "../../utils/helpers/has-access.helpers";
import { useLoading } from "../../utils/loading-indicator.component";
import { PersonDisplay } from "../person-display/person-display.component";
import "./has-access-side-people-picker.styless.scss";
import { getGroupsClient } from "../../services/dashboard.service";

type HasAccessSidePeoplePickerProps = {
    multiple?: boolean;
    orgGroupId: string;
    selectedPersonas?: SelectOption[];
    onSelectedOptionsChanged: (selectedOptions: SelectOption[]) => void
}


export interface IPerson {
    id: string;
    displayName: string;
    email: string;
    privateEmail: string;
    personType: string;
    department: string;
    jobTitle: string;
}
export type SelectOption = {
    value: string | number,
    label: string,
    userPrincipalName?: string;
    mail?: string;

}


export const HasAccessSidePeoplePicker: React.FC<HasAccessSidePeoplePickerProps> = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const [currentValue, setCurrentValue] = React.useState<string>("");
    const [selectedValues, setSelectedValues] = React.useState<SelectOption[]>([]);
    const [isLoading, loadingService] = useLoading();
    const [options, setOptions] = React.useState<SelectOption[]>([]);
    const isMultiple = props.multiple !== undefined ? props.multiple : true;

    React.useEffect(() => {
        props.onSelectedOptionsChanged(selectedValues);
    }, [selectedValues]);

    React.useEffect(() => {
        if (props.selectedPersonas !== undefined && props.selectedPersonas.length > 0) {
            setSelectedValues(props.selectedPersonas);
        }
    }, [props.selectedPersonas]);

    const Option = (props) => {
        const person = props.data as SelectOption;

        return <components.Option {...props}>
            <PersonDisplay id={person.label} name={person.label} userPrincipalName={person.userPrincipalName} />

        </components.Option>
    }

    const onParametersChanged = async (newOption: SelectOption, actionMeta: ActionMeta<SelectOption>) => {
        if (isMultiple) {
            setSelectedValues([...selectedValues, newOption] as SelectOption[]);
        } else {
            setSelectedValues([newOption] as SelectOption[]);
        }


        setCurrentValue("");
    }

    React.useEffect(() => {
        if (currentValue && currentValue.length > 1) {
            updateSuggestions();
        } else {
            setOptions([]);
        }

    }, [currentValue]);

    const updateSuggestions = async () => {
        // console.log("updateSuggestions", props);
        loadingService.showLoading("Loading suggestions...", async (hideMessage) => {
            try {
                const filterText = currentValue.trim();
                const client= getGroupsClient();
                var users= await client.searchMembers(props.orgGroupId,filterText);

                const mappedUserOptions = users?.map((user) => ({
                    value: user.id,
                    label: `${user.givenName} ${user.surname}`,
                    userPrincipalName: user.userPrincipalName
                } as SelectOption)) ?? [];
                const uniqueOptions = removeDuplicates(mappedUserOptions, selectedValues);
                const removedCurrenUserOptions = uniqueOptions.filter(x => x.value !== account.localAccountId);
                setOptions(removedCurrenUserOptions);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting suggestions.");
            } finally {
                hideMessage();
            }
        });

    }

    const onRemoveSelection = (person: SelectOption) => {
        const updatedValues = selectedValues.filter(x => x.value !== person.value);
        setSelectedValues(updatedValues);
    }

    return <div style={{ display: "flex", flexDirection: "column", margin: "5px 0px" }}>
        <ReactSelect
            value={[]}
            isLoading={isLoading}
            isClearable={false}
            options={options}           
            onInputChange={(newValue: string) => { setCurrentValue(newValue); }}
            onChange={onParametersChanged}
            components={{ Option }} />
        <div className="selected-people">
            {selectedValues && selectedValues.map((person) => {

                return <div className="person-container" key={`person-${person.value}`}>
                    <Avatar className="persona-avatar" name={person.label} color="colorful" />
                    <span className="persona-name">{person.label}</span>
                    <Button appearance="transparent" icon={<Dismiss12Regular />} onClick={() => onRemoveSelection(person)} />
                </div>
            })}
        </div>
    </div>
}




