import { Button, MenuItem, Tab, TabList, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { Add20Regular, ArrowResetRegular, CopyRegular, DataScatterRegular, DataTrendingRegular, DeleteRegular, DocumentTableRegular, DocumentTextRegular, EditRegular } from "@fluentui/react-icons";

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from '../components/breadcrumbs/breadcrumbs';
import { TableBodyWithLoading } from "../components/table-body-with-loading/table-body-with-loading.component";
import { ContextMenu, ContextMenuType } from "../files/context-menu/context-menu.component";
import { getFilesClient, getFiltersClient } from "../services/dashboard.service";
import { BreadcrumbItem, FileModel, FilterQuery, FilterResultModel } from "../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../utils/loading-indicator.component";
import { DeleteFilterModal } from "./delete-filter-modal.component";
import "./filters.styless.scss";
import { GenerateExcelFile } from "./generate-excel-file/generate-excel-file.component";
import { toast } from "react-toastify";
import ShowLocalTime from "../components/show-local-time/show-local-time.component";

type FiltersProps = {
};
export type FiltersRouteParams = {
    uniquefileId: string,
    filterId?: string
};

export const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];

export const Filters: React.FC<FiltersProps> = (props) => {
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const routeParams = useParams<FiltersRouteParams>();
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedFilter, setSelectedFilter] = React.useState<FilterResultModel>();
    const [filters, setFilters] = React.useState<FilterResultModel[]>([]);
    const [showGenerateExcelFileModal, setShowGenerateExcelFileModal] = React.useState<boolean>(false);
    const [showDeleteFilterModal, setShowDeleteFilterModal] = React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState<string>("active");
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();



    useEffect(() => {
        initializePage();
    }, []);

    const initializePage = async () => {
        const resp = await getFile();
        await getFilters(resp?.file?.id ?? undefined);
    }

    const getFile = async () => {
        try {
            const client = getFilesClient();
            const resp = await client.getFileLatest(routeParams.uniquefileId!, true);
            if (resp) {
                setSelectedFile(resp.file);
                setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
            }
            return resp;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file information.");
        }
    }
    const getFilters = async (fileId?: number) => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getFiltersClient();
                const query = { fileId: fileId ?? selectedFile!.id } as FilterQuery;
                const filtersResp = await client.getFilters(query);
                setFilters(filtersResp ?? []);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting filters.");
            } finally {
                hideMessage();
            }
        });
    }

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }


    const onShowDetailsClick = (filterId: number) => {
        navigate(`/files/${selectedFile?.uniqueIdentifier}/filters/${filterId}/details`)
    }

    const onRestoreFilterClick = () => {
        loadingService.showLoading("Restoring filter...", async (hideMessage) => {
            try {
                const client = getFiltersClient();
                await client.restoreFilter(selectedFilter.id);
                toast.success("Filter successfully restored.");
                await getFilters();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while restoring filter.");
            } finally {
                hideMessage();
            }
        });
    }



    const getContextItems = () => {


        let menuItems: JSX.Element[] = [];

        if (!selectedFilter?.isDeleted) {
            menuItems.push(...[

                <MenuItem icon={<EditRegular />} key="edit" onClick={() => navigate(`/files/${selectedFile?.uniqueIdentifier}/filters/edit/${selectedFilter?.id}`)}> Edit</MenuItem>,
                <MenuItem icon={<CopyRegular />} key="clone" onClick={() => navigate(`/files/${selectedFile?.uniqueIdentifier}/filters/edit?cloneId=${selectedFilter?.id}`)}> Clone</MenuItem>,
                <MenuItem icon={<DeleteRegular />} key="delete" onClick={() => setShowDeleteFilterModal(true)}> Archive</MenuItem>,
                <MenuItem icon={<DocumentTableRegular />} key="generate-excel" onClick={() => setShowGenerateExcelFileModal(true)}> Create Excel File</MenuItem>,
                <MenuItem icon={<DataScatterRegular />} key="create-msss" onClick={() => { navigate(`/files/${selectedFile.uniqueIdentifier}/sensitivity-studies/edit?filterId=${selectedFilter?.id}`); }}> Create MSS</MenuItem>,
                <MenuItem icon={<DataTrendingRegular />} key="create-ot" onClick={() => { navigate(`/files/${selectedFile.uniqueIdentifier}/optimum-tester/edit?filterId=${selectedFilter?.id}`); }}> Create OT</MenuItem>
            ]);
        } else {
            menuItems.push(<MenuItem key="restore" icon={<ArrowResetRegular />} onClick={() => { onRestoreFilterClick(); }}> Restore</MenuItem >);
        }


        return menuItems;
    }

    const contextMenuRef = React.createRef<ContextMenuType>();
    const flowsheetParentDirectoryUniqueId = breadcrumbs && breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].uniqueIdentifier : undefined;

    const activeFilters = filters.filter(x => !x.isDeleted);
    const deletedFilters = filters.filter(x => !!x.isDeleted);

    return <div className='page-wrapper'>
        <div className="filters-wrapper">
            <div className='toolbar__wrapper'>
                <Toolbar>
                    <ToolbarButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/filters/edit`)}
                        appearance='primary'
                        icon={<Add20Regular />}>New Filter</ToolbarButton>
                    <LoadingIndicator loadingService={loadingService} />
                </Toolbar>
            </div>

            <div className='filters-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>
                    {breadcrumbs.map((item: BreadcrumbItem) => {
                        return <Breadcrumb
                            key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                            onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                    })}
                    {selectedFile &&
                        <Breadcrumb
                            key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                            onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/filters`)}
                        >{selectedFile.name}</Breadcrumb>}
                    <Breadcrumb key={`breadcrumb-filters`} active={true}>Filters</Breadcrumb>

                </Breadcrumbs>
            </div>



            <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { contextMenuRef.current?.hideMenu(); setSelectedTab(data.value as string); setSelectedFilter(undefined); }}>
                <Tab key="active" value="active" >Active</Tab>
                <Tab key="deleted" value="deleted">Archived</Tab>
            </TabList>
            <div className="tab-content">
                {selectedTab == "active" && <div className='filters-table__wrapper'>
                    <Table >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell key="name" className='filters-table__cell--bold'>Name</TableHeaderCell>
                                <TableHeaderCell key="created-at" className='filters-table__cell--bold'>Created At</TableHeaderCell>
                                <TableHeaderCell key="empty-column" ></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBodyWithLoading isLoading={isLoading}
                            columnCount={3} loadingMessage="Loading..."
                            itemCount={activeFilters ? activeFilters.length : 0}
                            noItemsMessage="No items found.">
                            {activeFilters && activeFilters.length > 0 && activeFilters.map((item) => {
                                return <TableRow
                                    key={`filter-${item.id}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { setSelectedFilter(item); onShowDetailsClick(item.id!); }}
                                    className={selectedFilter && selectedFilter.id == item.id ? "table-row-selected" : undefined}
                                    onContextMenu={(ev) => { ev.preventDefault(); setSelectedFilter(item); contextMenuRef.current?.showMenu(ev); }}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell><ShowLocalTime date={item.createdAt}  /></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            })}
                        </TableBodyWithLoading>
                    </Table> </div>

                }
                {selectedTab == "deleted" && <div className='filters-table__wrapper'>
                    <Table >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell key="name" className='filters-table__cell--bold'>Name</TableHeaderCell>
                                <TableHeaderCell key="created-at" className='filters-table__cell--bold'>Created At</TableHeaderCell>
                                <TableHeaderCell key="empty-column" ></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBodyWithLoading isLoading={isLoading}
                            columnCount={3} loadingMessage="Loading..."
                            itemCount={deletedFilters ? deletedFilters.length : 0}
                            noItemsMessage="No items found.">
                            {deletedFilters && deletedFilters.length > 0 && deletedFilters.map((item) => {
                                return <TableRow
                                    style={{ cursor: "pointer" }}
                                    key={`filter-${item.id}`}
                                    onClick={() => { setSelectedFilter(item); onShowDetailsClick(item.id!); }}
                                    className={selectedFilter && selectedFilter.id == item.id ? "table-row-selected" : undefined}
                                    onContextMenu={(ev) => { ev.preventDefault(); setSelectedFilter(item); contextMenuRef.current?.showMenu(ev); }}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell><ShowLocalTime date={item.createdAt}  /></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            })}
                        </TableBodyWithLoading>
                    </Table>
                </div>
                }
            </div>

        </div>
        <ContextMenu ref={contextMenuRef} hasIcons>
            {getContextItems()}
        </ContextMenu>
        <GenerateExcelFile
            isOpened={showGenerateExcelFileModal}
            selectedFile={selectedFile!}
            selectedFilter={selectedFilter!}
            parentDirectoryUniqueId={flowsheetParentDirectoryUniqueId}
            onOpenedChange={(isOpened) => { setShowGenerateExcelFileModal(isOpened) }} />


        <DeleteFilterModal
            filterId={selectedFilter?.id}
            filterName={selectedFilter?.name}
            isOpened={showDeleteFilterModal}
            onSuccess={() => { setShowDeleteFilterModal(false); getFilters(); }}
            onClose={() => { setShowDeleteFilterModal(false); }} />
    </div >
}