import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import React from "react";
import { toast } from "react-toastify";
import { settings } from "../../App";
import { getUserToken } from "../../services/dashboard.service";
import { JobExecutionResponseModel, JobResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import JobExecutionStatusComponent from "../job-execution-status/job-execution-status.component";
import { getSimulatorName } from "../../utils/helpers/simulator.helpers";
import { Button } from "@fluentui/react-components";
import { useLoading } from "../../utils/loading-indicator.component";
import { TableBodyWithLoading } from "../table-body-with-loading/table-body-with-loading.component";


type JobExecutionTableProps = {
    job?: JobResponseModel;
    isLoading: boolean;
    isExample?: boolean;
    executions: JobExecutionResponseModel[];
    showExecutionResults(executionId: number);
    showJobExecutionActions(executionId: number);
}

export const JobExecutionTable: React.FC<JobExecutionTableProps> = (props) => {



    const getAgentsActions = (item: JobExecutionResponseModel) => {


        return <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <span style={{ color: "green" }}>{item.successfullCount ? item.successfullCount : "0"}</span>/
            <span style={{ color: "red" }}>{item.failedCount ? item.failedCount : "0"}</span>&nbsp;of&nbsp;
            <span>{item.totalCount ? item.totalCount : "0"}</span>
        </div>
    }

    const downloadFlowsheet = async (executionId: number, filename: string) => {

        try {
            const accessToken = await getUserToken();
            window.location.href = `${settings.dispatcherServiceUrl}/api/ui/jobs/executions/${executionId}/calculated-flowsheet?access_token=${accessToken}`;
        } catch (error) {
            toast.error("An error occurred while downlaoding file.");
            console.log("An error occurred while downlaoding file.", error);
        }
    }

    const getAgentActionCell = (item: JobExecutionResponseModel) => {
        const agentActions = getAgentsActions(item);
        return item ? <span
            onClick={() => { props.showJobExecutionActions(item.id) }}
            style={{ color: "blue", textDecoration: "underline", cursor: "pointer", display: "flex" }}
        > {agentActions} </span> : ""

    }

    const getCalculatedFlowsheetCell = (item: JobExecutionResponseModel) => {
        if (!item || props.isExample) return "-";
        if (item.forceCalculatedFlowsheetUpload === true) {
            if (!item.savedFilename || item.savedFilename.length == 0) {
                return "Waiting for upload...";
            }
        }
        return item && item.savedFilename  ?
            <Button appearance="primary"
                onClick={() => downloadFlowsheet(item.id, item.savedFilename)}
            >Download</Button> : "-"

    }

    const { executions } = props;

    return <div>
        <Table>
            <TableHeader>
                <TableRow key="table-header">
                    <TableHeaderCell key="id" style={{ width: "100px" }} className='table__cell--bold'>Id</TableHeaderCell>
                    <TableHeaderCell key="status" style={{ width: "150px" }} className='table__cell--bold'>Status</TableHeaderCell>
                    <TableHeaderCell key="assignedToAgent" className='table__cell--bold'>Assigned to Agent</TableHeaderCell>
                    <TableHeaderCell key="simulator" className='table__cell--bold'>Simulator</TableHeaderCell>
                    <TableHeaderCell key="agentActions" className='table__cell--bold'>Actions</TableHeaderCell>
                    <TableHeaderCell key="calculatedFlowsheet" className='table__cell--bold'>Calculated flowsheet</TableHeaderCell>
                    <TableHeaderCell key="show-results" className='table__cell--bold'></TableHeaderCell>

                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={props.isLoading}
                columnCount={7} loadingMessage="Loading..."
                itemCount={executions ? executions.length : 0}
                noItemsMessage="No items found.">
                {executions &&
                    executions.map((item, index) => {
                        return <TableRow key={`execution-${index}`}>
                            <TableCell> {item.id}  </TableCell>
                            <TableCell>
                                <JobExecutionStatusComponent value={item.status} errorMessage={item.errorsMessage} />
                            </TableCell>
                            <TableCell>
                                <span>
                                    {item.assignedToAgent ? <>{item.assignedToAgent} <small>{item.agentVersion}</small> </> : "-"}
                                    <br />
                                    {item.totalProcessingDuration ? item.totalProcessingDuration : ""}
                                </span>
                            </TableCell>

                            <TableCell>{getSimulatorName(props.job?.simulatorType)} <small>{item.simulatorVersion}</small></TableCell>
                            <TableCell>  {getAgentActionCell(item)}  </TableCell>
                            <TableCell> {getCalculatedFlowsheetCell(item)}  </TableCell>
                            <TableCell><Button appearance="primary" onClick={() => { props.showExecutionResults(item.id) }}>Results</Button>   </TableCell>

                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
    </div>
}