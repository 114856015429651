
import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import React from "react";
import { IDropdownOption } from "../../utils/shared.types";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog.component";

type ManageAccessRoleDropdownProps = {
    personaName?: string;
    onSelectedRoleChanged?(optionSelected: string);
    onMenuExpandToogle(isExpanded: boolean);
}

export const ManageAccessRoleDropdown: React.FC<ManageAccessRoleDropdownProps> = (props) => {
    const [showStopSharingDialog, setShowStopSharingDialog] = React.useState<boolean>(false);

    return <>
        <Menu onOpenChange={(e, data) => { props.onMenuExpandToogle(data.open); }}>
            <MenuTrigger disableButtonEnhancement>
                <MenuButton size="medium"></MenuButton>
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem onClick={() => { setShowStopSharingDialog(true); }}>Stop sharing</MenuItem>

                </MenuList>
            </MenuPopover>
        </Menu>
        <ConfirmationDialog
            title="Stop sharing"
            subText={`Are you sure that you want to stop sharing with '${props.personaName}'?`}
            isOpened={showStopSharingDialog}
            onConfirm={() => { setShowStopSharingDialog(false); props.onSelectedRoleChanged("stop"); }}
            onClose={() => setShowStopSharingDialog(false)} />
    </>
}