import genricfile from "../../assets/filetype-icons/genericfile.svg";
import xlsxFile from "../../assets/filetype-icons/xlsx_icon.svg";

export interface ICustomFileTypeToIconMap {
    [key: string]: { extensions?: string[], imageUrl: string }
}
export const CustomFileTypeToIconMap: ICustomFileTypeToIconMap = {
    cc7: {
        extensions: ['cc7'],
        imageUrl: require("../../assets/filetype-icons/cc7.png")
    },
    ccsim: {
        extensions: ['ccsim'],
        imageUrl: require("../../assets/filetype-icons/ccsim.png")
    },
    dwxml: {
        extensions: ['dwxml', 'dwxmz'],
        imageUrl: require("../../assets/filetype-icons/dwsim.png")
    },
    folder: {
        extensions: ['folder'],
        imageUrl: require("../../assets/filetype-icons/folder.png")
    },
    image: {
        extensions: ['png', 'jpg', 'jpeg', 'ico'],
        imageUrl: require("../../assets/filetype-icons/image.png")
    },
    xlsx: {
        extensions: ['xlsx'],
        imageUrl: xlsxFile
    },
    pdf: {
        extensions: ['pdf'],
        imageUrl: require("../../assets/filetype-icons/pdf.png")
    },
    defaultFile: {
        extensions: [],
        imageUrl: genricfile
    }

};