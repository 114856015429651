import React from "react";
import { CalculationJobResultModel, ExperimentResponseModel } from "../../swagger-clients/ai-for-pfd-clients.service";
import { Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { ResultInputParameter } from "../components/result-input-parameter.component";

type CalculationInputsTabProps = {
    experiment: ExperimentResponseModel;
    calculationJob: CalculationJobResultModel;
}
export const CalculationInputsTab: React.FC<CalculationInputsTabProps> = (props) => {
    const { calculationJob, experiment } = props;

    return <div>
        <Table>
            <TableHeader>
                <TableRow key="table-header">
                    <TableHeaderCell key="parameter" style={{ width: "400px" }} className='table__cell--bold'>Parameter</TableHeaderCell>

                    <TableHeaderCell key="value" className='table__cell--bold'>Value</TableHeaderCell>

                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={false}
                columnCount={2} loadingMessage="Loading..."
                itemCount={calculationJob?.inputs?.length ?? 0}
                noItemsMessage="No items found.">
                {!!calculationJob?.inputs &&
                    calculationJob.inputs.map((item, index) => {
                        return <TableRow key={`input-param-${index}`}>
                            <TableCell>
                                <ResultInputParameter parameter={item.parameter} unitInOneLine separationMethod={experiment.separationMethod} />
                            </TableCell>
                            <TableCell>{+Number(item.value).toFixed(3)}</TableCell>

                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
    </div>
}