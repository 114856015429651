import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import React from "react";
import { getStatisticsClient } from "../../services/dispatcher.service";
import { AggregatedUsegDataResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingService, useLoading } from "../../utils/loading-indicator.component";
import { TableBodyWithLoading } from "../table-body-with-loading/table-body-with-loading.component";

type DispatcherUsageStatisticsProps = {
    agentId?: number;
    applicationId?: number;
    flowsheetId?: string;
    flowsheetVersionId?: string;
    tag?: string;
    loadingService: LoadingService;
    isLoading: boolean;
}


interface IUsageDataRow {
    id: string;
    name: string;
    overall?: string;
    calculated?: string;
    failed?: string;
    unit?: string;
}
export type DispatcherUsageStatisticsType = {
    GetUsageStatistics: () => void
}

export const DispatcherUsageStatistics = React.forwardRef<DispatcherUsageStatisticsType, DispatcherUsageStatisticsProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            GetUsageStatistics() {
                GetUsageStatistics();
            }
        }));

    const [dataRows, setDataRows] = React.useState<IUsageDataRow[]>([]);


    React.useEffect(() => {
        GetUsageStatistics();
    }, []);

    const GetUsageStatistics = async () => {

        try {
            const { agentId, applicationId, flowsheetId, flowsheetVersionId, tag } = props;
            if (tag && tag.length > 0) {
                getUsageStatisticsByTag();
            } else if (agentId && agentId > 0) {
                getUsageStatisticsByAgentId();
            } else if (applicationId && applicationId > 0) {
                getUsageStatisticsByApplicationId();
            } else if (flowsheetVersionId && flowsheetVersionId.length > 0 && flowsheetId && flowsheetId.length > 0) {
                getUsageStatisticsByFlowsheetVersion();
            } else if (flowsheetId && flowsheetId.length > 0) {
                getUsageStatisticsByFlowsheetId();
            }

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting dispatcher usage statistics.", false);
        }

    }

    const getUsageStatisticsByTag = async () => {
        props.loadingService.showLoading("Loading statistics...", async (hideMessage) => {
            try {

                const { tag } = props;
                const client = getStatisticsClient();
                const usageData = await client.getStatisticsForTag(tag);
                extractUsageDataRows(usageData);
            } catch (error) {
                if ((error as any).status && (error as any).status == 404) {
                    console.log("No usage data found.");
                } else {
                    processServerError(error, undefined, "An error occurred while getting dispatcher usage statistics by tag.");

                }

            } finally {
                hideMessage();
            }
        });
    }

    const getUsageStatisticsByAgentId = async () => {
        props.loadingService.showLoading("Loading statistics...", async (hideMessage) => {
            try {

                const { agentId } = props;
                const client = getStatisticsClient();
                const usageData = await client.getStatisticsForAgent(agentId);
                extractUsageDataRows(usageData);
            } catch (error) {
                if ((error as any).status && (error as any).status == 404) {
                    console.log("No usage data found.");
                } else {
                    processServerError(error, undefined, "An error occurred while getting dispatcher usage statistics by agent id.");
                }
            } finally {
                hideMessage();
            }
        });
    }

    const getUsageStatisticsByApplicationId = async () => {
        props.loadingService.showLoading("Loading statistics...", async (hideMessage) => {
            try {
                const { applicationId } = props;
                const client = getStatisticsClient();
                const usageData = await client.getStatisticsForApplication(applicationId);
                extractUsageDataRows(usageData);
            } catch (error) {
                if ((error as any).status && (error as any).status == 404) {
                    console.log("No usage data found.");
                } else {
                    processServerError(error, undefined, "An error occurred while getting dispatcher usage statistics by application id.");

                }
            } finally {
                hideMessage();
            }
        });
    }
    const getUsageStatisticsByFlowsheetVersion = async () => {
        props.loadingService.showLoading("Loading statistics...", async (hideMessage) => {
            try {

                const { flowsheetVersionId, flowsheetId } = props;
                const client = getStatisticsClient();
                const usageData = await client.getStatisticsForFlowsheet2(flowsheetId, flowsheetVersionId);
                extractUsageDataRows(usageData);
            } catch (error) {
                if ((error as any).status && (error as any).status == 404) {
                    console.log("No usage data found.");
                } else {
                    processServerError(error, undefined, "An error occurred while getting dispatcher usage statistics by flowsheet version.");
                }
            } finally {
                hideMessage();
            }
        });
    }
    const getUsageStatisticsByFlowsheetId = async () => {
        props.loadingService.showLoading("Loading statistics...", async (hideMessage) => {
            try {

                const { flowsheetId } = props;
                const client = getStatisticsClient();
                const usageData = await client.getStatisticsForFlowsheet(flowsheetId);
                extractUsageDataRows(usageData);
            } catch (error) {
                if ((error as any).status && (error as any).status == 404) {
                    console.log("No usage data found.");
                } else {
                    processServerError(error, undefined, "An error occurred while getting dispatcher usage statistics by flowsheet id.");

                }
            } finally {
                hideMessage();
            }
        });
    }


    const extractUsageDataRows = (usageData: AggregatedUsegDataResponseModel) => {
        const rows: IUsageDataRow[] = [
            {
                id: "1", name: "Total jobs",
                overall: usageData?.totalCount?.totalJobs?.toString() ?? "-",
                calculated: usageData?.totalCount?.calculatedExecutions?.toString() ?? "-",
                failed: usageData?.totalCount?.failedExecutions?.toString() ?? "-"

            },
            {
                id: "2", name: "Total job executions",
                overall: usageData?.totalCount?.totalExecutions?.toString() ?? "-",
                calculated: usageData?.totalCount?.calculatedExecutions?.toString() ?? "-",
                failed: usageData?.totalCount?.failedExecutions?.toString() ?? "-"
            },
            {
                id: "3", name: "Average calculation duration",
                overall: usageData?.avgCalcDuration?.overall?.toFixed(2).toString() ?? "-",
                calculated: usageData?.avgCalcDuration?.calculatedExecutions?.toFixed(2).toString() ?? "-",
                failed: usageData?.avgCalcDuration?.failedExecutions?.toFixed(2).toString() ?? "-",
                unit: "s"
            },
            {
                id: "4", name: "Average setup & calculation duration",
                overall: usageData?.avgSetupAndCalcDuration?.overall?.toFixed(2).toString() ?? "-",
                calculated: usageData?.avgSetupAndCalcDuration?.calculatedExecutions?.toFixed(2).toString() ?? "-",
                failed: usageData?.avgSetupAndCalcDuration?.failedExecutions?.toFixed(2).toString() ?? "-",
                unit: "s"
            },
            {
                id: "5", name: "Total calculation time",
                overall: usageData?.calculationRuntime?.overall?.toFixed(2).toString() ?? "-",
                calculated: usageData?.calculationRuntime?.calculatedExecutions?.toFixed(2).toString() ?? "-",
                failed: usageData?.calculationRuntime?.failedExecutions?.toFixed(2).toString() ?? "-",
                unit: "s"
            },
            {
                id: "6", name: "Total setup & calculation time",
                overall: usageData?.setupAndCalculationRuntime?.overall?.toFixed(2).toString() ?? "-",
                calculated: usageData?.setupAndCalculationRuntime?.calculatedExecutions?.toFixed(2).toString() ?? "-",
                failed: usageData?.setupAndCalculationRuntime?.failedExecutions?.toFixed(2).toString() ?? "-",
                unit: "s"
            }
        ];
        setDataRows(rows);

    }


    return <Table className="s365-table">
        <TableHeader>
            <TableRow>
                <TableHeaderCell key="name" className='s365-table__cell--bold'>Name</TableHeaderCell>
                <TableHeaderCell key="overall" className='s365-table__cell--center s365-table__cell--bold'>Overall</TableHeaderCell>
                <TableHeaderCell key="calculated" className='s365-table__cell--center s365-table__cell--bold'>Calculated</TableHeaderCell>
                <TableHeaderCell key="failed" className='s365-table__cell--center s365-table__cell--bold'>Failed</TableHeaderCell>
            </TableRow>
        </TableHeader>
        <TableBodyWithLoading isLoading={props.isLoading}
            columnCount={4} loadingMessage="Loading..."
            itemCount={dataRows ? dataRows.length : 0}
            noItemsMessage="No items found.">
            {dataRows && dataRows.map((row) => {
                return <TableRow>
                    <TableCell>{row.name}</TableCell>
                    <TableCell className="s365-table__cell--center">{row.overall ?? "-"} {row.unit ?? ""}</TableCell>
                    <TableCell className="s365-table__cell--center">{row.calculated ?? "-"} {row.unit ?? ""}</TableCell>
                    <TableCell className="s365-table__cell--center">{row.failed ?? "-"} {row.unit ?? ""}</TableCell>
                </TableRow>
            })}
        </TableBodyWithLoading>
    </Table>
})