import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Spinner, DialogActions } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React, { useRef } from "react";
import { useLoading } from "../../../utils/loading-indicator.component";
import { getExportDataClient } from "../../../services/sensitivity-studies.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { ExportDataStatus } from "../../../swagger-clients/sensitivity-studies-api-clients.service";


type FileGenerationProgressModalProps = {
    isOpened: boolean;
    requestId: string;
    onHide: () => void;
}

export const FileGenerationProgressModal: React.FC<FileGenerationProgressModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [isFileBeingGenerated, setIsFileBeingGenerated] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading(true);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const statusRef = useRef<ExportDataStatus>();
    const intervalRef = useRef<NodeJS.Timer>();
    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    React.useEffect(() => {
        onInit();
        return () => clearInterval(intervalRef.current);
    }, []);

    const onInit = async () => {
        // await new Promise((resolve: any, reject: any) => {
        //     setTimeout(async () => {
        //         await checkGenerationStatus();
        //         resolve();
        //     }, 5000);
        // });

        let intervalMs = 5000;

        console.log("FileGenerationProgressModal onInit");
        intervalRef.current = setInterval(async () => {
            if (!!statusRef.current?.isGenerated) {
                return;
            }
            await checkGenerationStatus();
            intervalMs = 1000;
        }, intervalMs);

    }



    const checkGenerationStatus = async () => {

        const messageId = loadingService.showMessage("Checking for generation status...");
        try {
            const client = getExportDataClient();
            const statusResp = await client.getStatus(props.requestId);

            statusRef.current = statusResp;
            if (!statusResp) {
                setErrorMessage("Request not found.");
            } else {
                setIsFileBeingGenerated(!statusResp.isGenerated);
                setErrorMessage(undefined);
            }


        } catch (error) {
            processServerError(error, undefined, "An error occurred while checking generation request status.");
            clearInterval(intervalRef.current);
        } finally {
            loadingService.hideMessage(messageId);
        }

    }

    const onModalClose = () => {
        setOpen(false);
        if (props.onHide)
            props.onHide();
    }
    return <Dialog

        open={open}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle>
                    Export data in progress
                </DialogTitle>
                <DialogContent>
                    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        {isLoading && !statusRef.current && !errorMessage && <Spinner size="extra-small" appearance="primary" label="Checking generation status..." />}
                        {isFileBeingGenerated && <Spinner size="extra-small" appearance="primary" label="File is being generated..." />}
                        {!!errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}
                        {!!statusRef.current?.isGenerated && <span>
                            File <b>{statusRef.current?.filename}</b> was successfully generated.
                            <br /> File location: <b>{statusRef.current?.filepath}</b>.
                        </span>}
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button appearance="secondary" onClick={onModalClose} type="button">Close</Button>
                </DialogActions>

            </DialogBody>

        </DialogSurface>


    </Dialog>
}