import { createTableColumn, SelectionItemId, Table, TableCell, TableColumnDefinition, TableColumnSizingOptions, TableHeader, TableHeaderCell, TableRow, TableRowId, TableSelectionCell, useTableColumnSizing_unstable, useTableFeatures, useTableSelection } from "@fluentui/react-components";
import React from "react";
import { useLoading } from "../../../utils/loading-indicator.component";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import ShowLocalTime from "../../../components/show-local-time/show-local-time.component";
import { FileTableItem } from "../../models/file-table.models";
import { _copyAndSort } from "../../../utils/helpers/array.helpers";
import { processServerError } from "../../../utils/helpers/error.helper";
import { ApplicationSelectionModel } from "./copy-file-objects-dialog.component";
import { getExperimentsClient } from "../../../services/ai-for-pfd.service";
import { ExperimentStatusComponent } from "../../../components/experiment-status/experiment-status.component";
import { IExam } from "../../../take-home-exams/shared/exams.models";
import { ExamStatusComponent } from "../../../components/exam-status/exam-status.component";
import { GroupDisplay } from "../../../components/group-display/group-display.component";
import { getExamsClient } from "../../../services/take-home-exams.service";

type SelectTheeTableProps = {
    file: FileTableItem;
    //can use this to show number of items that are loaded
    onDataLoaded: (count: number) => void;
    onSelectedItemsChanged: (selectedItems: ApplicationSelectionModel[]) => void;
    selectedItems: number[];
}



export const SelectTheeTable: React.FC<SelectTheeTableProps> = (props) => {
    const [items, setItems] = React.useState<IExam[]>([]);

    const [isLoading, loadingService] = useLoading();
    const [columns, setColumns] = React.useState<TableColumnDefinition<IExam>[]>([]);
    const [selectedRows, setSelectedRows] = React.useState(
        () => new Set<TableRowId>(props.selectedItems)
    );
    React.useEffect(() => { getExams(); }, []);

    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        checkbox: {
            minWidth: 30,
            idealWidth: 30,
            autoFitColumns: false
        },
        id: {
            minWidth: 70,
            defaultWidth: 70,
            idealWidth: 70,
            autoFitColumns: false
        },
        name: {
            minWidth: 10,
            defaultWidth: 9000,
        },
        status: {
            minWidth: 100,
            defaultWidth: 200,
        },
        group: {
            minWidth: 100,
            defaultWidth: 300
        },
        date: {
            minWidth: 100,
            defaultWidth: 150
        },
        startTime: {
            minWidth: 50,
            defaultWidth: 100

        },
        duration: {
            minWidth: 50,
            defaultWidth: 100

        },
        created: {
            minWidth: 90,
            defaultWidth: 200

        }
    });


    const { getRows, columnSizing_unstable, tableRef, selection: {
        toggleRow,
        selectRow,
        clearRows,
        toggleAllRows
    }, } = useTableFeatures<IExam>(
        {
            columns,
            items,
            getRowId: (item: IExam) => (+item.id)

        },
        [useTableColumnSizing_unstable({ columnSizingOptions }),
        useTableSelection({
            selectionMode: "multiselect",
            selectedItems: selectedRows,
            onSelectionChange: (e, data) => {
                setSelectedRows(new Set<TableRowId>(data.selectedItems));
                const selectedIds = [...data.selectedItems].map(x => +x);
                const mappedSelections = selectedIds?.map(id => {
                    const experiment = items.find(x => x.id == id);
                    return { id: +experiment.id } as ApplicationSelectionModel;
                });
                props.onSelectedItemsChanged(mappedSelections);
            },
        })
        ]
    );
    const isRowSelected = (rowId: number) => {
        return selectedRows.has(rowId);
    }

    const rows = getRows((row) => {
        const selected = isRowSelected(+row.item.id);
        return {
            ...row,
            rowId: +row.item.id,
            selected
        };
    });
    React.useEffect(() => {
        const columnsLocal = getAllColumns();
        setColumns(columnsLocal);
    }, [selectedRows, items]);

    const getAllColumns = () => {

        const allRowsSelected = items.length > 0 && [...selectedRows].length > 0 && [...selectedRows].length == items.length;
        const someRowsSelected = items.length > 0 && [...selectedRows].length > 0 && !allRowsSelected;

        let columnsLocal: TableColumnDefinition<IExam>[] = [
            createTableColumn<IExam>({
                columnId: "checkbox",
                renderHeaderCell: () => {
                    return <TableSelectionCell
                        checked={
                            allRowsSelected ? true : someRowsSelected ? "mixed" : false
                        }
                        onClick={(ev) => onToggleAllRows(ev)}
                        checkboxIndicator={{ "aria-label": "Select all rows " }}
                    />
                },
                renderCell: (item) => {
                    const selected = isRowSelected(+item.id);

                    return <TableSelectionCell

                        checked={selected}
                        onClick={(ev) => { ev.stopPropagation(); toggleRow(ev, +item.id); }}
                        {...columnSizing_unstable.getTableCellProps("checkbox")}
                    />
                }
            }),
            createTableColumn<IExam>({
                columnId: "id",
                renderHeaderCell: () => <>ID</>,
                renderCell: (item: IExam) => {
                    return <span>{item.id}</span>;

                }
            }),
            createTableColumn<IExam>({
                columnId: "name",
                renderHeaderCell: () => <>Name</>,
                renderCell: (item: IExam) => {
                    return <span>{item.name}</span>;

                }
            }),
            createTableColumn<IExam>({
                columnId: "status",
                renderHeaderCell: () => <>Status</>,
                renderCell: (item: IExam) => {
                    return <ExamStatusComponent status={item.status} />;

                }
            }),
            createTableColumn<IExam>({
                columnId: "group",
                renderHeaderCell: () => <>Group</>,
                renderCell: (item: IExam) => {
                    return <GroupDisplay groupId={item.groupId} />;
                }
            }),
            createTableColumn<IExam>({
                columnId: "date",
                renderHeaderCell: () => <>Exam date</>,
                renderCell: (item: IExam) => {

                    return <ShowLocalTime date={item.date} />;

                }
            }),
            createTableColumn<IExam>({
                columnId: "startTime",
                renderHeaderCell: () => <>Start time</>,
                renderCell: (item: IExam) => {
                    return item.startTime;
                }
            }),
            createTableColumn<IExam>({
                columnId: "duration",
                renderHeaderCell: () => <>Duration</>,
                renderCell: (item: IExam) => {

                    return item.duration;
                }
            }),
            createTableColumn<IExam>({
                columnId: "created",
                renderHeaderCell: () => <>Created</>,
                renderCell: (item: IExam) => {

                    return <ShowLocalTime date={item.createdAt} multiline />;
                }
            })
        ];

        return columnsLocal;
    }



    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "status": return true;
            case "date": return true;
            case "startTime": return true;
            case "duration": return true;
            case "modified": return true;

            default: return false;
        }
    }


    const getExams = async () => {
        loadingService.showLoading("Getting exams...", async (hideMessage) => {
            try {
                const client = getExamsClient();
                let examsResponse = await client.getExams(props.file.uniqueIdentifier);
                let examsMapped: IExam[] = examsResponse.map(examResp => ({ ...examResp } as unknown as IExam));
                examsMapped = _copyAndSort<IExam>(examsMapped, "createdAt", true);
                examsMapped = examsMapped.filter(x => x.isDeleted == false);
                setItems(examsMapped);
                props.onDataLoaded(examsMapped?.length ?? 0);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get exams.");
            } finally {
                hideMessage();
            }
        });

    }


    const onToggleAllRows = (ev: any) => {
        toggleAllRows(ev);
    }

    return <div>
        <div className='scenarios-table__wrapper'>
            <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()} >
                <TableHeader>
                    <TableRow>
                        {columns.map((column) => (
                            <TableHeaderCell
                                onDragStart={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}
                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={5} loadingMessage="Loading..."
                    itemCount={items ? items.length : 0}
                    noItemsMessage="No items found.">
                    {!isLoading && rows.map(({ item, selected }) => (
                        <TableRow
                            key={`scenario-${item.id}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => { toggleRow(null, +item.id); }}
                        >

                            {columns.map((column) => (
                                <TableCell
                                    className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}

                        </TableRow>
                    ))}


                </TableBodyWithLoading>
            </Table>
        </div>
    </div>
}

