import * as React from "react";
import { useParams } from "react-router-dom";
import { processServerError } from "../../utils/helpers/error.helper";
import { getFilterWebFormsClient } from "../../services/dashboard.service";
import { FilterWebFormJobResult, JobProcessingStatus, JobStatusRequestModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import JobStatus from "../../components/job-status/job-status.component";
import nl2br from 'nl2br';
import { Button } from "@fluentui/react-components";

type FilterWebFormResultsPageProps = {
    requestId?: string;
    onJobFinished: () => void;
}



export const FilterWebFormResultsPage: React.FC<FilterWebFormResultsPageProps> = (props) => {

    const [jobResult, setJobResult] = React.useState<FilterWebFormJobResult>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showErrorDetails, setShowErrorDetails] = React.useState<boolean>(false);
    const statusLoadingInterval = React.useRef<number>();
    React.useEffect(() => {
        getJobStatus();

        statusLoadingInterval.current = window.setInterval(() => {
            getJobStatus();
        }, 5000);
        getJobStatus();

    }, [props.requestId]);

    React.useEffect(() => {
        setTimeout(() => { window.scrollTo(0, document.body.scrollHeight) }, 50);
    }, []);


    const getJobStatus = async () => {
        try {
            setIsLoading(true);
            const client = getFilterWebFormsClient();
            const model = new JobStatusRequestModel({ requestId: props.requestId });
            const resultResp = await client.getJobResult(model);
            setJobResult(resultResp);
            if (resultResp.status !== JobProcessingStatus.NotCalculated) {
                window.clearInterval(statusLoadingInterval.current);
                props.onJobFinished();
            }

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting job result.");

        } finally {
            setIsLoading(false);
        }

    }

    return <div>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous"></link>
        <div className="container my-4 p-0">

            {jobResult && jobResult.status == JobProcessingStatus.NotCalculated &&
                <div className="alert alert-info" role="alert">
                    The flowsheet calculation has started. This page refreshes every 5 seconds.<br />
                    The flowsheet calculation time depends on the size of the flowsheet and number of available agents.
                </div>}
            <dl className="row">

                {jobResult && jobResult.status !== JobProcessingStatus.Finished && <>
                    <dt className="col-sm-3 col-md-2">Job status</dt>
                    <dd className="col-sm-9 col-md-10"> <JobStatus value={jobResult.status} />
                        {
                            jobResult.status == JobProcessingStatus.NotCalculated && isLoading && <>
                                <div className="spinner-border text-primary spinner-border-sm ms-2" role="status">
                                </div>
                                <span>Loading...</span>
                            </>
                        }</dd></>
                }
            </dl>
            {jobResult && !!jobResult.error && jobResult.status == JobProcessingStatus.Failed && <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <span className="alert alert-danger w-100" role="alert">An error occured, try different input parameters.</span>
                    {!showErrorDetails && <Button appearance="primary" style={{ width: "fit-content" }} onClick={() => { setShowErrorDetails(true) }}>Show details</Button>}
                </div>
                {showErrorDetails && <span className="alert alert-danger w-100" role="alert" dangerouslySetInnerHTML={{ __html: `${nl2br(jobResult.error)}` }}></span>}
            </div>

            }
            {jobResult && !!jobResult.result && (jobResult.status == JobProcessingStatus.Finished || jobResult.status == JobProcessingStatus.Failed) &&
                <div dangerouslySetInnerHTML={{ __html: jobResult.result }}>

                </div>}


        </div>
    </div>
}