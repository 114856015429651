import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FilterWebFormDataPostModel, FilterWebFormDataRequestModel, FilterWebFormResponseModel, WebFormInputParameterPostModel, WebFormInputParameterResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { getFilterWebFormsClient } from "../../services/dashboard.service";
import MathJaxDisplay from "../../components/mathjax-display/mathjax-display";
import { useForm } from "react-hook-form";
import { Label, Field, Input, Button, InfoLabel } from "@fluentui/react-components";
import { GLOBAL_STYLES } from "../../styles";
import { InfoButton } from "@fluentui/react-components/unstable";
import { FilterWebFormResultsPage } from "./filter-web-form-results.page";
import sidebarLogo from '../../assets/sidebar-logo.png';
import './filter-web-form.page.scss';

type FilterWebFormPageProps = {

}

type FiltersWebFormRouteParams = {
    formId?: string;
}

// Input page
export const FilterWebFormPage: React.FC<FilterWebFormPageProps> = (props) => {
    const [isRequestInProgress, setIsRequestInProgress] = React.useState<boolean>(false);
    const routeParams = useParams<FiltersWebFormRouteParams>();
    const [currentRequestId, setCurrentRequestId] = React.useState<string>("");
    const [isJobCalculating, setIsJobCalculating] = React.useState<boolean>(false);
    const [filterData, setFilterData] = React.useState<FilterWebFormResponseModel>();
    const { register, handleSubmit, watch, setError, clearErrors, setValue, formState: { errors, isSubmitted }, reset } = useForm<any>();
    const [showNotFoundText, setShowNotFoundText] = React.useState<boolean>(false);


    const resizeElementRef = React.useRef(null);

    React.useEffect(() => {
        const resizeElement = resizeElementRef.current;

        const handleResize = entries => {
            if (entries?.length > 0 ?? false) {
                const { width, height } = entries[0].contentRect;
                sendHeight({ currentTarget: { clientHeight: height } as any } as any);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(resizeElement);

        // Clean up the observer when the component unmounts
        return () => {
            resizeObserver.disconnect();
        };
    }, []);



    React.useEffect(() => {

        getFilterData();
    }, []);

    const sendHeight = (ev: React.SyntheticEvent<HTMLDivElement, Event>) => {

        const height = ev.currentTarget.clientHeight;
        if (!!height) {
            window.parent.postMessage({ type: "update-s365-webform", height }, "*");
        }
    }


    const getFilterData = async () => {
        try {
            setShowNotFoundText(false);
            const client = getFilterWebFormsClient();
            const filterDataResp = await client.getPublicWebFromData(new FilterWebFormDataRequestModel({ formId: routeParams.formId }));
            if (!!filterDataResp) {
                setFilterData(filterDataResp);
            }

        } catch (error) {
            if ((error as any).status == 404) {
                setShowNotFoundText(true);
            } else {
                processServerError(error, undefined, "An error occurred while getting filter data.");
            }

        }
    }

    const onSubmit = async (data: any) => {
        console.log("onSubmit", data);
        setIsRequestInProgress(true);
        const inputParams = Object.entries(data)
            .filter(([key]) => key.startsWith('param_'))
            .map(([key, value]) => (new WebFormInputParameterPostModel({
                inputParameterId: +key.replace('param_', ''),
                value: +value,
            })));


        try {
            setCurrentRequestId("");
            const client = getFilterWebFormsClient();
            const model = new FilterWebFormDataPostModel({ formId: routeParams.formId, inputParameters: inputParams });
            const jobResp = await client.submitWebFormDataForCalculation(model);
            setCurrentRequestId(jobResp.jobUniqueId);
            setIsJobCalculating(true);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while submitting job.");
        } finally {
            setIsRequestInProgress(false);
        }

    }

    const getMinValueLimit = (inParam: WebFormInputParameterResponseModel) => {

        if (inParam.minValue === undefined)
            return undefined;

        return {
            value: inParam.minValue,
            message: `Minimum value is ${inParam.minValue}.`
        }
    }
    const getMaxValueLimit = (inParam: WebFormInputParameterResponseModel) => {

        if (inParam.maxValue === undefined)
            return undefined;

        return {
            value: inParam.maxValue,
            message: `Maximum value is ${inParam.maxValue}.`
        }
    }

    const designParameters = !!filterData && !!filterData.inputParameters ? filterData.inputParameters.filter(x => !!x.isDesignParameter) : [];
    const operationalParameters = !!filterData && !!filterData.inputParameters ? filterData.inputParameters.filter(x => !x.isDesignParameter) : [];

    return <div ref={resizeElementRef} className="filter-web-form-page" onLoad={(ev) => sendHeight(ev)} onResize={(ev) => sendHeight(ev)} >
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous"></link>


        <div className="container my-4">
            {!!showNotFoundText && <p>Web form not found.</p>}

            {!!filterData && <div style={{ display: "flex", flexDirection: "column" }}>
                {!!filterData.intro && <MathJaxDisplay html={filterData.intro} />}
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="my-2">
                    <div style={{ display: "flex", gap: "20px" }} >
                        {!!designParameters && designParameters.length > 0 && <div className="input-param-form" style={{ border: "1px solid black", backgroundColor: "rgb(228,246,253)", flex: 1 }}>
                            <h4><u>Design Parameters</u></h4>
                            {designParameters.map((inParam) => {
                                return <>
                                    <label htmlFor="staticEmail" className="label" style={{ textAlign: "right" }} >{inParam.name}:</label>

                                    <div className="input-field" style={{ alignSelf: "center" }}>

                                        <Field
                                            className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                                            validationState={errors[`param_${inParam.inputParameterId}`] ? "error" : "none"}
                                            validationMessage={errors[`param_${inParam.inputParameterId}`] ? errors[`param_${inParam.inputParameterId}`].message.toString() : undefined}
                                        >
                                            <Input
                                                defaultValue={inParam.showDefaultValue ? inParam.defaultValue?.toString() : undefined}
                                                type="number"
                                                className="form-control form-control-sm d-flex"
                                                readOnly={isRequestInProgress || isJobCalculating}
                                                {...register(`param_${inParam.inputParameterId}`, { required: { value: true, message: "Value is required." }, min: getMinValueLimit(inParam), max: getMaxValueLimit(inParam) })}
                                                contentAfter={!!inParam.description && <InfoLabel info={<>{inParam.description}</>} />}
                                            />

                                        </Field>
                                    </div>
                                </>
                            })}
                        </div>}
                        {!!operationalParameters && operationalParameters.length > 0 && <div className="input-param-form" style={{ border: "1px solid black", backgroundColor: "rgb(236,248,231)", flex: 1 }}>
                            <h4><u>Operational Parameters</u></h4>
                            {operationalParameters.map((inParam) => {
                                return <>
                                    <label htmlFor="staticEmail" className="label" style={{ textAlign: "right" }} >{inParam.name}:</label>

                                    <div className="input-field" style={{ alignSelf: "center" }}>

                                        <Field
                                            className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                                            validationState={errors[`param_${inParam.inputParameterId}`] ? "error" : "none"}
                                            validationMessage={errors[`param_${inParam.inputParameterId}`] ? errors[`param_${inParam.inputParameterId}`].message.toString() : undefined}
                                        >
                                            <Input
                                                defaultValue={inParam.showDefaultValue ? inParam.defaultValue?.toString() : undefined}
                                                type="number"
                                                className="form-control form-control-sm d-flex"
                                                readOnly={isRequestInProgress || isJobCalculating}
                                                {...register(`param_${inParam.inputParameterId}`, { required: { value: true, message: "Value is required." }, min: getMinValueLimit(inParam), max: getMaxValueLimit(inParam) })}
                                                contentAfter={!!inParam.description && <InfoLabel info={<>{inParam.description}</>} />}
                                            />

                                        </Field>
                                    </div>
                                </>
                            })}
                        </div>}

                    </div>


                    <label className="label" ></label>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Button type="submit" style={{ width: "fit-content", height: "fit-content" }} appearance="primary" disabled={isRequestInProgress || isJobCalculating}>Calculate</Button>

                        <span style={{
                            display: "flex",
                            fontSize: "1rem",
                            fontWeight: "400",
                            fontStyle: "italic",
                            alignItems: "center"
                        }}> powered by  <img className="disable-visual-search" src={sidebarLogo} style={{ width: "auto", height: "50px" }} alt='Simulate 365 logo' /></span>

                    </div>


                </form>

            </div>}

            {!!currentRequestId && <FilterWebFormResultsPage requestId={currentRequestId} onJobFinished={() => { setIsJobCalculating(false); }} />}
        </div>

    </div>
}