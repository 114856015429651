import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, ProgressBar } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { getDownloadFilesClient, getUserToken } from "../../services/dashboard.service";
import { GenerateZipStatus } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { settings } from "../../App";
import { Alert } from "@fluentui/react-components/unstable";

type DownloadFilesDialogProps = {
    downloadRequestId: string;
    isOpened?: boolean;
    isFolder?: boolean;
    onClose?: () => void;
};

export const DownloadFilesDialog: React.FC<DownloadFilesDialogProps> = (props: DownloadFilesDialogProps) => {


    const [open, setOpen] = React.useState(false);
    const [isRequestInProgress, setIsRequestInProgress] = React.useState<boolean>(false);
    const intervalIdRef = React.useRef<NodeJS.Timeout>();
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [showProgressBar, setShowProgressBar] = React.useState<boolean>(true);
    const [processFilesCount, setProccessFilesCount] = React.useState<number>(0);
    const [totalFilesCount, setTotalFilesCount] = React.useState<number>(1);


    React.useEffect(() => {
        intervalIdRef.current = setInterval(() => {
            checkGenerationStatus();
        }, 1000);
    }, []);

    const stopStatusCheck = () => {
        if (intervalIdRef.current)
            clearInterval(intervalIdRef.current);
    }
    const checkGenerationStatus = async () => {

        if (props.downloadRequestId) {
            try {
                setErrorMessage(undefined);
                const client = getDownloadFilesClient();
                const status = await client.getZipFilesStatus(props.downloadRequestId);
                if (status) {
                    if (status.status == GenerateZipStatus.Finished || status.status == GenerateZipStatus.Failed) {
                        stopStatusCheck();
                    }
                    if (status.status == GenerateZipStatus.Finished) {
                        downloadFile();
                    }
                    if (status.status == GenerateZipStatus.Failed) {
                        setErrorMessage(status.errorMessage);
                    }
                    if (status.status == GenerateZipStatus.InProgress) {
                        setShowProgressBar(true);
                        setProccessFilesCount(status.finishedCount ?? 0);
                        setTotalFilesCount(status.totalCount ?? 0);
                    }
                }
            } catch (error) {
                console.log("An error occurred while getting status of request to download files.", error);
                setErrorMessage("An error occurred while getting status of request to download files.");
            }
        }
    }
    const downloadFile = async () => {
        const userToken = await getUserToken();
        window.location.href = `${settings?.dashboardServiceUrl}/api/download-files/download/${props.downloadRequestId}?access_token=${userToken}`;
        onModalClose();
    }

    const onModalClose = () => {
        setOpen(false);

        if (props.onClose)
            props.onClose();
    }

    React.useEffect(() => {
        setOpen(!!props.isOpened);


    }, [props.isOpened]);




    return (<Dialog key={`create-directory-modal`} open={open} modalType="modal" onOpenChange={(event, data) => {

        setOpen(data.open);
        if (data.open == false) {
            if (props.onClose)
                props.onClose();
        }

    }}>
        <DialogSurface>
            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={!isRequestInProgress ? onModalClose : undefined}
                    icon={!isRequestInProgress ? <Dismiss24Filled /> : undefined} />}>
                    {props.isFolder ? "Download Folder" : "Download files"}
                </DialogTitle>
                <DialogContent style={{ display: "flex" }}>
                    {!!errorMessage && <Alert intent="error">{errorMessage}</Alert>}
                    {!errorMessage && showProgressBar &&
                        <ProgressBar max={totalFilesCount} value={processFilesCount} thickness="large" />}
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>);
}
