import { Label } from "@fluentui/react-components";
import React from "react";
import { getJobActionsClient } from "../../services/dispatcher.service";
import { WebHookActionHttpMethod, WebHookActionPayloadType, WebHookActionResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";

type WebHookActionDetailsProps = {
    actionId: number;
}

export const WebHookActionDetails: React.FC<WebHookActionDetailsProps> = (props) => {
    const [actionDetails, setActionDetails] = React.useState<WebHookActionResponseModel>();

    React.useEffect(() => {
        if (props.actionId) {
            getDetails();
        }
    }, [props.actionId]);

    const getDetails = async () => {
        try {

            const client = getJobActionsClient();
            const details = await client.getWebHookAction(props.actionId);
            setActionDetails(details);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting WebHook action details.");
        }
    }

    const payloadType = actionDetails ? getPayloadType(actionDetails.payloadType) : "";
    const httpMethod = actionDetails ? getWebHookActionHTTPMethod(actionDetails.httpMethod) : "";
    return <div>
            <div className="input-form">
                <div className="input-form-item">
                    <Label className="input-form-label">Endpoint:</Label>
                    <p
                        style={{ padding: "5px 0px", margin: "0 0 0 10px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                        title={actionDetails ? actionDetails.endpoint : ""}>{actionDetails ? actionDetails.endpoint : ""}</p>
                </div>
                <div className="input-form-item">
                    <Label className="input-form-label">HTTP Method:</Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{httpMethod}</p>
                </div>
                <div className="input-form-item">
                    <Label className="input-form-label">Payload type:</Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{payloadType}</p>
                </div>
            </div>

            {actionDetails && actionDetails.headers && actionDetails.headers.length > 0 &&
                <div style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                    <Label style={{ fontWeight: 600 }}>Headers:</Label>
                    <table className="table">
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                        {actionDetails.headers.map(header => (
                            <tr>
                                <td>{header.name}</td>
                                <td style={{ wordBreak: "break-all" }}>{header.value}</td>
                            </tr>))
                        }

                    </table>
                </div>
            }
            {
                actionDetails && actionDetails.payloadType == WebHookActionPayloadType.CustomFormdata
                && actionDetails.formParameters && actionDetails.formParameters.length > 0 &&
                <div style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                    <Label style={{ fontWeight: 600 }}>Payload:</Label>
                    <table className="table">
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                        {actionDetails.formParameters.map(parameter => (
                            <tr>
                                <td>{parameter.name}</td>
                                <td style={{ wordBreak: "break-all" }}>{parameter.value}</td>
                            </tr>))
                        }

                    </table>
                </div>
            }
            {actionDetails && actionDetails.payloadType == WebHookActionPayloadType.CustomJson &&
                <div style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                    <Label style={{ fontWeight: 600, margin: "0px", marginBottom: "10px" }}>Payload:</Label>
                    <pre style={{ backgroundColor: "lightgray", whiteSpace: "pre-wrap" }}>
                        {actionDetails.body}
                    </pre>
                </div>

            }

    </div>
}

const getPayloadType = (payloadType: WebHookActionPayloadType): string => {

    switch (payloadType) {
        case WebHookActionPayloadType.CustomFormdata:
            return "Custom form data";
        case WebHookActionPayloadType.CustomJson:
            return "Custom JSON";

        case WebHookActionPayloadType.DefaultJson:
            return "Default JSON";

        default: return "";
    }
}

const getWebHookActionHTTPMethod = (httpMethod: WebHookActionHttpMethod): string => {

    switch (httpMethod) {
        case WebHookActionHttpMethod.Get:
            return "GET";
        case WebHookActionHttpMethod.Post:
            return "POST";
        case WebHookActionHttpMethod.Delete:
            return "DELETE";
        case WebHookActionHttpMethod.Patch:
            return "PATCH";
        case WebHookActionHttpMethod.Put:
            return "PUT";
        default:
            return "";
    }

}