import { Toolbar, ToolbarButton, Label, Radio, RadioGroup, Tab, TabList, Button, Field, Input, Combobox, Textarea } from "@fluentui/react-components";
import { InfoButton } from "@fluentui/react-components/unstable";
import { Option } from "@fluentui/react-components";

import { FolderOpenRegular, Save20Regular } from "@fluentui/react-icons";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationPicker } from "../../components/application-picker/application-picker.component";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { DialogFilePicker } from "../../components/dialog-file-picker/dialog-file-picker.component";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import FilterPicker from "../../components/filter-picker/filter-picker.component";
import { flowsheetExtensions } from "../../global.variables";
import { getFilesClient, getFiltersClient, getUserToken } from "../../services/dashboard.service";
import { getAnalyzeFileClient, getScenariosClient } from "../../services/excel-runner.service";
import { AnalyzeFilePostModel, AnalyzeParametersPostModel, CalculationType, ParameterModel2, ScenarioPostModel, ScenarioQuery } from "../../swagger-clients/excel-runner-api-clients.service";
import { BreadcrumbItem, FileModel, FilterParameterType, FilterQuery, FilterResultModel, IFilterQuery, InputFilterParameterResultModel, OutputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { _copyAndSort } from "../../utils/helpers/array.helpers";
import { processServerError } from "../../utils/helpers/error.helper";
import { IValidationResultData, nameof, ValidationResult } from "../../utils/helpers/validation.helpers";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { IChoiceGroupOption, IDropdownOption } from "../../utils/shared.types";
import { ScenarioInputParametersTab } from "./input-parameter-tab/input-parameter-tab.component";
import { INewScenario } from "./models/scenario.models";
import { ScenarioOutputParametersTab } from "./output-parameter-tab/output-parameter-tab.component";

type EditScenarioProps = {

}
export type ExcelRunnerRouteParams = {
    uniquefileId: string,
    scenarioId?: string
}
const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];

const scenarioCalculationTypes: IChoiceGroupOption[] = [
    { key: '1', text: 'Run all' },
    { key: '2', text: 'Run step by step' },

];

export const EditScenario: React.FC<EditScenarioProps> = (props) => {
    const routeParams = useParams<ExcelRunnerRouteParams>();

    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [selectedTab, setSelectedTab] = React.useState<string>("input-parameters");
    const [sheetsDropdownOptions, setSheetsDropdownOptions] = React.useState<IDropdownOption[]>([]);
    const [analysisRequestInProgress, setAnalysisRequestInProgress] = React.useState<boolean>(false);
    const [selectedScenario, setSelectedScenario] = React.useState<INewScenario>({ name: "", filterId: null, filterVersionId: null, sheet: null, firstRow: 4,  calculationType: CalculationType.RunAll, stepEvaluationFunction: null, inputParameters: [], outputParameters: [], saveResultsToSourceFile: false });
    const [selectedFilter, setSelectedFilter] = React.useState<FilterResultModel>(undefined);
    const [selectedFilterDocument, setSelectedFilterDocument] = React.useState<FileModel>();
    const [showFilePicker, setShowFilePicker] = React.useState<boolean>(false);
    const [isFormSubmitted, setSubmitionState] = React.useState<boolean>(false);
    const [isFormDisabled, setFormDisabled] = React.useState<boolean>(false);
    const [cloneFilterVersionChanged, setCloneFilterVersionChanged] = React.useState<boolean>(false);
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const cloneId = searchParams.get('cloneId');

    React.useEffect(() => {
        getFile();
    }, []);

    React.useEffect(() => {
        if (selectedFile) {

            loadingService.showLoading(
                "Analyzing Excel file, loading sheets...",
                (hideMessage) => {
                    analyzeFileGetFilterAndSheetsAsync().finally(() => hideMessage());
                }
            );

            //Clone scenario logic          
            const queryFilterId = searchParams.get('filterId');
            const queryFilterVersionId = searchParams.get('filterVersionId');
            if (queryFilterId && queryFilterVersionId) {
                setSelectedScenario(s => ({
                    ...s,
                    filterId: +queryFilterId,
                    filterVersionId: +queryFilterVersionId,
                }) as INewScenario);
            }

            if (cloneId) {
                getScenario(+cloneId).then((scenario) => {
                    setSelectedScenario(s => ({
                        ...s, ...scenario,
                        cloneFilterVersionId: scenario.filterVersionId,
                        cloneFilterId: scenario.filterId,
                        inputParameters: [],
                        outputParameters: []
                    }) as INewScenario);


                    getFilterByVersion(scenario.filterId, scenario.filterVersionId).then((scenarioFilter) => {
                        console.log("getFilterByVersion result", scenarioFilter, scenario);
                        scenarioFilter.inputFilterParameters = _copyAndSort<InputFilterParameterResultModel>(scenarioFilter.inputFilterParameters, "order", false);
                        scenarioFilter.outputFilterParameters = _copyAndSort<OutputFilterParameterResultModel>(scenarioFilter.outputFilterParameters, "order", false);

                        if (scenario.inputParameters && scenario.inputParameters.length) {
                            for (let ip of scenarioFilter.inputFilterParameters) {
                                const ipscenario = scenario.inputParameters.find(x => +x.filterParameterId == ip.id);
                                console.log("ipscenario", ipscenario);
                                if (ipscenario)
                                    setInputParameterColumn(ip.id, ipscenario.column);
                            }
                        }

                        // Set suggestions for output parameters
                        if (scenario.outputParameters && scenario.outputParameters.length) {
                            for (let op of scenarioFilter.outputFilterParameters) {
                                const opscenario = scenario.outputParameters.find(x => +x.filterParameterId == op.id);
                                if (opscenario)
                                    setOutputParameterColumn(op.id, opscenario.column);
                            }
                        }
                        setSelectedFilterDocument({
                            id: scenarioFilter.fileId,
                            currentVersionNumber: +scenarioFilter.fileVersionNumber,
                            uniqueIdentifier: scenarioFilter.fileUniqueIdentifier
                        } as unknown as FileModel);

                        onSelectFilter(scenarioFilter.id);

                    });
                });
            }


        }
    }, [selectedFile]);

    // Try to analyze parameters when sheet and filter are selected
    React.useEffect(() => {
        if (selectedScenario.filterId && selectedScenario.sheet) {
            //If there is no query params
            if (!cloneId) {

                setSelectedScenario(s => ({
                    ...s,
                    inputParameters: [],
                    outputParameters: []
                }));

                loadingService.showLoading(
                    "Analyzing Excel file, loading suggested parameters...",
                    (hideMessage) => {
                        analyzeFileGetParametersAsync().finally(() => hideMessage());
                    }
                );
            }
        }
    }, [selectedScenario.filterId, selectedScenario.sheet]);

    React.useEffect(() => {
        if (selectedFilter && selectedScenario && cloneId) {
            if (Object.keys(selectedScenario.inputParameters)?.length > 0 || Object.keys(selectedScenario.outputParameters)?.length > 0) {
                recalculateFilterParams();
            }
        }

    }, [selectedFilter]);


    const onSaveClick = async () => {
        setSubmitionState(true);

        console.log("On save", selectedScenario, selectedFilter);

        let validationResult = validate(selectedScenario, selectedFilter);
        console.log("validationResult", validationResult);
        if (validationResult.isInvalid()) {

            let globalErrors = validationResult.getGlobalErrors();
            if (globalErrors && globalErrors.length > 0) {
                toast.error(globalErrors.join("\n"));
                //console.log(globalErrors.join("\n"));
            }
        }
        else {
            // Submit
            loadingService.showLoading("Saving scenario...", async (hideMessage) => {
                try {
                    let client = getScenariosClient();
                    const accessToken: string = await getUserToken();

                    // Input parameters
                    let inputParameters: ParameterModel2[] = [];
                    for (let filterParameterId in selectedScenario.inputParameters) {
                        inputParameters.push(new ParameterModel2({
                            parameterId: filterParameterId as any,
                            column: selectedScenario.inputParameters[filterParameterId]
                        }));
                    }

                    // Output parameters
                    let outputParameters: ParameterModel2[] = [];
                    for (let filterParameterId in selectedScenario.outputParameters) {
                        outputParameters.push(new ParameterModel2({
                            parameterId: filterParameterId as any,
                            column: selectedScenario.outputParameters[filterParameterId]
                        }));
                    }

                    const postModel = new ScenarioPostModel({
                        accessToken: accessToken,
                        calculationType: selectedScenario.calculationType,
                        driveId: "s365v2",
                        fileDriveId: selectedFile.uniqueIdentifier,
                        fileVersion: selectedFile.currentVersionNumber?.toString(),
                        filterId: selectedScenario.filterId,
                        filterVersionId: selectedScenario.filterVersionId,
                        name: selectedScenario.name,
                        sheet: selectedScenario.sheet,
                        firstRow: selectedScenario.firstRow,
                        inputParameters: inputParameters,
                        outputParameters: outputParameters,                      
                        stepEvaluationFunction: selectedScenario.stepEvaluationFunction,
                        // saveResultsToSourceFile: selectedScenario.saveResultsToSourceFile
                        saveResultsToSourceFile: false
                    });

                    var response = await client.saveScenario(postModel);

                    toast.success("Experiment saved successfully.");

                    navigate(`/files/${selectedFile.uniqueIdentifier}/doe-xls/details/${response.id}`);
                }
                catch (error) {
                    processServerError(error, undefined, "An error occured while saving experiment.");
                } finally {
                    hideMessage();
                }
            });

        }

    }
    const getFile = async () => {
        loadingService.showLoading("Getting file...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                const resp = await client.getFileLatest(routeParams.uniquefileId!, true);
                if (resp) {
                    setSelectedFile(resp.file);
                    setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
                }
                return resp;

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting file information.");
            } finally {
                hideMessage();
            }
        });

    }

    const getScenario = async (scenarioId: number) => {
        const messageId = loadingService.showMessage("Getting experiment...");
        try {

            const client = getScenariosClient();
            const accessToken: string = await getUserToken();

            const query = {
                scenarioId: scenarioId,
                accessToken: accessToken,
                driveItemId: selectedFile.uniqueIdentifier,
                flowsheetsListId: "s365v2",
                siteId: "s365v2"
            } as unknown as ScenarioQuery;

            const scenario = await client.getScenario(query);

            return scenario;
        }
        catch (error) {
            processServerError(error, undefined, "An error occurred while getting experiment.");
        } finally {
            loadingService.hideMessage(messageId);
        }
    }

    const getFilterByVersion = async (filterId: number, filterVersionId: number) => {
        try {

            const client = getFiltersClient();
            const filter = await client.getFilterWithoutFile(filterId, filterVersionId);
            return filter;

        } catch (error) {
            processServerError(error, undefined, "An errror occurred while trying to get filter by version.");
        }
    }

    const analyzeFileGetFilterAndSheetsAsync = async () => {
        try {
            let client = getAnalyzeFileClient();


            const accessToken = await getUserToken();

            var postModel = new AnalyzeFilePostModel({
                fileId: routeParams.uniquefileId,
                driveId: "s365v2",
                accessToken: accessToken
            });

            setAnalysisRequestInProgress(true);
            let response = await client.analyzeFilterAndSheets(postModel);

            var dropDownOptions = response.sheets.map(s => {
                return { key: s, text: s } as IDropdownOption;
            });

            setSheetsDropdownOptions(dropDownOptions);

            // If there is only one sheet, select it by default
            if (dropDownOptions && dropDownOptions.length == 1) {
                selectSheet(dropDownOptions[0].key.toString());
            }

          
        }
        catch (error) {
            processServerError(error, undefined, "An error occured while analyzing Excel file.");
        }
        finally {
            setAnalysisRequestInProgress(false);
        }
    };

    // Analyze parameters
    const analyzeFileGetParametersAsync = async () => {
        try {
            let client = getAnalyzeFileClient();
            const { filterId, sheet } = selectedScenario;
            const accessToken = await getUserToken();

            const postModel = new AnalyzeParametersPostModel();
            postModel.init({
                fileId: selectedFile.uniqueIdentifier,
                driveId: "s365v2",
                accessToken: accessToken,
                filterId: selectedScenario.filterId,
                sheet: selectedScenario.sheet
            });

            setAnalysisRequestInProgress(true);

            let response = await client.analyzeParameters(postModel);

            // Set suggestion for first row
            if (response.firstRow) {
                setSelectedScenario(s => ({ ...s, firstRow: response.firstRow }));
            }
            if (!selectedScenario.cloneFilterVersionId || selectedScenario.cloneFilterVersionId == selectedScenario.filterVersionId) {
                // Set suggestions for input parameters
                if (response.inputParameters && response.inputParameters.length) {
                    for (let ip of response.inputParameters) {
                        setInputParameterColumn(ip.filterParameterId, ip.column);
                    }
                }

                // Set suggestions for output parameters
                if (response.outputParameters && response.outputParameters.length) {
                    for (let op of response.outputParameters) {
                        setOutputParameterColumn(op.filterParameterId, op.column);
                    }
                }
            }
        }
        catch (error) {
            processServerError(error, undefined, "An error occured while analyzing parameters from Excel file.");
        }
        finally {
            setAnalysisRequestInProgress(false);
        }
    }

    const recalculateFilterParams = async () => {

        try {

            let inputParams = { ...selectedScenario.inputParameters };
            let outputParams = { ...selectedScenario.outputParameters };

            //if filter or filter version changed, try to match and remove incorrect input parameter values
            const scenarioFilter = await getFilterByVersion(selectedScenario.cloneFilterId, selectedScenario.cloneFilterVersionId);
            console.log("new filter, old filter", selectedFilter, scenarioFilter, inputParams);

            if (selectedFilter && scenarioFilter && selectedFilter.id == scenarioFilter.id && selectedFilter.currentVersionId !== scenarioFilter.currentVersionId) {
                for (let paramId in inputParams) {
                    const paramExists = selectedFilter.inputFilterParameters.find(x => x.id == parseInt(paramId));
                    if (!paramExists) {
                        const param = scenarioFilter.inputFilterParameters.find(x => x.id == parseInt(paramId));
                        if (param) {
                            const newParam = selectedFilter.inputFilterParameters.find(x => x.alias == param.alias);
                            if (newParam) {
                                console.log("Setting new param to:", newParam, inputParams[paramId]);
                                inputParams[newParam.id] = inputParams[paramId];
                            }
                        }
                        delete inputParams[paramId];
                    }
                }


                for (let paramId in outputParams) {
                    const paramExists = selectedFilter.outputFilterParameters.find(x => x.id == parseInt(paramId));
                    if (!paramExists) {

                        const param = scenarioFilter.outputFilterParameters.find(x => x.id == parseInt(paramId));
                        if (param) {
                            const newParam = selectedFilter.outputFilterParameters.find(x => x.alias == param.alias);
                            if (newParam) {
                                outputParams[newParam.id] = outputParams[paramId];
                            }
                        }

                        delete outputParams[paramId];
                    }
                }

                setSelectedScenario(s => ({
                    ...s,
                    inputParameters: { ...inputParams },
                    outputParameters: { ...outputParams }
                }));
            }


            //  console.log("inputParams, outputParams", inputParams, outputParams);



        } catch (error) {
            processServerError(error, undefined, "An error occurred while recalculating filter parameters.");
        }


    }

    // On sheet selected
    const selectSheet = async (sheetName: string) => {
        setSelectedScenario(s => ({
            ...s,
            sheet: sheetName
        }));
    };
    const onSelectFilter = (filterId: number) => {
        loadingService.showLoading(
            "Loading Filter...",
            (hideMessage) => {
                loadFilterAsync(filterId).finally(() => {
                    hideMessage();

                });
            }
        );
    }

    const loadFilterAsync = async (filterId: number) => {
        try {
            const client = getFiltersClient();
            const filter = await client.getFilterWithoutFileLatest(filterId);


            const sortedInputParams = _copyAndSort<InputFilterParameterResultModel>(filter.inputFilterParameters, "order", false);
            const sortedOutputParams = _copyAndSort<OutputFilterParameterResultModel>(filter.outputFilterParameters, "order", false);

            setSelectedFilter({ ...filter, inputFilterParameters: sortedInputParams, outputFilterParameters: sortedOutputParams } as FilterResultModel);

            setSelectedScenario(s => ({
                ...s,
                filterId: filter.id,
                filterVersionId: filter.currentVersionId
            }));



        } catch (error) {
            console.log("An error occurred while trying to get filter.", error);
        }
    };


    const setInputParameterColumn = (filterParameterId: number, column: string) => {



        setSelectedScenario(s => (
            {
                ...s,
                inputParameters: {
                    ...s.inputParameters,
                    [parseInt(filterParameterId as any)]: column
                }
            }
        ));
    }

    const setOutputParameterColumn = (filterParameterId: number, column: string) => {
        setSelectedScenario(s => (
            {
                ...s,
                outputParameters: {
                    ...s.outputParameters,
                    [parseInt(filterParameterId as any)]: column
                }
            }
        ));
    }


    const validate = (scenario: INewScenario, filter: FilterResultModel): ValidationResult => {
        console.log("Validate experiment called,", scenario);
        let result = {
            fieldsErrors: {},
            globalErrors: []
        } as IValidationResultData;

        if (!scenario.name)
            result.fieldsErrors[nameof<INewScenario>('name')] = "Experiment name is required.";

        if (!scenario.filterId)
            result.fieldsErrors[nameof<INewScenario>('filterId')] = "Filter is required.";

        if (!scenario.sheet)
            result.fieldsErrors[nameof<INewScenario>('sheet')] = "Excel sheet is required.";

        if (scenario.calculationType == CalculationType.StepByStep && !scenario.stepEvaluationFunction)
            result.fieldsErrors[nameof<INewScenario>('stepEvaluationFunction')] = "Step evaluation function is required.";
    

        // Input params
        if (!filter || !filter.inputFilterParameters || !filter.inputFilterParameters.length)
            result.globalErrors.push("At least one input parameter must be specified.")
        else {
            for (let ip of filter.inputFilterParameters.filter(p => p.parameterType == FilterParameterType.ExpectedInput)) {
                if (!scenario.inputParameters[ip.id]) {
                    result.fieldsErrors[`ip_${ip.id}`] = "Parameter is required.";
                }
                else if (!(/^[A-z]+$/.test(scenario.inputParameters[ip.id]))) {
                    result.fieldsErrors[`ip_${ip.id}`] = "Only letters are allowed.";
                } else {
                    let paramsWithSameValue = [];
                    Object.values(scenario.inputParameters).forEach((value, index) => {
                        if (value.toLowerCase() == scenario.inputParameters[ip.id].toLowerCase()) {
                            paramsWithSameValue.push(value);
                        }
                    });


                    Object.values(scenario.outputParameters).forEach((value, index) => {
                        if (value.toLowerCase() == scenario.inputParameters[ip.id].toLowerCase()) {
                            paramsWithSameValue.push(value);
                        }
                    });

                    if (paramsWithSameValue.length > 1) {
                        result.fieldsErrors[`ip_${ip.id}`] = "Field must be unique in both input and output.";
                    }

                }

            }
        }

        // Output params
        if (!filter || !filter.outputFilterParameters || !filter.outputFilterParameters.length)
            result.globalErrors.push("At least one output parameter must be specified.")
        else {
            for (let op of filter.outputFilterParameters.filter(p => p.parameterType == FilterParameterType.ExpectedOutput)) {
                if (!scenario.outputParameters[op.id]) {
                    result.fieldsErrors[`op_${op.id}`] = "Parameter is required.";
                }
                else if (!(/^[A-z]+$/.test(scenario.outputParameters[op.id]))) {

                    result.fieldsErrors[`op_${op.id}`] = "Only letters are allowed.";

                } else {
                    let paramsWithSameValue = [];
                    Object.values(scenario.inputParameters).forEach((value, index) => {
                        if (value.toLowerCase() == scenario.outputParameters[op.id].toLowerCase()) {
                            paramsWithSameValue.push(value);
                        }
                    });


                    Object.values(scenario.outputParameters).forEach((value, index) => {
                        if (value.toLowerCase() == scenario.outputParameters[op.id].toLowerCase()) {
                            paramsWithSameValue.push(value);
                        }
                    });

                    if (paramsWithSameValue.length > 1) {
                        result.fieldsErrors[`op_${op.id}`] = "Field must be unique in both input and output.";
                    }

                }
            }
        }

        return new ValidationResult(result);
    }

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }

    let validationResult = isFormSubmitted ? validate(selectedScenario, selectedFilter) : new ValidationResult(undefined);

    return <div className="content-wrapper edit-scenario">

        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton appearance='subtle' disabled={isLoading}
                    onClick={() => { loadingService.showLoading("Saving...", (hideMessage) => { onSaveClick().finally(() => hideMessage()); }); }}
                    icon={<Save20Regular />}>Save</ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />
            </Toolbar>
        </div>

        <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/doe-xls`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-doe`} active={true}>Design of Experiments</Breadcrumb>

            </Breadcrumbs>
        </div>

        <div className="input-form">
            <div className="input-form-item">
                <Label className="input-form-label">
                    Experiment name*:
                </Label>
                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<INewScenario>('name'), isFormSubmitted)}
                    validationState={validationResult.getFieldValidationMessage(nameof<INewScenario>('name'), isFormSubmitted) ? "error" : "none"}>
                    <Input
                        autoComplete="off"
                        maxLength={50}
                        disabled={isLoading}
                        value={selectedScenario?.name}
                        onChange={(ev, data) => setSelectedScenario(s => ({ ...s, name: data.value }))} />
                </Field>
            </div>

            <div className="input-form-item">
                <Label className="input-form-label">
                    Flowsheet:
                </Label>
                {selectedFilterDocument && <FileDisplayName
                    fileUniqueIdentifier={selectedFilterDocument.uniqueIdentifier}
                    fileVersionNumber={selectedFilterDocument.currentVersionNumber}
                />}
                <Button appearance="secondary"
                    style={{ marginLeft: "var(--spacingHorizontalS)" }}
                    icon={<FolderOpenRegular />}
                    onClick={() => { setShowFilePicker(true) }}>Browse...</Button>
                {showFilePicker && <DialogFilePicker
                    isOpened={true}
                    filterFileTypes={flowsheetExtensions}
                    onClose={() => { setShowFilePicker(false); }}
                    onFileSelected={(file: FileModel) => { setSelectedFilterDocument(file); setShowFilePicker(false); }} />}
            </div>

            {selectedFilterDocument && <div className="input-form-item">
                <Label className="input-form-label">
                    Filter*:
                </Label>
                <FilterPicker
                    errorMessage={validationResult.getFieldValidationMessage(nameof<INewScenario>('filterId'), isFormSubmitted)}
                    className="input-form-field"
                    filterId={selectedScenario.filterId}
                    filterVersionId={selectedScenario.filterVersionId}
                    fileId={selectedFilterDocument?.id}
                    onChange={(filterId: number) => onSelectFilter(filterId)}
                    style={{ width: "100%" }}
                    disabled={isFormDisabled || isLoading}
                />
            </div>}
            <div className="input-form-item">
                <Label className="input-form-label">
                    Sheet:
                </Label>

                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<INewScenario>('sheet'), isFormSubmitted)}
                    validationState={validationResult.getFieldValidationMessage(nameof<INewScenario>('sheet'), isFormSubmitted) ? "error" : "none"}>
                    <Combobox
                        value={selectedScenario.sheet}
                        disabled={isLoading}
                        placeholder="Select sheet"
                        autoComplete="off"
                        onOptionSelect={(ev, data) => { setSelectedScenario(s => ({ ...s, sheet: data.optionValue })) }}

                    >
                        {sheetsDropdownOptions.map((option) => {
                            return <Option value={option.key?.toString()}>
                                {option.text}
                            </Option>
                        })}
                    </Combobox>
                </Field>
            </div>
            <div className="input-form-item">
                <Label className="input-form-label">
                    Calculation type:
                </Label>
                <RadioGroup
                    name="saveCalculatedFlowsheets"
                    className="input-form-field"
                    disabled={isLoading}
                    value={selectedScenario ? selectedScenario.calculationType?.toString() : ""}
                    onChange={(ev, data) => { setSelectedScenario(s => ({ ...s, calculationType: +data.value })) }}
                >
                    {scenarioCalculationTypes.map((option) => {
                        return <Radio
                            label={option.text}
                            value={option.key?.toString()}
                        />
                    })}

                </RadioGroup>
            </div>
            <div className="input-form-item">
                <Label className="input-form-label">
                    First row:
                </Label>
                <Field
                    className="input-form-field"
                    validationMessage={validationResult.getFieldValidationMessage(nameof<INewScenario>('firstRow'), isFormSubmitted)}
                    validationState={validationResult.getFieldValidationMessage(nameof<INewScenario>('firstRow'), isFormSubmitted) ? "error" : "none"}>
                    <Input
                        type="number"
                        value={selectedScenario?.firstRow?.toString()}
                        disabled={isLoading}
                        onChange={(ev, data) => { setSelectedScenario(s => ({ ...s, firstRow: +data.value })) }}
                    />
                </Field>
                <InfoButton info={<>The row number in your Excel file where your data starts.</>} />
            </div>           

        </div>

        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
            <Tab key="input-tab" value="input-parameters" >Input parameters</Tab>
            <Tab key="output-tab" value="output-parameters">Output parameters</Tab>
            {selectedScenario.calculationType == CalculationType.StepByStep && <Tab key="step-by-step-tab" value="step-by-step">Step by step settings</Tab>}
        </TabList>
        <div className="tab-content">
            {selectedTab == "input-parameters" && selectedFilterDocument &&
                <div>
                    <ScenarioInputParametersTab
                        inputParameters={selectedFilter?.inputFilterParameters ?? []}
                        selectedFilter={selectedFilter}
                        selectedFilterDocument={selectedFilterDocument}
                        values={selectedScenario.inputParameters}
                        isFormSubmitted={isFormSubmitted}
                        isFormDisabled={isFormDisabled}
                        validationResult={validationResult}
                        isLoading={isLoading}
                        onChange={(p, c) => setInputParameterColumn(p, c)}
                    />
                </div>
            }
            {selectedTab == "output-parameters" && selectedFilterDocument &&
                <><ScenarioOutputParametersTab
                    outputParameters={selectedFilter?.outputFilterParameters}
                    selectedFilter={selectedFilter}
                    selectedFilterDocument={selectedFilterDocument}
                    values={selectedScenario.outputParameters}
                    isFormSubmitted={isFormSubmitted}
                    isFormDisabled={isFormDisabled}
                    validationResult={validationResult}
                    isLoading={isLoading}
                    onChange={(p, c) => setOutputParameterColumn(p, c)}
                /></>}

            {selectedTab == "step-by-step" &&
                <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Label className="input-form-label" style={{ textAlign: "left" }}>
                            Step evaluation function:
                        </Label>
                        <Field
                            validationMessage={validationResult.getFieldValidationMessage(nameof<INewScenario>('stepEvaluationFunction'), isFormSubmitted)}
                            validationState={validationResult.getFieldValidationMessage(nameof<INewScenario>('stepEvaluationFunction'), isFormSubmitted) ? "error" : "none"}>
                            <Textarea
                                className="editable-text-area-field"
                                name="stepEvaluationFunction"
                                rows={10}
                                value={selectedScenario ? selectedScenario.stepEvaluationFunction : ""}
                                onChange={(ev, data) => { setSelectedScenario(s => ({ ...s, stepEvaluationFunction: data.value })) }}
                            />
                        </Field>
                        <small>You can specify JavaScript Math functions inside step evaluation function function.
                            Function must return true to continue to next step.
                            You can override output parameters in step evaluation function.</small>
                    </div>

                </>}
        </div>

    </div>;
}