
import isVarName from 'is-var-name';
import { IValidationResultData, nameof, ValidationResult } from '../../utils/helpers/validation.helpers';
import { IExam, IExercise } from '../shared/exams.models';





export const validate = (exam: IExam): ValidationResult => {

    let result = {
        fieldsErrors: {},
        globalErrors: []
    } as IValidationResultData;

    if (!exam.name)
        result.fieldsErrors[nameof<IExam>('name')] = "Exam name is required.";

    if (!exam.groupId)
        result.fieldsErrors[nameof<IExam>('groupId')] = "Group is required.";

    if (!exam.startTime)
        result.fieldsErrors[nameof<IExam>('startTime')] = "Start time is required.";

    if (exam.startTime && !(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.test(exam.startTime)))
        result.fieldsErrors[nameof<IExam>('startTime')] = "Incorrect time format.";

    if (!exam.date)
        result.fieldsErrors[nameof<IExam>('date')] = "Date is required.";

    if (!exam.duration)
        result.fieldsErrors[nameof<IExam>('duration')] = "Duration is required.";

    if (!exam.worksheet)
        result.fieldsErrors[nameof<IExam>('worksheet')] = "Worksheet is required.";

    if (!exam.firstRow)
        result.fieldsErrors[nameof<IExam>('firstRow')] = "First row is required.";
    if (exam.firstRow && !/^[0-9]*$/.test(exam.firstRow.toString())) {
        result.fieldsErrors[nameof<IExam>('firstRow')] = "Incorrect number format.";
    }

    if (!exam.stepSize)
        result.fieldsErrors[nameof<IExam>('stepSize')] = "Step size is required.";

    if (exam.stepSize && !/^[0-9]*$/.test(exam.stepSize.toString())) {
        result.fieldsErrors[nameof<IExam>('stepSize')] = "Incorrect number format.";
    }

    if (exam.duration && !(/^[0-9]*:([0-5]?[0-9])$/.test(exam.duration)))
        result.fieldsErrors[nameof<IExam>('duration')] = "Incorrect time format.";

    if (!exam.gradingTolerance)
        result.fieldsErrors[nameof<IExam>('gradingTolerance')] = "Grading tolerance is required.";

    if (exam.gradingTolerance && !/^[0-9]*$/.test(exam.gradingTolerance.toString())) {
        result.fieldsErrors[nameof<IExam>('gradingTolerance')] = "Incorrect number format.";
    }
    if (exam && exam.exercises && exam.exercises.length > 0) {
        exam.exercises.forEach((exercise, exIndex) => {
            validateExercise(exercise, result);
            exercise.subExercises.forEach((subexercise, subexIndex) => {
                validateExercise(subexercise, result);
            });
        });

    }


    return new ValidationResult(result);
}

const validateExercise = (exercise: IExercise, result: IValidationResultData) => {

    if (exercise.inputParameters && exercise.inputParameters.length > 0) {
        exercise.inputParameters.forEach((inputParam, inParamIndex) => {
            let parameterId = `ex_${inputParam.exerciseIndex}`;
            if (inputParam.subexerciseIndex !== undefined) {
                parameterId += `_${inputParam.subexerciseIndex}`;
            }
            parameterId += `_row_${inParamIndex}`;

            if (!inputParam.alias) {
                let columnId = `ip_${parameterId}_alias`;

                result.fieldsErrors[columnId] = "Alias is required.";
            } else {
                const inputParamsWithsameAlias = exercise.inputParameters.filter((inparam) => inparam.alias == inputParam.alias);
                const outputParamsWithsameAlias = exercise.outputParameters.filter((outparam) => outparam.alias == inputParam.alias);
                const total = (inputParamsWithsameAlias ? inputParamsWithsameAlias.length : 0) + (outputParamsWithsameAlias ? outputParamsWithsameAlias.length : 0);
                let columnId = `ip_${parameterId}_alias`;
                if (total > 1) {
                    result.fieldsErrors[columnId] = "Alias needs to be unique in input and output.";
                }
                if (!isVarName(inputParam.alias)) {
                    result.fieldsErrors[columnId] = "Alias can contain only letters, numbers and underscore (_).";
                }

            }
            if (!inputParam.column) {
                let columnId = `ip_${parameterId}_column`;
                result.fieldsErrors[columnId] = "Column is required.";
            } else {
                let columnId = `ip_${parameterId}_column`;
                const inparamsWithsameColumn = exercise.inputParameters.filter((inparam) => inparam.column && inparam.column.toLowerCase() == inputParam.column.toLowerCase());
                const outparamsWithsameColumn = exercise.outputParameters.filter((outparam) => outparam.column && outparam.column.toLowerCase() == inputParam.column.toLowerCase());
                const total = (inparamsWithsameColumn ? inparamsWithsameColumn.length : 0) + (outparamsWithsameColumn ? outparamsWithsameColumn.length : 0);
                if (total > 1) {
                    result.fieldsErrors[columnId] = "Column needs to be unique in input and output.";
                }

                if (!(/^[A-z]+$/.test(inputParam.column))) {
                    result.fieldsErrors[columnId] = "Column can contain only letters.";
                }
            }
        });
    }

    if (exercise.outputParameters && exercise.outputParameters.length > 0) {
        exercise.outputParameters.forEach((outputParam, outParamIndex) => {
            let parameterId = `ex_${outputParam.exerciseIndex}`;
            if (outputParam.subexerciseIndex !== undefined) {
                parameterId += `_${outputParam.subexerciseIndex}`;
            }
            parameterId += `_row_${outParamIndex}`;
            if (!outputParam.alias) {
                let columnId = `op_${parameterId}_alias`;
                result.fieldsErrors[columnId] = "Alias is required.";
            } else {
                let columnId = `op_${parameterId}_alias`;
                const outputParamsWithsameAlias = exercise.outputParameters.filter((outparam) => outparam.alias == outputParam.alias);
                const inputParamsWithsameAlias = exercise.inputParameters.filter((inparam) => inparam.alias == outputParam.alias);
                const total = (outputParamsWithsameAlias ? outputParamsWithsameAlias.length : 0) + (inputParamsWithsameAlias ? inputParamsWithsameAlias.length : 0);
                if (total > 1) {
                    result.fieldsErrors[columnId] = "Alias needs to be unique in input and output.";
                }

                if (!isVarName(outputParam.alias)) {
                    result.fieldsErrors[columnId] = "Alias can contain only letters, numbers and underscore (_).";
                }
            }
            if (!outputParam.column) {
                let columnId = `op_${parameterId}_column`;
                result.fieldsErrors[columnId] = "Column is required.";
            } else {
                let columnId = `op_${parameterId}_column`;
                const inparamsWithsameColumn = exercise.inputParameters.filter((inparam) => inparam.column && inparam.column.toLowerCase() == outputParam.column.toLowerCase());
                const outparamsWithsameColumn = exercise.outputParameters.filter((outparam) => outparam.column && outparam.column.toLowerCase() == outputParam.column.toLowerCase());
                const total = (inparamsWithsameColumn ? inparamsWithsameColumn.length : 0) + (outparamsWithsameColumn ? outparamsWithsameColumn.length : 0);
                if (total > 1) {
                    result.fieldsErrors[columnId] = "Column needs to be unique in input and output.";
                }

                if (!(/^[A-z]+$/.test(outputParam.column))) {
                    result.fieldsErrors[columnId] = "Column can contain only letters.";
                }
            }
            if (!outputParam.description) {
                let columnId = `op_${parameterId}_description`;
                result.fieldsErrors[columnId] = "Description is required.";
            }
            if (!outputParam.points) {
                let columnId = `op_${parameterId}_points`;
                result.fieldsErrors[columnId] = "Points are required.";
            } else {
                let columnId = `op_${parameterId}_points`;
                if (!/^-?\d+$/.test(outputParam.points.toString())) {
                    result.fieldsErrors[columnId] = "Value not a number.";
                } else if (outputParam.points < 0) {
                    result.fieldsErrors[columnId] = "Value needs to be greater than 0.";
                }




            }
        });
    }

}