import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Label, Spinner, ToolbarButton } from "@fluentui/react-components";
import { Code20Regular, Copy20Regular, Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { useParams } from "react-router-dom";
import { FiltersRouteParams } from "../filters.component";
import { toast } from "react-toastify";
import { Text } from "@fluentui/react-components";
import { processServerError } from "../../utils/helpers/error.helper";
import { getFiltersClient } from "../../services/dashboard.service";
import { FilterParameterType, FilterPythonScriptPostModel, FilterResultModel, ParameterDefaultValue } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import FlowsheetObjectService from "../../services/flowsheet-objects.service";
import getFlowsheetObjectByParameter from "../../components/paramater-picker-next/parameter-to-flowsheet-objects-maper";
import { FlowsheetParameterModel } from "../../swagger-clients/dispatcher-next-api-clients.service";

type CreatePythonScriptToolbarButtonProps = {
    isOpened?: boolean;
    filter: FilterResultModel;
    onClose?: () => void;
}

export const CreatePythonScriptToolbarButton: React.FC<CreatePythonScriptToolbarButtonProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const routeParams = useParams<FiltersRouteParams>();
    const [pythonCode, setPythonCode] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    React.useEffect(() => {
        getPythonScriptCode();
    }, []);


    const getPythonScriptCode = async () => {
        try {
            setIsLoading(true);
            setPythonCode("");
            const filtersClient = getFiltersClient();
            const inputParameterDefaultValues = await getDefaultInputParameterValues();

            const model = new FilterPythonScriptPostModel({
                filterId: +routeParams.filterId,
                inputParameterValues: inputParameterDefaultValues
            });

            const resp = await filtersClient.getPythonScript(model);
            if (!resp || !resp.script) {
                setPythonCode(`An error occurred while getting python script code.`);
            } else {
                setPythonCode(resp.script);
            }

        } catch (error) {
            setPythonCode(`An error occurred while getting python script code.`);
            processServerError(error, undefined, "An error occurred while getting python script code.");
        } finally {
            setIsLoading(false);
        }
    }

    const getDefaultInputParameterValues = async () => {
        try {
            const expectedInputParameters = props.filter.inputFilterParameters.filter(x => x.parameterType == FilterParameterType.ExpectedInput);
            if (!expectedInputParameters || expectedInputParameters.length == 0)
                return [];

            const flowsheetObjects = await FlowsheetObjectService.getFlowsheetObjectsPromise("s365v2", props.filter.fileUniqueIdentifier, props.filter.fileVersionNumber.toString());

            const inputParameterDefaultValues = expectedInputParameters.map(x => {
                const flowsheetObjectResult = getFlowsheetObjectByParameter(x.name, flowsheetObjects);
                console.log("getDefaultInputParameterValues", x.name, flowsheetObjectResult);
                const param = (flowsheetObjectResult as any[])?.filter(y => !!(y as FlowsheetParameterModel).parameter)?.[0] as FlowsheetParameterModel;
                return new ParameterDefaultValue({ parameterName: x.name, value: param.value });
            });

            return inputParameterDefaultValues;

        } catch (error) {
            console.log("An error occurred while getting default input parameters.", error);
            throw "An error occurred while getting default input parameters."
        }
    }

    const onCopyCode = async () => {
        if (!!pythonCode) {
            navigator.clipboard.writeText(pythonCode);
            toast.success("Python script copied.", { autoClose: 2000 });
        }
    }



    const onModalClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    }


    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface style={{ maxWidth: "700px" }}>
            <DialogBody style={{ maxWidth: "inherit" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                    Create Python script
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", gap: "20px", marginTop: "10px" }}>
                        <Button icon={<Copy20Regular />} onClick={onCopyCode}>Copy Python script</Button>
                    </div>
                    <br />
                    <Label style={{ fontWeight: "bold" }}>Python script:</Label>

                    <Text style={{
                        whiteSpace: 'pre-wrap',
                        margin: "5px",
                        backgroundColor: "var(--colorNeutralBackground1Hover)",
                        display: "grid",
                        minHeight: "calc(90vh - 200px)"
                    }}>
                        {isLoading && <Spinner size="extra-small" appearance="primary" label="Getting python script..." />}
                        {!isLoading && <div style={{ whiteSpace: 'pre-wrap', padding: "7px", backgroundColor: "var(--colorNeutralBackground1Hover)" }}>{pythonCode}</div>}
                    </Text>
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}