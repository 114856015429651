import * as React from "react";
import { ExperimentProcessingStatus } from "../../swagger-clients/ai-for-pfd-clients.service";

export interface IExperimentStatusProps {
    status?: ExperimentProcessingStatus;
}

export const ExperimentStatusComponent: React.FC<IExperimentStatusProps> = (props) => {

    const experimentStatuses: { [status: string]: { color: string, name: string } } =
    {
        [ExperimentProcessingStatus.Draft.toString()]: { color: "#115ea3", name: "Draft" },
        [ExperimentProcessingStatus.Initializing.toString()]: { color: "purple", name: "Initializing" },
        [ExperimentProcessingStatus.Stopped.toString()]: { color: "orange", name: "Stopped" },
        [ExperimentProcessingStatus.Failed.toString()]: { color: "red", name: "Failed" },
        [ExperimentProcessingStatus.Finished.toString()]: { color: "grey", name: "Finished" },
        [ExperimentProcessingStatus.Running.toString()]: { color: "green", name: "In progress" }
    };
    if (props.status === undefined) {
        return null;
    }

    const status = experimentStatuses[props.status];


    return <span style={{ color: status.color }}>{status.name}</span>;


}