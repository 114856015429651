import React from "react";


export const TourIntro = () => {


    return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontSize: "20px", fontWeight: 500, height: "100%" }}>
        <span>Welcome to Simulate 365 Dashboard!</span>
        <span>Take a quick tour to learn about Dashboard features.</span>

    </div>
}