import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { HasAccessSidePeoplePicker, SelectOption } from "../../components/has-access-side-people-picker/has-access-side-people-picker.component";
import { processServerError } from "../../utils/helpers/error.helper";
import ImpersonateUserService from "../../services/impersonate-user.service";

type ImpersonateUserModalProps = {
    isOpened: boolean;
    onSuccess: () => void;
    onClose: () => void;
}

export const ImpersonateUserModal: React.FC<ImpersonateUserModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [selectedPersonas, setSelectedPersons] = React.useState<SelectOption[]>([]);

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    const onImpersonateClick = async () => {
        try {
            setIsLoading(true);
            const userId = selectedPersonas[0].value?.toString();
            await ImpersonateUserService.impersonateUser(userId);
            props.onSuccess();

        } catch (error) {
            processServerError(error, undefined, `An error occurred while trying to impersonate user.`);
        } finally {
            setIsLoading(false);
        }
    }

    const onModalClose = () => {
        setOpen(false);

        if (props.onClose) {

            props.onClose();
        }

    }
    return <div>
        <Dialog open={open} onOpenChange={(event, data) => {
            setOpen(data.open);
            if (!data.open) {
                onModalClose();
            }
        }}>
            <DialogSurface style={{ maxWidth: "700px" }}>
                <DialogBody style={{ maxWidth: "inherit" }}>
                    <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                        Impersonate user
                    </DialogTitle>
                    <DialogContent style={{overflow:"visible"}} >
                        {(!selectedPersonas || selectedPersonas.length == 0) && <HasAccessSidePeoplePicker
                            multiple={false}
                            onSelectedOptionsChanged={(persons) => { setSelectedPersons(persons) }}
                            orgGroupId={"5f6e0a51-ae32-4e1f-846f-c38a9f6d7481"}
                        />}
                        {!!selectedPersonas && selectedPersonas.length==1 &&<span>
                            Are you sure you want to impersonate <b>{selectedPersonas[0].label}</b>?
                        </span>
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button appearance="primary"
                            onClick={onImpersonateClick}
                            disabled={isLoading || !selectedPersonas}>
                            Impersonate</Button>
                        <Button appearance="secondary"
                            disabled={isLoading}
                            onClick={onModalClose}>Cancel</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog >
    </div>
}