import * as React from "react";
import { JobProcessingStatus } from "../../swagger-clients/dispatcher-next-api-clients.service";

interface IStatusProps {
    value: JobProcessingStatus;
}

let mapping = {
    [JobProcessingStatus.Failed]: { text: "Failed", color: "red" },
    [JobProcessingStatus.Finished]: { text: "Finished", color: "green" },
    [JobProcessingStatus.NotCalculated]: { text: "Not calculated", color: "grey" },
    [JobProcessingStatus.Cancelled]: { text: "Cancelled", color: "darkgrey" }
}

const JobStatus: React.FunctionComponent<IStatusProps> = (props) => {
    const status = mapping[props.value];
    return status && <span style={{ color: status.color }}>{status.text}</span>;
}

export default JobStatus;