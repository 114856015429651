import React from "react";
import { SensitivityStudy, SensitivityStudyOutputParameter } from "../edit-sensitivity-study.interfaces";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { Label } from "@fluentui/react-components";
import { Dropdown, Option, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { getDropdownText } from "../../../utils/helpers/dropdown.helpers";
import { IDropdownOption } from "../../../utils/shared.types";
import { ValidateIsNotNullOrWhitespace } from "../edit-sensitivity-study.validation";
import { ParameterDisplay } from "../../../components/parameter-display/parameter-display.component";
import { FileModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { TableBodyWithLoading } from "../../../components/table-body-with-loading/table-body-with-loading.component";

type StudyOutputParametersTabProps = {
    selectedStudy?: SensitivityStudy;
    selectedFile: FileModel;
    onSelectedStudyChanged?: (updatedStudy: SensitivityStudy) => void;
    isFormSubmitted: boolean;
    isLoading: boolean;
}

const searchForDropdownOptions = [{ key: 0, text: "Min" }, { key: 1, text: "Max" }] as IDropdownOption[];


export const StudyOutputParametersTab: React.FC<StudyOutputParametersTabProps> = (props) => {

    const onSearchForChanged = (event: any, option: number, index?: number) => {
        props.onSelectedStudyChanged({ ...props.selectedStudy, searchFor: option } as SensitivityStudy);
    }

    const onPropertyChanged = (propertyName: string, newValue: string) => {

        let updatedStudy = { ...props.selectedStudy } as SensitivityStudy;
        updatedStudy[propertyName] = newValue;

        props.onSelectedStudyChanged(updatedStudy);
    }

    const optimizationFunctionValidation = ValidateIsNotNullOrWhitespace(props.selectedStudy.optimizationFunction, "Optimization function");
    const outputParams = [...props.selectedStudy.sensitivityStudyOutputParameters];

    const getParameterCell = (item: (SensitivityStudyOutputParameter | any), index: number) => {
        if (item.isFilterFormula === true) {
            return <p style={{ margin: 0 }}>Formula</p>;
        }
        const outputFilterParam = item ? item.outputFilterParameter : undefined;
        const selectedFilter = props.selectedStudy.filter;
        return <p style={{ margin: 0 }}>{outputFilterParam && props.selectedFile ? <ParameterDisplay
            value={outputFilterParam.name}
            fileUniqueId={props.selectedFile.uniqueIdentifier}
            versionNumber={props.selectedFile.currentVersionNumber.toString()}

        /> : ""}</p>
    }

    const getAliasCell = (item: (SensitivityStudyOutputParameter | any), index: number) => {
        const outputFilterParam = item ? item.outputFilterParameter : undefined;
        return <p style={{ margin: 0 }}>{outputFilterParam ? outputFilterParam.alias : ""}</p>
    }

    const getFormulaCell = (item: (SensitivityStudyOutputParameter | any), index: number) => {
        if (item.isFilterFormula === true) {
            return <p style={{ margin: 0 }}>{item.filterFormulaValue ? item.filterFormulaValue : "-"}</p>;
        } else {
            return <p style={{ margin: 0 }}>-</p>;
        }

    }

    return <div>
        <div className="input-form">
            <div className="input-form-item">
                <Label style={{ fontWeight: 600, width: "30%", textAlign: "right", maxWidth: "250px", paddingRight: "22px" }}>Search for:</Label>
                <Dropdown value={getDropdownText(props.selectedStudy.searchFor.toString(), searchForDropdownOptions)}
                    onOptionSelect={(ev: any, data) => { onSearchForChanged(ev, +data.optionValue!); }}>
                    {searchForDropdownOptions.map((option) => {
                        return <Option key={`search-for-${option.key}`} value={option.key?.toString()}>
                            {option.text}
                        </Option>;
                    })}
                </Dropdown>

            </div>
            <div className="input-form-item">
                <Label
                    style={{
                        fontWeight: 600,
                        width: "30%",
                        textAlign: "right",
                        maxWidth: "250px",
                        paddingRight: "22px"
                    }}>Optimization function:</Label>

                <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                    {/* <TextField
                    value={props.selectedStudy.optimizationFunction}
                    multiline rows={5}
                    onChange={(e, newValue) => { onPropertyChanged("optimizationFunction", newValue) }}
                    errorMessage={props.isFormSubmitted && !optimizationFunctionValidation.isValid ? optimizationFunctionValidation.validationErrors[0] : ""} /> */}
                    <CodeMirror
                                 value={props.selectedStudy.optimizationFunction}
                                height="150px"
                                extensions={[javascript({ jsx: true })]}
                                onChange={(value)=>{onPropertyChanged.call(this, "optimizationFunction", value)}}
                            />
                   
                   
                    {/* <CodeMirror
                        className="CodeMirror--h150"
                        value={props.selectedStudy.optimizationFunction}
                        options={{ mode: 'javascript', lineNumbers: true }}
                        onBeforeChange={(editor, data, value) => {
                            onPropertyChanged.call(this, "optimizationFunction", value);
                        }}
                        onChange={(editor, data, value) => {
                        }}
                    /> */}
                    {
                        props.isFormSubmitted && !optimizationFunctionValidation.isValid &&
                        <span style={{ color: "red" }}>{optimizationFunctionValidation.validationErrors[0]}</span>
                    }
                    <small>You can specify JavaScript Math functions inside optimization function.</small>
                </div>
            </div>

            <div className="input-form-item">
                <Label
                    style={{
                        fontWeight: 600,
                        width: "30%",
                        textAlign: "right",
                        maxWidth: "250px",
                        paddingRight: "22px"

                    }}>Output constraining function:</Label>

                <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                    {/* <TextField
                    value={props.selectedStudy.outputConstraints ? props.selectedStudy.outputConstraints :
                        "// Your code goes here \n"
                        + "// Define as JavaScript \n\n"
                        + "// Always leave this line last \n"
                        + "return true;"}
                    multiline rows={5}
                    onChange={(e, newValue) => { onPropertyChanged("outputConstraints", newValue) }} /> */}
 <CodeMirror
                                value={props.selectedStudy.outputConstraints}
                                height="200px"
                                extensions={[javascript({ jsx: true })]}
                                onChange={(value)=>{onPropertyChanged.call(this, "outputConstraints", value);}}
                            />
                    {/* <CodeMirror
                       
                        value={props.selectedStudy.outputConstraints}
                        options={{ mode: 'javascript', lineNumbers: true }}
                        onBeforeChange={(editor, data, value) => {
                            onPropertyChanged.call(this, "outputConstraints", value);
                        }}
                        onChange={(editor, data, value) => {
                        }}
                    /> */}

                    <small>You can specify JavaScript Math functions inside output constraining function.
                        You can specify input and output parameters. If left empty constraints won't be taken into account.</small>
                </div>
            </div>
        </div>

        <Table>
            <TableHeader>
                <TableRow key="table-header">
                    <TableHeaderCell key="parameter" style={{ width: "300px" }} className='table__cell--bold'>Parameter</TableHeaderCell>
                    <TableHeaderCell key="alias" className='table__cell--bold'>Alias</TableHeaderCell>
                    <TableHeaderCell key="formula" className='table__cell--bold'>Formula</TableHeaderCell>

                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={props.isLoading}
                columnCount={3} loadingMessage="Loading..."
                itemCount={outputParams ? outputParams.length : 0}
                noItemsMessage={`Select filter to view parameters.`}>
                {outputParams &&
                    outputParams.map((item, index) => {
                        return <TableRow key={`input-param-${index}`}>
                            <TableCell>
                                {getParameterCell(item, index)}
                            </TableCell>
                            <TableCell >
                                {getAliasCell(item, index)}
                            </TableCell>
                            <TableCell>
                                {getFormulaCell(item, index)}
                            </TableCell>


                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
    </div>
}