import React from "react";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from 's365-dashboard-v2-file-picker';
import { BreadcrumbItem, FileSortFields, FileSystemEntityType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { FilesTable, FilesTableSort } from "../../files/files-table/files-table.component";
import { useLoading } from "../../utils/loading-indicator.component";
import { HubConnection } from "@microsoft/signalr";
import { FileTableItem } from "../../files/models/file-table.models";
import { getDashboardSharingClient } from "../../services/dashboard.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { getFileExtension } from "../../files/file-type-icon/file-type-icon.helpers";
type ExampleFilePickerProps = {
    hubConnection: HubConnection;
    filterFileTypes?: string[];
    disableOpenFile?: boolean;
    onFileSelected: (file: FileTableItem) => void;
    onFolderSelected?: (folder: FileTableItem) => void;
}
const emptyBreadcrumbs = [{ name: "Simulate 365 Examples" } as BreadcrumbItem];

export const ExampleFilePicker: React.FC<ExampleFilePickerProps> = (props) => {
    const [files, setFiles] = React.useState<FileTableItem[]>([]);
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [selectedFolder, setSelectedFolder] = React.useState<string>();
    const [selectedFile, setSelectedFile] = React.useState<FileTableItem>();
    const [isLoading, loadingService] = useLoading();
    const [sortBy, setSortBy] = React.useState<FilesTableSort>({ field: FileSortFields.Name, isDescending: false });

    React.useEffect(() => {
        getFilesWithLoading();
    }, [selectedFolder]);

    const onBreadcrumbItemClick = async (directoryUniqueId?: string) => {
        setSelectedFolder(directoryUniqueId);
        setSelectedFile(undefined);
    }
    const onSelectedItemChanged = (item: FileTableItem) => {
        if (item.type == FileSystemEntityType.File) {
            setSelectedFile(item);
            props.onFileSelected(item);
        } else {
            setSelectedFolder(item.uniqueIdentifier);
            props.onFolderSelected?.(item);
        }
    }

    const getFilesWithLoading = async () => {
        loadingService.showLoading("Loading...", (hideMessage) => {
            getFiles().finally(() => hideMessage());
        })
    }

    const getFiles = async () => {
        loadingService.showLoading("Loading files...", async (hideMessage) => {
            try {


                const client = getDashboardSharingClient();
                const resp = await client.getExamples(selectedFolder ?? "", sortBy.field, sortBy.isDescending);

                if (resp) {
                    if (!!props.filterFileTypes && props.filterFileTypes.length > 0) {
                        const filteredFiles = resp.files.filter(x => x.type == FileSystemEntityType.Directory || props.filterFileTypes.indexOf(getFileExtension(x.name)) > -1);
                        setFiles(filteredFiles);
                    } else {
                        setFiles(resp?.files ?? []);
                    }


                    setBreadcrumbs(resp?.breadcrumbItems ? [...emptyBreadcrumbs, ...resp.breadcrumbItems] : emptyBreadcrumbs);
                }

            }
            catch (error) {
                processServerError(error, undefined, "An error occurred while getting examples.");
            }
            finally {
                hideMessage();
            }
        });

    }

    return <div>
        <div className='files-wrapper__breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((test: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${test.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(test.uniqueIdentifier); }}>{test.name}</Breadcrumb>
                })}
            </Breadcrumbs>
        </div>
        <FilesTable
            isLoading={isLoading}
            loadingService={loadingService}
            showLoadingRow
            files={files}
            readonly
            disableSubmitForAnalysis
            hideAnalyzedColumn
            hideAnalyticsColumn
            hideTagsColumn
            hideModifiedColumn
            disableOpenFile
            hideContextMenu
            onNavigateTo={(folder: FileTableItem) => { setSelectedFolder(folder.uniqueIdentifier) }}
            onShowDetails={() => { }}
            onShowTagInputsChanged={() => { }}
            hubConnection={props.hubConnection}
            onSelectedItemChanged={(item) => { onSelectedItemChanged(item) }}
            onReloadFilesEvent={() => getFilesWithLoading()}
        />

    </div>

}
