import { Field, Input, Label, Tab, TabList } from "@fluentui/react-components";
import React from "react";
import { StreamModel } from "../../edit-experiment.models";
import { ExperimentStreamType, SeparationMethodOption } from "../../../../swagger-clients/ai-for-pfd-clients.service";
import { StreamComponent } from "./stream.component";
import { IExperimentValidation } from "../../edit-experiment.validation";

type IdealDistillationStreamProps = {
    separationMethod: SeparationMethodOption;
    streams: StreamModel[];
    onChange: (streams: StreamModel[]) => void;
    isDetails?: boolean;
    isFormSubmitted?: boolean;
    experimentValidation?: IExperimentValidation;
}

export const IdealDistillationStream: React.FC<IdealDistillationStreamProps> = (props) => {
    const [selectedTab, setSelectedTab] = React.useState<string>("feed");

    const feedStream = props.streams.find(x => x.streamType == ExperimentStreamType.Feed);
    const feedStreamIndex = props.streams.findIndex(x => x.streamType == ExperimentStreamType.Feed);
    const feedStreamValidation = feedStreamIndex > -1 && !!props.experimentValidation?.streams[feedStreamIndex] ? props.experimentValidation?.streams[feedStreamIndex] : undefined;


    return <div style={{ marginTop: "-15px" }}>
        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { setSelectedTab(data.value as string); }}>
            <Tab key="feed" value="feed" >Feed</Tab>
        </TabList>

        <div className="tab-content" style={{ marginTop: "10px" }}>

            <StreamComponent
                separationMethod={props.separationMethod}
                stream={feedStream}
                onChange={(data) => props.onChange([data])}
                isDetails={props.isDetails}
                streamValidation={feedStreamValidation}
                isFormSubmitted={props.isFormSubmitted} />
        </div>

    </div>
}