export function generateColorPalette(size) {
  let palette = [  "#051e28","#3a8841","#40b5bc","#483df6","#86759f" ];
  if (size <= palette.length) {
    return palette.slice(0, size);
  }


  for (let i = 0; i < size - palette.length; i++) {
    const hue = (i * 360 / size) % 360;
    const saturation = 70; // You can adjust saturation and lightness as needed
    const lightness = 50;

    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    palette.push(color);
  }

  return palette;
}

