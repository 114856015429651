import { Avatar, Button } from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";
import React from "react";
import ReactSelect, { ActionMeta, components } from "react-select";
import { getGroupsClient } from "../../services/dashboard.service";
import { graphClient } from "../../services/msgraph.service";
import { GroupSlimResponseModel, GroupTableResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { removeDuplicates } from "../../utils/helpers/has-access.helpers";
import { useLoading } from "../../utils/loading-indicator.component";
import { SelectOption } from "../has-access-side-people-picker/has-access-side-people-picker.component";
import "./has-access-group-picker.styless.scss";

type HasAccessGroupPickerProps = {
    isGlobalShare?: boolean;
    onSelectedOptionsChanged: (selectedOptions: SelectOption[]) => void
}

export const HasAccessGroupPicker: React.FC<HasAccessGroupPickerProps> = (props) => {
    const [isLoading, loadingService] = useLoading();
    const [options, setOptions] = React.useState<SelectOption[]>([]);
    const [currentValue, setCurrentValue] = React.useState<string>("");
    const [selectedValues, setSelectedValues] = React.useState<SelectOption[]>([]);


    React.useEffect(() => {
        if (currentValue && currentValue.length > 1) {
            updateSuggestions();
        } else {
            setOptions([]);
        }

    }, [currentValue]);


    React.useEffect(() => {
        props.onSelectedOptionsChanged(selectedValues);
    }, [selectedValues]);

    const getUserOwnedGroups = async () => {

        try {

            const client = getGroupsClient();

            const userGroups = await client.getUserOwnedGroups();
            return userGroups.filter(x => !x.isDeleted);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting user groups.");
        }


    }

    const getAdGroups = async (): Promise<GroupTableResultModel[]> => {
        try {
            const client = getGroupsClient();
            const groups = await client.getAllGroupsAdmin();
            const mappedGroups = groups?.map(group => ({ groupname: group.displayName, id: group.id } as GroupTableResultModel)) ?? [];
            return mappedGroups;
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting AD groups.");
        }
    }

    const updateSuggestions = async () => {

        loadingService.showLoading("Loading suggestions...", async (hideMessage) => {
            try {

                const groups = !props.isGlobalShare ? await getUserOwnedGroups() : await getAdGroups();
                const groupOptions = groups?.map((group) => ({ label: group.groupname, value: group.id } as SelectOption));
                const uniqueOptions = removeDuplicates(groupOptions, selectedValues);
                setOptions(uniqueOptions);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting group suggestions.");
            }
            finally {
                hideMessage();
            }
        });
    }

    const onParametersChanged = async (newOption: SelectOption, actionMeta: ActionMeta<SelectOption>) => {

        setSelectedValues([...selectedValues, newOption] as SelectOption[]);
        setCurrentValue("");
    }

    const Option = (props) => {
        const person = props.data as SelectOption;

        return <components.Option {...props}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Avatar className="group-display-avatar" name={person.label} color="colorful" />
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="group-display-name">{person.label}</span>
                </div>
            </div>

        </components.Option>
    }


    const onRemoveSelection = (person: SelectOption) => {
        const updatedValues = selectedValues.filter(x => x.value !== person.value);
        setSelectedValues(updatedValues);
    }

    return <div style={{ display: "flex", flexDirection: "column", margin: "5px 0px" }}>
        <ReactSelect
            value={[]}
            isLoading={isLoading}
            isClearable={false}
            options={options}
            onInputChange={(newValue: string) => { setCurrentValue(newValue); }}
            onChange={onParametersChanged}
            components={{ Option }} />
        <div className="selected-groups">
            {selectedValues && selectedValues.map((person) => {

                return <div className="group-container">
                    <Avatar className="group-avatar" name={person.label} color="colorful" />
                    <span className="group-name">{person.label}</span>
                    <Button appearance="transparent" icon={<Dismiss12Regular />} onClick={() => onRemoveSelection(person)} />
                </div>
            })}
        </div>
    </div>
}