
import { Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent, Spinner, DialogActions, Button } from "@fluentui/react-components";
import React from "react";
type SaveFlowsheetToDashboardProgressModalProps = {
    isLoading: boolean;
    filename?: string;
    filePath?: string;
    isSuccess: boolean;
    isOpened: boolean;

    onHide: () => void;

}

export const SaveFlowsheetToDashboardProgressModal: React.FC<SaveFlowsheetToDashboardProgressModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const onModalClose = () => {
        setOpen(false);
        if (props.onHide)
            props.onHide();
    }
    return <Dialog

        open={open}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle>
                Save flowsheet to Dashboard
                </DialogTitle>
                <DialogContent>
                    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        {props.isLoading && <Spinner size="extra-small" appearance="primary" label="Saving flowsheet to dashboard..." />}
                        {!props.isLoading && !props.isSuccess && <span style={{ color: "red" }}>An error occurred while saving flowsheet to dashboard.</span>}
                        {!props.isLoading && props.isSuccess && <span>
                            Flowsheet <b>{props.filename}</b> was successfully saved.
                            <br /> Flowsheet location: <b>{props.filePath}</b>.
                        </span>}
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button appearance="secondary" onClick={onModalClose} type="button">Close</Button>
                </DialogActions>

            </DialogBody>

        </DialogSurface>


    </Dialog>
}