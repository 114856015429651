import { Button, Tooltip } from "@fluentui/react-components";
import { CopyRegular } from "@fluentui/react-icons";
import React from "react";


type CopyToClipboardProps = {
    tooltip?: string;
    content?: string;
    style?: React.CSSProperties;
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = (props) => {

    const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
    const [copyUniqueIDToolTip, setCopyUniqueIDToolTip] = React.useState<string>(props.tooltip);
    const onCopyClick = () => {
        navigator.clipboard.writeText(props.content);
        setCopyUniqueIDToolTip("Copied");
        setShowTooltip(true);
        setTimeout(() => { setCopyUniqueIDToolTip(props.tooltip); setShowTooltip(false); }, 5000);
    }

    return <Tooltip relationship="label" withArrow visible={showTooltip} content={copyUniqueIDToolTip}>
        <Button icon={<CopyRegular />}
            appearance="transparent"
            onMouseOver={() => { setShowTooltip(true); }}
            onMouseLeave={() => { if (copyUniqueIDToolTip !== "Copied") setShowTooltip(false); }}
            onClick={() => { onCopyClick() }} style={{ width: "fit-content", textDecoration: "underline", ...props.style }}></Button>
    </Tooltip>
}