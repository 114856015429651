import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, Input } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React, { useEffect } from "react";
import { LoadingButton } from "../../../components/loading-button/loading-button.component";
import { useLoading } from "../../../utils/loading-indicator.component";
import { processServerError } from "../../../utils/helpers/error.helper";
import { useForm } from "react-hook-form";
import { GLOBAL_STYLES } from "../../../styles";
import { getGroupsClient } from "../../../services/dashboard.service";
import { CreateGroupPostModel, RenameGroupPostModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { toast } from "react-toastify";

type AddOrRenameGroupModalProps = {
    groupId?: string;
    groupName?: string;
    isOpened?: boolean;
    onSuccess: (groupId: string) => void;
    onClose: () => void;
}


type CreateGroupModel = {
    groupname: string;
    groupnameExists?: string;
}

export const AddOrRenameGroupModal: React.FC<AddOrRenameGroupModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [isLoading, loadingService] = useLoading();
    const { register, handleSubmit, watch, setError, clearErrors, setValue, formState: { errors, isSubmitted }, reset } = useForm<CreateGroupModel>(
        { defaultValues: { groupname: props.groupName } }
    );
    const groupNameInputRef = React.createRef<HTMLInputElement>();

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened, groupNameInputRef.current]);

    React.useEffect(() => {      
        if (open && groupNameInputRef.current) {           
            groupNameInputRef.current.focus();
        }
    }, [open]);

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    }

    const onSubmit = async (data: CreateGroupModel) => {
        if (!!props.groupId) {
            await onRenameClick(data);
        } else {
            await onCreateClick(data);
        }
    }
    const onCreateClick = async (data: CreateGroupModel) => {
        loadingService.showLoading("Creating group...", async (hideMessage) => {
            try {
                const client = getGroupsClient();
                let result = await client.createGroup(new CreateGroupPostModel({ groupname: data.groupname }));
                toast.success("Group successfully created.");
                props.onSuccess(result.id);
            } catch (error) {
                processServerError(error, setError, `An error occurred while creating group.`);
            } finally {
                hideMessage();
            }
        });
    }

    const onRenameClick = async (data: CreateGroupModel) => {
        loadingService.showLoading("Renaming group...", async (hideMessage) => {
            try {
                const client = getGroupsClient();
                await client.renameGroup(new RenameGroupPostModel({ groupId: props.groupId, groupname: data.groupname }));
                toast.success("Group successfully renamed.");
                props.onSuccess(props.groupId!);
            } catch (error) {
                processServerError(error, setError, `An error occurred while renaming group.`);
            } finally {
                hideMessage();
            }
        });
    }

    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface style={{ maxWidth: "500px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogBody style={{ maxWidth: "inherit" }}>

                    <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                        {props.groupId ? "Rename group" : "Create group"}</DialogTitle>

                    <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                        <Field
                            className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                            validationState={(!!errors.groupname || !!errors.groupnameExists) ? "error" : "none"}
                            validationMessage={
                                <span>
                                    {errors.groupname && <>{errors.groupname.message}</>}
                                    {errors.groupnameExists && <>{errors.groupnameExists.message}</>}

                                </span>}>
                            <Input
                                id="groupname"
                             
                                {...register("groupname", { required: { value: true, message: "Group name is required." } })}
                                ref={groupNameInputRef}
                                placeholder="Enter group name"
                                onChange={(ev, data) => { clearErrors(["groupname", "groupnameExists"]); setValue("groupname", data.value); }}
                            />
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            isLoading={isLoading}
                            appearance="primary"
                            type="submit"
                            message={!!props.groupId ? "Renaming..." : "Creating..."}
                        >{!!props.groupId ? "Rename" : "Create"}</LoadingButton>
                        <Button appearance="secondary" type="button" onClick={onModalClose} >Cancel</Button>
                    </DialogActions>

                </DialogBody>
            </form>
        </DialogSurface>
    </Dialog >
}