import { CustomFileTypeToIconMap, ICustomFileTypeToIconMap } from './file-type-icon.data';
import { IFileTypeIconProps } from './file-type-icon.component';

export interface IFileTypeIconCustomOptions {
    width: number;
    height: number;
    filename: string;
}



export function getIconImageUrl(filename: string, isFolder?: boolean): string | undefined {
    if (!filename)
        return undefined;

    const extension = !!isFolder ? "folder" : getFileExtension(filename);
    if (extension && extension != "") {
        for (const iconName in CustomFileTypeToIconMap) {
            const extensions = CustomFileTypeToIconMap[iconName].extensions;

            if (extensions) {
                for (let i = 0; i < extensions.length; i++) {
                    if (extensions[i].toLowerCase() == extension.toLowerCase()) {
                        return CustomFileTypeToIconMap[iconName].imageUrl;
                    }

                }
            }
        }
    }
    if (!isFolder) {
        return CustomFileTypeToIconMap["defaultFile"].imageUrl;
    }
    return undefined;
}


export const getFileExtension = (filename: string) => {
    if (!filename)
        return undefined;

    var filenameArray = filename.split('.');
    if (filenameArray && filenameArray.length > 1) {
        return filenameArray.pop();
    } else {
        return undefined;
    }
}

export const getFileNameWithoutExtension = (filename?: string) => {
    if (!filename) {
        return "";
    }
    const extension = getFileExtension(filename);
    if (!!extension) {
        const nameWithoutExtension = filename.split(`.${extension}`)[0];
        return nameWithoutExtension;
    }
    return "";
}