import { Tooltip } from "@fluentui/react-components";
import * as React from "react";
import { JobExecutionStatus } from '../../swagger-clients/dispatcher-next-api-clients.service';
//import "./job-execution-status.styless.scss"


interface IJobExecutionStatusProps {   
    value: JobExecutionStatus;
    errorMessage?: string;
}
const mapping = {
    [JobExecutionStatus.Failed]: { text: "Failed", color: "red" },
    [JobExecutionStatus.Calculated]: { text: "Calculated", color: "green" },
    [JobExecutionStatus.AssignedToAgent]: { text: "Assigned to agent", color: "blue" },
    [JobExecutionStatus.NotExecuted]: { text: "Not executed", color: "gray" },
    [JobExecutionStatus.Cancelled]: { text: "Cancelled", color: "dimgray" }


};

const JobExecutionStatusComponent: React.FunctionComponent<IJobExecutionStatusProps> = (props) => {

    const status = mapping[props.value];
   // console.log("JobExecutionStatusComponent", props);

    const onRenderPlainCard = (message: string): JSX.Element => {

        return <pre style={{ padding: "10px 25px" }}>{message}</pre>;
    };



    if (status && props.errorMessage) {
        return (<Tooltip content={props.errorMessage} relationship="description">
            <div> <span style={{ color: status.color }} >{status.text}</span></div>
        </Tooltip>);
    } else {
        return <span style={{ color: status.color }} >{status.text}</span>;
    }


}

export default JobExecutionStatusComponent;