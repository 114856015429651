import React from "react";
import { JobType } from "../../swagger-clients/dispatcher-next-api-clients.service";

interface IJobTypeComponentProps {
    value: number;
}

const mapping = {
    [JobType.AnalyzeFlowsheet]: { text: "Analyze" },
    [JobType.RunFlowsheet]: { text: "Run" },
    [JobType.RunFlowsheetStepByStep]: { text: "Run step by step" }
}
const JobTypeComponent: React.FunctionComponent<IJobTypeComponentProps> = (props) => {

    const status = mapping[props.value]; 
    return status && <span>{status.text}</span>;
}

export default JobTypeComponent;