import { Button } from "@fluentui/react-components";
import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { toast } from "react-toastify";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { getThumbnailsClient } from "../../services/dashboard.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";

type GenerateThumbnailModalProps = {
    fileId: number,
    fileVersionNumber: number,
    isOpened?: boolean,
    onSuccess: () => void;
    onClose?: () => void;
};

export const GenerateThumbnailModal: React.FC<GenerateThumbnailModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [isLoading, loadingService] = useLoading();

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const onGenerateThumbnailClick = async () => {
        loadingService.showLoading("Generating...", async (hideMessage) => {
            try {
                const client = getThumbnailsClient();
                await client.generateThumbnail(props.fileId, props.fileVersionNumber);
                toast.success("Request to generate thumbnail submitted.");
                props.onSuccess();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while submitting request ot generate thumbnail.");
            } finally {
                hideMessage();
            }
        });
    }

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }

    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Generate thumbnail</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to generate thumbnail for this file?</p>
                </DialogContent>
                <DialogActions>
                    <LoadingButton isLoading={isLoading} appearance="primary" onClick={onGenerateThumbnailClick}>Generate</LoadingButton>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}