import { Button } from "@fluentui/react-components";
import React, { ForwardedRef } from "react";

type FileUploaderProps = {
    isHidden?: boolean,
    isSingle?: boolean;
    fileExtensions?: string[];
    handleFiles(files: FileList): void
};
export type FileUploaderType = {
    resetValue(): void,
    click(): void
}

const FileUploader = React.forwardRef<FileUploaderType, FileUploaderProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            resetValue() {
                resetValue();
            },
            click() {
                handleClick(null);
            }
        }));

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event: any) => {
        console.log("handleClick", hiddenFileInput.current);
        if (hiddenFileInput.current) {
            resetValue();
            hiddenFileInput.current.click();
        }

    };

    const resetValue = () => {
        console.log("FileUploader resetValue called.");
        if (hiddenFileInput.current)
            hiddenFileInput.current.value = "";
    }
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = (event: any) => {
        const filesUploaded = event.target.files;
        props.handleFiles(filesUploaded);
    };
    return (
        <>
            <Button onClick={handleClick} style={{ marginLeft: "10px", marginRight: "10px", display: !!props.isHidden ? "none" : undefined }} >
                Upload files
            </Button>
            <input
                type="file"
                multiple={!props.isSingle}
                accept={props.fileExtensions?.join(',') ?? undefined}
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
            />
        </>
    );
});
export default FileUploader;