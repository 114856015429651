import { SensitivityStudyResultModel, StudyInputParamterType, StudyParamterRange } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { IStudyInputParameterValidation, IStudyValidation, IValidationField, SensitivityStudyInputParameter } from "./edit-sensitivity-study.interfaces";


export const SensitivityStudyIsValid = (study: IStudyValidation): boolean => {

    return study.studyName.isValid && study.optimizationFunction.isValid  && study.filter.isValid && ParametersAreValid(study.inputParameters);
}

const ParametersAreValid = (paramsValidations: IStudyInputParameterValidation[]): boolean => {
    if (!paramsValidations || paramsValidations.length == 0)
        return true;

    var invalidParameters = paramsValidations.filter((param) =>
        !(param.value.isValid &&
            param.stepsBefore!.isValid &&
            param.stepsAfter!.isValid &&
            param.stepSize!.isValid &&
            param.minValue!.isValid &&
            param.maxValue!.isValid));

    return invalidParameters.length == 0;
}

export const ValidField: IValidationField = {
    isValid: true,
    validationErrors: []
};

export const ValidateSensitivityStudy = (study: SensitivityStudyResultModel): IStudyValidation => {

    let studyValidation = {} as IStudyValidation;
    studyValidation.studyName = ValidateIsNotNullOrWhitespace(study.name!, "Study name");    
    studyValidation.filter = ValidateIsNotNullOrWhitespace(study.filterId ? study.filterId.toString() : "", "Filter");
    studyValidation.optimizationFunction = ValidateIsNotNullOrWhitespace(study.optimizationFunction!, "Optimization function");
    studyValidation.inputParameters = ValidateStudyInputParamters(study.sensitivityStudyInputParameters ?? [], study.studyParamterRange!);

    return studyValidation;
}

export const ValidateStudyInputParamters = (inputParams: SensitivityStudyInputParameter[], paramRange: StudyParamterRange): IStudyInputParameterValidation[] => {
    var inputFieldsValidations: IStudyInputParameterValidation[] = [];

    inputParams.forEach((param) => {
        let paramValidation = {} as IStudyInputParameterValidation;

        if (param.valueType == StudyInputParamterType.FilterDefined) {
            paramValidation.value = ValidField;
            paramValidation.stepsAfter = ValidField;
            paramValidation.stepsBefore = ValidField;
            paramValidation.minValue = ValidField;
            paramValidation.maxValue = ValidField;
            paramValidation.stepSize = ValidField;
        }
        else if (param.valueType == StudyInputParamterType.FixedValue) {
            paramValidation.value = ValidateStudyParameterValue(param.value.toString(), "Value", true, false);
            paramValidation.stepsAfter = ValidField;
            paramValidation.stepsBefore = ValidField;
            paramValidation.minValue = ValidField;
            paramValidation.maxValue = ValidField;
            paramValidation.stepSize = ValidField;
        }
        // else if(param.parameterType==CustomStudyInputParameterType.FilterFormula){
        //     paramValidation.value=ValidateIsNotNullOrWhitespace(param.filterParameterValue,"Formula");
        //     paramValidation.stepsAfter = ValidField;
        //     paramValidation.stepsBefore = ValidField;
        //     paramValidation.minValue = ValidField;
        //     paramValidation.maxValue = ValidField;
        //     paramValidation.stepSize = ValidField;
        // }

        else {
            if (paramRange == StudyParamterRange.MinMax) {
                if (!param.minValue)
                    console.log("Param missing min", param);
                paramValidation.stepsAfter = ValidField;
                paramValidation.stepsBefore = ValidField;
                paramValidation.value = ValidField;
                paramValidation.minValue = ValidateMinMax(param.minValue!.toString(), param.minValue!.toString(), param.maxValue!.toString(), "Min", true, false);
                paramValidation.maxValue = ValidateMinMax(param.maxValue!.toString(), param.minValue!.toString(), param.maxValue!.toString(), "Max", true, false);
                paramValidation.stepSize = ValidateGraterThanZero(param.stepSize!.toString(), "Value", true, false);

            } else {
                paramValidation.stepsAfter = ValidateGraterOrEqualZero(param.stepsAfter!.toString(), "Value", false, true);
                paramValidation.stepsBefore = ValidateGraterOrEqualZero(param.stepsBefore!.toString(), "Value", false, true);
                paramValidation.value = ValidateStudyParameterValue(param.value.toString(), "Value", true, false);
                paramValidation.minValue = ValidField;
                paramValidation.maxValue = ValidField;
                paramValidation.stepSize = ValidateGraterThanZero(param.stepSize!.toString(), "Value", true, false);

            }
        }


        inputFieldsValidations.push(paramValidation);

    });


    return inputFieldsValidations;
}


export const ValidateIsNotNullOrWhitespace = (value: string, fieldName: string): IValidationField => {
    let fieldValidation = { isValid: true, validationErrors: [] as any[] };
    if (isNullOrWhitespace(value)) {
        fieldValidation.isValid = false;

        fieldValidation.validationErrors.push(`${fieldName} is required.`);
    }
    return fieldValidation;
}



export const ValidateDecimalNumber = (value: string) => {
    return /^[+-]?\d+(\.\d+)?$/.test(value);
}

export const ValidateIntegerNumber = (value: string) => {
    return /^[+-]?\d+$/.test(value);
}

export const ValidateGraterThanZero = (value: string, name: string, isDecimal: boolean, isInteger: boolean): IValidationField => {
    let fieldValidation: IValidationField = ValidateStudyParameterValue(value, name, isDecimal, isInteger);

    if (Number(value) <= 0) {
        fieldValidation.isValid = false;
        fieldValidation.validationErrors.push(`${name} needs to be grater than 0`);
    }
    return fieldValidation;
}

export const ValidateGraterOrEqualZero = (value: string, name: string, isDecimal: boolean, isInteger: boolean): IValidationField => {
    let fieldValidation: IValidationField = ValidateStudyParameterValue(value, name, isDecimal, isInteger);

    if (Number(value) < 0) {
        fieldValidation.isValid = false;
        fieldValidation.validationErrors.push(`${name} needs to be grater than 0`);
    }
    return fieldValidation;
}

export const ValidateMinMax = (value: string, minValue: string, maxValue: string, name: string, isDecimal: boolean, isInteger: boolean): IValidationField => {
    let fieldValidation: IValidationField = ValidateStudyParameterValue(value, name, isDecimal, isInteger);

    if (Number(minValue) > Number(maxValue)) {
        fieldValidation.isValid = false;
        fieldValidation.validationErrors.push(`Min larger than max.`);
    }
    return fieldValidation;
}


export const ValidateStudyParameterValue = (paramValue: string, fieldName: string, isDecimal: boolean, isInteger: boolean): IValidationField => {

    let fieldValidation: IValidationField = { isValid: true, validationErrors: [] };

    if (isNullOrWhitespace(paramValue)) {
        fieldValidation.isValid = false;
        fieldValidation.validationErrors.push(`${fieldName} is required!`);
    }
    if (isDecimal) {
        if (!ValidateDecimalNumber(paramValue)) {
            fieldValidation.isValid = false;
            fieldValidation.validationErrors.push(`${fieldName} should be decimal number!`);
        }
    }

    if (isInteger) {
        if (!ValidateIntegerNumber(paramValue)) {
            fieldValidation.isValid = false;
            fieldValidation.validationErrors.push(`${fieldName} should be integer number!`);
        }
    }


    return fieldValidation;

}

export const isNullOrWhitespace = (value: string): boolean => {

    if (!value || value == "")
        return true;

    return value.toString().replace(/\s/g, '').length < 1
}