import React from "react";
import { SaveFileModal, SaveFileModel } from "../../../components/save-file-modal/save-file-modal.component";
import { processServerError } from "../../../utils/helpers/error.helper";
import { getSensitivityStudiesClient } from "../../../services/sensitivity-studies.service";
import { CreatePDFReportPostModel, PDFReportCreatedResponseModel } from "../../../swagger-clients/sensitivity-studies-api-clients.service";
import { toast } from "react-toastify";
import { useLoading } from "../../../utils/loading-indicator.component";
import { CreatePDFReportProgressModal } from "./create-pdf-report-progress-modal.component";

type CreatePDFReportProps = {
    studyId: number;
    defaultName?: string;
    parentDirectoryUniqueId?: string;
    onHide: () => void;

}

export const CreatePDFReport: React.FC<CreatePDFReportProps> = (props) => {
    const [showSaveFileModal, setShowSaveFileModal] = React.useState<boolean>(true);
    const [showProgressModal, setShowProgressModal] = React.useState<boolean>(false);
    const [isRequestSuccessfull, setIsRequestSuccessfull] = React.useState<boolean>(false);
    const [isLoading, LoadingService] = useLoading(false);
    const [fileCreatedResponse, setFileCreatedResponse] = React.useState<PDFReportCreatedResponseModel>();

    const onSavePDFReportClick = async (data: SaveFileModel) => {
        const messageId = LoadingService.showMessage("Creating PDF report...");
        try {
            setShowProgressModal(true);
            setShowSaveFileModal(false);
            setIsRequestSuccessfull(false);
            const client = getSensitivityStudiesClient();
            const model = new CreatePDFReportPostModel({
                studyId: props.studyId,
                filename: `${data.name}.${data.extension}`,
                parentUniqueIdentifier: data.parentDirectoryUniqueId,
                conflictAction: data.conflictAction
            });

            const fileCreatedresp = await client.createPDFReport(model);
            setFileCreatedResponse(fileCreatedresp);

            setIsRequestSuccessfull(true);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while creating PDF report.");
        } finally {
            LoadingService.hideMessage(messageId);
        }

    }


    return <div>

        <SaveFileModal isOpened={showSaveFileModal}
            modalTitle="Create PDF Report"
            defaultName={props.defaultName}
            fileExtension="pdf"
            fileExtensionText="PDF file (*.pdf)"
            parentDirectoryUniqueId={props.parentDirectoryUniqueId}
            onSuccess={onSavePDFReportClick}
            onHide={() => { setShowSaveFileModal(false); props.onHide(); }}

        />

        <CreatePDFReportProgressModal
            isLoading={isLoading}
            isSuccess={isRequestSuccessfull}
            filePath={fileCreatedResponse?.simulatePath}
            filename={fileCreatedResponse?.filename}
            isOpened={showProgressModal}
            onHide={() => { setShowProgressModal(false); props.onHide(); }}
        />


    </div>

}