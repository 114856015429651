import { Avatar, Slot } from "@fluentui/react-components";
import React from "react";
import { SharedWithDestinationType, SharedWithResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { IDropdownOption } from "../../utils/shared.types";
import { ManageAccessRoleDropdown } from "../manage-access-role-dropdown/manage-access-role-dropdown.component";


type HasAccessSidePersonaProps = {
    person: SharedWithResponseModel;
    isOwner: boolean;   
    onRoleChanged?(person: SharedWithResponseModel, role: string);
}


export const HasAccessSidePersona: React.FC<HasAccessSidePersonaProps> = (props) => {
    const [itemFocused, setItemFocused] = React.useState<boolean>(false);

    return <div style={{ display: "flex", alignItems: "center", border: itemFocused ? "1px solid black" : "none", padding: "4px 2px" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Avatar name={props.person.name} color="colorful" style={{
                marginRight: "10px", fontSize: "10px",
                fontWeight: 600
            }} />
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{props.person.name}</span>
                {props.person.destinationType == SharedWithDestinationType.User && <small>{props.person.userPrincipalName}</small>}
            </div>

        </div>

        <div style={{ marginLeft: "auto" }}>
            {props.isOwner == true && (<span>Owner</span>)}
            {!props.isOwner &&
                (<ManageAccessRoleDropdown
                    onMenuExpandToogle={(isOpened) => setItemFocused(isOpened)}
                    onSelectedRoleChanged={(role) => { props.onRoleChanged(props.person, role) }}
                    personaName={props.person.name} />)
            }
        </div>
    </div>
}