import { useMsal } from "@azure/msal-react";
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, Label, Link, Tab, TabList, tokens } from "@fluentui/react-components";
import { ArrowClockwise20Regular, Dismiss24Filled, InfoFilled } from "@fluentui/react-icons";
import React from "react";
import { toast } from "react-toastify";
import { getJobsDetailsClient } from "../../services/dispatcher.service";
import { JobProcessingStatus, JobResponseModel, JobType } from "../../swagger-clients/dispatcher-next-api-clients.service";
import JobStatus from "../job-status/job-status.component";
import { InputParametersTable } from "./input-parameters-table.component";
import { JobExecutionTable } from "./job-executions-table.component";
import { JobResultsTable, JobResultsTableType } from "./job-results-table.component";
import { JobActionsTable } from "./job-actions-table.component";
import { JobExecutionActions } from "./job-execution-actions.component";
import { useLoading } from "../../utils/loading-indicator.component";
import { Alert } from "@fluentui/react-components/unstable";
import { GLOBAL_STYLES } from "../../styles";
import { ApplicationDetailsModal } from "../application-details-modal/application-details-modal.component";
import { FileDisplayName } from "../file-display-name/file-display-name.component";
import ShowLocalTime from "../show-local-time/show-local-time.component";

type DispatcherJobDetailsProps = {
    jobId: number;
    isOpened?: boolean;
    isExample?: boolean;
    onSuccess?: () => void;
    onClose?: () => void;
};

type DispatcherJobDetailsModel = {
    name: string,
    extension?: string
};


export type DispatcherJobDetailsType = {
    externalReload?: (jobId: number) => void;
}
export const DispatcherJobDetails = React.forwardRef<DispatcherJobDetailsType, DispatcherJobDetailsProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            externalReload(jobId) {
                externalReload(jobId);
            }
        }));
    const [open, setOpen] = React.useState(false);
    const [unAuthorized, setUnAuthorized] = React.useState<boolean>(false);
    const [job, setJob] = React.useState<JobResponseModel>();
    const [showApplicationDetails, setShowApplicationDetails] = React.useState<boolean>();
    const [selectedTab, setSelectedTab] = React.useState<string>("details");
    const [selectedExecution, setSelectedExecution] = React.useState<number>();
    const [isLoading, loadingService] = useLoading();
    const { instance } = useMsal();

    const account = instance.getActiveAccount();
    const JobResultsTableRef = React.createRef<JobResultsTableType>();
    const JobActionsTableRef = React.createRef<any>();
    const JobExecutionActionsRef = React.createRef<any>();


    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);


    React.useEffect(() => {
        if (props.jobId)
            getJob(props.jobId);
    }, [props.jobId]);


    const getJob = async (jobId: number) => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                setUnAuthorized(false);
                const client = getJobsDetailsClient();
                var jobResp = await client.getJob(jobId);

                setJob(jobResp);

            } catch (error) {
                if ((error as any).code == "401") {
                    setUnAuthorized(true);
                } else {
                    toast.error("An error occurred while trying to get Job details.");
                }
                console.log("An error occurred while trying to get Job details.", error);
            } finally {
                hideMessage();
            }
        });

    }


    const externalReload = async (jobId: number) => {
        if (props.jobId == jobId) {
            console.log("Signalr reload.", jobId);
            await onRefreshClick();
        }
    }

    const getJobType = (jobType: JobType): string => {

        switch (jobType) {
            case JobType.AnalyzeFlowsheet:
                return "Analyze";
            case JobType.RunFlowsheet:
                return "Calculate";
            case JobType.RunFlowsheetStepByStep:
                return "Calculate";
        }
    }

    const onRefreshClick = async () => {

        getJob(props.jobId);

        if (JobResultsTableRef.current) {
            await JobResultsTableRef.current.getExecutionResults();
        }
        else if (JobActionsTableRef.current) {
            await JobActionsTableRef.current.getJobActions();
        }
        else if (JobExecutionActionsRef.current) {
            await JobExecutionActionsRef.current.getJobExecutionActions();
        }
    }


    const onClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }

    const cancelJob = (jobId: number) => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getJobsDetailsClient();
                await client.cancelJob(jobId);

                // Reload job details
                await getJob(jobId);

            } catch (error) {
                toast.error("An error occurred while trying to cancel job.");
            } finally {
                hideMessage();
            }
        });
    }

    const jobtype = job ? getJobType(job.jobType) : "";

    return (<Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "700px", maxWidth: "1200px" }}>

            <DialogBody style={{ minWidth: "inherit", maxWidth: "inherit", minHeight: "450px" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onClose} icon={<Dismiss24Filled />} />}>
                    {`Job #${props.jobId}`}
                    <Button appearance="subtle" icon={<ArrowClockwise20Regular />} onClick={() => onRefreshClick()}></Button>
                </DialogTitle>
                <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                    {unAuthorized !== undefined && unAuthorized == true &&
                        <Alert intent="error"> You are not authorized to view this job.</Alert>
                    }
                    {(unAuthorized == undefined || unAuthorized == false) && job &&
                        <>
                            {account.localAccountId !== job.adUserId &&
                                <Alert intent="info" className="alert--info-global">
                                    This job was created by {job.owner?.displayName}.
                                </Alert>
                            }

                            <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
                                <Tab key="tab-details" value="details" >Details</Tab>
                                <Tab key="tab-input-parameters" value="input-parameters" >Input parameters</Tab>
                                <Tab key="tab-executions" value="executions" >Executions</Tab>
                                <Tab key="tab-results" value="results" >Results</Tab>
                                <Tab key="tab-actions" value="actions" >Actions</Tab>
                                <Tab key="tab-executed-actions" value="executed-actions" >Executed actions</Tab>

                            </TabList>
                            <div className="tab-content">
                                {selectedTab == "details" && <div>

                                    <div className="input-form">
                                        <div className="input-form-item input-form-item--without-margin">
                                            <Label className="input-form-label">
                                                Id:
                                            </Label>
                                            <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{job?.id}</p>
                                        </div>
                                      {!props.isExample &&  <div className="input-form-item input-form-item--without-margin">
                                            <Label className="input-form-label">
                                                Flowsheet:
                                            </Label>
                                            <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>
                                                <FileDisplayName fileUniqueIdentifier={job.flowsheetItemId} fileVersionNumber={+job.flowsheetVersion} /></p>
                                        </div>}

                                        {!props.isExample &&    <div className="input-form-item input-form-item--without-margin">
                                            <Label className="input-form-label">
                                                Application:
                                            </Label>
                                            <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>
                                                <Link onClick={() => { setShowApplicationDetails(true); }}>{job?.application}</Link></p>
                                        </div>}

                                        <div className="input-form-item input-form-item--without-margin">
                                            <Label className="input-form-label">
                                                Job type:
                                            </Label>
                                            <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{jobtype}</p>
                                        </div>
                                        <div className="input-form-item input-form-item--without-margin">
                                            <Label className="input-form-label">
                                                Created At:
                                            </Label>
                                            <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{job ? <ShowLocalTime date={job.createdAt} /> : ""}</p>
                                        </div>
                                        <div className="input-form-item input-form-item--without-margin">
                                            <Label className="input-form-label">
                                                Processing status:
                                            </Label>
                                            <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{job ? <JobStatus value={job.processingStatus} /> : ""}</p>
                                        </div>

                                    </div>


                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Button appearance="primary"
                                            style={{ height: "100%", margin: "0px 10px", padding: "10px", display: "flex", flexDirection: "column" }}
                                            disabled>
                                            <strong> Run failed job again </strong>
                                            <span>and execute actions</span>
                                        </Button>
                                        <Button
                                            style={{ height: "100%", margin: "0px 10px", padding: "10px", display: "flex", flexDirection: "column" }}
                                            disabled>
                                            <strong>Run job </strong>
                                            <span>and save flowsheet</span>
                                        </Button>
                                        <Button disabled={job.processingStatus == JobProcessingStatus.Failed || job.processingStatus == JobProcessingStatus.Cancelled} onClick={() => cancelJob(job.id)} style={{ margin: "0", marginLeft: "auto" }} >Cancel job</Button>
                                    </div>
                                </div>
                                }

                                {selectedTab == "input-parameters" && <div>
                                    <InputParametersTable
                                        isLoading={isLoading}
                                        inputParameters={job.inputParameters}
                                        fileUniqueId={job.flowsheetItemId}
                                        fileVersionNumber={job.flowsheetVersion} />
                                </div>}
                                {selectedTab == "executions" &&
                                    <JobExecutionTable
                                        isLoading={isLoading}
                                        job={job}
                                        isExample={props.isExample}
                                        executions={job.executions ?? []}
                                        showJobExecutionActions={(executionId: number) => { setSelectedTab("executed-actions"); setSelectedExecution(executionId); }}
                                        showExecutionResults={(executionId: number) => { setSelectedTab("results"); setSelectedExecution(executionId); }}
                                    />}

                                {selectedTab == "results" && <JobResultsTable
                                    ref={JobResultsTableRef}
                                    jobExecutionId={selectedExecution}
                                    fileUniqueId={job ? job.flowsheetItemId : ""}
                                    fileVersionNumber={job ? job.flowsheetVersion : ""}
                                    executions={job ? job.executions : []} />}
                                {selectedTab == "actions" && <JobActionsTable ref={JobActionsTableRef} jobId={job ? job.id : undefined}
                                />
                                }
                                {selectedTab == "executed-actions" &&
                                    <JobExecutionActions 
                                    ref={JobExecutionActionsRef} 
                                    executions={job ? job.executions : []}
                                        
                                        jobExecutionId={selectedExecution}
                                    />}
                            </div>
                            {showApplicationDetails && <ApplicationDetailsModal
                                applicationId={job?.applicationId}
                                isOpened={showApplicationDetails}
                                onClose={() => { setShowApplicationDetails(false); }}
                            />}
                        </>
                    }
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>);
});