import { CircleFilled } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from './sidebar-menu';

type SidebarSubmenuProps = {
    items: MenuItem[]
}

export const SidebarSubmenu: React.FC<any> = (props: SidebarSubmenuProps) => {

    const navigate = useNavigate();   

    const itemClicked = (item: MenuItem, e: React.MouseEvent) => {
        e.preventDefault();

        if (item.action) {
            if (typeof item.action === 'string') {
                navigate(item.action);
            }
            else if (typeof item.action === 'function') {
                item.action(navigate);
            }
        }
    };

    return <ul className='sidebar__submenu'>
        {props.items.filter(x => !x.hidden).map(item => {
            return <>
                <li className='sidebar__submenu-item'>
                    <a
                        className='sidebar__submenu-item-inner'
                        href={item.action && typeof item.action === 'string' ? item.action : undefined}
                        onClick={e => itemClicked(item, e)}
                    >
                        <CircleFilled className='sidebar__submenu-item-icon' />{item.name}
                    </a>
                    {item.children && <SidebarSubmenu items={item.children} />}
                </li>
            </>;
        })}
    </ul>;
}