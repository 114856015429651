import { Label } from "@fluentui/react-components";
import React from "react";
import { getJobActionsClient } from "../../services/dispatcher.service";
import { EmailActionResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";


type EmailActionDetailsProps = {
    actionId: number;
}

export const EmailActionDetails: React.FC<EmailActionDetailsProps> = (props) => {

    const [actionDetails, setActionDetails] = React.useState<EmailActionResponseModel>();

    React.useEffect(() => {
        if (props.actionId) {
            getDetails();
        }
    }, [props.actionId]);
    
    const getDetails = async () => {
        try {

            const client = getJobActionsClient();
            var details = await client.getEmailAction(props.actionId);
          setActionDetails(details);

        } catch (error) {
          processServerError("An error occurred while getting Email action details.");
            console.log("An error occurred while getting Email action details.", error);
        }
    }

    return <div>
 <div className="input-form">
                <div className="input-form-item">
                    <Label style={{ fontWeight: 600, width: "30%", textAlign: "right", maxWidth: "250px" }}>To:</Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{actionDetails ? actionDetails.to : ""}</p>
                </div>
                <div className="input-form-item">
                    <Label style={{ fontWeight: 600, width: "30%", textAlign: "right", maxWidth: "250px" }}>From:</Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{actionDetails ? actionDetails.from : ""}</p>
                </div>
                <div className="input-form-item">
                    <Label style={{ fontWeight: 600, width: "30%", textAlign: "right", maxWidth: "250px" }}>Subject:</Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{actionDetails ? actionDetails.subject : ""}</p>
                </div>
                <div className="input-form-item">
                    <Label style={{ fontWeight: 600, width: "30%", textAlign: "right", maxWidth: "250px" }}>Is HTML:</Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{actionDetails ? (actionDetails.isHtml == true ? "Yes" : "No") : ""}</p>
                </div>

            </div>
            <div style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                <Label style={{ fontWeight: 600 }}>Message:</Label>
                <pre style={{ backgroundColor: "lightgray", whiteSpace: "pre-wrap" }}>{actionDetails ? actionDetails.message : ""}</pre>
            </div>
    </div>
}