import { Menu, MenuTrigger, MenuButtonProps, Tooltip, SplitButton, Button, MenuPopover, MenuList, MenuItem } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell } from "@fluentui/react-components";
import React from "react";
import { toast } from "react-toastify";
import { ConfirmationDialog } from "../../../../components/confirmation-dialog/confirmation-dialog.component";
import { TableBodyWithLoading } from "../../../../components/table-body-with-loading/table-body-with-loading.component";
import { getAgentRegistrationTokensClient } from "../../../../services/dispatcher.service";
import { AgentRegistrationTokenResponseModel } from "../../../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../../../utils/helpers/error.helper";
import { useLoading } from "../../../../utils/loading-indicator.component";
import ShowLocalTime from "../../../../components/show-local-time/show-local-time.component";

type AgentRegistrationTokensTableProps = {
    agentGroupId: number;
}

export const AgentRegistrationTokensTable: React.FC<AgentRegistrationTokensTableProps> = (props) => {
    const [agentRegistrationTokens, setAgentRegistrationTokens] = React.useState<AgentRegistrationTokenResponseModel[]>([]);
    const [showDeactivateDialog, setShowDeactivateDialog] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const [showCopied, setShowCopied] = React.useState<string>();

    React.useEffect(() => {
        getAgentRegistrationTokens();
    }, []);
    const getAgentRegistrationTokens = async () => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getAgentRegistrationTokensClient();
                const agentRegistrationTokensResp = await client.getAgentRegistrationTokens(props.agentGroupId);
                setAgentRegistrationTokens(agentRegistrationTokensResp);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting agent registration tokens.");
            } finally {
                hideMessage();
            }

        });
    }

    const onCreateTokenClicked = async () => {
        loadingService.showLoading("Creating token...", async (hideMessage) => {
            try {
                const client = getAgentRegistrationTokensClient();
                const newToken = await client.createToken(props.agentGroupId);
                const updatedTokens = [newToken, ...agentRegistrationTokens];
                setAgentRegistrationTokens(updatedTokens);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to create agent registration token.");
            } finally {
                hideMessage();
            }
        });


    }

    const onDeactivateTokenClicked = async () => {
        loadingService.showLoading("Deactivating token...", async (hideMessage) => {
            try {
                const client = getAgentRegistrationTokensClient();
                await client.deactivateToken(props.agentGroupId);
                setShowDeactivateDialog(false);
                toast.success("Api key deactivated.");
                await getAgentRegistrationTokens();
            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to deactivate agent registration token.");
            }
            finally {
                hideMessage();
            }
        });
    }

    const onCopyClicked = (apiKey: string) => {
        navigator.clipboard.writeText(apiKey);

        setShowCopied(apiKey);
        setTimeout(() => { setShowCopied(undefined); }, 2000);
    }

    return <div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}><Button appearance="primary" disabled={isLoading} onClick={() => onCreateTokenClicked()}>Create Token</Button></div>
        <Table >
            <TableHeader>
                <TableRow>
                    <TableHeaderCell key="name" style={{ width: "300px" }} className='s365-table__cell--bold'>Name</TableHeaderCell>
                    <TableHeaderCell key="created-at" className='s365-table__cell--bold'>Created At</TableHeaderCell>
                    <TableHeaderCell key="actions" className='s365-table__cell--bold'></TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={isLoading}
                columnCount={3} loadingMessage="Loading..."
                itemCount={agentRegistrationTokens ? agentRegistrationTokens.length : 0}
                noItemsMessage="No items found.">
                {agentRegistrationTokens && agentRegistrationTokens.length > 0 && agentRegistrationTokens.map((item) => {
                    return <TableRow key={`application-${item.id}`} >
                        <TableCell style={{ textDecoration: !item.isActive ? "line-through" : undefined }}>{item.token}</TableCell>
                        <TableCell><ShowLocalTime date={item.createdAt}  /></TableCell>
                        <TableCell>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>

                                {item.isActive &&
                                    <Menu positioning="below-end">
                                        <MenuTrigger disableButtonEnhancement>
                                            {(triggerProps: MenuButtonProps) =>
                                                <Tooltip relationship="description" visible={showCopied == item.token} content="Copied to clipboard">
                                                    <SplitButton size="small"
                                                        primaryActionButton={
                                                            <Button size="small"
                                                                appearance="transparent"
                                                                style={{ padding: 0 }}
                                                                onClick={() => onCopyClicked(item.token)}>Copy</Button>}
                                                        menuButton={triggerProps} ></SplitButton>
                                                </Tooltip>}
                                        </MenuTrigger>
                                        <MenuPopover style={{ minWidth: "90px" }}>
                                            <MenuList>
                                                <MenuItem style={{ height: "20px" }} disabled={isLoading} onClick={() => { setShowDeactivateDialog(true) }}>Deactivate</MenuItem>
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>}
                                {!item.isActive && <span style={{ paddingRight: "13px" }}><b>Inactive</b></span>}
                            </div>

                        </TableCell>

                    </TableRow>
                })}
            </TableBodyWithLoading>
        </Table>
        <ConfirmationDialog
            title="Deactivate agent registration token"
            subText={`Do you want to deactivate  current agent registration token?`}
            isOpened={showDeactivateDialog}
            onConfirm={() => { onDeactivateTokenClicked(); }}
            onClose={() => setShowDeactivateDialog(false)} />
    </div>
}