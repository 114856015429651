import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions, Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { FileTableItem } from "../../models/file-table.models";
import { SelectDoeExperimentsTable } from "./select-doe-experiments-table.component";
import { SelectFilterModel, SelectFiltersTable } from "./select-filters-table.component";
import { SelectMssStudiesTable } from "./select-mss-studies-table.component";
import { SelectCopilotExperimentsTable } from "./select-copilot-experiments-table.component";
import { getFileExtension } from "../../file-type-icon/file-type-icon.helpers";
import { ICopyApplicationItemModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { SelectTheeTable } from "./select-thee-table.component";

type CopyFileObjectsDialogProps = {
    fileToCopy: FileTableItem;
    isClone?: boolean;
    isOpened: boolean;
    onClose: () => void;
    onSuccess: (data: CopyFileObjectResponseModel) => void;
}

export type ApplicationSelectionModel = {
    id: number;
    filterId?: number;
    filterVersionId?: number;
}
export type CopyFileObjectResponseModel = {
    filters?: number[];
    doeExperiments?: ICopyApplicationItemModel[];
    mssStudies?: ICopyApplicationItemModel[];
    optimumTests?: ICopyApplicationItemModel[];
    copilotExperiments?: ICopyApplicationItemModel[];
    theeExams?: ICopyApplicationItemModel[];
}

const applicationObjectsByFileExtension: { [extension: string]: string[] } = {
    "dwxmz": ["filters", "doe", "mss", "opt", "copilot"],
    "dwxml": ["filters", "doe", "mss", "opt", "copilot"],
    "xlsx": ["doe", "thee"]
}

export const CopyFileObjectsDialog: React.FC<CopyFileObjectsDialogProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const fileExtension = React.useMemo(() => getFileExtension(props.fileToCopy.name), [props.fileToCopy]);
    const possibleApplications = applicationObjectsByFileExtension[fileExtension];

    const [openItems, setOpenItems] = React.useState(possibleApplications);
    const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
        setOpenItems(data.openItems);
    };
    const [filtersCount, setFiltersCount] = React.useState<number>();
    const [selectedFilters, setSelectedFilters] = React.useState<SelectFilterModel[]>([]);

    const [doeExperimentsCount, setDoeExperimentsCount] = React.useState<number>();
    const [selectedDoeExperiments, setSelectedDoeExperiments] = React.useState<ApplicationSelectionModel[]>([]);

    const [theeExamsCount, setTheeExamsCount] = React.useState<number>();
    const [selectedTheeExams, setSelectedTheeExams] = React.useState<ApplicationSelectionModel[]>([]);

    const [mssStudiesCount, setMssStudiesCount] = React.useState<number>();
    const [selectedMssStudies, setSelecteMssStudies] = React.useState<ApplicationSelectionModel[]>([]);

    const [optimumTestsCount, setOptimumTestsCount] = React.useState<number>();
    const [selectedOptimumTests, setSelectedOptimumTests] = React.useState<ApplicationSelectionModel[]>([]);

    const [copilotExperimentsCount, setCopilotExperimentsCount] = React.useState<number>();
    const [selectedCopilotExperiments, setSelectedCopilotExperiments] = React.useState<ApplicationSelectionModel[]>([]);


    React.useEffect(() => { setOpen(!!props.isOpened); }, [props.isOpened]);


    const onModalClose = () => {
        setOpen(false);
        props.onClose();
    }

    const onUpdateRequiredFilters = (data: ApplicationSelectionModel[], applicationMarker: string) => {
        let filtermap: { [filterId: string]: string[] } = {};
        data?.forEach(item => {
            if (!!item.filterId) {
                if (!filtermap[item.filterId.toString()]) {
                    filtermap[item.filterId.toString()] = [`${applicationMarker}_${item.id}`];
                } else {
                    filtermap[item.filterId.toString()].push(`${applicationMarker}_${item.id}`);
                }
            }
        });
        const filtersToUpdate = Object.keys(filtermap).map((filterId: string) =>
            ({ id: +filterId, requiredFor: new Set<string>(filtermap[filterId.toString()] ?? []) } as SelectFilterModel));
        updateFilters(filtersToUpdate, applicationMarker);

    }

    const updateFilters = (data: SelectFilterModel[], applicationMarker: string) => {
        let existingFilters = [...selectedFilters];
        console.log("existingFilters,data", existingFilters, data);

        // remove filters not in data

        existingFilters.forEach(filter => {
            const dataFilter = data?.find(x => x.id == filter.id);
            if (dataFilter === undefined) {
                filter.requiredFor = new Set<string>([]);
            }
        });

        data.forEach(filter => {
            let existingFilter = existingFilters?.find(x => x.id == filter.id);
            if (existingFilter === undefined) {
                existingFilters.push(filter);
            } else {
                if (filter.requiredFor !== undefined && filter.requiredFor.size > 0) {
                    if (existingFilter.requiredFor === undefined || existingFilter.requiredFor.size == 0) {
                        existingFilter.requiredFor = filter.requiredFor;
                    } else {
                        const filterRequiredForValues = [...(filter.requiredFor ?? [])];

                        // remove old values                       
                        let existingFilterRequiredForValues = [...existingFilter.requiredFor];
                        const applicationRequiredForValues = existingFilterRequiredForValues.filter(x => x.indexOf(`${applicationMarker}_${filter.id}`) === -1);
                        const filteredApplicationRequiredForValues = [...(applicationRequiredForValues ?? []), ...(filterRequiredForValues ?? [])]
                        existingFilter.requiredFor = new Set<string>(filteredApplicationRequiredForValues ?? []);

                        // add new required values
                        filterRequiredForValues.forEach(value => {
                            if (!existingFilter.requiredFor.has(value)) {
                                existingFilter.requiredFor.add(value);
                            }
                        });
                    }
                }
            }
        });
        setSelectedFilters(existingFilters);
    }

    const onContinueClick = () => {
        const model = {
            filters: selectedFilters?.map(x => x.id) ?? [],
            doeExperiments: selectedDoeExperiments?.map(x => ({ id: x.id, filterId: +x.filterId, filterVersionId: +x.filterVersionId } as ICopyApplicationItemModel)) ?? [],
            mssStudies: selectedMssStudies?.map(x => ({ id: x.id, filterId: +x.filterId, filterVersionId: +x.filterVersionId } as ICopyApplicationItemModel)) ?? [],
            optimumTests: selectedOptimumTests?.map(x => ({ id: x.id, filterId: +x.filterId, filterVersionId: +x.filterVersionId } as ICopyApplicationItemModel)) ?? [],
            copilotExperiments: selectedCopilotExperiments?.map(x => ({ id: x.id, filterId: +x.filterId, filterVersionId: +x.filterVersionId } as ICopyApplicationItemModel)) ?? [],
            theeExams: selectedTheeExams?.map(x => ({ id: x.id, filterId: +x.filterId, filterVersionId: +x.filterVersionId } as ICopyApplicationItemModel)) ?? []
        } as CopyFileObjectResponseModel;
        props.onSuccess(model);
    }



    return <Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }


    }}>
        <DialogSurface style={{ width: "70vw", maxWidth: "100%" }}>

            <DialogBody style={{ maxWidth: "100%" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>{props.isClone ? "Clone" : "Copy"} file with data</DialogTitle>
                <DialogContent style={{ height: "80vh" }}>
                    <Accordion
                        multiple
                        collapsible
                        openItems={openItems}
                        onToggle={handleToggle}>
                        {possibleApplications.indexOf("filters") > -1 && <AccordionItem value="filters">
                            <AccordionHeader>Filters
                                {filtersCount !== undefined &&
                                    <span style={{ marginLeft: "5px" }}>({selectedFilters.length}/{filtersCount})</span>}
                            </AccordionHeader>
                            <AccordionPanel>
                                <div>
                                    <SelectFiltersTable
                                        file={props.fileToCopy}
                                        selectedItems={selectedFilters}
                                        onDataLoaded={(count) => { setFiltersCount(count) }}
                                        onSelectedItemsChanged={(itemIds) => { setSelectedFilters(itemIds); }}
                                    /></div>
                            </AccordionPanel>
                        </AccordionItem>}

                        {possibleApplications.indexOf("doe") > -1 && <AccordionItem value="doe">
                            <AccordionHeader>Design of Experiments (DoE)
                                {doeExperimentsCount !== undefined &&
                                    <span style={{ marginLeft: "5px" }}>({selectedDoeExperiments.length}/{doeExperimentsCount})</span>}
                            </AccordionHeader>
                            <AccordionPanel>
                                <div>
                                    <SelectDoeExperimentsTable
                                        file={props.fileToCopy}
                                        selectedItems={selectedDoeExperiments?.map(x => x.id) ?? []}
                                        onDataLoaded={(count) => { setDoeExperimentsCount(count) }}
                                        onSelectedItemsChanged={(selectedItems) => {
                                            setSelectedDoeExperiments(selectedItems);
                                            if (fileExtension !== "xlsx") {
                                                onUpdateRequiredFilters(selectedItems, "doe");
                                            }
                                        }}
                                    /></div>
                            </AccordionPanel>
                        </AccordionItem>}
                        {possibleApplications.indexOf("thee") > -1 && <AccordionItem value="thee">
                            <AccordionHeader>Take-Home Exercise / Exam (THEE)
                                {theeExamsCount !== undefined &&
                                    <span style={{ marginLeft: "5px" }}>({selectedTheeExams.length}/{theeExamsCount})</span>}
                            </AccordionHeader>
                            <AccordionPanel>
                                <div>
                                    <SelectTheeTable
                                        file={props.fileToCopy}
                                        selectedItems={selectedTheeExams?.map(x => x.id) ?? []}
                                        onDataLoaded={(count) => { setTheeExamsCount(count) }}
                                        onSelectedItemsChanged={(selectedItems) => {
                                            setSelectedTheeExams(selectedItems);
                                        }}
                                    /></div>
                            </AccordionPanel>
                        </AccordionItem>}

                        {possibleApplications.indexOf("mss") > -1 && <AccordionItem value="mss">
                            <AccordionHeader>Multivariate Sensitivity Studies (MSS)
                                {mssStudiesCount !== undefined &&
                                    <span style={{ marginLeft: "5px" }}>({selectedMssStudies.length}/{mssStudiesCount})</span>}
                            </AccordionHeader>
                            <AccordionPanel>
                                <div>
                                    <SelectMssStudiesTable
                                        isOptimumTest={false}
                                        file={props.fileToCopy}
                                        selectedItems={selectedMssStudies?.map(x => x.id) ?? []}
                                        onDataLoaded={(count) => { setMssStudiesCount(count) }}
                                        onSelectedItemsChanged={(selectedItems) => {
                                            setSelecteMssStudies(selectedItems);
                                            onUpdateRequiredFilters(selectedItems, "mss");
                                        }}
                                    /></div>
                            </AccordionPanel>
                        </AccordionItem>}

                        {possibleApplications.indexOf("opt") > -1 && <AccordionItem value="opt">
                            <AccordionHeader>Optimum Tests (OT)
                                {optimumTestsCount !== undefined &&
                                    <span style={{ marginLeft: "5px" }}>({selectedOptimumTests.length}/{optimumTestsCount})</span>}
                            </AccordionHeader>
                            <AccordionPanel>
                                <div>
                                    <SelectMssStudiesTable
                                        isOptimumTest={true}
                                        file={props.fileToCopy}
                                        selectedItems={selectedDoeExperiments?.map(x => x.id) ?? []}
                                        onDataLoaded={(count) => { setOptimumTestsCount(count) }}
                                        onSelectedItemsChanged={(selectedItems) => {
                                            setSelectedOptimumTests(selectedItems);
                                            onUpdateRequiredFilters(selectedItems, "opt");
                                        }}
                                    /></div>
                            </AccordionPanel>
                        </AccordionItem>}
                        {possibleApplications.indexOf("copilot") > -1 && <AccordionItem value="copilot">
                            <AccordionHeader>Flowsheet Copilot Experiments
                                {copilotExperimentsCount !== undefined &&
                                    <span style={{ marginLeft: "5px" }}>({selectedCopilotExperiments.length}/{copilotExperimentsCount})</span>}
                            </AccordionHeader>
                            <AccordionPanel>
                                <div>
                                    <SelectCopilotExperimentsTable
                                        file={props.fileToCopy}
                                        selectedItems={selectedCopilotExperiments?.map(x => x.id) ?? []}
                                        onDataLoaded={(count) => { setCopilotExperimentsCount(count) }}
                                        onSelectedItemsChanged={(selectedItems) => {
                                            setSelectedCopilotExperiments(selectedItems);
                                        }}
                                    /></div>
                            </AccordionPanel>
                        </AccordionItem>}

                    </Accordion>

                </DialogContent>
                <DialogActions>
                    <Button appearance="primary" onClick={onContinueClick}>Continue</Button>
                    <Button appearance="secondary" onClick={onModalClose}>Cancel</Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}

