import { TabList, Tab, Button, Field, Input } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from "@fluentui/react-components";
import React from "react";
import { useParams } from "react-router-dom";
import { getEducationsClient } from "../../../../services/take-home-exams.service";
import { ExamDescriptionModel } from "../../../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { processServerError } from "../../../../utils/helpers/error.helper";
import { ValidationResult } from "../../../../utils/helpers/validation.helpers";
import { LoadingService } from "../../../../utils/loading-indicator.component";
import { defaultPlaceholders, ExerciseTemplateEditor } from "../../../edit-exam/exercises-table/exercise-template-editor/exercise-template-editor.component";
import { IExercise, IExerciseParameter } from "../../../shared/exams.models";
import { SubmitResultsRouteParams } from "../submit-results.component";
import MathJaxDisplay from "../../../../components/mathjax-display/mathjax-display";

type ResultsTableProps = {
    validationResult: ValidationResult;
    exercises: IExercise[];
    isFormSubmitted: boolean;
    isRequestInProgress: boolean;
    isDisabled?: boolean;
    loadingService: LoadingService;
    onChange(exercises: IExercise[]);
    onAutoSave();

}

export const ResultsTable: React.FC<ResultsTableProps> = (props) => {
    const routeParams = useParams<SubmitResultsRouteParams>();
    const [selectedExerciseIndex, setSelectedExerciseIndex] = React.useState<number | undefined>(-1);
    const [selectedSubExerciseIndex, setSelectedSubExerciseIndex] = React.useState<number | undefined>(-1);
    const [examDescription, setExamDescription] = React.useState<ExamDescriptionModel>();
    const { exercises } = props;
    const selectedExercise = exercises && selectedExerciseIndex > -1 && exercises.length > 0 ? exercises[selectedExerciseIndex] : undefined;
    const selectedSubExercise = selectedExercise && selectedSubExerciseIndex > -1 && selectedExercise.subExercises.length > 0 ? selectedExercise.subExercises[selectedSubExerciseIndex] : selectedExercise;

    React.useEffect(() => {
        getExamDescription();
    }, []);

    const getExamDescription = () => {
        props.loadingService.showLoading("Getting exam description...", async (hideMessage) => {
            try {
                const client = getEducationsClient();
                const examDesc = await client.getUserExamDescription(+routeParams.examId);
                setExamDescription(examDesc);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting exam description.");
            } finally {
                hideMessage();
            }

        });
    }

    const onTextPropertyChange = (ev, newValue: string, propertyName: string, parameterId: number, exerciseIndex: number, subExIndex?: number) => {
        let { exercises } = props;
        console.log("onTextPropertyChange", exercises, exerciseIndex, subExIndex);
        const value = newValue && newValue.length > 0 ? newValue : undefined;
        let exercise = exercises[exerciseIndex];
        if (subExIndex !== undefined) {
            exercise = exercise.subExercises[subExIndex];
        }

        exercise.outputParameters = exercise.outputParameters.map(x => {
            if (x.id == parameterId)
                return { ...x, value: value as any } as IExerciseParameter;
            else
                return x;
        });

        props.onChange(exercises);

    }

    const getProgressStatus = (exerciseIndex: number, exSubIndex): string => {
        const { exercises } = props;
        var exercise = exercises[exerciseIndex];
        var subExercises = exercise.subExercises;
        if (exSubIndex == undefined && subExercises && subExercises.length > 0) {
            let total = 0;
            let filledCount = 0;
            subExercises.forEach((subExercise, subIndex) => {
                total += subExercise.outputParameters.length;
                filledCount += subExercise.outputParameters.filter(x => x && x.value && x.value.toString().length > 0).length;
            });
            return `(${filledCount}/${total})`;
        }
        if (exSubIndex !== undefined) {
            exercise = exercise.subExercises[exSubIndex];
        }
        if (exercise) {
            const total = exercise.outputParameters.length;
            const filledCount = exercise.outputParameters.filter(x => x && x.value && x.value.toString().length > 0).length;
            return `(${filledCount}/${total})`;
        }
        return "";


    }

    const onRenderItemLink = (exIndex: number, validationResult: ValidationResult, isFormSubmitted: boolean): JSX.Element => {

        var errors = isFormSubmitted && validationResult ? validationResult.getPartialFieldValidationMessages(`ex_${exIndex}`, isFormSubmitted) : [];
        const hasErrors = errors && errors.length > 0 ? true : false;

        return <span> {`Exercise ${exIndex + 1} ${getProgressStatus(exIndex, undefined)}`}</span>

    }


    const onSubExerciseRenderItemLink = (exIndex: number, subexIndex: number, validationResult: ValidationResult, isFormSubmitted: boolean): JSX.Element => {

        return <span> {`Result submission ${exIndex + 1}.${String.fromCharCode(97 + subexIndex)} ${getProgressStatus(exIndex, subexIndex)}`}</span>
    }

    const getExerciseDescription = () => {
        const { exercises } = props;
        const exercise = exercises[selectedExerciseIndex];
        console.log("getExerciseDescription", exercise, examDescription);
        if (exercise.subExercises.length == 0) {
            const exerciseDescription = examDescription.exercises.find(x => x.id == exercise.id);
            return exerciseDescription.description;
        } else {
            const exerciseDescription = examDescription.exercises.find(x => x.id == exercise.subExercises[0].id);
            return exerciseDescription.description;
        }
    }

    const getParameterValueCell = (item: IExerciseParameter, index: number) => {
        let columnId = `ex${item.exerciseId}`;
        if (item.subexerciseIndex !== undefined) {
            columnId += `_${item.subexerciseIndex}`;
        }
        columnId += `_p${item.id}`;

        return <Field
            validationMessage={props.validationResult.getFieldValidationMessage(columnId, props.isFormSubmitted)}
            validationState={!!props.validationResult.getFieldValidationMessage(columnId, props.isFormSubmitted) ? "error" : "none"}>
            <Input
                key={item.id.toString()}
                value={item.value ? item.value.toString() : ""}
                disabled={props.isRequestInProgress || props.isDisabled}
                onChange={(ev, data) => { onTextPropertyChange(ev, data.value, "value", item.id, item.exerciseIndex, item.subexerciseIndex) }}
                onBlur={() => props.onAutoSave()} />
        </Field>
    }

    return <> <TabList selectedValue={selectedExerciseIndex !== undefined ? selectedExerciseIndex.toString() : undefined}
        onTabSelect={(ev, data) => {
            setSelectedExerciseIndex(+data.value);
            const ex = exercises[+data.value];

            setSelectedSubExerciseIndex(-1);


        }}>
        <Tab key="-1" value="-1">Introduction</Tab>

        {
            exercises.map((exercise, exIndex) => {
                return <Tab key={exIndex.toString()} value={exIndex.toString()} >{onRenderItemLink(exIndex, props.validationResult, props.isFormSubmitted)}</Tab>
            })
        }

    </TabList>
        <div id="exercise-tab-content">
            {selectedExerciseIndex == -1 && <div className="exam-pdf-wrapper">
                    <MathJaxDisplay html={examDescription?.introduction} />
                </div>
            }
            {selectedExerciseIndex > -1 && <div>
                <TabList selectedValue={selectedExerciseIndex !== undefined && selectedSubExerciseIndex !== undefined ? `${selectedExerciseIndex.toString()}_${selectedSubExerciseIndex.toString()}` : undefined}
                    onTabSelect={(ev, data) => {
                        console.log("on subExercise tab click", data.value);

                        const keys = data.value.toString().split("_");
                        const exerciseIndex = +keys[0];
                        const subExerciseIndex = +keys[1];
                        const pivotExercise: IExercise = exercises[exerciseIndex];

                        setSelectedSubExerciseIndex(subExerciseIndex);


                    }}>
                    <Tab
                        key={`${selectedExerciseIndex.toString()}_-1`}
                        value={`${selectedExerciseIndex.toString()}_-1`} >
                        Description</Tab>

                    {
                        selectedExercise && selectedExercise.subExercises.map((subexercise, subExIndex) => {

                            return <Tab
                                key={`${selectedExerciseIndex.toString()}_${subExIndex.toString()}`}
                                value={`${selectedExerciseIndex.toString()}_${subExIndex.toString()}`} >
                                {onSubExerciseRenderItemLink(selectedExerciseIndex, subExIndex, props.validationResult, props.isFormSubmitted)}</Tab>

                        })
                    }
                    {selectedExercise && selectedExercise.subExercises.length == 0 &&
                        <Tab
                            key={`${selectedExerciseIndex.toString()}_-2`}
                            value={`${selectedExerciseIndex.toString()}_-2`} >
                            Result submission</Tab>
                    }


                </TabList>

                <div id="sub-exercise-tab">
                    {selectedSubExercise && selectedSubExerciseIndex !== -1 && selectedSubExerciseIndex <= selectedExercise.subExercises.length &&
                        <>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell key="description" className='exams-table__cell--bold'>Description</TableHeaderCell>
                                            <TableHeaderCell key="value" className='exams-table__cell--bold'>Value</TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {selectedSubExercise && selectedSubExercise.outputParameters.length > 0 && selectedSubExercise.outputParameters.map((param, paramIndex) => {
                                            return <TableRow key={`EX_${selectedExerciseIndex}_Sub_${selectedSubExerciseIndex}_outparam_${paramIndex}`}>
                                                <TableCell>{param.description} </TableCell>
                                                <TableCell>{getParameterValueCell(param, paramIndex)}</TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </>

                    }
                    {
                        selectedSubExerciseIndex == -1 && <div className="exam-pdf-wrapper">
                            <MathJaxDisplay html={getExerciseDescription()} />
                        </div>
                    }



                </div>
            </div>
            }
        </div>

    </>
}