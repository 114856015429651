import * as React from 'react';
import { getIconImageUrl } from './file-type-icon.helpers';

export interface IFileTypeIconProps {
  filename: string,
  isFolder?: boolean,
  width?: number;
  height?: number;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const FileTypeIcon: React.FunctionComponent<IFileTypeIconProps> = (props: IFileTypeIconProps) => {
  const imageUrl = getIconImageUrl(props.filename, props.isFolder);

  return <img src={imageUrl} width={props.width ?? 20} height={props.height ?? 20} className={props.className} alt={props.alt} style={props.style} />
}