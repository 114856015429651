import { Toolbar, ToolbarButton, Label, Tab, TabList, Field, Input } from "@fluentui/react-components";
import { Save20Regular } from "@fluentui/react-icons";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import FilterPicker from "../../components/filter-picker/filter-picker.component";
import { getFilesClient, getFiltersClient, getUserToken } from "../../services/dashboard.service";
import { getDoeExperimentsClient, getScenariosClient } from "../../services/excel-runner.service";
import { CalculationType, DoeExperimentPostModel, ExperimentParameterPostModel, ExperimentRowModel, IExperimentParameterPostModel, ParameterModel2, ProcessingStatus, ScenarioDataQuery, ScenarioPostModel, ScenarioQuery, ScenarioResultModel } from "../../swagger-clients/excel-runner-api-clients.service";
import { BreadcrumbItem, FileModel, FileWithBreadcrumbsResponseModel, FilterParameterType, FilterResultModel, InputFilterParameterResultModel, OutputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { _copyAndSort } from "../../utils/helpers/array.helpers";
import { processServerError } from "../../utils/helpers/error.helper";
import { IValidationResultData, nameof, ValidationResult } from "../../utils/helpers/validation.helpers";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { INewScenario } from "../edit-scenario/models/scenario.models";
import { EditDoeExperimentInputParametersTab } from "./input-parameters-tab.component";
import { INewExperiment, ParametersModel } from "./experiment.models";
import { EditDoeExperimentOutputParametersTab } from "./output-parameters-tab.component";
import { ThumbnailImage } from "../../files/thumbnail/thumbnail-image/thumbnail-image.component";
import { getFileExtension } from "../../files/file-type-icon/file-type-icon.helpers";
import { HubConnection } from "@microsoft/signalr";
import { ThumbnailModal } from "../../files/thumbnail/thumbnail-modal/thumbnail-modal.component";

type EditDoeExperimentProps = {
    hubConnection?: HubConnection;
}
export type ExcelRunnerRouteParams = {
    uniquefileId: string,
    experimentId?: string
}
const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];



export const EditDoeExperiment: React.FC<EditDoeExperimentProps> = (props) => {
    const routeParams = useParams<ExcelRunnerRouteParams>();

    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [selectedTab, setSelectedTab] = React.useState<string>("input-parameters");
    const [experiment, setExperiment] = React.useState<INewExperiment>({ name: "", filterId: null, filterVersionId: null, inputParameters: [], outputParameters: [] });
    const [selectedFilter, setSelectedFilter] = React.useState<FilterResultModel>(undefined);
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [isFormSubmitted, setSubmitionState] = React.useState<boolean>(false);
    const [isFormDisabled, setFormDisabled] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const cloneId = searchParams.get('cloneId');
    const [showThumbnailModal, setShowThumbnailModal] = React.useState<boolean>(false);    
   

    React.useEffect(() => {

        onInitialize();

    }, []);

    React.useEffect(() => {
        if (!!selectedFilter) {
            getFile();
        }

    }, [selectedFilter?.id]);


    const onInitialize = async () => {



        const queryFilterId = searchParams.get('filterId');

        if (queryFilterId) {
            onSelectFilter(+queryFilterId);
        }
        //Clone or Edit scenario logic          
        if (cloneId || !!routeParams.experimentId) {
            const experimentId = cloneId ?? routeParams.experimentId;
            const experimentResp = await getExperiment(+experimentId);
            // in case that someone tries injecting id into url
            if (!!routeParams.experimentId && experimentResp.processingStatus !== ProcessingStatus.NotRunning) {
                toast.error("Experiments that are started can't be edited.");
                return;
            }

            setExperiment(s => ({
                ...s, ...experimentResp,
                cloneFilterVersionId: experimentResp.filterVersionId,
                cloneFilterId: experimentResp.filterId
            }) as INewExperiment);

            await getExperimentInputData(+experimentId);

            onSelectFilter(experimentResp.filterId);
        }
    }

    const getAccessToken = async () => {

        try {
            const token = await getUserToken();
            return token;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting user token.");
        }
    }

    const getExperimentInputData = async (experimentId: number) => {
        const messageId = loadingService.showMessage("Getting experiment data...");
        try {
            const client = getScenariosClient();
            const accessToken = await getAccessToken();
            const query = new ScenarioDataQuery({
                accessToken: accessToken,
                driveItemId: routeParams.uniquefileId,
                flowsheetsListId: "s365v2",
                scenarioId: experimentId,
                siteId: "s365v2",
                applyPagination: false,
                skip: 0,
                take: 25

            });
            const experimentData = await client.getScenarioData(query);
            const inputParams = experimentData.rows.map((row, index) => {
                let rowParams = { index: index } as ParametersModel;

                row.cells?.forEach(x => {
                    rowParams[x.filterParameterId] = x.value?.toString();

                });
                return rowParams;
            });
            setExperiment(s => ({ ...s, inputParameters: inputParams }));

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting experiment data.");
        } finally {
            loadingService.hideMessage(messageId);
        }

    }

    const getFile = async () => {
        loadingService.showLoading("Getting file...", async (hideMessage) => {
            try {
                const client = getFilesClient();

                if (!selectedFilter)
                    throw "Filter should be loaded before file.";

                const fileWithBreadcrumbs = await client.getFile(selectedFilter.fileUniqueIdentifier, selectedFilter.fileVersionNumber, true);


                if (fileWithBreadcrumbs) {
                    setSelectedFile(fileWithBreadcrumbs.file);
                    setBreadcrumbs([...emptyBreadcrumbs, ...(fileWithBreadcrumbs.breadcrumbItems ?? [])]);
                }
                return fileWithBreadcrumbs;

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting file information.");
            } finally {
                hideMessage();
            }
        });
    }

    const onSaveClick = async () => {
        setSubmitionState(true);

        console.log("On save", experiment, selectedFilter);

        let validationResult = validateDoeExperiment(experiment, selectedFilter);
        console.log("validationResult", validationResult);
        if (validationResult.isInvalid()) {

            let globalErrors = validationResult.getGlobalErrors();
            if (globalErrors && globalErrors.length > 0) {
                toast.error(globalErrors.join("\n"));
                //console.log(globalErrors.join("\n"));
            }
        }
        else {
            // Submit
            loadingService.showLoading("Saving experiment...", async (hideMessage) => {
                try {
                    const client = getDoeExperimentsClient();

                    let postModel = new DoeExperimentPostModel();
                    postModel.filterId = experiment.filterId;
                    postModel.filterVersionId = experiment.filterVersionId;
                    postModel.flowsheetUniqueId = selectedFile?.uniqueIdentifier;
                    postModel.name = experiment.name;
                    postModel.inputDataRows = experiment.inputParameters.map(item => {
                        let parameters = [];
                        for (const [key, value] of Object.entries(item)) {
                            if (!isNaN(+key))
                                parameters.push(new ExperimentParameterPostModel({ filterParameterId: +key, value: +value }));
                        }

                        return new ExperimentRowModel({ parameters: parameters });
                    });
                    console.log("postModel", postModel);
                    let savedExperiment: ScenarioResultModel = undefined;
                    if (!routeParams.experimentId) {
                        savedExperiment = await client.createExperiment(postModel);
                        toast.success("Experiment saved successfully.");
                    } else {
                        savedExperiment = await client.updateExperiment(+routeParams.experimentId!, postModel);
                        toast.success("Experiment updated successfully.");
                    }



                    navigate(`/files/${selectedFilter?.fileUniqueIdentifier}/doe/details/${savedExperiment.id}`);
                }
                catch (error) {
                    const actionName = !!routeParams.experimentId ? "updating" : "saving";
                    processServerError(error, undefined, `An error occured while ${actionName} experiment.`);
                } finally {
                    hideMessage();
                }
            });

        }

    }


    const getExperiment = async (experimentId: number) => {
        const messageId = loadingService.showMessage("Getting experiment...");
        try {

            const client = getScenariosClient();
            const accessToken: string = await getUserToken();

            const query = {
                scenarioId: experimentId,
                accessToken: accessToken,
                driveItemId: routeParams?.uniquefileId,
                flowsheetsListId: "s365v2",
                siteId: "s365v2"
            } as unknown as ScenarioQuery;

            const experiment = await client.getScenario(query);

            return experiment;
        }
        catch (error) {
            processServerError(error, undefined, "An error occurred while getting experiment.");
        } finally {
            loadingService.hideMessage(messageId);
        }
    }

    const getFilterByVersion = async (filterId: number, filterVersionId: number) => {
        try {

            const client = getFiltersClient();
            const filter = await client.getFilterWithoutFile(filterId, filterVersionId);
            return filter;

        } catch (error) {
            processServerError(error, undefined, "An errror occurred while trying to get filter by version.");
        }
    }


    const onSelectFilter = (filterId: number) => {
        loadingService.showLoading(
            "Loading Filter...",
            (hideMessage) => {
                loadFilterAsync(filterId).finally(() => {
                    hideMessage();

                });
            }
        );
    }

    const loadFilterAsync = async (filterId: number) => {
        try {
            const client = getFiltersClient();
            const filter = await client.getFilterWithoutFileLatest(filterId);


            const sortedInputParams = _copyAndSort<InputFilterParameterResultModel>(filter.inputFilterParameters, "order", false);
            const sortedOutputParams = _copyAndSort<OutputFilterParameterResultModel>(filter.outputFilterParameters, "order", false);

            setSelectedFilter({ ...filter, inputFilterParameters: sortedInputParams, outputFilterParameters: sortedOutputParams } as FilterResultModel);

            setExperiment(s => ({
                ...s,
                filterId: filter.id,
                filterVersionId: filter.currentVersionId
            }));



        } catch (error) {
            console.log("An error occurred while trying to get filter for experiment.", error);
        }
    };





 

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }

    let validationResult = isFormSubmitted ? validateDoeExperiment(experiment, selectedFilter) : new ValidationResult(undefined);

    return <div className="content-wrapper edit-experiment">

        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton appearance='subtle' disabled={isLoading}
                    onClick={() => { loadingService.showLoading("Saving...", (hideMessage) => { onSaveClick().finally(() => hideMessage()); }); }}
                    icon={<Save20Regular />}>Save</ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />
            </Toolbar>
        </div>

        <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/doe`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-doe`} active={true}>Design of Experiments</Breadcrumb>

            </Breadcrumbs>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
            <div className="input-form" style={{ flex: 1 }}>
                <div className="input-form-item">
                    <Label className="input-form-label">
                        Experiment name*:
                    </Label>
                    <Field
                        className="input-form-field"
                        validationMessage={validationResult.getFieldValidationMessage(nameof<INewExperiment>('name'), isFormSubmitted)}
                        validationState={validationResult.getFieldValidationMessage(nameof<INewExperiment>('name'), isFormSubmitted) ? "error" : "none"}>
                        <Input
                            autoComplete="off"
                            maxLength={50}
                            disabled={isLoading}
                            value={experiment?.name}
                            onChange={(ev, data) => setExperiment(s => ({ ...s, name: data.value }))} />
                    </Field>
                </div>

                <div className="input-form-item">
                    <Label className="input-form-label">
                        Flowsheet:
                    </Label>
                    {selectedFilter && <FileDisplayName
                        fileUniqueIdentifier={selectedFilter.fileUniqueIdentifier}
                        fileVersionNumber={selectedFilter.fileVersionNumber}
                    />}
                </div>

                <div className="input-form-item">
                    <Label className="input-form-label">
                        Filter:
                    </Label>
                    <span>
                        {selectedFilter ? selectedFilter.name : ""}
                    </span>
                </div>


            </div>


            {selectedFile && props.hubConnection &&
                <div className="thumbnail--wrapper" style={{ flex: 1 }}>
                    <Label className="group-label">Thumbnail:</Label>
                    <div style={{ flex: 1 }}>
                        <ThumbnailImage
                            fileUniqueIdentifier={selectedFile.uniqueIdentifier!}
                            fileId={selectedFile.id!}
                            fileVersionNumber={selectedFile.currentVersionNumber!}
                            fileExtension={getFileExtension(selectedFile.name)}
                            hubConnection={props.hubConnection}
                            onClick={() => { setShowThumbnailModal(true) }}
                            asBackground={true}
                            fullImage={true} />
                    </div>
                    {selectedFile && showThumbnailModal &&
                        <ThumbnailModal
                            file={selectedFile}
                            hubConnection={props.hubConnection!}
                            isOpened={showThumbnailModal}
                            onClose={() => setShowThumbnailModal(false)}
                        />}
                </div>}
        </div>



        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
            <Tab key="input-tab" value="input-parameters" >Input parameters</Tab>
            <Tab key="output-tab" value="output-parameters">Output parameters</Tab>
        </TabList>
        <div className="tab-content">
            {selectedTab == "input-parameters" && selectedFilter &&
                <div>
                    <EditDoeExperimentInputParametersTab
                        experiment={experiment}
                        filter={selectedFilter}
                        isFormSubmitted={isFormSubmitted}
                        isLoading={isLoading}
                        validationResult={validationResult}
                        onInputParametersChange={(inputParams) => {
                            console.log("inputParams", inputParams);
                            setExperiment({ ...experiment, inputParameters: inputParams })
                        }}
                    />
                </div>
            }
            {selectedTab == "output-parameters" && selectedFilter &&
                <>
                    <EditDoeExperimentOutputParametersTab filter={selectedFilter} isLoading={isLoading} />
                </>
            }


        </div>

    </div>;
}

export const validateDoeExperiment = (experiment: INewExperiment, filter: FilterResultModel): ValidationResult => {
    console.log("Validate experiment called,", experiment);
    let result = {
        fieldsErrors: {},
        globalErrors: []
    } as IValidationResultData;

    if (!experiment.name)
        result.fieldsErrors[nameof<INewScenario>('name')] = "Experiment name is required.";

    if (!experiment.filterId)
        result.fieldsErrors[nameof<INewScenario>('filterId')] = "Filter is required.";





    const expectedInputParameters = filter.inputFilterParameters.filter(p => p.parameterType == FilterParameterType.ExpectedInput);
    for (let i = 0; i < expectedInputParameters.length; i++) {
        const inputFilterParam = expectedInputParameters[i];
        for (let j = 0; j < experiment.inputParameters.length; j++) {
            const inputRow = experiment.inputParameters[j];
            if (!inputRow[inputFilterParam.id]) {
                result.fieldsErrors[`ip_${j}_${inputFilterParam.id}`] = "Parameter is required.";
            } else {
                const regex = /^\d+(\.\d+)?$/;
                if (!regex.test(inputRow[inputFilterParam.id])) {
                    result.fieldsErrors[`ip_${j}_${inputFilterParam.id}`] = "Incorrect number format.";
                }

            }
        }
    }

    return new ValidationResult(result);
}