import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getDispatcherFlowsheetsClient, getFilesClient, getFiltersClient } from "../../services/dashboard.service";
import { FlowsheetObjectsResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { BreadcrumbItem, FileModel, FilterParameterType, FilterQuery, FilterResultModel, InputFilterParameterResultModel, OutputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { _copyAndSort } from "../../utils/helpers/array.helpers";
import { processServerError } from "../../utils/helpers/error.helper";
import { IChoiceGroupOption, IDropdownOption } from "../../utils/shared.types";
import { SensitivityStudiesRouteParams } from "../sensitivity-studies-list.component";
import { SensitivityStudy, SensitivityStudyInputParameter, SensitivityStudyOutputParameter } from "./edit-sensitivity-study.interfaces";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from '../../components/breadcrumbs/breadcrumbs';
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { getSensitivityStudiesClient } from "../../services/sensitivity-studies.service";
import { SensitivityStudyDataQuery, SensitivityStudyInputParameterPostModel, SensitivityStudyPostModel, SensitivityStudyQuery, StudyInputParamterType, StudyOutputParamterType, StudyParamterRange, StudyType } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { getStepsBeforeAfterVariationsCount } from "../utilities/total-variations-calculator";
import { calculateVariationsAndJobs, GetStudyParamValueFromFilterParam, GetStudyParamValueFromFlowsheet, MapFilterParameterTypeToCustomStudyInputParameterType, roundIfGreatherThanOne } from "../utilities/study.utilities";
import { Button, Field, Input, InputOnChangeData, Label, Radio, RadioGroup, Select, Tab, TabList, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Dropdown, Option } from "@fluentui/react-components";
import { Save20Regular } from "@fluentui/react-icons";
import { SensitivityStudyIsValid, ValidateIsNotNullOrWhitespace, ValidateSensitivityStudy } from "./edit-sensitivity-study.validation";
import "./edit-sensitivity-study.styless.scss";
import { getDropdownText } from "../../utils/helpers/dropdown.helpers";
import { ApplicationPicker } from "../../components/application-picker/application-picker.component";
import { StudyInputParametersTab } from "./input-parameters-tab/input-parameters-tab.component";
import { StudyOutputParametersTab } from "./output-parameters-tab/output-parameters-tab.component";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { toast } from "react-toastify";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { GLOBAL_STYLES } from "../../styles";
import { ThumbnailModal } from "../../files/thumbnail/thumbnail-modal/thumbnail-modal.component";
import { HubConnection } from "@microsoft/signalr";
import { ThumbnailImage } from "../../files/thumbnail/thumbnail-image/thumbnail-image.component";
import { getFileExtension } from "../../files/file-type-icon/file-type-icon.helpers";

type EditSensitivityStudyProps = {
    isOptimumTester?: boolean,
    isDwsimApp?: boolean,
    baseOptReactRoute?: string,
    hubConnection: HubConnection
};

type EditSensitivityStudyState = {
    submitionState: boolean;
    isFormSubmitted: boolean;
    isFormSaved: boolean;
    errorMessage?: string;
    cloneFilterVersionChanged: boolean;
    showSetFlowsheetValuesDialog: boolean;
    showSetOptimalValuesDialog: boolean;
}
const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];

export const EditSensitivityStudy: React.FC<EditSensitivityStudyProps> = (props) => {
    const routeParams = useParams<SensitivityStudiesRouteParams>();
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [filtersDropdownOptions, setFiltersDropdownOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedTab, setSelectedTab] = React.useState<string>("input-parameters");
    const [flowsheetObjects, setFlowsheetObjects] = React.useState<FlowsheetObjectsResponseModel>();
    const [selectedStudy, setSelectedStudy] = React.useState<SensitivityStudy>(
        {
            flowsheetDriveId: routeParams.uniquefileId,
            sensitivityStudyInputParameters: [],
            sensitivityStudyOutputParameters: [],
            totalJobs: undefined,
            name: "",
            studyParamterRange: props.isOptimumTester ? 1 : 0,
            searchFor: 0,
            inputConstraints: "// Your code goes here \n"
                + "// Define as JavaScript \n\n"
                + "// Always leave this line last \n"
                + "return true; \n",
            outputConstraints: "// Your code goes here \n"
                + "// Define as JavaScript \n\n"
                + "// Always leave this line last \n"
                + "return true; \n"
        } as SensitivityStudy);
    const [searchParams] = useSearchParams();
    const cloneId = searchParams.get('cloneId');
    const initialrowId = searchParams.get('initialrowId');
    const queryFilterId = searchParams.get('filterId');
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [isLoading, loadingService] = useLoading();
    const [showThumbnailModal, setShowThumbnailModal] = React.useState<boolean>(false);
    const isEditView = routeParams.studyId ? true : false;
    const navigate = useNavigate();

    const studyParamterRangeOptions: IChoiceGroupOption[] = [
        { key: '0', text: 'Min and max values' },
        { key: '1', text: 'Initial value with steps before and after' },

    ];


    const [state, setState] = React.useState<EditSensitivityStudyState>({
        submitionState: false,
        isFormSaved: false,
        isFormSubmitted: false,
        errorMessage: undefined,
        cloneFilterVersionChanged: false,
        showSetFlowsheetValuesDialog: false,
        showSetOptimalValuesDialog: false
    });



    React.useEffect(() => {
        onInit();
    }, []);

    React.useEffect(() => {

        if (!flowsheetObjects || !filtersDropdownOptions)
            return;

        if (routeParams.studyId) {
            loadingService.showLoading(
                "Loading study...",
                async (hideMessage) => {
                    getStudy(routeParams.studyId!)
                        .finally(() => hideMessage());
                }
            );
        } else {


            if (queryFilterId) {
                onFilterChanged(+queryFilterId);
            }
            if (cloneId) {

                cloneStudy(cloneId, initialrowId ?? undefined);
            }

        }
    }, [flowsheetObjects, filtersDropdownOptions]);

    const onInit = async () => {
        const fileResp = await getFile();
        await getFilters(fileResp?.file?.id);
        await getFlowsheetObjectsWithLoading(fileResp?.file);

    }


    const getFilters = async (fileId?: number) => {
        try {
            const client = getFiltersClient();
            const query = { fileId: fileId ?? selectedFile!.id } as FilterQuery;
            const filtersResp = await client.getFilters(query);

            const filtersSorted = _copyAndSort<FilterResultModel>(filtersResp, "id", true);
            const dropDownOptions = filtersSorted ?
                filtersSorted.filter(x => !x.isDeleted).map(filter => ({ key: filter.id, text: filter.name } as IDropdownOption))
                : [];
            setFiltersDropdownOptions(dropDownOptions);
            console.log(" filter dropDownOptions", dropDownOptions);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting filters.");
        }
    }

    const getFile = async () => {
        try {
            const client = getFilesClient();
            const resp = await client.getFileLatest(routeParams.uniquefileId!, true);
            if (resp) {
                setSelectedFile(resp.file);
                setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
            }
            return resp;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file information.");
        }
    }

    const getFlowsheetObjectsWithLoading = (file?: FileModel) => {
        loadingService.showLoading(
            "Getting flowsheet information...",
            async (hideMessage) => {
                getFlowsheetObjects(file)
                    .finally(() => hideMessage());
            }
        );
    }

    const getFlowsheetObjects = async (file?: FileModel) => {
        try {
            const currentFile = file ? file : selectedFile;
            const client = getDispatcherFlowsheetsClient();

            var objects = await client.getFlowsheetObjects("s365v2", currentFile!.uniqueIdentifier!.toString(), currentFile!.currentVersionNumber!.toString());
            // console.log("Flowsheets objects.", objects);
            setFlowsheetObjects(objects);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting flowsheet objects.");
        }
    }

    const hasInitialRowIdAndCloneId = (): boolean => {

        return !!cloneId && !!initialrowId;
    }
    const setOptimumRowValues = async () => {

        if (!hasInitialRowIdAndCloneId())
            return;
        loadingService.showLoading(
            "Setting optimal row values...",
            async (hideMessage) => {
                try {
                    let study = { ...selectedStudy } as SensitivityStudy;
                    await getClonedStudyRowData(study, +cloneId!, +initialrowId!);
                    setSelectedStudy(study);
                } catch (error) {
                    processServerError(error, undefined, "An error occurred while trying to set optimum row values for input parameters.");

                }
                hideMessage();
            }
        );
    }

    const getClonedStudyRowData = async (study: SensitivityStudy, studyId: number, rowId: number) => {
        try {

            const client = getSensitivityStudiesClient();
            const query = new SensitivityStudyDataQuery({
                rowId: rowId,
                sensitivityStudyId: studyId,
                sensitivityStudyVersionId: study.currentVersionId!,
                skip: 0,
                take: 1
            });
            const dataResult = await client.getSensitivityStudyData(query);


            study.sensitivityStudyInputParameters?.forEach((inputParam) => {

                const inputRow = dataResult?.rows?.[0];
                const cell = inputRow?.inputParameters?.find((value) => value.sensitivityStudyInputParameter?.inputFilterParameterId == inputParam.inputFilterParameterId);
                //Skip fixed values
                if (cell?.sensitivityStudyInputParameter?.stepSize) {
                    inputParam.value = cell.value!;
                    inputParam.stepSize = 10;
                    inputParam.stepsBefore = 10;
                    inputParam.stepsAfter = 10;
                    inputParam.totalVariations = getStepsBeforeAfterVariationsCount(10, 10);
                }
            });

            var totalJobs = 0;
            study.sensitivityStudyInputParameters?.forEach(inputParam => {
                //  console.log("Inpt parm var", inputParam.totalVariations);
                if (inputParam.totalVariations) {
                    if (totalJobs == 0) {
                        totalJobs = parseInt(inputParam.totalVariations.toString());
                    } else {
                        totalJobs = totalJobs + parseInt(inputParam.totalVariations.toString());
                    }
                }
            });
            console.log("totaljobs", totalJobs);
            study.totalJobs = totalJobs;


        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to get test row data.");
        }

    }

    //Fills initial value of input parameters
    const setDefaultFlowsheetParameterValues = async () => {
        try {

            let inputParameters = [...(selectedStudy!.sensitivityStudyInputParameters ?? [])];
            if (selectedStudy!.studyParamterRange == StudyParamterRange.InitialValueWithSteps) {
                inputParameters.forEach(inputParam => {
                    const param = inputParam.flowsheetParameterValue;
                    if (inputParam.valueType == StudyInputParamterType.FixedValue) {
                        if (param && param.value !== undefined) {
                            inputParam.value = roundIfGreatherThanOne(param.value);
                        }
                    } else {
                        if (param && param.value !== undefined) {
                            inputParam.value = roundIfGreatherThanOne(param.value);
                            inputParam.stepsBefore = 1;
                            inputParam.stepsAfter = 1;
                            inputParam.stepSize = roundIfGreatherThanOne(param.value * 0.1);
                        }
                    }
                });
            } else {
                inputParameters.forEach(inputParam => {
                    const param = inputParam.flowsheetParameterValue;
                    if (inputParam.valueType == StudyInputParamterType.FixedValue) {
                        if (param && param.value !== undefined) {
                            inputParam.value = roundIfGreatherThanOne(param.value);
                        }
                    } else {
                        if (param && param.value !== undefined) {
                            inputParam.stepSize = roundIfGreatherThanOne(param.value * 0.1);
                            inputParam.minValue = roundIfGreatherThanOne(param.value) - inputParam.stepSize;
                            inputParam.maxValue = roundIfGreatherThanOne(param.value) + inputParam.stepSize;
                        }
                    }

                });
            }
            const updatedStudy = calculateVariationsAndJobs(selectedStudy!, inputParameters);
            setSelectedStudy(updatedStudy);


        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to set default values for input parameters.");
        }
    }

    const cloneDataAsync = async (cloneId: string, initialRowId?: string) => {
        console.log("CloneID:", cloneId);
        let study = await getCloneData(cloneId.toString());

        if (initialRowId && study) {
            console.log("InitialRowId:", initialRowId);
            await getClonedStudyRowData(study, +cloneId, +initialRowId);
            study.name = `Optimization test for row ${initialRowId}`;
        }

        setSelectedStudy(study!);
    }

    const cloneStudy = async (cloneId: string, initialRowId?: string) => {
        loadingService.showLoading(
            "Cloning data...",
            (hideMessage) => {
                cloneDataAsync(cloneId, initialRowId)
                    .finally(() => hideMessage());
            }
        );
    }


    const getCloneData = async (studyId: string) => {

        try {
            var study = await getStudyAsync(studyId);
            console.log("Clone study before", study);
            study.id = 0;
            study.optimalRowId = undefined;
            study.currentStep = undefined;
            study.currentStepStatus = undefined;
            study.calculatedRows = undefined;
            study.createdAt = undefined;
            study.failedRows = undefined;
            study.studyParamterRange = props.isOptimumTester ? 1 : study.studyParamterRange;
            study.sensitivityStudyInputParameters = study.sensitivityStudyInputParameters?.map(x => ({ ...x, id: 0, sensitivityStudyId: 0 } as SensitivityStudyInputParameter));
            study.sensitivityStudyOutputParameters = study.sensitivityStudyOutputParameters?.map(x => ({ ...x, id: 0 } as SensitivityStudyOutputParameter));



            var totalJobs = 0;
            study.sensitivityStudyInputParameters?.forEach(inputParam => {
                //  console.log("Inpt parm var", inputParam.totalVariations);
                if (inputParam.totalVariations) {
                    if (totalJobs == 0) {
                        totalJobs = parseInt(inputParam.totalVariations.toString());
                    } else {
                        totalJobs = totalJobs + parseInt(inputParam.totalVariations.toString());
                    }
                }
            });
            console.log("totaljobs", totalJobs);
            study.totalJobs = totalJobs !== 0 ? totalJobs : undefined;
            console.log("Clone study after", study);
            return study;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to get clone data.");
        }
    }

    const getStudyAsync = async (studyId: string) => {

        //  console.log("getSensitivityStudyAsync action",action);
        const client = getSensitivityStudiesClient();

        const query = {
            flowsheetDriveId: routeParams.uniquefileId,
            flowsheetsListId: "s365v2",
            siteId: "s365v2"
        } as SensitivityStudyQuery;
        let study = await client.getSensitivityStudy(Number(studyId), query) as SensitivityStudy;
        console.log("Study before getting params", study);
        study = await getFilterParamtersData(selectedFile.uniqueIdentifier!, study) as SensitivityStudy;

        console.log("Loaded study:", study);
        return study as SensitivityStudy;

    }

    const getStudy = async (studyId: string) => {
        if (studyId) {
            try {
                const study = await getStudyAsync(studyId);
                setSelectedStudy(study);
            }
            catch (error) {
                processServerError(error, undefined, "An error ocurred while trying to get senstivity study.");
            }
        }
    }

    const getFilterParamtersData = async (fileUniqueId: string, study: SensitivityStudy) => {

        try {
            //  console.log("getFilterParamtersData", flowsheetObjects);
            const client = getFiltersClient();

            let filter: FilterResultModel = await client.getFilterLatest(fileUniqueId, +study.filterId!);
            // if (!AreParametersOrdered(filter)) {
            //     OrderFilterParams(filter);
            // }
            console.log("Study:", study, " Filter", filter);

            filter.inputFilterParameters = _copyAndSort<InputFilterParameterResultModel>(filter.inputFilterParameters ?? [], "order", false);
            filter.outputFilterParameters = _copyAndSort<OutputFilterParameterResultModel>(filter.outputFilterParameters ?? [], "order", false);

            //clone when filter version has changed

            let oldFilter: FilterResultModel | undefined = undefined;
            if (filter.id == study.filterId && filter.currentVersionId !== study.filterVersionId) {


                oldFilter = await client.getFilter(fileUniqueId, +study.filterId!, study.filterVersionId);
                setState({ ...state, cloneFilterVersionChanged: true });
            }

            if (study.sensitivityStudyInputParameters && study.sensitivityStudyInputParameters.length > 0) {
                study.sensitivityStudyInputParameters = filter.inputFilterParameters.map(filterParam => {
                    let param = { ...filterParam } as InputFilterParameterResultModel;
                    if (oldFilter) {
                        let oldinputParam = oldFilter.inputFilterParameters?.find(x => x.alias == filterParam.alias);
                        if (oldinputParam) {
                            param = oldinputParam
                        }
                    }
                    const inputParam = study.sensitivityStudyInputParameters?.find(x => x.inputFilterParameterId == param.id);
                    return {
                        ...inputParam,
                        inputFilterParameterId: filterParam.id,
                        inputFilterParameter: {
                            name: param.name,
                            alias: param.alias,
                        },
                        parameterType: MapFilterParameterTypeToCustomStudyInputParameterType(param.parameterType!),
                        filterParameterValue: GetStudyParamValueFromFilterParam(param),
                        flowsheetParameterValue: GetStudyParamValueFromFlowsheet(param, flowsheetObjects)


                    } as unknown as SensitivityStudyInputParameter

                });
            }





            const outputStudyParams = filter && filter.outputFilterParameters && filter.outputFilterParameters.length > 0 ?
                filter.outputFilterParameters.map((param) => {

                    return {
                        id: 0,
                        outputFilterParameterId: param.id,
                        outputFilterParameter: { ...param } as any,
                        parameterType: param.parameterType == FilterParameterType.FixedValue ||
                            param.parameterType == FilterParameterType.Formula ? StudyOutputParamterType.FilterDefined : StudyOutputParamterType.ExpectedOutput,
                        isFilterFormula: param.parameterType == FilterParameterType.Formula ? true : false,
                        filterFormulaValue: param.formula

                    } as unknown as SensitivityStudyOutputParameter
                }) : [];




            return {
                ...study,
                filter: filter,
                filterVersionId: filter.currentVersionId,
                sensitivityStudyOutputParameters: outputStudyParams
            } as SensitivityStudy;






        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to get filter for sensitivity study.");
        }


    }

    const onFilterChanged = async (filterId?: number) => {
        if (!filterId) {
            setSelectedStudy({
                ...selectedStudy,
                filterId: undefined,
                filter: undefined,
                filterVersionId: undefined,
                sensitivityStudyInputParameters: [],
                sensitivityStudyOutputParameters: []
            } as SensitivityStudy);
            return;
        }


        try {

            //   console.log("onFilterChanged", flowsheetObjects);

            const client = getFiltersClient();

            const filter = await client.getFilterLatest(selectedFile.uniqueIdentifier!, filterId);

            filter.inputFilterParameters = _copyAndSort<InputFilterParameterResultModel>(filter.inputFilterParameters ?? [], "order", false);
            filter.outputFilterParameters = _copyAndSort<OutputFilterParameterResultModel>(filter.outputFilterParameters ?? [], "order", false);


            const inputStudyParams = filter && filter.inputFilterParameters && filter.inputFilterParameters.length > 0 ? filter.inputFilterParameters.map((param) => {
                return {
                    id: 0,
                    inputFilterParameterId: param.id,
                    inputFilterParameter: { ...param } as any,
                    valueType: param.parameterType == FilterParameterType.FixedValue ||
                        param.parameterType == FilterParameterType.Formula ? StudyInputParamterType.FilterDefined : StudyInputParamterType.Range,
                    parameterType: MapFilterParameterTypeToCustomStudyInputParameterType(param.parameterType!),
                    filterParameterValue: GetStudyParamValueFromFilterParam(param),
                    flowsheetParameterValue: GetStudyParamValueFromFlowsheet(param, flowsheetObjects!)
                } as unknown as SensitivityStudyInputParameter
            }) : [];

            const outputStudyParams = filter && filter.outputFilterParameters && filter.outputFilterParameters.length > 0 ? filter.outputFilterParameters.map((param) => {
                return {
                    id: 0,
                    outputFilterParameterId: param.id,
                    outputFilterParameter: { ...param } as any,
                    parameterType: param.parameterType == FilterParameterType.FixedValue ||
                        param.parameterType == FilterParameterType.Formula ? StudyOutputParamterType.FilterDefined : StudyOutputParamterType.ExpectedOutput,
                    isFilterFormula: param.parameterType == FilterParameterType.Formula ? true : false,
                    filterFormulaValue: param.formula
                } as unknown as SensitivityStudyOutputParameter
            }) : [];

            console.log("sensitivityStudyInputParameters", inputStudyParams, "outputStudyParams", outputStudyParams);
            setSelectedStudy({
                ...selectedStudy,
                filterId: filterId,
                filter: filter,
                filterVersionId: filter.currentVersionId,
                sensitivityStudyInputParameters: inputStudyParams,
                sensitivityStudyOutputParameters: outputStudyParams
            } as SensitivityStudy);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to get filter for sensitivity study.");
        }
    }

    const onPropertyChanged = (propertyName: string, newValue: string) => {
        setSelectedStudy({ ...selectedStudy, [propertyName]: newValue } as SensitivityStudy);
    }


    const onNameChange = (ev: any, data: InputOnChangeData) => {
        setSelectedStudy({ ...selectedStudy, name: data.value } as SensitivityStudy);
    }

    const onStudyParamterRangeChange = (rangeType: StudyParamterRange) => {
        let inputParams = selectedStudy?.sensitivityStudyInputParameters;
        inputParams = inputParams?.map((param) => ({
            ...param,
            value: undefined,
            minValue: undefined,
            maxValue: undefined,
            stepSize: undefined,
            stepsAfter: undefined,
            stepsBefore: undefined,
        } as unknown as SensitivityStudyInputParameterPostModel))

        setSelectedStudy({
            ...selectedStudy,
            totalJobs: undefined,
            studyParamterRange: rangeType,
            sensitivityStudyInputParameters: inputParams
        } as SensitivityStudy);
    }

    const onSaveClick = async () => {
        setState({ ...state, isFormSubmitted: true });

        try {
            console.log("onSaveClick selectedStudy", selectedStudy);
            const validationResult = ValidateSensitivityStudy(selectedStudy);
            console.log("Study validation result:", validationResult);
            if (!SensitivityStudyIsValid(validationResult)) {
                toast.error("There are 1 or more validation errors!");
                return;
            }


            const client = getSensitivityStudiesClient();


            console.log("Saving study", selectedStudy);
            let model = { ...selectedStudy, filter: undefined } as unknown as SensitivityStudyPostModel;

            model.flowsheetsListId = "s365v2";
            model.siteId = "s365v2";
            model.driveId = "s365v2";
            model.flowsheetDriveId = selectedFile.uniqueIdentifier;
            model.fileVersionId = selectedFile.currentVersionNumber;
            console.log("Saving Sensitivity study:", model);
            model.studyType = props.isOptimumTester ? StudyType.OptimumTest : StudyType.SensitivityStudy;

            let savedStudy = await client.createOrUpdateStudy(model) as SensitivityStudy;

            if (savedStudy.sensitivityStudyInputParameters && savedStudy.sensitivityStudyInputParameters.length > 0) {
                savedStudy = await getFilterParamtersData(selectedFile.uniqueIdentifier!, savedStudy);
            }

            setSelectedStudy(savedStudy);
            if (routeParams.studyId) {
                toast.success(`${props.isOptimumTester ? "Optimum test" : "Sensitivity study"} updated!`);
            }
            else {
                toast.success(`${props.isOptimumTester ? "Optimum test" : "Sensitivity study"} saved!`);
            }
            if (props.isDwsimApp) {
                if (props.isOptimumTester) {

                    navigate(`/files/${routeParams.uniquefileId}/optimum-tester/details/${savedStudy.id}`);
                } else {
                    navigate(`/files/${routeParams.uniquefileId}/sensitivity-studies/details/${savedStudy.id}`);

                }

            } else {
                if (props.isOptimumTester) {
                    navigate(`/files/${routeParams.uniquefileId}/optimum-tester/details/${savedStudy.id}`);
                } else {
                    navigate(`/files/${routeParams.uniquefileId}/sensitivity-studies/details/${savedStudy.id}`);
                }

            }


        }
        catch (error) {
            processServerError(error, undefined, "An error ocurred while trying to save sensitivity study.");
        }
    }

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }

    console.log("selectedStudy", selectedStudy);
    const nameLabel = props.isOptimumTester ? "Test name" : "Study name";
    const nameValidation = ValidateIsNotNullOrWhitespace(selectedStudy ? selectedStudy.name! : "", nameLabel);
    const filterValidation = ValidateIsNotNullOrWhitespace(selectedStudy ? (selectedStudy.filterId ? selectedStudy.filterId.toString() : "") : "", "Filter");

    return <div className="content-wrapper edit-study">

        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton appearance='subtle' disabled={isLoading}
                    onClick={() => { loadingService.showLoading("Saving...", (hideMessage) => { onSaveClick().finally(() => hideMessage()); }); }}
                    icon={<Save20Regular />}>Save</ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />
            </Toolbar>
        </div>

        <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/${props.isOptimumTester ? "optimum-tester" : "sensitivity-studies"}`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-sensitivity-studies`} active={true}>{props.isOptimumTester ? "Optimum Test" : "Multivariate Sensitivity Study"}</Breadcrumb>

            </Breadcrumbs>
        </div>
        <div style={{ display: "flex", gap: 10 }}>
            <div className="input-form" style={{ flex: 1, paddingTop: "3px" }}>
                <div className="input-form-item">
                    <Label className="input-form-label">
                        {nameLabel}*:
                    </Label>
                    <Field
                        className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                        validationMessage={state.isFormSubmitted && !nameValidation.isValid ? nameValidation.validationErrors[0] : ""}
                        validationState={state.isFormSubmitted && !nameValidation.isValid ? "error" : "none"}>
                        <Input
                            value={selectedStudy?.name}
                            onChange={(ev, data) => onNameChange(ev, data)}
                        />
                    </Field>
                </div>

                <div className="input-form-item">
                    <Label className="input-form-label">
                        Filter*:
                    </Label>
                    <Field
                        className="input-form-field"
                        validationMessage={state.isFormSubmitted && !selectedStudy.filterId ? "Filter is required." : ""}
                        validationState={state.isFormSubmitted && !selectedStudy.filterId ? "error" : "none"}>
                        <Select
                            value={selectedStudy.filterId?.toString() ?? undefined}
                            placeholder="Select an option"
                            onChange={(ev, data) => { onFilterChanged(+data.value!); }} >
                            <option value={undefined}>Select filter</option>
                            {filtersDropdownOptions.map((option) => {
                                return <option value={option.key?.toString()}>
                                    {option.text}
                                </option>
                            })}
                        </Select>

                    </Field>
                </div>

                {/* {!props.isOptimumTester && <div className="input-form-item">
                <Label className="input-form-label">
                    Define ranges as:
                </Label>
                <Field className="input-form-field">
                    <RadioGroup
                        name="saveCalculatedFlowsheets"

                        value={selectedStudy ? selectedStudy.studyParamterRange?.toString() : ""}
                        onChange={(ev, data) => { onStudyParamterRangeChange(+data.value); }}
                    >
                        {studyParamterRangeOptions.map((option) => {
                            return <Radio
                                label={option.text}
                                value={option.key?.toString()}
                            />
                        })}

                    </RadioGroup>
                </Field>


            </div>
            } */}

                <div className="input-form-item" >
                    <Label className="input-form-label">Total jobs:</Label>
                    <div style={{ padding: "5px" }}>
                        <span>{selectedStudy && selectedStudy.totalJobs ? selectedStudy.totalJobs.toString() : "-"}</span>
                        <span style={{
                            display: "block",
                            marginTop: "5px",
                            marginBottom: "10px",
                            color: "#737373"
                        }}>This count might be smaller depending on constraining function.</span>
                    </div>

                </div>

            </div>
            {selectedFile && props.hubConnection &&
                <div className="thumbnail--wrapper" style={{ flex: 1 }}>
                    <Label className="group-label">Thumbnail:</Label>
                    <div style={{ flex: 1 }}>
                        <ThumbnailImage
                            fileUniqueIdentifier={selectedFile.uniqueIdentifier!}
                            fileId={selectedFile.id!}
                            fileVersionNumber={selectedFile.currentVersionNumber!}
                            fileExtension={getFileExtension(selectedFile.name)}
                            hubConnection={props.hubConnection}
                            onClick={() => { setShowThumbnailModal(true) }}
                            asBackground={true}
                            fullImage={true} />
                    </div>
                    {selectedFile && showThumbnailModal &&
                        <ThumbnailModal
                            file={selectedFile}
                            hubConnection={props.hubConnection!}
                            isOpened={showThumbnailModal}
                            onClose={() => setShowThumbnailModal(false)}
                        />}
                </div>}

        </div>


        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
            <Tab key="input-tab" value="input-parameters" >Input parameters</Tab>
            <Tab key="output-tab" value="output-parameters">Output parameters</Tab>
        </TabList>
        <div className="tab-content">
            {selectedTab == "input-parameters" &&
                <div>
                    {hasInitialRowIdAndCloneId() && <Button
                        style={{ marginBottom: "10px", marginRight: "10px" }}

                        disabled={!selectedStudy || !selectedStudy.filterId}
                        onClick={() => setState({ ...state, showSetOptimalValuesDialog: true })}
                    >Insert Values From MSS</Button>}
                    <Button
                        style={{ marginBottom: "10px", marginRight: "10px" }}
                        disabled={!selectedStudy || !selectedStudy.filterId}
                        onClick={() => setState({ ...state, showSetFlowsheetValuesDialog: true })}
                    >Insert Flowsheet Values</Button>

                    <Button
                        style={{ marginBottom: "10px" }}
                        disabled={!selectedStudy || !selectedStudy.filterId}
                        onClick={() => onStudyParamterRangeChange(selectedStudy.studyParamterRange)}
                    >Clear Values</Button>

                    <StudyInputParametersTab
                        isLoading={isLoading}
                        selectedStudy={selectedStudy}
                        selectedFile={selectedFile}
                        onSelectedStudyChanged={(updatedStudy: SensitivityStudy) => { setSelectedStudy(updatedStudy) }}
                        isFormSubmitted={state.isFormSubmitted} />

                    <div className="input-form-item" style={{ marginTop: "20px" }}>
                        <Label
                            style={{
                                fontWeight: 600,
                                width: "30%",
                                textAlign: "right",
                                maxWidth: "250px",
                                paddingRight: "22px"

                            }}>Input constraining function:</Label>

                        <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                            <CodeMirror
                                value={selectedStudy?.inputConstraints}
                                height="200px"
                                extensions={[javascript({ jsx: true })]}
                                onChange={(value) => { onPropertyChanged.call(this, "inputConstraints", value) }}
                            />
                            {/* <CodeMirror
                                className="CodeMirror--h200"
                                value={selectedStudy?.inputConstraints}
                                options={{ mode: 'javascript', lineNumbers: true }}
                                onBeforeChange={(editor, data, value) => {
                                    onPropertyChanged.call(this, "inputConstraints", value)
                                }}
                                onChange={(editor, data, value) => {
                                }}
                            /> */}
                            <small>You can specify JavaScript Math functions inside Input Constraining Function. You can use input parameters with alias as variables (alias is variable name). If left empty constraints won't be taken into account.</small>
                        </div>
                    </div>
                </div>
            }
            {selectedTab == "output-parameters" &&
                <StudyOutputParametersTab
                    isLoading={isLoading}
                    selectedFile={selectedFile}
                    isFormSubmitted={state.isFormSubmitted}
                    selectedStudy={selectedStudy}
                    onSelectedStudyChanged={(updatedStudy: SensitivityStudy) => { setSelectedStudy(updatedStudy) }} />}
        </div>


        <ConfirmationDialog
            isOpened={state.showSetFlowsheetValuesDialog}
            title='Set flowsheet values'
            subText="Existing values will be overwritten. Do you want to continue?"
            onConfirm={() => { setDefaultFlowsheetParameterValues(); setState({ ...state, showSetFlowsheetValuesDialog: false }); }}
            onClose={() => setState({ ...state, showSetFlowsheetValuesDialog: false })} />

        <ConfirmationDialog
            isOpened={state.showSetOptimalValuesDialog}
            title='Set optimal row values'
            subText="Existing values will be overwritten. Do you want to continue?"
            onConfirm={() => { setOptimumRowValues(); setState({ ...state, showSetOptimalValuesDialog: false }); }}
            onClose={() => setState({ ...state, showSetOptimalValuesDialog: false })} />




    </div>;

}

