import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Label, Radio, RadioGroup } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { UploadConflictAction, UploadFileResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { CustomFileModel, FilesUploadService } from "./FilesUploadService";
import { ConflictModal } from "../../components/conflict-modal/conflict-modal.component";

export type FilesUploadConflictModel = {
    files: CustomFileModel[],
    parentDirectoryUniqueId: string,
    hasExistingDirectory: boolean,
    resolve: (files: UploadFileResponseModel[]) => void,
    reject: (reason) => void
};


export const FilesUploadConflictModal: React.FC<{ filesUploadService: FilesUploadService }> = (props) => {


    const [open, setOpen] = useState(false);
    const [model, setModel] = useState<FilesUploadConflictModel>();

    React.useEffect(() => {
        console.log("conflict modal useeffect");
        props.filesUploadService
            .RegisterOnConflictModalShow((m: FilesUploadConflictModel) => {
                setOpen(true);
                setModel(m);
            });
    }, [props.filesUploadService]);


    const onSubmit = (conflictAction: UploadConflictAction) => {
        setOpen(false); // Close modal
        props.filesUploadService.UploadFilesWithCallbacks(model.files, model.parentDirectoryUniqueId, conflictAction, model.resolve, model.reject);
    }



    return open &&
        <ConflictModal
            isOpened={open}
            onSubmit={onSubmit}
            showExistingDirectoryMessage={!!model.hasExistingDirectory}
            onClose={() => { setOpen(false); model.reject(`Conflict modal closed.`); }} />
};