import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Label, DialogActions } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import "./comming-soon.modal.scss";

type CommingSoonModalProps = {
    isOpened: boolean;
    imageSrc: any;
    title: string;
    onClose: () => void;
}

export const CommingSoonModal: React.FC<CommingSoonModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);


    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "90vw" }}>

            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose}
                    icon={<Dismiss24Filled />} />}>{props.title}</DialogTitle>
                <DialogContent>
                    <div className="image-container">
                        <img src={props.imageSrc} alt={`${props.title} image`} className="disable-visual-search" />
                        <div className="watermark">Coming Soon...</div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button appearance="secondary" onClick={onModalClose}>Close</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}