import React from "react";
import { settings } from "../../App";
import { getPrivacyPolicyClient, getUserToken } from "../../services/dashboard.service";
import { EulaAcceptanceSource, EulaStatusPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { EulaModal } from "./eula-modal/eula-modal.component";

type EulaProps = {

    onEulaStatusChanged: (hasAccepted: boolean) => void;
}


export const Eula: React.FC<EulaProps> = (props) => {

    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [hasAccepted, setHasAccepted] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);


    React.useEffect(() => {
        hasUserAcceptedEula();

    }, []);

    const hasUserAcceptedEula = async () => {

        try {
            setIsLoading(true);
            const userAccessToken = await getUserToken();
            var model = new EulaStatusPostModel({
                accessToken: userAccessToken,
                siteId: "s365v2",
                eulaAcceptanceSource: EulaAcceptanceSource.Dashboard
            });
            const client = getPrivacyPolicyClient();
            const response = await client.getEULAStatus(model);
            console.log("GetEulaStatus reponse:", response);
            setHasAccepted(response.hasAccepted);
            props.onEulaStatusChanged(response.hasAccepted);
        }
        catch (error) {
            console.log("An error ocurred while getting EULA status.", error)
            setErrorMessage("An error occurred while loading EULA acceptance status.");
        } finally {
            setIsLoading(false);
        }

    }
    return !isLoading && !hasAccepted ? <EulaModal isOpened={true} errorMessage={errorMessage} onClose={() => { hasUserAcceptedEula(); }} /> : null;
}