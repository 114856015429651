import { Button, Menu, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, SplitButton, Tooltip } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell } from "@fluentui/react-components";
import React from "react";
import { toast } from "react-toastify";
import { getApplicationApiKeysClient } from "../../services/dispatcher.service";
import { ApplicationApiKeyResponseModel, CreateApplicationKeyPostModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog.component";
import { TableBodyWithLoading } from "../table-body-with-loading/table-body-with-loading.component";
import { CreateApplicationKeyModal } from "./create-application-key-modal/create-application-key-modal.component";
import ShowLocalTime from "../show-local-time/show-local-time.component";

type ApplicationKeysListProps = {
    applicationId: number;
    applicationName: string;
    isDeleted: boolean;
}

export const ApplicationKeysList: React.FC<ApplicationKeysListProps> = (props) => {

    const [applicationApiKeys, setApplicationApiKeys] = React.useState<ApplicationApiKeyResponseModel[]>([]);
    const [showDeactivateDialog, setShowDeactivateDialog] = React.useState<boolean>(false);
    const [showCreateDialog, setShowCreateDialog] = React.useState<boolean>(false);
    const [selectedApiKeyId, setSelectedApiKeyId] = React.useState<number>();
    const [isLoading, loadingService] = useLoading();
    const [showCopied, setShowCopied] = React.useState<string>();

    React.useEffect(() => {
        if (props.applicationId)
            getApiKeys();
    }, [props.applicationId]);

    const getApiKeys = async () => {
        loadingService.showLoading("Getting application keys...", async (hideMessage) => {
            try {

                const client = getApplicationApiKeysClient();
                const apiKeys = await client.getApplicationApiKeys(props.applicationId);
                setApplicationApiKeys(apiKeys);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting Application Api keys.");

            } finally {
                hideMessage();
            }
        });
    }

    const onCreateApiKeyClicked = async (description: string) => {
        try {
            const client = getApplicationApiKeysClient();
            const apiKey = await client.createApiKey(props.applicationId, new CreateApplicationKeyPostModel({ description: description }));
            setApplicationApiKeys([apiKey, ...applicationApiKeys]);
            setShowCreateDialog(false);
            toast.success("Api key deactivated.");
        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to deactivate application api key.");
        }
    }

    const onDeactivateKeyClicked = async () => {
        try {
            const client = getApplicationApiKeysClient();
            await client.deactivateApiKey(props.applicationId, selectedApiKeyId);
            const updatedKeys = applicationApiKeys.map(key => {
                if (key.id == selectedApiKeyId) {
                    return new ApplicationApiKeyResponseModel({ ...key, isActive: false })
                } else {
                    return key;
                }
            });
            setApplicationApiKeys([...updatedKeys]);
            setShowDeactivateDialog(false);
            toast.success("Api key deactivated.");
        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to deactivate application api key.");
        }
    }

    const onCopyClicked = (apiKey: string) => {
        navigator.clipboard.writeText(apiKey);

        setShowCopied(apiKey);
        setTimeout(() => { setShowCopied(undefined); }, 2000);
    }

    return <div>
        <Button appearance="primary" style={{ marginBottom: "var(--spacingVerticalS)" }} onClick={() => { setShowCreateDialog(true) }}>Add API key</Button>
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell key="name" style={{ width: "300px" }} className='s365-table__cell--bold'>Name</TableHeaderCell>
                    <TableHeaderCell key="created-at" className='s365-table__cell--bold'>Created At</TableHeaderCell>
                    <TableHeaderCell key="actions" className='s365-table__cell--bold'></TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={isLoading}
                columnCount={3} loadingMessage="Loading..."
                itemCount={applicationApiKeys ? applicationApiKeys.length : 0}
                noItemsMessage="No items found.">
                {applicationApiKeys && applicationApiKeys.length > 0 && applicationApiKeys.map((item) => {
                    return <TableRow key={`application-${item.id}`} >
                        <TableCell><div style={{ display: "flex", flexDirection: "column" }}>
                            <span style={{ textDecoration: !item.isActive ? "line-through" : undefined }}>{item.apiKey}</span>
                            <small>{item.description}</small>
                        </div></TableCell>
                        <TableCell><ShowLocalTime date={item.createdAt}  /></TableCell>
                        <TableCell>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                {props.isDeleted && <span style={{ paddingRight: "13px" }}>-</span>}
                                {!props.isDeleted && item.isActive &&
                                    <Menu positioning="below-end">
                                        <MenuTrigger disableButtonEnhancement>
                                            {(triggerProps: MenuButtonProps) =>
                                                <Tooltip relationship="description" visible={showCopied == item.apiKey} content="Copied to clipboard">
                                                    <SplitButton size="small"
                                                        primaryActionButton={
                                                            <Button size="small"
                                                                appearance="transparent"
                                                                style={{ padding: 0 }}
                                                                onClick={() => onCopyClicked(item.apiKey)}>Copy</Button>}
                                                        menuButton={triggerProps} ></SplitButton>
                                                </Tooltip>}
                                        </MenuTrigger>
                                        <MenuPopover style={{ minWidth: "90px" }}>
                                            <MenuList>
                                                <MenuItem style={{ height: "20px" }} onClick={() => { setSelectedApiKeyId(item.id); setShowDeactivateDialog(true) }}>Deactivate</MenuItem>
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>}
                                {!props.isDeleted && !item.isActive && <span style={{ paddingRight: "13px" }}><b>Inactive</b></span>}
                            </div>

                        </TableCell>

                    </TableRow>
                })}
            </TableBodyWithLoading>
        </Table>
        {selectedApiKeyId && showDeactivateDialog && <ConfirmationDialog
            title="Deactivate application key"
            subText={`Do you want to deactivate ${props.applicationName}'s current application key?`}
            isOpened={showDeactivateDialog}
            onConfirm={() => { onDeactivateKeyClicked(); }}
            onClose={() => { setSelectedApiKeyId(undefined); setShowDeactivateDialog(false); }} />}
        {showCreateDialog && <CreateApplicationKeyModal
            isOpened={true}
            onSubmit={(description: string) => { onCreateApiKeyClicked(description) }}
            onClose={() => { setShowCreateDialog(false) }} />}
    </div>

}