import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import sidebarLogo from '../../../assets/sidebar-logo.png';
import { HubConnection } from "@microsoft/signalr";
import { SensitivityStudyResultModel } from "../../../swagger-clients/sensitivity-studies-api-clients.service";
import { getSensitivityStudiesClient } from "../../../services/sensitivity-studies.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { SensitivityStudyDetails } from "../../sensitivity-study-details/sensitivity-study-details.component";
import { ApplicationTryNowButton } from "../../../components/application-try-now-button/application-try-now-button.component";
import { WizardApplication } from "../../../components/create-experiment-wizard/create-experiment-wizard.modal";

type StudyDetailsModalProps = {
    studyId: number;
    isOptimumTest?: boolean;
    isOpened?: boolean;
    hubConnection: HubConnection;
    onClose?: () => void;
}

export const StudyDetailsModal: React.FC<StudyDetailsModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);


    const onClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }


    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "95vw" }}>

            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onClose} icon={<Dismiss24Filled />} />}
                    style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <span>Multivariate Sensitivity Study (MSS) Example</span>
                    <span style={{
                        color: "grey",
                        fontSize: "1rem",
                        fontWeight: "400",
                        fontStyle: "italic",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        powered by  <img className="disable-visual-search" src={sidebarLogo} style={{ width: "auto", height: "40px" }} alt='Simulate 365 logo' /> </span>
                </DialogTitle>
                <DialogContent>

                    <SensitivityStudyDetails isExample studyId={props.studyId} isOptimumTester={props.isOptimumTest} hubConnection={props.hubConnection} />
                </DialogContent>
                <DialogActions>
                    <ApplicationTryNowButton
                        hubConnection={props.hubConnection}
                        application={props.isOptimumTest ? WizardApplication.OptimumTest : WizardApplication.MSS} />
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}
