import { Tooltip } from "@fluentui/react-components";
import React from "react";

type JobProgressCountProps = {
    totalJobs?: number;
    calculatedJobs: number;
    failedJobs: number;
}

const getTooltipContent = (totalJobs: number, calculatedJobs: number, failedJobs: number) => {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{color: 'var(--colorPaletteGreenForeground1)'}}>Calculated: {calculatedJobs}</span>
        <span style={{color: 'var(--colorPaletteRedForeground1)'}}>Failed: {failedJobs}</span>
        <span>Total: {totalJobs}</span>
    </div>
}

export const JobProgressCount: React.FC<JobProgressCountProps> = (props) => {
    const { totalJobs, calculatedJobs, failedJobs } = props;

    if (!totalJobs) 
        return <span>-</span>;

    const calculatedPercentage = Number(totalJobs) <= 0 ? 0 : Math.round(((Number(calculatedJobs) + Number(failedJobs)) / Number(totalJobs)) * 100);

    return <Tooltip withArrow content={getTooltipContent(totalJobs, calculatedJobs, failedJobs)} relationship="label">
        <span><span style={{color: 'var(--colorPaletteGreenForeground1)'}}>{calculatedJobs}</span>/<span style={{color: 'var(--colorPaletteRedForeground1)'}}>{failedJobs}</span> ({calculatedPercentage}%) out of {totalJobs}</span>
    </Tooltip>;
}