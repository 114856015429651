import { ActiveJobResponseModel } from "../../../../swagger-clients/dispatcher-next-api-clients.service";
import { ActiveJob } from "../../dispatcher-jobs-table/dispatcher-jobs-table.component";




export const MapActiveJobsShallowCopy = (source: ActiveJob[], destination: ActiveJob[]) => {


    //add new jobs
    let newJobs = source.filter(x => destination.findIndex(y => y.jobId == x.jobId) == -1);
    if (newJobs && newJobs.length > 0) {
        destination.push(...newJobs);
    }
    //remove old jobs, that dont exist anymore
    let oldJobs = destination.filter(x => source.findIndex(y => y.jobId == x.jobId) == -1);
    if (oldJobs && oldJobs.length > 0) {
        const oldJobsIndexes = oldJobs.map(job => destination.findIndex(x => x.jobId == job.jobId)).filter(y => y !== -1);
        oldJobsIndexes.forEach(jobIndex => {
            destination.splice(jobIndex, 1);
        });
    }

    // update jobs in state
    const existingJobs = source.filter(x => destination.findIndex(y => y.jobId == x.jobId) > -1);

    if (existingJobs && existingJobs.length > 0) {
        for (let i = 0; i < existingJobs.length; i++) {
            let exJob = existingJobs[i];
            let stJob = destination.find(x => x.jobId == exJob.jobId);

            stJob.status = exJob.status;
            MapExecutionsShallowCopy(exJob.executions, stJob.executions);
        }
    }

}

const MapExecutionsShallowCopy = (source: ActiveJobResponseModel[], destination: ActiveJobResponseModel[]) => {

    if (source && source.length > 0) {
        // Add new executions
        let newExecutions = source.filter(x => destination.findIndex(y => y.activeJobId == x.activeJobId) == -1);
        if (newExecutions && newExecutions.length > 0) {
            destination.push(...newExecutions);
        }

        // remove Executions that does not exist anymore
        let oldExecutions = destination.filter(x => source.findIndex(y => y.activeJobId == x.activeJobId) == -1);
        if (oldExecutions && oldExecutions.length > 0) {
            let oldExecutionsIndexes = oldExecutions.map(instance => destination.findIndex(x => x.activeJobId == instance.activeJobId)).filter(y => y !== -1);
            oldExecutionsIndexes.forEach(agentIndex => {
                destination.splice(agentIndex, 1);
            });
        }


        let existingExecutions = source.filter(x => destination.findIndex(y => y.activeJobId == x.activeJobId) > -1);

        if (existingExecutions && existingExecutions.length > 0) {
            for (let i = 0; i < existingExecutions.length; i++) {
                let exExecution = existingExecutions[i];
                let stExecution = destination.find(x => x.activeJobId == exExecution.activeJobId);
                stExecution.priority = exExecution.priority;
                stExecution.jobType = exExecution.jobType;
                stExecution.createdAt = stExecution.createdAt;
                if (exExecution.agent) {
                    if (!stExecution.agent) {
                        stExecution.agent = exExecution.agent;
                    } else {
                        stExecution.agent.id = exExecution.agent.id;
                        stExecution.agent.assignedAt = exExecution.agent.assignedAt;
                        stExecution.agent.name = exExecution.agent.name;
                    }

                } else {
                    stExecution.agent = undefined;
                }
                if (exExecution.application) {
                    if (!stExecution.application) {
                        stExecution.application = exExecution.application;
                    } else {
                        stExecution.application.id = exExecution.application.id;
                        stExecution.application.name = exExecution.application.name;
                    }


                } else {
                    stExecution.application = undefined;
                }

                if (exExecution.flowsheet) {
                    if (!stExecution.flowsheet) {
                        stExecution.flowsheet = exExecution.flowsheet;
                    } else {
                        stExecution.flowsheet.driveItemId = exExecution.flowsheet.driveItemId;
                        stExecution.flowsheet.driveItemVersion = exExecution.flowsheet.driveItemVersion;
                        stExecution.flowsheet.name = exExecution.flowsheet.name;
                    }

                } else {
                    stExecution.flowsheet = undefined;
                }
                if (exExecution.job) {
                    if (!stExecution.job) {
                        stExecution.job = exExecution.job;
                    } else {
                        stExecution.job.id = exExecution.job.id;
                        stExecution.job.status = exExecution.job.status;
                    }

                } else {
                    stExecution.job = undefined;
                }

                if (exExecution.jobExecution) {
                    if (!stExecution.jobExecution) {
                        stExecution.jobExecution = exExecution.jobExecution;
                    } else {
                        stExecution.jobExecution.id = exExecution.jobExecution.id;
                        stExecution.jobExecution.status = exExecution.jobExecution.status;
                    }


                } else {
                    stExecution.jobExecution = undefined;
                }


            }
        }

    }

}