import React from "react";
import { HideSidebarSectionInTour, ShowSidebarSectionInTour } from "./tour-guide.shared";

type MyWorkTourProps = {

}

export const MyWorkTour: React.FC<MyWorkTourProps> = (props) => {
    React.useEffect(() => {

        ShowSidebarSectionInTour("files_link");

        return () => {
            // Close my work menu section
            HideSidebarSectionInTour("files_link");
        }
    }, [])
    return <div style={{ marginTop: "var(--spacingVerticalMNudge)", marginBottom: "var(--spacingVerticalMNudge)" }}>
        <p>Manage your files and folders, categorize them with custom tags and comments and share them with others.</p>
        <span style={{ fontWeight: "bold" }}>Power tip!</span>
        <br />
        <span>Use Examples of our flowsheets to get started. Clone them to your Dashboard and right-click on files to see full options.</span>
        <br />
        <span>Visit Help for guidance on how to benefit from built-in Dashboard applications.</span>
    </div>
}