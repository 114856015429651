import { MenuItem, TableColumnDefinition, TableColumnSizingOptions, createTableColumn, useTableColumnSizing_unstable, useTableFeatures } from "@fluentui/react-components";
import { Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { DocumentTextRegular } from "@fluentui/react-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import CountdownTimer from "../../components/countdown-timer/countdown-timer.component";
import { ExamStatusComponent } from "../../components/exam-status/exam-status.component";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { UserDisplayName } from "../../components/user-display-name/user-display-name.component";
import { ContextMenu, ContextMenuType } from "../../files/context-menu/context-menu.component";
import { getEducationsClient } from "../../services/take-home-exams.service";
import { BreadcrumbItem } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { EducationExamResponseModel, ExamStatus } from "../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { addHours, addMinutes } from "../../utils/helpers/date.helpers";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import TakeHomeExamImage from '../../assets/TakeHomeExam.png';
import moment from "moment";


type StudentExamsProps = {

};
export const SudentExams: React.FC<StudentExamsProps> = (props) => {
    const navigate = useNavigate();
    const [items, setItems] = React.useState<EducationExamResponseModel[]>([]);
    const [selectedExam, setSelectedExam] = React.useState<EducationExamResponseModel>();
    const [isLoading, loadingService] = useLoading();
    const contextMenuRef = React.createRef<ContextMenuType>();
    const [columns, setColumns] = React.useState<TableColumnDefinition<EducationExamResponseModel>[]>(columnsDef);

    React.useEffect(() => {
        getUserExams();
    }, []);


    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({

        name: {
            minWidth: 100,
            defaultWidth: 300,
        },
        status: {
            minWidth: 100,
            defaultWidth: 200,
        },
        date: {
            minWidth: 100,
            defaultWidth: 200,
        },
        startTime: {
            minWidth: 50,
            defaultWidth: 100
        },
        duration: {
            minWidth: 50,
            defaultWidth: 100
        },
        timeleft: {
            minWidth: 100,
            defaultWidth: 200

        },
        supervisor: {
            minWidth: 100,
            defaultWidth: 300

        }
    });



    const getUserExams = async () => {
        loadingService.showLoading("Getting exams...", async (hideMessage) => {
            try {
                const client = getEducationsClient();
                const userExams = await client.getExams();
                setItems(userExams ?? []);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting user exams.");
            } finally {
                hideMessage();
            }

        });
    }
    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "status": return true;
            case "date": return true;
            case "startTime": return true;
            case "duration": return true;
            case "timeleft": return true;
            default: return false;
        }
    }

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<EducationExamResponseModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );


    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        menuItems.push(<MenuItem icon={<DocumentTextRegular />} key="details" onClick={() => navigate(`/exams/${selectedExam.id}/submit-answers`)} > Submit answers</MenuItem>);

        return menuItems;
    }

    return <div className='page-wrapper'>
        <div className="files-wrapper">

            <div className='files-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>
                    <Breadcrumb key={`breadcrumb-exams`} >My Exams</Breadcrumb>

                </Breadcrumbs>
            </div>


            <div className='s365-table__wrapper'>
                <Table sortable ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => (
                                <TableHeaderCell
                                    onDragStart={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                                    {...columnSizing_unstable.getTableHeaderCellProps(
                                        column.columnId
                                    )}
                                >
                                    {column.renderHeaderCell()}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBodyWithLoading isLoading={isLoading}
                        columnCount={9} loadingMessage="Loading..."
                        itemCount={!!items ? items.length : 0}
                        noItemsMessage="No items found.">
                        {!!items && items.length > 0 && items.map((item) => {
                            return <TableRow
                                key={`exam-${item.id}`}
                                style={{ cursor: "pointer" }}
                                onClick={() => { setSelectedExam(item); navigate(`/exams/${item.id}/submit-answers`) }}
                                className={selectedExam && selectedExam.id == item.id ? "table-row-selected" : undefined}
                                onContextMenu={(ev) => { ev.preventDefault(); setSelectedExam(item); contextMenuRef.current?.showMenu(ev); }}>
                                {columns.map((column) => (
                                    <TableCell
                                        className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                        {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                    >
                                        {column.renderCell(item)}
                                    </TableCell>
                                ))}

                            </TableRow>
                        })}

                    </TableBodyWithLoading>
                </Table>
            </div>
            {(!isLoading && !items?.length) && <div className='files-empty-directory'>
                <img className='files-empty-directory__image' src={TakeHomeExamImage} style={{ width: "256px" }} />
                <span className='files-empty-directory__text--main'>Take-Home Exercises/ Exams</span>
                <span className='files-empty-directory__text--sub'>This view shows exams that have been created for you.</span>
            </div>}




        </div>
        <ContextMenu ref={contextMenuRef} hasIcons>
            {getContextItems()}
        </ContextMenu>


    </div >
}

const columnsDef: TableColumnDefinition<EducationExamResponseModel>[] = [
    createTableColumn<EducationExamResponseModel>({
        columnId: "name",
        renderHeaderCell: () => <>Name</>,
        renderCell: (item: EducationExamResponseModel) => {
            return <span>{item.name}</span>;

        }
    }),
    createTableColumn<EducationExamResponseModel>({
        columnId: "status",
        renderHeaderCell: () => <>Status</>,
        renderCell: (item: EducationExamResponseModel) => {
            return <ExamStatusComponent status={item.status} />;
        }
    }),
    createTableColumn<EducationExamResponseModel>({
        columnId: "date",
        renderHeaderCell: () => <>Date</>,
        renderCell: (item: EducationExamResponseModel) => {

            return <ShowLocalTime date={item.date} />;

        }
    }),
    createTableColumn<EducationExamResponseModel>({
        columnId: "startTime",
        renderHeaderCell: () => <>Start time</>,
        renderCell: (item: EducationExamResponseModel) => {

            return <span>{item.startTime}</span>;

        }
    }),
    createTableColumn<EducationExamResponseModel>({
        columnId: "duration",
        renderHeaderCell: () => <>Duration</>,
        renderCell: (item: EducationExamResponseModel) => {

            return <span>{item.duration}</span>;
        }
    }),
    createTableColumn<EducationExamResponseModel>({
        columnId: "timeleft",
        renderHeaderCell: () => <>Time left</>,
        renderCell: (item: EducationExamResponseModel) => {

            const durationSplit = item ? item.duration.split(":") : [];
            const startedAt: Date = item ? moment.utc(moment(item.startedAt).format("YYYY-MM-DD HH:mm:ss")).local().toDate() : undefined;
            const expiryDate = durationSplit.length > 0 ? (addHours(addMinutes(startedAt, +durationSplit[1]), +durationSplit[0])) : undefined;

            return <span>{!!item && item.status == ExamStatus.Started && !!expiryDate ? <CountdownTimer expiryDate={expiryDate} /> : "-"}</span>
        }
    }),
    createTableColumn<EducationExamResponseModel>({
        columnId: "supervisor",
        renderHeaderCell: () => <>Supervisor</>,
        renderCell: (item: EducationExamResponseModel) => {

            return <UserDisplayName userId={item.userId} />;
        }
    })

];


