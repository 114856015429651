import { Menu, MenuButton, MenuButtonProps, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import { Add20Regular, DocumentArrowUp16Regular, FolderAdd16Regular, FolderArrowUp16Regular } from "@fluentui/react-icons";
import React, { useMemo } from "react";
import CreateDirectoryModal from "../../files/create-directory-modal/create-directory-modal.component";
import FileUploader, { FileUploaderType } from "../../files/upload/file-uploader.component";
import FolderUploader, { FolderUploaderType } from "../../files/upload/folder-uploader.component";
import { LoadingService } from "../../utils/loading-indicator.component";
import { CustomFileModel, FilesUploadService } from "./FilesUploadService";
import { useParams } from "react-router-dom";

type NewButtonProps = {
    onRefresh: () => void,
    loadingService: LoadingService,
    parentDirectoryUniqueId: string,
    filesUploadService: FilesUploadService
}

export const NewButton = ({ filesUploadService, ...props }: NewButtonProps) => {
    const fileUplaoderRef = React.createRef<FileUploaderType>();
    const folderUplaoderRef = React.createRef<FolderUploaderType>();
    const routeParams = useParams();

    const [showCreateDirectoryDialog, setShowCreateDirectoryDialog] = React.useState<boolean>(false);

    const uploadFiles = (data: FileList) => {
        const mappedFiles = [...(data as any)].map((file: File) => ({
            file: file, webkitRelativePath:
                file.webkitRelativePath
        } as CustomFileModel));

        filesUploadService.UploadFiles(mappedFiles, props.parentDirectoryUniqueId)
            .then(() => props.onRefresh());
    };

    return <>
        <Menu positioning="below-start">
            <MenuTrigger>
                {(triggerProps: MenuButtonProps) => {
                    return <MenuButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                        appearance='primary'
                        icon={<Add20Regular />}
                        {...triggerProps}
                    >New</MenuButton>
                }}
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem key="new-folder-btn" onClick={() => setShowCreateDirectoryDialog(true)}
                        icon={<FolderAdd16Regular />}>New folder</MenuItem>
                    <MenuDivider />
                    <MenuItem key="file-upload-btn"
                        onClick={() => fileUplaoderRef.current?.click()}
                        icon={<DocumentArrowUp16Regular />}
                    >File upload</MenuItem>
                    <MenuItem key="folder-upload-btn"
                        onClick={() => folderUplaoderRef.current?.click()}
                        icon={<FolderArrowUp16Regular />}
                    >Folder upload</MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>

        { /* Create directory */}
       { !!showCreateDirectoryDialog && <CreateDirectoryModal
            parentDirectoryUniqueId={routeParams.parentDirectoryUniqueId}
            onSuccess={() => { setShowCreateDirectoryDialog(false); props.onRefresh(); }}
            isOpened={showCreateDirectoryDialog}
            onClose={() => setShowCreateDirectoryDialog(false)}
        />}

        { /* Upload files */}
        <FileUploader
            isHidden={true}
            ref={fileUplaoderRef}
            handleFiles={(data) => uploadFiles(data)}
        />

        { /* Upload folder */}
        <FolderUploader
            isHidden={true}
            ref={folderUplaoderRef}
            handleFiles={(data) => uploadFiles(data)}
        />
    </>;
}