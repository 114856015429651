
import React, { useState } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { useParams } from 'react-router-dom';
import { FilesTable, FilesTableSort } from '../../files/files-table/files-table.component';
import { FileTableColumn, FileTableItem } from '../../files/models/file-table.models';
import { SidePanel } from '../../files/side-panel/side-panel';
import { ParentPathLink } from '../../files/parent-path-link/parent-path-link.component';
import { TagOption } from '../../files/tag-picker/tag-picker.component';
import { getFilesClient, getTagsClient } from '../../services/dashboard.service';
import { FileModel, FileSortFields, FileSystemEntityType, IRestoreFilePostModel, RestoreFilePostModel } from '../../swagger-clients/s365-dashboard-v2-api-clients.service';
import { processServerError } from '../../utils/helpers/error.helper';
import { useLoading } from '../../utils/loading-indicator.component';
import EmptyStarredIcon from '../../assets/empty-starred.png';
import { MenuItem, createTableColumn, tokens } from '@fluentui/react-components';
import { ArrowResetRegular, DeleteRegular, DocumentTextRegular, InfoFilled } from '@fluentui/react-icons';
import { toast } from 'react-toastify';
import { findAncestor } from 'typescript';
import { Alert } from '@fluentui/react-components/unstable';
import TimeAgo from 'react-timeago';
import moment from 'moment';
import { RestoreFileRenameDialog } from './restore-file-rename-dialog.component';
import { S365TimeAgo } from '../../components/s365-time-ago/s365-time-ago.component';

type RemovedFilesPageProps = {
    hubConnection?: HubConnection
}

function RemovedFilesPage(props: RemovedFilesPageProps) {

    const [files, setFiles] = React.useState<FileTableItem[]>([]);
    const [showTagInputs, setShowTagInputs] = useState<{ [key: string]: boolean }>({});
    const [selectedItem, setSelectedItem] = React.useState<FileTableItem>();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [showRestoreRenameDialog, setShowRestoreRenameDialog] = React.useState<boolean>(false);
    const [restoreNewName, setRestoreNewname] = React.useState<string>("");
    const [isLoading, loadingService] = useLoading();
    const [isFilesLoading, setIsFilesLoading] = useState(false);
    const [fileTagDefaultOptions, setFileTagDefaultOptions] = React.useState<TagOption[]>([]);
    const [commentValidationState, setCommentValidationState] = React.useState<'error' | 'warning' | 'success'>();
    const [commentValidationMessage, setCommentValidationMessage] = React.useState<string>();
    const [sortBy, setSortBy] = React.useState<FilesTableSort>({ field: FileSortFields.Name, isDescending: false });

    const routeParams = useParams();

    React.useEffect(() => {
        if (!!restoreNewName) {
            onRestoreFileClick();
        }

    }, [restoreNewName]);

    React.useEffect(() => {
        getFilesWithLoading();
    }, [sortBy]);

    React.useEffect(() => {
        getTagDefaultSuggestions();
    }, []);

    React.useEffect(() => {
        if (commentValidationMessage) {
            setCommentValidationMessage(undefined);
        }
        if (commentValidationState) {
            setCommentValidationState(undefined);
        }
        setRestoreNewname("");
    }, [selectedItem]);



    const getFilesWithLoading = async () => {
        loadingService.showLoading("Loading...", (hideMessage) => {
            getFiles().finally(() => hideMessage());
        })
    }

    const getFiles = async () => {
        try {
            setIsFilesLoading(true); // This separate isLoading is needed to show empty directory icon

            const client = getFilesClient();
            const resp = await client.getDeletedFiles(sortBy.field, sortBy.isDescending);

            if (resp) {
                setFiles(resp ?? []);
                if (selectedItem) {
                    const updatedSelectedItem = resp?.find(x => x.id == selectedItem.id && x.type == selectedItem.type);
                    console.log("updatedSelectedItem", updatedSelectedItem);
                    setSelectedItem({ ...updatedSelectedItem } as FileModel);
                }

            }

        }
        catch (error) {
            processServerError(error, undefined, "An error occurred while getting files.");
        }
        finally {
            setIsFilesLoading(false);
        }
    }

    const getTagDefaultSuggestions = async () => {

        try {

            const client = getTagsClient();

            const fileTagSuggestionResp = await client.getFileTagSuggestions("", 0);

            const filetagOptions = fileTagSuggestionResp.map(x => ({ label: x.name, value: x.id } as TagOption));

            setFileTagDefaultOptions(filetagOptions);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting tag name suggestions.");
        }
    }
    const onRestoreFileClick = async () => {
        loadingService.showLoading("Restoring...", async (hideMessage) => {

            try {
                const client = getFilesClient();
                const model = new RestoreFilePostModel({
                    fileUniqueId: selectedItem.uniqueIdentifier,
                    fileType: selectedItem.type,
                    newName: restoreNewName

                } as IRestoreFilePostModel);
                await client.restoreFile(model);
                toast.success("File successfully restored.");
                getFiles();

            } catch (error) {
                if ((error as any).status == 409) {
                    setShowRestoreRenameDialog(true);
                    if (!!restoreNewName) {
                        const isFolder = selectedItem?.type == FileSystemEntityType.Directory;
                        toast.error(`${isFolder ? 'Folder' : 'File'} with specified name already exists in destination location.`);
                    }
                } else {
                    processServerError(error, undefined, "An error occurred while restoring file.");
                }

            } finally {
                hideMessage();
            }

        });

    }

    // const onPermanentDeleteClick = () => {
    //     loadingService.showLoading("Permanently deleting...", async (hideMessage) => {
    //         try {

    //         } catch (error) {

    //         } finally {
    //             hideMessage();
    //         }
    //     });
    // }


    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        if (!selectedItem) {
            return menuItems;
        }


        menuItems.push(...[
            <MenuItem key="restore" icon={<ArrowResetRegular />} onClick={() => { onRestoreFileClick(); }}> Restore</MenuItem >,
            //  <MenuItem key="permanent-delete" icon={<DeleteRegular />} onClick={() => { onPermanentDeleteClick(); }}> Permanent Delete</MenuItem >,
            <MenuItem key="details" icon={<DocumentTextRegular />} onClick={() => { setShowDetails(true); }}> Details</MenuItem >
        ]);


        return menuItems;
    }

    const filePathColumn = {
        column: createTableColumn<FileTableItem>({
            columnId: "location",
            renderHeaderCell: () => <>Location</>,
        }),
        cellValue(fileItem) {
            return <ParentPathLink directoryPaths={fileItem.directoryPaths} />
        },

    } as FileTableColumn;

    const deletedColumn = {
        column: createTableColumn<FileTableItem>({
            columnId: "deleted",
            renderHeaderCell: () => <>Deleted</>,
        }),
        cellValue(fileItem) {
            return <S365TimeAgo date={moment(fileItem.deletedAt).local()} />
        },
        cellTextCentered: true
    } as FileTableColumn;


    const contextMenuItems = getContextItems();
    return (<div className='page-wrapper'>
        <div className='files-wrapper'>
            <div className='page-title'>Bin</div>
            <Alert intent="info" className="alert--info-global" style={{ marginBottom: "var(--spacingVerticalXXL)" }}  >
                Files will be permanently deleted after 30 days.
            </Alert>
            <FilesTable
                isLoading={isFilesLoading}
                loadingService={loadingService}
                files={files}
                readonly
                hideModifiedColumn
                disableNavigation
                disableSubmitForAnalysis
                sortable
                sortBy={sortBy}
                onSortChange={(data) => { setSortBy(data); }}
                additionalColumns={[deletedColumn, filePathColumn]}
                hubConnection={props.hubConnection}
                contextMenuItems={contextMenuItems}
                onSelectedItemChanged={(item) => { setSelectedItem(item); }}
                onShowTagInputsChanged={(value) => setShowTagInputs(value)}
                onReloadFilesEvent={() => getFilesWithLoading()}
                onShowDetails={(item) => { setSelectedItem(item); setShowDetails(true); }} />

        </div>

        {/* Side panel */}
        <SidePanel
            isOpened={selectedItem && showDetails}
            selectedItem={selectedItem}
            readOnly
            fileTagDefaultOptions={fileTagDefaultOptions}
            hubConnection={props.hubConnection}
            loadingService={loadingService}
            onHide={() => setShowDetails(false)}
            onReloadFilesEvent={() => getFilesWithLoading()}
        />

        {showRestoreRenameDialog &&
            <RestoreFileRenameDialog
                currentName={selectedItem?.name}
                isFolder={selectedItem?.type == FileSystemEntityType.Directory}
                isOpened={showRestoreRenameDialog}
                onSuccess={(newName) => { setRestoreNewname(newName); setShowRestoreRenameDialog(false); }}
                onClose={() => { setRestoreNewname(""); setShowRestoreRenameDialog(false); }}
            />}

    </div>
    );
}

export default RemovedFilesPage;