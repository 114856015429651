import * as React from 'react';
import './parameter-picker-next.style.scss';
import getFlowsheetObjectByParameter from './parameter-to-flowsheet-objects-maper';
import FlowsheetObjectService from '../../services/flowsheet-objects.service';
import { FlowsheetObjectModel, FlowsheetObjectsResponseModel, FlowsheetObjectType, FlowsheetParameterAccessMode, FlowsheetParameterModel } from '../../swagger-clients/dispatcher-next-api-clients.service';

import { Alert } from '@fluentui/react-alert';
import { ActionMeta, components, GroupBase, MultiValue, StylesConfig } from 'react-select';
import { default as ReactSelect } from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';
import { _copyAndSort } from '../../utils/helpers/array.helpers';


enum ParameterAccessModel {
    Read,
    Write
}

type ParameterPickerProps = {
    fileUniqueId: string,
    fileVersionNumber: string,
    value?: string,
    // isOutput?: boolean,   
    onChange?: (value: string) => void,
    accessMode: ParameterAccessModel
    //errorMessage?: string | JSX.Element
}

export type TagOption = {
    value: string | number,
    label: string

}

export type ParameterPickerGroupOption = {
    readonly label: string;
    readonly options: TagOption[];
}


type IFlowsheetObjectTag = TagOption & {
    flowsheetObject: FlowsheetObjectModel
}

type IFlowsheetParameterTag = TagOption & {
    flowsheetParameter: FlowsheetParameterModel
}

export const ParameterPickerNext: React.FC<ParameterPickerProps> = (props) => {
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
    const [itemLimit, setItemLimit] = React.useState<number>(2);
    const [selectedValues, setSelectedValues] = React.useState<TagOption[]>([]);
    const [options, setOptions] = React.useState<ParameterPickerGroupOption[]>([]);
    const [currentValue, setCurrentValue] = React.useState<string>("");
    const selectRef = React.createRef<Select<TagOption, true, GroupBase<TagOption>>>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {

        try {
            setIsLoading(true);
            let response: FlowsheetObjectsResponseModel;

            try {
                // Wait for data to load
                response = await FlowsheetObjectService.getFlowsheetObjectsPromise("s365v2", props.fileUniqueId, props.fileVersionNumber);

            } catch (error) {
                console.log("An error occurred while trying to get flowsheet objects.", error);
            }
            // console.log("ParameterPickerNext resonse objects", response, props.value);

            if (response && props.value) {
                // Get flowsheet objects
                let flowsheetObjects = getFlowsheetObjectByParameter(props.value, response);
                //  console.log("Initial objects", flowsheetObjects);
                let values: TagOption[] = [];
                // Convert to tags         
                for (let o of flowsheetObjects) {
                    if ((o as FlowsheetObjectModel).objectType !== undefined) {
                        values.push(mapFlowsheetObjectToTag(o));
                    }
                    else if ((o as FlowsheetParameterModel).parameter !== undefined) {

                        values.push(mapFlowsheetParameterToTag(o));
                    }
                }
                setSelectedValues(values);

            }
        }
        finally {
            setIsDisabled(false);
            setIsLoading(false);
        }

    }
    React.useEffect(() => {
        //  console.log("options changed", options);
        selectRef.current.renderMenu();

    }, [options]);

    React.useEffect(() => { updateSuggestions(); }, [selectedValues, currentValue]);

    const updateSuggestions = async () => {
        const suggestions = await showSuggestions(currentValue);
        //  console.log("updateSuggestions", suggestions, currentValue);
        setOptions([...suggestions]);
    }

    /**
     * Provide list of suggestions to be shown to user
     */
    const showSuggestions = async (filterText: string): Promise<ParameterPickerGroupOption[]> => {
        //  console.log("Show suggestions called.");
        let response: FlowsheetObjectsResponseModel;
        try {
            setIsLoading(true);
            response = await FlowsheetObjectService.getFlowsheetObjectsPromise("s365v2", props.fileUniqueId, props.fileVersionNumber);


            // Wait for data to load

            // If nothing is selected, filter flowsheet objects
            if (!selectedValues || !selectedValues.length) {
                let mappedOptions: ParameterPickerGroupOption[] = [];
                let objects = response.flowsheetObjects
                    .filter(o => (o.objectType == FlowsheetObjectType.UnitOp || o.objectType == FlowsheetObjectType.Stream) && (!filterText || o.displayName.toLowerCase().indexOf(filterText.toLowerCase())) != -1);

                if (!objects || objects.length == 0)
                    return mappedOptions;

                let allObjects = objects.map(o => mapFlowsheetObjectToTag(o));

                allObjects = _copyAndSort<IFlowsheetObjectTag>(allObjects, "label", false);


                const streams = allObjects.filter(x => x.flowsheetObject.objectType == FlowsheetObjectType.Stream);
                if (streams && streams.length > 0) {
                    mappedOptions.push({ label: "Streams", options: streams } as ParameterPickerGroupOption)
                }

                const unitOps = allObjects.filter(x => x.flowsheetObject.objectType == FlowsheetObjectType.UnitOp);
                if (unitOps && unitOps.length > 0) {
                    mappedOptions.push({ label: "Unit operations", options: unitOps } as ParameterPickerGroupOption)
                }

                return mappedOptions;

            }
            else {
                let lastTag = selectedValues[selectedValues.length - 1] as IFlowsheetObjectTag;
                //   console.log("lastTag", lastTag);
                // If flowsheet object is selected
                if ((lastTag as IFlowsheetObjectTag).flowsheetObject !== undefined) {
                    let toReturn: ParameterPickerGroupOption[] = [];
                    let flowsheetObject = (lastTag as IFlowsheetObjectTag).flowsheetObject;

                    let paramBase = "";
                    if (flowsheetObject.objectType == FlowsheetObjectType.UnitOp)
                        paramBase = `u{${flowsheetObject.uniqueIdentifier}}_p`;
                    else if (flowsheetObject.objectType == FlowsheetObjectType.Stream)
                        paramBase = `s{${flowsheetObject.uniqueIdentifier}}_p`;
                    else if (flowsheetObject.objectType == FlowsheetObjectType.Component) {
                        if (selectedValues.length >= 2) {
                            let streamTag = selectedValues[selectedValues.length - 2] as IFlowsheetObjectTag;
                            paramBase = `s{${streamTag.flowsheetObject.uniqueIdentifier}}_c{${flowsheetObject.uniqueIdentifier}}_`;
                        }
                    }
                    // console.log("paramBase", paramBase, "Total parameters", response.flowsheetParameters.length);
                    // First get parameters for unit operation or stream
                    let params = response.flowsheetParameters.filter(p => {
                        return p.parameter.startsWith(paramBase)
                            && p.displayName.toLowerCase().indexOf(filterText.toLowerCase()) != -1
                            && (
                                (props.accessMode == ParameterAccessModel.Read && (p.accessMode == FlowsheetParameterAccessMode.ReadOnly || p.accessMode == FlowsheetParameterAccessMode.ReadWrite))
                                || (props.accessMode == ParameterAccessModel.Write && (p.accessMode == FlowsheetParameterAccessMode.WriteOnly || p.accessMode == FlowsheetParameterAccessMode.ReadWrite))
                            );
                    });

                    console.log("Params", params);

                    let tagParams = params.map(p => mapFlowsheetParameterToTag(p));
                    tagParams = _copyAndSort<IFlowsheetParameterTag>(tagParams, "label", false);


                    if (tagParams && tagParams.length > 0) {
                        toReturn.push({ label: "Parameters", options: tagParams } as ParameterPickerGroupOption)
                    }

                    // For streams, get components
                    if (flowsheetObject.objectType == FlowsheetObjectType.Stream) {
                        let components = response.flowsheetObjects.filter(c => {
                            return c.objectType == FlowsheetObjectType.Component && c.displayName.toLowerCase().indexOf(filterText.toLowerCase()) != -1;
                        });

                        let tagComponents = components.map(o => mapFlowsheetObjectToTag(o));
                        //   console.log("flowsheetObject.uniqueIdentifier,tagComponents", flowsheetObject.uniqueIdentifier, tagComponents);
                        // show components that have parameters
                        tagComponents = tagComponents.filter(c =>
                            response.flowsheetParameters.find(x => x.parameter.indexOf(`s{${flowsheetObject.uniqueIdentifier}}_c{${c.value}}_`) != -1) !== undefined);


                        if (tagComponents && tagComponents.length > 0) {
                            tagComponents = _copyAndSort<IFlowsheetObjectTag>(tagComponents, "label", false);
                            toReturn.push({ label: "Components", options: tagComponents } as ParameterPickerGroupOption)
                        }
                    }

                    return toReturn;
                }
            }

            return [];
        } catch (error) {
            console.log("An error occurred while trying to get flowsheet objects showSuggestions.", error);

            let errorMessage = error ? (error as any).error : undefined;
            if (!errorMessage || errorMessage.length == 0) {
                errorMessage = "Flowsheet objects not available, please analyze flowsheet again.";
            }
            return [{ label: "Errors", options: [{ value: "error", label: errorMessage } as TagOption] } as ParameterPickerGroupOption];
        } finally {
            setIsLoading(false);
        }
    }

    const mapFlowsheetObjectToTag = (flowsheetObject: FlowsheetObjectModel) => {
        return {
            value: flowsheetObject.uniqueIdentifier,
            label: flowsheetObject.displayName,
            flowsheetObject: flowsheetObject
        } as IFlowsheetObjectTag;
    }

    const mapFlowsheetParameterToTag = (flowsheetParameter: FlowsheetParameterModel) => {
        return {
            value: flowsheetParameter.parameter,
            label: `${flowsheetParameter.displayName}` + (flowsheetParameter.unit ? ` (${flowsheetParameter.unit})` : ""),
            flowsheetParameter: flowsheetParameter
        } as IFlowsheetParameterTag;
    }
    const onItemSelected = (tag: TagOption) => {



        // If property is selected, send change to parent
        if ((tag as IFlowsheetParameterTag).flowsheetParameter !== undefined) {
            if (props.onChange) {
                let param = (tag as IFlowsheetParameterTag).flowsheetParameter;
                props.onChange(param.parameter);
            }
        }

        // Focus input field again

        return tag;
    }


    const Option = (props) => {
        const item = props.data;
        //console.log("Render option", props);
        // console.log("item,itemProps",item,itemProps);
        if ((item as IFlowsheetObjectTag).flowsheetObject !== undefined) {
            let casted = item as IFlowsheetObjectTag;
            let flowsheetObject = casted.flowsheetObject;
            return <components.Option {...props}>
                <div className="parameter-picker-next__sugested-item">
                    <div>{flowsheetObject.displayName}</div>
                    <small>{flowsheetObject.subtype}</small>
                </div>
            </components.Option>;
        }
        else if ((item as IFlowsheetParameterTag).flowsheetParameter !== undefined) {
            let casted = item as IFlowsheetParameterTag;
            let flowsheetParam = casted.flowsheetParameter;
            return <components.Option {...props}> <div className="parameter-picker-next__sugested-item">
                <div>{flowsheetParam.displayName}</div>
                <small>{flowsheetParam.value} {flowsheetParam.unit}</small>
            </div>
            </components.Option>;
        } else if (item.value == "error") {
            return <components.Option {...props}><div className="parameter-picker-next__sugested-item parameter-picker-next__sugested-item--error">
                <Alert intent="error" style={{ paddingRight: "5px" }} >
                    <span>{item.label}</span>
                </Alert>
            </div></components.Option>;
        }
        else {
            return <components.Option {...props}> <span>!ERROR - Unknown render</span></components.Option>;
        }
    }


    const onParametersChanged = async (selectedOptions: MultiValue<TagOption>, actionMeta: ActionMeta<TagOption>) => {
        console.log("onTagsChanged", selectedOptions, actionMeta);
        const lastItem = selectedOptions.length > 0 ? selectedOptions[selectedOptions.length - 1] : undefined;
        if (lastItem && selectedOptions.length == 2) {
            let lastTag = lastItem as IFlowsheetObjectTag;
            if (lastTag.flowsheetObject && lastTag.flowsheetObject.objectType == FlowsheetObjectType.Component) {
                setItemLimit(3);
            } else {
                setItemLimit(2);
            }
        }
        // on option selected
        if (actionMeta.action == "select-option") {
            onItemSelected(lastItem);
            setSelectedValues([...selectedOptions] as TagOption[]);
        }

        // on tag removed

        if (actionMeta.action == "remove-value") {

            let removedValueIndex = selectedValues.findIndex(x => x.value == actionMeta.removedValue.value);
            const updatedValues = selectedValues.filter((x, i) => i < removedValueIndex);
            setSelectedValues([...(updatedValues ?? [])]);

        }

    }

    // const renderItem = (props: IPickerItemProps<ITag>) => {


    //     return <TagItem {...props} styles={items.length > 2 ? { root: { maxWidth: "150px" } } : null}>{props.item.name}</TagItem>


    // }


    return <div style={{ width: "100%" }}><ReactSelect
        ref={selectRef}
        value={selectedValues}
        menuPlacement={"auto"}
        isMulti
        placeholder=""
        styles={ParameterPickerStyless}
        isLoading={isLoading}
        isClearable={false}
        options={options}
        isDisabled={isDisabled}
        onMenuOpen={() => updateSuggestions()}
        onInputChange={(newValue: string) => { setCurrentValue(newValue); }}
        onChange={onParametersChanged}
        components={{ Option }}

    /></div>


}

const ParameterPickerStyless: StylesConfig<TagOption, true, GroupBase<TagOption>> = {
    control: (styles) => ({ ...styles, minHeight: "30px" }),
    dropdownIndicator: (styles) => ({
        ...styles,
        paddingTop: 5,
        paddingBottom: 5,
    }),
    clearIndicator: (styles) => ({
        ...styles,
        paddingTop: 5,
        paddingBottom: 5,
    }),
    input: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: "14px"
    }),
    option: (styles) => ({
        ...styles,
        paddingTop: "4px",
        paddingBottom: "4px",
        lineHeight: "16px"
    }),
};


export default ParameterPickerNext;
export { ParameterAccessModel };