import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, Input, Select, Skeleton, SkeletonItem, TableCellLayout, TableColumnDefinition, createTableColumn, tokens } from "@fluentui/react-components";
import React, { useMemo } from "react";
import { CalculationJobResultModel, CalculationRunJobStatus, ExperimentDataQuery, ExperimentResponseModel, ParameterFilter, SeparationMethodOption, SpecificationProperty } from "../../../swagger-clients/ai-for-pfd-clients.service";
import { DataGridScrollableHeaderStyles, DataGridScrollableWrapper } from "../../../utils/DataGridScrollableWrapper";
import { getExperimentElasticSearchClient } from "../../../services/ai-for-pfd.service";
import { SignificantFigures } from "../../../components/significant-figures/significant-figures.component";
import { DwsimComponentDisplayName } from "../../../components/dwsim-component-display-name/dwsim-component-display-name.component";
import { IDropdownOption } from "../../../utils/shared.types";
import { DismissFilled } from "@fluentui/react-icons";
import CopilotFilterParameterPicker, { CopilotParamSelectOption, ICopilotFilterParamData } from "./copilot-parameter-picker.component";
import ReactPaginate from "react-paginate";
import { processServerError } from "../../../utils/helpers/error.helper";
import { CalculationJobStatusComponent } from "./calculation-job-status.component";
import { isNumeric } from "../../../utils/helpers/string.helpers";
import { GetSpecificationPropertyName, GetSpecificationPropertyUnit } from "../../edit-experiment/edit-experiment.helpers";
import { ResultInputParameter } from "../../components/result-input-parameter.component";
import { ResultOutputParameter } from "../../components/result-output-parameter.component";



type ExperimentResultDataProps = {
    experimentVersionId: number;
    separationMethod: SeparationMethodOption;
    experiment: ExperimentResponseModel;
    tableHeight?:string;
}


export type ExperimentResultDataType = { getData(): void; };

export const ExperimentResultData = React.forwardRef<ExperimentResultDataType, ExperimentResultDataProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            getData() {
                getData();
            }
        }));

    const [sequence, setSequence] = React.useState<number | undefined>(undefined);
    const [flowsheetId, setFlowsheetId] = React.useState<number | undefined>(undefined);
    const [calculationStatus, setCalculationStatus] = React.useState<CalculationRunJobStatus>(CalculationRunJobStatus.Calculated);
    const [filterParameter, setFilterParameter] = React.useState<ICopilotFilterParamData>(null);
    const [rows, setRows] = React.useState<CalculationJobResultModel[]>([]);
    const [totalRows, setTotalRows] = React.useState<number>(0);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [sizePerPage, setSizePerPage] = React.useState<number>(25);
    const [inputOutputColumns, setInputOutputColumns] = React.useState<TableColumnDefinition<CalculationJobResultModel>[]>([]);
    const [parameterPickerOptions, setParameterPickerOptions] = React.useState<CopilotParamSelectOption[]>([]);
    const [columns, setColumns] = React.useState<TableColumnDefinition<CalculationJobResultModel>[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [sortState, setSortState] = React.useState<{ sortColumn: string; sortDirection: "ascending" | "descending"; }>
        ({
            sortColumn: "sequence",
            sortDirection: "ascending",
        });

    const onColumnClick = (columnId: string) => {

        if (sortState.sortColumn != columnId) {
            setSortState({
                sortColumn: columnId,
                sortDirection: "ascending"
            })
        } else {
            if (sortState.sortDirection == "ascending") {
                setSortState({ sortColumn: columnId, sortDirection: "descending" });
            } else {
                setSortState({ sortColumn: columnId, sortDirection: "ascending" });
            }
        }
    }
    React.useEffect(() => {
        const columnsResp = getColumns();
        setColumns(columnsResp ?? []);
    }, []);



    React.useEffect(() => { getData(); }, [currentPage, sortState.sortColumn, sortState.sortDirection]);


    const getData = async () => {
        try {

            const client = getExperimentElasticSearchClient();

            let filterParamData: ParameterFilter = undefined;
            if (filterParameter) {
                filterParamData = new ParameterFilter();
                filterParamData.parameterId = filterParameter.parameterId;
                filterParamData.filterOperation = filterParameter.operation;
                filterParamData.equalsValue = filterParameter.equalsValue ? +filterParameter.equalsValue : undefined;
                filterParamData.endsValue = filterParameter.endValue ? +filterParameter.endValue : undefined;
            }

            const query = new ExperimentDataQuery({
                sequence: sequence,
                flowsheetId: flowsheetId,
                parameterFilter: filterParamData,
                status: calculationStatus > -1 ? calculationStatus : undefined,
                experimentVersionId: +props.experimentVersionId,
                orderBy: sortState.sortColumn.toString(),
                orderByDescending: sortState.sortDirection == "descending",
                skip: (currentPage - 1) * sizePerPage,
                take: sizePerPage
            });

            const dataResult = await client.getExperimentResultData(query);

            setTotalRows(dataResult.totalRecords);

            setRows(dataResult.rows);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting data.");
        }
        finally {
            setIsLoading(false);
        }

    }


    const getParameterOptions = () => {
        let paramterOptions: CopilotParamSelectOption[] = [];
        if (!rows || rows.length == 0)
            return paramterOptions;

        const firstRow = rows[0];
        firstRow.inputs.forEach((param) => {
            paramterOptions.push({ label: param.parameter, value: `parameters.${param.parameter}` });
        });
        paramterOptions.push(...[
            {
                label: "energy", value: "energy"
            },
            {
                label: "capex", value: "capex"
            },
            {
                label: "opex", value: "opex"
            }
        ]);

        firstRow.results.forEach((param) => {
            paramterOptions.push({ label: param.caS_Number, value: `purities.${param.caS_Number}` });
        });

        return paramterOptions;
    }




    const getInputAndResultParamColumns = () => {

        if (!rows || rows.length == 0)
            return [];

        const firstRow = rows[0];
        let inputColumns = [];
        firstRow.inputs.forEach((inputParam, index) => {
            inputColumns.push(createTableColumn<CalculationJobResultModel>({
                columnId: `parameters.${inputParam.parameter}`,
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold, wordBreak: "break-word" }}>
                        <ResultInputParameter parameter={inputParam.parameter} separationMethod={props.separationMethod} />
                    </TableCellLayout>;
                },
                renderCell: (item) => {
                    const cellParam = item.inputs[index];
                    return <TableCellLayout style={{ justifyContent: 'center' }}>
                        {cellParam && cellParam.value !== undefined ?
                            <SignificantFigures value={+cellParam.value} significantFigures={5} spanStyle={{ width: "100%", textAlign: "center" }} />
                            : "-"}</TableCellLayout>;
                },
            }));
        });

        inputColumns.push(...[createTableColumn<CalculationJobResultModel>({
            columnId: "energy",
            renderHeaderCell: () => {
                return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>
                    <ResultOutputParameter componentCasNumber="energy" separationMethod={props.experiment.separationMethod} />
                </TableCellLayout>;
            },
            renderCell: (item) => {
                return <TableCellLayout style={{ justifyContent: 'center' }}>{item.energy}</TableCellLayout>;
            },
        }),
        createTableColumn<CalculationJobResultModel>({
            columnId: "capex",
            renderHeaderCell: () => {
                return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>
                    <ResultOutputParameter componentCasNumber="capex" separationMethod={props.experiment.separationMethod} />
                    </TableCellLayout>;
            },
            renderCell: (item) => {
                return <TableCellLayout style={{ justifyContent: 'center' }}>{item.capex}</TableCellLayout>;
            },
        }),
        createTableColumn<CalculationJobResultModel>({
            columnId: "opex",
            renderHeaderCell: () => {
                return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>
                    <ResultOutputParameter componentCasNumber="opex" separationMethod={props.experiment.separationMethod} />
                    </TableCellLayout>;
            },
            renderCell: (item) => {
                return <TableCellLayout style={{ justifyContent: 'center' }}>{item.opex}</TableCellLayout>;
            },
        })
        ]);

        firstRow.results.forEach((resultParam, index) => {
            inputColumns.push(createTableColumn<CalculationJobResultModel>({
                columnId: `purities.${resultParam.caS_Number}`,
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold, wordBreak: "break-word" }}>
                     <ResultOutputParameter componentCasNumber={resultParam.caS_Number} separationMethod={props.experiment.separationMethod} />                     
                    </TableCellLayout>;
                },
                renderCell: (item) => {
                    const cellParam = item.results[index];
                    return <TableCellLayout style={{ justifyContent: 'center' }}>
                        {cellParam && cellParam.value !== undefined ?
                            <SignificantFigures value={+cellParam.value} significantFigures={5} spanStyle={{ width: "100%", textAlign: "center" }} />
                            : "-"}</TableCellLayout>;
                },
            }));
        });

        return inputColumns;
    }
    React.useEffect(() => {
        //console.log("rows changed", rows);
        if (!!rows && rows.length > 0 && inputOutputColumns.length == 0) {
            const paramColumns = getInputAndResultParamColumns();
            setInputOutputColumns(paramColumns);
            const columnsResp = getColumns(paramColumns);
            setColumns(columnsResp ?? []);
        }

        if (!!rows && rows.length > 0 && parameterPickerOptions.length == 0) {
            const parameterOptions = getParameterOptions();
            setParameterPickerOptions(parameterOptions);
        }

    }, [rows]);


    const getColumns = (additionalColumns?: TableColumnDefinition<CalculationJobResultModel>[]) => {
        let columnsLocal = [
            createTableColumn<CalculationJobResultModel>({
                columnId: "sequence",
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>Sequence</TableCellLayout>;
                },
                renderCell: (item) => {
                    console.log("Render cell sequence", item.sequence);
                    return <TableCellLayout style={{ justifyContent: 'center' }}>{item.sequence}</TableCellLayout>;
                },
            }),

            createTableColumn<CalculationJobResultModel>({
                columnId: "flowsheetId",
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>Flowsheet ID</TableCellLayout>;
                },
                renderCell: (item) => {
                    return <TableCellLayout style={{ justifyContent: 'center' }}>{item.flowsheetId}</TableCellLayout>;
                },
            }),
            createTableColumn<CalculationJobResultModel>({
                columnId: "calculationStatus",
                renderHeaderCell: () => {
                    return <TableCellLayout style={{ justifyContent: 'center', fontWeight: tokens.fontWeightSemibold }}>Calculation status</TableCellLayout>;
                },
                renderCell: (item) => {
                    return <TableCellLayout style={{ justifyContent: 'center' }}><CalculationJobStatusComponent status={item.status} /></TableCellLayout>;
                },
            }),

        ];


        columnsLocal.push(...(additionalColumns ?? []));

        return columnsLocal;
    }

    const onSearchClick = () => {
        // console.log("onSearchClick", currentPage);
        if (currentPage == 1) {
            getData();
        } else {
            setCurrentPage(1);
        }
    }
    const resetFilters = () => {
        setSequence(undefined);
        setFlowsheetId(undefined);
        setCalculationStatus(-1 as any);
        setFilterParameter(null);
        setSortState({ sortColumn: "sequence", sortDirection: "ascending" });
        window.setTimeout(() => { getData(); }, 200);
    }




    // console.log("result data tab rendered.", rows);

    const totalPages = totalRows > sizePerPage ? Math.ceil(totalRows / sizePerPage) : 1;
    return <div>

        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginRight: "10px" }}>Sequence:</span>
                <div style={{ display: "flex" }}>

                    <Input
                        value={sequence?.toString() ?? ""}
                        type="number"
                        onChange={(ev, data) => { setSequence(data.value !== undefined && data.value !== "" ? +data.value : undefined); }}
                        style={{ minWidth: "100px", marginRight: "10px" }} />
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginRight: "10px" }}>Flowsheet ID:</span>
                <div style={{ display: "flex" }}>

                    <Input
                        value={flowsheetId?.toString() ?? ""}
                        type="number"
                        onChange={(ev, data) => { setFlowsheetId(data.value !== undefined && data.value !== "" ? +data.value : undefined); }}
                        style={{ minWidth: "100px", marginRight: "10px" }} />
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
                <span style={{ marginRight: "10px" }}>Calculation status:</span>
                <Select
                    style={{ minWidth: "150px" }}
                    value={calculationStatus}
                    onChange={(ev, data) => { setCalculationStatus(+data.value); }}>
                    {calculationStatusOptions.map((option) => {
                        return <option value={option.key?.toString()}>{option.text}</option>
                    })}
                </Select>

            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <CopilotFilterParameterPicker
                    value={filterParameter}
                    separationMethod={props.separationMethod}
                    options={parameterPickerOptions}
                    onChange={(data) => { setFilterParameter(data); console.log("Filter parameter data changed ", data); }} />
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
                <span style={{ marginRight: "10px" }}>Rows per page:</span>
                <Select
                    style={{ minWidth: "150px" }}
                    value={sizePerPage}
                    onChange={(ev, data) => { setSizePerPage(+data.value); }}>
                    {[5,10,25,50,100].map((option) => {
                        return <option value={option.toString()}>{option}</option>
                    })}
                </Select>

            </div>

            <div style={{ display: "flex", alignSelf: "end" }}>
                <Button appearance="primary" style={{ width: "100px" }} onClick={() => onSearchClick()}>Search</Button>
                <Button appearance="subtle" icon={<DismissFilled />} onClick={() => { resetFilters(); getData(); }} />
            </div>

        </div>

        <DataGridScrollableWrapper columnsCount={columns.length} columnWidth={200} gridHeight={props.tableHeight}>
            <DataGrid
                items={rows}
                columns={columns}
                sortable
                sortState={sortState}
                getRowId={(item: CalculationJobResultModel) => item.calculationRun_JobId}
            >
                <DataGridHeader style={{ ...DataGridScrollableHeaderStyles }} >
                    <DataGridRow selectionCell={null} style={{ alignItems: 'stretch' }}>
                        {({ renderHeaderCell, columnId }) => (
                            <DataGridHeaderCell
                                style={{ backgroundColor: getHeaderCellBackground(columnId.toString()), cursor: "pointer" }}
                                sortDirection={sortState.sortColumn == columnId ? sortState.sortDirection : undefined}
                                onClick={() => onColumnClick(columnId.toString())}
                            >{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>

                {isLoading &&
                    <Skeleton>
                        {[...Array(20)].map(() => <SkeletonItem size={40} style={{ marginTop: '4px' }} />)}
                    </Skeleton>
                }

                {!isLoading &&
                    <DataGridBody<CalculationJobResultModel>>
                        {({ item, rowId }) => (
                            <DataGridRow<CalculationJobResultModel>
                                key={rowId}
                                selectionCell={null}
                            >
                                {({ renderCell }) => (
                                    <DataGridCell>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                }
            </DataGrid>
        </DataGridScrollableWrapper>

        <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me page-item'}
            breakLinkClassName={'page-link'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            forcePage={currentPage - 1}
            onPageChange={(page) => { console.log("Selected page:", page); setCurrentPage(page.selected + 1); }}
            containerClassName={'pagination'}
            activeClassName={'active'}
        />
    </div>
});

const getHeaderCellBackground = (columnId: string) => {
    if (columnId) {
        if (columnId.startsWith('parameters.'))
            return tokens.colorPaletteDarkOrangeBackground2;
        else if (columnId.startsWith('purities.') || ["energy", "capex", "opex"].indexOf(columnId) > -1)
            return tokens.colorPaletteGreenBackground2;
    }

    return null;
}





const calculationStatusOptions: IDropdownOption[] = [
    { key: -1, text: "All" },
    { key: CalculationRunJobStatus.Calculated, text: "Converged" },
    { key: CalculationRunJobStatus.Failed, text: "Not Converged" },

];





