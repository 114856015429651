export const isValidFileName = (filename: string) => {
    return /^[\w\-._ ]+$/.test(filename);
}


export function nameof<T>(key: keyof T, instance?: T): keyof T {
    return key;
}

export interface IValidationResultData {
    globalErrors: string[],
    fieldsErrors: { [fieldName: string]: string }
}


export class ValidationResult {
    constructor(private data: IValidationResultData) { }

    isInvalid() {
        if (this.data && ((this.data.fieldsErrors && Object.keys(this.data.fieldsErrors).length > 0) || (this.data.globalErrors && this.data.globalErrors.length > 0))) {
            return true;
        } else {
            return false;
        }
    }



    getFieldValidationMessage(field: string, isFormSubmitted: boolean) {
        if (isFormSubmitted && this.data && this.data.fieldsErrors && this.data.fieldsErrors[field]) {
            return this.data.fieldsErrors[field];
        } else {
            return "";
        }
    }
    getPartialFieldValidationMessages(partialFieldName: string, isFormSubmitted: boolean) {
        if (isFormSubmitted && this.data && this.data.fieldsErrors) {
            var keyNames = Object.keys(this.data.fieldsErrors);
            var errorFields = keyNames.filter(x => x.indexOf(partialFieldName) !== -1);
            return errorFields;
        } else {
            return [];
        }
    }

    getGlobalErrors() {
        if (this.data && this.data.globalErrors) {
            return this.data.globalErrors;
        } else {
            return [];
        }
    }
}
