import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions, Label } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import FilterPicker from "../components/filter-picker/filter-picker.component";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

type NewExperimentModalProps = {
    isOpened?: boolean;
    fileId: number;
    fileUniqueIdentifier: string;
    onClose: () => void;
}

export const NewExperimentModal: React.FC<NewExperimentModalProps> = (props) => {


    const [open, setOpen] = React.useState(false);
    const [selectedFilterId, setSelectedFilterId] = React.useState<number>();
    const navigate = useNavigate();

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const onConfirmClick = () => {
        navigate(`/files/${props.fileUniqueIdentifier}/doe/edit?filterId=${selectedFilterId}`);
    }

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }

    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose}
                    icon={<Dismiss24Filled />} />}>New Experiment</DialogTitle>
                <DialogContent>
                    <div className="input-form">
                        <div className="input-form-item">
                            <Label className="input-form-label">
                                Filter:
                            </Label>
                            <div style={{ display: "flex", flexDirection:"column" }}>
                                <FilterPicker
                                    className="input-form-field"
                                    filterId={selectedFilterId}
                                    fileId={props.fileId}
                                    onChange={(filterId: number) => setSelectedFilterId(filterId)}
                                    style={{ width: "100%" }}
                                />
                                <small>Select filter or create new one <Link to={`/files/${props.fileUniqueIdentifier}/filters/edit/`}>here</Link>.</small>
                            </div>


                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button appearance="primary" onClick={() => onConfirmClick()} disabled={!selectedFilterId} >Select</Button>
                    <Button appearance="secondary" onClick={onModalClose}>Cancel</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}