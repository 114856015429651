import { FlowsheetObjectsResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { FilterParameterType, InputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { SensitivityStudyResultModel, StudyInputParamterType, StudyParamterRange, StudyStep, StudyStepStatus } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { CustomStudyInputParameterType, SensitivityStudy, SensitivityStudyInputParameter } from "../edit-sensitivity-study/edit-sensitivity-study.interfaces";
import { getMinMaxTotalVariationsCount, getStepsBeforeAfterVariationsCount } from "./total-variations-calculator";

export const getCompletedJobsText = (study: SensitivityStudyResultModel): string => {

    if (!study) {
        return "";
    }
    const { calculatedRows, totalJobs, failedRows } = study;
    if (!totalJobs) return "-";

    const calculatedPercentage = Number(totalJobs) > 0 ?
        Math.round(((Number(calculatedRows) + Number(failedRows)) / Number(totalJobs)) * 100) : 100;

    return `${calculatedRows}/${failedRows} (${calculatedPercentage}%)`;
}

export function roundIfGreatherThanOne(value: number) {
    if (Math.abs(value) < 1) {
        return value;
    } else {
        return parseFloat(value.toFixed(4));
    }
}

export const calculateVariationsAndJobs = (study: SensitivityStudy, sensitivityStudyInputParameters: SensitivityStudyInputParameter[]): SensitivityStudy => {



    const updatedParams = sensitivityStudyInputParameters.map(inputParam => {
        if (study.studyParamterRange == StudyParamterRange.MinMax && inputParam.valueType == StudyInputParamterType.Range) {
            if (inputParam.minValue && inputParam.maxValue && inputParam.stepSize) {
                const totalVariations = getMinMaxTotalVariationsCount(inputParam.minValue, inputParam.maxValue, inputParam.stepSize);
                return { ...inputParam, totalVariations: totalVariations } as unknown as SensitivityStudyInputParameter;
            }
        }
        if (study.studyParamterRange == StudyParamterRange.InitialValueWithSteps && inputParam.valueType == StudyInputParamterType.Range) {
            if (inputParam.stepsBefore && inputParam.stepsAfter) {
                const totalVariations = getStepsBeforeAfterVariationsCount(inputParam.stepsBefore, inputParam.stepsAfter);
                return { ...inputParam, totalVariations: totalVariations } as unknown as SensitivityStudyInputParameter;
            }
        }
        return { ...inputParam, totalVariations: undefined } as unknown as SensitivityStudyInputParameter;
    });

    var totalJobs = 0;
    updatedParams.forEach(inputParam => {
        if (inputParam.totalVariations) {
            if (totalJobs == 0) {
                totalJobs = inputParam.totalVariations;
            } else {
                totalJobs = totalJobs * inputParam.totalVariations;
            }

        }

    });

    return { ...study, totalJobs: totalJobs, sensitivityStudyInputParameters: updatedParams } as SensitivityStudy;

}


export function MapFilterParameterTypeToCustomStudyInputParameterType(filterparameterType: FilterParameterType): CustomStudyInputParameterType {

    switch (filterparameterType) {
        case FilterParameterType.ExpectedInput:
            return CustomStudyInputParameterType.Range;
        case FilterParameterType.ExpectedOutput:
            return CustomStudyInputParameterType.Range;
        case FilterParameterType.FixedValue:
            return CustomStudyInputParameterType.FixedFilterValue;
        case FilterParameterType.Formula:
            return CustomStudyInputParameterType.FilterFormula;
        default:
            return CustomStudyInputParameterType.Range;
    }
}

export function GetStudyParamValueFromFilterParam(filter: InputFilterParameterResultModel) {
    switch (filter.parameterType) {
        case FilterParameterType.FixedValue:
            return filter.fixedValue;
        case FilterParameterType.Formula:
            return filter.formula;
        default:
            return undefined;
    }
}
export function GetStudyParamValueFromFlowsheet(filter: InputFilterParameterResultModel, flowsheetObjects: FlowsheetObjectsResponseModel): IFlowsheetInputParamValue | undefined {
    console.log("GetStudyParamValueFromFlowsheet", filter, flowsheetObjects);
    const flowsheetParam = flowsheetObjects?.flowsheetParameters?.find(x => x.parameter == filter.name);
    if (!flowsheetParam) return undefined;
    return { value: flowsheetParam.value, unit: flowsheetParam.unit } as IFlowsheetInputParamValue;
}

interface IFlowsheetInputParamValue {
    value?: number;
    unit?: string;
}


export const GetStudyStepText = (studyStep: StudyStep): string => {

    switch (studyStep) {
        case StudyStep.CalculateInitialJob:
            return "Analysis";
        case StudyStep.CalculateJobs:
            return "Calculate jobs";
        case StudyStep.CreateJobs:
            return "Analysis";
        default:
            return "";
    }
}

export const GetStudyStepStatusText = (studyStepStatus: StudyStepStatus): string => {

    switch (studyStepStatus) {
        case StudyStepStatus.Failed:
            return "Failed";
        case StudyStepStatus.InProgress:
            return "In progress";
        case StudyStepStatus.Ready:
            return "Not calculated";
        case StudyStepStatus.Stopped:
            return "Stopped";
        case StudyStepStatus.SuccessfullyFinished:
            return "Successfully calculated";  
        default:
            return "";
    }
}