import { Button, Checkbox, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Label } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { toast } from "react-toastify";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { getFilesClient, getFiltersClient, getUserToken } from "../../services/dashboard.service";
import { getStudiesCountClient } from "../../services/sensitivity-studies.service";
import { GLOBAL_STYLES } from "../../styles";
import { FileSystemEntityType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { StudyType } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { excelExtensions, flowsheetExtensions } from "../../global.variables";
import { getFileExtension } from "../file-type-icon/file-type-icon.helpers";
import { getScenarioCountClient } from "../../services/excel-runner.service";
import { ScenarioCountPostModel } from "../../swagger-clients/excel-runner-api-clients.service";
import { getExamCountClient } from "../../services/take-home-exams.service";
import { ExamCountPostModel } from "../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { getExperimentsCountClient } from "../../services/ai-for-pfd.service";
import { ExperimentCountPostModel } from "../../swagger-clients/ai-for-pfd-clients.service";

type DeleteFileModalProps = {
    uniqueFileId: string,
    name: string,
    isFolder: boolean,
    fileType: FileSystemEntityType,
    isOpened?: boolean,
    onSuccess: () => void,
    onClose?: () => void
};


export const DeleteFileModal: React.FC<DeleteFileModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [isLoading, loadingService] = useLoading();
    const [filtersCount, setFiltersCount] = React.useState<number>();
    const [sensitivityStudiesCount, setSensitvityStudiesCount] = React.useState<number>();
    const [optimumTestsCount, setOptimumTestsCount] = React.useState<number>();
    const [copilotExperimentsCount, setCopilotExperimentsCount] = React.useState<number>();
    const [scenarioCount, setScenarioCount] = React.useState<number>();
    const [takeHomeExamsCount, setTakeHomeExamsCount] = React.useState<number>();
    const [gaveConsent, setGaveConsent] = React.useState<boolean>(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const isFlowsheet = flowsheetExtensions.indexOf(getFileExtension(props.name)) > -1;
    const isExcelFile = excelExtensions.indexOf(getFileExtension(props.name)) > -1;

    React.useEffect(() => {

        if (isFlowsheet && props.isOpened) {

            getFiltersCount();
            getStudiesCount(StudyType.SensitivityStudy);
            getStudiesCount(StudyType.OptimumTest);
            getCopilotExperimentsCount();
        }
        if (isExcelFile && props.isOpened) {
            getScenariosCount();
            getTakeHomeExamsCount();
        }



    },
        [props.fileType, props.uniqueFileId, props.isOpened]);

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    const getTakeHomeExamsCount = () => {
        loadingService.showLoading("Loading take home exams count...", async (hideMessage) => {
            try {
                let client = getExamCountClient();

                const examsCountResp = await client.getExamCount(new ExamCountPostModel({ driveItemIds: [props.uniqueFileId] }))
                if (!!examsCountResp && examsCountResp.length > 0) {
                    setTakeHomeExamsCount(examsCountResp[0].count);
                } else {
                    setTakeHomeExamsCount(0);
                }
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting Take home exams count.");
            } finally {
                hideMessage();
            }
        });

    }

    const getScenariosCount = () => {
        loadingService.showLoading("Loading scenarios count...", async (hideMessage) => {
            try {
                const client = getScenarioCountClient();
                const accessToken = await getUserToken();
                const model = new ScenarioCountPostModel({ accessToken: accessToken, driveItemIds: [props.uniqueFileId] });
                const scenariosCountResp = await client.getExcelScenarioCount(model);
                if (!!scenariosCountResp && scenariosCountResp.length > 0) {
                    setScenarioCount(scenariosCountResp[0].count);
                } else {
                    setScenarioCount(0);
                }
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting DoE scenarios count.");
            } finally {
                hideMessage();
            }
        });

    }


    const getCopilotExperimentsCount = () => {

        loadingService.showLoading("Loading copilot experiments count...", async (hideMessage) => {
            try {
                const client = getExperimentsCountClient();

                const experimentsCountResp = await client.getExperimentCount(new ExperimentCountPostModel({ fileUniqueIds: [props.uniqueFileId] }));
                if (!!experimentsCountResp && experimentsCountResp.length > 0) {
                    setCopilotExperimentsCount(experimentsCountResp[0].count);
                } else {
                    setCopilotExperimentsCount(0);
                }
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting Copilot experiments count.");
            } finally {
                hideMessage();
            }
        });

    }

    const getFiltersCount = async () => {
        loadingService.showLoading("Loading filters count...", async (hideMessage) => {
            try {
                const client = getFiltersClient();
                const filterCountResp = await client.getFiltersCount(props.uniqueFileId);
                setFiltersCount(filterCountResp ?? 0);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting filters count.");
            } finally {
                hideMessage();
            }

        });
    }

    const getStudiesCount = async (studyType: StudyType) => {
        const studyTypeName = studyType == StudyType.SensitivityStudy ? "sensitivity studies" : "optimum tests";
        loadingService.showLoading(`Loading ${studyTypeName} count...`, async (hideMessage) => {
            try {
                const client = getStudiesCountClient();
                const studyCountResp = await client.getIndividualFlowsheetStudiesCount(props.uniqueFileId, studyType);
                if (studyType == StudyType.SensitivityStudy) {
                    setSensitvityStudiesCount(+studyCountResp ?? 0);
                } else {
                    setOptimumTestsCount(+studyCountResp ?? 0);
                }
            } catch (error) {
                processServerError(error, undefined, `An error occurred while getting ${studyTypeName} count.`);
            } finally {
                hideMessage();
            }

        });

    }
    const showFlowsheetFields = () => {
        console.log("showFlowsheetFields", isLoading, filtersCount, sensitivityStudiesCount, optimumTestsCount);
        if (isLoading === false && (!!filtersCount != false || !!sensitivityStudiesCount != false || !!optimumTestsCount != false)) {
            console.log("showFlowsheetFields", true);
            return true;
        }
        return false;
    }

    const showExcelFileFields = () => {
        if (isLoading === false && (!!scenarioCount != false || !!takeHomeExamsCount != false)) {

            return true;
        }
        return false;
    }

    const onRemoveClick = async () => {
        loadingService.showLoading("Removing file...", async (hideMessage) => {
            const fileTypeName = props.isFolder ? "folder" : "file";
            try {
                const client = getFilesClient();
                await client.deleteFile(props.uniqueFileId, props.isFolder ? FileSystemEntityType.Directory : FileSystemEntityType.File);
                toast.success(`Successfully removed ${fileTypeName}.`);
                props.onSuccess();
            } catch (error) {
                processServerError(error, undefined, `An error occurred while removing ${fileTypeName}`);
            } finally {
                hideMessage();
            }

        });

    }

    const ErrorLoadingData = () => {
        return <span style={{ color: "var(--colorStatusDangerBackground3)" }}>Error loading data</span>;
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (data.open == false) {
            if (props.onClose)
                props.onClose();
        }
    }}>
        <DialogSurface>
            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Remove</DialogTitle>
                <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                    <h3 style={{ marginTop: "0", fontWeight: "var(--fontWeightMedium)" }}>Are you sure you want to remove <b>'{props.name}'</b>?</h3>

                    {!isLoading && (showFlowsheetFields() || showExcelFileFields()) && <div>
                        <p>This file contains additional data:</p>
                        <div className="input-form">
                            {isFlowsheet && <>
                                <div className="input-form-item">
                                    <Label className="input-label">Filters:</Label>
                                    <span style={{ marginLeft: "10px" }}>{filtersCount !== undefined ? filtersCount : <ErrorLoadingData />}</span>
                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Multivariate Sensitivity Studies:</Label>
                                    <span style={{ marginLeft: "10px" }}>{sensitivityStudiesCount !== undefined ? sensitivityStudiesCount : <ErrorLoadingData />}</span>
                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Optimum Tests:</Label>
                                    <span style={{ marginLeft: "10px" }}>{optimumTestsCount !== undefined ? optimumTestsCount : <ErrorLoadingData />}</span>
                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Copilot Experiments:</Label>
                                    <span style={{ marginLeft: "10px" }}>{copilotExperimentsCount !== undefined ? copilotExperimentsCount : <ErrorLoadingData />}</span>
                                </div>
                            </>
                            }

                            {isExcelFile && <>

                                <div className="input-form-item">
                                    <Label className="input-label">Design of Experiments:</Label>
                                    <span style={{ marginLeft: "10px" }}>{scenarioCount !== undefined ? scenarioCount : <ErrorLoadingData />}</span>
                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Take-Home Exercises/Exams:</Label>
                                    <span style={{ marginLeft: "10px" }}>{takeHomeExamsCount !== undefined ? takeHomeExamsCount : <ErrorLoadingData />}</span>
                                </div>
                               

                            </>
                            }

                            <div className="input-form-checkbox">
                                <Checkbox
                                    onChange={(ev, data) => setGaveConsent(!!data.checked)}
                                    label="I am aware that all related data will be removed."
                                />
                            </div>
                            <div style={{ marginTop: "10px", fontStyle: "italic" }}>
                                <small>Note: You can access and restore your file including your data for 30 days from the recycle bin.</small>
                            </div>


                        </div>
                    </div>}


                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        isLoading={isLoading}
                        disabled={(isFlowsheet && showFlowsheetFields() && !gaveConsent) || (isExcelFile && showExcelFileFields() && !gaveConsent)}
                        appearance="primary"
                        type="button"
                        onClick={() => onRemoveClick()}
                    >Remove</LoadingButton>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}