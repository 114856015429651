import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import * as React from "react";
import { loginRequest } from "../auth/authConfig";
import { WelcomePage } from "../pages/welcome/welcome.page";
import { HubConnection } from "@microsoft/signalr";
import { Button } from "@fluentui/react-components";
import sidebarLogo from '../assets/sidebar-logo.png';
type HomePageProps = {
    hubConnection: HubConnection;
}

export const Home: React.FC<HomePageProps> = (props) => {

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();


    React.useEffect(() => {
        if (!isAuthenticated) {
            checkIfUserIsLoggedIn();
        }

    }, []);

    const checkIfUserIsLoggedIn = async () => {
        try {
            const account = instance.getAllAccounts()[0];
            console.log("checkIfUserIsLoggedIn instance:", instance);
            const accessTokenRequest = {
                scopes: ["user.read"],
                account: account,
            };

            const userToken = await instance.ssoSilent(loginRequest);
            console.log("userTokenResp", userToken);

        } catch (error) {
            console.log("checkIfUserIsLoggedIn error:", error);
        }

    }

    // if (!isAuthenticated) {
    //     instance.loginRedirect(loginRequest);
    // }

    const onLoginClick = () => {
        instance.loginRedirect(loginRequest);
    }

    const onRegisterClick = () => {
        window.open('https://simulate365.com/registration/', "_blank");
    }

    return <>
        <div style={{
            zIndex: "1000", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "grey", opacity: 0.5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
        </div>
        <div style={{
            zIndex: "1001", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <div style={{ backgroundColor: "white", opacity: 1, maxWidth: "25vw", minWidth:"400px", border: "1px solid black", padding: "20px", }}>
                <div style={{ backgroundImage: `url(${sidebarLogo})`, height: "120px", backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
                <div style={{ margin: "25px 10px" }}>
                    <p >You need to have an account on simulate365.com to use <b>Simulate 365 Dashboard</b> and our applications.</p>
                    <p>Log in with your Simulate 365 username and password.</p>
                </div>

                <div style={{ display: "flex", flexDirection: "row", justifyItems: "center", gap: "15px", zIndex: "99991" }}>
                    <Button size="large" appearance="outline" style={{ flex: 1 }} onClick={onRegisterClick}>Register</Button>
                    <Button size="large" appearance="outline" style={{ flex: 1 }} onClick={onLoginClick}>Log in</Button>
                </div>
            </div>
        </div>
        <WelcomePage hubConnection={props.hubConnection} />
    </>;
}

export default Home;