import { Link, TableColumnDefinition, TableColumnSizingOptions, Tooltip, createTableColumn, useTableColumnSizing_unstable, useTableFeatures } from "@fluentui/react-components";
import { Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { Guid } from "js-guid";
import React, { useMemo } from "react";
import { DispatcherJobDetails, DispatcherJobDetailsType } from "../../../components/dispatcher-job-details/dispatcher-job-details.component";
import JobStatus from "../../../components/job-status/job-status.component";
import JobTypeComponent from "../../../components/job-type/job-type.component";
import ShowLocalTime from "../../../components/show-local-time/show-local-time.component";
import { TableBodyWithLoading } from "../../../components/table-body-with-loading/table-body-with-loading.component";
import { getActiveJobsClient } from "../../../services/dispatcher.service";
import { JobProcessingStatus, ActiveJobResponseModel, JobExecutionStatus } from "../../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { useLoading } from "../../../utils/loading-indicator.component";
import { MapActiveJobsShallowCopy } from "../active-agents-table/utilities/activejob-mapper";
import TimeAgo from 'react-timeago';
import { ApplicationDetailsModal } from "../../../components/application-details-modal/application-details-modal.component";
import { FileDisplayName } from "../../../components/file-display-name/file-display-name.component";
import JobExecutionStatusComponent from "../../../components/job-execution-status/job-execution-status.component";
import { S365TimeAgo } from "../../../components/s365-time-ago/s365-time-ago.component";

type DispatcherJobsTableProps = {
    onJobsChanged: (jobs: ActiveJob[]) => void;
}

export type ActiveJob = {
    jobId: string;
    status?: JobProcessingStatus;
    executions: ActiveJobResponseModel[];
}

export type DispatcherJobsTableType = {
    onJobUpdated: (job: ActiveJobResponseModel) => void;
}

type ActiveJobTableItem = {
    key: string;
    jobId: string;
    executionJobId: string;
    jobStatus: number;
    executionStatus: JobExecutionStatus;
    createdAt: Date;
    flowsheet: string;
    flowsheetId?: string;
    flowsheetVersionNumber?: string;
    jobType: number;
    priority: number;
    application: string;
    applicationId: number;
    assignedToAgent: string;
    assignedAt: Date;
}

export const DispatcherJobsTable = React.forwardRef<DispatcherJobsTableType, DispatcherJobsTableProps>((props, ref) => {
    React.useImperativeHandle(
        ref,
        () => ({
            onJobUpdated(job: ActiveJobResponseModel) {
                onJobUpdated(job);
            }
        }));

    const refreshDataIntervalRef = React.useRef<number>();
    const [activeJobs, setActiveJobs] = React.useState<ActiveJob[]>([]);
    const [isLoading, loadingService] = useLoading();
    const [showJobDetailsModal, setShowJobDetailsModal] = React.useState<boolean>(false);
    const [selectedDispatcherJobId, setSelectedDispatcherJobId] = React.useState<number>();
    const [selectedApplicationId, setSelectedApplicationId] = React.useState<number>();
    const [showApplicationDetails, setShowApplicationDetails] = React.useState<boolean>(false);

    const dispatcherJobDetailsRef = React.useRef<DispatcherJobDetailsType>();

    React.useEffect(() => {
        getActiveJobsAsync();
        refreshDataIntervalRef.current = window.setInterval(() => {
            getActiveJobsAsync();
        }, 30000);

        // This function will be called when the component is unmounted
        return () => {
            console.log("Clear interval called");
            clearInterval(refreshDataIntervalRef.current);
        };
    }, []);


    const getActiveJobsAsync = async () => {
        loadingService.showLoading("Loading jobs...", async (hideMessage) => {
            try {
                const jobs = [...activeJobs];

                const client = getActiveJobsClient();

                const resp = await client.getActiveJobs();
                //      console.log("Active jobs", activeJobs);
                const groupedJobs = groupJobsById(resp);

                MapActiveJobsShallowCopy(groupedJobs, jobs);
                //  console.log("Grouped jobs:", groupedJobs);
                setActiveJobs(jobs);
                props.onJobsChanged(jobs);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get Active jobs.");

            } finally {
                hideMessage();
            }
        });

    }

    const groupJobsById = (activeJobs: ActiveJobResponseModel[]): ActiveJob[] => {

        var grouped = activeJobs.reduce((prev, curr) => {
            if (!prev[curr.job.id]) {
                prev[curr.job.id] = [curr];
            } else {
                prev[curr.job.id].push(curr);
            }
            return prev;
        }, {});

        var result = Object.keys(grouped).map((key) => ({ jobId: key, executions: grouped[key] } as ActiveJob));
        return result;
    }


    const onJobUpdated = (job: ActiveJobResponseModel) => {
        try {

            if (dispatcherJobDetailsRef.current) {
                dispatcherJobDetailsRef.current.externalReload(job.job.id);
            }
            const existingJob = activeJobs.find(x => x.jobId == job.job.id.toString());
            //if job exists update it
            if (existingJob) {
                //     console.log(`Job with id ${existingJob.jobId} exists.`);
                const updatedActiveJobs: ActiveJob[] = activeJobs.map(oldJob => {

                    if (oldJob.jobId == existingJob.jobId.toString()) {
                        //       console.log(`Job with id ${existingJob.jobId} found.`, oldJob);
                        let updatedJob = { jobId: oldJob.jobId, status: job.job.status } as ActiveJob;
                        const execution = oldJob.executions.find(oldExecution => oldExecution.job.id == job.job.id &&
                            oldExecution.jobExecution.id == job.jobExecution.id);
                        if (execution) {
                            //         console.log(`Job execution found`,execution);
                            //if execution exists update it
                            updatedJob.executions = oldJob.executions.map(oldExecution => {
                                if (oldExecution.job.id == job.job.id && oldExecution.jobExecution.id == job.jobExecution.id) {
                                    return job;
                                }
                                return oldExecution;
                            });
                        } else {
                            //        console.log("Job execution not found, adding new.");
                            //if execution does not exist add it
                            updatedJob.executions = [...oldJob.executions, job];
                        }


                        return updatedJob;
                    }
                    return oldJob;
                });

                setActiveJobs(updatedActiveJobs);
                props.onJobsChanged(updatedActiveJobs);

            } else {
                // if job  does not exist add it
                // console.log("Job not found", jobs, job);
                const newActiveJob = { jobId: job.job.id.toString(), executions: [job] } as ActiveJob;
                const updatedJobs = [...activeJobs, newActiveJob];
                setActiveJobs(updatedJobs);
                props.onJobsChanged(updatedJobs);
            }
        } catch (error) {
            console.log("An error ocurred while trying to add/update job.", error);
        }
    }

    const mapResponseModelToTableItem = (response: ActiveJobResponseModel, isJob: boolean = false): ActiveJobTableItem => {
        var jobTableItem = {} as ActiveJobTableItem;
        if (isJob && isJob == true) {
            jobTableItem.key = response.job ? response.job.id.toString() : Guid.newGuid().toString();
            jobTableItem.jobId = response.job ? response.job.id.toString() : undefined;
            jobTableItem.jobStatus = response.job ? response.job.status : null;
            jobTableItem.application = response.application ? response.application.name : "-";
            jobTableItem.applicationId = response.application ? response.application.id : undefined;
            jobTableItem.jobType = response.jobType ? response.jobType : null;
            jobTableItem.flowsheet = response.flowsheet ? response.flowsheet.name : "";
            jobTableItem.flowsheetId = response.flowsheet ? response.flowsheet.driveItemId : undefined;
            jobTableItem.flowsheetVersionNumber = response.flowsheet ? response.flowsheet.driveItemVersion : undefined;

        } else {
            jobTableItem.key = response.activeJobId + response.jobExecution ? response.jobExecution.id.toString() : "";
            jobTableItem.jobId = response.job ? response.job.id.toString() : undefined;
            jobTableItem.jobStatus = response.job ? response.job.status : null;
            jobTableItem.executionJobId = response.jobExecution ? response.jobExecution.id.toString() : "";
            jobTableItem.executionStatus = response.jobExecution ? response.jobExecution.status : null;
            jobTableItem.assignedAt = response.agent ? response.agent.assignedAt : undefined;
            jobTableItem.assignedToAgent = response.agent ? response.agent.name : undefined;
            jobTableItem.priority = response.priority;
            jobTableItem.createdAt = response.createdAt;
            jobTableItem.application = response.application ? response.application.name : "-";
            jobTableItem.applicationId = response.application ? response.application.id : undefined;
            jobTableItem.jobType = response.jobType ? response.jobType : null;
            jobTableItem.flowsheet = response.flowsheet ? response.flowsheet.name : "";
            jobTableItem.flowsheetId = response.flowsheet ? response.flowsheet.driveItemId : undefined;
            jobTableItem.flowsheetVersionNumber = response.flowsheet ? response.flowsheet.driveItemVersion : undefined;
        }

        return jobTableItem;
    }


    const getColumns = (): TableColumnDefinition<ActiveJobTableItem>[] => [
        createTableColumn<ActiveJobTableItem>({
            columnId: "jobId",
            renderHeaderCell: () => <div style={{ textAlign: "center" }}>Job ID/ Execution ID</div>,
            renderCell: (item: ActiveJobTableItem) => {

                return <span># <Tooltip withArrow relationship="description" content={
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <span>Job # {item.jobId}</span>
                        <JobStatus value={item.jobStatus} />
                    </div>}>
                    <Link
                        onClick={() => { setSelectedDispatcherJobId(+item.jobId); setShowJobDetailsModal(true); }}>{item.jobId}</Link>
                </Tooltip>
                    .{item.executionJobId}</span>

            }
        }),
        createTableColumn<ActiveJobTableItem>({
            columnId: "status",
            renderHeaderCell: () => <>Status</>,
            renderCell: (item: ActiveJobTableItem) => {

                return <>{item.executionStatus!==undefined && <JobExecutionStatusComponent value={item.executionStatus} />}</> ;

            }
        }),
        createTableColumn<ActiveJobTableItem>({
            columnId: "createdAt",
            renderHeaderCell: () => <>Created At</>,
            renderCell: (item: ActiveJobTableItem) => {

                return <>{item.createdAt && <S365TimeAgo date={item.createdAt} maxPeriod={1} />} </>;

            }
        }),
        createTableColumn<ActiveJobTableItem>({
            columnId: "flowsheet",
            renderHeaderCell: () => <>Flowsheet</>,
            renderCell: (item: ActiveJobTableItem) => {

                return <FileDisplayName fileUniqueIdentifier={item.flowsheetId} fileVersionNumber={+item.flowsheetVersionNumber} />;

            }
        }),
        createTableColumn<ActiveJobTableItem>({
            columnId: "jobType",
            renderHeaderCell: () => <>Job type</>,
            renderCell: (item: ActiveJobTableItem) => {

                return <JobTypeComponent value={item.jobType} />;
            }
        }),
        createTableColumn<ActiveJobTableItem>({
            columnId: "priority",
            renderHeaderCell: () => <>Priority</>,
            renderCell: (item: ActiveJobTableItem) => {

                return item.priority;
            }
        }),        
        createTableColumn<ActiveJobTableItem>({
            columnId: "assignedToAgent",
            renderHeaderCell: () => <>Assigned to Agent</>,
            renderCell: (item: ActiveJobTableItem) => {

                return item.assignedToAgent;
            }
        }), createTableColumn<ActiveJobTableItem>({
            columnId: "assignedAt",
            renderHeaderCell: () => <>Assigned At</>,
            renderCell: (item: ActiveJobTableItem) => {

                return <>{item.assignedAt && <S365TimeAgo date={item.assignedAt} maxPeriod={1} />}</>;
            }
        })

    ];



    const getTableItems = () => {
        let itemsLocal: ActiveJobTableItem[] = [];
        for (const job of activeJobs) {
          
            for (const execution of job.executions) {
                const executionTableItem = mapResponseModelToTableItem(execution);
                itemsLocal.push(executionTableItem);
            }

        }
        return itemsLocal;
    }
    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        jobId: { 
            minWidth: 50,
            defaultWidth: 150,           
        },
        status: {
            minWidth: 50,
            defaultWidth:100,
        },
        createdAt: {
            minWidth: 100,
            defaultWidth: 100,
        },
        flowsheet: {
            minWidth: 100,
            defaultWidth: 300,
        },
        jobType: {
            minWidth: 100,
            defaultWidth: 100
        },
        priority: {
            minWidth: 50,
            defaultWidth: 80
        },
        application: {
            minWidth: 100,
            defaultWidth: 200
        }, 
        assignedToAgent: {
            minWidth: 100,
            defaultWidth: 200
        },
        assignedAt:{
            minWidth: 100,
            defaultWidth: 100
        }
    });

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "status": return true;
            case "createdAt": return true;   
            case "jobType": return true;
            case "priority": return true;     
            case "application": return true;
            case "assignedToAgent": return true;   
            case "assignedAt": return true;          

            default: return false;
        }
    }

    const items = useMemo(() => getTableItems(), [activeJobs]);
    const columns = useMemo(() => getColumns(), []);

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<ActiveJobTableItem>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );


    return <div className='s365-table__wrapper'>
        <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
            <TableHeader>
                <TableRow>
                {columns.map((column) => (
                            <TableHeaderCell
                            onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                                className={`${isColumnCentered(column.columnId as string) ? 'column--center' : ''} s365-table__cell--bold`}                              
                                
                                key={column.columnId}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}
                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                </TableRow>
            </TableHeader>

            <TableBodyWithLoading isLoading={isLoading}
                columnCount={9} loadingMessage="Loading..."
                itemCount={activeJobs ? activeJobs.length : 0}
                noItemsMessage="No jobs found.">
                {items && items.length > 0 && items.map((item) => {
                    
                                               
                            return <TableRow key={`job-${item.jobId}-${item.executionJobId}`}>
                                {columns.map((column) => (
                                <TableCell
                                    className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                            </TableRow>                      
                   
                })}
            </TableBodyWithLoading>

        </Table>

        {
            showJobDetailsModal && selectedDispatcherJobId &&
            <DispatcherJobDetails
                ref={dispatcherJobDetailsRef}
                jobId={selectedDispatcherJobId}
                isOpened={true}
                onClose={() => setShowJobDetailsModal(false)}
            />
        }
       
    </div >
});


