import React from "react";
import { GLOBAL_STYLES } from "../../styles";
import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Label, Tab, TabList } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { getExperimentElasticSearchClient, getExperimentsClient } from "../../services/ai-for-pfd.service";
import { CalculationJobResultModel, ExperimentResponseModel } from "../../swagger-clients/ai-for-pfd-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { CalculationJobStatusComponent } from "../details-experiment/result-data/calculation-job-status.component";
import { CalculationDetailTab } from "./calculation-details-tab.component";
import { CalculationInputsTab } from "./calculation-inputs-tab.component";
import { CalculationResultTab } from "./calculation-result-tab.component";

type CalculationJobDetailsModalProps = {
    sequence: number;
    flowsheetId: number;
    experimentId: number;
    experimentVersionId: number;
    uniquefileId: string;
    isOpened?: boolean;
    onClose?: () => void;
}
export const CalculationJobDetailsModal: React.FC<CalculationJobDetailsModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [experiment, setExperiment] = React.useState<ExperimentResponseModel>();
    const [calculationJob, setCalculationJob] = React.useState<CalculationJobResultModel>();
    const [selectedTab, setSelectedTab] = React.useState<string>("details");

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    React.useEffect(() => {
        getExperiment();
        getCalculationJob();
    }, []);

    const getExperiment = async () => {
        try {
            const client = getExperimentsClient();
            const experimentResp = await client.getExperiment(props.uniquefileId, props.experimentId);
            setExperiment(experimentResp);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting experiment.");
        }
    }

    const getCalculationJob = async () => {

        try {
            const client = getExperimentElasticSearchClient();
            const calculationJobResp = await client.getCalculationJobDetails(props.experimentVersionId, props.sequence, props.flowsheetId);
            setCalculationJob(calculationJobResp);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting calculation job.");
        }
    }



    const onClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }

    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "60vw" }}>

            <DialogBody style={{ height: "80vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onClose} icon={<Dismiss24Filled />} />}>
                    Experiment {props.sequence}_{props.flowsheetId}
                </DialogTitle>
                <DialogContent>

                    <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { setSelectedTab(data.value as string); }}>
                        <Tab key="details" value="details">Details</Tab>
                        <Tab key="inputs" value="inputs" >Inputs</Tab>
                        <Tab key="result" value="result"  >Result</Tab>
                    </TabList>
                    <div className="tab-content">
                        {selectedTab == "details" && experiment && calculationJob && <CalculationDetailTab experiment={experiment} calculationJob={calculationJob} />}
                        {selectedTab == "inputs" && experiment && calculationJob && <CalculationInputsTab experiment={experiment} calculationJob={calculationJob} />}
                        {selectedTab == "result" && experiment && calculationJob && <CalculationResultTab experiment={experiment} calculationJob={calculationJob} />}


                    </div>
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}