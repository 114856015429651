import { Button } from "@fluentui/react-components";
import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { toast } from "react-toastify";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { getSensitivityStudiesClient } from "../../services/sensitivity-studies.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { SensitivityStudyResultModel } from "../../swagger-clients/sensitivity-studies-api-clients.service";


type DeleteSensitivityStudyModalProps = {
    study: SensitivityStudyResultModel;
    isOpened?: boolean,
    isOptimumTester?: boolean,
    onSuccess: () => void;
    onClose: () => void;
};

export const DeleteSensitivityStudyModal: React.FC<DeleteSensitivityStudyModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);



    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    const onDeleteClick = async () => {
        try {
            const client = getSensitivityStudiesClient();
            await client.deleteStudy(props.study.id);
            toast.success(`Successfully archived ${props.isOptimumTester ? "Optimum Test" : "Sensitivity Study"}.`);
            props.onSuccess();

        } catch (error) {
            processServerError(error, undefined, `An error occurred while archiving ${props.isOptimumTester ? "Optimum Test" : "Sensitivity Study"}. `);

        } finally {
            setIsRequestInProgress(false);
        }
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }

    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                    Archive {props.isOptimumTester ? "optimum test" : "sensitivity study"}
                </DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to archive '{props.study?.name}' {props.isOptimumTester ? "optimum test" : "sensitivity study"}? </p>
                </DialogContent>
                <DialogActions>
                    <LoadingButton isLoading={isRequestInProgress} appearance="primary" onClick={onDeleteClick}>Archive</LoadingButton>
                    <Button appearance="secondary" onClick={props.onClose}>Cancel</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}