import React from "react";
import { SaveFileModal, SaveFileModel } from "../../components/save-file-modal/save-file-modal.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { getDoeExportDataClient } from "../../services/excel-runner.service";
import { GenerateExcelRequestModel } from "../../swagger-clients/excel-runner-api-clients.service";
import { DoeFileGenerationProgressModal } from "./file-generation-progress-modal.component";


type ExportDataExcelProps = {
    flowsheetParentDirectoryUniqueId?: string;
    isVisible: boolean;
    scenarioId: number;
    defaultName: string;
    includeData?: boolean;
    includeCovergedData?: boolean;
    onVisibleChanged: (isVisible) => void;
}

export const ExportDoeDataExcel: React.FC<ExportDataExcelProps> = (props) => {

    const [showProgressModal, setShowProgressModal] = React.useState<boolean>(false);
    const [requestId, setRequestId] = React.useState<string>();
    const generateExcelFile = async (data: SaveFileModel) => {
        try {
            const client = getDoeExportDataClient();
            const genrationResp = await client.generateExcel(new GenerateExcelRequestModel({
                filename: data.name,
                scenarioId: props.scenarioId,
                parentDirectoryUniqueId: data.parentDirectoryUniqueId,
                uploadConflictAction: data.conflictAction,
                writeResults: props.includeData,
                includeConvergedResults: props.includeCovergedData

            }));
            setRequestId(genrationResp?.requestId);
            setShowProgressModal(true);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while exporting data to Excel file.");
        }

    }
    const getModalTitle = () => {
        if (props.includeData) {
            return "Export all data to Excel";
        }
        if (props.includeCovergedData) {
            return "Export converged data to Excel";
        }
        if (!props.includeData && !props.includeCovergedData) {
            return "Export input data to Excel";
        }
    }

    const modalTitle = getModalTitle();

    return <>
        {props.isVisible &&
            <SaveFileModal
                isOpened={true}
                modalTitle={modalTitle}
                defaultName={props.defaultName}
                fileExtension="xlsx"
                fileExtensionText="Excel Workbook (*.xlsx)"
                parentDirectoryUniqueId={props.flowsheetParentDirectoryUniqueId}
                onHide={() => { props.onVisibleChanged(false); }}
                onSuccess={generateExcelFile} />}

        {requestId && showProgressModal &&
            <DoeFileGenerationProgressModal
                requestId={requestId}
                isOpened={showProgressModal}
                onHide={() => { setShowProgressModal(false); props.onVisibleChanged(false); }}
            />}
    </>
}