import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React, { useRef, useState } from "react";
import { generalSettings } from "../../App";
import { getFilesClient } from "../../services/dashboard.service";
import { GLOBAL_STYLES } from "../../styles";
import { FileModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";

export interface IOpenInS365AppModalProps {
    isOpened?: boolean;
    fileUniqueIdentifier?: string;
    onClose?: () => void;
}

const OpenInS365AppModal: React.FunctionComponent<IOpenInS365AppModalProps> = (props) => {

    const [isLoading, loadingService] = useLoading();
    const fileRef = useRef<FileModel>();

    const parentDirectoryRef = React.useRef<FileModel>();

    React.useEffect(() => {
        onInitAsync();

        const interval = setInterval(async () => {
            const isActive = await isOpenInAppServiceActive();
            if (isActive) {
                onModalClose();
            }
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const onInitAsync = async () => {
        await getFile();
        openInAppClicked();
    }

    const getFile = async () => {
        const messageId = loadingService.showMessage("Getting file...");
        try {
            const client = getFilesClient();
            const resp = await client.getFileLatest(props.fileUniqueIdentifier!, true);
            if (resp) {
                fileRef.current = resp.file;
                parentDirectoryRef.current = resp.breadcrumbItems && resp.breadcrumbItems.length > 0 ?
                    resp.breadcrumbItems[resp.breadcrumbItems.length - 1] : undefined;
            }

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file information.");
        } finally {
            loadingService.hideMessage(messageId);
        }

    }

    const isOpenInAppServiceActive = async () => {
        const messageId = loadingService.showMessage("Checking if user has application installed...");
        try {
            const resp = await fetch('http://localhost:52871');
            if (resp.status == 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log("An error occurred when checking if open in app service is active.");
            return false;
        } finally {
            loadingService.hideMessage(messageId);
        }

    }

    const openInAppClicked = () => {

        let url = "";
        if (!!parentDirectoryRef.current) {
            url = `s365://${parentDirectoryRef.current.uniqueIdentifier}/${fileRef.current.uniqueIdentifier}`;
        } else {
            url = `s365://${fileRef.current.uniqueIdentifier}`;
        }

        url = `${url}?environment=${generalSettings.environment}`;

        window.location.href = url;
    }

    const downloadComponentClicked = () => {
        window.location.href = 'https://simulate365.com/wp-content/uploads/s365-file-opener/simulate-365-file-opener-latest.exe';
    }

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }


    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const [open, setOpen] = React.useState(false);

    return <Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (data.open == false) {
            if (props.onClose)
                props.onClose();
        }

    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Open files locally</DialogTitle>
                <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                    <div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Button appearance="primary" style={{ marginTop: "5px", "marginBottom": "5px" }} onClick={openInAppClicked} >Open file</Button>
                            <Button appearance="primary" style={{ marginTop: "5px", "marginBottom": "5px" }} onClick={downloadComponentClicked}>Download Installer</Button>

                        </div>,
                        <div style={{ margin: "10px 0px" }}>
                            <p style={{ textAlign: "center" }}>This functionality requires a local installation. </p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions fluid style={{ width: '100%' }}>
                    {isLoading && <Spinner
                        label="Checking for Simulate 365 File Opener ..."
                        labelPosition="after"
                        size="small"
                    />}
                    <Button
                        appearance="primary"
                        onClick={onModalClose}
                        style={{ marginLeft: 'auto' }}>Close</Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}

export default OpenInS365AppModal