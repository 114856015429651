import React from "react";
import { ApplicationResponseModel, EditDefaultApplicationPostModel, IEditDefaultApplicationPostModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { getApplicationsClient } from "../../services/dispatcher.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { Field, Input, Label, Select, Switch, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Edit20Regular, Save20Regular } from "@fluentui/react-icons";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "s365-dashboard-v2-file-picker";
import { BreadcrumbItem } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { useNavigate } from "react-router-dom";
import { GLOBAL_STYLES } from "../../styles";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

type EditClusterSettingsProps = {

}

export const EditClusterSettings: React.FC<EditClusterSettingsProps> = (props) => {


    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();

    const { register, handleSubmit, watch, setError, formState: { errors }, setValue } = useForm<IEditDefaultApplicationPostModel>({
        defaultValues: {
            defaultFlowsheetTimeout: "00:05:00",
            retriesCount: 2,
            saveCalculatedFlowsheets: false
        }
    });

    React.useEffect(() => {
        getDefaultApplication();
    }, []);

    const getDefaultApplication = () => {
        loadingService.showLoading("Loading cluster settings...", async (hideMessage) => {
            try {
                const client = getApplicationsClient();
                const defaultApp = await client.getDefaultApplication();
                console.log("defaultApp", defaultApp);
                if (defaultApp) {
                    setValue("defaultFlowsheetTimeout", defaultApp?.defaultFlowsheetTimeout ?? "00:05:00", { shouldDirty: true, shouldTouch: true });
                    setValue("retriesCount", defaultApp?.retriesCount ?? 2, { shouldDirty: true, shouldTouch: true });
                    setValue("saveCalculatedFlowsheets", defaultApp?.saveCalculatedFlowsheets ?? false, { shouldDirty: true, shouldTouch: true });
                }


            } catch (error) {
                processServerError(error, undefined, 'An error occurred while getting Cluster settings.');
            } finally {
                hideMessage();
            }
        });


    }

    const onSubmit = (data: IEditDefaultApplicationPostModel) => {
        console.log("onSubmit", data);
        loadingService.showLoading("Saving...", async (hideMessage) => {
            try {
                const client = getApplicationsClient();
                const model = new EditDefaultApplicationPostModel(data);
                await client.editDefaultApplication(model);
                toast.success("Cluster settings were saved successfully.");
                navigate("/cluster/settings");


            } catch (error) {
                processServerError(error, undefined, "An error occurred while saving cluster settings.");
            } finally {
                hideMessage();
            }

        });
    }

    const saveCalculatedFlowsheets = watch("saveCalculatedFlowsheets");
    const retriesCount = watch("retriesCount");
    const defaultFlowsheetTimeout = watch("defaultFlowsheetTimeout");


    return <div className="content-wrapper">
        <form onSubmit={handleSubmit(m => onSubmit(m))}>
            <div className='toolbar__wrapper'>
                <Toolbar>
                    <ToolbarButton appearance='subtle' type="submit" disabled={isLoading}
                        onClick={() => { }}
                        icon={<Save20Regular />}>Save</ToolbarButton>
                    <LoadingIndicator loadingService={loadingService} />
                </Toolbar>
            </div>

            <div className='breadcrumbs-wrapper'>
                <Breadcrumbs>
                    <Breadcrumb key={`breadcrumb-cluster`} onClick={() => navigate(`/cluster`)}>Cluster</Breadcrumb>
                    <Breadcrumb key={`breadcrumb-sensitivity-studies`} active={true}>Settings</Breadcrumb>
                </Breadcrumbs>
            </div>

            <div className="input-form">
                <div className="input-form-item">
                    <Label className="input-form-label">
                        Flowsheet timeout:
                    </Label>
                    <Field
                        className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                        validationMessage={!!errors.defaultFlowsheetTimeout ? errors.defaultFlowsheetTimeout.message : ""}
                        validationState={!!errors.defaultFlowsheetTimeout ? "error" : "none"}>
                        <Select  {...register("defaultFlowsheetTimeout", {
                            required: {
                                value: true,
                                message: "Flowsheet timeout is required."
                            }
                        })}>
                            <option value="00:01:00">1 minute</option>
                            <option value="00:05:00">5 minutes</option>
                            <option value="00:10:00">10 minutes</option>
                            <option value="00:15:00">15 minutes</option>
                            <option value="00:30:00">30 minutes</option>
                        </Select>
                    </Field>
                </div>

                <div className="input-form-item">
                    <Label className="input-form-label">
                        Retries:
                    </Label>
                    <Field
                        className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                        validationMessage={!!errors.retriesCount ? errors.retriesCount.message : ""}
                        validationState={!!errors.retriesCount ? "error" : "none"}>
                        <Input
                            type="number"
                            value={retriesCount?.toString() ?? ""}
                            {...register("retriesCount", {
                                required: {
                                    value: true,
                                    message: "Number of retries is required."
                                }
                            })}
                        />
                    </Field>
                </div>

                <div className="input-form-item">
                    <Label className="input-form-label">
                        Save calculated flowsheets:
                    </Label>
                    <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}>
                        <Switch
                            checked={saveCalculatedFlowsheets ?? false}
                            disabled={isLoading}
                            onChange={(ev, data) => { setValue("saveCalculatedFlowsheets", data.checked) }}
                        />
                    </Field>
                </div>
            </div>
        </form>

    </div>

}