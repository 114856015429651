import React from "react";
import { useLoading } from "../../utils/loading-indicator.component";
import { PendingInvitationResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions, TableCell, Table, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { GLOBAL_STYLES } from "../../styles";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { PersonDisplay } from "../../components/person-display/person-display.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { getContactsClient } from "../../services/dashboard.service";
import { toast } from "react-toastify";

type PendingInvitationsModalProps = {
    isOpened?: boolean;
    pendingInvitations: PendingInvitationResponseModel[];
    onSuccess: () => void;
    onClose?: () => void;
}

export const PendingInvitationsModal: React.FC<PendingInvitationsModalProps> = (props) => {

    const [isLoading, loadingService] = useLoading();

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);
    const { pendingInvitations } = props;

    const [open, setOpen] = React.useState(false);

    const onAcceptClick = async (contactId: number) => {
        try {
            const client = getContactsClient();
            await client.acceptPendingInvite(contactId);
            toast.success("The invitation was successfully accepted.");
            props.onSuccess();

        } catch (error) {
            processServerError(error, undefined, "An error occurred while accepting invitation.");
        }


    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (data.open == false) {
            if (props.onClose)
                props.onClose();
        }

    }}>
        <DialogSurface style={{ width: "70vw", maxWidth: "100%" }}>
            <DialogBody style={{ maxWidth: "100%" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Pending invitations</DialogTitle>
                <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING} style={{ height: "80vh" }}>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell key="displayName" style={{ width: "400px" }} className='groups-table__cell--bold'>Display name</TableHeaderCell>
                                <TableHeaderCell key="email" style={{ width: "300px" }} className='groups-table__cell--bold column--center'>Email</TableHeaderCell>
                                <TableHeaderCell key="action" className='groups-table__cell--bold  column--center'></TableHeaderCell>

                            </TableRow>
                        </TableHeader>
                        <TableBodyWithLoading isLoading={isLoading}
                            columnCount={3} loadingMessage="Loading..."
                            itemCount={pendingInvitations ? pendingInvitations.length : 0}
                            noItemsMessage="No pending invitations.">
                            {pendingInvitations && pendingInvitations.length > 0 && pendingInvitations.map((item) => {
                                if (!item.userInfo)
                                    return null;

                                return <TableRow
                                    key={`contact-${item.userInfo.id}`}>

                                    <TableCell>
                                        <PersonDisplay id={item.userInfo.id}
                                            name={`${item.userInfo.givenName} ${item.userInfo.surname}`}
                                            userPrincipalName={item.userInfo.userPrincipalName} />
                                    </TableCell>

                                    <TableCell className="column--center">{item.userInfo.privateEmail}</TableCell>
                                    <TableCell className="column--center">
                                        <Button appearance="primary" onClick={() => { onAcceptClick(item.contactId) }}>Accept</Button>
                                    </TableCell>

                                </TableRow>
                            })}
                        </TableBodyWithLoading>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <LoadingButton isLoading={isLoading} type="button" onClick={onModalClose} appearance="secondary">Close</LoadingButton>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>);
}