import { Tooltip } from '@fluentui/react-components';
import * as React from 'react';
import { FileRowResultModel,RowProcessingStatus } from '../../swagger-clients/excel-runner-api-clients.service';



interface IExcelRunnerRowProcessingStatusParams {
    row: FileRowResultModel
  
}

const getStatusTextAndColor = (status: RowProcessingStatus): string[] => {
    switch (status) {
        case RowProcessingStatus.Calculated: // Green
            return ['Converged', 'ForestGreen'];
        case RowProcessingStatus.CalculationFailed: // Red
            return ['Not Converged', 'DarkRed'];
        case RowProcessingStatus.NotSubmittedToDispatcher: // Gray, null for default
            return ['Not submitted to dispatcher', null];
        case RowProcessingStatus.ProcessingFailed: // Red
            return ['Processing failed', 'DarkRed'];       
        case RowProcessingStatus.SubmittedToDispatcher: // Blue
            return ['Submitted to dispatcher', 'CornflowerBlue'];      
        default:
            return [null, null];
    }
}

const ExcelRunnerRowProcessingStatus: React.FunctionComponent<IExcelRunnerRowProcessingStatusParams> = (props: IExcelRunnerRowProcessingStatusParams) => {

    if (!props || !props.row)
        return null;

    const [text, color] = getStatusTextAndColor(props.row.processingStatus);

    // Set color only if defined
    let styles: React.CSSProperties = {};
    if (!!color) {
        styles.color = color;
    }
    
        return <span style={styles}>{text}</span>
   
}

export { ExcelRunnerRowProcessingStatus };