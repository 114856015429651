import { settings } from "../App";
import {
    AgentsClient, ApplicationsClient, FlowsheetsClient, JobActionsClient,
    JobDetailsClient, JobExecutionActionsClient, StatisticsClient, ApplicationApiKeyClient, AgentGroupsClient, AgentRegistrationTokensClient, SharingClient, SearchClient, ActiveJobsClient
} from "../swagger-clients/dispatcher-next-api-clients.service";
import { AxiosInstance } from "./dashboard.service";

export const getApplicationsClient = () => {
    return new ApplicationsClient(settings?.dispatcherServiceUrl, AxiosInstance);
}

export const getAgentsClient = () => {
    return new AgentsClient(settings?.dispatcherServiceUrl, AxiosInstance);
}

export const getFlowsheetsClient = () => {
    return new FlowsheetsClient(settings?.dispatcherServiceUrl, AxiosInstance);
}

export const getStatisticsClient = () => {
    return new StatisticsClient(settings?.dispatcherServiceUrl, AxiosInstance);
}


export const getJobsDetailsClient = () => {
    return new JobDetailsClient(settings?.dispatcherServiceUrl, AxiosInstance);
}

export const getJobActionsClient = () => {
    return new JobActionsClient(settings?.dispatcherServiceUrl, AxiosInstance);
}

export const getJobExecutionActionsClient = () => {
    return new JobExecutionActionsClient(settings?.dispatcherServiceUrl, AxiosInstance);
}
export const getApplicationApiKeysClient = () => {
    return new ApplicationApiKeyClient(settings?.dispatcherServiceUrl, AxiosInstance);
}

export const getAgentGroupsClient = () => {
    return new AgentGroupsClient(settings?.dispatcherServiceUrl, AxiosInstance);
}

export const getAgentRegistrationTokensClient = () => {
    return new AgentRegistrationTokensClient(settings?.dispatcherServiceUrl, AxiosInstance);
}

export const getSharingClient = () => {
    return new SharingClient(settings?.dispatcherServiceUrl, AxiosInstance);
}

export const getSearchClient = () => {
    return new SearchClient(settings?.dispatcherServiceUrl, AxiosInstance);
}

export const getActiveJobsClient = () => {
    return new ActiveJobsClient(settings?.dispatcherServiceUrl, AxiosInstance);
}







