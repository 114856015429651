import React, { useMemo } from "react";
import { FileModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { Button } from "@fluentui/react-components";
import { CustomFileModel, FilesUploadService } from "../../pages/FilesList/FilesUploadService";
import { useLoading } from "../../utils/loading-indicator.component";
import FileUploader, { FileUploaderType } from "../../files/upload/file-uploader.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { getFilesClient } from "../../services/dashboard.service";
import { dwsimExtensions } from "../../global.variables";
import { FilesUploadConflictModal } from "../../pages/FilesList/FilesUploadConflictModal";
import { SaveFileModal, SaveFileModel } from "../save-file-modal/save-file-modal.component";
import { getFileExtension, getFileNameWithoutExtension } from "../../files/file-type-icon/file-type-icon.helpers";

type UploadFileToLocationProps = {
    title?: string;
    isMultiple?: boolean;
    filterFileTypes?: string[];
    onFileUploaded(file: FileModel);

}

export const UploadFileToLocation: React.FC<UploadFileToLocationProps> = (props) => {

    const [showDestinationPicker, setShowDestinationPicker] = React.useState<boolean>(false);
    const [showSelectDestinationButton, setShowSelectDestionationButton] = React.useState<boolean>(true);
    const [isLoading, loadingService] = useLoading();
    const filesUploadService = useMemo(() => new FilesUploadService(loadingService), []);
    const fileUplaoderRef = React.createRef<FileUploaderType>();
    const [selectedFile, setSelectedFile] = React.useState<CustomFileModel>();

    const uploadFile = async (data: SaveFileModel) => {

        try {
            const filename = `${data.name}.${data.extension}`;
            const webkitRelativePathUpdated = selectedFile.webkitRelativePath?.replace(selectedFile.file.name, filename);
            const updatedFile = new File([selectedFile.file], filename, { type: selectedFile.file.type });
            const fileWithName: CustomFileModel = { file: updatedFile, webkitRelativePath: webkitRelativePathUpdated };
            const uploadedFiles = await filesUploadService.UploadFiles([fileWithName], data?.parentDirectoryUniqueId, data.conflictAction);
            const fileUniqueId = uploadedFiles[0].fileUniqueIdentifier;
            await getFile(fileUniqueId);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while uploading file.");
        }

    };

    const onFileSelected = (data: FileList) => {
        const mappedFiles = [...(data as any)].map((file: File) => ({
            file: file, webkitRelativePath:
                file.webkitRelativePath
        } as CustomFileModel));
        setSelectedFile(mappedFiles[0]);
        setShowDestinationPicker(true);
    }

    const getFile = async (uniquefileId: string) => {
        try {
            const client = getFilesClient();
            const resp = await client.getFileLatest(uniquefileId, true);
            if (resp) {
                props.onFileUploaded(resp.file);
            }
            return resp;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file information.");
        }
    }
    const selectedFileExtension = !!selectedFile ? getFileExtension(selectedFile.file.name) : "dwxmz";
    const filenameWithoutExtension = selectedFile ? getFileNameWithoutExtension(selectedFile.file.name) : undefined;
    return <div>
        {showSelectDestinationButton && <Button appearance="primary" onClick={() => { fileUplaoderRef.current?.click(); }}>{props.title ?? "Upload file"}</Button>}
        {!!selectedFile && showDestinationPicker &&
            <SaveFileModal isOpened={showDestinationPicker}
                modalTitle="Save to Dashboard"
                saveButtonLabel="Save"
                defaultName={filenameWithoutExtension}
                fileExtension={selectedFileExtension}
                fileExtensionText={`Simulation file (*.${selectedFileExtension})`}
                onSuccess={(data) => { setShowDestinationPicker(false); uploadFile(data); }}
                onHide={() => { setSelectedFile(undefined); setShowDestinationPicker(false); }}
            />}
        <FileUploader
            isHidden={true}
            isSingle={!props.isMultiple}
            fileExtensions={props.filterFileTypes}
            ref={fileUplaoderRef}
            handleFiles={(data) => onFileSelected(data)}
        />
    </div>
}