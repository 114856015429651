import React from "react";
import { Avatar, Table, TableCell, TableHeader, TableHeaderCell, TableRow, Toolbar, ToolbarButton, tokens } from "@fluentui/react-components";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { getContactsClient, getOrganizationsClient } from "../../services/dashboard.service";
import { AcceptInvitePostModel, ContactResponseModel, ContactType, OrganizationResultModel, PendingInvitationResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { PersonDisplay } from "../../components/person-display/person-display.component";
import { Add20Regular, InfoFilled } from "@fluentui/react-icons";
import { AddContactDialog } from "./add-contact-dialog.component";
import { PendingInvitationsModal } from "./pending-invitations-modal.component";
import { toast } from "react-toastify";
import { ContactFormModal } from "../../components/contact-form-modal/contact-form-modal.component";
import { _copyAndSort } from "../../utils/helpers/array.helpers";
import { Alert } from "@fluentui/react-components/unstable";

type ContactListProps = {

}

export const ContactList: React.FC<ContactListProps> = (props) => {
    const navigate = useNavigate();
    const [contacts, setContacts] = React.useState<ContactResponseModel[]>([]);
    const [isLoading, loadingService] = useLoading();
    const [showAddContactModal, setShowAddContactModal] = React.useState<boolean>(false);
    const [pendingInvites, setPendingInvites] = React.useState<PendingInvitationResponseModel[]>([]);
    const [showPendingInvitationsModal, setShowPendingInvitationsModal] = React.useState<boolean>(false);
    const [myOrganization, setMyOrganization] = React.useState<OrganizationResultModel>();
    const [showContactFormModal, setShowContactFormModal] = React.useState<boolean>(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const [sortBy, setSortBy] = React.useState<{ column: string, descending: boolean }>({ column: "userInfo.displayName", descending: false });



    React.useEffect(() => {
        getMyOrganization();
        getContacts();
        getPendingInvites();
    }, []);

    React.useEffect(() => {

        const invitationCode = searchParams.get("invitationCode");
        if (invitationCode) {
            AcceptInvitation(invitationCode);
        }
    }, []);

    const getContacts = () => {
        loadingService.showLoading("Getting contacts...", async (hideMessage) => {
            try {
                const client = getContactsClient();
                const contactsResp = await client.getMyContacts();
                const sortedResp = _copyAndSort<ContactResponseModel>(contactsResp, sortBy.column, sortBy.descending);
                setContacts(sortedResp ?? []);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting contacts.");
            } finally {
                hideMessage();
            }
        });
    }

    const getPendingInvites = async () => {
        try {
            const client = getContactsClient();
            var pendingInvitesResp = await client.getPendingInvites();
            setPendingInvites(pendingInvitesResp ?? []);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting pending invites.");
        }
    }

    const AcceptInvitation = async (invitationCode: string) => {
        loadingService.showLoading("Accepting invitation...", async (hideMessage) => {


            try {
                const client = getContactsClient();
                const fromUser = await client.acceptInvite(new AcceptInvitePostModel({ invitationCode: invitationCode }));
                toast.success(`Invitation from ${fromUser.displayName} was successfully accepted.`);

                // delete query parameter
                searchParams.delete('invitationCode');
                setSearchParams(searchParams);

                getPendingInvites();
                getContacts();
            } catch (error) {
                processServerError(error, undefined, "An error occurred while accepting invitation.");
            } finally {
                hideMessage();
            }
        });

    }

    const getMyOrganization = async () => {
        const messageId = loadingService.showMessage("Getting organization info...");
        try {
            const client = getOrganizationsClient();
            const orgResp = await client.getMyOrganization();
            setMyOrganization(orgResp);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting your organization info.");
        } finally {
            loadingService.hideMessage(messageId);
        }
    }

    React.useEffect(() => { onSortChanged(); }, [sortBy]);

    const onSortChanged = () => {

        const sorted = _copyAndSort<ContactResponseModel>(contacts, sortBy.column, sortBy.descending);
        setContacts(sorted);

    }

    const onColumnClick = (column: string) => {
        if (column == sortBy.column) {
            setSortBy((s) => ({ ...s, descending: !s.descending }));
        } else {
            setSortBy({ column: column, descending: false });

        }
    }


    const getSortDirection = (column: string) => {

        if (sortBy.column !== column)
            return undefined;
        else return sortBy.descending ? "descending" : "ascending";


    }


    return <div className="content-wrapper">
        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                    onClick={() => { setShowAddContactModal(true); }}
                    appearance='primary'
                    icon={<Add20Regular />}>Add Contact</ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />
            </Toolbar>
        </div>
        <div className='groups-wrapper__breadcrumbs-wrapper'>
            <Breadcrumbs>               
                <Breadcrumb key={`breadcrumb-collaboration`}>Collaboration</Breadcrumb>
                <Breadcrumb key={`breadcrumb-organization`} active={true}>My Contacts</Breadcrumb>
            </Breadcrumbs>
        </div>

        {pendingInvites.length > 0 && <Alert intent="info" className="alert--info-global" style={{ marginBottom: tokens.spacingVerticalMNudge }}>
            <span>There are some pending invites, <a href="#" onClick={(ev) => { ev.preventDefault(); setShowPendingInvitationsModal(true); }}>click here to view them</a>.</span>
        </Alert>}

        {!myOrganization && !isLoading && <div className='s365-table__wrapper' style={{ width: "100%", textAlign: "center" }}>
            <div style={{ padding: "var(--spacingVerticalXXXL)" }}>
                <span>You can instantly connect on Dashboard with other users who belong to the same company or university.<br />
                    Notify <a href="#" onClick={(ev) => { ev.preventDefault(); setShowContactFormModal(true) }}>support@simulate365.com</a> to activate this feature for you.<br />
                    <em>Note:</em> The feature only works if you and others are registered on simulate365.com with the same company or university email address.</span>
            </div>

            {showContactFormModal &&
                <ContactFormModal
                    onHide={() => { setShowContactFormModal(false); }}
                    onSuccess={() => {
                        setShowContactFormModal(false);
                        toast.success("Successfully sent support ticket.");
                    }} />}
        </div>}

        <div className="tab-content">
            <div className='s365-table__wrapper'>
                <Table
                    sortable

                >
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell key="displayName" onClick={() => { onColumnClick("userInfo.displayName") }} sortDirection={getSortDirection("userInfo.displayName")} style={{ width: "500px" }} className='groups-table__cell--bold'>Display name</TableHeaderCell>
                            <TableHeaderCell key="type" onClick={() => { onColumnClick("contactType") }} sortDirection={getSortDirection("contactType")} className='groups-table__cell--bold  column--center'>Type</TableHeaderCell>
                            <TableHeaderCell key="confirmed" onClick={() => { onColumnClick("confirmed") }} sortDirection={getSortDirection("confirmed")} className='groups-table__cell--bold  column--center'>Invite status</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBodyWithLoading isLoading={isLoading}
                        columnCount={4} loadingMessage="Loading..."
                        itemCount={contacts ? contacts.length : 0}
                        noItemsMessage="No contacts found.">
                        {contacts && contacts.length > 0 && contacts.map((item, index) => {


                            return <TableRow
                                key={`contact-${index + 1}`}>

                                <TableCell>
                                    {item.userInfo && <PersonDisplay id={`person-${index + 1}`}
                                        name={item.userInfo.displayName}
                                        userPrincipalName={item.userInfo.userPrincipalName} />}
                                    {!item.userInfo &&
                                        <div className="person-details" style={{ display: "flex", flexDirection: "row", alignItems: "center" }} key={`person-details-${index + 1}`}>
                                            <Avatar className="persona-display-avatar" name="?" color="colorful" />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span className="persona-display-name" style={{ fontStyle: "italic" }}>User not registered on Simulate 365</span>
                                                <small className="persona-principal-name" >{item.privateEmail}</small>
                                            </div>
                                        </div>}
                                </TableCell>

                                <TableCell className="column--center">{item.contactType == ContactType.External ? "External" : "My Organization"}</TableCell>
                                <TableCell className="column--center">{item.contactType == ContactType.External ?
                                    <span>{item.confirmed ? "Confirmed" : "Pending"}</span>
                                    : "-"}</TableCell>
                            </TableRow>
                        })}
                    </TableBodyWithLoading>
                </Table>
            </div>

            {showAddContactModal &&
                <AddContactDialog
                    isOpened={showAddContactModal}
                    onSuccess={() => { setShowAddContactModal(false); getContacts(); }}
                    onClose={() => { setShowAddContactModal(false); }} />}

            {showPendingInvitationsModal &&
                <PendingInvitationsModal
                    pendingInvitations={pendingInvites}
                    isOpened={showPendingInvitationsModal}
                    onSuccess={() => { getPendingInvites(); getContacts(); }}
                    onClose={() => { setShowPendingInvitationsModal(false); getPendingInvites(); }}
                />}




        </div>

    </div>

}