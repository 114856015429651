import { Button, Checkbox, Label, makeStyles, Popover, PopoverSurface, PopoverTrigger, PositioningShorthandValue, Select, Spinner, Textarea, Tooltip } from "@fluentui/react-components";
import { CheckmarkCircleRegular, Dismiss12Regular } from "@fluentui/react-icons";
import React, { ReactElement } from "react";
import { IDropdownOption } from "../../utils/shared.types";
import { HasAccessGroupPicker } from "../has-access-group-picker/has-access-group-picker.component";
import { HasAccessSidePeoplePicker, IPerson, SelectOption } from "../has-access-side-people-picker/has-access-side-people-picker.component";
import "./grant-access-callout.styless.scss";


export interface ISharepointGroup {
    id: string;
    displayName: string;
}
type RecipientPostModel = {
    recipientId: string;
    displayName: string;
    isGroup?: boolean;
}

export enum InvitationSource {
    Email = 0,
    ObjectId = 1,
}

export type ShareModel = {
    invitationSource: InvitationSource;
    sendInvitation: boolean;
    userMessage: string;
    recipients: RecipientPostModel[];
    withEveryone: boolean;

};
type GrantAccessCalloutProps = {
    name: string;
    directionaHint: PositioningShorthandValue;
    showCallout?: boolean;
    userOrgGroup?: ISharepointGroup;
    showSuccessMessage?: boolean;
    hideOrganization?: boolean;
    isGlobalShare?: boolean;
    style?: React.CSSProperties;
    canShareWithEveryone?: boolean;
    hideInvitation?: boolean;
    isRequestInProgress?: boolean;
    icon: ReactElement;
    onShare?(model: ShareModel);
    onCalloutVisibleChanged?();
}

const allUsersGroup = { id: "5f6e0a51-ae32-4e1f-846f-c38a9f6d7481", displayName: "All users" } as ISharepointGroup;




export const GrantAccessCallout: React.FC<GrantAccessCalloutProps> = (props) => {

    const [isOpened, setIsOpened] = React.useState<boolean>(false);
    const [selectedShareOption, setSelectedShareOption] = React.useState<string>("user");
    const [notifyPeople, setNotifyPeople] = React.useState<boolean>(false);
    const [shareMessage, setShareMessage] = React.useState<string>("");
    const [selectedPersonsToShareWith, setSelectedPersonsToShareWith] = React.useState<IPerson[]>([]);
    const [selectedGroups, setSelectedGroups] = React.useState<SelectOption[]>([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true);


    React.useEffect(() => {
        const isDisabled = isGrantAccessButtonDisabled();
        setIsSubmitDisabled(isDisabled);
    }, [selectedGroups, selectedPersonsToShareWith, selectedShareOption]);

    React.useEffect(() => {
        setIsOpened(props.showCallout);
    }, [props.showCallout]);

    const setSelectedPersons = (selectedPersonas: SelectOption[]) => {
        console.log("Selected personas callout:", selectedPersonas);
        const selectedPersons = selectedPersonas.map(persona => {
            return { id: persona.value, displayName: persona.label } as IPerson

        });
        setSelectedPersonsToShareWith(selectedPersons);

    }

    const isGrantAccessButtonDisabled = (): boolean => {

        console.log("isGrantAccessButtonDisabled", selectedShareOption, selectedPersonsToShareWith);
        if (selectedShareOption == "user") {
            return !selectedPersonsToShareWith || !(selectedPersonsToShareWith.length > 0);
        } else if (selectedShareOption == "organization") {
            return props.userOrgGroup == undefined;
        } else if (selectedShareOption == "group") {
            return !selectedGroups || !(selectedGroups.length > 0);

        } else if (selectedShareOption == "everyone") {
            console.log("Sharing with everyone");
            return undefined;
        }

        return true;

    }

    const shareDocuments = async () => {
        if (selectedPersonsToShareWith && selectedPersonsToShareWith.length > 0) {

            const recipients = selectedPersonsToShareWith.map((person: IPerson) => { return { recipientId: person.id, displayName: person.displayName } as RecipientPostModel });

            const model = {
                invitationSource: InvitationSource.Email,
                sendInvitation: notifyPeople,
                userMessage: shareMessage,
                recipients: recipients

            } as ShareModel;
            props.onShare(model);
        }
    }

    const shareWithGroup = async (recipients: RecipientPostModel[]) => {
        console.log("Share with group called!");
        if (recipients && recipients.length > 0) {
            const model = {
                invitationSource: InvitationSource.ObjectId,
                sendInvitation: false,
                userMessage: shareMessage,
                recipients: recipients
            } as ShareModel;
            props.onShare(model);
        } else {
            throw new Error("Group not loaded.");
        }

    }

    const onGrantAccessClick = () => {

        const { userOrgGroup } = props;
        if (selectedShareOption == "user") {
            shareDocuments();
        } else if (selectedShareOption == "group") {
            if (selectedGroups && selectedGroups.length > 0) {
                const recipients = selectedGroups.map(group => ({ recipientId: group.value, displayName: group.label, isGroup: true } as RecipientPostModel));
                shareWithGroup(recipients);
            }
        }

        else if (selectedShareOption == "organization") {
            if (userOrgGroup) {
                const recipients = [{ recipientId: userOrgGroup.id, displayName: userOrgGroup.displayName, isGroup: true } as RecipientPostModel];
                shareWithGroup(recipients);
            }
        } else if (selectedShareOption == "everyone") {
            shareWithEveryone();
        }

    }

    const shareWithEveryone = () => {
        let model: ShareModel = {} as ShareModel;
        model.withEveryone = true;
        props.onShare(model);
    }



    const shareOptions: IDropdownOption[] = [
        { key: 'user', text: 'User' },
        { key: 'group', text: 'Group' },
        { key: 'organization', text: 'Organization', hidden: props.hideOrganization },
        { key: 'everyone', text: "Everyone (Public)", hidden: !props.canShareWithEveryone }

    ];


    return <div style={props.style}>

        <Popover withArrow positioning="below" open={isOpened} onOpenChange={(e, data) => {
            props.onCalloutVisibleChanged();
        }}>
            <PopoverTrigger disableButtonEnhancement>
                <Button icon={props.icon} appearance="transparent" />

            </PopoverTrigger>
            <PopoverSurface>
                <div className="grant-access-callout">
                    <div className="header">
                        <div className="title">
                            <span className="main">Grant Access</span>
                            <span className="sub">{props.name}</span>
                        </div>
                    </div>
                    <div className="callout-body">
                        {!props.showSuccessMessage && <>
                            <div className="share-with-select">
                                <Label>Share with</Label>
                                <Select
                                    value={selectedShareOption}
                                    placeholder="Select an option"
                                    onChange={(ev, data) => { setSelectedShareOption(data.value); }}>
                                    {shareOptions.map((option) => {
                                        return !option.hidden ? <option value={option.key?.toString()}>
                                            {option.text}
                                        </option> : null
                                    })}
                                </Select>
                            </div>

                            {selectedShareOption == "user" && <>

                                <HasAccessSidePeoplePicker
                                    onSelectedOptionsChanged={(persons) => { setSelectedPersons(persons) }}
                                    orgGroupId={!props.isGlobalShare ? (props.userOrgGroup ? props.userOrgGroup.id : undefined) : allUsersGroup.id}
                                />

                                {!props.hideInvitation && <>
                                    <Checkbox label="Notify people"
                                        style={{ marginTop: "10px", alignItems: "center" }}
                                        checked={notifyPeople}
                                        onChange={(ev, data) => setNotifyPeople(!!data.checked)} />

                                    <Textarea
                                        placeholder="Add a message (optional)"
                                        rows={4}
                                        value={shareMessage}
                                        disabled={!notifyPeople}
                                        style={{ width: "100%" }}
                                        onChange={(ev, data) => setShareMessage(data.value)}
                                    /></>
                                }

                            </>
                            }

                            {
                                selectedShareOption == "group" &&
                                <HasAccessGroupPicker
                                    isGlobalShare={props.isGlobalShare}
                                    onSelectedOptionsChanged={(items) => { setSelectedGroups(items); }} />
                            }

                            {selectedShareOption == "organization" && !props.hideOrganization &&
                                <>
                                    {props.userOrgGroup ?
                                        <p style={{ fontSize: "20px", fontWeight: 600, textAlign: "center" }}>You will share with <br />
                                            <i> {props.userOrgGroup.displayName}</i> group</p>
                                        :
                                        <p style={{ fontSize: "20px", fontWeight: 600, textAlign: "center" }}>You need to be part of the organization to use this feature.</p>

                                    }
                                </>
                            }

                            {selectedShareOption == "everyone" &&
                                <p style={{ fontSize: "20px", fontWeight: 600, textAlign: "center" }}>
                                    This resource will be shared with all users, it will be public.
                                </p>
                            }

                            {!props.isRequestInProgress &&
                                <Button appearance="secondary"
                                    style={{ marginTop: "10px" }}
                                    onClick={onGrantAccessClick}
                                    disabled={isSubmitDisabled}>
                                    Grant Access
                                </Button>
                            }
                            {props.isRequestInProgress && <Spinner label="Sharing..." labelPosition="after" />}
                        </>
                        }
                        {props.showSuccessMessage && (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }} >
                            <CheckmarkCircleRegular color="lightgreen" style={{ fontSize: "50px" }} />
                            <p >Access Granted</p>

                        </div>)}


                    </div>

                </div>

            </PopoverSurface>
        </Popover>
    </div>
}