import React from "react";
import { getExperimentsClient } from "../../../services/ai-for-pfd.service";
import { Body1, Card, CardHeader, CardPreview, Spinner } from "@fluentui/react-components";
import { getFiltersClient, getThumbnailsClient } from "../../../services/dashboard.service";
import thumbnail404 from "../../../assets/images/thumbnail-404.png";
import { Alert } from "@fluentui/react-components/unstable";
import { ScenarioResultModel } from "../../../swagger-clients/excel-runner-api-clients.service";
import { getScenariosClient } from "../../../services/excel-runner.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { FilterFlowsheetQuery, FilterResultModel, ShareDefinitionSourceType } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import Co2AbsorptionWithMea from "../../../assets/images/examples/mss/CO2_Absorption_with_MEA.png";
import { generalSettings } from "../../../App";
import { SensitivityStudyResultModel } from "../../../swagger-clients/sensitivity-studies-api-clients.service";
import { getSensitivityStudiesClient } from "../../../services/sensitivity-studies.service";

type StudyExampleProps = {
    studyId: number;
    onClick: () => void;
}
type MssExampleObject = {
    [studyId: string]: any;
}
type Environment = "development" | "staging" | "production";

type MssExampleEnvironmentMapping = {
    [K in Environment]: MssExampleObject;
};

const mssExampleMappings: MssExampleEnvironmentMapping = {
    "development": {
        "95": Co2AbsorptionWithMea
    },
    "staging": {
        "284": Co2AbsorptionWithMea
    },
    "production": {
        "262": Co2AbsorptionWithMea
    }
}

export const StudyExample: React.FC<StudyExampleProps> = (props) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [study, setStudy] = React.useState<SensitivityStudyResultModel>();
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [image, setImage] = React.useState<string>();

    React.useEffect(() => {
        onInit();
    }, [props.studyId]);

    const onInit = async () => {
        setIsLoading(true);
        const studyResp = await getStudy();
        if (!!studyResp) {
            const environment = generalSettings.environment ?? "development";
            const existingImage = mssExampleMappings[environment]?.[props.studyId.toString()];
            if (!!existingImage) {
                setImage(existingImage);
                setIsLoading(false);
                return;
            }

            const filterFileResp = await getFilterFileInfo(studyResp.filterId, studyResp.filterVersionId);
            if (filterFileResp)
                await getSmallThumbnail(filterFileResp.fileUniqueIdentifier, +filterFileResp.fileVersionNumber);
        }
        setIsLoading(false);
    }
    const getFilterFileInfo = async (filterId: number, filterVersionId: number) => {
        try {
            const client = getFiltersClient();
            const query = new FilterFlowsheetQuery({
                filterId: filterId,
                filterVersionId: filterVersionId,
                sourceId: props.studyId,
                sourceType: ShareDefinitionSourceType.MssStudy
            });
            const filterResp = await client.getFlowsheetInfo(query);
            return filterResp;
        } catch (error) {
            setErrorMessage(`Experiment filter not found.`);
            processServerError(error, undefined, "An errror occurred while trying to get experiment filter.");
            return null;
        }
    }
    const getStudy = async () => {

        try {
            setErrorMessage(undefined);
            const client = getSensitivityStudiesClient();
            const studyResp = await client.getSensitivityStudyById(props.studyId);
            setStudy(studyResp);
            return studyResp;
        } catch (error) {
            console.log(`An error occured while loading study #${props.studyId}`, error);
            setErrorMessage(`An error occured while loading study.`);
            return undefined;
        }
    }

    const getSmallThumbnail = async (flowsheetUniqueId: string, flowsheetVersion: number) => {
        try {
            const client = getThumbnailsClient();
            const response = await client.getThumbnailSmall(flowsheetUniqueId, flowsheetVersion);
            if (response) {
                var reader = new FileReader();
                reader.readAsDataURL(response.data);
                reader.onloadend = function () {
                    var base64String = reader.result;
                    // console.log("thumbnail image", base64String);
                    setImage(base64String?.toString());
                }
                reader.onerror = () => {
                    console.log("error while reading thumbnail image");
                    setImage(undefined);
                };
            } else {
                setImage(undefined);
            }

        } catch (error) {
            setImage(undefined);
            console.log("getThumbnailUrl error", error);
        }


    }

    return <Card size="small" style={{ cursor: "pointer", marginBottom: "5px", borderRadius: "6px" }} onClick={() => { props.onClick() }}>
        {!isLoading && study && <CardHeader
            header={<b>{study.name}</b>}
        />}

        <CardPreview style={{ padding: "5px" }}>
            {!isLoading && !!errorMessage &&
                <Alert intent="error"
                    style={{ display: "flex", width: "250px", height: "fit-content", padding: 0, marginBottom: "5px" }}>
                    {errorMessage}
                </Alert>
            }
            {!isLoading && image && <img className="disable-visual-search" src={image} style={{ width: "252px", height: "fit-content" }} alt={`${study.name} flowsheet thumbnail`} />}
            {!isLoading && !image && <img className="disable-visual-search" style={{ width: "252px", height: "fit-content" }} src={thumbnail404} alt={`Thumbnail not found.`} />}
            {isLoading && <div style={{ padding: " 0px 5px" }}><Spinner size="medium" appearance="primary" label="Loading..." /></div>}
        </CardPreview>

    </Card>
}