import { Popover, PopoverSurface, PopoverTrigger, Tooltip } from "@fluentui/react-components";
import { ArrowRightRegular, FolderRegular } from "@fluentui/react-icons";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { ParentReferenceModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import './parent-path-link.scss';

type ParentPathLinkProps = {
    directoryPaths?: ParentReferenceModel[]
}

export const ParentPathLink: React.FC<ParentPathLinkProps> = (props) => {
    const navigate = useNavigate();

    const dashboardElement = { name: "My Work", uniqueIdentifier: undefined } as ParentReferenceModel;
    let directoryPaths: ParentReferenceModel[] = [dashboardElement, ...(props.directoryPaths ?? [])];

    const parentDirectory = directoryPaths[directoryPaths.length - 1];

    if (directoryPaths.length > 1) {
        return <Popover openOnHover={true} positioning={'before'}>
            <PopoverTrigger disableButtonEnhancement>
                <Link to={`/files/${parentDirectory.uniqueIdentifier ?? ""}`} className="parent-path-link__trigger"><FolderRegular className='parent-path-link__icon' />{parentDirectory.name}</Link>
            </PopoverTrigger>
            <PopoverSurface style={{ padding: 'var(--spacingVerticalXS)' }}>
                <Breadcrumbs className="breadcrumbs--parent-path-link">
                    {directoryPaths.map((directory: ParentReferenceModel, index) => {
                        if (index == directoryPaths.length - 1) {
                            return null;
                        }
                        return <Breadcrumb
                            key={`breadcrumb-${directory.uniqueIdentifier ?? "dashboard"}`}
                            showIcon={true}
                            onClick={() => {
                                navigate(`/files/${directory.uniqueIdentifier ?? ""}`);
                            }}><span style={{ display: "block" }}>{directory.name}</span></Breadcrumb>
                    })}
                </Breadcrumbs>
            </PopoverSurface>
        </Popover>;
    } else {
        return <Link to={`/files/${parentDirectory.uniqueIdentifier ?? ""}`} className="parent-path-link__trigger"><FolderRegular className='parent-path-link__icon' />{parentDirectory.name}</Link>;
    }

}