
export const ShowSidebarSectionInTour = (elementId: string, clickOnLink: boolean = true, setBackground: boolean = false) => {
    const myWorkListItem = document.getElementById(elementId);

    if (!myWorkListItem) return;

    //Open menu item
    if (clickOnLink) {
        const links = myWorkListItem.getElementsByTagName("a");
        if (!!links && links.length > 0) {
            links[0].click();
        }
    }


    myWorkListItem.style.position = "relative";
    myWorkListItem.style.zIndex = "9999999";
    myWorkListItem.style.border = "1px solid var(--colorNeutralBackground5)";
    myWorkListItem.style.pointerEvents = "none";
    if (setBackground) {
        myWorkListItem.style.backgroundColor = "var(--colorNeutralBackground1)";
    }


}

export const HideSidebarSectionInTour = (elementId: string, clickOnLink: boolean = true, setBackground: boolean = false) => {
    const myWorkListItem = document.getElementById(elementId);

    if (!myWorkListItem) return;


    myWorkListItem.style.position = "unset";
    myWorkListItem.style.zIndex = "unset";
    myWorkListItem.style.border = "unset"
    myWorkListItem.style.pointerEvents = "unset";
    if (setBackground) {
        myWorkListItem.style.backgroundColor = "unset";
    }


    //Close menu item
    if (clickOnLink) {
        const links = myWorkListItem.getElementsByTagName("a");
        if (!!links && links.length > 0) {
            links[0].click();
        }
    }
}