import { FilterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { SensitivityStudyInputParameterPostModel, SensitivityStudyOutputParameterPostModel, SensitivityStudyResultModel } from "../../swagger-clients/sensitivity-studies-api-clients.service";

export interface IStudyInputParameterValidation {
    value: IValidationField;
    minValue?: IValidationField;
    maxValue?: IValidationField;
    stepSize?: IValidationField;
    stepsAfter?: IValidationField;
    stepsBefore?: IValidationField;

}

export interface IValidationField {
    isValid: boolean;
    validationErrors: string[];
}

export interface IStudyValidation {
    studyName: IValidationField;  
    filter: IValidationField;
    optimizationFunction: IValidationField;
    inputParameters: IStudyInputParameterValidation[];



}
export class SensitivityStudy extends SensitivityStudyResultModel {
    filter?: FilterResultModel;
    sensitivityStudyInputParameters?: SensitivityStudyInputParameter[];
    sensitivityStudyOutputParameters?: SensitivityStudyOutputParameter[];
}


interface IFlowsheetInputParamValue {
    value?: number;
    unit?: string;
}
export enum CustomStudyInputParameterType {
    FixedStudyValue,
    Range,
    FixedFilterValue,
    FilterFormula
}
export class SensitivityStudyInputParameter extends SensitivityStudyInputParameterPostModel {
    parameterType?: CustomStudyInputParameterType;
    filterParameterValue?: string;
    flowsheetParameterValue?: IFlowsheetInputParamValue;
}

export class SensitivityStudyOutputParameter extends SensitivityStudyOutputParameterPostModel {
    isFilterFormula?: boolean;
    filterFormulaValue?: string;
}

