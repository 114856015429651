import React, { useRef } from "react";
import { useLoading } from "../../../utils/loading-indicator.component";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner } from "@fluentui/react-components";
import { GLOBAL_STYLES } from "../../../styles";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { getScenariosClient } from "../../../services/excel-runner.service";
import { WriteResultsStatus, WriteResultsStatusModel } from "../../../swagger-clients/excel-runner-api-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";


type WriteResultsStatusModalProps = {
    requestId: string;
    isOpened?: boolean,
    onClose?: () => void
};


export const WriteResultsStatusModal: React.FC<WriteResultsStatusModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [isLoading, loadingService] = useLoading(true);
    const [isWritingInProgress, setIsWritingInProgress] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const statusRef = useRef<WriteResultsStatusModel>();
    const intervalRef = useRef<NodeJS.Timer>();

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    React.useEffect(() => {
        onInit();
        return () => clearInterval(intervalRef.current);
    }, []);

    const onModalClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }

    const onInit = async () => {

        let intervalMs = 2000;

        intervalRef.current = setInterval(async () => {
            if (statusRef.current?.status == WriteResultsStatus.Failed || statusRef.current?.status == WriteResultsStatus.Finished) {
                return clearInterval(intervalRef.current);
            }
            await checkWriteResultsStatus();
            intervalMs = 1000;
        }, intervalMs);

    }


    const checkWriteResultsStatus = async () => {

        const messageId = loadingService.showMessage("Checking for writing status...");
        try {
            const client = getScenariosClient();
            const statusResp = await client.getWriteResultsStatus(props.requestId);

            statusRef.current = statusResp;
            if (!statusResp) {
                setErrorMessage("Request not found.");
            } else {
                setErrorMessage(undefined);
                if (statusResp.status == WriteResultsStatus.InProgress) {
                    setIsWritingInProgress(true);
                }
                else if (statusResp.status == WriteResultsStatus.Finished) {
                    setIsWritingInProgress(false);
                }
                else if (statusResp.status == WriteResultsStatus.Failed) {
                    setIsWritingInProgress(false);
                    setErrorMessage(statusResp.errorMessage);
                }
            }


        } catch (error) {
            processServerError(error, undefined, "An error occurred while checking writing status.");
            clearInterval(intervalRef.current);
        } finally {
            loadingService.hideMessage(messageId);
        }

    }


    return (<Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Write results</DialogTitle>
                <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        {isLoading && !statusRef.current && !errorMessage && <Spinner size="extra-small" appearance="primary" label="Checking status..." />}
                        {!!statusRef?.current && (statusRef.current.status == WriteResultsStatus.InProgress || statusRef.current.status == WriteResultsStatus.InQueue)
                            && <Spinner size="extra-small" appearance="primary" label="Result are being written..." />}
                        {!!statusRef.current && statusRef.current.status == WriteResultsStatus.Failed && !!errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}
                        {!!statusRef.current && statusRef.current.status == WriteResultsStatus.Finished && <span>
                            The results were successfully written to the Excel file.
                            <br /> File location: <b>{statusRef.current?.filepath}</b>.
                        </span>}
                    </div>
                </DialogContent>
                <DialogActions>

                    <Button appearance="secondary" onClick={() => props.onClose()}>Close</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}

