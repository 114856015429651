import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions, Spinner } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { getProcessingClient } from "../../../services/ai-for-pfd.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { Alert } from "@fluentui/react-components/unstable";
import { CanStartExperimentResponseModel } from "../../../swagger-clients/ai-for-pfd-clients.service";

type StartExperimentModalProps = {
    experimentId: number;
    experimentVersionId: number;
    isOpened?: boolean;
    isRequestInProgress?: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

export const StartExperimentModal: React.FC<StartExperimentModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [canStartResponse, setCanStartResponse] = React.useState<CanStartExperimentResponseModel | undefined>(undefined);



    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    React.useEffect(() => {
       checkIfUserCanStartExperiment();
    }, []);

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    const checkIfUserCanStartExperiment = async () => {
        try {

            setCanStartResponse(undefined);

            const client = getProcessingClient();
            const resp = await client.canUserStartExperiment(props.experimentId, props.experimentVersionId);
            setCanStartResponse(resp ?? undefined);


        } catch (error) {
            processServerError(error, undefined, "An error occurred while checking if user can start the calculation.", false);
               setCanStartResponse( new CanStartExperimentResponseModel({
                hasLicense:false,
                canStart:false,
                errorMessage:"An error occurred while checking if user can start the calculation."
               }));
        }

    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }

    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={!props.isRequestInProgress ? onModalClose : undefined}
                    icon={!props.isRequestInProgress ? <Dismiss24Filled /> : undefined} />}>Start calculation</DialogTitle>
                <DialogContent style={{display:"flex"}}>
                    {!canStartResponse &&  <div style={{marginTop:"10px",marginBottom:"10px"}}>
                    <Spinner size="medium" label="Checking for a Copilot license..." />
                    </div>}
                    {!!canStartResponse && !!canStartResponse.canStart &&
                        <p>
                           Are you sure you want to start the calculation? You won't be able to edit your experiment anymore.
                        </p>
                    }
                    {!!canStartResponse && !canStartResponse.canStart && canStartResponse.hasLicense &&
                        <Alert intent="error" style={{ margin: "10px 0px", width: "100%" }}>
                            <span>{canStartResponse.errorMessage}</span>
                        </Alert>
                    }
                    {!!canStartResponse && !canStartResponse.hasLicense && <p>
                        This functionality requires a license.  Get your free trial or license in the <a href="https://simulate365.com/downloads/copilot" target="_blank">shop</a> to start your calculation.
                    </p>}

                </DialogContent>
                <DialogActions>
                    <Button appearance="primary"
                        onClick={props.onConfirm}
                        disabled={props.isRequestInProgress ||!canStartResponse ||!canStartResponse.canStart}>
                        Continue</Button>
                    <Button appearance="secondary"
                        disabled={props.isRequestInProgress}
                        onClick={onModalClose}>Cancel</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}