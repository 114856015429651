import { settings } from "../App";
import { BestResultsClient, ComponentsClient, ElasticSearchClient, ExperimentCountClient, ExperimentsClient, ProcessingClient } from "../swagger-clients/ai-for-pfd-clients.service";
import { AxiosInstance } from "./dashboard.service";

export const getExperimentsClient = () => {
    return new ExperimentsClient(settings?.aiForPFDServiceUrl, AxiosInstance);
}

export const getExperimentsCountClient = () => {
    return new ExperimentCountClient(settings?.aiForPFDServiceUrl, AxiosInstance);
}

export const getComponentsClient = () => {
    return new ComponentsClient(settings?.aiForPFDServiceUrl, AxiosInstance);
}

export const getExperimentElasticSearchClient = () => {
    return new ElasticSearchClient(settings?.aiForPFDServiceUrl, AxiosInstance);
}

export const getProcessingClient = () => {
    return new ProcessingClient(settings?.aiForPFDServiceUrl, AxiosInstance);
}
export const getBestResultsClient = () => {
    return new BestResultsClient(settings?.aiForPFDServiceUrl, AxiosInstance);
}