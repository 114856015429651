import { Toolbar, ToolbarButton, Label, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, ToolbarGroup, Link } from "@fluentui/react-components";
import { ChatBubblesQuestion24Regular, ChatBubblesQuestionRegular, CopyRegular, DocumentDataRegular, EditRegular, NotepadPersonRegular, PlayRegular, StopRegular } from "@fluentui/react-icons";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { settings } from "../../App";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { ExamStatusComponent } from "../../components/exam-status/exam-status.component";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { getFilesClient, getGroupsClient } from "../../services/dashboard.service";
import { getExamActionsClient, getExamDataClient, getExamsClient } from "../../services/take-home-exams.service";
import { BreadcrumbItem, FileModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { ExamPDFsFolderExistsResponseModel, ExamStatus } from "../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { IDropdownOption } from "../../utils/shared.types";
import { ExcerciseTableType, ExercisesTable } from "../edit-exam/exercises-table/exercises-table.component";
import { ExamDataType } from "../exam-data/exam-data.component";
import { ExamsRouteParams } from "../exams.component";
import { getExamStatus, mapExamPostModelToIExam } from "../shared/exam.utilities";
import { IExam } from "../shared/exams.models";
import { GroupDetailsModal } from "../../contacts/groups/group-details-modal/group-details-modal.component";

type ExamDetailsProps = {

}
const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];

export const ExamDetails: React.FC<ExamDetailsProps> = (props) => {

    const [userOwnedGroupOptions, setUserOwnedGroupOptions] = React.useState<IDropdownOption[]>([]);
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [isLoading, loadingService] = useLoading();
    const [exam, setExam] = React.useState<IExam>();
    const routeParams = useParams<ExamsRouteParams>();
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const navigate = useNavigate();
    const ExcerciseTableRef = React.useRef<ExcerciseTableType>();
    const [showConfirmExamPDFsDeletion, setShowConfirmExamPDFsDeletion] = React.useState<boolean>(false);
    const [showGroupDetailsModal, setShowGroupDetailsModal] = React.useState<boolean>(false);
    const [examPdfFolder, setExamPdfFolder] = React.useState<ExamPDFsFolderExistsResponseModel>();

    React.useEffect(() => {
        getFile();
        getUserOwnedGroups();
        getExam(+routeParams.examId);
    }, []);

    React.useEffect(() => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = `${settings.takeHomeExamsServiceUrl}/Views/print-styles.css`
        document.querySelector("head").appendChild(link);
    }, []);

    const getUserOwnedGroups = async () => {
        loadingService.showLoading("Getting user owned groups...", async (hideMessage) => {
            try {
                const client = getGroupsClient();

                const groups = await client.getUserOwnedGroups();
                const groupOptions = groups.map((group) => ({ key: group.id, text: group.groupname } as IDropdownOption));
                setUserOwnedGroupOptions(groupOptions);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get user owned groups.")
            } finally {
                hideMessage();
            }
        });

    }

    const getFile = async () => {
        loadingService.showLoading("Getting file data...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                const resp = await client.getFileLatest(routeParams.uniquefileId!, true);
                if (resp) {
                    setSelectedFile(resp.file);
                    setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
                }
                return resp;

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting file information.");
            } finally {
                hideMessage();
            }
        });

    }

    const getExam = async (id: number) => {
        const messageId = loadingService.showMessage("Loading exam...");
        try {

            const client = getExamsClient();
            const exam = await client.getExam(id);
            const mappedExam = mapExamPostModelToIExam(exam);
            // console.log("exam, mappedExam", exam, mappedExam);
            setExam(mappedExam);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to get exam.");
        } finally {
            loadingService.hideMessage(messageId);
        }
    }

    const generateExamData = async () => {
        loadingService.showLoading("Generating exam data...", async (hideMessage) => {
            try {
                const client = getExamDataClient();

                await client.generateExamData(+routeParams.examId);
                toast.success("Exam data generated.");

                ExcerciseTableRef.current!.getGeneratedData();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while generating data.");
            }
            finally {
                hideMessage();

            }
        });

    }

    const generateExamPDFs = async (hasConfirmed: boolean = false) => {
        loadingService.showLoading("Generating exam PDFs...", async (hideMessage) => {
            try {
                const client = getExamDataClient();

                if (!hasConfirmed) {
                    const examPDFsFolderExistsResponse = await client.checkDoesExamPDFsFolderExists(+routeParams.examId);
                    if (examPDFsFolderExistsResponse.exists) {
                        setExamPdfFolder(examPDFsFolderExistsResponse);
                        setShowConfirmExamPDFsDeletion(true);
                        return;
                    } else {
                        setExamPdfFolder(undefined);
                    }
                }

                await client.generateExamPDFs(+routeParams.examId);
                toast.success("Exam PDFs generated.");
            } catch (error) {
                processServerError(error, undefined, "An error occurred while generating exam pdfs.");
            }
            finally {
                hideMessage();
            }
        });
    }

    const finishExam = async () => {
        loadingService.showLoading("Finishing exam...", async (hideMessage) => {
            try {
                const client = getExamActionsClient();
                await client.finishExam(+routeParams.examId);
                await getExam(+routeParams.examId);
                toast.success("Exam has finished.");


            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to finish exam.");
            } finally {
                hideMessage();
            }

        });

    }


    const startExam = async () => {
        loadingService.showLoading("Starting exam...", async (hideMessage) => {
            try {
                const client = getExamActionsClient();
                await client.startExam(+routeParams.examId);
                await getExam(+routeParams.examId);
                toast.success("Exam has started.");
            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to start exam.");
            } finally {
                hideMessage();
            }
        });

    }

    const exportAnswers = async () => {
        loadingService.showLoading("Exporting answers...", async (hideMessage) => {
            try {
                const client = getExamsClient();
                await client.exportExamAnswers(+routeParams.examId);
                toast.success("Exam answers exported.");
            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to export exam answers.");
            } finally {
                hideMessage();
            }
        });

    }
    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }

    const getToolbarItems = () => {
        let items: JSX.Element[] = [];
        if (exam && exam.status == ExamStatus.Draft && !exam.isDeleted) {
            items.push(<ToolbarButton appearance='subtle' icon={<EditRegular />}
                onClick={() => { navigate(`/files/${selectedFile?.uniqueIdentifier}/exams/edit/${routeParams.examId}`) }}>Edit</ToolbarButton>);
        }

        items.push(<ToolbarButton appearance='subtle' icon={<CopyRegular />}
            onClick={() => { navigate(`/files/${selectedFile?.uniqueIdentifier}/exams/edit?cloneId=${routeParams.examId}`) }}>Clone</ToolbarButton>);

        if (exam && !exam.isDeleted) {
            items.push(
                <Menu positioning="below-start">
                    <MenuTrigger>
                        {(triggerProps: MenuButtonProps) => {
                            return <MenuButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                                appearance='subtle'
                                icon={<DocumentDataRegular />}
                                {...triggerProps}
                            >Data</MenuButton>
                        }}
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem key="generateExamData" icon={<NotepadPersonRegular />}
                                onClick={async () => { await generateExamData(); }}>Generate individual student data</MenuItem>
                            <MenuItem key="generateExamPdfs" icon={<NotepadPersonRegular />}
                                onClick={() => { generateExamPDFs(); }}>Generate exam PDFs</MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>

            );
        }

        if (exam && exam.status == ExamStatus.Draft && !exam.isDeleted) {
            items.push(<ToolbarButton appearance='subtle' icon={<PlayRegular />} onClick={() => { startExam(); }}>Start</ToolbarButton>);
        }

        if (exam && exam.status == ExamStatus.Started && !exam.isDeleted) {
            items.push(<ToolbarButton appearance='subtle' icon={<StopRegular />} onClick={() => { finishExam(); }}>Finish Exam</ToolbarButton>);
        }
        items.push(<ToolbarButton appearance='subtle' icon={<ChatBubblesQuestionRegular />} onClick={() => { exportAnswers(); }}>Export Answers</ToolbarButton>);

        items.push(<ToolbarGroup><LoadingIndicator loadingService={loadingService} /></ToolbarGroup>);

        return items;

    }
    const groupOption = exam && userOwnedGroupOptions.length > 0 ? userOwnedGroupOptions.find(x => x.key == exam.groupId) : undefined;


    return <div className="content-wrapper">

        <div className='toolbar__wrapper'>
            <Toolbar>
                {getToolbarItems()}
            </Toolbar>
        </div>

        <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/exams`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-exams`} active={true}>Exams</Breadcrumb>

            </Breadcrumbs>
        </div>

        <div className="input-form">
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Exam name:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam?.name}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Group:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>
                    {groupOption ?
                        <Link onClick={() => { setShowGroupDetailsModal(true) }}>{groupOption.text}</Link>
                        : ""}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Date:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam ? <ShowLocalTime date={exam.date}  /> : ""}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Start time:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam ? exam.startTime : ""}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label"> Duration:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam ? exam.duration : ""} (HH:mm)</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Status:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}><ExamStatusComponent status={exam?.status} /></p>
            </div>
            {exam && exam.startedAt && <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">Started at:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam ? <ShowLocalTime date={exam.startedAt} /> : ""}</p>
            </div>}
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label"> Auto start:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam && !exam.autoStart ? "No" : "Yes"}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label"> Auto end:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam && !exam.autoEnd ? "No" : "Yes"}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label"> Grading tolerance (%):</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam && exam.gradingTolerance ? exam.gradingTolerance.toString() : ""}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label"> Worksheet:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam && exam.worksheet ? exam.worksheet : ""}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label"> First row:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam && exam.firstRow ? exam.firstRow.toString() : ""}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label"> Step size:</Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{exam && exam.stepSize ? exam.stepSize.toString() : ""}</p>
            </div>

        </div>

        <ExercisesTable
            {...props}
            exercises={exam ? exam.exercises : []}
            examId={exam ? exam.id : undefined}
            isRequestInProgress={false}
            isDetails
            isLoading={isLoading}
            loadingService={loadingService}
            introductionTemplate={exam?.introductionTemplate}
            // onChange={(exercises: IExercise[]) => { this.setState(s => ({ exam: { ...s.exam, exercises: exercises } as IExam })) }}
            // validationResult={validationResult}
            isFormSubmitted={false}
            ref={ExcerciseTableRef} />

        {showConfirmExamPDFsDeletion && <ConfirmationDialog
            isOpened={true}
            title="The exam folder already exists"
            subText={<><p>The folder <b>{examPdfFolder.folderPath}</b> already exists.</p>
                <p>Do you want to overwrite? <br /> Your previous folder will be deleted and replaced with the new Exam PDFs.</p>
            </>}
            confirmLabel="Continue"
            cancelLabel="Cancel"
            onConfirm={() => { setShowConfirmExamPDFsDeletion(false); generateExamPDFs(true); }}
            onClose={() => { setShowConfirmExamPDFsDeletion(false) }}
        />}
        {showGroupDetailsModal &&
            <GroupDetailsModal
                isOpened={true}
                hideEdit
                groupId={exam?.groupId}
                onClose={() => { setShowGroupDetailsModal(false); }}
            />}

    </div>




}