import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FileSystemEntityType, SuggestDocumentNamePostModel, UploadConflictAction } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import ReactDOM from "react-dom";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, Input, Label, Radio, RadioGroup } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { FileTableItem } from "../models/file-table.models";
import { GLOBAL_STYLES } from "../../styles";
import { useLoading } from "../../utils/loading-indicator.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { getFilesClient } from "../../services/dashboard.service";
import { getFileExtension } from "../file-type-icon/file-type-icon.helpers";

type ICopyFileConflictProps = {
    fileToCopy: FileTableItem;
    isClone?: boolean;
    destinationDirectoryUniqueId?: string;
    onCopy: (conflictData: ConflictCopyResponse) => void,
    onDismiss: () => void
};

export type ConflictFormModel = {
    conflictAction: string;
    newName: string;
};

export type ConflictCopyResponse = {
    conflictAction: UploadConflictAction;
    newName: string;
}

export const CopyFileConflictModal: React.FC<ICopyFileConflictProps> = (props) => {

    const { register, handleSubmit, watch, setError, formState: { errors }, setValue } = useForm<ConflictFormModel>({
        mode: "onSubmit",
        defaultValues: {
            conflictAction: "keep",
            newName: ""
        }
    });

    const [open, setOpen] = useState(true);
    const [isLoading, loadingService] = useLoading();

    React.useEffect(() => {
        getNewNameSuggestion();
    }, []);

    const getNewNameSuggestion = async () => {
        loadingService.showLoading("Loading suggestion name...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                const model = new SuggestDocumentNamePostModel({
                    destinationDirectoryUniqueId: props.destinationDirectoryUniqueId,
                    fileSystemEntityType: props.fileToCopy.type,
                    sourceUniqueId: props.fileToCopy.uniqueIdentifier
                });
                const resp = await client.getSuggestedDocumentName(model);

                if (resp) {
                    console.log("Setting suggested name", resp);
                    setValue("newName", resp.name, { shouldDirty: true, shouldTouch: true });
                }

            } catch (error) {
                processServerError(error, undefined, 'An error occurred while getting name suggestion.');
            } finally {
                hideMessage();
            }

        });
    }

    const mapConflictAction = (action: string) => {
        switch (action) {
            case "replace":
                return UploadConflictAction.Overwrite;
            case "keep":
                return UploadConflictAction.KeepBoth;
            default:
                return null;
        }
    };

    const onSubmit = (m: ConflictFormModel) => {
        setOpen(false); // Close modal
        const conflictAction = mapConflictAction(m.conflictAction);
        const conflictData = {
            conflictAction: conflictAction,
            newName: m.newName
        } as ConflictCopyResponse;
        props.onCopy(conflictData);
    }
    const newName = watch("newName");
    return open && ReactDOM.createPortal(
        <Dialog
            open={true}>
            <DialogSurface>
                <form onSubmit={handleSubmit(m => onSubmit(m))}>
                    <DialogBody style={{ minHeight: "200px" }}>
                        <DialogTitle action={<Button appearance="transparent" onClick={() => { props.onDismiss(); }} icon={<Dismiss24Filled />} />}>{props.isClone ? "Clone" : "Copy"} options</DialogTitle>
                        <DialogContent>

                            <Label id="label8">
                                A {props.fileToCopy?.type == FileSystemEntityType.Directory ? "directory" : "file"} with the same name already exists in the selected directory.<br />
                                Do you want to keep both {props.fileToCopy?.type == FileSystemEntityType.Directory ? "directories" : "files"}?
                            </Label>
                            <RadioGroup aria-labelledby="label8" style={{ marginTop: "var(--spacingVerticalXL)", marginBottom: "var(--spacingVerticalXL)" }}  >
                                <Radio
                                    {...register("conflictAction", { required: true })}
                                    label={`Keep both ${props.fileToCopy?.type == FileSystemEntityType.Directory ? "directories" : "files"}`}
                                    value="keep"
                                />
                                <Radio
                                    {...register("conflictAction", { required: true })}
                                    disabled
                                    label={`Replace existing ${props.fileToCopy?.type == FileSystemEntityType.Directory ? "directory" : "file"}`}
                                    value="replace"
                                />
                            </RadioGroup>
                            <div style={{ display: "flex", alignItems: "center", marginBottom: "var(--spacingVerticalXL)" }}>
                                <Field
                                    label='Enter new name'
                                    className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                                    validationState={errors.newName ? "error" : "none"}
                                    validationMessage={

                                        errors.newName ? `${props.fileToCopy?.type == FileSystemEntityType.Directory ? "Directory" : "File"} name is required.` : undefined
                                    }>
                                    <Input
                                        id="newName"
                                        value={newName}
                                        {...register("newName", { required: true })}
                                        onChange={(ev, data) => { setValue("newName", data.value); }}
                                    />
                                </Field>
                                {props.fileToCopy.type == FileSystemEntityType.File &&
                                    <span style={{ marginTop: "24px", marginLeft: "5px" }}>.{getFileExtension(props.fileToCopy.name)}</span>
                                }

                            </div>



                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                appearance="primary"
                                disabled={isLoading || !!errors.conflictAction || !!errors.newName}
                            >{props.isClone ? "Clone" : "Copy"}</Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
        , document.body);
};