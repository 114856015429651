import { Button, Field, Label, Select } from "@fluentui/react-components";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import React from "react";
import { getJobExecutionActionsClient } from "../../services/dispatcher.service";
import { JobExecutionActionResponseModel, JobExecutionResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { IDropdownOption } from "../../utils/shared.types";
import JobExecutionActionStatusComponent from "../job-execution-action-status/job-execution-action-status.component";
import { getActionType, getActionTriggerOn } from "../../utils/job-action.utilities";
import { ActionDetailsModal } from "../job-action-details/action-details-modal.component";
import { useLoading } from "../../utils/loading-indicator.component";
import { TableBodyWithLoading } from "../table-body-with-loading/table-body-with-loading.component";

type JobExecutionActionsProps = {
    jobExecutionId?: number;
    executions: JobExecutionResponseModel[];
}
export type JobExecutionActionsType = {
    getJobExecutionActions(): void
}

export const JobExecutionActions = React.forwardRef<JobExecutionActionsType, JobExecutionActionsProps>((props, ref) => {
    React.useImperativeHandle(
        ref,
        () => ({
            getJobExecutionActions() {
                getJobExecutionActions();
            }
        }));

    const [selectedExecutionId, setSelectedExecutionId] = React.useState<number>(props.jobExecutionId);
    const [selectedExecutionAction, setSelectedExecutionAction] = React.useState<JobExecutionActionResponseModel>();
    const [actions, setActions] = React.useState<JobExecutionActionResponseModel[]>([]);
    const [showActionDetailsModal, setShowActionDetailsModal] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();

    React.useEffect(() => {
        if (selectedExecutionId)
            getJobExecutionActions();
    }, [selectedExecutionId]);

    React.useEffect(() => {
        if (props.jobExecutionId) {
            setSelectedExecutionId(props.jobExecutionId);
        } else {
            if (props.executions && props.executions.length > 0) {
                setSelectedExecutionId(props.executions[0].id);
            }
        }
    }, [props.jobExecutionId, props.executions]);



    const getJobExecutionActions = async () => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {


                const client = getJobExecutionActionsClient();

                const executionActions = await client.getJobExecutionActions(selectedExecutionId);
                setActions(executionActions);
            }
            catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get execution actions.");
            }
            finally {
                hideMessage();
            }
        });
    }

    const showActionDetails = (action: JobExecutionActionResponseModel) => {
        setSelectedExecutionAction(action); setShowActionDetailsModal(true);
    }

    const { executions } = props;
    const dropdownOptions = executions && executions.length > 0 ?
        executions.map(x => ({ key: x.id, text: x.id.toString() } as IDropdownOption)) : [];
    return <div>
        <div className="input-form">
            <div className="input-form-item">
                <Label className="input-form-label">Results for execution:</Label>
                <Field className="input-form-field"><Select
                    value={selectedExecutionId?.toString() ?? undefined}
                    placeholder="Select an option"
                    onChange={(ev, data) => { setSelectedExecutionId(+data.value!); }}
                >
                    {dropdownOptions.map((option) => {
                        return <option value={option.key?.toString()}>
                            {option.text}
                        </option>
                    })}
                </Select></Field>

            </div>
        </div>
        <Table>
            <TableHeader>
                <TableRow key="table-header">
                    <TableHeaderCell key="action-id" style={{ width: "100px" }} className='table__cell--bold'>Action Id</TableHeaderCell>
                    <TableHeaderCell key="status" className='table__cell--bold'>Status</TableHeaderCell>
                    <TableHeaderCell key="type" className='table__cell--bold'>Type</TableHeaderCell>
                    <TableHeaderCell key="triggerOn" className='table__cell--bold'>Trigger on</TableHeaderCell>
                    <TableHeaderCell key="details" className='table__cell--bold'></TableHeaderCell>


                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={isLoading}
                columnCount={5} loadingMessage="Loading..."
                itemCount={actions ? actions.length : 0}
                noItemsMessage="No items found.">
                {actions &&
                    actions.map((item, index) => {
                        return <TableRow key={`execution-${index}`}>
                            <TableCell> {item.actionId} </TableCell>
                            <TableCell> <JobExecutionActionStatusComponent actionId={item.actionId.toString()} value={item.status} /> </TableCell>
                            <TableCell> {getActionType(item.action.type)} </TableCell>
                            <TableCell>{getActionTriggerOn(item.action.triggerOn)}</TableCell>
                            <TableCell><Button appearance="primary" onClick={() => { showActionDetails(item) }}>Details</Button></TableCell>


                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
        <ActionDetailsModal isOpened={showActionDetailsModal}
            onClose={() => { setShowActionDetailsModal(false) }}
            action={selectedExecutionAction?.action} />
    </div>
});