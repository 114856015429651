import { Button, Input, InputOnChangeData, Tab, TabList } from "@fluentui/react-components";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { ChevronUpRegular, ChevronDownRegular } from "@fluentui/react-icons";
import React from "react";
import { CopyToClipboard } from "../../../components/copy-to-clipboard-icon/copy-to-clipboard-icon.component";
import { LoadingSpinner } from "../../../components/loading-spinner/loading-spinner.component";
import { TableBodyWithLoading } from "../../../components/table-body-with-loading/table-body-with-loading.component";
import { getDispatcherFlowsheetsClient } from "../../../services/dashboard.service";
import { FlowsheetObjectModel, FlowsheetObjectsResponseModel, FlowsheetObjectType, FlowsheetParameterModel } from "../../../swagger-clients/dispatcher-next-api-clients.service";
import { _copyAndSort } from "../../../utils/helpers/array.helpers";
import { processServerError } from "../../../utils/helpers/error.helper";
import { useLoading } from "../../../utils/loading-indicator.component";
import { FlowsheetParameterAccessModeComponent } from "../../../components/flowsheet-parameter-access-mode/flowsheet-parameter-access-mode.component";

type FlowsheetObjectsProps = {
    fileUniqueIdentifier: string,
    fileVersionNumber: number,
    isSelected: boolean
};

interface IFlowsheetObjectModelExtended extends FlowsheetObjectModel {
    parameters: IFlowsheetParameterModelExtended[];
}
interface IFlowsheetParameterModelExtended extends FlowsheetParameterModel {
    isComponent: boolean;
    isReactionSet?: boolean;
    parameters: FlowsheetParameterModel[];
}

interface IFlowsheetObjectValues {
    components: IFlowsheetObjectModelExtended[];
    streams: IFlowsheetObjectModelExtended[];
    unitOperations: IFlowsheetObjectModelExtended[];
    propertyPackages: IFlowsheetObjectModelExtended[];
    reactionSets: IFlowsheetObjectModelExtended[];
    parameters: FlowsheetParameterModel[];

}

interface IParameterFilter {
    objectId: string;
    value: string;
}
export interface IFlowsheetObjectRow {
    id: string;
    isDataloaded: boolean;
}
export interface IFlowsheetComponentRow {
    parentId: string;
    componentId: string;
    isDataloaded: boolean;
}


interface IFlowsheetObjectsState {
    components: IFlowsheetObjectModelExtended[];
    streams: IFlowsheetObjectModelExtended[];
    unitOperations: IFlowsheetObjectModelExtended[];
    propertyPackages: IFlowsheetObjectModelExtended[];
    reactionSets: IFlowsheetObjectModelExtended[];
    expandedRows: IFlowsheetObjectRow[];
    expandedComponents: IFlowsheetComponentRow[];
    isDataLoaded: boolean;
    componentFilter?: string;
    streamFilter?: string;
    reactionSetFilter?: string;
    unitOperationFilter?: string;
    parameterFilters: IParameterFilter[];
    defaultValues?: IFlowsheetObjectValues;
}

export const FlowsheetObjects: React.FC<FlowsheetObjectsProps> = (props) => {
    const [isLoading, loadingService] = useLoading();
    const [state, setState] = React.useState<IFlowsheetObjectsState>({
        components: [],
        streams: [],
        unitOperations: [],
        propertyPackages: [],
        reactionSets: [],
        expandedRows: [],
        expandedComponents: [],
        isDataLoaded: false,
        parameterFilters: []
    });

    const [selectedTab, setSelectedTab] = React.useState<string>("components");

    React.useEffect(() => {
        if (props.isSelected && !state.isDataLoaded) {
            getFlowsheetObjects();
        }
    }, [props.isSelected]);

    const onObjectFilterChanged = (property: string, value: string) => {
        // console.log("onObjectFilterChanged", property, value);
        const { defaultValues } = state;
        if (property == "componentFilter") {
            if (value && value.length > 0) {
                const components = defaultValues!.components.filter(x => x.displayName!.toLowerCase().indexOf(value.toLowerCase()) !== -1);
                setState({ ...state, components: [...components], [property]: value });
            } else {
                setState({ ...state, components: [...defaultValues!.components], [property]: value });
            }

        } else if (property == "streamFilter") {
            if (value && value.length > 0) {
                const streams = defaultValues!.streams.filter(x => x.displayName!.toLowerCase().indexOf(value.toLowerCase()) !== -1);
                setState({ ...state, streams: [...streams], [property]: value });
            } else {
                setState({ ...state, streams: [...defaultValues!.streams], [property]: value });
            }

        } else if (property == "unitOperationFilter") {
            if (value && value.length > 0) {
                const unitOperations = defaultValues!.unitOperations.filter(x => x.displayName!.toLowerCase().indexOf(value.toLowerCase()) !== -1);
                setState({ ...state, unitOperations: [...unitOperations], [property]: value });
            } else {
                setState({ ...state, unitOperations: [...defaultValues!.unitOperations], [property]: value });
            }
        } else if (property == "reactionSetFilter") {
            if (value && value.length > 0) {
                const reactionSets = defaultValues!.reactionSets.filter(x => x.displayName!.toLowerCase().indexOf(value.toLowerCase()) !== -1);
                setState({ ...state, reactionSets: [...reactionSets], [property]: value });
            } else {
                setState({ ...state, reactionSets: [...defaultValues!.reactionSets], [property]: value });
            }
        }
    }

    const getFlowsheetObjects = async () => {
        loadingService.showLoading("Loading flowsheet objects...", async (hideMessage) => {
            try {

                const client = getDispatcherFlowsheetsClient();

                var objects = await client.getFlowsheetObjects("s365v2", props.fileUniqueIdentifier.toString(), props.fileVersionNumber.toString());
                // console.log("Flowsheets objects.", objects);
                var mappedObjects = MapFlowsheetObjectsResponseModelTo(objects);

                setState({
                    defaultValues: {
                        ...mappedObjects
                    },
                    components: mappedObjects.components,
                    propertyPackages: mappedObjects.propertyPackages,
                    reactionSets: mappedObjects.reactionSets.map(reactionSet => ({ ...reactionSet, parameters: [...reactionSet.parameters] } as IFlowsheetObjectModelExtended)),
                    streams: mappedObjects.streams.map(stream => ({ ...stream, parameters: [...stream.parameters] } as IFlowsheetObjectModelExtended)),
                    unitOperations: mappedObjects.unitOperations.map(unitOp => ({ ...unitOp, parameters: [...unitOp.parameters] } as IFlowsheetObjectModelExtended)),
                    isDataLoaded: true
                } as IFlowsheetObjectsState);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting flowsheet objects.");
            } finally {
                hideMessage();
            }
        });
    }

    const isRowExpanded = (id: string, expandedRows: IFlowsheetObjectRow[]) => {
        const rowArray = expandedRows ? expandedRows.filter((value) => value.id == id) : [];
        return rowArray && rowArray.length > 0;
    }

    const isComponentRowExpanded = (componentId: string, parentId: string, expandedComponents: IFlowsheetComponentRow[]) => {
        const rowArray = expandedComponents?.filter((value) => value.parentId == parentId && value.componentId == componentId);
        return rowArray && rowArray.length > 0;
    }

    const toogleExpand = (i: number, item: FlowsheetObjectModel) => {
        //  console.log("toogleExpand:", i, state);
        let expandedRows = [...(state.expandedRows ?? [])];
        let isExpanded = false;

        const expandedRowArray = expandedRows.filter((value) => value.id == item.uniqueIdentifier!.toString());
        if (expandedRowArray && expandedRowArray.length > 0) {
            isExpanded = true;
        }
        if (!isExpanded) {
            setState({ ...state, expandedRows: [...(state.expandedRows ?? []), { id: item.uniqueIdentifier } as IFlowsheetObjectRow] });
        } else {

            //remove row from array
            setState({ ...state, expandedRows: expandedRows.filter((value) => value.id !== item.uniqueIdentifier!.toString()) });

        }
    }

    const toogleComponentExpand = (item: IFlowsheetParameterModelExtended, parent: FlowsheetObjectModel) => {
        let expandedComponents = [...(state.expandedComponents ?? [])];
        let isExpanded = false;

        const expandedComponentsArray = expandedComponents.filter((value) => value.parentId == parent.uniqueIdentifier
            && value.componentId == item.flowsheetObjectUniqueId);
        if (expandedComponentsArray && expandedComponentsArray.length > 0) {
            isExpanded = true;
        }
        if (!isExpanded) {
            setState({
                ...state,
                expandedComponents: [...(state.expandedComponents ?? []),
                { parentId: parent.uniqueIdentifier, componentId: item.flowsheetObjectUniqueId } as IFlowsheetComponentRow]
            });
        } else {

            const updatedComponents = expandedComponents.filter((value) => (value.parentId !== parent.uniqueIdentifier ||
                (value.parentId == parent.uniqueIdentifier && value.componentId !== item.flowsheetObjectUniqueId)));

            //remove row from array
            setState({ ...state, expandedComponents: updatedComponents });

        }
    }

    const getExpandObject = (item: FlowsheetObjectModel | FlowsheetParameterModel, i: number) => {

        if (item && (item as any).uniqueIdentifier && (item as FlowsheetObjectModel).objectType !== FlowsheetObjectType.Component) {
            const { expandedRows } = state;

            return <div
                style={{ display: "flex", flexDirection: "column" }}
                onClick={(e) => {
                    e.nativeEvent.preventDefault();
                    toogleExpand(i, item);
                }}>
                <Button
                    key="chevron-up"
                    appearance="transparent"
                    className="chevron--button"
                    style={{
                        display: isRowExpanded((item as any).uniqueIdentifier.toString(), expandedRows) ? "inline" : "none"
                    }}
                    icon={<ChevronUpRegular />}
                />
                <Button
                    key="chevron-down"
                    appearance="transparent"
                    className="chevron--button"
                    style={{
                        display: !isRowExpanded((item as any).uniqueIdentifier.toString(), expandedRows) ? "inline" : "none"
                    }}
                    icon={<ChevronDownRegular />}
                />

            </div>

        } else {
            return "";
        }
    }

    const getObjectComponentExpandColumn = (item: IFlowsheetParameterModelExtended, i: number, parentItem: FlowsheetObjectModel) => {

        if (item && item.isComponent) {
            const { expandedRows } = state;

            return <div
                style={{ display: "flex", flexDirection: "column" }}
                onClick={(e) => {
                    e.nativeEvent.preventDefault();
                    toogleComponentExpand(item, parentItem);
                }}>
                <Button
                    key="chevron-up"
                    appearance="transparent"
                    className="chevron--button"
                    style={{
                        display: isComponentRowExpanded(item.flowsheetObjectUniqueId!, parentItem.uniqueIdentifier!, state.expandedComponents) ? "inline" : "none"
                    }}
                    icon={<ChevronUpRegular />}
                />
                <Button
                    key="chevron-down"
                    appearance="transparent"
                    className="chevron--button"
                    style={{
                        display: !isComponentRowExpanded(item.flowsheetObjectUniqueId!, parentItem.uniqueIdentifier!, state.expandedComponents) ? "inline" : "none"
                    }}
                    icon={<ChevronDownRegular />}
                />

            </div>

        } else {
            return "";
        }

    }
    const getObjectComponentRow = (item: IFlowsheetParameterModelExtended, i: number, parentItem: FlowsheetObjectModel) => {
        let rows = [<TableRow onClick={(ev) => {
            if (item.isComponent) {
                ev.nativeEvent.preventDefault();
                toogleComponentExpand(item, parentItem);
            }
        }}>
            <TableCell> {item.isComponent ? getObjectComponentExpandColumn(item, i, parentItem) : ""}</TableCell>
            <TableCell>{item.displayName} {!item.isComponent && !item.isReactionSet && <CopyToClipboard
                tooltip="Copy unique parameter identifier"
                content={item.parameter}
                style={{ marginLeft: "var(--spacingHorizontalM)" }} />}</TableCell>
            {!item.isReactionSet && <TableCell>{item.value}</TableCell>}
            {!item.isReactionSet && <TableCell>{item.unit}</TableCell>}
            {!item.isReactionSet && <TableCell><FlowsheetParameterAccessModeComponent accessMode={item.accessMode} /></TableCell>}
        </TableRow>];
        if (!item.isComponent) {
            return rows;
        }

        const expandedCompArray = state.expandedComponents?.filter(x => x.parentId == parentItem.uniqueIdentifier &&
            x.componentId == item.flowsheetObjectUniqueId);

        if (expandedCompArray && expandedCompArray.length > 0) {
            rows.push(<TableRow>
                <TableCell colSpan={4}>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell key="expand-object-2" style={{ width: "20px" }} ></TableHeaderCell>
                                <TableHeaderCell key="name-2" >Name</TableHeaderCell>
                                <TableHeaderCell key="value-2" >Value</TableHeaderCell>
                                <TableHeaderCell key="unit-2">Unit</TableHeaderCell>
                                <TableHeaderCell key="access-mode-2">Access</TableHeaderCell>

                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {item.parameters.map((compParam, index) => {

                                return <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        {compParam.displayName}
                                        <CopyToClipboard
                                            tooltip="Copy unique parameter identifier"
                                            content={compParam.parameter}
                                            style={{ marginLeft: "var(--spacingHorizontalM)" }} />
                                    </TableCell>
                                    <TableCell>{compParam.value}</TableCell>
                                    <TableCell>{compParam.unit}</TableCell>
                                    <TableCell><FlowsheetParameterAccessModeComponent accessMode={compParam.accessMode} /></TableCell>

                                </TableRow>

                            })}

                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>);

        }

        return rows;
    }

    const onObjectParameterFilterChanged = (objectId: string, value: string, objectType: FlowsheetObjectType) => {
        const { defaultValues, parameterFilters, streams, unitOperations, reactionSets } = state;


        let itemIndex: number;
        if (objectType == FlowsheetObjectType.Stream) {
            const streamsUpdated = [...streams];
            itemIndex = streamsUpdated.findIndex(x => x.uniqueIdentifier == objectId);

            if (itemIndex > -1) {
                let defaultParams: IFlowsheetParameterModelExtended[] = [];
                const defaultValuesArray = defaultValues!.streams.filter(x => x.uniqueIdentifier == objectId);
                if (defaultValuesArray && defaultValuesArray.length > 0) {
                    defaultParams = defaultValuesArray[0].parameters ?? [];
                }
                if (value && value.length > 0) {
                    streamsUpdated[itemIndex].parameters = [...defaultParams!.filter(x => x.displayName!.toLowerCase().indexOf(value.toLowerCase()) !== -1)];
                } else {
                    streamsUpdated[itemIndex].parameters = [...defaultParams];
                }
                setState({ ...state, streams: [...streamsUpdated] });
            }

        } else if (objectType == FlowsheetObjectType.UnitOp) {
            const unitOpUpdated = [...unitOperations];
            itemIndex = unitOperations.findIndex(x => x.uniqueIdentifier == objectId);
            if (itemIndex > -1) {
                let defaultParams: IFlowsheetParameterModelExtended[] = [];
                const defaultValuesArray = defaultValues!.unitOperations.filter(x => x.uniqueIdentifier == objectId);
                if (defaultValuesArray && defaultValuesArray.length > 0) {
                    defaultParams = defaultValuesArray[0].parameters;
                }
                if (value && value.length > 0) {
                    unitOpUpdated[itemIndex].parameters = [...defaultParams.filter(x => x.displayName!.toLowerCase().indexOf(value.toLowerCase()) !== -1)];


                } else {
                    unitOpUpdated[itemIndex].parameters = [...defaultParams];
                }

                setState({ ...state, unitOperations: [...unitOpUpdated] });
            }
        }
        else if (objectType == FlowsheetObjectType.ReactionSet) {
            const reactionSetsUpdated = [...reactionSets];
            itemIndex = reactionSets.findIndex(x => x.uniqueIdentifier == objectId);

            if (itemIndex > -1) {
                let defaultParams: IFlowsheetParameterModelExtended[] = [];
                const defaultValuesArray = defaultValues!.reactionSets.filter(x => x.uniqueIdentifier == objectId);
                if (defaultValuesArray && defaultValuesArray.length > 0) {
                    defaultParams = defaultValuesArray[0].parameters;
                }
                console.log("FlowsheetObjectType.ReactionSet defaultValues!.reactionSets ,objectId", defaultValues!.reactionSets, objectId);
                if (!!value && value.length > 0) {
                    reactionSetsUpdated[itemIndex].parameters = [...defaultParams.filter(x => x.displayName!.toLowerCase().indexOf(value.toLowerCase()) !== -1)];
                } else {
                    reactionSetsUpdated[itemIndex].parameters = [...defaultParams];
                }

                setState({ ...state, reactionSets: [...reactionSetsUpdated] });
            }
        }

        // update filter value
        const filterParamIndex = parameterFilters?.findIndex(x => x.objectId == objectId) ?? -1;
        let paramFiltersUpdated = [...(parameterFilters ?? [])];
        if (filterParamIndex < 0) {
            paramFiltersUpdated.push({ objectId: objectId, value: value } as IParameterFilter);
        } else {
            paramFiltersUpdated[filterParamIndex].value = value;
        }
        setState({ ...state, parameterFilters: paramFiltersUpdated });



    }


    const getDisplayNameColumn = (item: IFlowsheetObjectModelExtended, expandedRows: IFlowsheetObjectRow[]) => {
        const isReactionSet = item.objectType == FlowsheetObjectType.ReactionSet;

        if (!isReactionSet && isRowExpanded((item as any).uniqueIdentifier.toString(), expandedRows)) {
            const { parameterFilters } = state;
            const filterValue = parameterFilters?.find(x => x.objectId == item.uniqueIdentifier);
            return <div>
                {item.displayName}
                <Input
                    placeholder={`Filter ${item.displayName}`}
                    style={{ marginLeft: "15px" }}
                    value={filterValue ? (filterValue.value ?? "") : ""}
                    onClick={(ev) => { ev.stopPropagation(); }}
                    onChange={(ev: React.FormEvent<HTMLInputElement>) => {
                        onObjectParameterFilterChanged(item.uniqueIdentifier!, ev.currentTarget.value, item.objectType!);
                    }}
                />
            </div>
        }
        else return item.displayName;

    }

    const getRow = (item: IFlowsheetObjectModelExtended, i: number) => {
        const isComponent = (item as FlowsheetObjectModel).objectType == FlowsheetObjectType.Component;
        const isReactionSet = (item as FlowsheetObjectModel).objectType == FlowsheetObjectType.ReactionSet;
        let rows = [<TableRow onClick={(e) => {
            if (!isComponent) {
                e.nativeEvent.preventDefault();
                toogleExpand(i, item);
            }

        }}>
            <TableCell>{getExpandObject(item, i)}</TableCell>
            <TableCell>{getDisplayNameColumn(item, expandedRows)} </TableCell>
            {isComponent && !isReactionSet && <TableCell>{item.casNumber}</TableCell>}
            {!isComponent && !isReactionSet && <TableCell></TableCell>}
            {!isComponent && !isReactionSet && <TableCell></TableCell>}
        </TableRow>];
        const expanded = expandedRows?.find(x => x.id == item.uniqueIdentifier!);
        if (!!expanded) {
            rows.push(<TableRow>
                <TableCell colSpan={4}>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell key="expand-object-1" style={{ width: "20px" }} ></TableHeaderCell>
                                <TableHeaderCell key="name-1" >Name</TableHeaderCell>
                                {!isReactionSet && <TableHeaderCell key="value-1" >Value</TableHeaderCell>}
                                {!isReactionSet && <TableHeaderCell key="unit-1">Unit</TableHeaderCell>}
                                {!isReactionSet && <TableHeaderCell key="access-mode">Access</TableHeaderCell>}

                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {item.parameters?.map((objectParam, index) => {

                                return getObjectComponentRow(objectParam, index, item);

                            })}

                        </TableBody>
                    </Table>

                </TableCell>

            </TableRow>);
        }
        return rows;

    }

    const { componentFilter, components, streamFilter, streams, expandedRows, unitOperations,
        propertyPackages, reactionSets, unitOperationFilter, reactionSetFilter } = state;

    return <div style={{ height: "calc(75vh + 30px)" }}>

        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
            <Tab key="components-tab" value="components" >Components</Tab>
            <Tab key="streams-tab" value="streams">Streams</Tab>
            <Tab key="unit-operations-tab" value="unit-operations">Unit operations</Tab>
            <Tab key="settings-tab" value="settings">Settings</Tab>
        </TabList>

        {selectedTab == "components" && <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Input
                    placeholder="Filter Components"
                    style={{ marginTop: "10px" }}
                    value={componentFilter ?? ""}
                    onChange={(ev: React.FormEvent<HTMLInputElement>, data: InputOnChangeData) => {
                        onObjectFilterChanged.call(this, "componentFilter", data.value);
                    }}
                />
            </div>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell key="expand-object" style={{ width: "20px" }} ></TableHeaderCell>
                        <TableHeaderCell key="name" >Name</TableHeaderCell>
                        <TableHeaderCell key="cas-nr" >CAS-Nr.</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading
                    isLoading={isLoading}
                    columnCount={4}
                    loadingMessage="Loading..."
                    itemCount={components ? components.length : 0}
                    noItemsMessage="No items found."
                >
                    {components.map((item, i) => {
                        return getRow(item, i);
                    })
                    }

                </TableBodyWithLoading>
            </Table>
        </div>
        }
        {selectedTab == "streams" && <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                <Input
                    placeholder="Filter Streams"
                    style={{ marginTop: "10px" }}
                    value={streamFilter ?? ""}
                    onChange={(ev: React.FormEvent<HTMLInputElement>, data: InputOnChangeData) => {
                        onObjectFilterChanged.call(this, "streamFilter", data.value);
                    }}
                />
            </div>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell key="expand-object" style={{ width: "20px" }} ></TableHeaderCell>
                        <TableHeaderCell key="name" >Name</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={4} loadingMessage="Loading..."
                    itemCount={streams ? streams.length : 0}
                    noItemsMessage="No items found.">
                    {streams.map((item, i) => {
                        return getRow(item, i);
                    })
                    }

                </TableBodyWithLoading>
            </Table>
        </div>
        }

        {selectedTab == "unit-operations" && <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                <Input
                    placeholder="Filter unit operations"
                    style={{ marginTop: "10px" }}
                    value={unitOperationFilter ?? ""}
                    onChange={(ev: React.FormEvent<HTMLInputElement>, data: InputOnChangeData) => {
                        onObjectFilterChanged.call(this, "unitOperationFilter", data.value);
                    }}
                />
            </div>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell key="expand-object" style={{ width: "20px" }} ></TableHeaderCell>
                        <TableHeaderCell key="name" >Name</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={4} loadingMessage="Loading..."
                    itemCount={unitOperations ? unitOperations.length : 0}
                    noItemsMessage="No items found.">
                    {unitOperations.map((item, i) => {
                        return getRow(item, i);
                    })
                    }

                </TableBodyWithLoading>
            </Table>
        </div>
        }
        {selectedTab == "settings" &&
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginBottom: "20px" }}>
                    <span><b>Property packages</b></span>

                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell key="name" >Name</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBodyWithLoading
                            isLoading={isLoading}
                            columnCount={4}
                            loadingMessage="Loading..."
                            itemCount={propertyPackages ? propertyPackages.length : 0}
                            noItemsMessage="No items found."
                        >
                            {propertyPackages.map((item, i) => {
                                return <TableRow>
                                    <TableCell>{item.displayName}</TableCell>
                                </TableRow>;
                            })
                            }

                        </TableBodyWithLoading>
                    </Table>
                </div>

                <span><b>Reaction sets</b></span>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell key="expand-object" style={{ width: "20px" }} ></TableHeaderCell>
                            <TableHeaderCell key="name" style={{ width: "100%" }} >Name</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBodyWithLoading isLoading={isLoading}
                        columnCount={4} loadingMessage="Loading..."
                        itemCount={reactionSets ? reactionSets.length : 0}
                        noItemsMessage="No items found.">
                        {reactionSets?.map((item, i) => {
                            return getRow(item, i);
                        })
                        }

                    </TableBodyWithLoading>
                </Table>

            </div>
        }



    </div >;
}


const MapFlowsheetObjectsResponseModelTo = (response: FlowsheetObjectsResponseModel): IFlowsheetObjectValues => {

    let components: IFlowsheetObjectModelExtended[] = [];
    let streams: IFlowsheetObjectModelExtended[] = [];
    let unitOperations: IFlowsheetObjectModelExtended[] = [];
    let propertyPackages: IFlowsheetObjectModelExtended[] = [];
    let reactionSets: IFlowsheetObjectModelExtended[] = [];
    let parameters: FlowsheetParameterModel[] = [];

    components = response.flowsheetObjects!.filter(x => x.objectType == FlowsheetObjectType.Component) as IFlowsheetObjectModelExtended[];

    streams = response.flowsheetObjects!.filter(x => x.objectType == FlowsheetObjectType.Stream).map(stream => {
        const params = response.flowsheetParameters!.filter(x => x.flowsheetObjectUniqueId == stream.uniqueIdentifier && x.parameter!.indexOf('_c') == -1)
            .map(param => ({ ...param, isComponent: false } as IFlowsheetParameterModelExtended));

        const componentParams = components.map(component => {
            var cparams = response.flowsheetParameters!
                .filter(x => x.parameter!.indexOf(`s{${stream.uniqueIdentifier}}_c{${component.uniqueIdentifier}}`) !== -1);
            const sortedCparams = _copyAndSort<FlowsheetParameterModel>([...cparams], "displayName", false);
            return {
                flowsheetObjectUniqueId: component.uniqueIdentifier,
                displayName: component.displayName,
                isComponent: true,
                parameters: sortedCparams
            } as IFlowsheetParameterModelExtended;
        });
        const sortedParams = _copyAndSort<IFlowsheetParameterModelExtended>([...params], "displayName", false);
        const sortedCompParams = _copyAndSort<IFlowsheetParameterModelExtended>([...componentParams], "displayName", false);
        return { ...stream, parameters: [...sortedParams, ...sortedCompParams] } as IFlowsheetObjectModelExtended;
    });

    unitOperations = response.flowsheetObjects!.filter(x => x.objectType == FlowsheetObjectType.UnitOp).map(unitOp => {
        const params = response.flowsheetParameters!.filter(x => x.flowsheetObjectUniqueId == unitOp.uniqueIdentifier)
            .map(param => ({ ...param, isComponent: false } as IFlowsheetParameterModelExtended));
        const sortedParams = _copyAndSort<IFlowsheetParameterModelExtended>([...params], "displayName", false);
        return { ...unitOp, parameters: [...sortedParams] } as IFlowsheetObjectModelExtended;
    });


    propertyPackages = response.flowsheetObjects!.filter(x => x.objectType == FlowsheetObjectType.PropertyPackage).map((propPackage) => {
        return { ...propPackage } as IFlowsheetObjectModelExtended;
    });

    const reactions = response.flowsheetObjects.filter(x => x.objectType == FlowsheetObjectType.Reaction);

    reactionSets = response.flowsheetObjects.filter(x => x.objectType == FlowsheetObjectType.ReactionSet).map((item) => {

        const params = reactions.filter(x => x.uniqueIdentifier.indexOf(`rs{${item.uniqueIdentifier}`) > -1)
            .map((param) => ({ ...param, isReactionSet: true } as IFlowsheetParameterModelExtended));

        const sortedParams = _copyAndSort<IFlowsheetParameterModelExtended>([...params], "displayName", false);
        return { ...item, parameters: sortedParams } as IFlowsheetObjectModelExtended;
    });


    streams = _copyAndSort<IFlowsheetObjectModelExtended>(streams, "displayName", false);
    unitOperations = _copyAndSort<IFlowsheetObjectModelExtended>(unitOperations, "displayName", false);
    components = _copyAndSort<IFlowsheetObjectModelExtended>(components, "displayName", false);
    propertyPackages = _copyAndSort<IFlowsheetObjectModelExtended>(propertyPackages, "displayName", false);
    reactionSets = _copyAndSort<IFlowsheetObjectModelExtended>(reactionSets, "displayName", false);

    return { components, streams, unitOperations, propertyPackages, reactionSets } as IFlowsheetObjectValues;

}