import { Alert } from "@fluentui/react-components/unstable";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import React, { useState } from "react";

type MathJaxDisplayProps = {
    html: string
};

const MathJaxDisplay = (props: MathJaxDisplayProps) => {

    const [showError, setShowError] = useState(false);

    // Wait some time before showing error.
    // When all is working it takes a second for MathJax to initialize, and warning will flash to user.
    setTimeout(() => setShowError(!((window as unknown as any).MathJax)), 1000);

    return <>
        {!!props.html && showError && <Alert intent="error">
            An error occured while initializing LaTeX parsing.<br />
            This might be a temporary issue, try to refresh this page.
        </Alert>}

        {props.html && <MathJaxContext onLoad={() => setShowError(false)} onError={(error) => { setShowError(true); console.log("An error occured while loading MathJax.", error); }}>
            <MathJax dangerouslySetInnerHTML={{ __html: props.html }} />
        </MathJaxContext>}
    </>;
}

export default MathJaxDisplay;