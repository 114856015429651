import React from "react";
import { Button, Field, Input, TableColumnDefinition, createTableColumn } from "@fluentui/react-components";
import { Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { FilterParameterType, FilterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { INewExperiment, ParametersModel } from "./experiment.models";
import { ParameterDisplay } from "../../components/parameter-display/parameter-display.component";
import { GLOBAL_STYLES } from "../../styles";
import { Dismiss20Regular } from "@fluentui/react-icons";
import { ValidationResult, nameof } from "../../utils/helpers/validation.helpers";

type EditDoeExperimentInputParametersTabProps = {
    experiment: INewExperiment;
    filter: FilterResultModel;
    isFormSubmitted: boolean,
    isLoading: boolean;
    validationResult: ValidationResult;
    onInputParametersChange: (parameters: ParametersModel[]) => void;
};


export const EditDoeExperimentInputParametersTab: React.FC<EditDoeExperimentInputParametersTabProps> = (props) => {

    const onAddRowClick = () => {
        const updatedParameters = [...(props.experiment.inputParameters ?? []), { index: props.experiment.inputParameters?.length ?? 0 }];
        props.onInputParametersChange(updatedParameters);

    }
    const onItemChanged = (index: number, inputFilterParamId: number, value: string) => {
        const updatedInputParameters = props.experiment.inputParameters.map((inputParamRow) => {
            if (index !== inputParamRow.index)
                return inputParamRow;

            let updatedRow = { ...inputParamRow, [inputFilterParamId]: value };
            return updatedRow;
        });
        props.onInputParametersChange(updatedInputParameters);
    }

    const onRemoveRowClick = (rowIndex: number) => {
        const updatedRows = props.experiment.inputParameters.filter((x) => x.index !== rowIndex);
        props.onInputParametersChange(updatedRows);
    }

    const getColumns = () => {
        let columns: TableColumnDefinition<ParametersModel>[] = [];

        const filterInputParams = props.filter.inputFilterParameters;

        filterInputParams.forEach((filterInputParam, inParamIndex) => {
            const column = createTableColumn<ParametersModel>({
                columnId: `input-param-${filterInputParam?.id}`,
                renderHeaderCell: () => <div>{
                    !!filterInputParam.name && filterInputParam.parameterType !== FilterParameterType.Formula ?

                        <ParameterDisplay
                            value={filterInputParam.name}
                            fileUniqueId={props.filter.fileUniqueIdentifier}
                            versionNumber={props.filter.fileVersionNumber.toString()}
                            showTooltip
                        />
                        : filterInputParam.formula}
                </div>,
                renderCell: (item: ParametersModel) => {
                    if (filterInputParam.parameterType == FilterParameterType.Formula)
                        return filterInputParam.formula ?? "-";
                    if (filterInputParam.parameterType == FilterParameterType.FixedValue)
                        return filterInputParam.fixedValue ?? "-";
                    const paramValue = item[filterInputParam.id];
                    const hasErrors = paramValue === undefined;


                    return <Field
                        className={`table-input-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                        validationMessage={props.validationResult.getFieldValidationMessage(`ip_${item.index}_${filterInputParam.id}`, props.isFormSubmitted)}
                        validationState={props.validationResult.getFieldValidationMessage(`ip_${item.index}_${filterInputParam.id}`, props.isFormSubmitted) ? "error" : "none"}
                    >
                        <Input
                            value={paramValue?.toString() ?? ""}
                            disabled={props.isLoading}
                            onChange={(ev, data) => onItemChanged(item.index, filterInputParam.id, data.value)} />
                    </Field>;

                }
            });
            columns.push(column);

        });

        const deleteRowColumn = createTableColumn<ParametersModel>({
            columnId: `delete-row`,
            renderHeaderCell: () => <div></div>,
            renderCell: (item: ParametersModel) => {
                return <Button
                    style={{ float: "right" }}
                    appearance="transparent"
                    icon={<Dismiss20Regular />}
                    onClick={() => { onRemoveRowClick(item.index) }} />

            }
        });
        columns.push(deleteRowColumn);

        return columns;
    }

    const tableColumns = getColumns();



    return <>
        <div className="s365-table__wrapper">
            <Table>
                <TableHeader>
                    <TableRow key="table-header">
                        {tableColumns.map((item, index) => {
                            return <TableHeaderCell key={item.columnId}>
                                {item.renderHeaderCell()}
                            </TableHeaderCell>
                        })}
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={props.isLoading}
                    columnCount={tableColumns.length} loadingMessage="Loading..."
                    itemCount={props.experiment?.inputParameters ? props.experiment.inputParameters.length : 0}
                    noItemsMessage={`You can add data by clicking on the "Add row" button.`}>
                    {
                        props.experiment.inputParameters.map((inputParam, inputParamIndex) => {
                            return <TableRow key={`input-param-${inputParamIndex}`}>
                                {tableColumns.map((column, columnIndex) => {
                                    return <TableCell className="parameter-cell">
                                        {column.renderCell(inputParam)}
                                    </TableCell>
                                })
                                }

                            </TableRow>
                        })

                    }

                </TableBodyWithLoading>
            </Table>
        </div>
        <Button key="add-param-btn" appearance="primary"
            style={{ marginTop: "10px" }}
            disabled={props.isLoading}
            onClick={onAddRowClick}>Add row</Button>
    </>;
}