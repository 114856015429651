import React, { createRef, useReducer } from "react";
import { CKEditor, CKEditorConfig, CKEditorEventAction, useCKEditor } from 'ckeditor4-react';
import { MathJax } from "better-react-mathjax";
import { settings } from "../../../../App";
import { processServerError } from "../../../../utils/helpers/error.helper";
import { getUserToken } from "../../../../services/dashboard.service";

type ExerciseTemplateEditorProps = {
    value?: string;
    accessToken: string;
    onValueChange?(value: string);
    enablePlaceholder?: boolean;
    placeholders?: string[];

}



export const defaultPlaceholders = ["Full name", "Email", "S365 Username"];

export const ExerciseTemplateEditor: React.FC<ExerciseTemplateEditorProps> = (props) => {
    const [isReady, setIsReady] = React.useState<boolean>(false);
    const extraPlugins = ['justify', 'font'];

    const [config, setConfig] = React.useState<CKEditorConfig>({
        mathJaxLib: "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",

        filebrowserUploadUrl: `${settings?.takeHomeExamsServiceUrl}/api/exam-images/upload`,
        extraPlugins: props.enablePlaceholder ? [...extraPlugins, 'placeholder_select'] : extraPlugins,
        placeholder_select: {
            placeholders: props.placeholders
        },
        fileTools_requestHeaders: { 'Authorization': `Bearer ${props.accessToken}` },
        removePlugins: 'scayt,wsc',
        removeButtons: 'PasteFromWord,Blockquote,CreatePlaceholder'

    }
    );


    const [element, setElement] = React.useState<HTMLDivElement>();

    const { editor } = useCKEditor({
        config,
        element,
        editorUrl: "/scripts/ckeditor/ckeditor.js",
        initContent: "",

        dispatchEvent: ({ type, payload }) => {
            if (type == CKEditorEventAction.change) {
                onValueChanged(payload.editor);
            } else if (type == CKEditorEventAction.instanceReady) {
                onInstanceReady(payload.editor);
            }
        },
        subscribeTo: ['change', 'instanceReady']
    });

    React.useEffect(() => {
        if (editor) {
            const currentData = editor.getData();
            //console.log("On editor value changed", props.value, currentData);
            if (props.value && props.value !== currentData) {
                console.log("Calling editor.setData", props.value);
                editor.setData(props.value ?? "");

            }
        }

    }, [props.value, editor]);

    const onInstanceReady = (editor: any) => {
        console.log("onInstanceReady", props.value);
        const currentData = editor.getData();
        if (props.value && props.value !== currentData) {
            console.log("Calling onInstanceReady editor.setData", props.value);
            editor.setData(props.value ?? "");
        }

    }
    const onValueChanged = (editor: any) => {
        if (editor) {
            const data = editor.getData();
            if (props.value !== data) {
                props.onValueChange(data);
            }

        } else {
            console.log("editor not found!!!");
        }

    }


    return <div style={{ paddingTop: "var(--spacingVerticalM)" }}><div ref={setElement} /></div>;





}