import React from "react";
import { Button, Checkbox, CheckboxOnChangeData, Field, Input, Label, Popover, PopoverSurface, PopoverTrigger, Tab, TabList, Table, TableCell, TableHeader, TableHeaderCell, TableRow, Tooltip } from "@fluentui/react-components";
import { FileModel, FilterResultModel, InputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { FilterWebFormRichTextEditor } from "./filter-web-form-rich-text-editor.component";
import { getUserToken } from "../../services/dashboard.service";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { ParameterDisplay } from "../../components/parameter-display/parameter-display.component";
import { IValidationField } from "./edit-filter.types";
import { ValidateRequiredField, ValidateWebForm, WebFormParametersAreValid } from "./edit-filter.validation";
import { Alert } from "@fluentui/react-components/unstable";
import { ApplicationPicker } from "../../components/application-picker/application-picker.component";
import { SensitivityStudy } from "../../sensitivity-studies/edit-sensitivity-study/edit-sensitivity-study.interfaces";
import { GLOBAL_STYLES } from "../../styles";
import FlowsheetObjectService from "../../services/flowsheet-objects.service";
import { getSignificantFigures } from "../../utils/helpers/significant-figures";
import { ArrowReplyAll20Regular } from "@fluentui/react-icons";

type FilterWebFormProps = {
    filter?: FilterResultModel;
    selectedFile?: FileModel;
    setFilter: (filter: FilterResultModel) => void;
    isFormSubmitted: boolean;
    isLoading: boolean;
}


export const FilterWebForm: React.FC<FilterWebFormProps> = (props) => {

    const [accessToken, setAccessToken] = React.useState<string>();
    const [selectedTab, setSelectedTab] = React.useState<string>("intro");
    const [hasInputParameterErrors, setHasInputParameterErrors] = React.useState<boolean>(false);
    const [hasOutputError, setHasOutputError] = React.useState<boolean>(false);


    React.useEffect(() => {

        const webFormValidation = props.isFormSubmitted ? ValidateWebForm(props.filter) : undefined;
        if (!!webFormValidation && !WebFormParametersAreValid(webFormValidation.inputParameters)) {
            setHasInputParameterErrors(true);
        } else {
            setHasInputParameterErrors(false);
        }

        if (!!webFormValidation && !webFormValidation.outputDescription.isValid) {
            setHasOutputError(true);
        } else {
            setHasOutputError(false);
        }

    }, [props.isFormSubmitted, props.filter.webFormOutputDescription]);

    React.useEffect(() => { onInit(); }, []);
    const onInit = async () => {
        const userToken = await getUserToken();
        setAccessToken(userToken);

        await loadDefaultInputParamValues();
    }

    const loadDefaultInputParamValues = async () => {
        if (!props.filter.id) {
            let filterData = { ...props.filter } as FilterResultModel;
            const flowsheetObjects = await FlowsheetObjectService.getFlowsheetObjectsPromise("s365v2", props.selectedFile.uniqueIdentifier, props.selectedFile!.currentVersionNumber.toString());
            filterData.inputFilterParameters = filterData.inputFilterParameters.map((inputParam) => {
                const flowsheetParam = flowsheetObjects?.flowsheetParameters?.find(x => x.parameter == inputParam.name);
                return new InputFilterParameterResultModel({
                    ...inputParam,
                    webFormDefaultValue: flowsheetParam !== undefined ? getSignificantFigures(flowsheetParam.value, 2) : undefined,
                    webFormShowDefaultValue: true
                });
            });
            props.setFilter(filterData);
        }

    }

    const onEnableWebFormChanged = (ev: React.ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {

        let filterData = { ...props.filter } as FilterResultModel;
        filterData.enableWebForm = !!data.checked;
        if (!data.checked) {
            filterData.webFormIntro = undefined;
            filterData.webFormOutputDescription = undefined;
            filterData.webFormApplicationId = undefined;
            filterData.inputFilterParameters = filterData.inputFilterParameters.map((inParam) => {
                return { ...inParam, webFormDescription: undefined, webFormName: undefined } as InputFilterParameterResultModel;
            });
        }
        props.setFilter(filterData);
    }

    const getDefaultFilterParameterValue = async (item: InputFilterParameterResultModel, index: number) => {
        const flowsheetObjects = await FlowsheetObjectService.getFlowsheetObjectsPromise("s365v2", props.selectedFile.uniqueIdentifier, props.selectedFile!.currentVersionNumber.toString());

        const flowsheetParam = flowsheetObjects?.flowsheetParameters?.find(x => x.parameter == item.name);
        return flowsheetParam;
    }

    const onSetFlowsheetItemValueClick = async (item: InputFilterParameterResultModel, index: number) => {
        const flowsheetObjects = await FlowsheetObjectService.getFlowsheetObjectsPromise("s365v2", props.selectedFile.uniqueIdentifier, props.selectedFile!.currentVersionNumber.toString());

        const flowsheetParam = flowsheetObjects?.flowsheetParameters?.find(x => x.parameter == item.name);
        let filterData = { ...props.filter } as FilterResultModel;
        filterData.inputFilterParameters[index] = {
            ...filterData.inputFilterParameters[index],
            webFormDefaultValue: flowsheetParam !== undefined ? getSignificantFigures(flowsheetParam.value, 2) : undefined
        } as InputFilterParameterResultModel;
        props.setFilter(filterData);
    }

    const getWebFormNameCell = (item: InputFilterParameterResultModel, index: number) => {
        let validationResult = { isValid: true, validationErrors: [] } as IValidationField;
        validationResult = ValidateRequiredField("Name", item.webFormName);


        return <div className="input-wrapper">
            <Field
                className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                validationMessageIcon={null}
                validationMessage={props.isFormSubmitted ? validationResult.validationErrors[0] : undefined}
                validationState={props.isFormSubmitted && validationResult.validationErrors[0] ? "error" : "none"}>
                <Input
                    value={item.webFormName}
                    style={{ maxWidth: "100%" }}
                    onChange={(ev, data) => { onItemChanged(index, data.value as string, "webFormName") }} />
            </Field>
        </div>
    }

    const DefaultParameterValue = (props: { item: InputFilterParameterResultModel, index: number }) => {

        const [defaultValue, setDefaultValue] = React.useState<number | undefined>();
        const [defaultUnit, setDefaultUnit] = React.useState<string | undefined>();
        React.useEffect(() => {


            getDefaultValue();
        }, []);
        const getDefaultValue = async () => {
            const defaultParam = await getDefaultFilterParameterValue(props.item, props.index);

            const unit = defaultParam?.unit && defaultParam.unit !== "unknown" ? defaultParam.unit : "";
            setDefaultUnit(unit);
            const value = defaultParam !== undefined ? getSignificantFigures(defaultParam.value, 2) : undefined;
            setDefaultValue(value);
        }

        return defaultValue !== undefined && <span>{defaultValue} {defaultUnit}</span>
    }
    const getWebFormDefaultValueCell = (item: InputFilterParameterResultModel, index: number) => {
        let validationResult = { isValid: true, validationErrors: [] } as IValidationField;
        validationResult = ValidateRequiredField("Default value", item.webFormDefaultValue?.toString() ?? "");
        const hasErrors = item.webFormShowDefaultValue && !!validationResult.validationErrors[0];


        return <div className="input-wrapper">
            <Field
                className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                validationMessageIcon={null}
                validationMessage={props.isFormSubmitted && hasErrors ? validationResult.validationErrors[0] : undefined}
                validationState={props.isFormSubmitted && hasErrors ? "error" : "none"}>
                <Input
                    value={item.webFormDefaultValue?.toString() ?? ""}
                    style={{ maxWidth: "100%" }}
                    onChange={(ev, data) => { onItemChanged(index, data.value as string, "webFormDefaultValue") }}
                    disabled={!item.webFormShowDefaultValue}
                    contentAfter={
                        <div style={{ display: "flex" }}>
                            <Tooltip positioning="above" content={<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <span>Insert Flowsheet Value</span>
                                <DefaultParameterValue item={item} index={index} /></div>}
                                relationship="label">
                                <Button appearance="transparent" onClick={() => onSetFlowsheetItemValueClick(item, index)} icon={<ArrowReplyAll20Regular />} />
                            </Tooltip>
                            <Popover openOnHover withArrow positioning={"after"} >
                                <PopoverTrigger disableButtonEnhancement>
                                    <Checkbox checked={item.webFormShowDefaultValue} onChange={(ev, data) => { onItemChanged(index, data.checked, "webFormShowDefaultValue") }} />
                                </PopoverTrigger>

                                <PopoverSurface tabIndex={-1}>
                                    <span>Display Default value in the Web Form</span>
                                </PopoverSurface>
                            </Popover>
                        </div>


                    }
                />
            </Field>
        </div>
    }


    const onItemChanged = (index: number, newValue: any, column: string) => {

        let newParams = [...(props.filter?.inputFilterParameters ?? [])];
        const oldValue = (newParams[index] as any)[column] as any;
        if (oldValue !== newValue) {
            (newParams[index] as any)[column] = newValue !== "" ? newValue : undefined;

            const updatedFilter = { ...props.filter, inputFilterParameters: newParams } as FilterResultModel;
            props.setFilter(updatedFilter);
        }
    }


    const outputPlaceholders = props.filter.outputFilterParameters?.map((param) => `out.${param.alias}`) ?? [];
    const inputPlaceholders = props.filter.inputFilterParameters?.map((param) => `in.${param.alias}`) ?? [];
    const paramsPlaceholders = [...inputPlaceholders, ...outputPlaceholders];

    return <div>
        <div>
            <Checkbox
                checked={props.filter.enableWebForm}
                label="Enable Filter Web Form"
                onChange={onEnableWebFormChanged} />
        </div>
        {!!props.filter.enableWebForm && <div className="input-form" style={{ marginTop: "var(--spacingVerticalXL)", marginBottom: 0 }}>
            <div className="input-form-item">
                <Label style={{ marginRight: "var(--spacingHorizontalXL)" }}>
                    Application:
                </Label>
                <ApplicationPicker
                    className="input-form-field"
                    required
                    isSubmitted={props.isFormSubmitted}
                    selectedApplicationId={props.filter.webFormApplicationId?.toString() ?? ""}
                    onChange={(application) => { props.setFilter({ ...props.filter, webFormApplicationId: application.id } as FilterResultModel) }}
                />
            </div>
        </div>}

        {!!props.filter.enableWebForm && <>
            <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
                <Tab key="intro-tab" value="intro" >Intro</Tab>
                <Tab key="input-parameters-tab" value="input-parameters" className={hasInputParameterErrors ? "has-errors" : undefined}>Input parameters</Tab>
                <Tab key="Output-tab" value="output" className={hasOutputError ? "has-errors" : undefined}>Output</Tab>
            </TabList>
            <div className="tab-content">
                {selectedTab == "intro" && <div>
                    {!!accessToken && <FilterWebFormRichTextEditor
                        placeholders={[]}
                        enablePlaceholder={false}
                        accessToken={accessToken}
                        value={props.filter.webFormIntro ?? ""}
                        onValueChange={(value) => { props.setFilter({ ...props.filter, webFormIntro: value } as FilterResultModel) }} />}

                </div>}

                {selectedTab == "input-parameters" &&
                    <div className="s365-table__wrapper">
                        <Table>
                            <TableHeader>
                                <TableRow key="table-header">
                                    <TableHeaderCell key="parameter" style={{ width: "300px" }} className='files-table__cell--bold'>Parameter</TableHeaderCell>
                                    <TableHeaderCell key="name" style={{ width: "220px" }} className='files-table__cell--bold'>Name</TableHeaderCell>
                                    <TableHeaderCell key="description" style={{ width: "180px" }} className='files-table__cell--bold'>Description</TableHeaderCell>
                                    <TableHeaderCell key="design-parameter" style={{ width: "150px" }} className='files-table__cell--bold'>Design parameter</TableHeaderCell>
                                    <TableHeaderCell key="default-value" style={{ width: "150px" }} className='files-table__cell--bold'>Default value</TableHeaderCell>
                                    <TableHeaderCell key="min-value" style={{ width: "100px" }} className='files-table__cell--bold'>Min value</TableHeaderCell>
                                    <TableHeaderCell key="max-value" style={{ width: "100px" }} className='files-table__cell--bold'>Max value</TableHeaderCell>

                                </TableRow>
                            </TableHeader>
                            <TableBodyWithLoading isLoading={props.isLoading}
                                columnCount={3} loadingMessage="Loading..."
                                itemCount={props.filter?.inputFilterParameters ? props.filter.inputFilterParameters.length : 0}
                                noItemsMessage={`You can add parameters by clicking on the "Add parameter" button.`}>
                                {props.filter && props.filter.inputFilterParameters &&
                                    props.filter.inputFilterParameters.map((item, index) => {
                                        return <TableRow key={`input-param-${index}`} >
                                            <TableCell key={"parameter"} className="parameter-cell">
                                                {!!item.name ? <ParameterDisplay
                                                    value={item.name}
                                                    fileUniqueId={props.selectedFile.uniqueIdentifier}
                                                    versionNumber={props.selectedFile.currentVersionNumber.toString()}
                                                />

                                                    : "-"}
                                            </TableCell>
                                            <TableCell key="name" className="parameter-cell">
                                                {getWebFormNameCell(item, index)}
                                            </TableCell>
                                            <TableCell key="description" className="parameter-cell">
                                                <Field className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}>
                                                    <Input
                                                        value={item.webFormDescription}
                                                        style={{ maxWidth: "100%" }}
                                                        onChange={(ev, data) => { onItemChanged(index, data.value as string, "webFormDescription") }} />
                                                </Field>
                                            </TableCell>
                                            <TableCell key="design-parameter" className="parameter-cell">
                                                <Field className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}>
                                                    <Checkbox
                                                        checked={item.webFormIsDesignParameter}
                                                        style={{ maxWidth: "100%" }}
                                                        onChange={(ev, data) => { onItemChanged(index, data.checked, "webFormIsDesignParameter") }} />
                                                </Field>
                                            </TableCell>
                                            <TableCell key="defaultValue" className="parameter-cell">
                                                {getWebFormDefaultValueCell(item, index)}
                                            </TableCell>
                                            <TableCell key="webFormMinValue" className="parameter-cell">
                                                <Field className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}>
                                                    <Input
                                                        type="number"
                                                        value={item.webFormMinValue?.toString()}
                                                        style={{ maxWidth: "100%" }}
                                                        onChange={(ev, data) => { onItemChanged(index, data.value as string, "webFormMinValue") }} />
                                                </Field>
                                            </TableCell>
                                            <TableCell key="webFormMaxValue" className="parameter-cell">
                                                <Field className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH} >
                                                    <Input
                                                        value={item.webFormMaxValue?.toString()}
                                                        style={{ maxWidth: "100%" }}
                                                        onChange={(ev, data) => { onItemChanged(index, data.value as string, "webFormMaxValue") }} />
                                                </Field>
                                            </TableCell>

                                        </TableRow>
                                    })

                                }

                            </TableBodyWithLoading>
                        </Table>
                    </div>
                }
                {selectedTab == "output" && <div>
                    {hasOutputError && <Alert intent="error" style={{ minHeight: "30px", marginBottom: "var(--spacingVerticalMNudge)" }}>
                        Output is required.
                    </Alert>}
                    {!!accessToken && <FilterWebFormRichTextEditor
                        placeholders={paramsPlaceholders}
                        enablePlaceholder={false}
                        accessToken={accessToken}
                        value={props.filter.webFormOutputDescription ?? ""}
                        onValueChange={(value) => { props.setFilter({ ...props.filter, webFormOutputDescription: value } as FilterResultModel) }} />}

                </div>}
            </div>
        </>
        }



    </div>
}

