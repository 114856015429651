import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, Input, Field, createTableColumn, TableColumnDefinition, TableColumnSizingOptions, useTableFeatures, useTableColumnSizing_unstable } from "@fluentui/react-components";
import React from "react";
import { ParameterDisplay } from "../../../components/parameter-display/parameter-display.component";
import { TableBodyWithLoading } from "../../../components/table-body-with-loading/table-body-with-loading.component";
import { FileModel, FilterParameterType, FilterResultModel, InputFilterParameterResultModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { ValidationResult } from "../../../utils/helpers/validation.helpers";
import { GLOBAL_STYLES } from "../../../styles";

type ScenarioInputParametersTabProps = {
    inputParameters: InputFilterParameterResultModel[];
    isFormSubmitted: boolean;
    isFormDisabled: boolean;
    values: {};
    isLoading: boolean;
    onChange: (filterParamId: number, column: string) => void;
    validationResult: ValidationResult;
    selectedFilter: FilterResultModel;
    selectedFilterDocument: FileModel;
}

export const ScenarioInputParametersTab: React.FunctionComponent<ScenarioInputParametersTabProps> = (props) => {


    const [columns, setColumns] = React.useState<TableColumnDefinition<InputFilterParameterResultModel>[]>([]);

    React.useEffect(() => { const columnsResp = getColumns(); setColumns(columnsResp); }, []);

    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        parameter: {
            minWidth: 100,
            defaultWidth: 300,
        },
        alias: {
            minWidth: 100,
            defaultWidth: 200,
        },
        parameterType: {
            minWidth: 100,
            defaultWidth: 200
        },
        valueFormulaField: {
            minWidth: 100,
            defaultWidth: 200
        },
        column: {
            minWidth: 50,
            defaultWidth: 100,
            idealWidth:100

        }
    });

    const getColumns = () => {
        const columnsDef: TableColumnDefinition<InputFilterParameterResultModel>[] = [
            createTableColumn<InputFilterParameterResultModel>({
                columnId: "parameter",
                renderHeaderCell: () => <>Parameter</>,
                renderCell: (item: InputFilterParameterResultModel) => {
                    return <ParameterDisplay
                        value={item.name}
                        fileUniqueId={props.selectedFilterDocument.uniqueIdentifier}
                        versionNumber={props.selectedFilterDocument.currentVersionNumber?.toString()} />;

                }
            }),
            createTableColumn<InputFilterParameterResultModel>({
                columnId: "alias",
                renderHeaderCell: () => <>Alias</>,
                renderCell: (item: InputFilterParameterResultModel) => {
                    return item.alias;

                }
            }),
            createTableColumn<InputFilterParameterResultModel>({
                columnId: "parameterType",
                renderHeaderCell: () => <>Parameter Type</>,
                renderCell: (item: InputFilterParameterResultModel) => {
                    return {
                        [FilterParameterType.ExpectedInput]: "Column",
                        [FilterParameterType.Formula]: "Formula",
                        [FilterParameterType.FixedValue]: "Fixed value",
                    }[item.parameterType];
                }
            }),
            createTableColumn<InputFilterParameterResultModel>({
                columnId: "valueFormulaField",
                renderHeaderCell: () => <>Formula / Fixed Value</>,
                renderCell: (item: InputFilterParameterResultModel) => {

                    return <>
                        {item.parameterType == FilterParameterType.Formula && <span>{item.formula}</span>}
                        {item.parameterType == FilterParameterType.FixedValue && <span>{item.fixedValue}</span>}
                        {item.parameterType != FilterParameterType.FixedValue && item.parameterType != FilterParameterType.Formula && <span>-</span>}
                    </>;
                }
            }),
            createTableColumn<InputFilterParameterResultModel>({
                columnId: "column",
                renderHeaderCell: () => <>Column</>,
                renderCell: (item: InputFilterParameterResultModel) => {

                    return <>{item.parameterType == FilterParameterType.ExpectedInput &&
                        <Field
                            className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                            validationMessage={props.validationResult.getFieldValidationMessage(`ip_${item.id}`, props.isFormSubmitted)}
                            validationState={props.isFormSubmitted && props.validationResult.getFieldValidationMessage(`ip_${item.id}`, props.isFormSubmitted) ? "error" : "none"}
                        >
                            <Input
                                type="text"
                                maxLength={5}
                                defaultValue={props.values[item.id]}
                                onChange={(ev, data) => { props.onChange(item.id, data.value) }}
                            /></Field>}
                        {item.parameterType != FilterParameterType.ExpectedInput &&
                            <Field
                                className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                                validationMessage={props.validationResult.getFieldValidationMessage(`ip_${item.id}`, props.isFormSubmitted)}
                                validationState={props.isFormSubmitted && props.validationResult.getFieldValidationMessage(`ip_${item.id}`, props.isFormSubmitted) ? "error" : "none"}
                            >
                                <Input
                                    type="text"
                                    value={props.values[item.id]}
                                    onChange={(ev, data) => { props.onChange(item.id, data.value) }}
                                />
                            </Field>}</>;

                }
            })
        ];

        return columnsDef;
    }
    const items = props.inputParameters ?? [];

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<InputFilterParameterResultModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    return <div>

        <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
            <TableHeader>
                <TableRow key="table-header">
                    {columns.map((column) => (
                        <TableHeaderCell
                            onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            className={`table__cell--bold`}
                            key={column.columnId}
                            {...columnSizing_unstable.getTableHeaderCellProps(
                                column.columnId
                            )}
                        >
                            {column.renderHeaderCell()}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>

            <TableBodyWithLoading isLoading={props.isLoading}
                columnCount={5} loadingMessage="Loading..."
                itemCount={props.inputParameters ? props.inputParameters.length : 0}
                noItemsMessage={`Select filter to view parameters.`}>
                {props.inputParameters &&
                    props.inputParameters.map((item, index) => {
                        return <TableRow key={`input-param-${item.id}`}>
                            {columns.map((column) => (
                                <TableCell
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
    </div>;
}


