import React from "react";
import { FileTableItem } from "../../files/models/file-table.models";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { ExampleFilePicker } from "../example-file-picker/example-file-picker.component";
import { HubConnection } from "@microsoft/signalr";

type ExampleFilePickerDialogProps = {
    isOpened: boolean;
    title?: string;
    hubConnection: HubConnection;
    filterFileTypes?: string[];
    onClose: () => void;

    onFileSelected: (file: FileTableItem) => void;
}

export const ExampleFilePickerDialog: React.FC<ExampleFilePickerDialogProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [selectedFile, setSelectedFile] = React.useState<FileTableItem>();

    React.useEffect(() => { setOpen(!!props.isOpened); }, [props.isOpened]);



    const onModalClose = () => {
        setOpen(false);
        props.onClose();
    }

    return <Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }


    }}>
        <DialogSurface style={{ width: "70vw", maxWidth: "100%" }}>

            <DialogBody style={{ maxWidth: "100%" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>{props.title ?? "Select file"}</DialogTitle>
                <DialogContent style={{ height: "80vh" }}>
                    <ExampleFilePicker
                        disableOpenFile
                        filterFileTypes={props.filterFileTypes}
                        hubConnection={props.hubConnection}
                        onFileSelected={(file) => { setSelectedFile(file); }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button appearance="primary" disabled={!selectedFile} onClick={() => { props.onFileSelected(selectedFile); }}>Select</Button>
                    <Button appearance="secondary" onClick={onModalClose}>Cancel</Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}