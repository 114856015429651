import { useMsal } from "@azure/msal-react";
import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Label, TabList, Tab, Field } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableSelectionCell, TableCell } from "@fluentui/react-components";
import { Dismiss24Filled, DismissRegular, EditRegular, RenameRegular, TextEditStyleRegular } from "@fluentui/react-icons";
import React from "react";
import { SelectOption } from "../../../components/has-access-side-people-picker/has-access-side-people-picker.component";
import { PersonDisplay } from "../../../components/person-display/person-display.component";
import { TableBodyWithLoading } from "../../../components/table-body-with-loading/table-body-with-loading.component";
import { getGroupsClient, getUsersClient } from "../../../services/dashboard.service";
import { graphClient } from "../../../services/msgraph.service";
import { UserModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { _copyAndSort } from "../../../utils/helpers/array.helpers";
import { processServerError } from "../../../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../../../utils/loading-indicator.component";
import { IGraphUser } from "../models/models";
import { ContactOption, ContactPicker } from "../../../files/share/contact-picker/contact-picker.component";

type GroupDetailsModalProps = {
    isOpened: boolean;
    groupId?: string;
    onRenameClick?: () => void;
    onClose?: () => void;
    hideEdit?: boolean;
}

export const GroupDetailsModal: React.FC<GroupDetailsModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
    const [groupName, setGroupName] = React.useState<string>("");
    const [selectedMembers, setSelectedMembers] = React.useState<IGraphUser[]>([]);
    const [selectedOwners, setSelectedOwners] = React.useState<SelectOption[]>([]);
    const [selectedTab, setSelectedTab] = React.useState<string>("members");
    const [selectedUser, setSelectedUser] = React.useState<ContactOption>();
    const [loggedInUser, setLoggedInUser] = React.useState<IGraphUser>();
    const [isLoading, loadingService] = useLoading();

    React.useEffect(() => {
        onInit();
    }, []);

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    const onInit = async () => {
        const currentUser = await getCurrentUser();
        if (currentUser) {
            setLoggedInUser(currentUser);
            await getGroup(props.groupId, currentUser);
        }

    }

    const getCurrentUser = async () => {
        const messageId = loadingService.showMessage("Getting user information");

        try {
            const client = getUsersClient();
            const userResp = await client.getUserDetails("");
            console.log("userResp", userResp);
            return {
                id: userResp.id,
                givenName: userResp.givenName,
                surname: userResp.surname,
                mail: userResp.email,
                userPrincipalName: userResp.userPrincipalName
            } as IGraphUser;
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting current user info.");
            return undefined;
        }
        finally {
            loadingService.hideMessage(messageId);
        }



    }

    const getGroup = async (groupId: string, currentUser: IGraphUser) => {
        loadingService.showLoading("Loading group...", async (hideMessage) => {
            try {

                const client = getGroupsClient();
                const group = await client.getGroup(groupId);
                const sortedUsers = _copyAndSort<UserModel>(group.members, "userPrincipalName", false);
                var members = sortedUsers.map(x => ({
                    key: x.id,
                    id: x.id,
                    givenName: x.givenName,
                    surname: x.surname,
                    mail: x.privateEmail,
                    userPrincipalName: x.userPrincipalName
                } as IGraphUser));
                setSelectedMembers(members ?? []);
                const sortedOwners = group.owners ? _copyAndSort<UserModel>(group.owners, "userPrincipalName", false) : [];
                let selectedOwnersMapped = group.owners ?
                    sortedOwners.map(user => {
                        return {
                            value: user.id,
                            userPrincipalName: user.userPrincipalName,
                            label: `${user.givenName} ${user.surname}`,
                            mail: user.privateEmail
                        } as SelectOption
                    }) : [];

                console.log("selectedOwners", selectedOwnersMapped);
                setSelectedOwners(selectedOwnersMapped ?? []);
                setGroupName(group.name);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while loading members of group.");
            } finally {
                hideMessage();
            }
        });

    }

    const onAddMemberClick = (user: ContactOption) => {
        loadingService.showLoading("Adding member...", async (hideMessage) => {
            try {
                const client = getGroupsClient();
                await client.addMember(props.groupId, user.value!.toString());
                // Reload table
                await onInit();
            } catch (error) {
                processServerError(error, undefined, "An error occurred while adding group member.");
            } finally {
                hideMessage();
                setSelectedUser(undefined);
            }
        });
    }

    const onAddOwnerClick = (user: ContactOption) => {
        loadingService.showLoading("Adding owner...", async (hideMessage) => {
            try {
                const client = getGroupsClient();
                await client.addOwner(props.groupId, user.value!.toString());
                // Reload table
                await onInit();
            } catch (error) {
                processServerError(error, undefined, "An error occurred while adding group owner.");
            } finally {
                hideMessage();
                setSelectedUser(undefined);
            }
        });
    }

    const onRemoveMemberClick = async (memberId: string) => {

        loadingService.showLoading("Removing member...", async (hideMessage) => {
            try {
                const client = getGroupsClient();
                await client.removeMember(props.groupId, memberId);
                // Reload table
                await onInit();
            } catch (error) {
                processServerError(error, undefined, "An error occurred while removing group member.");
            } finally {
                hideMessage();
                setSelectedUser(undefined);
            }
        });

    }

    const onRemoveOwnerClick = async (ownerId: string) => {

        loadingService.showLoading("Removing owner...", async (hideMessage) => {
            try {
                const client = getGroupsClient();
                await client.removeOwner(props.groupId, ownerId);
                // Reload table
                await onInit();
            } catch (error) {
                processServerError(error, undefined, "An error occurred while removing group owner.");
            } finally {
                hideMessage();
                setSelectedUser(undefined);
            }
        });

    }


    const onModalClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    }

    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface style={{ maxWidth: "700px", minHeight: "600px" }}>
            <DialogBody style={{ maxWidth: "inherit" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <span>Group: <i>{`${groupName}`}</i></span>
                        {!props.hideEdit && <Button size="small"
                            appearance="subtle"
                            style={{ marginLeft: "var(--spacingHorizontalM)", fontSize: "var(--fontSizeBase400)" }}
                            onClick={() => { props.onRenameClick() }}
                            icon={<TextEditStyleRegular />}> Rename</Button>}
                    </div>
                </DialogTitle>
                <DialogContent className="edit-application">
                    <div>
                        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { setSelectedUser(undefined); setSelectedTab(data.value as string); }}>
                            <Tab key="members" value="members" >Members</Tab>
                            <Tab key="owners" value="owners">Owners</Tab>
                        </TabList>
                    </div>
                    <div className="tab-content" style={{ minHeight: "500px", marginTop: "10px" }}>
                        {selectedTab == "members" && <>
                            <div style={{ marginBottom: "var(--spacingVerticalM)" }}>
                                <Field label="Add members">
                                    <ContactPicker
                                        value={selectedUser}
                                        placeholder="Find user"
                                        style={{ width: "300px" }}
                                        onChange={(user) => {
                                            if (user) {
                                                setSelectedUser(user);
                                                onAddMemberClick(user);
                                            }
                                        }} />
                                </Field>
                            </div>
                            <Table >
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell key="name" style={{ width: "300px" }} className='groups-table__cell--bold'>Display name</TableHeaderCell>
                                        <TableHeaderCell key="empty" className='groups-table__cell--bold'></TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBodyWithLoading isLoading={!isSubmitted && isLoading}
                                    columnCount={3} loadingMessage="Loading..."
                                    itemCount={selectedMembers ? selectedMembers.length : 0}
                                    noItemsMessage="No users found.">
                                    {selectedMembers && selectedMembers.length > 0 && selectedMembers.map((item) => {
                                        return <TableRow
                                            key={`exam-${item.id}`}>

                                            <TableCell> <PersonDisplay id={item.id}
                                                name={`${item.givenName} ${item.surname}`}
                                                userPrincipalName={item.userPrincipalName} />
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        appearance="transparent"
                                                        icon={<DismissRegular color="red" />}
                                                        onClick={() => { onRemoveMemberClick(item.id) }}
                                                    ></Button>
                                                </div>
                                            </TableCell>

                                        </TableRow>
                                    })}
                                </TableBodyWithLoading>
                            </Table>
                        </>}

                        {selectedTab == "owners" && <>
                            <div style={{ marginBottom: "var(--spacingVerticalM)" }}>
                                <Field label="Add owners">
                                    <ContactPicker
                                        value={selectedUser}
                                        placeholder="Find user"
                                        style={{ width: "300px" }}
                                        onChange={(user) => {
                                            if (user) {
                                                setSelectedUser(user);
                                                onAddOwnerClick(user);
                                            }

                                        }} />
                                </Field>
                            </div>
                            <Table >
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell key="name" style={{ width: "300px" }} className='groups-table__cell--bold'>Display name</TableHeaderCell>
                                        <TableHeaderCell key="empty" className='groups-table__cell--bold'></TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBodyWithLoading isLoading={!isSubmitted && isLoading}
                                    columnCount={3} loadingMessage="Loading..."
                                    itemCount={selectedOwners ? selectedOwners.length : 0}
                                    noItemsMessage="No users found.">
                                    {selectedOwners && selectedOwners.length > 0 && selectedOwners.map((item) => {
                                        return <TableRow
                                            key={`exam-${item.value}`}>

                                            <TableCell><PersonDisplay id={item.value.toString()}
                                                name={`${item.label}`}
                                                userPrincipalName={item.userPrincipalName} /></TableCell>
                                            <TableCell>
                                                {loggedInUser?.id !== item.value.toString() && <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        appearance="transparent"
                                                        icon={<DismissRegular color="red" />}
                                                        onClick={() => { onRemoveOwnerClick(item.value.toString()) }}
                                                    ></Button>
                                                </div>}
                                            </TableCell>

                                        </TableRow>
                                    })}
                                </TableBodyWithLoading>
                            </Table>
                        </>
                        }

                    </div>
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}