import { Button, Label } from "@fluentui/react-components";
import { Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { GLOBAL_STYLES } from "../../styles";
import { JobActionResponseModel, JobActionTriggerOn, JobActionType } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { EmailActionDetails } from "./email-action-details.component";
import { WebHookActionDetails } from "./webhook-action-details.component";


type ActionDetailsModalProps = {
    action: JobActionResponseModel;
    isOpened: boolean;
    onClose: () => void;
}

export const ActionDetailsModal: React.FC<ActionDetailsModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);

    const onClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }
    const { action } = props;
    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    return <div>
        <Dialog open={open} onOpenChange={(event, data) => {
            setOpen(data.open);
            if (!data.open) {
                onClose();
            }

        }}>
            <DialogSurface style={{ minWidth: "700px", maxWidth: "1200px" }}>

                <DialogBody style={{ minWidth: "inherit", maxWidth: "inherit", minHeight: "450px" }}>
                    <DialogTitle action={<Button appearance="transparent" onClick={onClose} icon={<Dismiss24Filled />} />}>Action #{action ? action.id : ""} details</DialogTitle>
                    <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                        {action && action.triggerOn && action.triggerOn == JobActionTriggerOn.Custom &&
                            <div style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                                <Label style={{ fontWeight: 600, margin: "0px", marginBottom: "10px" }}>Condition function:</Label>
                                <pre style={{ backgroundColor: "lightgray", whiteSpace: "pre-wrap" }}>
                                    {action.customConditionFunction}
                                </pre>
                            </div>
                        }
                        {action && action.type == JobActionType.WebHook && action.triggerOn !== JobActionTriggerOn.Custom &&
                            <WebHookActionDetails actionId={action.id} />
                        }
                        {
                            action && action.type == JobActionType.Email && action.triggerOn !== JobActionTriggerOn.Custom &&
                            <EmailActionDetails actionId={action ? action.id : undefined} />
                        }
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    </div>
}