import React from "react";
import { getExperimentsClient } from "../../../services/ai-for-pfd.service";
import { ExperimentResponseModel } from "../../../swagger-clients/ai-for-pfd-clients.service";
import { Body1, Card, CardHeader, CardPreview, Spinner } from "@fluentui/react-components";
import { ThumbnailImage } from "../../../files/thumbnail/thumbnail-image/thumbnail-image.component";
import { getThumbnailsClient } from "../../../services/dashboard.service";
import thumbnail404 from "../../../assets/images/thumbnail-404.png";
import { Alert } from "@fluentui/react-components/unstable";
import { CopilotExampleMappings } from "../example.settings";
import { generalSettings } from "../../../App";

type CopilotExampleProps = {
    experimentId: number;
    onClick: () => void;
}

export const CopilotExample: React.FC<CopilotExampleProps> = (props) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [experiment, setExperiment] = React.useState<ExperimentResponseModel>();
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [exampleTitle, setExampleTitle] = React.useState<string>();
    const [image, setImage] = React.useState<string>();

    React.useEffect(() => { onInit(); }, []);

    const onInit = async () => {
        setIsLoading(true);
        const hasMapping = hasExistingImageMapping(props.experimentId);
        if (props.experimentId > 0) {
            const experimentResp = await getExperiment();
            if (!!experimentResp && !hasMapping) {
                await getSmallThumbnail(experimentResp.flowsheetUniqueId, experimentResp.flowsheetVersion);
            }
        }
        setIsLoading(false);
    }

    const hasExistingImageMapping = (experimentId: number) => {
        const environment = generalSettings.environment ?? "development";
        const exampleInfo = CopilotExampleMappings[environment]?.[experimentId.toString()];
        if (!exampleInfo)
            return false;

        if (!!exampleInfo.title) {
            setExampleTitle(exampleInfo.title);
        }
        if (!!exampleInfo.image) {
            setImage(exampleInfo.image);
        }
        return true;
    }


    const getExperiment = async () => {

        try {
            setErrorMessage(undefined);
            const client = getExperimentsClient();
            const experimentResp = await client.getExperimentById(props.experimentId, false);
            setExperiment(experimentResp);
            return experimentResp;
        } catch (error) {
            console.log(`An error occured while loading experiment #${props.experimentId}`, error);
            setErrorMessage(`An error occured while loading experiment.`);
            return undefined;
        }
    }

    const getSmallThumbnail = async (flowsheetUniqueId: string, flowsheetVersion: number) => {
        try {
            const client = getThumbnailsClient();
            const response = await client.getThumbnailSmall(flowsheetUniqueId, flowsheetVersion);
            if (response) {
                var reader = new FileReader();
                reader.readAsDataURL(response.data);
                reader.onloadend = function () {
                    var base64String = reader.result;
                    // console.log("thumbnail image", base64String);
                    setImage(base64String?.toString());
                }
                reader.onerror = () => {
                    console.log("error while reading thumbnail image");
                    setImage(undefined);
                };
            } else {
                setImage(undefined);
            }

        } catch (error) {
            setImage(undefined);
        }

    }

    return <Card size="small" style={{ cursor: "pointer", marginBottom: "5px", borderRadius: "6px" }} onClick={() => { props.onClick() }}>
        {!isLoading && (experiment || exampleTitle) && <CardHeader
            header={<b>{exampleTitle ?? experiment.name} </b>}
        />}

        <CardPreview style={{ padding: "5px" }}>
            {!isLoading && !!errorMessage &&
                <Alert intent="error"
                    style={{ display: "flex", width: "250px", height: "fit-content", padding: 0, marginBottom: "5px" }}>
                    {errorMessage}
                </Alert>
            }
            {!isLoading && image && <img src={image} className="disable-visual-search" style={{ width: "252px", height: "fit-content" }} alt={`${exampleTitle ?? experiment.name} flowsheet thumbnail`} />}
            {!isLoading && !image && <img className="disable-visual-search" style={{ width: "252px", height: "fit-content" }} src={thumbnail404} alt={`Thumbnail not found.`} />}
            {isLoading && <div style={{ padding: " 0px 5px" }}><Spinner size="medium" appearance="primary" label="Loading..." /></div>}
        </CardPreview>

    </Card>
}