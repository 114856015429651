import React from "react";
import { IPlotly } from "../../../utils/shared.types";
import { processServerError } from "../../../utils/helpers/error.helper";
import { getExperimentElasticSearchClient } from "../../../services/ai-for-pfd.service";
import { CalculationJobOutputModel, CalculationJobResultModel, ExperimentComponentResponseModel, ExperimentResponseModel, SeparationMethodOption } from "../../../swagger-clients/ai-for-pfd-clients.service";
import Plot from 'react-plotly.js';
import { getSignificantFigures } from "../../../utils/helpers/significant-figures";

type SequenceResultGraphProps = {
    sequnceNumber: number;
    experimentVersionId: number;
    experiment: ExperimentResponseModel;
    separationMethod: SeparationMethodOption;
    components: ExperimentComponentResponseModel[];
}

export type SequenceResultGraphType = { refreshData(): void; };

export const SequenceResultGraph = React.forwardRef<SequenceResultGraphType, SequenceResultGraphProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            refreshData() {
                getData();
            }
        }));

    const [chartData, setChartData] = React.useState<IPlotly>({

        layout: {
            autosize: true,
            uirevision: 'true',
            title: `Sequence ${props.sequnceNumber}`,
            config: { displaySpinner: true }
        }
    } as IPlotly);

    React.useEffect(() => {
        getData();
    }, [props.sequnceNumber]);

    const getData = async () => {
        try {
            const client = getExperimentElasticSearchClient();

            const calculationResults = await client.getExperimentSequenceGraphData(props.experimentVersionId, props.sequnceNumber);
            const mappedResults = MapCalculationResultsToIPloty(props.sequnceNumber, calculationResults, props.components, props.separationMethod, props.experiment);
            setChartData(mappedResults);

        } catch (error) {
            processServerError(error, undefined, `An error occurred while loading data for sequence ${props.sequnceNumber}.`, false);
        }
    }

    return <>  <Plot data={chartData.data} layout={chartData.layout} /></>
});

const MapCalculationResultsToIPloty = (
    sequence: number,
    results: CalculationJobResultModel[],
    components: ExperimentComponentResponseModel[],
    separationMethod: SeparationMethodOption,
    experiment: ExperimentResponseModel) => {
    let plotly = {
        data: [
            {
                x: results.map((x: CalculationJobResultModel) => calculateAverage(x.results)),
                y: results.map(x => x.capex + x.opex),
                type: 'scatter',
                mode: 'markers',
                marker: { color: 'var(--colorCompoundBrandStroke)' },
                name: "All values",
                hoverinfo: 'text',
                text: results.map(x => getPopoverText(x, experiment)), // Customize each point individually
            }
        ],
        layout: {
            autosize: true,
            uirevision: 'true',
            title: `Sequence ${sequence}`,
            config: { displaySpinner: false },
            xaxis: { title: { text: "Average purity" } },
            yaxis: { title: { text: "Capex + Opex" } },

        }
    } as IPlotly;

    return plotly;
}

export function getPopoverText(result: CalculationJobResultModel, experiment: ExperimentResponseModel) {

    const components = experiment.components;
    const separationMethod = experiment.separationMethod;
    const purities = result.results.map((item) => ({ name: GetComponentName(item.caS_Number, components), value: item.value }));

    let text = `Sequence: ${result.sequence}<br>Flowsheet ID: ${result.flowsheetId}<br>Energy: ${result.energy} kW<br>` +
        `Capex: ${getSignificantFigures(result.capex, 5)} €<br>Opex: ${getSignificantFigures(result.opex, 5)} €/year<br>`;


    purities.forEach(item => {
        text += `${(separationMethod == SeparationMethodOption.Absorption ? `Recovery in Gas Outlet:${item.name}` : `Mass Fraction (${item.name}): `)}` +
            `${getSignificantFigures(item.value, 5)}<br>`;

    });

    return text;
}

function GetComponentName(casNr: string, components: ExperimentComponentResponseModel[]) {

    const component = components.find(x => x.componentCasNr == casNr);

    return component?.name ?? "Unknown";

}


export function calculateAverage(purities: CalculationJobOutputModel[]) {

    // Calculate the sum
    const sum = purities.reduce((prevVal: number, purity: CalculationJobOutputModel) => purity.value + prevVal, 0);

    // Calculate the average
    const average = sum / purities.length;

    return average;
}