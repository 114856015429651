import React from "react";
import { Checkbox, Field, Input, Label, Popover, PopoverSurface, PopoverTrigger, Tab, TabList, Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { FileModel, FilterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";

import { getUserToken } from "../../services/dashboard.service";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { ParameterDisplay } from "../../components/parameter-display/parameter-display.component";
import MathJaxDisplay from "../../components/mathjax-display/mathjax-display";
import { highlightPlaceholders } from "../../take-home-exams/edit-exam/exercises-table/exercises-table.component";
import { ApplicationName } from "../../components/application-name/application-name.component";
import { EyeOffRegular, EyeRegular } from "@fluentui/react-icons";

type FilterWebFormDetailsProps = {
    filter?: FilterResultModel;
    isLoading: boolean;
    selectedFile: FileModel;
}


export const FilterWebFormDetails: React.FC<FilterWebFormDetailsProps> = (props) => {


    const [accessToken, setAccessToken] = React.useState<string>();
    const [selectedTab, setSelectedTab] = React.useState<string>("intro");

    React.useEffect(() => { onInit(); }, []);
    const onInit = async () => {
        const userToken = await getUserToken();
        setAccessToken(userToken);
    }




    return <div>


        {!!props.filter.enableWebForm && <>
            <div className="input-form-item input-form-item--without-margin">
                <Label style={{ paddingLeft: "var(--spacingHorizontalMNudge)", fontWeight: "bold" }}>
                    Application:
                </Label>
                <ApplicationName style={{ padding: "5px 0px", margin: "0 0 0 10px" }} applicationId={props.filter.webFormApplicationId} />
            </div>

            <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
                <Tab key="intro-tab" value="intro" >Intro</Tab>
                <Tab key="input-parameters-tab" value="input-parameters" >Input parameters</Tab>
                <Tab key="Output-tab" value="output">Output</Tab>
            </TabList>
            <div className="tab-content">
                {selectedTab == "intro" && <div>
                    <MathJaxDisplay html={props.filter.webFormIntro ?? ""} />
                </div>}

                {selectedTab == "input-parameters" &&
                    <div className="s365-table__wrapper">
                        <Table>
                            <TableHeader>
                                <TableRow key="table-header">
                                    <TableHeaderCell key="parameter" style={{ width: "400px" }} className='files-table__cell--bold'>Parameter</TableHeaderCell>
                                    <TableHeaderCell key="alias" style={{ width: "220px" }} className='files-table__cell--bold'>Name</TableHeaderCell>
                                    <TableHeaderCell key="parameter-type" style={{ width: "180px" }} className='files-table__cell--bold'>Description</TableHeaderCell>
                                    <TableHeaderCell key="design-parameter" style={{ width: "150px" }} className='files-table__cell--bold'>Design Parameter</TableHeaderCell>
                                    <TableHeaderCell key="default-value" style={{ width: "100px" }} className='files-table__cell--bold'>Default value</TableHeaderCell>
                                    <TableHeaderCell key="min-value" style={{ width: "100px" }} className='files-table__cell--bold'>Min value</TableHeaderCell>
                                    <TableHeaderCell key="max-value" style={{ width: "100px" }} className='files-table__cell--bold'>Max value</TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBodyWithLoading isLoading={props.isLoading}
                                columnCount={3} loadingMessage="Loading..."
                                itemCount={props.filter?.inputFilterParameters ? props.filter.inputFilterParameters.length : 0}
                                noItemsMessage={`You can add parameters by clicking on the "Add parameter" button.`}>
                                {props.filter && props.filter.inputFilterParameters &&
                                    props.filter.inputFilterParameters.map((item, index) => {
                                        return <TableRow key={`input-param-${index}`}>
                                            <TableCell key={"parameter"} style={{ verticalAlign: "middle" }}>
                                                {!!item.name ? <ParameterDisplay
                                                    value={item.name}
                                                    fileUniqueId={props.selectedFile.uniqueIdentifier}
                                                    versionNumber={props.selectedFile.currentVersionNumber.toString()}
                                                />

                                                    : "-"}
                                            </TableCell>
                                            <TableCell key="name" style={{ verticalAlign: "middle" }}>
                                                {item.webFormName}
                                            </TableCell>
                                            <TableCell key="description" style={{ verticalAlign: "middle" }}>
                                                {item.webFormDescription}
                                            </TableCell>
                                            <TableCell key="design-parameter" style={{ verticalAlign: "middle" }}>
                                                {!!item.webFormIsDesignParameter ? "YES" : "NO"}
                                            </TableCell>
                                            <TableCell key="default-value" style={{ verticalAlign: "middle" }}>
                                                <Popover openOnHover withArrow positioning={"above"} >
                                                    <PopoverTrigger disableButtonEnhancement>
                                                        {!!item.webFormShowDefaultValue ? <EyeRegular title="test" /> : <EyeOffRegular />}
                                                    </PopoverTrigger>

                                                    <PopoverSurface tabIndex={-1}>
                                                        <span> {!!item.webFormShowDefaultValue ? "Default value in displayed in the Web Form" : "Default value is hidden in the Web Form"}</span>
                                                    </PopoverSurface>
                                                </Popover>

                                                {item.webFormDefaultValue}
                                            </TableCell>
                                            <TableCell key="min-value" style={{ verticalAlign: "middle" }}>
                                                {item.webFormMinValue}
                                            </TableCell>
                                            <TableCell key="max-value" style={{ verticalAlign: "middle" }}>
                                                {item.webFormMaxValue}
                                            </TableCell>

                                        </TableRow>
                                    })

                                }

                            </TableBodyWithLoading>
                        </Table>
                    </div>
                }
                {selectedTab == "output" && <div>
                    <MathJaxDisplay html={!!props.filter.webFormOutputDescription ?
                        highlightPlaceholders(props.filter.webFormOutputDescription) : ""} />

                </div>}
            </div>
        </>
        }

    </div>
}