import { Table, TableCell, TableColumnDefinition, TableColumnSizingOptions, TableHeader, TableHeaderCell, TableRow, createTableColumn, useTableColumnSizing_unstable, useTableFeatures } from "@fluentui/react-components";
import React, { useMemo } from "react";
import { TableBodyWithLoading } from "../../../components/table-body-with-loading/table-body-with-loading.component";
import { getAgentsClient } from "../../../services/dispatcher.service";
import { ActiveAgentResponseModel, ActiveJobResponseModel, CurrentJobModel, StatusModel } from "../../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { useLoading } from "../../../utils/loading-indicator.component";
import TimeAgo from 'react-timeago';
import SimulatorTypeComponent from "../../../components/simulator-type/simulator-type.component";
import { DispatcherJobDetails, DispatcherJobDetailsType } from "../../../components/dispatcher-job-details/dispatcher-job-details.component";
import { Link } from "@fluentui/react-components";
import { useMsal } from "@azure/msal-react";
import { UserDisplayName } from "../../../components/user-display-name/user-display-name.component";
import { S365TimeAgo } from "../../../components/s365-time-ago/s365-time-ago.component";

export type ActiveAgentsTableProps = {
    onActiveAgentsChanged?: (agents: ActiveAgentResponseModel[]) => void;
}
export type ActiveAgentsTableType = {
    onJobUpdated: (job: ActiveJobResponseModel) => void;
    onJobUnassignedFromAgent: (model: any) => void;
    onJobAssignedToAgent: (agentId: number, instanceNumber: number, jobId: number, jobExecutionId: number, assignedAt: Date) => void;
    onActiveAgentStatusUpdated: (agentId: number, instanceNumber: number, status: StatusModel, lastUpdateAt: Date) => void;
}

export const ActiveAgentsTable = React.forwardRef<ActiveAgentsTableType, ActiveAgentsTableProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            onJobUpdated(job: ActiveJobResponseModel) {
                onJobUpdated(job);
            },
            onJobUnassignedFromAgent(model: any) {
                onJobUnassignedFromAgent(model);
            },
            onJobAssignedToAgent(agentId: number, instanceNumber: number, jobId: number, jobExecutionId: number, assignedAt: Date) {
                onJobAssignedToAgent(agentId, instanceNumber, jobId, jobExecutionId, assignedAt);
            },
            onActiveAgentStatusUpdated(agentId: number, instanceNumber: number, status: StatusModel, lastUpdateAt: Date) {
                onActiveAgentStatusUpdated(agentId, instanceNumber, status, lastUpdateAt);
            }
        }));

    const [activeAgents, setActiveAgents] = React.useState<ActiveAgentResponseModel[]>([]);
    const [showJobDetailsModal, setShowJobDetailsModal] = React.useState<boolean>(false);
    const [selectedDispatcherJobId, setSelectedDispatcherJobId] = React.useState<number>();
    const refreshAgentsIntervalRef = React.useRef<number>();

    const [isLoading, loadingService] = useLoading();
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const dispatcherJobDetailsRef = React.useRef<DispatcherJobDetailsType>();

    React.useEffect(() => {
        getActiveAgents();
        refreshAgentsIntervalRef.current = window.setInterval(() => {
            getActiveAgents();
        }, 20000);

        // This function will be called when the component is unmounted
        return () => {
            console.log("Clear interval called");
            clearInterval(refreshAgentsIntervalRef.current);
        };
    }, []);


    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        name: { 
            minWidth: 100,
            defaultWidth: 250,           
        },
        buildVersion: {
            minWidth: 100,
            defaultWidth:250,
        },
        lastUpdate: {
            minWidth: 100,
            defaultWidth: 250,
        },
        currentJob: {
            minWidth: 100,
            defaultWidth: 250,
        },
        owner: {
            minWidth: 100,
            defaultWidth: 300
        }
    });

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "buildVersion": return true;
            case "lastUpdate": return true;   
            case "currentJob": return true;
            case "owner": return true;          

            default: return false;
        }
    }

 


    const getActiveAgents = async () => {
        loadingService.showLoading("Loading active agents...", async (hideMessage) => {
            try {
                const client = getAgentsClient();
                const resp = await client.getActiveAgents();
                setActiveAgents(resp ?? []);
                props.onActiveAgentsChanged(resp);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get active agents.");
            } finally {
                hideMessage();
            }
        });
    }

    const onJobUpdated = (job: ActiveJobResponseModel) => {

        if (selectedDispatcherJobId && selectedDispatcherJobId == job.job.id) {
            dispatcherJobDetailsRef.current.externalReload(selectedDispatcherJobId);
        }

    }

    const onActiveAgentStatusUpdated = (agentId: number, instanceNumber: number, status: StatusModel, lastUpdateAt: Date) => {


        let agent = activeAgents.find(x => x.agentId == agentId);
        if (agent) {

            agent.status = status;
            agent.lastUpdateAt = lastUpdateAt;

            const updatedAgents = activeAgents.map(a => a.agentId == agentId ? agent : a);
            setActiveAgents(updatedAgents);
            props.onActiveAgentsChanged(updatedAgents);


        } else {
            getActiveAgents();
        }
    }

    const onJobAssignedToAgent = (agentId: number, instanceNumber: number, jobId: number, jobExecutionId: number, assignedAt: Date) => {


        let agent = activeAgents.find(x => x.agentId == agentId);
        if (agent) {
            agent.currentJob = {
                jobId: jobId,
                jobExecutionId: jobExecutionId,
                assignedAt: assignedAt
            } as CurrentJobModel;

            const updatedAgents = activeAgents.map(a => a.agentId == agentId ? agent : a);
            setActiveAgents(updatedAgents);
            props.onActiveAgentsChanged(updatedAgents);
        }
    }

    const onJobUnassignedFromAgent = (model: any) => {


        let agent = activeAgents.find(x => x.agentId == model.agentId);
        if (agent) {
            agent.currentJob = undefined;
            const updatedAgents = activeAgents.map(a => a.agentId == model.agentId ? agent : a);
            setActiveAgents(updatedAgents);
            props.onActiveAgentsChanged(updatedAgents);
        }
    }

    const getColumns = (): TableColumnDefinition<ActiveAgentResponseModel>[] => [
        createTableColumn<ActiveAgentResponseModel>({
            columnId: "name",
            renderHeaderCell: () => <div style={{ textAlign: "center" }}>Name</div>,
            renderCell: (item: ActiveAgentResponseModel) => {

                return <div style={{ display: "flex" }}>
                    {item.simulators && item.simulators.map((simulator) =>
                        (<SimulatorTypeComponent style={{ width: "20px", height: "20px", marginRight: "var(--spacingHorizontalSNudge)" }} value={simulator.type} />)
                    )}
                    <span> {item.displayName}</span>
                </div>;

            }
        }),
        createTableColumn<ActiveAgentResponseModel>({
            columnId: "buildVersion",
            renderHeaderCell: () => <>Build version / Status</>,
            renderCell: (item: ActiveAgentResponseModel) => {

                return <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{item.buildVersion}</span>
                    <small>{item.status?.title}</small>
                </div>;

            }
        }),
        createTableColumn<ActiveAgentResponseModel>({
            columnId: "lastUpdate",
            renderHeaderCell: () => <>Last update At</>,
            renderCell: (item: ActiveAgentResponseModel) => {

                return <S365TimeAgo date={item.lastUpdateAt} maxPeriod={1} />;

            }
        }),
        createTableColumn<ActiveAgentResponseModel>({
            columnId: "currentJob",
            renderHeaderCell: () => <>Current job</>,
            renderCell: (item: ActiveAgentResponseModel) => {

                return <>{item.currentJob && <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Link onClick={() => { setSelectedDispatcherJobId(item.currentJob.jobId); setShowJobDetailsModal(true); }}>
                        #{item.currentJob.jobId}
                    </Link>
                    <S365TimeAgo date={item.currentJob.assignedAt} maxPeriod={1} />
                </div>
                }</>;

            }
        }),
        createTableColumn<ActiveAgentResponseModel>({
            columnId: "owner",
            renderHeaderCell: () => <>Owner</>,
            renderCell: (item: ActiveAgentResponseModel) => {

                return <>  {item && item.owner && account.localAccountId == item.owner.id && <span style={{ fontStyle: "italic" }}>You</span>}
                    {item && item.owner && account.localAccountId !== item.owner.id && <UserDisplayName userId={item.owner.id} />}
                    {item && !item.owner?.id && <span style={{ fontStyle: "italic" }}>{item.owner.displayName}</span>}
                </>;
            }
        })
    ];
    const columns = useMemo(() => getColumns(), []);

    const items = activeAgents ?? [];

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<ActiveAgentResponseModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    return <div className='s365-table__wrapper'>
        <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
            <TableHeader>
                <TableRow>
                {columns.map((column) => (
                            <TableHeaderCell
                            onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                                className={`${isColumnCentered(column.columnId as string) ? 'column--center' : ''} s365-table__cell--bold`}                              
                                
                                key={column.columnId}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}

                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={isLoading}
                columnCount={5} loadingMessage="Loading..."
                itemCount={activeAgents ? activeAgents.length : 0}
                noItemsMessage="No items found.">
                {activeAgents && activeAgents.length > 0 && activeAgents.map((item) => {
                    return <TableRow key={`job-${item.displayName}-${item.instanceNumber}`}>
                       {columns.map((column) => (
                                <TableCell
                                    className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}

                    </TableRow>
                })}
            </TableBodyWithLoading>
        </Table>

        {showJobDetailsModal && selectedDispatcherJobId &&
            <DispatcherJobDetails
                ref={dispatcherJobDetailsRef}
                jobId={selectedDispatcherJobId}
                isOpened={true}
                onClose={() => setShowJobDetailsModal(false)}
            />}
    </div>

});


