import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, createTableColumn, TableSelectionCell, TableColumnSizingOptions, TableRowId, TableColumnDefinition, useTableFeatures, useTableColumnSizing_unstable, useTableSelection } from "@fluentui/react-components";
import React from "react";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import ShowLocalTime from "../../../components/show-local-time/show-local-time.component";
import { FilterQuery, FilterResultModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { useLoading } from "../../../utils/loading-indicator.component";
import { getFiltersClient } from "../../../services/dashboard.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { FileTableItem } from "../../models/file-table.models";

type SelectFiltersTableProps = {
    file: FileTableItem;
    //can use this to show number of items that are loaded
    onDataLoaded: (count: number) => void;
    onSelectedItemsChanged: (items: SelectFilterModel[]) => void;
    selectedItems: SelectFilterModel[];
}
export type SelectFilterModel = {
    id: number;
    requiredFor?: Set<string>;
}

export const SelectFiltersTable: React.FC<SelectFiltersTableProps> = (props) => {
    const [items, setItems] = React.useState<FilterResultModel[]>([]);
    const [columns, setColumns] = React.useState<TableColumnDefinition<FilterResultModel>[]>([]);
    const [isLoading, loadingService] = useLoading();

    const getPropsSelectedIds = () => {
        console.log("getPropsSelectedIds", props.selectedItems);
        return props.selectedItems?.map(x => +x.id) ?? [];
    }

    const [selectedRows, setSelectedRows] = React.useState(
        () => new Set<TableRowId>(getPropsSelectedIds())
    );
    React.useEffect(() => {
        if (selectedRows.size !== props.selectedItems.length) {
            setSelectedRows(new Set<TableRowId>(getPropsSelectedIds()));
        }
    }, [props.selectedItems]);



    React.useEffect(() => {
        getFilters();
    }, []);

    const getFilters = async (fileId?: number) => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getFiltersClient();
                const query = { fileId: fileId ?? props.file!.id } as FilterQuery;
                let filtersResp = await client.getFilters(query);
                filtersResp = filtersResp?.filter(x => !x.isDeleted) ?? [];
                setItems(filtersResp ?? []);
                props.onDataLoaded(filtersResp?.length ?? 0);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting filters.");
            } finally {
                hideMessage();
            }
        });
    }
    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        checkbox: {
            minWidth: 30,
            idealWidth: 30,
            autoFitColumns: false
        },
        id: {
            minWidth: 70,
            defaultWidth:70,
            idealWidth: 70,
            autoFitColumns: false
        },
        name: {
            minWidth: 100,
            defaultWidth: 9000,
        },
        created: {
            minWidth: 90,
            defaultWidth: 200
        }
    });


    const { getRows, columnSizing_unstable, tableRef, selection: {
        toggleRow,
        toggleAllRows
    }, } = useTableFeatures<FilterResultModel>(
        {
            columns,
            items,
            getRowId: (item: FilterResultModel) => (+item.id)

        },
        [useTableColumnSizing_unstable({ columnSizingOptions }),
        useTableSelection({
            selectionMode: "multiselect",
            selectedItems: selectedRows,
            onSelectionChange: (e, data) => {
                setSelectedRows(new Set<TableRowId>(data.selectedItems));
                const requiredFilters = props.selectedItems.filter(x => !!x.requiredFor && x.requiredFor.size > 0);
                const requiredFilterIds = requiredFilters?.map(x => x.id) ?? [];
                const newFilterIds = [...data.selectedItems].filter(x => requiredFilterIds.find(y => y === x) === undefined);
                const mappedNewFilters = newFilterIds?.map(x => ({ id: x } as SelectFilterModel)) ?? [];
                const allFilters = [...requiredFilters, ...mappedNewFilters];
                props.onSelectedItemsChanged(allFilters);
            },
        })
        ]
    );
    const isRowSelected = (rowId: number) => {
        return selectedRows.has(rowId);
    }

    const rows = getRows((row) => {
        const selected = isRowSelected(+row.item.id);
        return {
            ...row,
            rowId: +row.item.id,
            selected
        };
    });
    React.useEffect(() => {
        const columnsLocal = getAllColumns();
        setColumns(columnsLocal);
    }, [selectedRows, items]);

    const onToggleAllRows = (ev: any) => {
        toggleAllRows(ev);
    }

    const isFilterRequired = (id: number) => {
        const filter = props.selectedItems.find(x => x.id == id);
        if (!filter)
            return false;
        if (!filter.requiredFor || filter.requiredFor.size == 0)
            return false;

        return true;
    }


    const getAllColumns = () => {

        const allRowsSelected = items.length > 0 && [...selectedRows].length > 0 && [...selectedRows].length == items.length;
        const someRowsSelected = items.length > 0 && [...selectedRows].length > 0 && !allRowsSelected;

        let columnsLocal: TableColumnDefinition<FilterResultModel>[] = [
            createTableColumn<FilterResultModel>({
                columnId: "checkbox",
                renderHeaderCell: () => {
                    return <TableSelectionCell
                        checked={
                            allRowsSelected ? true : someRowsSelected ? "mixed" : false
                        }
                        {...columnSizing_unstable.getTableHeaderCellProps("checkbox")}
                        onClick={(ev) => onToggleAllRows(ev)}
                        checkboxIndicator={{ "aria-label": "Select all rows " }}
                    />
                },
                renderCell: (item) => {
                    const selected = isRowSelected(+item.id);

                    return <TableSelectionCell
                        checked={selected}

                        onClick={(ev) => { ev.stopPropagation(); if (!isFilterRequired(+item.id)) { toggleRow(ev, +item.id); } }}
                        {...columnSizing_unstable.getTableCellProps("checkbox")}
                    />
                }
            }),
            createTableColumn<FilterResultModel>({
                columnId: "id",
                renderHeaderCell: () => <>ID</>,
                renderCell: (item: FilterResultModel) => {
                    return <span>{item.id}</span>;

                }
            }),
            createTableColumn<FilterResultModel>({
                columnId: "name",
                renderHeaderCell: () => <>Name</>,
                renderCell: (item: FilterResultModel) => {
                    return <span>{item.name}</span>;

                }
            }),
            createTableColumn<FilterResultModel>({
                columnId: "created",
                renderHeaderCell: () => <>Created</>,
                renderCell: (item: FilterResultModel) => {

                    return <ShowLocalTime date={item.createdAt} />;
                }
            })
        ];

        return columnsLocal;
    }

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {           
            case "created": return true;

            default: return false;
        }
    }

    return <div className='scenarios-table__wrapper'>
        <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()} >
            <TableHeader>
                <TableRow>
                    {columns.map((column) => (
                        <TableHeaderCell
                            onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                           className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                            {...columnSizing_unstable.getTableHeaderCellProps(
                                column.columnId
                            )}
                        >
                            {column.renderHeaderCell()}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={isLoading}
                columnCount={4} loadingMessage="Loading..."
                itemCount={items ? items.length : 0}
                noItemsMessage="No items found.">
                {!isLoading && rows.map(({ item, selected }) => (
                    <TableRow
                        key={`filter-${item.id}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => { toggleRow(null, +item.id); }}
                    >

                        {columns.map((column) => (
                            <TableCell
                                {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                            >
                                {column.renderCell(item)}
                            </TableCell>
                        ))}

                    </TableRow>
                ))}


            </TableBodyWithLoading>
        </Table>
    </div>

}