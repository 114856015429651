import { ComponentResponseModel } from "../swagger-clients/ai-for-pfd-clients.service";
import { FlowsheetObjectsResponseModel } from "../swagger-clients/dispatcher-next-api-clients.service";
import { getComponentsClient } from "./ai-for-pfd.service";



class FlowsheetComponentsService {
    private static __requestsCache: { [key: string]: Promise<ComponentResponseModel[]> } = {};

    public static getFlowsheetComponentsPromise() {
        // Create Promise for loading data, it it doesn't exist already
        let cacheKey = `flowsheet-components`;
        if (FlowsheetComponentsService.__requestsCache[cacheKey] === undefined) {
            let client = getComponentsClient();
            FlowsheetComponentsService.__requestsCache[cacheKey] = client.getAllDwsimComponents();
        }

        return FlowsheetComponentsService.__requestsCache[cacheKey];
    }
}

export default FlowsheetComponentsService;