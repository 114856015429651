import React from "react";
import { FileTableItem } from "../../files/models/file-table.models";
import { useForm } from "react-hook-form";
import { useLoading } from "../../utils/loading-indicator.component";
import { getSupportClient } from "../../services/dashboard.service";
import { processServerError } from "../../utils/helpers/error.helper";
import ReactDOM from "react-dom";
import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, tokens, DialogContent, Field, Textarea, DialogActions, Input } from "@fluentui/react-components";
import { Dismiss24Filled, MailRegular } from "@fluentui/react-icons";
import { GLOBAL_STYLES } from "../../styles";
import { FileDisplayName } from "../file-display-name/file-display-name.component";
import { LoadingButton } from "../loading-button/loading-button.component";
import { ContactFormPostModel, IContactFormPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";

type IContactFormModalProps = {
    modalTitle?: string;
    messageLabel?: string;
    emailSubject?: string;
    buttonLabel?: string;
    file?: FileTableItem;
    onSuccess: () => void;
    onHide: () => void;
}



export const ContactFormModal: React.FC<IContactFormModalProps> = (props) => {

    const { register, handleSubmit, watch, setError, formState: { errors }, setValue } = useForm<IContactFormPostModel>({
        defaultValues: {
            emailSubject: props.emailSubject
        }
    });
    const [isLoading, loadingService] = useLoading();


    React.useEffect(() => {
        if (props.file) {
            setValue("fileUniqueId", props.file.uniqueIdentifier);
            setValue("fileVersionNumber", props.file.currentVersionNumber);
        }
        setValue("hasFileAttached", !!props.file);

    }, [props.file]);



    const onSubmit = (data: IContactFormPostModel) => {
        loadingService.showLoading("Sending...", async (hideMessage) => {
            try {
                const model = new ContactFormPostModel({
                    emailSubject: data.emailSubject ?? "Support request from Dashboard",
                    message: data.message,
                    fileUniqueId: data.fileUniqueId,
                    fileVersionNumber: data.fileVersionNumber,
                    hasFileAttached: data.hasFileAttached
                });
                const client = getSupportClient();
                await client.sendSimulationFileTicket(new ContactFormPostModel(model));

                props.onSuccess();
            } catch (error) {
                processServerError(error, undefined, "An error occurred while sending ticket to support.");
            } finally {
                hideMessage();
            }
        });


    }


    return ReactDOM.createPortal(
        <Dialog
            open={true}>
            <DialogSurface>
                <form onSubmit={handleSubmit(m => onSubmit(m))}>
                    <DialogBody style={{ minHeight: "200px" }}>
                        <DialogTitle style={{ display: "flex", alignItems: "center" }} action={<Button appearance="transparent" onClick={() => { props.onHide(); }} icon={<Dismiss24Filled />} />}>
                            <MailRegular style={{ marginRight: tokens.spacingHorizontalM }} /> {props.modalTitle ?? "support@simulate365.com"}
                        </DialogTitle>
                        <DialogContent>
                            <Field                               
                                label={props.messageLabel ?? "Subject"}
                                className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                            >
                                <Input
                                    placeholder="(Optional)"
                                    id="emailSubject"
                                    {...register("emailSubject", { required: false })}
                                    readOnly={isLoading}
                                />
                            </Field>


                            <Field
                                style={{ marginTop: tokens.spacingVerticalL }}
                                label={props.messageLabel ?? "How can we help? We'll will send the reply to your email. "}
                                className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                                validationState={(!!errors.message || !!errors.message) ? "error" : "none"}
                                validationMessage={
                                    <span>
                                        {errors.message && <>Message is required.</>}

                                    </span>}>
                                <Textarea
                                    id="message"
                                    rows={5}
                                    textarea={{ style: { height: "auto" } }}
                                    {...register("message", { required: true })}
                                    readOnly={isLoading}
                                />
                            </Field>

                            {props.file && <Field
                                style={{ marginTop: tokens.spacingVerticalL }}
                                label="Attached file">
                                <FileDisplayName fileUniqueIdentifier={props.file.uniqueIdentifier} fileVersionNumber={props.file.currentVersionNumber} />
                            </Field>}

                        </DialogContent>
                        <DialogActions>
                            <LoadingButton isLoading={isLoading} type="submit" appearance="primary">{props.buttonLabel ?? "Send to support"}</LoadingButton>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
        , document.body);

}