import React from "react";
import { SeparationMethodOption } from "../../../../swagger-clients/ai-for-pfd-clients.service";
import { StreamModel } from "../../edit-experiment.models";
import { IdealDistillationStream } from "./ideal-distillation-stream.component";
import { AbsorptionStream } from "./absorption-stream.component";
import { IExperimentValidation } from "../../edit-experiment.validation";
import { Alert } from "@fluentui/react-components/unstable";
import { EntrainerStream } from "./entrainer-stream.component";

type StreamsTabProps = {
    separationMethod: SeparationMethodOption;
    streams: StreamModel[];
    onChange?: (streams: StreamModel[]) => void;
    isDetails?: boolean;
    isFormSubmitted?: boolean;
    experimentValidation?: IExperimentValidation;
}

export const StreamsTab: React.FC<StreamsTabProps> = (props) => {
    const { isFormSubmitted, experimentValidation } = props;
    console.log("streams", props.streams);
    return <div>
        {isFormSubmitted && !!experimentValidation && !experimentValidation.streamsGlobal.isValid &&
            <Alert intent="error" style={{ minHeight: "30px", marginBottom: "var(--spacingVerticalMNudge)" }}>
                {experimentValidation.streamsGlobal.validationErrors[0]}
            </Alert>
        }

        {props.separationMethod == SeparationMethodOption.IdealMixtureDistillation &&
            <IdealDistillationStream
                separationMethod={props.separationMethod}
                streams={props.streams}
                onChange={props.onChange}
                isDetails={props.isDetails}
                isFormSubmitted={props.isFormSubmitted}
                experimentValidation={props.experimentValidation} />}

        {props.separationMethod == SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation &&
            <IdealDistillationStream streams={props.streams}
                separationMethod={props.separationMethod}
                onChange={props.onChange}
                isDetails={props.isDetails}
                isFormSubmitted={props.isFormSubmitted}
                experimentValidation={props.experimentValidation} />}

        {props.separationMethod == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer &&
            <EntrainerStream
                separationMethod={props.separationMethod}
                streams={props.streams} onChange={props.onChange} isDetails={props.isDetails}
                isFormSubmitted={props.isFormSubmitted}
                experimentValidation={props.experimentValidation}
            />}

        {props.separationMethod == SeparationMethodOption.Absorption &&
            <AbsorptionStream
                separationMethod={props.separationMethod}
                streams={props.streams} onChange={props.onChange} isDetails={props.isDetails}
                isFormSubmitted={props.isFormSubmitted}
                experimentValidation={props.experimentValidation} />}
    </div>
}