import React, { useMemo } from "react";
import FlowsheetComponentsService from "../../services/flowsheet-components.service";
import { processServerError } from "../../utils/helpers/error.helper";

type DwsimComponentDisplayNameProps = {
    cas_number: string;
    style?: React.CSSProperties;
}


export const DwsimComponentDisplayName: React.FC<DwsimComponentDisplayNameProps> = (props) => {

    const [displayName, setDisplayName] = React.useState<string>();

    React.useEffect(() => {
        getDisplayName();
    }, [props.cas_number]);


    const getDisplayName = async () => {
        try {
            const componentsResp = await FlowsheetComponentsService.getFlowsheetComponentsPromise();
            if (!!componentsResp && componentsResp.length > 0) {
                const component = componentsResp.find(x => x.casNr == props.cas_number);
                setDisplayName(component?.name ?? "Unknown component");
            }
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting all dwsim components.", false);
        }
    }

    return <span style={props.style}>{displayName}</span>
}