import { MenuItem, Tab, TabList, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { Add20Regular, DeleteRegular, DocumentTextRegular, EditRegular, RenameRegular } from "@fluentui/react-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { ContextMenu, ContextMenuType } from "../../files/context-menu/context-menu.component";
import { getGroupsClient } from "../../services/dashboard.service";
import { BreadcrumbItem, GroupTableResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { _copyAndSort } from "../../utils/helpers/array.helpers";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { MembersCount } from "./members-count/members-count.component";
import "./groups-table.styless.scss";
import { GroupDetailsModal } from "./group-details-modal/group-details-modal.component";
import { AddOrRenameGroupModal } from "./add-rename-group-modal/add-rename-group-modal.component";
import { DeleteGroupModal } from "./delete-group-modal.component";

type GroupsTableProps = {

}
export const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];

export const GroupsTable: React.FC<GroupsTableProps> = (props) => {

    const [orderBy, setOrderBy] = React.useState<string>("groupname");
    const [orderByDescneding, setOrderByDescending] = React.useState<boolean>(false);
    const [userGroups, setUserGroups] = React.useState<GroupTableResultModel[]>([]);
    const [isLoading, loadingService] = useLoading();
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [selectedGroup, setSelectedGroup] = React.useState<GroupTableResultModel>();
    const [selectedTab, setSelectedTab] = React.useState<string>("active");
    const [showCreateGroupModal, setShowCreateGroupModal] = React.useState<boolean>(false);
    const [showDetailsGroupModal, setShowDetailsGroupModal] = React.useState<boolean>(false);
    const [showDeleteGroupModal, setShowDeleteGroupModal] = React.useState<boolean>(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        getUserGroups();

    }, []);

    const getUserGroups = async () => {
        loadingService.showLoading("Loading user groups...", async (hideMessage) => {
            try {
                const client = getGroupsClient();
                const userGroupsResp = await client.getUserOwnedGroups();

                const sortedUserGroups = _copyAndSort<GroupTableResultModel>(userGroupsResp, orderBy, orderByDescneding);
                setUserGroups(sortedUserGroups);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting user groups.");
            } finally {
                hideMessage();
            }
        });

    }
    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        menuItems.push(...[
            <MenuItem icon={<DocumentTextRegular />} key="details" onClick={() => { setShowDetailsGroupModal(true); }} > Details</MenuItem>
        ]);
        if (!selectedGroup?.isDeleted) {
            menuItems.push(<MenuItem icon={<DeleteRegular />} key="delete" onClick={() => { setShowDeleteGroupModal(true); }} > Delete</MenuItem>);
        }



        return menuItems;
    }

    const contextMenuRef = React.createRef<ContextMenuType>();
    const activeGroups = userGroups.filter(x => !x.isDeleted);
    const deletedGroups = userGroups.filter(x => x.isDeleted == true);

    const groups = selectedTab == "active" ? activeGroups : deletedGroups;

    return <div className='page-wrapper'>
        <div className="groups-wrapper">
            <div className='toolbar__wrapper'>
                <Toolbar>
                    <ToolbarButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                        onClick={() => { setSelectedGroup(undefined); setShowCreateGroupModal(true); }}
                        appearance='primary'
                        icon={<Add20Regular />}>New Group</ToolbarButton>
                    <LoadingIndicator loadingService={loadingService} />
                </Toolbar>
            </div>

            <div className='groups-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>                  
                    <Breadcrumb  key={`breadcrumb-collaboration`}>Collaboration</Breadcrumb>
                    <Breadcrumb key={`breadcrumb-groups`} active={true}>Groups</Breadcrumb>

                </Breadcrumbs>
            </div>
            <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { setSelectedTab(data.value as string); setSelectedGroup(undefined); }}>
                <Tab key="active" value="active" >Active</Tab>
                <Tab key="deleted" value="deleted">Deleted</Tab>
            </TabList>

            <div className="tab-content">
                <div className='groups-table__wrapper'>
                    <Table >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell key="name" className='groups-table__cell--bold'>Group name</TableHeaderCell>
                                <TableHeaderCell key="group" className='groups-table__cell--bold column--center'>Members count</TableHeaderCell>
                                <TableHeaderCell key="start-time" style={{ width: "100px" }} className='groups-table__cell--bold column--center'>Owners count</TableHeaderCell>
                                <TableHeaderCell key="created" style={{ width: "200px" }} className='groups-table__cell--bold column--center'>Created</TableHeaderCell>
                                <TableHeaderCell key="empty" className='groups-table__cell--bold'></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBodyWithLoading isLoading={isLoading}
                            columnCount={5} loadingMessage="Loading..."
                            itemCount={groups ? groups.length : 0}
                            noItemsMessage="No items found.">
                            {groups && groups.length > 0 && groups.map((item) => {
                                return <TableRow
                                    key={`exam-${item.id}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { setSelectedGroup(item); setShowDetailsGroupModal(true); }}
                                    className={selectedGroup && selectedGroup.id == item.id ? "table-row-selected" : undefined}
                                    onContextMenu={(ev) => { ev.preventDefault(); setSelectedGroup(item); contextMenuRef.current?.showMenu(ev); }}>
                                    <TableCell>{item.groupname}</TableCell>
                                    <TableCell className="column--center"><MembersCount groupId={item.id} /></TableCell>
                                    <TableCell className="column--center">{item.ownersCount}</TableCell>
                                    <TableCell className="column--center"><ShowLocalTime date={item.createdAt}  /></TableCell>
                                    <TableCell></TableCell>

                                </TableRow>
                            })}
                        </TableBodyWithLoading>
                    </Table> </div>



            </div>

        </div>
        <ContextMenu ref={contextMenuRef} hasIcons>
            {getContextItems()}
        </ContextMenu>

        {showCreateGroupModal && <AddOrRenameGroupModal
            isOpened={true}
            groupId={selectedGroup?.id}
            groupName={selectedGroup?.groupname}
            onSuccess={(groupId) => {
                getUserGroups();
                setShowCreateGroupModal(false);

                setSelectedGroup(new GroupTableResultModel({ id: groupId }));
                setShowDetailsGroupModal(true);
            }}
            onClose={() => { setShowCreateGroupModal(false); }}

        />}
        {
            showDetailsGroupModal &&
            <GroupDetailsModal isOpened
                groupId={selectedGroup?.id}
                onRenameClick={() => {
                    setShowDetailsGroupModal(false);
                    setShowCreateGroupModal(true);
                    console.log("Selected group", selectedGroup);
                }}
                onClose={() => { setShowDetailsGroupModal(false); }} />
        }

        {showDeleteGroupModal && selectedGroup && <DeleteGroupModal

            groupId={selectedGroup.id}
            groupName={selectedGroup.groupname}
            isOpened={showDeleteGroupModal}
            onConfirm={() => { setSelectedGroup(undefined); getUserGroups(); setShowDeleteGroupModal(false); }}
            onClose={() => { setShowDeleteGroupModal(false); }}
        />}

    </div >
}