
import React, { useState } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { useNavigate, useParams } from 'react-router-dom';
import { FilesTable, FilesTableSort } from '../../files/files-table/files-table.component';
import { FileTableColumn, FileTableItem } from '../../files/models/file-table.models';
import { SidePanel } from '../../files/side-panel/side-panel';
import { ParentPathLink } from '../../files/parent-path-link/parent-path-link.component';
import { TagOption } from '../../files/tag-picker/tag-picker.component';
import { getFilesClient, getTagsClient } from '../../services/dashboard.service';
import { BreadcrumbItem, FileModel, FileSortFields } from '../../swagger-clients/s365-dashboard-v2-api-clients.service';
import { processServerError } from '../../utils/helpers/error.helper';
import { useLoading } from '../../utils/loading-indicator.component';
import EmptyStarredIcon from '../../assets/empty-starred.png';
import { createTableColumn } from '@fluentui/react-components';
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from 's365-dashboard-v2-file-picker';

type StaredFilesPageProps = {
    hubConnection?: HubConnection
}

function StarredFilesPage(props: StaredFilesPageProps) {

    const [files, setFiles] = React.useState<FileTableItem[]>([]);
    const [showTagInputs, setShowTagInputs] = useState<{ [key: string]: boolean }>({});
    const [selectedItem, setSelectedItem] = React.useState<FileTableItem>();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const [isFilesLoading, setIsFilesLoading] = useState(false);
    const [fileTagDefaultOptions, setFileTagDefaultOptions] = React.useState<TagOption[]>([]);
    const [commentValidationState, setCommentValidationState] = React.useState<'error' | 'warning' | 'success'>();
    const [commentValidationMessage, setCommentValidationMessage] = React.useState<string>();
    const [sortBy, setSortBy] = React.useState<FilesTableSort>({ field: FileSortFields.Name, isDescending: false });

    const navigate = useNavigate();

    React.useEffect(() => {

        getTagDefaultSuggestions();
    }, []);

    React.useEffect(() => {
        if (commentValidationMessage) {
            setCommentValidationMessage(undefined);
        }
        if (commentValidationState) {
            setCommentValidationState(undefined);
        }
    }, [selectedItem]);

    React.useEffect(() => {
        getFilesWithLoading();
    }, [sortBy]);



    const getFilesWithLoading = async () => {
        loadingService.showLoading("Loading...", (hideMessage) => {
            getFiles().finally(() => hideMessage());
        })
    }

    const getFiles = async () => {
        try {
            setIsFilesLoading(true); // This separate isLoading is needed to show empty directory icon

            const client = getFilesClient();
            const resp = await client.getStaredFiles(sortBy.field, sortBy.isDescending);

            if (resp) {
                setFiles(resp ?? []);
                if (selectedItem) {
                    const updatedSelectedItem = resp?.find(x => x.id == selectedItem.id && x.type == selectedItem.type);
                    console.log("updatedSelectedItem", updatedSelectedItem);
                    setSelectedItem({ ...updatedSelectedItem } as FileModel);
                }
            }
        }
        catch (error) {
            processServerError(error, undefined, "An error occurred while getting files.");
        }
        finally {
            setIsFilesLoading(false);
        }
    }

    const getTagDefaultSuggestions = async () => {

        try {

            const client = getTagsClient();

            const fileTagSuggestionResp = await client.getFileTagSuggestions("", 0);

            const filetagOptions = fileTagSuggestionResp.map(x => ({ label: x.name, value: x.id } as TagOption));

            setFileTagDefaultOptions(filetagOptions);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting tag name suggestions.");
        }
    }

    const filePathColumn = {
        column: createTableColumn<FileTableItem>({
            columnId: "location",
            renderHeaderCell: () => <>Location</>,
        }),
        cellValue(fileItem) {
            return <ParentPathLink directoryPaths={fileItem.directoryPaths} />
        },

    } as FileTableColumn;


    return (<div className='page-wrapper'>


        <div className='files-wrapper'>
            <div className='files-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>
                    <Breadcrumb key={`breadcrumb-Starred`} >My Starred</Breadcrumb>
                </Breadcrumbs>
            </div>
            <FilesTable
                isLoading={isFilesLoading}
                loadingService={loadingService}
                files={files}
                additionalColumns={[filePathColumn]}
                sortable
                sortBy={sortBy}
                onSortChange={(data) => { setSortBy(data); }}
                hubConnection={props.hubConnection}
                onSelectedItemChanged={(item) => { setSelectedItem(item) }}
                onShowTagInputsChanged={(value) => setShowTagInputs(value)}
                onReloadFilesEvent={() => getFilesWithLoading()}
                onShowDetails={(item) => { setSelectedItem(item); setShowDetails(true); }} />


            {(!isFilesLoading && !files?.length) && <div className='files-empty-directory'>
                <img className='files-empty-directory__image' src={EmptyStarredIcon} />
                <span className='files-empty-directory__text--main'>Add to starred</span>
                <span className='files-empty-directory__text--sub'>by right-clicking on file/folder and choosing "Add to starred".</span>
            </div>}
        </div>

        {/* Side panel */}
        <SidePanel
            isOpened={selectedItem && showDetails}
            selectedItem={selectedItem}
            fileTagDefaultOptions={fileTagDefaultOptions}
            hubConnection={props.hubConnection}
            loadingService={loadingService}
            onHide={() => setShowDetails(false)}
            onReloadFilesEvent={() => getFilesWithLoading()}
        />

    </div>
    );
}

export default StarredFilesPage;