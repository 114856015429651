import { Button, Tab, TabList } from "@fluentui/react-components";
import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React from "react";
import { FileTableItem } from "../../models/file-table.models";
import { FlowsheetObjects } from "./flowsheet-objects.component";
import { ThumbnailModalImage } from "./thumbnail-modal-image.component";
import "./thumbnail-modal.styless.scss";
import { getFileExtension } from "../../file-type-icon/file-type-icon.helpers";

type ThumbnailModalProps = {
    file: FileTableItem;
    hubConnection: HubConnection;
    isOpened?: boolean,
    onClose?: () => void;
};

export const ThumbnailModal: React.FC<ThumbnailModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState<string>("flowsheet-layout");

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);
    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface style={{ maxWidth: "75vw" }}>
            <DialogBody style={{ maxWidth: "inherit" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>{props.file.name}</DialogTitle>
                <DialogContent>
                    <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
                        <Tab key="flowsheet-layout" value="flowsheet-layout" >Flowsheet Layout</Tab>
                        <Tab key="flowsheet-objects" value="flowsheet-objects">Flowsheet Objects</Tab>
                    </TabList>
                    <div id="tab-content">
                        <ThumbnailModalImage
                            fileUniqueIdentifier={props.file.uniqueIdentifier!}
                            fileId={props.file.id!}
                            fileVersionNumber={props.file.currentVersionNumber!}
                            fileExtension={getFileExtension(props.file.name)}
                            hubConnection={props.hubConnection}
                            style={{ display: (selectedTab == "flowsheet-layout" ? "flex" : "none") }} />

                        <div style={{ display: (selectedTab == "flowsheet-objects" ? "block" : "none") }}>
                            <FlowsheetObjects
                                fileUniqueIdentifier={props.file.uniqueIdentifier!}
                                fileVersionNumber={props.file.currentVersionNumber!}
                                isSelected={selectedTab == "flowsheet-objects"}
                            />
                        </div>
                    </div>
                </DialogContent>
            </DialogBody>

        </DialogSurface>
    </Dialog>

}