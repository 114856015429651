import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Dropdown } from "@fluentui/react-components";
import React from "react";
import { CopyToClipboard } from "../../components/copy-to-clipboard-icon/copy-to-clipboard-icon.component";
import { ParameterDisplay } from "../../components/parameter-display/parameter-display.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { FileModel, FilterParameterType, FilterResultModel, InputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { getParameterTypeText } from "../filter-utilities";
//import "./input-parameters-tab.styless.scss";

type InputParametersDetailsProps = {
    filter?: FilterResultModel;
    isLoading: boolean;
    selectedFile: FileModel;
};

export const InputParametersDetails: React.FC<InputParametersDetailsProps> = (props) => {



    const getFormulaCell = (item: InputFilterParameterResultModel) => {
        if (item.parameterType == FilterParameterType.ExpectedInput) {
            return "-";
        }
        if (item.parameterType == 2) {
            return item.fixedValue !== undefined ? item.fixedValue : "-";
        } else {

            return !!item.formula ? item.formula : "-";
        }
    }

    return <>
        <div className="s365-table__wrapper">
            <Table>
                <TableHeader>
                    <TableRow key="table-header">

                        <TableHeaderCell key="parameter" style={{ width: "400px" }} className='files-table__cell--bold'>Parameter</TableHeaderCell>
                        <TableHeaderCell key="alias" style={{ width: "220px" }} className='files-table__cell--bold'>Alias</TableHeaderCell>
                        <TableHeaderCell key="parameter-type" style={{ width: "180px" }} className='files-table__cell--bold'>Parameter Type</TableHeaderCell>
                        <TableHeaderCell key="value-formula-field" style={{ width: "250px" }} className='files-table__cell--bold'>Value / Formula / Field</TableHeaderCell>
                        <TableHeaderCell key="empty-field" ></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={props.isLoading}
                    columnCount={4} loadingMessage="Loading..."
                    itemCount={props.filter?.inputFilterParameters ? props.filter.inputFilterParameters.length : 0}
                    noItemsMessage="No items found.">
                    {props.filter && props.filter.inputFilterParameters &&
                        props.filter.inputFilterParameters.map((item, index) => {
                            return <TableRow key={`input-param-${index}`}>
                                <TableCell key="name" className="s365-table__cell--center-vertical" style={{ display: "flex", alignItems: "center" }}>
                                    {!!item.name && item.parameterType !== FilterParameterType.Formula ? <>  <ParameterDisplay
                                        value={item.name}
                                        fileUniqueId={props.selectedFile.uniqueIdentifier}
                                        versionNumber={props.selectedFile.currentVersionNumber.toString()}
                                    />
                                        <CopyToClipboard
                                            tooltip="Copy unique parameter identifier"
                                            content={item.name}
                                            style={{ marginLeft: "var(--spacingHorizontalM)" }} />
                                    </> : "-"}
                                </TableCell>
                                <TableCell key="alias" className="s365-table__cell--center-vertical">
                                    {item.alias}
                                </TableCell>
                                <TableCell key="param-type" className="s365-table__cell--center-vertical">
                                    {getParameterTypeText(item.parameterType)}
                                </TableCell>
                                <TableCell key="formula" className="s365-table__cell--center-vertical">{getFormulaCell(item)}</TableCell>
                                <TableCell key="empty" ></TableCell>
                            </TableRow>
                        })

                    }

                </TableBodyWithLoading>
            </Table>
        </div>

    </>;
}