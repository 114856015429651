import { Button } from "@fluentui/react-components";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import React from "react";
import { getJobActionsClient } from "../../services/dispatcher.service";
import { JobActionResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { getActionType, getActionTriggerOn } from "../../utils/job-action.utilities";
import { useLoading } from "../../utils/loading-indicator.component";
import { ActionDetailsModal } from "../job-action-details/action-details-modal.component";
import { TableBodyWithLoading } from "../table-body-with-loading/table-body-with-loading.component";


type JobActionsTableProps = {
    jobId: number;
}

export type JobActionsTableType = {
    getJobActions(): void
}


export const JobActionsTable = React.forwardRef<JobActionsTableType, JobActionsTableProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            getJobActions() {
                getJobActions();
            }
        }));
    const [isLoading, loadingService] = useLoading();
    const [actions, setActions] = React.useState<JobActionResponseModel[]>();
    const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);
    const [showActionDetailsModal, setShowActionDetailsModal] = React.useState<boolean>(false);
    const [selectedAction, setSelectedAction] = React.useState<JobActionResponseModel>();

    React.useEffect(() => {
        getJobActions();
    }, []);

    const getJobActions = async () => {
        if (props.jobId) {
            loadingService.showLoading("Loading...", async (hideMessage) => {

                try {

                    const client = getJobActionsClient();
                    const jobActions = await client.getJobActions(props.jobId);
                    setActions(jobActions);

                } catch (error) {
                    processServerError(error, undefined, "An error occurred while trying to get job actions.");
                }
                finally {
                    hideMessage();
                }
            });
        }

    }

    return <div>
        <Table>
            <TableHeader>
                <TableRow key="table-header">
                    <TableHeaderCell key="parameter" style={{ width: "50px" }} className='table__cell--bold'>Id</TableHeaderCell>
                    <TableHeaderCell key="parameterdesc" style={{ width: "150px" }} className='table__cell--bold'>Type</TableHeaderCell>
                    <TableHeaderCell key="triggerOn" className='table__cell--bold'>Trigger on</TableHeaderCell>
                    <TableHeaderCell key="actions" className='table__cell--bold'></TableHeaderCell>

                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={isLoading}
                    columnCount={4} loadingMessage="Loading..."
                    itemCount={actions ? actions.length : 0}
                    noItemsMessage="No items found.">
                {actions &&
                    actions.map((item, index) => {
                        return <TableRow key={`input-param-${index}`}>
                            <TableCell> {item.id} </TableCell>
                            <TableCell>{getActionType(item.type)} </TableCell>
                            <TableCell>{getActionTriggerOn(item.triggerOn)}</TableCell>
                            <TableCell>
                                <Button appearance="primary"
                                    onClick={() => { setSelectedAction(item); setShowActionDetailsModal(true); }}>
                                    Details</Button>
                            </TableCell>

                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
        {selectedAction && showActionDetailsModal && <ActionDetailsModal
            isOpened={showActionDetailsModal}
            onClose={() => { setShowActionDetailsModal(false) }}
            action={selectedAction}
        />}
    </div>
});