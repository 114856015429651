import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Label, DialogActions } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React from "react";
import { ApplicationTryNowButton } from "../../components/application-try-now-button/application-try-now-button.component";
import { WizardApplication } from "../../components/create-experiment-wizard/create-experiment-wizard.modal";

type WebFormExampleModalProps = {
    isOpened: boolean;
    hubConnection: HubConnection;
    onClose: () => void;
}

export const WebFormExampleModal: React.FC<WebFormExampleModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    React.useEffect(() => {
        window.addEventListener('message', function (e) {
            let message = e.data;
            if (message.type === 'update-s365-webform' && message.height) {
                let iframe = document.querySelector("#s365-webform-iframe");
                // @ts-ignore
                iframe.height = Math.ceil(message.height) + 'px';
            }
        },
            false
        );
    }, []);


    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "90vw" }}>

            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose}
                    icon={<Dismiss24Filled />} />}>Web Form Example</DialogTitle>
                <DialogContent>

                    <iframe id="s365-webform-iframe"
                        src="https://dashboard.simulate365.com/wf/0b8811ba7be64e89"
                        width="100%"
                        height="720"
                        style={{ border: 0 }}
                    ></iframe>

                </DialogContent>
                <DialogActions>
                    <ApplicationTryNowButton hubConnection={props.hubConnection} application={WizardApplication.WebForm} />
                    <Button appearance="secondary" onClick={onModalClose}>Close</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}