import React from "react";
import SimulatorTypeComponent from "../../../components/simulator-type/simulator-type.component";
import { SimulatorType } from "../../../swagger-clients/dispatcher-next-api-clients.service";
import { getSimulatorName } from "../../../utils/helpers/simulator.helpers";


export interface IAgentStatistics {
    cc7SimulatorsCount?: number;
    cc8SimulatorsCount?: number;
    dwsimSimulatorsCount?: number;
}

interface IAgentStatisticsProps {
    statistics: IAgentStatistics;
    title: string;
}

const AgentStatistics: React.FC<IAgentStatisticsProps> = (props) => {

    const getSimulatorStatisticTemplate = (simulatorType: SimulatorType, count: number) => {


        return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginRight: "10px" }}>
            <SimulatorTypeComponent value={simulatorType} style={{width:"20px", height:"20px"}} />
            <span title={getSimulatorName(simulatorType)}>{count}</span>

        </div>

    }


    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <span style={{ margin: "5px 0px" }}>{props.title}</span>
        <div style={{ display: "flex" }}>
            {
                props.statistics && props.statistics.cc7SimulatorsCount && props.statistics.cc7SimulatorsCount > 0 ?
                    getSimulatorStatisticTemplate(SimulatorType.Chemcad7, props.statistics.cc7SimulatorsCount) : null
            }
            {
                props.statistics && props.statistics.cc8SimulatorsCount && props.statistics.cc8SimulatorsCount > 0 ?
                    getSimulatorStatisticTemplate(SimulatorType.Chemcad8, props.statistics.cc8SimulatorsCount) : null
            }

            {
                props.statistics && props.statistics.dwsimSimulatorsCount && props.statistics.dwsimSimulatorsCount > 0 ?
                    getSimulatorStatisticTemplate(SimulatorType.DWSIM, props.statistics.dwsimSimulatorsCount) : null
            }
        </div>


    </div>
}


export default AgentStatistics;