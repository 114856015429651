import { Link, Tooltip } from "@fluentui/react-components";
import React from "react";
import { FileTableItem } from "../models/file-table.models";

type CopyUniqueIdentifierProps = {
    file: FileTableItem;
}

export const CopyUniqueIdentifier: React.FC<CopyUniqueIdentifierProps> = (props) => {

    const [copyUniqueIDToolTip, setCopyUniqueIDToolTip] = React.useState<string>();
    const onCopyClick = () => {
        navigator.clipboard.writeText(`s365v2/${props.file.uniqueIdentifier}/${props.file.currentVersionNumber}`);
        setCopyUniqueIDToolTip("Copied");
        setTimeout(() => { setCopyUniqueIDToolTip(undefined); }, 5000);
    }

    return <Tooltip relationship="label" withArrow visible={!!copyUniqueIDToolTip} content={copyUniqueIDToolTip}>
        <Link onClick={() => { onCopyClick() }} style={{ width: "fit-content", textDecoration: "underline" }}>Copy unique file identifier</Link>
    </Tooltip>
}
