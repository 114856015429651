import { Button, Spinner } from "@fluentui/react-components";
import { Add16Regular, ZoomFit16Regular, ZoomIn16Regular, ZoomOut16Regular } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React from "react";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { getThumbnailsClient } from "../../../services/dashboard.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { flowsheetExtensions, generateThumbnailExtensions } from "../../../global.variables";
import thumbnail404 from "../../../assets/images/thumbnail-404.png";

type ThumbnailModalImageProps = {
    fileId: number,
    fileVersionNumber: number,
    fileUniqueIdentifier: string,
    fileExtension: string,
    hubConnection: HubConnection,
    style?: React.CSSProperties
};

export const ThumbnailModalImage: React.FC<ThumbnailModalImageProps> = (props) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [imageUrl, setImageUrl] = React.useState<string>();
    const wrapperRef = React.createRef<ReactZoomPanPinchRef>();
    const imageRef = React.createRef<HTMLImageElement>();
    const divRef = React.createRef<HTMLDivElement>();
    const componentRef = React.createRef<HTMLDivElement>();
    const isSupported = !!props.fileExtension && flowsheetExtensions.indexOf(props.fileExtension) > -1 && generateThumbnailExtensions.indexOf(props.fileExtension) > -1;

    React.useEffect(() => {
        getThumbnailUrl();

        if (props.hubConnection) {
            props.hubConnection.on('OnThumbnailGenerated', (fileId: number, fileVersionNumber: number) => {
                console.log("HUB method OnThumbnailGenerated  invoked!", fileId, fileVersionNumber);
                if (fileId == props.fileId && fileVersionNumber == props.fileVersionNumber) {
                    getThumbnailUrl();
                }
            });
        }
    }, []);

    React.useEffect(() => {
        if (divRef.current && !isSupported) {
            wrapperRef.current?.zoomToElement(divRef.current as HTMLElement, undefined, 0);
            setIsLoading(false);
        }

    }, [divRef.current]);

    const getThumbnailUrl = async () => {
        try {
            setIsLoading(true);

            const client = getThumbnailsClient();
            const response = await client.getThumbnailFull(props.fileUniqueIdentifier, props.fileVersionNumber);
            if (response) {
                setImageUrl(response);
            }
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting thumbnail image url.", false);
            setIsLoading(false);
        }
    }




    return <div style={{ display: "flex", flexDirection: "column", position: 'relative', ...props.style }} ref={componentRef}>

        <TransformWrapper initialScale={1} minScale={0.1} centerOnInit centerZoomedOut ref={wrapperRef} >
            {({ zoomIn, zoomOut, resetTransform, state, centerView, ...rest }) => {
                return <React.Fragment>
                    {isLoading && <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'white', zIndex: '1000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Spinner label='Loading image...' size='huge' labelPosition='below' />
                    </div>}
                    <div className="tools"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            marginLeft: "auto",
                            alignItems: "center",
                            cursor: "pointer"
                        }}
                    >
                        <span style={{ padding: "0px 5px" }}>{(state.scale * 100).toFixed(0)}%</span>
                        <Button appearance="subtle" icon={<ZoomIn16Regular />} onClick={() => zoomIn()} />
                        <Button appearance="subtle" icon={<ZoomOut16Regular />} onClick={() => zoomOut()} />
                        <Button appearance="subtle" icon={<ZoomFit16Regular />} onClick={() => wrapperRef.current?.zoomToElement(imageRef.current as HTMLElement)} />
                    </div>

                    <TransformComponent wrapperStyle={{ width: "100%", height: "75vh" }}>

                        {isSupported && <img src={!!imageUrl ? imageUrl : (!isLoading ? thumbnail404 : undefined)}
                            ref={imageRef}
                            className="disable-visual-search"
                            alt="Thumbnail image"
                            onLoad={(ev) => {
                                wrapperRef.current?.zoomToElement(imageRef.current as HTMLElement, undefined, 0);
                                setIsLoading(false);

                            }} />}

                        {!isSupported && <div ref={divRef} style={{
                            padding: "var(--spacingHorizontalXL)",
                            width: "100%", aspectRatio: "4/3", display: "flex",
                            alignItems: "center", textAlign: "center",
                            border: "1px solid var(--colorNeutralForeground3)",
                            backgroundColor: "var(--colorNeutralBackground3)"
                        }}
                        >
                            Thumbnail is not supported for your simulator</div>}
                    </TransformComponent>

                </React.Fragment>
            }
            }
        </TransformWrapper>
    </div>
}