import { Label, Tab, TabList, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { ArrowClockwise20Regular, ArrowDownloadRegular, CopyRegular, EditRegular, Play20Regular, SaveRegular, Stop20Regular, Warning24Regular } from "@fluentui/react-icons";
import React, { useEffect, useMemo } from "react";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { BreadcrumbItem, FileModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from '../../components/breadcrumbs/breadcrumbs';
import { useNavigate, useParams } from "react-router-dom";
import { emptyBreadcrumbs } from "../../filters/filters.component";
import { ExperimentRouteParams } from "../flowsheet-experiments.component";
import { ComponentModel, StreamCompositionModel } from "../edit-experiment/edit-experiment.models";
import { getFilesClient } from "../../services/dashboard.service";
import { getFlowsheetsClient } from "../../services/dispatcher.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { ExperimentProcessingStatus, ExperimentResponseModel, PropertyPackageOption, SeparationMethodOption, StartExperimentCalculationPostModel, StopExperimentCalculationPostModel } from "../../swagger-clients/ai-for-pfd-clients.service";
import { getExperimentsClient, getProcessingClient } from "../../services/ai-for-pfd.service";
import { propertyPackageDropdownOptions } from "../edit-experiment/edit-experiment.component";
import { GetSeparationMethodName } from "../edit-experiment/edit-experiment.helpers";
import { ExperimentComponentsTab } from "../edit-experiment/tabs/components-tab/experiment-components-tab.component";
import { ExperimentMethodTab } from "../edit-experiment/tabs/experiment-method-tab.component";
import { OptimizerSettingsTab } from "../edit-experiment/tabs/optimizer-settings-tab.component";
import { SpecificationTab } from "../edit-experiment/tabs/specifications-tab/specifications-tab.component";
import { StreamsTab } from "../edit-experiment/tabs/streams-tab/streams-tab.component";
import saveAs from "file-saver";
import { toast } from "react-toastify";
import { generalSettings, settings } from "../../App";
import { ExperimentResultData, ExperimentResultDataType } from "./result-data/experiment-result-data.component";
import FlowsheetComponentsService from "../../services/flowsheet-components.service";
import { Alert } from "@fluentui/react-components/unstable";
import { ExperimentResultGraphs, ExperimentResultGraphsType } from "./result-graph/experiment-result-graphs-tab.component";
import { ExperimentStatusComponent } from "../../components/experiment-status/experiment-status.component";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { ExperimenBestResults } from "./best-results/experiment-best-results.component";
import { StartExperimentModal } from "./start-experiment-modal/start-experiment-modal.component";
import { JobProgressCount } from "../../components/job-progress-count/job-progress-count.component";

type ExperimentDetailsProps = {
    experimentId?: number;
    isShared?: boolean;
    exampleName?: string;
    dataTableHeight?: string;
}

export const ExperimentDetails: React.FC<ExperimentDetailsProps> = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const routeParams = useParams<ExperimentRouteParams>();
    const [experiment, setExperiment] = React.useState<ExperimentResponseModel>();
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedTab, setSelectedTab] = React.useState<string>("method");
    const [components, setComponents] = React.useState<ComponentModel[]>([]);
    const [isLoading, loadingService] = useLoading();
    const [missingComponents, setMissingComponents] = React.useState<ComponentModel[]>([]);
    const [showStartProcessingDialog, SetShowStartProcessingDialog] = React.useState<boolean>(false);
    const [showStopProcessingDialog, SetShowStopProcessingDialog] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const dataTabRef = React.useRef<ExperimentResultDataType>();
    const graphsTabRef = React.useRef<ExperimentResultGraphsType>();

    useEffect(() => {
        initializePage();
    }, []);

    const initializePage = async () => {
        const experimentResp = await getExperiment();
        if (!!experimentResp && !props.isShared)
            await getFile(experimentResp.flowsheetUniqueId);
    }


    const getExperimentId = () => {
        if (props.isShared)
            return props.experimentId;
        else return routeParams.experimentId;
    }
    const experimentId = useMemo(() => { return getExperimentId(); }, [props.experimentId, routeParams.experimentId]);

    const getFile = async (uniquefileId) => {
        try {
            const client = getFilesClient();
            const resp = await client.getFileLatest(uniquefileId, true);
            if (resp) {
                setSelectedFile(resp.file);
                setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
            }
            return resp;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file information.");
        }
    }


    const getExperiment = async () => {
        const messageId = loadingService.showMessage("Loading experiment...");
        try {
            const client = getExperimentsClient();
            const experimentResp = await client.getExperimentById(+experimentId, false);

            const componentsMapped = experimentResp.components.map((component) => {

                return {
                    ...component,
                    displayName: component.name,
                    casNumber: component.componentCasNr

                } as ComponentModel;

            });
            setComponents(componentsMapped);

            // Find imported components to show warning if there are any
            const allDwsimComponents = await FlowsheetComponentsService.getFlowsheetComponentsPromise();
            const missingComponentsLocal = componentsMapped.filter(x => allDwsimComponents.findIndex(y => y.casNr == x.casNumber) == -1);
            setMissingComponents(missingComponentsLocal);

            const mappedExperimentStreams = experimentResp.streams.map((stream) => {
                const componsitions = stream.compositions.map((composition) => {
                    const component = componentsMapped.find(x => x.casNumber == composition.componentCasNr);
                    return {
                        ...composition,
                        displayName: component.displayName,
                        isEntrainer: component.isEntrainer
                    } as StreamCompositionModel;
                });
                return { ...stream, compositions: componsitions };
            });
            setExperiment({ ...experimentResp, streams: mappedExperimentStreams as any } as any);
            return experimentResp;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting experiment.");
            return null;
        } finally {
            loadingService.hideMessage(messageId);
        }
    }

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }
    const getPropertyPackageName = (propertyPackage: PropertyPackageOption) => {

        const propPackage = propertyPackageDropdownOptions.find(x => x.key == propertyPackage);
        return propPackage?.text;
    }

    const getComponentsFromExperiment = () => {
        const componentsLocal = experiment?.components.map((item) => {
            const comp = components.find(x => x.casNumber == item.componentCasNr);
            return { ...comp, targetValue: item.targetValue, isSeparation: item.isSeparation, isSolvent: item.isSolvent } as ComponentModel;
        });
        return componentsLocal ?? [];
    }

    const onDownloadYmlClick = () => {
        loadingService.showLoading("Downloading file...", async (hideMessage) => {
            try {
                const client = getExperimentsClient();
                const fileResp = await client.downloadYML(selectedFile?.uniqueIdentifier, +experimentId);
                saveAs(fileResp.data, `${experiment.name}_config.yml`);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while downloading YML file.");
            } finally {
                hideMessage();
            }
        });
    }

    const onCopyRunCommandClick = () => {
        const script = getOptimizeScript(experiment.separationMethod);
        let command = `python ${script} --name ${experiment.name} --property-package ${getPropertyPackageName(experiment.propertyPackage)} --mixture ${experiment.name}_config` +

            ` --optimization-steps 100 --n-workers 16  --n-suggestions 16 --timeout-worker ${Number(experiment.convergenceTimeout) + 10} --timeout-convergence ${experiment.convergenceTimeout}` +
            ` --internal-tolerance ${experiment.internalTolerance} --external-tolerance ${experiment.externalTolerance} --es-warmup 50 --es-optimization 50 --use-capex-opex --clip-obj`;
        if (experiment.separationMethod == SeparationMethodOption.IdealMixtureDistillation) {
            command += " --use-pressure-temperature-control --true-controllers";
        }
        command += ` --s365-experiment ${experiment.experimentVersionId} --s365-ai-for-pfd-url ${settings.aiForPFDServiceUrl}`;
        navigator.clipboard.writeText(command);
        toast.success("Run command copied.", { autoClose: 3000 });
    }

    const getOptimizeScript = (separationMethod: SeparationMethodOption) => {
        switch (separationMethod) {
            case SeparationMethodOption.IdealMixtureDistillation:
                return "scripts/optimize.py";
            case SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation:
                return "scripts/optimize.py";
            case SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer:
                return "scripts/optimize.py";
            case SeparationMethodOption.Absorption:
                return "scripts/optimize_absorber.py";
        }

    }
    const onEditClick = () => {
        navigate(`/files/${selectedFile?.uniqueIdentifier}/copilot/edit/${experimentId}`);
    }

    const onConfirmStartExperimentClick = () => {
        loadingService.showLoading(
            "Starting the calculation...",
            (hideMessage) => {
                onConfirmStartExperiment()
                    .finally(() => hideMessage());
            }
        );
    }
    const onConfirmStartExperiment = async () => {
        try {
            const client = getProcessingClient();
            await client.start(new StartExperimentCalculationPostModel({ experimentId: experiment.id, experimentVersionId: experiment?.experimentVersionId }));
            toast.success(`Calculation started successfully.`);
            SetShowStartProcessingDialog(false);
            await getExperiment();
        } catch (error) {
            processServerError(error, undefined, "An error occurred while starting the calculation.");
        }

    }


    const onConfirmStopExperimentClick = async () => {
        loadingService.showLoading(
            "Stoping the calculation...",
            (hideMessage) => {
                onConfirmStopExperiment()
                    .finally(() => hideMessage());
            }
        );
    }

    const onConfirmStopExperiment = async () => {
        try {
            const client = getProcessingClient();
            await client.stop(new StopExperimentCalculationPostModel({ experimentId: experiment.id, experimentVersionId: experiment?.experimentVersionId }));
            toast.success(`Calculation stopped successfully.`);
            SetShowStopProcessingDialog(false);
            await getExperiment();
        } catch (error) {
            processServerError(error, undefined, "An error occurred while stopping the calculation.");
        }

    }

    const onStopProcessingButtonClicked = async () => {

        var experimentResp = await getExperiment();
        if (!!experimentResp) {
            if (experimentResp.processingStatus == ExperimentProcessingStatus.Running) {
                SetShowStopProcessingDialog(true);
            } else {
                toast.error("Experiment can't be stopped while initializing.");
            }
        }
    }

    const onRefreshClick = () => {
        getExperiment();
        if (selectedTab === "data") {
            dataTabRef.current?.getData();
        }
        if (selectedTab === "graphs") {
            graphsTabRef.current?.refreshData();
        }
    }

    return <div className="content-wrapper">
        {!props.isShared && <div className='toolbar__wrapper'>
            <Toolbar>
                {!props.isShared && !!experiment && experiment.processingStatus == ExperimentProcessingStatus.Draft &&
                    <ToolbarButton icon={<EditRegular />} onClick={() => { onEditClick(); }}>Edit</ToolbarButton>
                }
                {!props.isShared && <ToolbarButton icon={<CopyRegular />} onClick={() => { navigate(`/files/${selectedFile?.uniqueIdentifier}/copilot/edit?cloneId=${+experimentId}`) }}>Clone</ToolbarButton>}

                <ToolbarButton appearance='subtle' icon={<ArrowClockwise20Regular />} onClick={() => { onRefreshClick(); }} >Refresh</ToolbarButton>

                {!props.isShared && !!experiment && experiment.processingStatus == ExperimentProcessingStatus.Draft && <ToolbarButton appearance='subtle' key="start"
                    icon={<Play20Regular />}
                    disabled={!!experiment?.isDeleted}
                    onClick={() => { SetShowStartProcessingDialog(true); }}>Start calculation</ToolbarButton>}
                {!props.isShared && !!experiment &&
                    (experiment.processingStatus == ExperimentProcessingStatus.Running
                        || experiment.processingStatus == ExperimentProcessingStatus.Initializing) &&
                    <ToolbarButton appearance='subtle' key="stop"
                        icon={<Stop20Regular />}

                        onClick={() => { onStopProcessingButtonClicked(); }}>Stop calculation</ToolbarButton>}
                {/*Staging removed for Luisa User meeting */}
                {!props.isShared && generalSettings && generalSettings.environment.toLowerCase() !== "production" && <>
                    <ToolbarButton icon={<ArrowDownloadRegular />} disabled={!!experiment?.isDeleted} onClick={() => { onDownloadYmlClick(); }}>Download YML</ToolbarButton>
                    <ToolbarButton icon={<CopyRegular />} disabled={!!experiment?.isDeleted} onClick={() => { onCopyRunCommandClick(); }}>Copy Run command</ToolbarButton>
                </>}

                <LoadingIndicator loadingService={loadingService} />

            </Toolbar>
        </div>}

        {!props.isShared && <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/copilot`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-copilot`} active={true}>Flowsheet Copilot</Breadcrumb>
            </Breadcrumbs>
        </div>}

        <div style={{ display: "flex" }}>
            <div className="input-form" style={{ marginBottom: "20px", flex: 1 }}>
                <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Experiment:
                    </Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{experiment?.name}</p>
                </div>
                <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Status:
                    </Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}><ExperimentStatusComponent status={experiment?.processingStatus} /></p>
                </div>
                <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Property package:
                    </Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{getPropertyPackageName(experiment?.propertyPackage)}</p>
                </div>
                <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Flowsheets:
                    </Label>
                    <JobProgressCount
                        totalJobs={experiment?.totalFlowsheets}
                        calculatedJobs={experiment?.totalConverged}
                        failedJobs={experiment?.totalNotConverged} />
                </div>

                {experiment && experiment.processingStatus == ExperimentProcessingStatus.Failed &&
                    <Alert intent="error" style={{ margin: "10px 0px", width: "100%" }}>
                        <span>{!!experiment && !!experiment.errorMessage ? experiment.errorMessage : "Unknown error occurred."}</span>
                    </Alert>}

            </div>
            {props.isShared && <div style={{ flex: 1, display: "flex", flexDirection: "row-reverse" }}>
                <div style={{ padding: "5px 10px", backgroundColor: "var(--colorNeutralBackground5)", textAlign: "center", maxWidth: "500px", alignSelf: "end" }}>
                    <h3 style={{ marginTop: "0px" }}>Generate and optimize simulation flowsheets using<br /> Artifical Intelligence</h3>
                    <p>This page shows a demo of the Flowsheet Copilot. Have a look at the results for the automated generation and optimization of a process flowsheet!</p>
                    <p style={{ marginBottom: "0px" }}>Request your trial at team@simulate365.com.</p>
                </div>
            </div>

            }

        </div>

        {!!missingComponents && missingComponents.length > 0 && <Alert intent="warning" icon={<Warning24Regular />} className="alert--info-global" style={{ minHeight: "30px", marginBottom: "var(--spacingVerticalMNudge)" }}>
            Your experiment contains components that are not available in the DWSIM database. <br />
            To run the experiment with your defined components, please contact support@simulate365.com and send the component .json files.<br />
            Missing components: {missingComponents.map(x => x.displayName).join(", ")}
        </Alert>}
        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { setSelectedTab(data.value as string); }}>
            <Tab key="method" value="method">1. Method</Tab>
            <Tab key="components" value="components" >2. Components</Tab>
            <Tab key="streams" value="streams"  >3. Streams</Tab>
            <Tab key="specifications" value="specifications" >4. Specifications</Tab>
            <Tab key="settings" value="settings" >5. Optimizer Settings</Tab>
            <Tab key="data" value="data" >6. Data</Tab>
            <Tab key="graphs" value="graphs">7. Graphs</Tab>
            <Tab key="results" value="results">8. Results</Tab>

        </TabList>
        <div className="tab-content" style={{ paddingLeft: "var(--spacingHorizontalL)", paddingRight: "var(--spacingHorizontalL)" }}>

            {selectedTab === "method" &&
                <ExperimentMethodTab
                    selected={experiment?.separationMethod}
                    isLoading={isLoading}
                    isDetails
                    isValid
                />}
            {selectedTab === "components" &&
                <ExperimentComponentsTab
                    components={getComponentsFromExperiment()}
                    separationMethod={experiment?.separationMethod}
                    isDetails
                />}

            {selectedTab === "streams" &&
                <StreamsTab
                    separationMethod={experiment?.separationMethod}
                    streams={experiment?.streams as any}
                    isDetails
                />}

            {selectedTab === "specifications" && experiment &&
                <SpecificationTab specifications={experiment?.specifications as any} isDetails separationMethod={experiment.separationMethod} />}

            {selectedTab === "settings" &&
                <OptimizerSettingsTab
                    settings={{
                        externalTolerance: experiment?.externalTolerance,
                        convergenceTimeout: experiment?.convergenceTimeout,
                        internalTolerance: experiment?.internalTolerance,
                        maximumCost: experiment?.maximumCost
                    }}
                    isDetails />}


            {selectedTab === "data" && experiment &&
                <ExperimentResultData
                    ref={dataTabRef}
                    tableHeight={props.dataTableHeight}
                    experiment={experiment}
                    experimentVersionId={experiment?.experimentVersionId}
                    separationMethod={experiment.separationMethod}
                />}
            {selectedTab === "graphs" && experiment &&
                <ExperimentResultGraphs
                    ref={graphsTabRef}
                    experiment={experiment}
                    experimentVersionId={experiment?.experimentVersionId}
                    components={experiment.components}
                    separationMethod={experiment.separationMethod} />}

            {experiment &&
                <ExperimenBestResults
                    isVisible={selectedTab === "results"}
                    experiment={experiment}
                    isLoading={isLoading}
                    loadingService={loadingService}
                />}

        </div>

        {experiment && !!showStartProcessingDialog && <StartExperimentModal
            experimentId={experiment.id}
            experimentVersionId={experiment.experimentVersionId}
            isOpened={showStartProcessingDialog}
            isRequestInProgress={isLoading}
            onConfirm={() => { onConfirmStartExperimentClick(); }}
            onClose={() => SetShowStartProcessingDialog(false)}
        />}


        <ConfirmationDialog
            title="Stop calculation"
            subText="Are you sure you want to stop the calculation? Your calculation can not be restarted, but you can clone your experiment to start new calculations."
            isOpened={showStopProcessingDialog}
            isRequestInProgress={isLoading}
            onConfirm={() => { onConfirmStopExperimentClick(); }}
            onClose={() => SetShowStopProcessingDialog(false)}
        />


    </div>
}