import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import sidebarLogo from '../../../assets/sidebar-logo.png';
import { HubConnection } from "@microsoft/signalr";
import { EditFilter } from "./edit-filter/edit-filter.component";
import { FilterResultModel } from "../swagger-clients/s365-dashboard-v2-api-clients.service";


type CreateFilterModalProps = {
    fileUniqueId: string;
    isOpened?: boolean;
    onSuccess: (filter: FilterResultModel) => void;
    hubConnection: HubConnection;
    onClose?: () => void;
}

export const CreateFilterModal: React.FC<CreateFilterModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);


    const onClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }


    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "95vw" }}>

            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onClose} icon={<Dismiss24Filled />} />}>
                    Create Filter
                </DialogTitle>
                <DialogContent>
                    <EditFilter
                        hubConnection={props.hubConnection}
                        isModal
                        fileUniqueId={props.fileUniqueId}
                        onFilterCreated={(filterResp: FilterResultModel) => { props.onSuccess(filterResp); onClose(); }} />

                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}
