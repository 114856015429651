import React from "react";
import { SaveFileModal, SaveFileModel } from "../../components/save-file-modal/save-file-modal.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { getExportDataClient } from "../../services/sensitivity-studies.service";
import { GenerateExcelRequestModel } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { FileGenerationProgressModal } from "./file-generation-progress-modal/file-generation-progress-modal.component";

type ExportDataExcelProps = {
    flowsheetParentDirectoryUniqueId?: string;
    isVisible: boolean;
    includeData?: boolean;
    studyId: number;
    studyVersionId: number;
    includeCovergedData?: boolean;
    onVisibleChanged: (isVisible) => void;
}

export const ExportDataExcel: React.FC<ExportDataExcelProps> = (props) => {

    const [showProgressModal, setShowProgressModal] = React.useState<boolean>(false);
    const [requestId, setRequestId] = React.useState<string>();
    const generateExcelFile = async (data: SaveFileModel) => {
        try {
            const client = getExportDataClient();
            const genrationResp = await client.generateExcel(new GenerateExcelRequestModel({
                filename: data.name,
                studyId: props.studyId,
                studyVersionId: props.studyVersionId,
                parentDirectoryUniqueId: data.parentDirectoryUniqueId,
                uploadConflictAction: data.conflictAction,
                writeResults: props.includeData,
                includeConvergedResults: props.includeCovergedData

            }));
            setRequestId(genrationResp?.requestId);
            setShowProgressModal(true);
          
        } catch (error) {
            processServerError(error, undefined, "An error occurred while exporting data to Excel file.");
        }

    }
    const getModalTitle=()=>{
           if(props.includeData){
            return "Export all data to Excel";
           }
           if(props.includeCovergedData){
            return "Export converged data to Excel";
           }
           if(!props.includeData && !props.includeCovergedData){
            return "Export input data to Excel";
           }       
    }

    const modalTitle= getModalTitle();

    return <>
        {props.isVisible &&
            <SaveFileModal
                isOpened={true}
                modalTitle={modalTitle}
                fileExtension="xlsx"
                fileExtensionText="Excel Workbook (*.xlsx)"
                parentDirectoryUniqueId={props.flowsheetParentDirectoryUniqueId}
                onHide={() => { props.onVisibleChanged(false); }}
                onSuccess={generateExcelFile} />}

        {requestId && showProgressModal &&
            <FileGenerationProgressModal
                requestId={requestId}
                isOpened={showProgressModal}
                onHide={() => { setShowProgressModal(false);   props.onVisibleChanged(false); }}
            />}
    </>
}