import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, Input } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { getFilesClient } from "../../services/dashboard.service";
import { GLOBAL_STYLES } from "../../styles";
import { RenameDirectoryPostModel, RenameFilePostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { getFileExtension } from "../file-type-icon/file-type-icon.helpers";

type RenameFileModalProps = {
    id: number,
    currentName: string,
    isFolder: boolean,
    isOpened?: boolean,
    onSuccess: () => void,
    onClose?: () => void
};

type RenameFileModel = {
    name: string,
    extension?: string,
    nameExists?: string
};

export const RenameFileModal: React.FC<RenameFileModalProps> = (props) => {

    const { register, handleSubmit, watch, setError, clearErrors, setValue, formState: { errors }, reset } = useForm<RenameFileModel>();
    const [open, setOpen] = React.useState(false);
    const [isLoading, loadingService] = useLoading();


    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    React.useEffect(() => {

        if (props.currentName) {
            const extension = getFileExtension(props.currentName);
            if (!!extension) {
                const nameWithoutExtension = props.currentName.split(`.${extension}`)[0];
                setValue("name", nameWithoutExtension);
                setValue("extension", extension);
            } else {
                setValue("name", props.currentName);
                setValue("extension", undefined);
            }


        }


    }, [props.currentName, props.isOpened]);


    const onSubmit = async (data: RenameFileModel) => {
        loadingService.showLoading("Renaming...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                if (!!props.isFolder) {
                    console.log("rename folder");
                    let postModel = new RenameDirectoryPostModel();
                    postModel.directoryId = props.id;
                    postModel.newName = data.name;
                    await client.renameDirectory(postModel);
                    toast.success("Folder was renamed.");
                }
                else {
                    console.log("rename file");
                    let postModel = new RenameFilePostModel();
                    postModel.fileId = props.id;
                    postModel.newName = data.name + (data.extension ? `.${data.extension}` : "");
                    await client.renameFile(postModel);
                    toast.success("File was renamed.");
                }

                setOpen(false);
                props.onSuccess();

            } catch (error) {
                const fileType = props.isFolder ? "folder" : "file";
                processServerError(error, setError, `An error occurred while renaming ${fileType}.`);
            } finally {
                hideMessage();
            }
        });
    }
    const fileExtension = watch("extension");
    const onModalClose = () => {
        setOpen(false);
        reset();
        if (props.onClose)
            props.onClose();
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {
        reset();

        setOpen(data.open);
        if (data.open == false) {
            if (props.onClose)
                props.onClose();
        }
    }}>
        <DialogSurface>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogBody>
                    <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Rename</DialogTitle>
                    <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                        <Field
                            className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                            validationState={(errors.name || errors.nameExists) ? "error" : "none"}
                            validationMessage={
                                <span>
                                    {errors.name && (
                                        props.isFolder ? "Folder name is required" : "File name is required")
                                    }
                                    {errors.nameExists && <>{errors.nameExists.message}</>}
                                </span>}>
                            <Input
                                id="name"

                                contentAfter={fileExtension && <span>.{fileExtension}</span>}
                                {...register("name", { required: true })}
                                onChange={(ev, data) => { clearErrors('nameExists'); setValue("name", data.value) }}
                            />
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={isLoading} appearance="primary" type="submit" >Rename</LoadingButton>
                    </DialogActions>
                </DialogBody>
            </form>
        </DialogSurface>
    </Dialog>);
}