import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner } from "@fluentui/react-components";
import { isError } from "@tanstack/react-query";
import React from "react";
import { UploadFileResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";

type GenerateExcelFileStatusModalProps = {
    isOpened: boolean;
    isLoading: boolean;
    isError: boolean;
    uploadedFile?: UploadFileResponseModel;
    onHide: () => void;
}

export const GenerateExcelFileStatusModal: React.FC<GenerateExcelFileStatusModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);
    const onModalClose = () => {
        setOpen(false);
        if (props.onHide)
            props.onHide();
    }
    const { isLoading, isError, uploadedFile } = props;

    return <Dialog

        open={open}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle>Generate Excel file</DialogTitle>
                <DialogContent>
                    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        {isLoading && <Spinner size="extra-small" appearance="primary" label="File is being generated..." />}
                        {isError && !isLoading && <span style={{ color: "red" }}>An error occurred while generating file.</span>}
                        {!isLoading && !isError && <span>
                            <b>{uploadedFile?.filename}</b> was successfully generated.
                            <br /> File location: <b>{uploadedFile?.simulatePath}</b>.
                        </span>}
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button appearance="secondary" onClick={onModalClose} type="button">Close</Button>
                </DialogActions>

            </DialogBody>

        </DialogSurface>


    </Dialog>

}