import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from "@fluentui/react-components";
import React from "react";
import { JobInputParameterResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { ParameterDisplay } from "../parameter-display/parameter-display.component";
import { TableBodyWithLoading } from "../table-body-with-loading/table-body-with-loading.component";

type InputParametersTableProps = {
    inputParameters: JobInputParameterResponseModel[];
    fileUniqueId: string;
    fileVersionNumber: string;
    isLoading: boolean;
}

export const InputParametersTable: React.FC<InputParametersTableProps> = (props) => {

    const { inputParameters } = props;

    return <div>
        <Table>
            <TableHeader>
                <TableRow key="table-header">
                    <TableHeaderCell key="parameter" style={{ width: "300px" }} className='table__cell--bold'>Parameter</TableHeaderCell>
                    <TableHeaderCell key="parameterdesc" style={{ width: "230px" }} className='table__cell--bold'></TableHeaderCell>
                    <TableHeaderCell key="value" className='table__cell--bold'>Value</TableHeaderCell>

                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={props.isLoading}
                columnCount={3} loadingMessage="Loading..."
                itemCount={inputParameters ? inputParameters.length : 0}
                noItemsMessage="No items found.">
                {inputParameters &&
                    inputParameters.map((item, index) => {
                        return <TableRow key={`input-param-${index}`}>
                            <TableCell>
                                <ParameterDisplay
                                    value={item.parameter}
                                    fileUniqueId={props.fileUniqueId}
                                    versionNumber={props.fileVersionNumber} />

                            </TableCell>
                            <TableCell>

                            </TableCell>
                            <TableCell>
                                {item.value}
                            </TableCell>

                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
    </div>;
}