import React from "react";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { getApplicationsClient } from "../../services/dispatcher.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { toast } from "react-toastify";
import { getGroupsClient } from "../../services/dashboard.service";


type DeleteGroupModalProps = {
    groupId: string;
    groupName: string;
    isOpened: boolean;
    onConfirm: () => void;
    onClose: () => void;


}


export const DeleteGroupModal: React.FC<DeleteGroupModalProps> = (props) => {

    const [isRequestInProgress, setIsRequestInProgress] = React.useState<boolean>(false);

    const onDeleteGroupClick = async () => {
        try {
            setIsRequestInProgress(true);
            const client = getGroupsClient();
            await client.deleteGroup(props.groupId);
            toast.success("Group was successfully deleted.");
            props.onConfirm();
        } catch (error) {
            processServerError(error, undefined, "An error occurred while deleting group.");
        } finally {
            setIsRequestInProgress(false);
        }

    }

    return <ConfirmationDialog
        title="Delete group"
        subText={`Are you sure you want to delete '${props.groupName}' group?`}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        isRequestInProgress={isRequestInProgress}
        onConfirm={onDeleteGroupClick}
        isOpened={props.isOpened}
        onClose={props.onClose}

    />
}