import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Popover, PopoverSurface, PopoverTrigger, Tooltip } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React, { useMemo } from "react";
import { EditDoeExperimentInputParametersTab } from "../../edit-experiment/input-parameters-tab.component";
import { ValidationResult } from "../../../utils/helpers/validation.helpers";
import { FilterResultModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { INewExperiment, ParametersModel } from "../../edit-experiment/experiment.models";
import { validateDoeExperiment } from "../../edit-experiment/edit-experiment.component";
import { toast } from "react-toastify";
import { getDoeExperimentsClient } from "../../../services/excel-runner.service";
import { AddExperimentDataRowsPostModel, ExperimentParameterPostModel, ExperimentRowModel } from "../../../swagger-clients/excel-runner-api-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { LoadingButton } from "../../../components/loading-button/loading-button.component";

type AddExperimentRowsModalProps = {
    isOpened?: boolean;
    isExample?: boolean;
    experimentId: number;
    experiment: INewExperiment;
    filter: FilterResultModel;
    onSuccess: () => void;
    onClose: () => void;
};

export const AddExperimentRowsModal: React.FC<AddExperimentRowsModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [isRequestInProgress, setIsRequestInProgress] = React.useState<boolean>(false);
    const [inputRows, setInputRows] = React.useState<ParametersModel[]>([]);
    const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
    const [showExampleTooltip, setShowExampleTooltip] = React.useState<boolean>(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const experiment = useMemo(() => ({ ...props.experiment, inputParameters: inputRows } as INewExperiment), [props.experiment, inputRows]);

    const onAddDataRows = async () => {
        setIsFormSubmitted(true);

        console.log("On save", experiment, props.filter);

        let validationResult = validateDoeExperiment(experiment, props.filter);
        console.log("validationResult", validationResult);
        if (validationResult.isInvalid()) {

            let globalErrors = validationResult.getGlobalErrors();
            if (globalErrors && globalErrors.length > 0) {
                toast.error(globalErrors.join("\n"));
            }
        }
        else {
            try {
                setIsRequestInProgress(true);
                const client = getDoeExperimentsClient();

                let postModel = new AddExperimentDataRowsPostModel();
                postModel.inputDataRows = experiment.inputParameters.map(item => {
                    let parameters = [];
                    for (const [key, value] of Object.entries(item)) {
                        if (!isNaN(+key))
                            parameters.push(new ExperimentParameterPostModel({ filterParameterId: +key, value: +value }));
                    }

                    return new ExperimentRowModel({ parameters: parameters });
                });

                await client.addExperimentDataRows(props.experimentId, postModel);
                toast.success("Data added successfully.");
                setOpen(false);
                props.onSuccess();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while adding data.");

            } finally {
                setIsRequestInProgress(false);
            }


        }
    }

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }


    let validationResult = isFormSubmitted ? validateDoeExperiment(experiment, props.filter) : new ValidationResult(undefined);

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "95vw" }}>

            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={!isRequestInProgress ? onModalClose : undefined}
                    icon={!isRequestInProgress ? <Dismiss24Filled /> : null} />}>Add data</DialogTitle>
                <DialogContent>
                    <EditDoeExperimentInputParametersTab
                        experiment={experiment}
                        validationResult={validationResult}
                        filter={props.filter}
                        isFormSubmitted={isFormSubmitted}
                        isLoading={isRequestInProgress}
                        onInputParametersChange={(parameters) => { setInputRows(parameters); }}
                    />
                </DialogContent>
                <DialogActions style={{ alignItems: "center" }}>
                    {!!props.isExample && <span>Adding data is not possible in this demo.</span>}

                    <LoadingButton
                        isLoading={isRequestInProgress}
                        appearance="primary"
                        onClick={onAddDataRows}
                        disabled={isRequestInProgress || props.isExample}
                    >
                        Save
                    </LoadingButton>

                    <Button appearance="secondary"
                        disabled={isRequestInProgress}
                        onClick={onModalClose}>Cancel</Button>

                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}