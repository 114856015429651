import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from "@fluentui/react-components";
import React from "react";
import { PersonDisplay } from "../../components/person-display/person-display.component";
import { SignificantFigures } from "../../components/significant-figures/significant-figures.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { getExamDataClient } from "../../services/take-home-exams.service";
import { ExamDataRowResultModel } from "../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingService, useLoading } from "../../utils/loading-indicator.component";
import { IExercise } from "../shared/exams.models";

type ExamDataProps = {
    exercises: IExercise[];
    isLoading: boolean;
    examId: number;
    loadingService: LoadingService;
}
export type ExamDataType = {
    getExamData: () => void
}

export const ExamData = React.forwardRef<ExamDataType, ExamDataProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            getExamData() {              
                getExamData();
            }
        }));
    const [rows, setRows] = React.useState<ExamDataRowResultModel[]>([]);

    React.useEffect(() => {
        getExamData();
    }, []);

    const getExamData = async () => {
        props.loadingService.showLoading("Loading exam data...", async (hideMessage) => {
            try {
                const client = getExamDataClient();
                const data = await client.getExamData(props.examId);
                setRows(data);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get exam data.");
            } finally {
                hideMessage();
            }
        });
    }

    const getAllParameters = () => {
        const { exercises } = props;
        let allParameters: string[] = [];
        for (var i = 0; i < exercises.length; i++) {
            const subExercises = exercises[i].subExercises;
            if (subExercises && subExercises.length > 0) {
                for (var j = 0; j < subExercises.length; j++) {
                    var inputParams = subExercises[j].inputParameters.map(inParam => inParam.generatedName);
                    var outputParams = subExercises[j].outputParameters.map(outParam => outParam.generatedName);
                    allParameters.push(...inputParams, ...outputParams);
                }

            } else {
                var inputParams = exercises[i].inputParameters.map(inParam => inParam.generatedName);
                var outputParams = exercises[i].outputParameters.map(outParam => outParam.generatedName);
                allParameters.push(...inputParams, ...outputParams);
            }

        }
        return allParameters;
    }
    const getParameterCellValue = (item: ExamDataRowResultModel, paramName: string) : number => {
        var cell = item.cells.find(x => x.generatedName == paramName);
        return cell ? Number(cell.value) : null;
    }

    const allParameters = getAllParameters();

    return <div style={{ overflowX: 'auto' }}>
        <Table style={{ tableLayout: "auto" }}>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell key="student" style={{ minWidth: "300px" }} className='exams-table__cell--bold'>Student</TableHeaderCell>
                    <TableHeaderCell key="email" style={{ minWidth: "300px" }} className='exams-table__cell--bold'>Email</TableHeaderCell>
                    {allParameters && allParameters.length > 0 && allParameters.map(param => {
                        return <TableHeaderCell key={param} className='exams-table__cell--bold column--center'>{param}</TableHeaderCell>
                    })}
                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={props.isLoading}
                columnCount={2 + allParameters.length} loadingMessage="Loading..."
                itemCount={rows ? rows.length : 0}
                noItemsMessage="No items found.">
                {rows.map((row, index) => {
                    return <TableRow>
                        <TableCell><PersonDisplay id={row.userId}
                            name={row.fullName}
                            userPrincipalName={`${row.username}@simulate365.com`} /></TableCell>
                        <TableCell>{row.email}</TableCell>
                        {allParameters && allParameters.length > 0 && allParameters.map(param => {
                            return <TableCell className="column--center"><SignificantFigures value={getParameterCellValue(row, param)} significantFigures={4} /></TableCell>
                        })}
                    </TableRow>
                })}
            </TableBodyWithLoading>
        </Table>
    </div>
});