import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import React from "react";
import { settings } from "../../../App";
import { getPrivacyPolicyClient, getUserToken } from "../../../services/dashboard.service";
import { EulaAcceptanceSource, EulaStatusPostModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";


type EulaModalProps = {
    isOpened?: boolean;
    errorMessage?: string;
    onClose: () => void;
}


export const EulaModal: React.FC<EulaModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    React.useEffect(() => {
        setErrorMessage(props.errorMessage);
    }, [props.errorMessage]);



    const onAccept = async () => {
        try {

            const accessToken = await getUserToken();
            var model = new EulaStatusPostModel({
                accessToken: accessToken,
                siteId: "s365v2",
                eulaAcceptanceSource: EulaAcceptanceSource.Dashboard
            });

            const client = getPrivacyPolicyClient();
            await client.acceptEula(model);
            onModalClose();

        }
        catch (error) {
            console.log("An error ocurred while accepting EULA.", error);

            setErrorMessage("An error occurred while trying to accept EULA.");
        }
    }


    const onModalClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    }

    return <Dialog open={open} modalType="alert" onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface style={{ maxWidth: "90vw" }}>
            <DialogBody style={{ maxWidth: "inherit" }}>
                <DialogTitle>End-user license agreement</DialogTitle>

                <DialogContent style={{ height: "80vh", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <iframe src="/eula/CGC-Cloud-EULA.html" style={{ width: "100%", flex: 1, border: 0 }}></iframe>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {errorMessage && <div style={{ marginRight: "var(--spacingHorizontalMNudge)" }}>
                            <span style={{ color: "red" }}>{errorMessage} Please refresh page and try again.</span>
                        </div>}
                        <Button appearance="primary" disabled={!!errorMessage} onClick={onAccept} >I Accept</Button>
                    </div>


                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}