import { useMsal } from "@azure/msal-react";
import { PositioningShorthandValue, Spinner } from "@fluentui/react-components";
import { Add20Regular, Globe20Regular, PersonArrowRight20Regular } from "@fluentui/react-icons";
import React from "react";
import { toast } from "react-toastify";
import { GrantAccessCallout, ISharepointGroup, ShareModel } from "../../../../components/grant-access-callout/grant-access-callout.component";
import { IPerson } from "../../../../components/has-access-side-people-picker/has-access-side-people-picker.component";
import { HasAccessSidePersona } from "../../../../components/has-access-side-persona/has-access-side-persona.component";
import { getDashboardSharingClient, getGroupsClient } from "../../../../services/dashboard.service";
import { getSharingClient } from "../../../../services/dispatcher.service";
import { graphClient } from "../../../../services/msgraph.service";
import { SharedWithDestinationType, SharedWithResponseModel, ShareWithPostModel, StopSharingPostModel } from "../../../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../../../utils/helpers/error.helper";
import { useLoading } from "../../../../utils/loading-indicator.component";

type AgentShareProps = {
    agentGroupId: number;
    agentName?: string;
}

export const AgentShare: React.FC<AgentShareProps> = (props) => {

    const [userOrgGroup, setUserOrgGroup] = React.useState<ISharepointGroup>();
    const [sharedWith, setSharedWith] = React.useState<SharedWithResponseModel[]>();
    const [isCalloutVisible, setIsCalloutVisible] = React.useState<boolean>(false);
    const [isUserGlobalAdministrator, setIsUserGlobalAdministrator] = React.useState<boolean>(false);
    const [isGlobalCalloutVisible, setIsGlobalCalloutVisible] = React.useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();

    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    React.useEffect(() => {

        onInit();
    }, []);

    const onInit = async () => {
        await getUserOrgGroup();
        await getSharedWith();
        await checkIfUserIsAdmin();
    }

    const getUserOrgGroup = async () => {

        const groups = await loadUserGroups().catch((error) => {
            console.log("An error occurred while loading user groups.", error);
            toast.error("An error occurred while loading user groups.");
            return [];
        });;


        //  var userOrgGroup = groups.value.find(x => x.displayName && x.displayName.toLowerCase().indexOf('@') !== -1);
        const userOrgGroupResp = groups.find((group) => String(group.displayName).startsWith("@"));

        console.log("User groups:", groups, userOrgGroupResp);

        setUserOrgGroup(userOrgGroupResp);
    }

    const checkIfUserIsAdmin = async () => {
        try {
            const client = getDashboardSharingClient();
            const response = await client.isUserAdmin();
            setIsUserGlobalAdministrator(response.isAdmin);

        } catch (error) {
            console.log("An error occurred while trying to check if user is Admin.", error);
        }
    }

    const loadUserGroups = async () => {
        const client = getGroupsClient();
        return client.getUserMemberOfGroups();
    }

    const getSharedWith = async () => {
        loadingService.showLoading("Getting shared with...", async (hideMessage) => {
            try {
                if (props.agentGroupId) {

                    const client = getSharingClient();
                    const sharedWithList = await client.getAgentGroupSharedWith(props.agentGroupId);
                    const mappedShareWith = sharedWithList.map(x => {
                        if (x.destinationType == SharedWithDestinationType.EveryonePublic) {
                            return { ...x, name: "Everyone (Public)" } as SharedWithResponseModel;
                        }
                        return x;
                    })
                    setSharedWith(mappedShareWith);
                }

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting shared with data.");

            } finally {
                hideMessage();
            }
        });

    }

    const onShare = async (model: ShareModel) => {
        loadingService.showLoading("Sharing...", async (hideMessage) => {
            try {
                var postModels: ShareWithPostModel[] = [];
                if (model.withEveryone == true) {
                    postModels.push({ destinationId: null, destinationType: SharedWithDestinationType.EveryonePublic } as ShareWithPostModel);
                }
                else
                    if (model && model.recipients && model.recipients.length > 0) {

                        postModels = model.recipients.map(x => ({
                            destinationId: x.recipientId,
                            destinationType: x.isGroup == true ? SharedWithDestinationType.Group : SharedWithDestinationType.User
                        } as ShareWithPostModel));
                    }
                console.log("AgentShare model, postModels", model, postModels);

                if (postModels.length > 0) {
                    const client = getSharingClient();
                    const addedObjects = await client.shareAgentGroup(props.agentGroupId, postModels);
                    setShowSuccessMessage(true);

                    const newPersonas = addedObjects.filter(x => sharedWith.findIndex(y => y.id == x.id) == -1).map(x => {
                        if (x.destinationType == SharedWithDestinationType.EveryonePublic) {
                            return { ...x, name: "Everyone (Public)" } as SharedWithResponseModel;
                        }
                        return x;

                    });

                    setSharedWith([...sharedWith, ...newPersonas]);
                }

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to share agent group.");
            } finally {
                hideMessage();
            }
        });

    }


    const roleChanged = async (person: SharedWithResponseModel, role: string) => {
        loadingService.showLoading("Stoping share...", async (hideMessage) => {
            try {
                if (person) {
                    if (role == "stop") {
                        const client = getSharingClient();
                        const model = {
                            destinationId: person.destinationId && person.destinationId.length > 0 ? person.destinationId : undefined,
                            destinationType: person.destinationType
                        } as StopSharingPostModel;
                        await client.stopSharing(props.agentGroupId, model);

                        let updatedSharedWith = [...sharedWith];
                        const sharedIndex = updatedSharedWith.findIndex(x => x.id == person.id);
                        console.log("sharedIndex", sharedIndex);
                        if (sharedIndex > -1) {
                            updatedSharedWith.splice(sharedIndex, 1);
                            console.log("sharedIndex,updatedSharedWith", sharedIndex, updatedSharedWith);
                            setSharedWith([...updatedSharedWith]);
                        }

                    }
                }
            }
            catch (error) {
                processServerError(error, undefined, "An error occurred while trying to stop sharing.");
            } finally {
                hideMessage();
            }
        });

    }

    return <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <PersonArrowRight20Regular style={{ marginRight: "10px" }} />
            <span style={{ marginRight: "10px" }}>Direct Access</span>
            <GrantAccessCallout
                style={{ marginRight: "10px" }}
                showCallout={isCalloutVisible}
                name={props.agentName}
                userOrgGroup={userOrgGroup}
                directionaHint={"below"}
                hideInvitation
                icon={<Add20Regular />}
                isRequestInProgress={isLoading}
                showSuccessMessage={showSuccessMessage}
                onCalloutVisibleChanged={() => { setShowSuccessMessage(false); setIsCalloutVisible(!isCalloutVisible) }}
                onShare={(model) => { onShare(model); }}
            />
            {isUserGlobalAdministrator && <GrantAccessCallout
                style={{ marginRight: "10px" }}
                showCallout={isGlobalCalloutVisible}
                name={props.agentName}
                userOrgGroup={userOrgGroup}
                directionaHint={"below"}
                hideInvitation
                hideOrganization
                canShareWithEveryone
                isGlobalShare
                icon={<Globe20Regular />}
                isRequestInProgress={isLoading}
                showSuccessMessage={showSuccessMessage}
                onCalloutVisibleChanged={() => { setShowSuccessMessage(false); setIsGlobalCalloutVisible(!isGlobalCalloutVisible) }}
                onShare={(model) => { onShare(model); }}
            />}

        </div>

        <div style={{ maxWidth: "400px", paddingTop: "5px", paddingBottom: "5px" }}>
            {isLoading == false && sharedWith && sharedWith.length > 0 && sharedWith.map(person =>
                <HasAccessSidePersona isOwner={account.localAccountId == person.destinationId} person={person} onRoleChanged={roleChanged.bind(this)} />)}
            {isLoading && <Spinner size="medium" label="Loading..." />}
        </div>
    </div>

}