import React from "react";
import { SeparationMethodOption } from "../../../../swagger-clients/ai-for-pfd-clients.service";

import { IdealDistillationComponent } from "./ideal-distillation.component";
import { Alert } from "@fluentui/react-components/unstable";
import { AbsorptionComponent } from "./absorption.component";
import { ComponentModel } from "../../edit-experiment.models";
import { IExperimentValidation } from "../../edit-experiment.validation";
import { EntrainerDistillationComponent } from "./entrainer-distillation.component";

type ExperimentComponentsTabProps = {
    separationMethod?: SeparationMethodOption;
    components: ComponentModel[];
    onChange?: (components: ComponentModel[]) => void;
    isDetails?: boolean;
    isFormSubmitted?: boolean;
    experimentValidation?: IExperimentValidation;   
}

export const ExperimentComponentsTab: React.FC<ExperimentComponentsTabProps> = (props) => {
    const { isFormSubmitted, experimentValidation } = props;

    return <div>
        {isFormSubmitted && !!experimentValidation && !experimentValidation.componentsGlobal.isValid &&
            <Alert intent="error" style={{ minHeight: "30px", marginBottom: "var(--spacingVerticalMNudge)" }}>
                {experimentValidation.componentsGlobal.validationErrors[0]}
            </Alert>
        }
        {props.separationMethod === SeparationMethodOption.IdealMixtureDistillation &&
            <IdealDistillationComponent
                separationMethod={props.separationMethod}
                components={props.components}               
                onChange={props.onChange}
                isDetails={props.isDetails}
                isFormSubmitted={props.isFormSubmitted}
                experimentValidation={props.experimentValidation}
            />}
        {props.separationMethod === SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation &&
            <IdealDistillationComponent
                separationMethod={props.separationMethod}
                components={props.components}                
                onChange={props.onChange}
                isDetails={props.isDetails}
                isFormSubmitted={props.isFormSubmitted}
                experimentValidation={props.experimentValidation}
            />}

        {props.separationMethod == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer &&
            <EntrainerDistillationComponent
                separationMethod={props.separationMethod}
                components={props.components}
                onChange={props.onChange}
                isDetails={props.isDetails}
                isFormSubmitted={props.isFormSubmitted}
                experimentValidation={props.experimentValidation}
            />}
        {props.separationMethod === SeparationMethodOption.Absorption &&
            <AbsorptionComponent
                separationMethod={props.separationMethod}
                components={props.components}
                onChange={props.onChange}
                isDetails={props.isDetails}
                isFormSubmitted={props.isFormSubmitted}
                experimentValidation={props.experimentValidation} />}
    </div>

}