import React from "react";
import ReactSelect, { ActionMeta, GroupBase, StylesConfig, components, createFilter } from "react-select";
import { getContactsClient } from "../../../services/dashboard.service";
import { ContactResponseModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { LoadingService, useLoading } from "../../../utils/loading-indicator.component";
import { SelectOption } from "../../../components/has-access-side-people-picker/has-access-side-people-picker.component";
import { PersonDisplay } from "../../../components/person-display/person-display.component";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";


type ContactPickerProps = {
    style?: React.CSSProperties;
    placeholder?: string;
    value?: ContactOption | null;
    onChange: (selectedOption: ContactOption) => void;
}

export type ContactOption = SelectOption & {
    userPrincipalName: string;
}
export const ContactPicker: React.FC<ContactPickerProps> = (props) => {

    const [currentValue, setCurrentValue] = React.useState<string>("");
    const [selectedValue, setSelectedValue] = React.useState<ContactOption | null>(props.value);
    const [isLoading, loadingService] = useLoading();
    const [options, setOptions] = React.useState<ContactOption[]>([]);
    const [contacts, setContacts] = React.useState<ContactResponseModel[]>([]);


    React.useEffect(() => { getContacts(); }, []);

    React.useEffect(() => { setSelectedValue(props.value); console.log("contact picker value changed", props.value); }, [props.value]);

    const Option = (props) => {
        const person = props.data as ContactOption;

        return <components.Option {...props}>
            <PersonDisplay id={person.label} name={person.label} userPrincipalName={person.userPrincipalName} />

        </components.Option>
    }

    const onParametersChanged = async (newOption: ContactOption, actionMeta: ActionMeta<ContactOption>) => {
        if (actionMeta.action == "select-option") {
            props.onChange(newOption);
            setSelectedValue(newOption);
        } else {
            props.onChange(undefined);
            setSelectedValue(undefined);
        }

    }

    React.useEffect(() => {
        updateSuggestions();

    }, [contacts]);

    const updateSuggestions = () => {
        const availableContacts = contacts.filter(x => x.confirmed !== false);
        const mappedUserOptions = availableContacts.map((user) => ({
            value: user.userInfo.id,
            label: user.userInfo.displayName,
            userPrincipalName: user.userInfo.userPrincipalName
        } as ContactOption)) ?? [];


        setOptions(mappedUserOptions);
    }

    const getContacts = async () => {
        loadingService.showLoading("Getting user contacts...", async (hideMessage) => {
            try {


                const client = getContactsClient();
                const contactsResp = await client.getMyContacts();
                setContacts(contactsResp ?? []);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting contacts.");
            } finally {
                hideMessage();
            }
        });

    }
    console.log("Contact picker value", selectedValue);

    return <div style={{ ...props.style }}>
        <ReactSelect
            isLoading={isLoading}
            isClearable
            placeholder={props.placeholder}
            options={options}
            value={selectedValue ?? null}
            filterOption={createFilter(filterConfig as any)}
            onInputChange={(newValue: string) => { setCurrentValue(newValue); }}
            onChange={onParametersChanged}
            components={{ Option }}
            styles={ContactPickerStyless} />
    </div>

}
const filterConfig = {
    ignoreCase: true,
    matchFrom: 'any',
    stringify: (option: FilterOptionOption<ContactOption>) => { return `${option.label} ${option.data.userPrincipalName}`; }
}
const ContactPickerStyless: StylesConfig<ContactOption, false, GroupBase<ContactOption>> = {
    control: (styles) => ({ ...styles, minHeight: "30px", borderBottomColor: "var(--colorNeutralStrokeAccessible) !important" }),
    dropdownIndicator: (styles) => ({
        ...styles,
        paddingTop: 5,
        paddingBottom: 5,
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display: "none"
    }),
    clearIndicator: (styles) => ({
        ...styles,
        paddingTop: 5,
        paddingBottom: 5,
    }),
    input: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    option: (styles) => ({
        ...styles,
        paddingTop: "4px",
        paddingBottom: "4px",
        lineHeight: "16px"
    }),
};

