import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableColumnDefinition, TableColumnSizingOptions, createTableColumn, useTableFeatures, useTableColumnSizing_unstable } from "@fluentui/react-components";
import React from "react";
import { ParameterDisplay } from "../../components/parameter-display/parameter-display.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { ScenarioResultModel } from "../../swagger-clients/excel-runner-api-clients.service";
import { FilterParameterType, FilterResultModel, InputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";

type ScenarioDetailsInputParametersTabProps = {
    scenario: ScenarioResultModel;
    filter: FilterResultModel;
    isLoading: boolean;
}

export const ScenarioDetailsInputParametersTab: React.FunctionComponent<ScenarioDetailsInputParametersTabProps> = (props) => {
    const [columns, setColumns] = React.useState<TableColumnDefinition<InputFilterParameterResultModel>[]>([]);

    React.useEffect(() => { const columnsResp = getColumns(); setColumns(columnsResp); }, []);

    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        parameter: {
            minWidth: 100,
            defaultWidth: 300,
        },
        alias: {
            minWidth: 100,
            defaultWidth: 200,
        },
        parameterType: {
            minWidth: 100,
            defaultWidth: 200
        },
        valueFormulaField: {
            minWidth: 100,
            defaultWidth: 200
        },
        column: {
            minWidth: 50,
            defaultWidth: 100,
            idealWidth: 100

        }
    });

    const getColumns = () => {
        const columnsDef: TableColumnDefinition<InputFilterParameterResultModel>[] = [
            createTableColumn<InputFilterParameterResultModel>({
                columnId: "parameter",
                renderHeaderCell: () => <>Parameter</>,
                renderCell: (item: InputFilterParameterResultModel) => {
                    return <ParameterDisplay
                        value={item.name}
                        fileUniqueId={props.filter.fileUniqueIdentifier}
                        versionNumber={props.filter.fileVersionNumber?.toString()} />;

                }
            }),
            createTableColumn<InputFilterParameterResultModel>({
                columnId: "alias",
                renderHeaderCell: () => <>Alias</>,
                renderCell: (item: InputFilterParameterResultModel) => {
                    return item.alias;

                }
            }),
            createTableColumn<InputFilterParameterResultModel>({
                columnId: "parameterType",
                renderHeaderCell: () => <>Parameter Type</>,
                renderCell: (item: InputFilterParameterResultModel) => {
                    return {
                        [FilterParameterType.ExpectedInput]: "Column",
                        [FilterParameterType.Formula]: "Formula",
                        [FilterParameterType.FixedValue]: "Fixed value",
                    }[item.parameterType];
                }
            }),
            createTableColumn<InputFilterParameterResultModel>({
                columnId: "valueFormulaField",
                renderHeaderCell: () => <>Formula / Fixed Value</>,
                renderCell: (item: InputFilterParameterResultModel) => {

                    return <>
                        {item.parameterType == FilterParameterType.Formula && <span>{item.formula}</span>}
                        {item.parameterType == FilterParameterType.FixedValue && <span>{item.fixedValue}</span>}
                        {item.parameterType != FilterParameterType.FixedValue && item.parameterType != FilterParameterType.Formula && <span>-</span>}
                    </>;
                }
            }),
            createTableColumn<InputFilterParameterResultModel>({
                columnId: "column",
                renderHeaderCell: () => <>Column</>,
                renderCell: (item: InputFilterParameterResultModel) => {

                    return <>{item.parameterType == FilterParameterType.ExpectedInput &&
                        <span>{getColumnCellValue(item.id)}</span>}</>;

                }
            })
        ];

        return columnsDef;
    }
    const items = props.filter.inputFilterParameters ?? [];

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<InputFilterParameterResultModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );



    const getColumnCellValue = (paramId: number) => {
        let param = props.scenario.inputParameters.find(p => p.filterParameterId == paramId);
        return <span>{param && param.column ? param.column : "-"}</span>
    }

    return <div>
        <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
            <TableHeader>
                <TableRow key="table-header">
                    {columns.map((column) => (
                        <TableHeaderCell
                            onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            className={`table__cell--bold`}
                            key={column.columnId}
                            {...columnSizing_unstable.getTableHeaderCellProps(
                                column.columnId
                            )}
                        >
                            {column.renderHeaderCell()}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>

            <TableBodyWithLoading isLoading={props.isLoading}
                columnCount={6} loadingMessage="Loading..."
                itemCount={props.filter.inputFilterParameters ? props.filter.inputFilterParameters.length : 0}
                noItemsMessage={`No items found.`}>
                {props.filter.inputFilterParameters &&
                    props.filter.inputFilterParameters.map((item, index) => {
                        return <TableRow key={`input-param-${item.id}`}>
                            {columns.map((column) => (
                                <TableCell
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
    </div>;
}