import { Field, Input, Label, Tab, TabList } from "@fluentui/react-components";
import React from "react";
import { StreamModel } from "../../edit-experiment.models";
import { ExperimentStreamType, SeparationMethodOption } from "../../../../swagger-clients/ai-for-pfd-clients.service";
import { StreamComponent } from "./stream.component";
import { IExperimentValidation } from "../../edit-experiment.validation";

type EntrainerStreamProps = {
    streams: StreamModel[];
    onChange: (streams: StreamModel[]) => void;
    isDetails?: boolean;
    isFormSubmitted?: boolean;
    experimentValidation?: IExperimentValidation;
    separationMethod: SeparationMethodOption;
}

export const EntrainerStream: React.FC<EntrainerStreamProps> = (props) => {
    const [selectedTab, setSelectedTab] = React.useState<string>("feed");


    const feedStreamIndex = props.streams.findIndex(x => x.streamType == ExperimentStreamType.Feed);
    const entrainerStreamIndex = props.streams.findIndex(x => x.streamType == ExperimentStreamType.Entrainer);
    const feedStreamValidation = feedStreamIndex > -1 && !!props.experimentValidation?.streams[feedStreamIndex] ? props.experimentValidation?.streams[feedStreamIndex] : undefined;
    const entrainerStreamValidation = entrainerStreamIndex > -1 && !!props.experimentValidation?.streams[entrainerStreamIndex] ? props.experimentValidation?.streams[entrainerStreamIndex] : undefined;



    const onStreamDataChange = (data: StreamModel) => {

        const updatedStreams = props.streams.map(stream => {
            if (stream.streamType === data.streamType) {
                return { ...data, compositions: [...data.compositions] } as StreamModel;
            }
            return stream;
        });
        props.onChange(updatedStreams);
    }


    return <div style={{ marginTop: "-15px" }}>
        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { setSelectedTab(data.value as string); }}>
            <Tab key="feed" value="feed" >Feed</Tab>
            <Tab key="entrainer" value="entrainer" >Entrainer</Tab>
        </TabList>

        <div className="tab-content" style={{ marginTop: "10px" }}>
            <div className="row">
                {selectedTab === "feed" &&
                    <StreamComponent
                        key="feed-stream"
                        separationMethod={props.separationMethod}
                        stream={props.streams[feedStreamIndex]}
                        onChange={(data) => onStreamDataChange(data)}
                        isDetails={props.isDetails}
                        isFormSubmitted={props.isFormSubmitted}
                        streamValidation={feedStreamValidation}
                    />}
                {selectedTab === "entrainer" &&
                    <StreamComponent
                        key="entrainer-stream"
                        disableEditComposition
                        isEntrainer
                        separationMethod={props.separationMethod}                       
                        stream={props.streams[entrainerStreamIndex]}
                        onChange={(data) => onStreamDataChange(data)}
                        isDetails={props.isDetails}
                        isFormSubmitted={props.isFormSubmitted}
                        streamValidation={entrainerStreamValidation}
                    />}
            </div>

        </div>

    </div>
}