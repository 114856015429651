import { SimulatorType } from "../../swagger-clients/dispatcher-next-api-clients.service";


export function getSimulatorName(simulatorType: SimulatorType) {
    const simulatorNames = {
        [SimulatorType.Chemcad7]: "Chemcad 7",
        [SimulatorType.Chemcad8]: "Chemcad 8",
        [SimulatorType.DWSIM]: "DWSIM Pro"
    };

    return simulatorNames[simulatorType];
}

export function getSimulatorTypeDefaultExtension(simulatorType: SimulatorType) {


    const simulatorTypes = {
        [SimulatorType.Chemcad7]: "cc7",
        [SimulatorType.Chemcad8]: "ccsim",
        [SimulatorType.DWSIM]: "dwxmz"
    };

    return simulatorTypes[simulatorType];
}