import { Tooltip } from '@fluentui/react-components';
import * as React from 'react';
import { IRowModel, RowProcessingStatus } from '../../../../swagger-clients/sensitivity-studies-api-clients.service';


interface ISensitivityStudyRowProcessingStatusParams {
    row: IRowModel,
    showError?: boolean
}

const getStatusTextAndColor = (status: RowProcessingStatus): string[] => {
    switch (status) {
        case RowProcessingStatus.Calculated: // Green
            return ['Converged', 'ForestGreen'];
        case RowProcessingStatus.CalculationFailed: // Red
            return ['Not Converged', 'DarkRed'];
        case RowProcessingStatus.NotSubmittedToDispatcher: // Gray, null for default
            return ['Not submitted to dispatcher', null];
        case RowProcessingStatus.ProcessingFailed: // Red
            return ['Processing failed', 'DarkRed'];
        case RowProcessingStatus.PostProcessingFailed:
            return ['Post-processing failed', 'DarkRed'];
        case RowProcessingStatus.SubmittedToDispatcher: // Blue
            return ['Submitted to dispatcher', 'CornflowerBlue'];
        case RowProcessingStatus.OutputConstraintViolated: // Purple
            return ['Output constraint violated', 'MediumVioletRed'];
        case RowProcessingStatus.ReceivedResults:
            return ['Received results', 'LightBlue'];
        default:
            return [null, null];
    }
}

const SensitivityStudyRowProcessingStatus: React.FunctionComponent<ISensitivityStudyRowProcessingStatusParams> = (props: ISensitivityStudyRowProcessingStatusParams) => {

    if (!props || !props.row)
        return null;

    const [text, color] = getStatusTextAndColor(props.row.processingStatus);

    // Set color only if defined
    let styles: React.CSSProperties = {};
    if (!!color) {
        styles.color = color;
    }

    if (props.showError && !!props.row.evaluationFunctionMessage) {
        return <Tooltip
            relationship='description'
            positioning="above"
            content={props.row.evaluationFunctionMessage}
        // This id is used on the tooltip itself, not the host
        // (so an element with this id only exists when the tooltip is shown)

        >
            <span style={styles}>{text}</span>
        </Tooltip>
    }
    else {
        return <span style={styles}>{text}</span>
    }
}

export { SensitivityStudyRowProcessingStatus };