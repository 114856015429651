import { Button, MenuItem, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { Add20Regular, ChevronDownRegular, ChevronUpRegular } from "@fluentui/react-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { ContextMenu, ContextMenuType } from "../../files/context-menu/context-menu.component";
import { getAgentGroupsClient } from "../../services/dispatcher.service";
import { AgentGroupResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { DetailsAgentGroupModal } from "./details-agent-group-modal/details-agent-group-modal.component";
import { EditAgentGroupModal } from "./edit-agent-group-modal/edit-agent-group-modal.component";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";

type AgentsListProps = {

}


interface IAgentGroupTableItem {
    id: number;
    key: string;
    name: string;
    lastUpdate?: Date;
    createdAt: Date;
    lastBuildVersion?: string;
    type: AgentItemType;
    isExpanded: boolean;
    agentCount: number;
    groupKey: string;
}

enum AgentItemType {
    Group,
    Agent
}

export const AgentsList: React.FC<AgentsListProps> = (props) => {
    const [agentGroups, setAgentGroups] = React.useState<IAgentGroupTableItem[]>([]);
    const [selectedAgentGroup, setSelectedAgentGroup] = React.useState<IAgentGroupTableItem>();
    const [allAgentsAndGroups, setAllAgentsAndGroups] = React.useState<IAgentGroupTableItem[]>([]);
    const [showEditAgentGroupModal, setShowEditAgentGroupModal] = React.useState<boolean>(false);
    const [showDetailsAgentGroupModal, setShowDetailsAgentGroupModal] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();

    React.useEffect(() => {
        getAgentGroups();

    }, []);

    const getAgentGroups = async () => {
        loadingService.showLoading("Getting agents...", async (hideMessage) => {

            try {
                const client = getAgentGroupsClient();
                const agentGroups = await client.getAgentGroups();
                const allGroupsItems = mapAgentGroupsToTableItems(agentGroups);
                const groups = allGroupsItems.filter(x => x.type == AgentItemType.Group);
                setAgentGroups(groups);
                setAllAgentsAndGroups(allGroupsItems);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting agent groups.");
            }
            finally {
                hideMessage();
            }
        });

    }

    const onToogleExpand = (item: IAgentGroupTableItem, index: number) => {
        console.log("On item invoked called!", item);




        let updatedAgentGroups = agentGroups.map(x => {
            if (x.key == item.key) {
                return { ...x, isExpanded: !item.isExpanded } as IAgentGroupTableItem
            }
            return x;
        });

        if (item.isExpanded) {
            //remove agents
            updatedAgentGroups = updatedAgentGroups.filter(x => x.groupKey !== item.key);
        } else {
            const insertAt = index + 1;

            //add agents
            const groupAgents = allAgentsAndGroups.filter(x => x.groupKey == item.key);
            groupAgents.forEach((agent, i) => {
                updatedAgentGroups.splice(insertAt + i, 0, agent);
            });
        }
        setAgentGroups(updatedAgentGroups);
    }

    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        menuItems.push(...[
            <MenuItem key="edit" onClick={() => { setShowEditAgentGroupModal(true); }}> Edit</MenuItem>
        ]);

        menuItems.push(<MenuItem key="details" onClick={() => { setShowDetailsAgentGroupModal(true) }} > Details</MenuItem>);

        return menuItems;
    }

    const getAgentGroupRows = (item: IAgentGroupTableItem) => {
        if (!item.isExpanded)
            return null;

        const agentGroupAgents = allAgentsAndGroups.filter(x => x.groupKey == item.key);

        if (!agentGroupAgents || agentGroupAgents.length == 0) {
            return <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }}>No agents.</TableCell>
            </TableRow>
        } else {
            const agentRows = agentGroupAgents.map((agent) => {
                return <TableRow>
                    <TableCell></TableCell>
                    <TableCell><span style={{ marginLeft: "30px" }}>{agent.name}</span></TableCell>
                    <TableCell><ShowLocalTime date={agent.createdAt}  /></TableCell>
                </TableRow>
            });

            return agentRows;
        }


    }



    const contextMenuRef = React.createRef<ContextMenuType>();

    return <div className="content-wrapper">
        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                    onClick={() => { setSelectedAgentGroup(undefined); setShowEditAgentGroupModal(true); }}
                    appearance='primary'
                    icon={<Add20Regular />}>New Agent Group</ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />

            </Toolbar>
        </div>
        <div className='groups-wrapper__breadcrumbs-wrapper'>
            <Breadcrumbs>               
               <Breadcrumb key={`breadcrumb-cluster`} onClick={() => navigate(`/cluster`)}>Cluster</Breadcrumb>
                <Breadcrumb key={`breadcrumb-agents`} active={true}>Agents</Breadcrumb>
            </Breadcrumbs>
        </div>
        <div className="s365-table__wrapper">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell key="name" className='s365-table__cell--bold'>Name</TableHeaderCell>
                        <TableHeaderCell key="created-at" className='s365-table__cell--bold'>Created At</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={2} loadingMessage="Loading..."
                    itemCount={agentGroups ? agentGroups.length : 0}
                    noItemsMessage="No items found.">
                    {agentGroups && agentGroups.length > 0 && agentGroups.map((item, index) => {
                        return <TableRow
                            key={`agent-group-${item.id}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => { setSelectedAgentGroup(item); setShowDetailsAgentGroupModal(true); }}
                            className={selectedAgentGroup && selectedAgentGroup.id == item.id ? "table-row-selected" : undefined}
                            onContextMenu={(ev) => { ev.preventDefault(); setSelectedAgentGroup(item); contextMenuRef.current?.showMenu(ev); }}
                        >
                            <TableCell><span style={{ marginLeft: "10px" }}>{item.name}</span></TableCell>
                            <TableCell><ShowLocalTime date={item.createdAt}  /></TableCell>
                        </TableRow>
                    })}
                </TableBodyWithLoading>
            </Table>
        </div>

        <ContextMenu ref={contextMenuRef} >
            {getContextItems()}
        </ContextMenu>

        {showEditAgentGroupModal && <EditAgentGroupModal
            agentGroupId={selectedAgentGroup?.id}
            isOpened={showEditAgentGroupModal}
            onSuccess={() => { setShowEditAgentGroupModal(false); getAgentGroups(); }}
            onClose={() => setShowEditAgentGroupModal(false)}
        />}
        {showDetailsAgentGroupModal &&
            <DetailsAgentGroupModal
                agentGroupId={selectedAgentGroup?.id}
                isOpened={showDetailsAgentGroupModal}
                onClose={() => { setShowDetailsAgentGroupModal(false); }}
                onEditClick={() => { setShowDetailsAgentGroupModal(false); setShowEditAgentGroupModal(true); }}
            />
        }

    </div>
}

const mapAgentGroupsToTableItems = (agentGroups: AgentGroupResponseModel[]): IAgentGroupTableItem[] => {

    try {
        var mappedItems: IAgentGroupTableItem[] = [];
        agentGroups.forEach(agentGroup => {

            const groupTableItem = {
                id: agentGroup.id,
                key: `group-${agentGroup.id}`,
                name: agentGroup.name,
                type: AgentItemType.Group,
                agentCount: agentGroup.agents ? agentGroup.agents.length : 0
            } as IAgentGroupTableItem;

            mappedItems.push(groupTableItem);

            if (agentGroup.agents && agentGroup.agents.length > 0) {
                agentGroup.agents.forEach(agent => {

                    const agentTableItem = {
                        id: agent.id,
                        key: `group-${agentGroup.id}-agent-${agent.id}`,
                        name: agent.displayName,
                        type: AgentItemType.Agent,
                        groupKey: `group-${agentGroup.id}`,
                        createdAt: agent.createdAt

                    } as IAgentGroupTableItem;

                    mappedItems.push(agentTableItem);

                });
            }

        });

        return mappedItems;

    } catch (error) {
        processServerError(error, undefined, "An error occurred while mapping AgentGroups to table items.");
    }
}