export const addHours = function (date: Date, hours: number): Date {

    let result = new Date(date.getTime());
    result.setHours(result.getHours() + hours);
    return result;
}

export const addMinutes = function (date: Date, minutes: number): Date {
    let result = new Date(date.getTime());
    result.setMinutes(result.getMinutes() + minutes);
    return result;

}