import React from "react";
import { useLoading } from "../../utils/loading-indicator.component";
import { FlowsheetSavedResponseModel, SaveExperimentFlowsheetPostModel } from "../../swagger-clients/ai-for-pfd-clients.service";
import { SaveFileModal, SaveFileModel } from "../../components/save-file-modal/save-file-modal.component";
import { getExperimentsClient } from "../../services/ai-for-pfd.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { SaveFlowsheetToDashboardProgressModal } from "./save-flowsheet-to-dashboard-progress-modal.component";


type SaveExperimentFileModalProps = {
    experimentVersionId: number;
    sequence: number;
    flowsheetId: number;
    defaultName?: string;
    parentDirectoryUniqueId?: string;
    onHide: () => void;

}

export const SaveExperimentFileModal: React.FC<SaveExperimentFileModalProps> = (props) => {
    const [showSaveFileModal, setShowSaveFileModal] = React.useState<boolean>(true);
    const [showProgressModal, setShowProgressModal] = React.useState<boolean>(false);
    const [isRequestSuccessfull, setIsRequestSuccessfull] = React.useState<boolean>(false);
    const [isLoading, LoadingService] = useLoading(false);
    const [fileCreatedResponse, setFileCreatedResponse] = React.useState<FlowsheetSavedResponseModel>();

    const onSaveFlowsheetClick = async (data: SaveFileModel) => {
        const messageId = LoadingService.showMessage("Saving flowsheet...");
        try {
            setShowProgressModal(true);
            setShowSaveFileModal(false);
            setIsRequestSuccessfull(false);
            const client = getExperimentsClient();
            const model = new SaveExperimentFlowsheetPostModel({

                filename: `${data.name}.${data.extension}`,
                parentUniqueId: data.parentDirectoryUniqueId,
                uploadConflictAction: data.conflictAction,
                experimentVersionId: props.experimentVersionId,
                flowsheetId: props.flowsheetId,
                sequence: props.sequence
            });

            const fileCreatedresp = await client.saveFlowsheetToDashboard(model);
            setFileCreatedResponse(fileCreatedresp);

            setIsRequestSuccessfull(true);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while saving flowsheet to dashboard.");
        } finally {
            LoadingService.hideMessage(messageId);
        }

    }


    return <div>

        <SaveFileModal isOpened={showSaveFileModal}
            modalTitle="Save to Dashboard"
            saveButtonLabel="Save"
            defaultName={props.defaultName}
            fileExtension="dwxmz"
            fileExtensionText="Simulation file (*.dwxmz)"
            parentDirectoryUniqueId={props.parentDirectoryUniqueId}
            onSuccess={onSaveFlowsheetClick}
            onHide={() => { setShowSaveFileModal(false); props.onHide(); }}

        />

        <SaveFlowsheetToDashboardProgressModal
            isLoading={isLoading}
            isSuccess={isRequestSuccessfull}
            filePath={fileCreatedResponse?.simulatePath}
            filename={fileCreatedResponse?.filename}
            isOpened={showProgressModal}
            onHide={() => { setShowProgressModal(false); props.onHide(); }}
        />


    </div>

}