import { settings } from "../App";
import { ExportDataClient, ProcessingClient, SensitivityStudiesClient, StudiesCountClient } from "../swagger-clients/sensitivity-studies-api-clients.service";
import { AxiosInstance } from "./dashboard.service";

export const getSensitivityStudiesClient = () => {
    return new SensitivityStudiesClient(settings?.sensitivityStudiesServiceUrl, AxiosInstance);
}

export const getProcessingClient = () => {

    return new ProcessingClient(settings?.sensitivityStudiesServiceUrl, AxiosInstance);
}

export const getExportDataClient = () => {
    return new ExportDataClient(settings?.sensitivityStudiesServiceUrl, AxiosInstance);
}
export const getStudiesCountClient = () => {
    return new StudiesCountClient(settings?.sensitivityStudiesServiceUrl, AxiosInstance);
}
