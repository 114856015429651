import { FolderRegular, FolderFilled, CircleFilled, DataBarVerticalRegular, BookQuestionMarkRtlRegular, DesktopFlowRegular, SettingsRegular, DeleteRegular, DataBarVerticalFilled, BookQuestionMarkRtlFilled, DesktopFlowFilled, SettingsFilled, DeleteFilled, ChevronDownFilled, ChevronUpFilled, ChartPersonRegular, ChartPersonFilled, DataScatter20Regular, DataScatterRegular, DataScatter20Filled, DataScatterFilled, PeopleTeamRegular, PeopleTeamFilled } from '@fluentui/react-icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { SidebarSubmenu } from './sidebar-submenu';
import './sidebar-menu.scss';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { featureFlagsSettings } from '../../App';

type SidebarMenuProps = {
    shrinked: boolean,
    onExpand: () => void
};

export type MenuItem = {
    id?: string,
    name: string,
    action?: string | ((navigate: NavigateFunction) => void),
    icons?: any[],
    hidden?: boolean,
    children?: MenuItem[]
};

const getMenuItems = () => {
    return [
        {
            id: "files_link",
            name: "Files",
            icons: [
                <FolderRegular className='sidebar__menu-item-icon sidebar__menu-item-icon--regular' />,
                <FolderFilled className='sidebar__menu-item-icon sidebar__menu-item-icon--filled' />
            ],
            children: [
                { name: "My Work", action: '/files' },
                { name: "My Starred", action: '/files/starred' },
                { name: "Shared with me", action: "/files/shared-with-me" },
                { name: "Shared by me", action: "/files/shared-by-me" },
                { name: "My Exams", action: "/student-exams" },
                { name: "Simulate 365 Examples", action: "/files/examples" },
            ]
        },
        {
            id: "analytics_sidebar_link",
            name: "Applications",
            icons: [
                <DataScatterRegular className='sidebar__menu-item-icon sidebar__menu-item-icon--regular' />,
                <DataScatterFilled className='sidebar__menu-item-icon sidebar__menu-item-icon--filled' />
            ],
            children: [
                { name: "Multivariate Sensitivity Study (MSS)", action: '/sensitivity-studies' },
                { name: "Design of Experiments (DoE)", action: "/doe" },
                { name: "Optimum Test (OT)", action: '/optimum-tester' },
                { name: "Flowsheet Copilot", action: '/copilot', hidden: !(featureFlagsSettings?.showCopilot) },
                { name: "Web Form", action: '/web-forms' },
                { name: "Take-Home Exercise / Exam (THEE)", action: '/teacher-exams' },
            ]
        },
        {
            id: "colaboration_link",
            name: "Collaboration",
            action: '/contacts/my-contacts',
            icons: [
                <PeopleTeamRegular className='sidebar__menu-item-icon sidebar__menu-item-icon--regular' />,
                <PeopleTeamFilled className='sidebar__menu-item-icon sidebar__menu-item-icon--filled' />
            ],

            children: [
                { name: "My Contacts", action: '/contacts/my-contacts' },
                { name: "My Contact Groups", action: '/groups' }]
        },
        {
            id: "cluster_sidebar_link",
            name: "Cluster",
            icons: [
                <DesktopFlowRegular className='sidebar__menu-item-icon sidebar__menu-item-icon--regular' />,
                <DesktopFlowFilled className='sidebar__menu-item-icon sidebar__menu-item-icon--filled' />
            ],
            children: [
                { name: "Activities", action: "/cluster" },
                { name: "Jobs", action: "/cluster/jobs" },
                { name: "Agents", action: '/cluster/agents' },
                { name: "Settings", action: "/cluster/settings" }
            ]
        },
        {
            name: "Bin",
            action: '/bin',
            icons: [
                <DeleteRegular className='sidebar__menu-item-icon sidebar__menu-item-icon--regular' />,
                <DeleteFilled className='sidebar__menu-item-icon sidebar__menu-item-icon--filled' />
            ]
        },
    ];


}


export const SidebarMenu: React.FC<any> = (props: SidebarMenuProps) => {

    const [selectedTopMenu, setSelectedTopMenu] = useState<number>();
    const navigate = useNavigate();
    const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);

    useEffect(
        () => {
            if (props.shrinked)
                setSelectedTopMenu(undefined);
        },
        [props.shrinked]
    );


    useEffect(() => {
        const menuItemsLocal = getMenuItems();
        setMenuItems(menuItemsLocal);
    }, []);




    const itemClicked = (item: MenuItem, itemIndex: number, e: React.MouseEvent) => {
        e.preventDefault();

        // If item has children, then click action is expand/shrink
        if (item.children && item.children.length) {
            setSelectedTopMenu(itemIndex !== selectedTopMenu ? itemIndex : undefined);
            props?.onExpand();
        }
        else if (item.action) {
            if (typeof item.action === 'string') {
                navigate(item.action);
            }
            else if (typeof item.action === 'function') {
                item.action(navigate);
            }
        }
    }

    return <ul className={classNames('sidebar__menu', { 'sidebar__menu--shrinked': props.shrinked })}>
        {menuItems.map((item, i) => {

            if (!!item.hidden)
                return null;

            return <>
                <li id={item.id} className={classNames('sidebar__menu-item', { 'sidebar__menu-item--active': selectedTopMenu === i })}>
                    <a
                        className='sidebar__menu-item-inner'
                        href={item.action && typeof item.action === 'string' ? item.action : undefined}
                        onClick={e => itemClicked(item, i, e)}
                    >
                        {item.icons[0]}
                        {item.icons[1]}
                        <span className='sidebar__menu-item-text'>{item.name}</span>
                        {item.children && <>
                            <ChevronDownFilled className='sidebar__menu-item-expand sidebar__menu-item-expand--closed' />
                            <ChevronUpFilled className='sidebar__menu-item-expand sidebar__menu-item-expand--opened' />
                        </>}
                    </a>
                    {item.children && <SidebarSubmenu items={item.children} />}
                </li>
            </>;
        })}
    </ul>;
}
