import { Button, Field, Input, Label } from "@fluentui/react-components";
import { AddRegular, DismissFilled } from "@fluentui/react-icons";
import React from "react";

type InputListProps = {
    label: string;
    values: string[];
    isSubmitted: boolean;
    onValuesChanged(values: string[]);
}

const InputValueStyle = { display: "flex", marginBottom: "5px" } as React.CSSProperties;

export const InputList: React.FC<InputListProps> = (props) => {

    const removeInput = (index: number) => {
        const newInputs = props.values.filter((x, i) => i !== index);
        props.onValuesChanged(newInputs);
    }

    const onAddInputClick = () => {
        const newInputs = [...props.values, ""];
        props.onValuesChanged(newInputs);
    }
    const onInputChanged = (value: string, index: number) => {
        const newInputs = props.values.map((x, i) => {
            if (index == i) {
                return value;
            }
            return x;
        });

        props.onValuesChanged(newInputs);
    }

    return <div className="input-form-item" style={{ alignItems: "flex-start" }}>
        <Label className="input-label">{props.label}</Label>

        <div className="input-field">
            {
                props.values ? props.values.map((value, index) => {
                    const isValid = /[^\s]/.test(value);
                    return <div style={InputValueStyle}>
                        <Field
                            validationState={props.isSubmitted && !isValid ? "error" : "none"}
                            validationMessage={props.isSubmitted && !isValid ? "Value is not valid!" : undefined}
                        >
                            <Input
                                value={value}                                
                                onChange={(event, data) => { onInputChanged(data.value, index); }}
                                contentAfter={<Button appearance="transparent" icon={<DismissFilled />} onClick={() => { removeInput(index); }}></Button>} />
                        </Field>

                    </div>

                }) : null
            }

            <Button appearance="secondary" icon={<AddRegular />} onClick={() => { onAddInputClick(); }} style={{ maxWidth: "50px" }} >Add</Button>

        </div>

    </div>;

}