import React from "react";
import { getApplicationsClient } from "../../services/dispatcher.service";
import { ApplicationResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";

type ApplicationNameProps = {
    applicationId: number,
    style?: React.CSSProperties;
};

export const ApplicationName: React.FC<ApplicationNameProps> = (props) => {
    const [application, setApplication] = React.useState<ApplicationResponseModel>();


    React.useEffect(() => {
        if (props.applicationId) {
            getApplication();
        }

    },
        [props.applicationId]);

    const getApplication = async () => {

        try {
            const client = getApplicationsClient();
            const application = await client.getApplication(props.applicationId);
            setApplication(application);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to get Application.");
        }

    }
    if (application) {
        const spanStyle = application.isDeleted ? { textDecoration: "line-through", ...props.style } as React.CSSProperties : props.style;
        const spanTitle = application.isDeleted ? "Deleted" : null;

        return <span style={spanStyle} title={spanTitle}>{application.name}</span>;
    } else {
        return null;
    }
}