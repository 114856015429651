import { HubConnection } from "@microsoft/signalr";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { ActiveAgentResponseModel, ActiveJobResponseModel, JobExecutionStatus, SimulatorType } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { ActiveAgentsTable, ActiveAgentsTableType } from "./active-agents-table/active-agents-table.component";
import AgentStatistics, { IAgentStatistics } from "./agents-statistics/agents-statistics.component";
import { ActiveJob, DispatcherJobsTable, DispatcherJobsTableType } from "./dispatcher-jobs-table/dispatcher-jobs-table.component";

type DispatcherActivitiesProps = {
    hubConnection: HubConnection
}


export const DispatcherActivities: React.FC<DispatcherActivitiesProps> = (props) => {
    const activeAgentsTableRef = React.useRef<ActiveAgentsTableType>();
    const dispatcherJobsTableRef = React.useRef<DispatcherJobsTableType>();
    const [agentsStatistics, setAgentsStatistics] = React.useState<IAgentStatistics>();
    const [pendingJobsStatistics, setPendingJobsStatistics] = React.useState<IAgentStatistics>();
    const [calculatingJobsStatistics, setCalculatingJobsStatistics] = React.useState<IAgentStatistics>();
    const navigate = useNavigate();

    React.useEffect(() => {
        console.log("DispatcherActivities", props.hubConnection);
        if (props.hubConnection) {
            console.log("Initializing hub events", props.hubConnection);
            props.hubConnection.on('AgentStatusUpdated', async (model) => {
                console.log("AgentStatusUpdated called.");
                await activeAgentsTableRef?.current?.onActiveAgentStatusUpdated(model.agentId, model.instanceNumber, model.status, model.lastUpdateAt);
            });
            props.hubConnection.on('JobAssignedToAgent', (model) => {
                console.log("JobAssignedToAgent called.");
                activeAgentsTableRef?.current?.onJobAssignedToAgent(model.agentId, model.instanceNumber,
                    model.jobId, model.jobExecutionId, model.assignedAt);
            });

            props.hubConnection.on('JobUnassignedFromAgent', (model) => {
                console.log("JobUnassignedFromAgent called.");
                activeAgentsTableRef?.current?.onJobUnassignedFromAgent(model);

            });

            props.hubConnection.on('JobUpdated', (model: ActiveJobResponseModel) => {
                console.log("Job updated.", model);
                dispatcherJobsTableRef?.current?.onJobUpdated(model);
            });

        }

    }, [props.hubConnection]);

    const onDispatcherJobsChanged = (jobs: ActiveJob[]) => {
        const cc7JobsPending = jobs.filter(x => x.executions.find(y => y.jobExecution && y.simulatorType == SimulatorType.Chemcad7 && y.jobExecution.status == JobExecutionStatus.NotExecuted) !== undefined);
        const cc8JobsPending = jobs.filter(x => x.executions.find(y => y.jobExecution && y.simulatorType == SimulatorType.Chemcad8 && y.jobExecution.status == JobExecutionStatus.NotExecuted) !== undefined);
        const dwsimJobsPending = jobs.filter(x => x.executions.find(y => y.jobExecution && y.simulatorType == SimulatorType.DWSIM && y.jobExecution.status == JobExecutionStatus.NotExecuted) !== undefined);

        const cc7JobsCalculating = jobs.filter(x => x.executions.find(y => y.jobExecution && y.simulatorType == SimulatorType.Chemcad7 && y.jobExecution.status == JobExecutionStatus.AssignedToAgent) !== undefined);
        const cc8JobsCalculating = jobs.filter(x => x.executions.find(y => y.jobExecution && y.simulatorType == SimulatorType.Chemcad8 && y.jobExecution.status == JobExecutionStatus.AssignedToAgent) !== undefined);
        const dwsimJobsCalculating = jobs.filter(x => x.executions.find(y => y.jobExecution && y.simulatorType == SimulatorType.DWSIM && y.jobExecution.status == JobExecutionStatus.AssignedToAgent) !== undefined);


        setPendingJobsStatistics({
            cc7SimulatorsCount: cc7JobsPending ? cc7JobsPending.length : 0,
            cc8SimulatorsCount: cc8JobsPending ? cc8JobsPending.length : 0,
            dwsimSimulatorsCount: dwsimJobsPending ? dwsimJobsPending.length : 0,
        });
        setCalculatingJobsStatistics({
            cc7SimulatorsCount: cc7JobsCalculating ? cc7JobsCalculating.length : 0,
            cc8SimulatorsCount: cc8JobsCalculating ? cc8JobsCalculating.length : 0,
            dwsimSimulatorsCount: dwsimJobsCalculating ? dwsimJobsCalculating.length : 0,
        });
    }

    const onActiveAgentsChanged = (agents: ActiveAgentResponseModel[]) => {
        //   console.log("onActiveAgentsChanged", agents);
        const cc7Simulators = agents.filter(x => x.simulators.find(y => y.type == SimulatorType.Chemcad7) !== undefined);
        const cc8Simulators = agents.filter(x => x.simulators.find(y => y.type == SimulatorType.Chemcad8) !== undefined);
        const dwsimSimulators = agents.filter(x => x.simulators.find(y => y.type == SimulatorType.DWSIM) !== undefined);
        setAgentsStatistics(
            {
                cc7SimulatorsCount: cc7Simulators ? cc7Simulators.length : 0,
                cc8SimulatorsCount: cc8Simulators ? cc8Simulators.length : 0,
                dwsimSimulatorsCount: dwsimSimulators ? dwsimSimulators.length : 0,
            }
        );

    }

    return <div className="content-wrapper">
        <div className='groups-wrapper__breadcrumbs-wrapper'>
            <Breadcrumbs>           
                <Breadcrumb key={`breadcrumb-cluster`} onClick={() => navigate(`/cluster`)}>Cluster</Breadcrumb>
                <Breadcrumb key={`breadcrumb-activities`} active={true}>Activities</Breadcrumb>
            </Breadcrumbs>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
            <AgentStatistics statistics={agentsStatistics} title='Active agents' />
            <AgentStatistics statistics={pendingJobsStatistics} title='Pending jobs' />
            <AgentStatistics statistics={calculatingJobsStatistics} title='Calculating jobs' />
        </div>

        <div className='page-title'>Active agents</div>
        <ActiveAgentsTable ref={activeAgentsTableRef} onActiveAgentsChanged={onActiveAgentsChanged} />
        <div className='page-title'>Recent and uncalculated jobs</div>
        <DispatcherJobsTable ref={dispatcherJobsTableRef} onJobsChanged={onDispatcherJobsChanged} />
    </div>
}