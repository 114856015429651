import React from "react";
import { HideSidebarSectionInTour, ShowSidebarSectionInTour } from "./tour-guide.shared";

type AnalyticsTourProps = {

}

export const AnalyticsTour: React.FC<AnalyticsTourProps> = (props) => {
    React.useEffect(() => {

        ShowSidebarSectionInTour("analytics_sidebar_link");

        return () => {
            // Close my work menu section
            HideSidebarSectionInTour("analytics_sidebar_link");
        }
    }, [])
    return <div style={{   marginTop: "var(--spacingVerticalMNudge)",   marginBottom: "var(--spacingVerticalMNudge)" }}>
        <p>View all studies you created using Dashboard's design tools. 
            You can analyze your flowsheets using the Multivariate Sensitivity Study (MSS) or Optimum Test (OT), 
            create entire separation sections using the Flowsheet Copilot or sample your design space by creating Design of Experiments (DoE) in Excel.</p>
        <span style={{ fontWeight: "bold" }}>Power tip!</span>
        <br />
        <span>All design tools can be accessed with a right-click on your flowsheet or Excel file. See Help for detailed instructions on how to get started!</span>
    </div>
}