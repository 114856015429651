import React from "react";
import { EditableTextAreaField } from "../../components/editable-textarea-field/editable-textarea-field.component";
import { getFilesClient } from "../../services/dashboard.service";
import { UpdateCommentPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingService } from "../../utils/loading-indicator.component";
import { FileTableItem } from "../models/file-table.models"

type EditCommentProps = {
    file: FileTableItem;
    disabled?: boolean;
    showDetailsIn2Rows?: boolean;
    rows?: number;
    loadingService: LoadingService;
    onReloadFilesEvent: () => void
}

export const EditComment: React.FC<EditCommentProps> = (props) => {

    const [commentValidationState, setCommentValidationState] = React.useState<'error' | 'warning' | 'success'>();
    const [commentValidationMessage, setCommentValidationMessage] = React.useState<string>();
    const [isInEditMode, setIsInEditMode] = React.useState<boolean>(false);


    const { file, loadingService } = props;

    const onCommentChange = async (comment: string) => {
        if (!file)
            return;
        loadingService.showLoading("Adding comment...", async (hideMessage) => {

            try {
                setCommentValidationState(undefined);
                setCommentValidationMessage("Saving...");
                const client = getFilesClient();
                await client.updateComment(file.uniqueIdentifier, { comment: comment, fileType: file.type } as UpdateCommentPostModel);
                setCommentValidationState(undefined);
                setCommentValidationMessage(undefined);
                props.onReloadFilesEvent();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while adding comment.", false);
                setCommentValidationState("error");
                setCommentValidationMessage("An error occurred while saving comment.");
            } finally {
                hideMessage();
            }

        });
    }

    if (props.showDetailsIn2Rows && !isInEditMode) {

        return <span className=" max-two-lines" style={{ width: "100%", minHeight: "20px", cursor: "text" }}
            onClick={() => { if (!props.disabled) { setIsInEditMode(true); } }}>{file.comment}</span>;
    }

    return <EditableTextAreaField
        value={file.comment}
        rows={props.rows}
        focusOnInit={props.showDetailsIn2Rows && isInEditMode}
        placeholder={!props.disabled ? "Leave a comment" : ""}
        disabled={!!props.disabled ? props.disabled : false}
        onClickAway={(comment) => { onCommentChange(comment); }}
        onLeaveEditMode={() => { setIsInEditMode(false); }}
        validationMessage={commentValidationMessage}
        validationState={commentValidationState}
        onValidationMessageChange={(message: string) => {
            if (message !== commentValidationMessage)
                setCommentValidationMessage(message);
        }}
        onValidationStateChange={(commentState) => {
            if (commentState !== commentValidationState)
                setCommentValidationState(commentState);
        }} />
}