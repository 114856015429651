import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, InputOnChangeData, Label, Radio, RadioGroup, RadioGroupOnChangeData, Textarea, Tooltip } from "@fluentui/react-components";
import { Dropdown, Option, Field } from "@fluentui/react-components";
import { Dismiss24Filled, Info20Regular } from "@fluentui/react-icons";
import React from "react";
import { toast } from "react-toastify";
import { getAgentsClient, getApplicationsClient } from "../../services/dispatcher.service";
import { GLOBAL_STYLES } from "../../styles";
import { AgentResponseModel, ApplicationPostModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { IChoiceGroupOption, IDropdownOption } from "../../utils/shared.types";
import "./edit-application-modal.styless.scss";


type EditApplicationModalProps = {
    applicationId?: number;
    defaultName?: string;
    isOpened?: boolean,
    onSuccess: (id: number) => void,
    onClose?: () => void
}

type OptionOnSelectData = {
    optionValue: string | undefined;
    selectedOptions: string[];
};

interface IAgentGroup {
    name: string;
    id: number;
    agents: AgentResponseModel[];
}

const yesNoOptions: IChoiceGroupOption[] = [
    { key: "1", text: 'Yes' },
    { key: "0", text: 'No' }
];

export const EditApplicationModal: React.FC<EditApplicationModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [application, setApplication] = React.useState<ApplicationPostModel>({
        name: props.defaultName,
        priority: 10,
        retriesCount: 1,
        defaultFlowsheetTimeout: "00:05",
        saveCalculatedFlowsheets: false,
        canRunOnAllAgents: true,
        agentsItCanRunOn: []
    } as unknown as ApplicationPostModel);
    const [agentOptions, setAgentOptions] = React.useState<IDropdownOption[]>([]);
    const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);


    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);


    const onModalClose = () => {
        setOpen(false);

        if (props.onClose) {

            props.onClose();
        }

    }

    React.useEffect(() => {
        if (props.applicationId)
            getApplication();
    }, [props.applicationId]);

    React.useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {
        await getAgents();
        if (props.applicationId) {
            await getApplication();
        }
    }


    const getApplication = async () => {
        try {
            const client = getApplicationsClient();
            const application = await client.getApplication(props.applicationId!);
            var updatedApplication = { ...application } as any as ApplicationPostModel;
            updatedApplication.agentsItCanRunOn = application.agentsItCanRunOn?.map(agent => agent.id as number);
            setApplication(updatedApplication);
            console.log("getApplication", application);

        } catch (error) {

            processServerError(error, undefined, "An error occurred while trying to get application.");
        }
    }

    const getAgents = async () => {
        try {
            const client = getAgentsClient();
            const agents = await client.getAgents();
            const agentGroups = getAgentGroups(agents);
            const agentOptionsResp = getAgentDropdownOptions(agentGroups);

            setAgentOptions(agentOptionsResp);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting Agents.", false);
        }
    }

    const getAgentGroups = (agents: AgentResponseModel[]): IAgentGroup[] => {
        let agentGroups: IAgentGroup[] = [];

        agents.forEach(agent => {
            var agentGroup = agentGroups.find(x => x.id == agent.agentGroupId);
            if (agentGroup) {
                agentGroup.agents.push(agent);
            } else {
                const agentGroup = {
                    id: agent.agentGroupId,
                    name: agent.agentGroupName,
                    agents: [agent]

                } as IAgentGroup;
                agentGroups.push(agentGroup);
            }

        });
        return agentGroups;
    }

    const getAgentDropdownOptions = (agentGroups: IAgentGroup[]): IDropdownOption[] => {
        var options: IDropdownOption[] = [];

        agentGroups.forEach(agentGroup => {

            if (agentGroup.agents.length > 0) {
                // options.push({ key: agentGroup.id, text: agentGroup.name });
                const agentOptions = agentGroup.agents.map((agent) => { return { key: agent!.id!.toString(), text: agent.displayName } as IDropdownOption });
                options.push(...agentOptions);
            }
        });

        return options;
    }

    const onTextValueChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, data?: InputOnChangeData) => {
        var property = ((event.target) as any).name;
        setApplication({ ...application, [property]: data?.value } as ApplicationPostModel);
    }
    const onRadioValueChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, data?: RadioGroupOnChangeData) => {

        var selectedValue = yesNoOptions.find(x => x.key == data?.value);
        var value = selectedValue!.key == "1" ? true : false;
        var property = (ev!.target as any).name;

        setApplication({ ...application, [property]: value } as ApplicationPostModel);

    }

    const onAgentsSelected = (event: any, data: OptionOnSelectData) => {

        const alreadySelected = application.agentsItCanRunOn?.find(x => x == +data!.optionValue!);


        if (!alreadySelected) {
            setApplication({ ...application, agentsItCanRunOn: [...application.agentsItCanRunOn ?? [], +data!.optionValue!] } as ApplicationPostModel);
        } else {
            const agentsItCanRunOnUpdated = application.agentsItCanRunOn?.filter(x => x !== (+data!.optionValue!)) ?? [];
            setApplication({ ...application, agentsItCanRunOn: [...agentsItCanRunOnUpdated] } as ApplicationPostModel);
        }
    }

    const getSelectedAgentsNames = (): string[] => {
        let names: string[] = [];
        const selectedOptions = agentOptions.filter(x => !!application.agentsItCanRunOn?.find(y => y == x.key));
        names = selectedOptions?.map(x => x.text!) ?? [];
        return names;

    }

    const onSaveButtonClicked = async () => {
        try {
            setIsSubmitted(true);

            if (isApplicationValid()) {
                var id: number | undefined = undefined;
                setIsSaving(true);

                const retriesCount = application?.retriesCount ? parseInt(application!.retriesCount!.toString()) : undefined;
                var model = new ApplicationPostModel({ ...application, retriesCount: retriesCount });
                model.priority = +application!.priority!;

                const client = getApplicationsClient();

                if (!model.id || model.id == 0) {
                    id = await client.create(model);
                    toast.success("Application created.");
                } else {
                    id = await client.update(model.id, model);
                    toast.success("Application updated.");
                }
                setIsSaving(false);

                props.onSuccess(id);
            }
        } catch (error) {
            setIsSaving(false);
            console.log("An Error occurred while creating Application.", error);
            toast.error("An Error occurred while creating Application.");
        }
    }

    const isValidName = (): boolean => {
        const { name } = application;
        if (name && name.length > 0) {
            return true;
        }
        return false;
    }

    const isValidPriority = (): boolean => {
        const { priority } = application;

        return /^\d+$/.test(priority!.toString());
    }
    const isValidRetriesCount = (): boolean => {
        const { retriesCount } = application;

        return /^\d+$/.test(retriesCount!.toString()) && +retriesCount! > 0;
    }

    const isValidTimeout = (): boolean => {
        const { defaultFlowsheetTimeout } = application;

        return /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.test(defaultFlowsheetTimeout!);
    }

    const isValidVersion = (version?: string) => {
        if (!version || version.length == 0)
            return true;

        if (/^(\d+\.)?(\d+\.)?(\*|\d+)$/.test(version)) {
            return true;
        }
        return false;
    }

    const isApplicationValid = () => {

        return isValidName()
            && isValidPriority()
            && isValidTimeout()
            && isValidRetriesCount()
            && isValidVersion(application!.requiredAgentVersionMin)
            && isValidVersion(application!.requiredAgentVersionMax);
    }
    const agentsItCanRunOn = application!.agentsItCanRunOn?.map((x) => x.toString()) ?? [];

    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface style={{ maxWidth: "700px" }}>
            <DialogBody style={{ maxWidth: "inherit" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                    {props.applicationId ? "Edit Application" : "Create Application"}</DialogTitle>
                <DialogContent className="edit-application">
                    <div className="input-form">
                        <div className="input-form-item">
                            <Label
                                className="input-label">Application name:</Label>
                            <Field
                                validationMessage={isSubmitted && !isValidName() ? "Name is required!" : ""}
                                validationState={isSubmitted && !isValidName() ? "error" : "none"}
                            >
                                <Input
                                    className="input-field"
                                    value={application?.name}
                                    name="name"
                                    onChange={onTextValueChanged} />
                            </Field>
                        </div>

                        <div className="input-form-item">
                            <Label className="input-label">Description:</Label>
                            <Textarea
                                className="input-field"
                                name="description"
                                rows={3}
                                value={application.description ? application.description : ""}
                                onChange={onTextValueChanged} />
                        </div>
                        <div className="input-form-item">
                            <Label className="input-label">Flowsheet timeout:</Label>

                            <Field
                                validationMessage={isSubmitted && !isValidTimeout() ? "Invalid time format!" : ""}
                                validationState={isSubmitted && !isValidTimeout() ? "error" : "none"}
                            >
                                <Input
                                    value={application.defaultFlowsheetTimeout ? application.defaultFlowsheetTimeout : ""}
                                    name="defaultFlowsheetTimeout"
                                    className="input-field-small"
                                    placeholder="HH:mm"
                                    onChange={onTextValueChanged} />
                            </Field>



                        </div>
                        <div className="input-form-item">
                            <Label className="input-label">Retries count:</Label>
                            <Field
                                validationMessage={isSubmitted && !isValidRetriesCount() ? "Retries count is required!" : ""}
                                validationState={isSubmitted && !isValidRetriesCount() ? "error" : "none"}>
                                <Input
                                    type="number"
                                    className="input-field-small"
                                    value={application.retriesCount ? application.retriesCount.toString() : ""}
                                    name="retriesCount"
                                    onChange={onTextValueChanged}
                                />
                            </Field>


                        </div>
                        <div className="input-form-item">
                            <Label className="input-label">Priority:</Label>

                            <Field
                                validationMessage={isSubmitted && !isValidPriority() ? "Priority is required!" : ""}
                                validationState={isSubmitted && !isValidPriority() ? "error" : "none"}>
                                <Input
                                    type="number"
                                    value={application.priority ? application.priority.toString() : ""}
                                    name="priority"
                                    className="input-field-small"
                                    onChange={onTextValueChanged}
                                    contentAfter={<Tooltip positioning="after" content="Lower number is higher priority." relationship="label">
                                        <Button appearance="transparent" icon={<Info20Regular />} />
                                    </Tooltip>}
                                />
                            </Field>

                        </div>

                        <div className="input-form-item">
                            <Label className="input-label">Save calculated flowsheet:</Label>
                            <RadioGroup name="saveCalculatedFlowsheets" className="input-field"
                                value={application.saveCalculatedFlowsheets !== undefined ? (application.saveCalculatedFlowsheets ? "1" : "0") : undefined}
                                onChange={onRadioValueChanged}
                            >
                                {yesNoOptions.map((option) => {
                                    return <Radio
                                        label={option.text}
                                        value={option.key?.toString()}
                                    />
                                })}

                            </RadioGroup>

                        </div>

                        <div className="input-form-item">
                            <Label className="input-label">Required Agent version:</Label>
                            <div style={{ width: "70%", maxWidth: "350px" }} >
                                <div style={{ display: "flex" }}>
                                    <Field
                                        className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                                        validationMessage={isSubmitted
                                            && application.requiredAgentVersionMin
                                            && application.requiredAgentVersionMin.length > 0
                                            && !isValidVersion(application.requiredAgentVersionMin)
                                            ? "Incorrect version format!" : ""}
                                        validationState={isSubmitted
                                            && application.requiredAgentVersionMin
                                            && application.requiredAgentVersionMin.length > 0
                                            && !isValidVersion(application.requiredAgentVersionMin) ? "error" : "none"}
                                    >
                                        <Input

                                            value={application.requiredAgentVersionMin ? application.requiredAgentVersionMin : ""}
                                            name="requiredAgentVersionMin"
                                            placeholder="Min"
                                            onChange={onTextValueChanged}
                                        />
                                    </Field>

                                    <span style={{ margin: "auto 5px" }}>-</span>

                                    <Field
                                        className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                                        validationMessage={isSubmitted
                                            && application.requiredAgentVersionMax
                                            && application.requiredAgentVersionMax.length > 0
                                            && !isValidVersion(application.requiredAgentVersionMax)
                                            ? "Incorrect version format!" : ""}
                                        validationState={isSubmitted
                                            && application.requiredAgentVersionMax
                                            && application.requiredAgentVersionMax.length > 0
                                            && !isValidVersion(application.requiredAgentVersionMax)
                                            ? "error" : "none"}>
                                        <Input
                                            value={application.requiredAgentVersionMax ? application.requiredAgentVersionMax : ""}
                                            name="requiredAgentVersionMax"
                                            onChange={onTextValueChanged}
                                            placeholder="Max" />
                                    </Field>
                                </div>
                            </div>
                        </div>

                        <div className="input-form-item">
                            <Label className="input-label">Can run on all agents:</Label>

                            <RadioGroup name="canRunOnAllAgents" className="input-field"
                                value={application.canRunOnAllAgents !== undefined ? (application.canRunOnAllAgents ? "1" : "0") : undefined}
                                onChange={onRadioValueChanged}
                            >
                                {yesNoOptions.map((option) => {
                                    return <Radio
                                        label={option.text}
                                        value={option.key?.toString()}
                                    />
                                })}

                            </RadioGroup>
                        </div>

                        {!application.canRunOnAllAgents && <div className="input-form-item" style={{ marginBottom: "20px" }}>
                            <Label className="input-label">Can run on agents:</Label>
                            <Dropdown
                                className="input-field"
                                placeholder="Select agents"
                                value={getSelectedAgentsNames().join(', ')}
                                selectedOptions={agentsItCanRunOn}
                                // eslint-disable-next-line react/jsx-no-bind
                                onOptionSelect={onAgentsSelected}
                                multiselect
                            >
                                {agentOptions?.map((item) => {
                                    return <Option value={item.key?.toString()} >
                                        {item.text}
                                    </Option>
                                })}

                            </Dropdown>
                        </div>
                        }


                    </div>
                </DialogContent>
                <DialogActions>
                    <Button appearance="primary" onClick={() => { onSaveButtonClicked(); }} >Save</Button>
                    <Button appearance="secondary" type="button" onClick={onModalClose} >Cancel</Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}