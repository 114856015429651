import { Tooltip } from "@fluentui/react-components";
import React from "react";
import { getSignificantFigures } from "../../utils/helpers/significant-figures";

type SignificantFiguresProps = {
    value: number;
    significantFigures: number;
    spanStyle?: React.CSSProperties;
}

export const SignificantFigures: React.FC<SignificantFiguresProps> = (props) => {

    return <Tooltip
        relationship="label"
        content={props.value?.toString()}>
        <span style={props.spanStyle}>{getSignificantFigures(props.value, props.significantFigures)}</span>
    </Tooltip>
}