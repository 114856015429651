
import React, { useState } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { useNavigate, useParams } from 'react-router-dom';
import { FilesTable, FilesTableSort } from '../../files/files-table/files-table.component';
import { FileTableItem } from '../../files/models/file-table.models';
import { SidePanel } from '../../files/side-panel/side-panel';
import { ParentPathLink } from '../../files/parent-path-link/parent-path-link.component';
import { TagOption } from '../../files/tag-picker/tag-picker.component';
import { getDashboardSharingClient, getFilesClient, getTagsClient } from '../../services/dashboard.service';
import { BreadcrumbItem, FileModel, FileSortFields, FileSystemEntityType } from '../../swagger-clients/s365-dashboard-v2-api-clients.service';
import { processServerError } from '../../utils/helpers/error.helper';
import { useLoading } from '../../utils/loading-indicator.component';
import { MenuItem } from '@fluentui/react-components';
import { CopyRegular, DocumentTextRegular } from '@fluentui/react-icons';
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from 's365-dashboard-v2-file-picker';
import { CopyFileDialog } from '../../files/copy-file-dialog/copy-file-dialog.component';
import { toast } from 'react-toastify';

type ExampleFilesPageProps = {
    hubConnection?: HubConnection
}

type ExampleFilesRouteParams = {
    parentSourceId?: string;
}
const emptyBreadcrumbs = [{ name: "Simulate 365 Examples" } as BreadcrumbItem];

function ExampleFilesPage(props: ExampleFilesPageProps) {

    const [files, setFiles] = React.useState<FileTableItem[]>([]);
    const [showTagInputs, setShowTagInputs] = useState<{ [key: string]: boolean }>({});
    const [selectedItem, setSelectedItem] = React.useState<FileTableItem>();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const [commentValidationState, setCommentValidationState] = React.useState<'error' | 'warning' | 'success'>();
    const [commentValidationMessage, setCommentValidationMessage] = React.useState<string>();
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [showCopyFileModal, setShowCopyFileModal] = React.useState<boolean>(false);
    const routeParams = useParams<ExampleFilesRouteParams>();
    const navigate = useNavigate();
    const [sortBy, setSortBy] = React.useState<FilesTableSort>({ field: FileSortFields.Name, isDescending: false });

    React.useEffect(() => {
        getFilesWithLoading();
    }, [routeParams.parentSourceId, sortBy]);

    React.useEffect(() => {
        if (commentValidationMessage) {
            setCommentValidationMessage(undefined);
        }
        if (commentValidationState) {
            setCommentValidationState(undefined);
        }
    }, [selectedItem]);



    const getFilesWithLoading = async () => {
        loadingService.showLoading("Loading...", (hideMessage) => {
            getFiles().finally(() => hideMessage());
        })
    }

    const getFiles = async () => {
        loadingService.showLoading("Loading files...", async (hideMessage) => {
            try {


                const client = getDashboardSharingClient();
                const resp = await client.getExamples(routeParams.parentSourceId ?? "", sortBy.field, sortBy.isDescending);

                if (resp) {
                    setFiles(resp?.files ?? []);
                    setBreadcrumbs(resp?.breadcrumbItems ? [...emptyBreadcrumbs, ...resp.breadcrumbItems] : emptyBreadcrumbs);
                    if (selectedItem) {
                        // selected item is tracked in different object so it needs to be updated
                        const updatedSelectedItem = resp?.files.find(x => x.id == selectedItem.id && x.type == selectedItem.type);
                        // console.log("updatedSelectedItem", updatedSelectedItem);
                        setSelectedItem({ ...updatedSelectedItem } as FileModel);
                    }

                }

            }
            catch (error) {
                processServerError(error, undefined, "An error occurred while getting examples.");
            }
            finally {
                hideMessage();
            }
        });

    }

    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        if (!selectedItem) {
            return menuItems;
        }


        menuItems.push(...[
            <MenuItem key="copy" icon={<CopyRegular />} onClick={() => setShowCopyFileModal(true)}>Clone to Dashboard</MenuItem >,
            <MenuItem key="details" icon={<DocumentTextRegular />} onClick={() => { setShowDetails(true); }}>Details</MenuItem >
        ]);


        return menuItems;
    }



    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/examples/${parentDirectoryId ?? ''}`);
    }

    const contextMenuItems = getContextItems();

    return (<div className='page-wrapper'>
        <div className='files-wrapper'>

            <div className='files-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>
                    {breadcrumbs.map((test: BreadcrumbItem) => {
                        return <Breadcrumb
                            key={`breadcrumb-${test.uniqueIdentifier ?? "dashboard"}`}
                            onClick={() => { setShowDetails(false); onBreadcrumbItemClick(test.uniqueIdentifier); }}>{test.name}</Breadcrumb>
                    })}
                </Breadcrumbs>
            </div>
            <FilesTable
                isLoading={isLoading}
                loadingService={loadingService}
                showLoadingRow
                files={files}
                readonly
                disableSubmitForAnalysis
                hideAnalyzedColumn
                hideAnalyticsColumn
                hideTagsColumn
                hideModifiedColumn
                basePagePath='/files/examples'
                sortable
                sortBy={sortBy}
                onSortChange={(data) => { setSortBy(data); }}
                contextMenuItems={contextMenuItems}
                hubConnection={props.hubConnection}
                onSelectedItemChanged={(item) => { setSelectedItem(item) }}
                onShowTagInputsChanged={(value) => setShowTagInputs(value)}
                onReloadFilesEvent={() => getFilesWithLoading()}
                onShowDetails={(item) => { setSelectedItem(item); setShowDetails(true); }} />

        </div>

        {/* Side panel */}
        <SidePanel
            isOpened={selectedItem && showDetails}
            selectedItem={selectedItem}
            readOnly
            fileTagDefaultOptions={[]}
            hubConnection={props.hubConnection}
            loadingService={loadingService}
            onHide={() => setShowDetails(false)}
            onReloadFilesEvent={() => getFilesWithLoading()}
        />

        {selectedItem && showCopyFileModal &&
            <CopyFileDialog
                fileToCopy={selectedItem}
                isClone
                isOpened={true}
                onClose={() => { setShowCopyFileModal(false); }}
                onSuccess={() => {
                    toast.success(`${selectedItem.type == FileSystemEntityType.File ? 'File' : 'Folder'}  cloned successfully.`);
                    setShowCopyFileModal(false);
                    getFilesWithLoading();
                }}
            />}

    </div>
    );
}

export default ExampleFilesPage;