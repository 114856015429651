import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Label, DialogActions, Tab, TabList, Textarea } from "@fluentui/react-components";
import { Dismiss24Filled, EditRegular } from "@fluentui/react-icons";
import React from "react";
import { getApplicationsClient } from "../../services/dispatcher.service";
import { ApplicationResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import "./application-details-modal.styless.scss";
import { ApplicationKeysList } from "./application-keys-list.component";

type ApplicationDetailsModalProps = {
    applicationId: number;
    isOpened?: boolean;
    hasEdit?: boolean;
    onClose?: () => void;
    onEditClick?: () => void;

}


export const ApplicationDetailsModal: React.FC<ApplicationDetailsModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [application, setApplication] = React.useState<ApplicationResponseModel>();
    const [selectedTab, setSelectedTab] = React.useState<string>("settings");

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    React.useEffect(() => {
        if (props.applicationId)
            getApplication();

    }, [props.applicationId]);

    const getApplication = async () => {
        try {
            const client = getApplicationsClient();
            const applicationResp = await client.getApplication(props.applicationId!);
            setApplication(applicationResp);

        } catch (error) {

            processServerError(error, undefined, "An error occurred while trying to get application.");
        }
    }


    const onModalClose = () => {
        setOpen(false);

        if (props.onClose) {
            setApplication(undefined);
            props.onClose();
        }

    }

    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface style={{ maxWidth: "700px" }}>
            <DialogBody style={{ maxWidth: "inherit" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <span>Application: <i>{`${application?.name}`}</i></span>
                        {props.hasEdit && <Button size="small"
                            appearance="subtle"
                            style={{ marginLeft: "var(--spacingHorizontalM)" }}
                            onClick={() => { props.onEditClick() }}
                            icon={<EditRegular />}> Edit</Button>}
                    </div></DialogTitle>
                <DialogContent className="application-details" style={{ minHeight: "510px" }}>
                    <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { setSelectedTab(data.value as string); }}>
                        <Tab key="active" value="settings" >Settings</Tab>
                        <Tab key="api-keys" value="api-keys">Api keys</Tab>
                    </TabList>
                    <div className="tab-content">
                        {selectedTab == "settings" &&
                            <div className="input-form">
                                <div className="input-form-item">
                                    <Label
                                        className="input-label">Application name:</Label>
                                    <span>{application?.name}</span>

                                </div>

                                <div className="input-form-item">
                                    <Label className="input-label">Description:</Label>
                                    <Textarea
                                        className="input-field input-field-text-area"
                                        name="description"
                                        rows={3}
                                        readOnly
                                        disabled
                                        value={application && application.description ? application.description : ""}
                                    />
                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Flowsheet timeout:</Label>
                                    <span>{application?.defaultFlowsheetTimeout}</span>
                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Retries count:</Label>
                                    <span>{application?.retriesCount}</span>
                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Priority:</Label>
                                    <span>{application?.priority}</span>
                                </div>

                                <div className="input-form-item">
                                    <Label className="input-label">Save calculated flowsheet:</Label>
                                    <span>{application?.saveCalculatedFlowsheets != undefined ? application.saveCalculatedFlowsheets == true ? "Yes" : "No" : "-"}</span>
                                </div>

                                <div className="input-form-item">
                                    <Label className="input-label">Minimal required agent version:</Label>
                                    <span>{application?.requiredAgentVersionMin ? application.requiredAgentVersionMin : "-"}</span>

                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Maximal supported agent version:</Label>
                                    <span>{application?.requiredAgentVersionMax ? application.requiredAgentVersionMax : "-"}</span>

                                </div>

                                <div className="input-form-item">
                                    <Label className="input-label">Can run on all agents:</Label>
                                    <span>{application?.canRunOnAllAgents != undefined ? application.canRunOnAllAgents == true ? "Yes" : "No" : "-"}</span>
                                </div>
                                <div className="input-form-item">
                                    <Label className="input-label">Can run on agents:</Label>
                                    <span style={{ flex: 1 }}>{application?.agentsItCanRunOn ? application.agentsItCanRunOn.map((agent, index) => {
                                        const lastIndex = application.agentsItCanRunOn.length - 1;
                                        return index == lastIndex ?
                                            <span style={{ display: "inline-block" }}> {agent.displayName} </span>
                                            :
                                            <span style={{ display: "inline-block" }}>{agent.displayName},&nbsp;</span>

                                    }) : ""}</span>

                                </div>




                            </div>
                        }
                        {selectedTab == "api-keys" &&
                            <div>
                                <ApplicationKeysList applicationId={props.applicationId} applicationName={application?.name} isDeleted={application?.isDeleted ?? false} />
                            </div>}

                    </div>
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}