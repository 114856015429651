import React from "react";
import { ApplicationResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { getApplicationsClient } from "../../services/dispatcher.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { Button, Label, Toolbar, ToolbarButton, Tooltip, teamsHighContrastTheme } from "@fluentui/react-components";
import { ArrowSyncRegular, CopyRegular, Edit20Regular } from "@fluentui/react-icons";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "s365-dashboard-v2-file-picker";
import { BreadcrumbItem } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog.component";

type ClusterSettingsDetailsProps = {

}

export const ClusterSettingsDetails: React.FC<ClusterSettingsDetailsProps> = (props) => {

    const [application, setApplication] = React.useState<ApplicationResponseModel>();
    const [isLoading, loadingService] = useLoading();
    const [showRegenerateConfirmationDialog, setShowRegenerateConfirmationDialog] = React.useState<boolean>(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        getDefaultApplication();
    }, []);

    const getDefaultApplication = () => {
        loadingService.showLoading("Loading cluster settings...", async (hideMessage) => {
            try {
                const client = getApplicationsClient();
                const defaultApp = await client.getDefaultApplication();
                setApplication(defaultApp);
            } catch (error) {
                processServerError(error, undefined, 'An error occurred while getting Cluster settings.');
            } finally {
                hideMessage();
            }
        });

    }
    const onCopyApiKeyClick = () => {
        navigator.clipboard.writeText(application?.applicationApiKey);
        toast.success("API key copied.");
    }
    const onRegenerateApiKeyClick = () => {
        loadingService.showLoading("Generating new API key...", async (hideMessage) => {
            try {
                const client = getApplicationsClient();
                await client.generateDefaultApplicationApiKey();
                toast.success("API key was regenerated successfully.");
                getDefaultApplication();

            } catch (error) {
                processServerError(error, undefined, 'An error occurred while generating new API key.');
            } finally {
                hideMessage();
            }
        });
    }


    return <div className="content-wrapper">

        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton appearance='subtle' disabled={isLoading}
                    onClick={() => { navigate(`/cluster/settings/edit`) }}
                    icon={<Edit20Regular />}>Edit</ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />
            </Toolbar>
        </div>

        <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>               
                <Breadcrumb key={`breadcrumb-cluster`} onClick={() => navigate(`/cluster`)}>Cluster</Breadcrumb>

                <Breadcrumb key={`breadcrumb-sensitivity-studies`} active={true}>Settings</Breadcrumb>

            </Breadcrumbs>
        </div>


        <div className="input-form">
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">
                    Flowsheet timeout (hours:min:sec):
                </Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{application?.defaultFlowsheetTimeout}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">
                    Retries:
                </Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{application?.retriesCount}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">
                    Save calculated flowsheets:
                </Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>
                    {application && (application.saveCalculatedFlowsheets ? "Yes" : "No")}
                </p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label">
                    API key:
                </Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>
                    {application?.applicationApiKey}
                </p>
                {application?.applicationApiKey &&
                    <Tooltip content="Copy" relationship="description">
                        <Button appearance="transparent" icon={<CopyRegular />} onClick={onCopyApiKeyClick} />
                    </Tooltip>}
                {application && <Tooltip content="Regenerate" relationship="description">
                    <Button appearance="transparent" icon={<ArrowSyncRegular />} onClick={() => { setShowRegenerateConfirmationDialog(true); }} />
                </Tooltip>}

            </div>


        </div>
        {showRegenerateConfirmationDialog && <ConfirmationDialog
            isOpened={showRegenerateConfirmationDialog}
            isRequestInProgress={isLoading}
            onConfirm={() => { setShowRegenerateConfirmationDialog(false); onRegenerateApiKeyClick(); }}
            title="Regenerate API key"
            subText={<span>Current API key will be invalidated and new one will be created.
                <br />Are you sure you want to proceed?</span>}
            confirmLabel="Yes"
            cancelLabel="No"
            onClose={() => { setShowRegenerateConfirmationDialog(false) }}
        />}
    </div>

}