import * as React from "react";
import { ExamStatus } from "../../swagger-clients/s365-take-home-exams-v2-clients.service";

export interface IExamStatusProps {
    status: ExamStatus;
}

export const ExamStatusComponent: React.FC<IExamStatusProps> = (props) => {

    const examStatuses: { [status: string]: { color: string, name: string } } =
    {
        [ExamStatus.Draft.toString()]: { color: "#115ea3", name: "Draft" },
        [ExamStatus.Cancelled.toString()]: { color: "red", name: "Cancelled" },
        [ExamStatus.Finished.toString()]: { color: "grey", name: "Finished" },
        [ExamStatus.Started.toString()]: { color: "green", name: "In progress" }
    };
    if (props.status === undefined) {
        return null;
    }

    const status = examStatuses[props.status];


    return <span style={{ color: status.color }}>{status.name}</span>;


}