import React from "react";
import { OptimizerSettingsModel } from "../edit-experiment.models";
import { Label, Field, Input } from "@fluentui/react-components";
import { GLOBAL_STYLES } from "../../../styles";
import { IExperimentValidation } from "../edit-experiment.validation";

type OptimizerSettingsTabProps = {
    settings?: OptimizerSettingsModel;
    onChange?: (settings: OptimizerSettingsModel) => void;
    isDetails?: boolean;
    isFormSubmitted?: boolean;
    experimentValidation?: IExperimentValidation;
}

export const OptimizerSettingsTab: React.FC<OptimizerSettingsTabProps> = (props) => {
    const { settings } = props;

    return <div className="input-form">
        <div className="input-form-item" style={{ marginBottom: props.isDetails ? 0 : undefined }}>
            <Label className="input-form-label" style={{ textAlign: "left", maxWidth: "200px" }} >
                Convergence timeout:
            </Label>
            {props.isDetails && <span>{settings?.convergenceTimeout}</span>}
            {!props.isDetails && <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                validationMessage={props.isFormSubmitted && !!props.experimentValidation &&
                    !props.experimentValidation.convergenceTimeout.isValid && props.experimentValidation.convergenceTimeout.validationErrors[0]}
                validationState={props.isFormSubmitted && !!props.experimentValidation &&
                    !props.experimentValidation.convergenceTimeout.isValid ? "error" : "none"}
            >
                <Input type="number"
                    readOnly={props.isDetails}
                    value={settings?.convergenceTimeout?.toString() ?? ""}
                    onChange={(ev, data) => { props.onChange({ ...settings, convergenceTimeout: data.value as any }) }}
                    contentAfter={<span style={{ fontWeight: 500, marginLeft: "var(--spacingHorizontalS)" }}>seconds</span>}  />
            </Field>
            }
        </div>
        <div className="input-form-item" style={{ marginBottom: props.isDetails ? 0 : undefined }}>
            <Label className="input-form-label" style={{ textAlign: "left", maxWidth: "200px" }}>
                Internal tolerance:
            </Label>
            {props.isDetails && <span>{settings?.internalTolerance}</span>}
            {!props.isDetails && <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
            validationMessage={props.isFormSubmitted && !!props.experimentValidation &&
                !props.experimentValidation.internalTolerance.isValid && props.experimentValidation.internalTolerance.validationErrors[0]}
            validationState={props.isFormSubmitted && !!props.experimentValidation &&
                !props.experimentValidation.internalTolerance.isValid ? "error" : "none"}
            >
                <Input type="number"
                    readOnly={props.isDetails}
                    value={settings?.internalTolerance?.toString() ?? ""}
                    onChange={(ev, data) => { props.onChange({ ...settings, internalTolerance: data.value as any }) }} />
            </Field>
            }
        </div>
        <div className="input-form-item" style={{ marginBottom: props.isDetails ? 0 : undefined }}>
            <Label className="input-form-label" style={{ textAlign: "left", maxWidth: "200px" }}>
                External tolerance:
            </Label>
            {props.isDetails && <span>{settings?.externalTolerance}</span>}
            {!props.isDetails && <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`} 
            validationMessage={props.isFormSubmitted && !!props.experimentValidation &&
                !props.experimentValidation.externalTolerance.isValid && props.experimentValidation.externalTolerance.validationErrors[0]}
            validationState={props.isFormSubmitted && !!props.experimentValidation &&
                !props.experimentValidation.externalTolerance.isValid ? "error" : "none"}
            >
                <Input type="number"
                    readOnly={props.isDetails}
                    value={settings?.externalTolerance?.toString() ?? ""}
                    onChange={(ev, data) => { props.onChange({ ...settings, externalTolerance: data.value as any }) }} />
            </Field>
            }
        </div>
        <div className="input-form-item" style={{ marginBottom: props.isDetails ? 0 : undefined }}>
            <Label className="input-form-label" style={{ textAlign: "left", maxWidth: "200px" }}>
                Maximum cost:
            </Label>
            {props.isDetails && <span>{settings?.maximumCost} €</span>}
            {!props.isDetails && <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`} 
            validationMessage={props.isFormSubmitted && !!props.experimentValidation &&
                !props.experimentValidation.maximumCost.isValid && props.experimentValidation.maximumCost.validationErrors[0]}
            validationState={props.isFormSubmitted && !!props.experimentValidation &&
                !props.experimentValidation.maximumCost.isValid ? "error" : "none"}
            >
                <Input type="number"
                    readOnly={props.isDetails}
                    value={settings?.maximumCost?.toString() ?? ""}
                    onChange={(ev, data) => { props.onChange({ ...settings, maximumCost: data.value as any }) }}
                    contentAfter={<span style={{ fontWeight: 500, marginLeft: "var(--spacingHorizontalS)" }}>€</span>} />
            </Field>}

        </div>        
    </div>
}