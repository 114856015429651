import { Label, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, Tab, TabList, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Alert } from "@fluentui/react-components/unstable";
import { ArrowClockwise20Regular, ArrowSyncDismissRegular, ArrowSyncRegular, Copy20Regular, DocumentSaveRegular, DocumentTable20Regular, Edit20Regular, Play20Regular, PlayRegular, StopRegular } from "@fluentui/react-icons";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { DispatcherUsageStatistics, DispatcherUsageStatisticsType } from "../../components/dispatcher-usage-statistics/dispatcher-usage-statistics.component";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import { getFilesClient, getFiltersClient, getUserToken } from "../../services/dashboard.service";
import { getScenarioProcessingClient, getScenariosClient } from "../../services/excel-runner.service";
import { AnalysisStatus, CalculationType, FileRowResultModel, ProcessingStatus, ResubmitFailedJobsPostModel, ResubmitRowPostModel, ScenarioProcessingQuery, ScenarioQuery, ScenarioResultModel, ScenarioWriteResultsQuery } from "../../swagger-clients/excel-runner-api-clients.service";
import { BreadcrumbItem, FileModel, FileWithBreadcrumbsResponseModel, FilterQuery, FilterResultModel, IFilterQuery, ShareDefinitionSourceType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { IChoiceGroupOption } from "../../utils/shared.types";
import { GetScenarioCurrentStep, GetScenarioCurrentStepStatus } from "../utilities/scenario.helpers";
import DataTab, { DataTabType } from "./data-tab/data-tab.component";
import { ScenarioDetailsInputParametersTab } from "./input-parameter-tab-details.component";
import { ScenarioDetailsOutputParametersTab } from "./output-parameter-tab-details.component";
import { IWriteResultsModel, WriteResultsDialog } from "./write-results-dialog/write-results-dialog.component";
import { WriteResultsStatusModal } from "./write-results-dialog/write-results-status-modal.component";
import { ThumbnailImage } from "../../files/thumbnail/thumbnail-image/thumbnail-image.component";
import { HubConnection } from "@microsoft/signalr";
import { JobProgressCount } from "../../components/job-progress-count/job-progress-count.component";
import { ExportDoeDataExcel } from "./export-doe-data-excel.component";
import { ThumbnailModal } from "../../files/thumbnail/thumbnail-modal/thumbnail-modal.component";
import { ExcelRunnerRouteParams } from "../edit-scenario/edit-scenario.component";
import { getFileNameWithoutExtension } from "../../files/file-type-icon/file-type-icon.helpers";


type ScenarioDetailsProps = {
    scenarioId?: number;
    hubConnection?: HubConnection;
    isExample?: boolean;
}
const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];

export const ScenarioDetails: React.FC<ScenarioDetailsProps> = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [isExcelBased, setIsExcelBased] = React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState<string>("data");
    // selectedFile can be excel or flowsheet, selectedFilterFile is allways flowsheet
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedFilterFile, setSelectedFilterFile] = React.useState<FileModel>();
    const [filter, setFilter] = React.useState<FilterResultModel>(null);
    const [reloadDataTab, setReloadDataTab] = React.useState<boolean>(false);
    const [showStopProcessingDialog, setShowStopProcessingDialog] = React.useState<boolean>(false);
    const [showWriteResultsDialog, setShowWriteResultsDialog] = React.useState<boolean>(false);
    const [showStartProcessingDialog, setShowStartProcessingDialog] = React.useState<boolean>(false);
    const [showWriteResultsStatusModal, setShowWriteResultsStatusModal] = React.useState<boolean>(false);
    const [writeResultsRequestId, setWriteResultsRequestId] = React.useState<string>();
    const [selectedScenario, setSelectedScenario] = React.useState<ScenarioResultModel>(
        { firstRow: 4, calculationType: 1, inputParameters: [], outputParameters: [] } as ScenarioResultModel);

    const [includeData, setIncludeData] = React.useState<boolean>(false);
    const [includeCovergedData, setIncludeConvergedData] = React.useState<boolean>(false);
    const [showExportModal, setShowExportModal] = React.useState<boolean>(false);
    const [showThumbnailModal, setShowThumbnailModal] = React.useState<boolean>(false);
    const routeParams = useParams<ExcelRunnerRouteParams>();
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();
    const usageStatisticsRef = React.useRef<DispatcherUsageStatisticsType>();
    const dataTabRef = React.useRef<DataTabType>();
    const getExperimentId = () => {
        if (props.isExample)
            return props.scenarioId;
        else return routeParams.scenarioId;
    }
    const scenarioId = useMemo(() => { return getExperimentId(); }, [props.scenarioId, routeParams.scenarioId]);

    React.useEffect(() => {
        getScenario();
    }, []);

    React.useEffect(() => {
        if (selectedScenario?.id) {
            getFilter(selectedScenario);
        }
    }, [selectedScenario?.id]);

    React.useEffect(() => {

        if (filter?.id) {
            getFile();
        }
    }, [filter?.id]);

    const getFile = async () => {
        const messageId = loadingService.showMessage("Getting file...");
        try {
            const client = getFilesClient();
            // on excel file based experiments fileDriveId is fileUniqueId
            const fileUniqueId = isExcelBased ? selectedScenario?.fileDriveId : filter.fileUniqueIdentifier!;
            const fileVersionNumber = isExcelBased ? undefined : filter.fileVersionNumber;

            let resp: FileWithBreadcrumbsResponseModel = undefined;
            if (!!fileVersionNumber) {
                resp = await client.getSharedFile(fileUniqueId, fileVersionNumber, ShareDefinitionSourceType.DoEExperiment, scenarioId.toString(), true);
            } else {
                resp = await client.getSharedFileLatest(fileUniqueId, ShareDefinitionSourceType.DoEExperiment, scenarioId.toString(), true);
            }
            if (isExcelBased) {
                const filterFileResp = await client.getSharedFile(filter?.fileUniqueIdentifier, filter?.fileVersionNumber, ShareDefinitionSourceType.DoEExperiment, scenarioId.toString(), true);
                setSelectedFilterFile(filterFileResp.file);
            } else {
                setSelectedFilterFile(resp.file);
            }
            if (resp) {
                setSelectedFile(resp.file);
                setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
            }

            return resp;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file information.");
            return undefined;
        } finally {
            loadingService.hideMessage(messageId);
        }
    }
    const getScenario = async () => {

        const messageId = loadingService.showMessage("Loading experiment...");
        try {
            const client = getScenariosClient();


            const scenario = await client.getScenarioById(+scenarioId);
            setSelectedScenario(scenario);
            if (!!scenario.fileDriveId) {
                setIsExcelBased(true);
                setSelectedTab("input-parameters");
            }
            return scenario;
        }
        catch (error) {
            console.log("An error occurred while getting experiment.", error);
        } finally {
            loadingService.hideMessage(messageId);
        }
    }

    const getFilter = async (scenario: ScenarioResultModel) => {
        const messageId = loadingService.showMessage("Loading filter...");
        try {

            const client = getFiltersClient();
            const filterResult = await client.getSharedFilter(ShareDefinitionSourceType.DoEExperiment, scenario.id.toString(), scenario.filterId, scenario.filterVersionId);

            setFilter(filterResult);
        }
        catch (error) {
            console.log("An error ocurred while trying to get filter.", error);
        } finally {
            loadingService.hideMessage(messageId);
        }
    }
    const onConfirmStartProcessingClick = () => {
        loadingService.showLoading("Starting the calculation...", async (hideMessage) => {
            try {
                const accessToken: string = await getUserToken();
                const client = getScenarioProcessingClient();
                const query = { scenarioId: selectedScenario.id, accessToken: accessToken } as ScenarioProcessingQuery;
                await client.startProcessing(query);
                toast.success("Calculation started successfully.");
                await getScenario();
            } catch (error) {
                processServerError(error, undefined, 'An error occurred while starting the calculation.');
            } finally {
                hideMessage();
                setShowStartProcessingDialog(false);
            }


        });
    }

    const stopPocessing = async () => {
        loadingService.showLoading("Stopping the calculation...", async (hideMessage) => {

            const accessToken: string = await getUserToken();
            const client = getScenarioProcessingClient();
            const query = { scenarioId: selectedScenario.id, accessToken: accessToken } as ScenarioProcessingQuery;
            try {
                await client.stopProcessing(query);
                toast.success("Calculation stopped successfully.");
                await getScenario();
            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to stop the calculation.");
            } finally {
                hideMessage();
            }
        });


    }

    const resumeProcessing = async () => {

        loadingService.showLoading("Resuming the calculation...", async (hideMessage) => {
            const accessToken: string = await getUserToken();
            const client = getScenarioProcessingClient();
            const query = { scenarioId: selectedScenario.id, accessToken: accessToken } as ScenarioProcessingQuery;

            try {
                await client.resumeProcessing(query);
                toast.success("Calculation resumed successfully.");
                await getScenario();
            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to resume the calculation.");
            } finally {
                hideMessage();
            }
        });

    }
    const WriteResults = async (data: IWriteResultsModel) => {
        loadingService.showLoading("Writing results...", async (hideMessage) => {
            try {


                const accessToken: string = await getUserToken();
                const client = getScenariosClient();
                const writeResultsResp = await client.writeResults({
                    scenarioId: selectedScenario.id,
                    writeDataTo: data.writeDataTo,
                    filename: data.filename,
                    accessToken: accessToken, writeIds: data.writeRowIds
                } as ScenarioWriteResultsQuery);

                if (!!writeResultsResp && !!writeResultsResp.requestId) {
                    setWriteResultsRequestId(writeResultsResp.requestId);
                    setShowWriteResultsStatusModal(true);
                }

                //  toast.info("Results are being written in background. This will take a few seconds.");
            } catch (error) {
                console.log("An error occurred while writing results.", error);
                toast.error("An error occurred while writing results.");

            }
            finally {
                hideMessage();
            }
        });
        getScenario();

    }

    const resubmitFailedRows = async () => {
        loadingService.showLoading(
            "Resubmitting failed jobs...",
            async (hideMessage) => {
                try {
                    const accessToken: string = await getUserToken();
                    const client = getScenarioProcessingClient();
                    await client.resubmitFailedRows({
                        scenarioId: scenarioId,
                        accessToken: accessToken
                    } as ResubmitFailedJobsPostModel);

                    toast.success(`Recalculation of failed jobs has started.`);

                } catch (error) {
                    processServerError(error, undefined, "An error occurred while trying to resubmit failed jobs to dispatcher.");
                } finally {
                    hideMessage();
                }
            });
    }


    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }
    const onRefreshClick = async () => {
        await getScenario();
        if (dataTabRef && dataTabRef.current) {
            dataTabRef.current.getData();
        }
        if (usageStatisticsRef && usageStatisticsRef.current) {
            usageStatisticsRef.current.GetUsageStatistics();
        }
    }



    const getToolbarItems = () => {
        let commandBarItems = [];

        if (!!selectedScenario && selectedScenario.processingStatus == ProcessingStatus.NotRunning && !isExcelBased) {
            commandBarItems.push(
                <ToolbarButton
                    appearance='subtle'
                    icon={<Edit20Regular />}
                    onClick={() => navigate(`/files/${selectedScenario?.fileDriveId}/doe/edit/${scenarioId}`)}
                >Edit</ToolbarButton>
            );
        }

        commandBarItems.push(
            <ToolbarButton
                appearance='subtle'
                icon={<Copy20Regular />}
                onClick={() => navigate(`/files/${selectedScenario?.fileDriveId}/${isExcelBased ? "doe-xls" : "doe"}/edit?cloneId=${scenarioId}`)}
            >Clone</ToolbarButton>,
            <ToolbarButton
                appearance='subtle'
                icon={<ArrowClockwise20Regular />}
                onClick={() => { onRefreshClick(); }}
            >Refresh</ToolbarButton>
        );



        if (isExcelBased) {
            commandBarItems.push(
                <ToolbarButton
                    appearance='subtle'
                    icon={<DocumentSaveRegular />}
                    onClick={() => { setShowWriteResultsDialog(true); }}
                    disabled={isLoading || !!selectedScenario.isDeleted}
                >Write Results</ToolbarButton>);
        }

        if (selectedScenario && selectedScenario.processingStatus == ProcessingStatus.Running) {
            commandBarItems.push(<ToolbarButton
                appearance='subtle'
                icon={<StopRegular />}
                onClick={() => { setShowStopProcessingDialog(true); }}
                disabled={isLoading || !!selectedScenario.isDeleted}
            >Stop calculation</ToolbarButton>);
        }
        if (selectedScenario && selectedScenario.processingStatus == ProcessingStatus.Stopped) {
            commandBarItems.push(<ToolbarButton
                appearance='subtle'
                icon={<PlayRegular />}
                onClick={() => { resumeProcessing(); }}
                disabled={isLoading || !!selectedScenario.isDeleted}
            >Resume calculation</ToolbarButton>);
        }
        if (selectedScenario && selectedScenario.processingStatus == ProcessingStatus.NotRunning) {


            commandBarItems.push(<ToolbarButton appearance='subtle' key="start"
                icon={<Play20Regular />}
                disabled={isLoading || !!selectedScenario.isDeleted}
                onClick={() => { setShowStartProcessingDialog(true); }}>Start calculation</ToolbarButton>);
        }


        if (selectedScenario && selectedScenario.analysisStatus == AnalysisStatus.AnalyzedSuccessfuly && selectedScenario.processingStatus != ProcessingStatus.NotRunning) {
            commandBarItems.push(
                <Menu positioning="below-start">
                    <MenuTrigger>
                        {(triggerProps: MenuButtonProps) => {
                            return <MenuButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                                appearance='subtle'
                                icon={<ArrowSyncRegular />}
                                {...triggerProps}
                            >Resubmit jobs</MenuButton>
                        }}
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList>
                            <MenuItem key="resubmit-failed"
                                icon={<ArrowSyncDismissRegular />}
                                disabled={selectedScenario && !!selectedScenario.isDeleted}
                                onClick={() => { resubmitFailedRows(); }}
                            >Resubmit all failed jobs to Dispatcher</MenuItem>

                        </MenuList>
                    </MenuPopover>
                </Menu>

            );
        }



        if (selectedScenario.processingStatus == ProcessingStatus.Finished) {
            commandBarItems.push(
                <Menu positioning="below-start">
                    <MenuTrigger>
                        {(triggerProps: MenuButtonProps) => {
                            return <MenuButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                                appearance='subtle'
                                icon={<DocumentTable20Regular />}
                                {...triggerProps}
                            >Export to Excel</MenuButton>
                        }}
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList>
                            <MenuItem key="dataonly"
                                icon={<DocumentTable20Regular />}
                                disabled={selectedScenario && !!selectedScenario.isDeleted}
                                onClick={() => { setShowExportModal(true); setIncludeData(false); setIncludeConvergedData(false); }}
                            >Input data only</MenuItem>

                            <MenuItem key="convergeddataonly"
                                icon={<DocumentTable20Regular />}
                                disabled={selectedScenario && !!selectedScenario.isDeleted}
                                onClick={() => { setShowExportModal(true); setIncludeConvergedData(true); }}
                            >Converged data only</MenuItem>

                            <MenuItem key="alldata"
                                icon={<DocumentTable20Regular />}
                                disabled={selectedScenario && !!selectedScenario.isDeleted}
                                onClick={() => { setShowExportModal(true); setIncludeData(true); setIncludeConvergedData(false); }}
                            >All data</MenuItem>

                        </MenuList>
                    </MenuPopover>
                </Menu>

            );
        }


        return commandBarItems;
    }

    const scenarioCalculationTypes: IChoiceGroupOption[] = [
        { key: '1', text: 'Run all' },
        { key: '2', text: 'Run step by step' },

    ];

    let selectedCalculationType = "";

    if (selectedScenario && selectedScenario.calculationType) {
        const selectedType = scenarioCalculationTypes.find(x => x.key == selectedScenario.calculationType.toString());

        selectedCalculationType = selectedType.text;
    }


    const flowsheetParentDirectoryUniqueId = breadcrumbs && breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].uniqueIdentifier : undefined;



    return <div className="content-wrapper">

        {!props.isExample && <div className='toolbar__wrapper'>
            <Toolbar>
                {getToolbarItems()}
            </Toolbar>
        </div>}

        {!props.isExample && <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/${isExcelBased ? "doe-xls" : "doe"}`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-doe`} active={true}>Design of Experiments</Breadcrumb>

            </Breadcrumbs>
        </div>}

        {selectedScenario && selectedScenario.analysisStatus == AnalysisStatus.AnalysisFailed &&
            <Alert intent="error" style={{ margin: "10px 0px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: 800 }}>File analysis failed!</span>
                    <span>{selectedScenario.analysisMessage ? selectedScenario.analysisMessage : ""}</span>
                </div>
            </Alert>}

        {selectedScenario && selectedScenario.resultsSavingMessage &&
            <Alert intent="error" style={{ margin: "10px 0px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: 800 }}>Writing results to source file failed.</span>
                    <span>{selectedScenario.resultsSavingMessage ? selectedScenario.resultsSavingMessage : ""}</span>
                </div>
            </Alert>}


        <div style={{ display: "flex", gap: "10px" }}>
            <div className="input-form" style={{ flex: 1 }}>
                <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Experiment name:
                    </Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{selectedScenario?.name}</p>
                </div>

                {!props.isExample && <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Flowsheet:
                    </Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}><FileDisplayName
                        fileUniqueIdentifier={filter?.fileUniqueIdentifier}
                        fileVersionNumber={filter?.fileVersionNumber}
                    /></p>
                </div>}

                <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Filter:
                    </Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{filter?.name}</p>
                </div>

                {!!isExcelBased && <>  <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Sheet:
                    </Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{selectedScenario?.sheet}</p>
                </div>

                    <div className="input-form-item input-form-item--without-margin">
                        <Label className="input-form-label">
                            Calculation type:
                        </Label>
                        <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{selectedCalculationType}</p>
                    </div>
                    <div className="input-form-item input-form-item--without-margin">
                        <Label className="input-form-label">
                            First row:
                        </Label>
                        <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{selectedScenario?.firstRow?.toString()}</p>
                    </div></>}
                <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Current step:
                    </Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{GetScenarioCurrentStep(selectedScenario?.analysisStatus)}</p>
                </div>
                <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Status:
                    </Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{GetScenarioCurrentStepStatus(selectedScenario?.analysisStatus, selectedScenario?.processingStatus)}</p>
                </div>
                <div className="input-form-item input-form-item--without-margin">
                    <Label className="input-form-label">
                        Jobs:
                    </Label>
                    <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>
                        <JobProgressCount
                            totalJobs={selectedScenario?.totalRows}
                            calculatedJobs={selectedScenario?.calculatedRows}
                            failedJobs={selectedScenario?.failedRows} />
                    </p>
                </div>



            </div>
            {filter && props.hubConnection &&
                <div className="thumbnail--wrapper" style={{ flex: 1, marginTop: "5px" }}>
                    <Label className="group-label">Thumbnail:</Label>
                    <div style={{ flex: 1 }}>
                        <ThumbnailImage
                            fileUniqueIdentifier={filter.fileUniqueIdentifier!}
                            fileId={filter.fileId!}
                            fileVersionNumber={filter.fileVersionNumber!}
                            fileExtension={"dwxmz"}
                            onClick={() => { setShowThumbnailModal(true); }}
                            hubConnection={props.hubConnection}
                            asBackground={true}
                            fullImage={true}
                        />
                        {selectedFilterFile && showThumbnailModal &&
                            <ThumbnailModal
                                file={selectedFilterFile}
                                hubConnection={props.hubConnection!}
                                isOpened={showThumbnailModal}
                                onClose={() => setShowThumbnailModal(false)}
                            />}
                    </div>
                </div>}
        </div>


        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
            {!!isExcelBased && <>
                <Tab key="input-tab" value="input-parameters" >Input parameters</Tab>
                <Tab key="output-tab" value="output-parameters">Output parameters</Tab>
            </>}
            {selectedScenario && selectedScenario.calculationType == CalculationType.StepByStep && <Tab key="step-by-step-tab" value="step-by-step">Step by step settings</Tab>}
            <Tab key="data-tab" value="data">Data</Tab>
            {!props.isExample && <Tab key="statistics-tab" value="statistics">Statistics</Tab>}
        </TabList>
        <div className="tab-content">
            {selectedTab == "input-parameters" && selectedScenario && filter && <>
                <ScenarioDetailsInputParametersTab isLoading={isLoading} scenario={selectedScenario} filter={filter} />
            </>}

            {selectedTab == "output-parameters" && selectedScenario && filter &&
                <ScenarioDetailsOutputParametersTab isLoading={isLoading} scenario={selectedScenario} filter={filter} />}
            {selectedTab == "step-by-step" && selectedScenario && selectedScenario.calculationType == CalculationType.StepByStep &&
                <div>
                    <Label className="input-form-label" style={{ textAlign: "left" }}>
                        Step evaluation function:
                    </Label>
                    <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                        <p>{selectedScenario.stepEvaluationFunction}
                        </p>

                        <small>You can specify JavaScript Math functions inside step evaluation function function.
                            Function must return true to continue to next step.
                            You can override output parameters in step evaluation function.</small>
                    </div>
                </div>

            }

            {selectedTab == "data" && selectedScenario && filter && <>
                <DataTab
                    ref={dataTabRef}
                    selectedScenario={selectedScenario}
                    filter={filter}
                    isExample={props.isExample}
                    selectedFile={selectedFile}
                    loadingService={loadingService}
                    onDataReloaded={() => { setReloadDataTab(false); }} />
            </>

            }


            {selectedTab == "statistics" && !props.isExample &&
                <DispatcherUsageStatistics
                    isLoading={isLoading}
                    ref={usageStatisticsRef}
                    loadingService={loadingService}
                    tag={`ExcelRunner#${selectedScenario.id}`}
                />
            }

        </div>
        {showStopProcessingDialog && <ConfirmationDialog
            title="Stop calculation"
            subText="Are you sure you want to stop the calculation? Your current batch of submitted jobs will be calculated but no new jobs will be submitted."
            isOpened={true}
            confirmLabel="Yes"
            cancelLabel="Cancel"
            onConfirm={() => { stopPocessing(); setShowStopProcessingDialog(false); }}
            onClose={() => { setShowStopProcessingDialog(false) }} />}

        {showWriteResultsDialog && <WriteResultsDialog
            isOpened={true}
            onSuccess={(data) => { WriteResults(data); setShowWriteResultsDialog(false); }}
            onClose={() => { setShowWriteResultsDialog(false); }}
        />}

        <ConfirmationDialog
            title="Start calculation"
            subText="Are you sure you want to start the calculation?"
            isOpened={showStartProcessingDialog}
            onConfirm={() => { onConfirmStartProcessingClick(); }}
            onClose={() => setShowStartProcessingDialog(false)}
        />
        {!!writeResultsRequestId && showWriteResultsStatusModal &&
            <WriteResultsStatusModal
                requestId={writeResultsRequestId}
                isOpened
                onClose={() => { setShowWriteResultsStatusModal(false); setWriteResultsRequestId(undefined); }}
            />}

        {showExportModal &&
            <ExportDoeDataExcel
                scenarioId={selectedScenario?.id}
                defaultName={`${getFileNameWithoutExtension(selectedFilterFile.name)}_${selectedScenario.name}`}
                flowsheetParentDirectoryUniqueId={flowsheetParentDirectoryUniqueId}
                isVisible={showExportModal}
                includeData={includeData}
                includeCovergedData={includeCovergedData}
                onVisibleChanged={(isVisible) => { setShowExportModal(isVisible); }}
            />}


    </div>
}