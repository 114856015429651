import React from "react";
import { HideSidebarSectionInTour, ShowSidebarSectionInTour } from "./tour-guide.shared";

type ConfigurationsTourProps = {

}

export const ConfigurationsTour: React.FC<ConfigurationsTourProps> = (props) => {
    React.useEffect(() => {

        ShowSidebarSectionInTour("configurations_sidebar_link");

        return () => {
            // Close my work menu section
            HideSidebarSectionInTour("configurations_sidebar_link");
        }
    }, [])
    return <div style={{   marginTop: "var(--spacingVerticalMNudge)",   marginBottom: "var(--spacingVerticalMNudge)" }}>
        <p>Set up and personalize your Dashboard. Add and manage contacts to collaborate and share files.</p>
        <p>Define and extend your cluster calculations by setting up your own agents and creating custom applications to personalize your flowsheet calculations. </p>
        <span style={{ fontWeight: "bold" }}>Power tip!</span>
        <br />
        <span>You can create custom groups with selected people from your contacts and share files with them simultaneously. </span>
    </div>
}