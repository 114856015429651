import React, { useEffect } from "react";
import { emptyBreadcrumbs } from "../../filters/filters.component";
import { BreadcrumbItem, FileModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from '../../components/breadcrumbs/breadcrumbs';
import { Toolbar, ToolbarButton, TabList, Tab, Label, Field, Input, Select } from "@fluentui/react-components";
import { Add20Regular, SaveRegular } from "@fluentui/react-icons";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ExperimentRouteParams } from "../flowsheet-experiments.component";
import { GLOBAL_STYLES } from "../../styles";
import { IDropdownOption } from "../../utils/shared.types";
import { ExperimentComponentPostModel, ExperimentPostModel, ExperimentSpecificationPropertyPostModel, ExperimentStreamCompositionPostModel, ExperimentStreamPostModel, ExperimentStreamType, PropertyPackageOption, SeparationMethodOption, SpecificationProperty } from "../../swagger-clients/ai-for-pfd-clients.service";
import { ExperimentMethodTab } from "./tabs/experiment-method-tab.component";
import { getFilesClient } from "../../services/dashboard.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { getFlowsheetsClient } from "../../services/dispatcher.service";
import { ExperimentComponentsTab } from "./tabs/components-tab/experiment-components-tab.component";
import { ComponentModel, OptimizerSettingsModel, SpecificationPropertyModel, StreamCompositionModel, StreamModel } from "./edit-experiment.models";
import { StreamsTab } from "./tabs/streams-tab/streams-tab.component";
import { SpecificationTab } from "./tabs/specifications-tab/specifications-tab.component";
import { OptimizerSettingsTab } from "./tabs/optimizer-settings-tab.component";
import { getExperimentsClient } from "../../services/ai-for-pfd.service";
import { toast } from "react-toastify";
import { IExperimentValidation, IsComponentsValid, IsMethodValid, IsOptimizerSettingsValid, IsSpecificationsValid, IsStreamsValid, IsValidExperimentValidation, ValidateExperiment } from "./edit-experiment.validation";

type EditExperimentProps = {

}


const defaultOptimizerSettings: OptimizerSettingsModel = {
    convergenceTimeout: 360,
    internalTolerance: 0.001,
    externalTolerance: 0.001,
    maximumCost: 10000

};


export const EditExperiment: React.FC<EditExperimentProps> = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const routeParams = useParams<ExperimentRouteParams>();
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedTab, setSelectedTab] = React.useState<string>("method");
    const [isLoading, loadingService] = useLoading();
    const [separationMethod, setSeparationMethod] = React.useState<SeparationMethodOption>();
    const [experimentName, setExperimentName] = React.useState<string>("");
    const [propertyPackage, setPropertyPackage] = React.useState<PropertyPackageOption>();
    const [components, setComponents] = React.useState<ComponentModel[]>([]);
    const [streams, setStreams] = React.useState<StreamModel[]>([]);
    const [specifications, setSpecifications] = React.useState<SpecificationPropertyModel[]>([]);
    const [optimizerSettings, setOptimizerSettings] = React.useState<OptimizerSettingsModel>(defaultOptimizerSettings);
    const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
    const [validationResult, setValidationResult] = React.useState<IExperimentValidation>();

    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        initializePage();
    }, []);

    useEffect(() => {
        if (isFormSubmitted) {
            revalidateExperiment();
        }

    }, [isFormSubmitted, experimentName, propertyPackage, separationMethod, components, streams, specifications, optimizerSettings]);



    const initializePage = async () => {

        const resp = await getFile();

        const defaultComponents = await getComponents(resp.file.uniqueIdentifier, resp.file.currentVersionNumber.toString());
        if (routeParams.experimentId !== undefined) {
            await getExperiment(defaultComponents, +routeParams.experimentId);
        } else {

            const cloneId = searchParams.get("cloneId");
            if (!!cloneId) {
                await getExperiment(defaultComponents, +cloneId);
            } else {
                setComponents(defaultComponents);
            }


        }
    }

    const getExperiment = async (defaultComponents: ComponentModel[], experimentId: number) => {
        const messageId = loadingService.showMessage("Getting experiment...");
        try {
            const client = getExperimentsClient();
            const experimentResp = await client.getExperiment(routeParams.uniquefileId, experimentId);
            console.log("Experiment resp", experimentResp);
            if (!!experimentResp) {
                setExperimentName(experimentResp.name);
                setPropertyPackage(experimentResp.propertyPackage);
                setSeparationMethod(experimentResp.separationMethod);

                let componentsMapped = defaultComponents.map((component) => {
                    const experimentComponent = experimentResp.components.find(x => x.componentCasNr == component.casNumber);
                    if (!!experimentComponent) {
                        return {
                            ...component,
                            isEntrainer: experimentComponent.isEntrainer,
                            isSeparation: experimentComponent.isSeparation,
                            isSolvent: experimentComponent.isSolvent,
                            targetValue: experimentComponent.targetValue

                        } as ComponentModel;
                    }

                    return component;

                });

                experimentResp.components.forEach((component) => {
                    const existingDefaultComponent = defaultComponents.find(x => x.casNumber == component.componentCasNr);
                    if (!existingDefaultComponent) {
                        componentsMapped.push({
                            ...component,
                            displayName: component.name,
                            casNumber: component.componentCasNr,
                            isDefault: false
                        } as ComponentModel);
                    }

                });

                // const componentsMapped = experimentResp.components.map((component) => {
                //     const defaultComponent = defaultComponents.find(x => x.casNumber == component.componentCasNr);

                //     return {
                //         ...component,
                //         displayName: component.name,
                //         casNumber: component.componentCasNr,
                //         isDefault: !!defaultComponent
                //     } as ComponentModel;
                // });
                setComponents(componentsMapped ?? []);

                const mappedExperimentStreams = experimentResp.streams.map((stream) => {
                    const componsitions = stream.compositions.map((composition) => {
                        const component = componentsMapped.find(x => x.casNumber == composition.componentCasNr);
                        return {
                            ...composition,
                            displayName: component.displayName,
                            isEntrainer: component.isEntrainer

                        } as StreamCompositionModel;
                    });
                    return { ...stream, compositions: componsitions } as StreamModel;
                });
                setStreams(mappedExperimentStreams);

                setSpecifications(experimentResp.specifications);

                const mappedOptimizerSettings = {
                    convergenceTimeout: experimentResp.convergenceTimeout,
                    internalTolerance: experimentResp.internalTolerance,
                    externalTolerance: experimentResp.externalTolerance,
                    maximumCost: experimentResp.maximumCost
                } as OptimizerSettingsModel;
                setOptimizerSettings(mappedOptimizerSettings);
            }

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting experiment.");
        } finally {
            loadingService.hideMessage(messageId);
        }

    }

    const getFile = async () => {
        const messageId = loadingService.showMessage("Loading file info...");
        try {
            const client = getFilesClient();
            const resp = await client.getFileLatest(routeParams.uniquefileId!, true);
            if (resp) {
                setSelectedFile(resp.file);
                setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
            }
            return resp;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file information.");
        } finally {
            loadingService.hideMessage(messageId);
        }

    }

    const getComponents = async (fileUniqueId, fileVersionNumber: string) => {

        try {
            const client = getFlowsheetsClient();
            const resp = await client.getFlowsheetComponents(fileUniqueId, fileVersionNumber);
            if (resp) {
                const mappedComponents = resp.map((component, index) => (
                    {
                        displayName: component.displayName,
                        casNumber: component.casNumber,
                        isDefault: true
                    } as ComponentModel));
                setComponents(mappedComponents ?? []);
                return mappedComponents;
            }
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting flowsheet components.");
        }

    }

    const getResetedComponents = () => {
        const resetedComponents = components?.map((component) => ({ displayName: component.displayName, casNumber: component.casNumber, isDefault: component.isDefault } as ComponentModel)) ?? [];
        setComponents(resetedComponents);

        return resetedComponents;
    }

    const onSeparationMethodChange = async (method?: SeparationMethodOption) => {
        setSeparationMethod(method);
        //  need to reset components when separation method changes
        const resetedComponents = getResetedComponents();
        updateStreams(method, resetedComponents);
        addSpecificationProperties(method);
    }
    const addSpecificationProperties = (method: SeparationMethodOption) => {
        switch (method) {
            case SeparationMethodOption.IdealMixtureDistillation:
                setSpecifications([NumberOfStagesFeedToTop, NumberOfStagesBottomToFeed,
                    CondenserPressure, RefluxRation, BottomProductFlowrate, HeaterTemperatures]);
                break;
            case SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation:
                setSpecifications([NumberOfStagesFeedToTop, NumberOfStagesBottomToFeed, CondenserPressure,
                    RefluxRation, BottomProductFlowrate, SwingPressure]);
                break;
            case SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer:
                setSpecifications([NumberOfStagesFeedToTop, NumberOfStagesEntrainerToTop, NumberOfStagesFeedToEntrainer,
                    NumberOfStagesBottomToFeed, CondenserPressure, RefluxRation, BottomProductFlowrate]);
                break;
            case SeparationMethodOption.Absorption:
                setSpecifications([NumberOfStages, GasPressure, SolventTemperature, SolventFlowrate]);
                break;
            default:
                break;

        }
    }

    const updateStreams = (separationMethodLocal: SeparationMethodOption, componentsLocal: ComponentModel[]) => {
        if (!separationMethodLocal) return;

        const feedStreamOld = streams.find(x => x.streamType == ExperimentStreamType.Feed);
        const feedCompositions = componentsLocal.filter(x => !!x.isSeparation)
            .map(composition => {
                const oldComp = feedStreamOld?.compositions?.find(x => x.componentCasNr == composition.casNumber);
                return {
                    componentCasNr: composition.casNumber,
                    displayName: composition.displayName,
                    value: oldComp?.value,
                    isHighBoiling: oldComp?.isHighBoiling,
                    isLowBoiling: oldComp?.isLowBoiling
                } as StreamCompositionModel;
            });


        const feedStream = { ...feedStreamOld, streamType: ExperimentStreamType.Feed, compositions: feedCompositions } as StreamModel;

        const solventStreamOld = streams.find(x => x.streamType == ExperimentStreamType.Solvent);
        const solventCompositions = componentsLocal.filter(x => !!x.isSolvent)
            .map(solvent => {
                return { componentCasNr: solvent.casNumber, displayName: solvent.displayName, value: 1 } as StreamCompositionModel;
            });
        const solventStream = { ...solventStreamOld, streamType: ExperimentStreamType.Solvent, compositions: solventCompositions } as StreamModel;

        const entrainerStreamOld = streams.find(x => x.streamType == ExperimentStreamType.Entrainer);
        const entrainerCompositions = componentsLocal.filter(x => !!x.isEntrainer || !!x.isSeparation)
            .map(component => {
                return {
                    componentCasNr: component.casNumber,
                    displayName: component.displayName,
                    value: 1,
                    isEntrainer: component.isEntrainer
                } as StreamCompositionModel;
            });
        const entrainerStream = { ...entrainerStreamOld, streamType: ExperimentStreamType.Entrainer, compositions: entrainerCompositions } as StreamModel;


        switch (separationMethodLocal) {
            case SeparationMethodOption.IdealMixtureDistillation:
                setStreams([feedStream]);
                break;
            case SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation:
                setStreams([feedStream]);
                return;

            case SeparationMethodOption.Absorption:
                setStreams([feedStream, solventStream]);
                break;
            case SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer:
                setStreams([feedStream, entrainerStream]);

        }
    }

    const onSaveClick = async () => {
        const messageId = loadingService.showMessage("Saving...");
        try {
            setIsFormSubmitted(true);
            setValidationResult(undefined);

            const model = getPostModel();
            const validationResp = ValidateExperiment(model);
            if (!IsValidExperimentValidation(validationResp)) {
                setValidationResult(validationResp);
                return;
            }


            const client = getExperimentsClient();
            const resp = await client.createOrUpdateExperiment(model);
            toast.success("Successfully created experiment.");
            navigate(`/files/${routeParams.uniquefileId}/copilot/details/${resp.experimentId}`);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while saving experiment.");
        } finally {
            loadingService.hideMessage(messageId);
        }
    }

    const revalidateExperiment = () => {
        const model = getPostModel();
        const validationResp = ValidateExperiment(model);
        setValidationResult(validationResp);
    }

    const getPostModel = () => {
        const componentsMapped = components.filter(x => x.isSeparation || x.isSolvent || x.isEntrainer).map((item) => (
            new ExperimentComponentPostModel({
                name: item.displayName,
                componentCasNr: item.casNumber,
                isSeparation: item.isSeparation,
                isSolvent: item.isSolvent,
                isEntrainer: item.isEntrainer,
                targetValue: item.targetValue
            })));
        const specificationsMapped = specifications.map((item) => (
            new ExperimentSpecificationPropertyPostModel({
                specificationProperty: item.specificationProperty,
                defaultValue: item.defaultValue,
                lowerBoundValue: item.lowerBoundValue,
                upperBoundValue: item.upperBoundValue
            })));

        const streamsMapped = streams.map((item) => {

            return new ExperimentStreamPostModel({
                name: item.name,
                streamType: item.streamType,
                pressure: item.pressure,
                temperature: item.temperature,
                molarFlowFlowrate: !!item.molarFlowFlowrate ? item.molarFlowFlowrate : undefined,
                massFlowFlowrate: !!item.massFlowFlowrate ? item.massFlowFlowrate : undefined,
                compositions: item.compositions.map((composition) => (new ExperimentStreamCompositionPostModel({
                    componentCasNr: composition.componentCasNr,
                    value: composition.value,
                    isHighBoiling: composition.isHighBoiling,
                    isLowBoiling: composition.isLowBoiling

                })))
            });
        });


        let model: ExperimentPostModel = new ExperimentPostModel({
            id: routeParams.experimentId !== undefined ? +routeParams.experimentId : undefined,
            name: experimentName,
            propertyPackage: propertyPackage,
            flowsheetUniqueId: selectedFile?.uniqueIdentifier,
            flowsheetVersion: selectedFile?.currentVersionNumber,
            convergenceTimeout: optimizerSettings?.convergenceTimeout,
            externalTolerance: optimizerSettings?.externalTolerance,
            internalTolerance: optimizerSettings?.internalTolerance,
            maximumCost: optimizerSettings?.maximumCost,
            separationMethod: separationMethod,
            components: componentsMapped,
            specifications: specificationsMapped,
            streams: streamsMapped
        });

        return model;
    }

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }

    const isStreamsTabDisabled = () => {
        const selectedComponents = components?.filter(x => x.isSeparation || x.isSolvent);
        if (selectedComponents?.length == 0 ?? true)
            return true;
        if (separationMethod == SeparationMethodOption.IdealMixtureDistillation) {
            if (selectedComponents.length < 2)
                return true;
        }
        if (separationMethod == SeparationMethodOption.Absorption) {
            const separationComponents = selectedComponents.filter(x => x.isSeparation);
            if (separationComponents.length < 2)
                return true;

            const solventComponents = selectedComponents.filter(x => x.isSolvent);
            if (solventComponents.length !== 1)
                return true;
        }

        return false;

    }

    console.log("Experiment validation result", validationResult);

    return <div className="content-wrapper">
        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton appearance='subtle' icon={<SaveRegular />} onClick={onSaveClick}>Save</ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />

            </Toolbar>
        </div>

        <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/copilot`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-copilot`} active={true}>Flowsheet Copilot</Breadcrumb>

            </Breadcrumbs>
        </div>

        <div className="input-form">
            <div className="input-form-item">
                <Label className="input-form-label">
                    Name of experiment:
                </Label>
                <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                    validationMessage={isFormSubmitted && !!validationResult && !validationResult.name.isValid && validationResult.name.validationErrors[0]}
                    validationState={isFormSubmitted && !!validationResult && !validationResult.name.isValid ? "error" : "none"}
                >
                    <Input type="text" value={experimentName} onChange={(ev, data) => { setExperimentName(data.value ?? "") }} />
                </Field>
            </div>

            <div className="input-form-item">
                <Label className="input-form-label">
                    Property package:
                </Label>
                <Field className="input-form-field"
                    validationMessage={isFormSubmitted && !!validationResult && !validationResult.propertyPackage.isValid && validationResult.propertyPackage.validationErrors[0]}
                    validationState={isFormSubmitted && !!validationResult && !validationResult.propertyPackage.isValid ? "error" : "none"}
                >
                    <Select value={propertyPackage} onChange={(ev, data) => { setPropertyPackage(+data.value) }}>
                        <option value={undefined}>Select property package</option>
                        {propertyPackageDropdownOptions.map((option) => {
                            return <option value={option.key?.toString()}>
                                {option.text}
                            </option>
                        })}
                    </Select>
                </Field>
            </div>
        </div>

        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { setSelectedTab(data.value as string); }}>
            <Tab key="method"
                value="method"
                className={isFormSubmitted && !!validationResult && !IsMethodValid(validationResult) ? "tab-with-errors" : undefined}>1. Method</Tab>

            <Tab key="components" value="components"
                disabled={!separationMethod}
                className={isFormSubmitted && !!validationResult && !IsComponentsValid(validationResult) ? "tab-with-errors" : undefined}
            >2. Components</Tab>
            <Tab key="streams" value="streams"
                disabled={!separationMethod || isStreamsTabDisabled()}
                className={isFormSubmitted && !!validationResult && !IsStreamsValid(validationResult) ? "tab-with-errors" : undefined}
            >3. Streams</Tab>
            <Tab key="specifications" value="specifications"
                disabled={!separationMethod}
                className={isFormSubmitted && !!validationResult && !IsSpecificationsValid(validationResult) ? "tab-with-errors" : undefined}>4. Specifications</Tab>
            <Tab key="settings" value="settings"
                className={isFormSubmitted && !!validationResult && !IsOptimizerSettingsValid(validationResult) ? "tab-with-errors" : undefined}>5. Optimizer Settings</Tab>

        </TabList>
        <div className="tab-content" style={{ paddingLeft: "var(--spacingHorizontalL)", paddingRight: "var(--spacingHorizontalL)" }}>
            {selectedTab === "method" &&
                <ExperimentMethodTab
                    selected={separationMethod}
                    onChange={onSeparationMethodChange}
                    isLoading={isLoading}
                    isValid={!isFormSubmitted || !validationResult || IsMethodValid(validationResult)}
                    validationMessage={validationResult?.separationMethod.validationErrors[0]}
                />}
            {selectedTab === "components" &&
                <ExperimentComponentsTab
                    components={components}
                    separationMethod={separationMethod}
                    onChange={(data) => { setComponents(data ?? []); updateStreams(separationMethod, data); }}
                    isFormSubmitted={isFormSubmitted}
                    experimentValidation={validationResult} />}

            {selectedTab === "streams" &&
                <StreamsTab
                    separationMethod={separationMethod}
                    streams={streams}
                    onChange={(data) => { setStreams(data); }}
                    isFormSubmitted={isFormSubmitted}
                    experimentValidation={validationResult} />}

            {selectedTab === "specifications" &&
                <SpecificationTab
                    separationMethod={separationMethod}
                    specifications={specifications}
                    onChange={(data) => setSpecifications(data)}
                    isFormSubmitted={isFormSubmitted}
                    experimentValidation={validationResult} />}

            {selectedTab === "settings" &&
                <OptimizerSettingsTab
                    settings={optimizerSettings}
                    onChange={(data) => setOptimizerSettings(data)}
                    isFormSubmitted={isFormSubmitted}
                    experimentValidation={validationResult} />}

        </div>

    </div>
}

export const propertyPackageDropdownOptions: IDropdownOption[] =
    [
        { key: PropertyPackageOption.MODFAC, text: "MODFAC" },
        { key: PropertyPackageOption.NRTL, text: "NRTL" },
        { key: PropertyPackageOption.PengRobinson, text: "PengRobinson" }
    ];


const NumberOfStagesFeedToTop = { specificationProperty: SpecificationProperty.NumberOfStagesFeedToTop } as SpecificationPropertyModel;
const NumberOfStagesBottomToFeed = { specificationProperty: SpecificationProperty.NumberOfStagesBottomToFeed } as SpecificationPropertyModel;
const NumberOfStagesEntrainerToTop = { specificationProperty: SpecificationProperty.NumberOfStagesEntrainerToTop } as SpecificationPropertyModel;
const NumberOfStagesFeedToEntrainer = { specificationProperty: SpecificationProperty.NumberOfStagesFeedToEntrainer } as SpecificationPropertyModel;
const CondenserPressure = { specificationProperty: SpecificationProperty.CondenserPressure } as SpecificationPropertyModel;
const RefluxRation = { specificationProperty: SpecificationProperty.RefluxRation } as SpecificationPropertyModel;
const BottomProductFlowrate = { specificationProperty: SpecificationProperty.BottomProductFlowrate } as SpecificationPropertyModel;
const HeaterTemperatures = { specificationProperty: SpecificationProperty.HeaterTemperatures } as SpecificationPropertyModel;
const NumberOfStages = { specificationProperty: SpecificationProperty.NumberOfStages } as SpecificationPropertyModel;
const GasPressure = { specificationProperty: SpecificationProperty.GasPressure } as SpecificationPropertyModel;
const SolventTemperature = { specificationProperty: SpecificationProperty.SolventTemperature } as SpecificationPropertyModel;
const SolventFlowrate = { specificationProperty: SpecificationProperty.SolventFlowrate } as SpecificationPropertyModel;
const SwingPressure = { specificationProperty: SpecificationProperty.SwingPressure } as SpecificationPropertyModel;

