import isVarName from 'is-var-name';
import { InputFilterParameterResultModel, OutputFilterParameterResultModel, FilterParameterType, FilterResultModel } from '../../swagger-clients/s365-dashboard-v2-api-clients.service';
import { IFilterValidation, IInputFilterParameterValidation, IOutputFilterParameterValidation, IValidationField, IWebFormValidation } from './edit-filter.types';

export const FilterIsValid = (validation: IFilterValidation): boolean => {

    const nameisValid = validation.filterName.isValid;
    const inputParamsIsValid = InputParametersAreValid(validation.inputParameters);
    const outputParamsIsValid = OutputParametersAreValid(validation.outputParameters);
    const webFormIsValid = WebFormIsValid(validation.webForm);

    return nameisValid && inputParamsIsValid && outputParamsIsValid && webFormIsValid;

}



export const OutputParametersAreValid = (outputParameters: IOutputFilterParameterValidation[]) => {
    //You should have at least 1  parameter
    if (outputParameters.length == 0)
        return false;

    var invalidParameters = outputParameters.filter((param) =>
        !(param && param.name?.isValid && param.alias!.isValid && (!(param as any).fixedValue || (param as any).fixedValue.isValid) && (param as any).formula.isValid));

    return invalidParameters.length == 0;
}

export const InputParametersAreValid = (inputParams: IInputFilterParameterValidation[]) => {
    //You should have at least 1  parameter
    if (inputParams.length == 0)
        return false;

    var invalidParameters = inputParams.filter((param) =>
        !(param && param.name?.isValid && param.alias!.isValid && (!param.fixedValue || param.fixedValue.isValid) && param.formula.isValid));

    return invalidParameters.length == 0;
}
export const WebFormParametersAreValid = (inputParams: IInputFilterParameterValidation[]) => {

    var invalidParameters = inputParams.filter((param) =>
        !param.webFormName.isValid || !param.webFormDefaultValue.isValid);

    return invalidParameters.length == 0;
}

export const WebFormIsValid = (webFormValidation: IWebFormValidation): boolean => {
    const parametersAreValid = WebFormParametersAreValid(webFormValidation.inputParameters);
    return parametersAreValid && webFormValidation.outputDescription.isValid && webFormValidation.applicationId.isValid;
}

export const ValidateFilterFields = (filter: FilterResultModel): IFilterValidation => {

    let filterValidation = {} as IFilterValidation;
    filterValidation.filterName = ValidateFilterName(filter.name ?? "");
    filterValidation.inputParameters = ValidateInputParameters(filter.inputFilterParameters ?? []);
    filterValidation.outputParameters = ValidateOutputParameters(filter.inputFilterParameters ?? [], filter.outputFilterParameters ?? []);
    filterValidation.webForm = ValidateWebForm(filter);
    return filterValidation;

}

export const ValidateWebForm = (filter: FilterResultModel) => {
    let webFormValidation = {
        outputDescription: { isValid: true, validationErrors: [] },
        inputParameters: [],
        applicationId: { isValid: true, validationErrors: [] }
    } as IWebFormValidation;

    if (!filter.enableWebForm)
        return webFormValidation;

    webFormValidation.outputDescription = ValidateRequiredField("Output", filter.webFormOutputDescription);
    webFormValidation.applicationId = ValidateRequiredField("Application", filter.webFormApplicationId?.toString());
    webFormValidation.inputParameters = ValidateWebFormParameters(filter.inputFilterParameters ?? [], filter.enableWebForm);

    return webFormValidation;

}

const ValidateFilterName = (filterName: string): IValidationField => {

    let nameValidation: IValidationField = { isValid: true, validationErrors: [] };

    if (!filterName || filterName.length == 0) {
        nameValidation.isValid = false;
        nameValidation.validationErrors.push("Name is required!");
    }

    return nameValidation;
}
export const ValidateWebFormParameters = (inputParams: InputFilterParameterResultModel[], webFormEnabled: boolean) => {
    var inputFieldsValidations: IInputFilterParameterValidation[] = [];
    if (!webFormEnabled)
        return inputFieldsValidations;


    inputParams.forEach(inputParam => {

        var inputParamValidation: IInputFilterParameterValidation = {};
        inputParamValidation.webFormName = { isValid: true, validationErrors: [] } as IValidationField;
        inputParamValidation.webFormDefaultValue = { isValid: true, validationErrors: [] } as IValidationField;

        if (webFormEnabled) {
            inputParamValidation.webFormName = ValidateRequiredField("Name", inputParam.webFormName);
            if (inputParam.webFormShowDefaultValue)
                inputParamValidation.webFormDefaultValue = ValidateRequiredField("Default value", inputParam.webFormDefaultValue?.toString() ?? "");

        }
        inputFieldsValidations.push(inputParamValidation);

    });

    return inputFieldsValidations;
}

export const ValidateInputParameters = (inputParams: InputFilterParameterResultModel[]): IInputFilterParameterValidation[] => {

    var inputFieldsValidations: IInputFilterParameterValidation[] = [];

    const allowedVariableNames = inputParams?.map(x => x.alias) ?? [];
    inputParams.forEach(inputParam => {

        var inputParamValidation: IInputFilterParameterValidation = {};
        inputParamValidation.name = ValidateParameterName(inputParam.name ?? "");
        inputParamValidation.name = ParameterValueIsUnique(inputParams, inputParam, "name", inputParamValidation.name);
        inputParamValidation.alias = { isValid: true, validationErrors: [] } as IValidationField;
        inputParamValidation.alias = ParameterValueIsUnique(inputParams, inputParam, "alias", inputParamValidation.alias);
        inputParamValidation.alias = AliasIsNotSameAsParameterName(inputParams, inputParam, inputParamValidation.alias);
        inputParamValidation.alias = IsValidVariableName(inputParam.alias ?? "");

        if (inputParam.parameterType == FilterParameterType.ExpectedInput) {
            inputParamValidation.fixedValue = { isValid: true, validationErrors: [] } as IValidationField;
            inputParamValidation.formula = { isValid: true, validationErrors: [] } as IValidationField;
        }
        else if (inputParam.parameterType == FilterParameterType.FixedValue) {
            inputParamValidation.fixedValue = ValidateFixedValue(inputParam.fixedValue?.toString() ?? "");
            inputParamValidation.formula = { isValid: true, validationErrors: [] } as IValidationField;
        }
        else {

            inputParamValidation.formula = ValidateFormulaValue(inputParam.formula ?? "", allowedVariableNames, "Formula is required!");
            inputParamValidation.fixedValue = { isValid: true, validationErrors: [] } as IValidationField;
        }

        console.log("Input parameter validation:", inputParamValidation);
        inputFieldsValidations.push(inputParamValidation);

    });

    return inputFieldsValidations;

}

export const ValidateOutputParameters = (inputParams: InputFilterParameterResultModel[], outputParams: OutputFilterParameterResultModel[]): IOutputFilterParameterValidation[] => {
    let outputFieldsValidations: IOutputFilterParameterValidation[] = [];
    let allowedVariableNames = inputParams?.map(x => x.alias) ?? [];
    const outParameterNames = outputParams?.map(x => x.alias) ?? [];
    if (outParameterNames?.length > 0 ?? false) {
        allowedVariableNames.push(...outParameterNames);
    }

    outputParams.forEach(outputParam => {

        let outputParamValidation: IOutputFilterParameterValidation = {};

        if (outputParam.parameterType == FilterParameterType.ExpectedOutput) {
            outputParamValidation.name = ValidateParameterName(outputParam.name ?? "");
            outputParamValidation.name = ParameterValueIsUnique(outputParams, outputParam, "name", outputParamValidation.name);
            outputParamValidation.formula = { isValid: true, validationErrors: [] } as IValidationField;
            outputParamValidation.alias = { isValid: true, validationErrors: [] } as IValidationField;
            outputParamValidation.alias = ParameterValueIsUnique(outputParams, outputParam, "alias", outputParamValidation.alias);
            outputParamValidation.alias = AliasIsNotSameAsParameterName(outputParams, outputParam, outputParamValidation.alias);
            outputParamValidation.alias = IsValidVariableName(outputParam.alias, outputParamValidation.alias);

        } else {
            outputParamValidation.name = { isValid: true, validationErrors: [] } as IValidationField;
            outputParamValidation.formula = ValidateFormulaValue(outputParam.formula ?? "", allowedVariableNames, "Formula is required!");
            outputParamValidation.alias = ValidateParameterValue(outputParam.formula ?? "", "Alias is required!");
            outputParamValidation.alias = ParameterValueIsUnique(outputParams, outputParam, "alias", outputParamValidation.alias);
            outputParamValidation.alias = AliasIsNotSameAsParameterName(outputParams, outputParam, outputParamValidation.alias);
            outputParamValidation.alias = IsValidVariableName(outputParam.alias ?? "", outputParamValidation.alias);
        }

        outputFieldsValidations.push(outputParamValidation);

    });

    return outputFieldsValidations;
}

export const ParameterValueIsUnique = (
    parameters: (InputFilterParameterResultModel[] | OutputFilterParameterResultModel[]),
    parameter: (InputFilterParameterResultModel | OutputFilterParameterResultModel),
    field: string, previousValidation: IValidationField): IValidationField => {

    if (!parameter || !(parameter as any)[field] || (parameter as any)[field] == "")
        return previousValidation;

    const existingParameters = parameters.filter((x) => (x as any)[field] == (parameter as any)[field]);
    if (existingParameters.length > 1) {
        if (!previousValidation.isValid) {
            let resultValidation = previousValidation;
            resultValidation.validationErrors.push(`Field ${field} duplicated!`);
            return resultValidation;
        } else {
            return {
                isValid: false,
                validationErrors: [`Field ${field} duplicated!`]
            } as IValidationField;
        }

    } else {
        return previousValidation;
    }

}

export const AliasIsNotSameAsParameterName = (parameters: (InputFilterParameterResultModel[] | OutputFilterParameterResultModel[]),
    parameter: (InputFilterParameterResultModel | OutputFilterParameterResultModel),
    previousValidation: IValidationField): IValidationField => {

    if (!parameter || !parameter.alias || parameter.alias == "")
        return previousValidation;

    const existingParameter = parameters.filter((param) => param.name == parameter.alias);

    if (existingParameter && existingParameter.length > 0) {

        if (!previousValidation.isValid) {
            let resultValidation = previousValidation;
            resultValidation.validationErrors.push(`Alias can't be parameter name!`);
            return resultValidation;
        } else {
            return {
                isValid: false,
                validationErrors: [`Alias can't be parameter name!`]
            } as IValidationField;
        }

    } else {
        return previousValidation;
    }
}

export const IsValidVariableName = (value: string, previousValidation?: IValidationField): IValidationField => {

    if (isVarName(value)) {
        if (previousValidation) {
            return previousValidation;
        }
        return { isValid: true, validationErrors: [] } as IValidationField;
    } else {
        if (previousValidation) {
            previousValidation.isValid = false;
            previousValidation.validationErrors.push("Invalid alias!");
            return previousValidation;

        }

        return {
            isValid: false,
            validationErrors: [`Invalid alias!`]
        } as IValidationField;
    }
}

export const ValidateRequiredField = (fieldName: string, value?: string) => {
    let nameValidation: IValidationField = { isValid: true, validationErrors: [] };

    if (!value || value.length == 0) {
        nameValidation.isValid = false;
        nameValidation.validationErrors.push(`${fieldName} is required!`);
    }

    return nameValidation;
}

export const ValidateParameterName = (paramName: string): IValidationField => {

    return ValidateRequiredField("Parameter name", paramName);

}

export const ValidateFixedValue = (paramValue: string): IValidationField => {
    let fieldValidation: IValidationField = { isValid: true, validationErrors: [] };

    if (!paramValue || paramValue.length == 0) {
        fieldValidation.isValid = false;
        fieldValidation.validationErrors.push("Parameter Value is required!");
    }
    if (!(/^\d*\.?\d*$/.test(paramValue))) {
        fieldValidation.isValid = false;
        fieldValidation.validationErrors.push("Value is not a number!");
    }

    return fieldValidation;
}

export const ValidateFormulaValue = (value: string, allowedVariables: string[],
    requiredMessage: string = "Parameter Value is required!"): IValidationField => {
    const notNullValidation = ValidateParameterValue(value, requiredMessage);
    if (!notNullValidation.isValid)
        return notNullValidation;

    let fieldValidation: IValidationField = { isValid: true, validationErrors: [] };

    if (value.indexOf("=") > -1) {
        fieldValidation.isValid = false;
        fieldValidation.validationErrors.push(`Define the formula without "=".`);
        return fieldValidation;
    }

    const isValidFormula = isValidMathExpression(value, allowedVariables);
    console.log("isValidMathExpression", isValidFormula, value)
    if (!isValidFormula.isValid) {
        fieldValidation.isValid = false;
        fieldValidation.validationErrors.push(`'${isValidFormula.notAllowedWord}' is not valid parameter name.`);
    }

    // const isDuplicateVar = isDuplicateVariable(value, allowedVariables);
    // if (isDuplicateVar) {
    //     fieldValidation.isValid = false;
    //     fieldValidation.validationErrors.push("Variable already used as parameter.");
    // }

    return fieldValidation;
}


export const ValidateParameterValue = (paramValue: string, message: string = "Parameter Value is required!"): IValidationField => {

    let fieldValidation: IValidationField = { isValid: true, validationErrors: [] };

    if (!paramValue || paramValue.length == 0) {
        fieldValidation.isValid = false;
        fieldValidation.validationErrors.push(message);
    }

    return fieldValidation;
}

export const isDuplicateVariable = (expression: string, allVariables: string[]) => {

    return allVariables?.find(x => x == expression) !== undefined ?? false;
}

export const isValidMathExpression = (expression, allowedVariables): { isValid: boolean, notAllowedWord?: string } => {
    //  console.log("isValidMathExpression", expression, allowedVariables);
    // Split the expression into words (based on spaces and operators)
    const words = expression.split(/\s+|[-+*/%()]/);
    // Check if each word is a valid variable or operator
    for (const word of words) {
        if (word.trim() !== '') {
            if (allowedVariables.includes(word) || !isNaN(word)) {
                // Valid variable
                continue;
            } else if (/^[+\-*/%()]$/.test(word)) {
                // Valid operator
                continue;
            } else {
                // Invalid word
                return { isValid: false, notAllowedWord: word };
            }
        }
    }

    return { isValid: true };
}