import { createTableColumn, SelectionItemId, Table, TableCell, TableColumnDefinition, TableColumnSizingOptions, TableHeader, TableHeaderCell, TableRow, TableRowId, TableSelectionCell, useTableColumnSizing_unstable, useTableFeatures, useTableSelection } from "@fluentui/react-components";
import React from "react";

import { useLoading } from "../../../utils/loading-indicator.component";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { JobProgressCount } from "../../../components/job-progress-count/job-progress-count.component";
import ShowLocalTime from "../../../components/show-local-time/show-local-time.component";
import { GetAnalysisStatus, GetProcessingStatus } from "../../../excel-runner/utilities/scenario.helpers";
import { getScenariosClient } from "../../../services/excel-runner.service";
import { FileTableItem } from "../../models/file-table.models";
import { getUserToken } from "../../../services/dashboard.service";
import { _copyAndSort } from "../../../utils/helpers/array.helpers";
import { processServerError } from "../../../utils/helpers/error.helper";
import { ApplicationSelectionModel } from "./copy-file-objects-dialog.component";
import { getSensitivityStudiesClient } from "../../../services/sensitivity-studies.service";
import { SensitivityStudyQuery, SensitivityStudyResultModel, StudyType } from "../../../swagger-clients/sensitivity-studies-api-clients.service";
import { StudyStepStatusComponent } from "../../../sensitivity-studies/study-step-status/study-step-status.component";
import { GetStudyStepText } from "../../../sensitivity-studies/utilities/study.utilities";

type SelectMssStudiesTableProps = {
    file: FileTableItem;
    //can use this to show number of items that are loaded
    onDataLoaded: (count: number) => void;
    onSelectedItemsChanged: (selectedItems: ApplicationSelectionModel[]) => void;
    selectedItems: number[];
    isOptimumTest: boolean;
}



export const SelectMssStudiesTable: React.FC<SelectMssStudiesTableProps> = (props) => {
    const [items, setItems] = React.useState<SensitivityStudyResultModel[]>([]);
    const [isLoading, loadingService] = useLoading();
    const [columns, setColumns] = React.useState<TableColumnDefinition<SensitivityStudyResultModel>[]>([]);
    const [selectedRows, setSelectedRows] = React.useState(
        () => new Set<TableRowId>(props.selectedItems)
    );
    React.useEffect(() => { getStudies(); }, []);


    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        checkbox: {
            minWidth: 30,
            idealWidth: 30,
            autoFitColumns: false
        },
        id: {
            minWidth: 70,
            defaultWidth:70,
            idealWidth: 70,
            autoFitColumns: false
        },
        name: {
            minWidth: 100,
            defaultWidth: 8910,
        },
        currentStep: {
            minWidth: 100,
            defaultWidth: 200
        },
        currentStepStatus: {
            minWidth: 100,
            defaultWidth: 200
        },
        jobs: {
            minWidth: 100,
            defaultWidth: 200

        },
        created: {
            minWidth: 90,
            defaultWidth: 200
        }
    });

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "currentStep": return true;
            case "currentStepStatus": return true;
            case "jobs": return true;
            case "created": return true;

            default: return false;
        }
    }



    const { getRows, columnSizing_unstable, tableRef, selection: {
        toggleRow,
        selectRow,
        clearRows,
        toggleAllRows
    }, } = useTableFeatures<SensitivityStudyResultModel>(
        {
            columns,
            items,
            getRowId: (item: SensitivityStudyResultModel) => (+item.id)

        },
        [useTableColumnSizing_unstable({ columnSizingOptions }),
        useTableSelection({
            selectionMode: "multiselect",
            selectedItems: selectedRows,
            onSelectionChange: (e, data) => {
                setSelectedRows(new Set<TableRowId>(data.selectedItems));
                const selectedIds = [...data.selectedItems].map(x => +x);
                const mappedSelections = selectedIds?.map(id => {
                    const study = items.find(x => x.id == id);
                    return { id: +study.id, filterId: study.filterId , filterVersionId: study.filterVersionId } as ApplicationSelectionModel;
                });
                props.onSelectedItemsChanged(mappedSelections);
            },
        })
        ]
    );
    const isRowSelected = (rowId: number) => {
        return selectedRows.has(rowId);
    }

    const rows = getRows((row) => {
        const selected = isRowSelected(+row.item.id);
        return {
            ...row,
            rowId: +row.item.id,
            selected
        };
    });
    React.useEffect(() => {
        const columnsLocal = getAllColumns();
        setColumns(columnsLocal);
    }, [selectedRows, items]);

    const getAllColumns = () => {

        const allRowsSelected = items.length > 0 && [...selectedRows].length > 0 && [...selectedRows].length == items.length;
        const someRowsSelected = items.length > 0 && [...selectedRows].length > 0 && !allRowsSelected;

        let columnsLocal: TableColumnDefinition<SensitivityStudyResultModel>[] = [
            createTableColumn<SensitivityStudyResultModel>({
                columnId: "checkbox",
                renderHeaderCell: () => {
                    return <TableSelectionCell
                        checked={
                            allRowsSelected ? true : someRowsSelected ? "mixed" : false
                        }
                        onClick={(ev) => onToggleAllRows(ev)}
                        checkboxIndicator={{ "aria-label": "Select all rows " }}
                    />
                },
                renderCell: (item) => {
                    const selected = isRowSelected(+item.id);

                    return <TableSelectionCell                       
                        checked={selected}
                        onClick={(ev) => { ev.stopPropagation(); toggleRow(ev, +item.id); }}
                        {...columnSizing_unstable.getTableCellProps("checkbox")}
                    />
                }
            }),
            createTableColumn<SensitivityStudyResultModel>({
                columnId: "id",
                renderHeaderCell: () => <>ID</>,
                renderCell: (item: SensitivityStudyResultModel) => {
                    return <span>{item.id}</span>;

                }
            }),
            createTableColumn<SensitivityStudyResultModel>({
                columnId: "name",
                renderHeaderCell: () => <>Name</>,
                renderCell: (item: SensitivityStudyResultModel) => {
                    return <span>{item.name}</span>;

                }
            }),
            createTableColumn<SensitivityStudyResultModel>({
                columnId: "currentStep",
                renderHeaderCell: () => <>Current step</>,
                renderCell: (item: SensitivityStudyResultModel) => {
                    return GetStudyStepText(item.currentStep);
                }
            }),
            createTableColumn<SensitivityStudyResultModel>({
                columnId: "currentStepStatus",
                renderHeaderCell: () => <>Current step status</>,
                renderCell: (item: SensitivityStudyResultModel) => {

                    return <StudyStepStatusComponent status={item.currentStepStatus} />;

                }
            }),
            createTableColumn<SensitivityStudyResultModel>({
                columnId: "jobs",
                renderHeaderCell: () => <>Jobs</>,
                renderCell: (item: SensitivityStudyResultModel) => {

                    return <JobProgressCount totalJobs={item.totalJobs} calculatedJobs={item.calculatedRows} failedJobs={item.failedRows} />;

                }
            }),
            createTableColumn<SensitivityStudyResultModel>({
                columnId: "created",
                renderHeaderCell: () => <>Created</>,
                renderCell: (item: SensitivityStudyResultModel) => {

                    return <ShowLocalTime date={item.createdAt} />;
                }
            })
        ];

        return columnsLocal;
    }



    const getStudies = async () => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getSensitivityStudiesClient();
                const query = {
                    flowsheetDriveId: props.file.uniqueIdentifier,
                    flowsheetsListId: "s365v2",
                    siteId: "s365v2",
                    studyType: props.isOptimumTest ? StudyType.OptimumTest : StudyType.SensitivityStudy
                } as SensitivityStudyQuery;

                const studiesResp = await client.getSensitivityStudies(query);

                let studiesSorted = _copyAndSort<SensitivityStudyResultModel>(studiesResp, "createdAt", true);
                studiesSorted = studiesSorted.filter(x => !x.isDeleted);

                setItems(studiesSorted ?? []);
                props.onDataLoaded(studiesSorted?.length ?? 0);
            } catch (error) {
                processServerError(error, undefined, `An error occurred while getting ${props.isOptimumTest ? "optimum tests" : "studies"}.`);
            } finally {
                hideMessage();
            }
        });
    }

    const onToggleAllRows = (ev: any) => {
        toggleAllRows(ev);
    }

    return <div>
        <div className='studies-table__wrapper'>
            <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
                <TableHeader>
                    <TableRow>
                        {columns.map((column) => (
                            <TableHeaderCell
                                onDragStart={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                                key={column.columnId}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}
                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={7} loadingMessage="Loading..."
                    itemCount={items ? items.length : 0}
                    noItemsMessage="No items found.">
                    {items && items.length > 0 && items.map((item) => {
                        return <TableRow
                            key={`study-${item.id}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => { toggleRow(null, +item.id); }}>
                            {columns.map((column) => (
                                <TableCell
                                    className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    })}
                </TableBodyWithLoading>
            </Table> </div>
    </div>
}

