import React from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { getTagsClient } from "../../services/dashboard.service";
import { DirectoryTagPostModel, FileSystemEntityType, FileTagPostModel, TagResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { ActionMeta, CSSObjectWithLabel, GroupBase, MultiValue, StylesConfig } from "react-select"
type TagPickerProps = {
    fileType: FileSystemEntityType,
    id: number,
    fileUniqueIdentifier: string,
    defaultFileOptions?: TagOption[],
    hasBoarder?: boolean,
    hasRemove?: boolean,
    placeholder?: string,
    tags?: TagResponseModel[],
    disabled?:boolean,
    styles?: StylesConfig<TagOption, true, GroupBase<TagOption>>,
    onFocusOut?: () => void,
    onTagAdded?: () => void,
    onTagRemoved?: () => void
}

export type TagOption = {
    value: string | number,
    label: string

}

export const TagPicker: React.FC<TagPickerProps> = (props) => {
    const [currentValue, setCurrentValue] = React.useState<string>("");
    const [selectedValues, setSelectedValues] = React.useState<TagOption[]>();

    React.useEffect(() => {
        const tags = props.tags?.map(tag => ({ value: tag.id, label: tag.name } as TagOption)) ?? [];
        setSelectedValues(tags);
    }, [props.tags]);

    const getSuggestions = async (value: string): Promise<TagOption[]> => {

        try {
            let resp: TagResponseModel[] = [];
            const client = getTagsClient();
            if (props.fileType == FileSystemEntityType.File) {
                resp = await client.getFileTagSuggestions(value, props.id);

            } else {
                resp = await client.getDirectoryTagSuggestions(value, props.id);
            }

            const tagOptions = resp.map(x => ({ label: x.name, value: x.id } as TagOption));
            return tagOptions;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting tag name suggestions.");
        }
    }

    const addSelectedSuggestion = async (tag: TagOption) => {
        try {
            const client = getTagsClient();
            if (props.fileType == FileSystemEntityType.File) {
                let model = new FileTagPostModel();
                model.fileId = props.id;
                model.tagId = +tag.value;
                await client.addFileTag(model);
            } else {
                let model = new DirectoryTagPostModel();
                model.directoryId = props.id;
                model.tagId = +tag.value;
                await client.addDirectoryTag(model);
            }
            if (props.onTagAdded)
                props.onTagAdded();
        } catch (error) {
            processServerError(error, undefined, "An error occurred while adding tag.");
        }
    }

    const addTagByName = async (tagName: string) => {

        try {
            const client = getTagsClient();
            if (props.fileType == FileSystemEntityType.File) {
                let model = new FileTagPostModel();
                model.tagName = tagName;
                model.fileId = props.id;
                await client.addFileTag(model);
            } else {
                let model = new DirectoryTagPostModel();
                model.directoryId = props.id;
                model.tagName = tagName;
                await client.addDirectoryTag(model);
            }
            if (props.onTagAdded)
                props.onTagAdded();
        } catch (error) {
            processServerError(error, undefined, "An error occurred while adding tag.");
        }
    }
    const onDeleteTag = async (tagId: number) => {
        try {
            const client = getTagsClient();

            await client.deleteTag(props.fileUniqueIdentifier, tagId, props.fileType == FileSystemEntityType.Directory);

            if (props.onTagRemoved)
                props.onTagRemoved();

        } catch (error) {
            processServerError(error, undefined, "An error occurred while deleting tag.");
        }

    }

    const onTagsChanged = async (selectedOptions: MultiValue<TagOption>, actionMeta: ActionMeta<TagOption>) => {
        console.log("onTagsChanged", selectedOptions, actionMeta);

        // on option selected
        if (actionMeta.action == "select-option") {
            const selectedOption = selectedOptions[selectedOptions.length - 1];
            await addSelectedSuggestion(selectedOption);
        }

        // on tag removed

        if (actionMeta.action == "remove-value") {

            onDeleteTag(+actionMeta.removedValue.value);

        }

    }
    const getMultiValueLabelStyles = (provided: CSSObjectWithLabel) => {
        let styles = {
            lineHeight: "normal"
        } as CSSObjectWithLabel;
        return { ...provided, ...styles };
    }

    const getControlStyles = (provided: CSSObjectWithLabel) => {
        let styles = {
            border: !props.hasBoarder ? "none !important" : provided.border,
            boxShadow: "none",
            background: "inherit"
        } as CSSObjectWithLabel;
        return { ...provided, ...styles };
    }

    return <AsyncCreatableSelect
        styles={{
            multiValueLabel: getMultiValueLabelStyles,
            control: getControlStyles,
            indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
            indicatorsContainer: (provided) => ({ ...provided, display: "none" }),
            multiValueRemove: (provided) => ({ ...provided, display: props.hasRemove ? "flex" : "none" }),
            ...props.styles

        }}
        cacheOptions
        value={selectedValues}
        defaultOptions={props.defaultFileOptions}
        isMulti
        isClearable={false}
        isDisabled={props.disabled}
        placeholder={props.placeholder ? props.placeholder : ""}
        loadOptions={getSuggestions}
        onInputChange={(newValue: string) => { setCurrentValue(newValue); }}
        onBlur={() => { if (props.onFocusOut) props.onFocusOut(); }}
        onCreateOption={addTagByName}
        onChange={onTagsChanged}


    />

}