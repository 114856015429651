import { Table, TableHeader, TableRow, TableHeaderCell, TableCell } from "@fluentui/react-components";
import React from "react";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { CalculationJobResultModel, ExperimentResponseModel } from "../../swagger-clients/ai-for-pfd-clients.service";
import { ResultOutputParameter } from "../components/result-output-parameter.component";


type CalculationResultTabProps = {
    calculationJob: CalculationJobResultModel;
    experiment: ExperimentResponseModel;
}

type CalculationResult = {
    // cas NR or name: capex,opex,energy
    componentCasNumber: string;
    value: number;
}

export const CalculationResultTab: React.FC<CalculationResultTabProps> = (props) => {
    const { experiment, calculationJob } = props;
    const [results, setResults] = React.useState<CalculationResult[]>([]);

    React.useEffect(() => {
        let resultsLocal: CalculationResult[] = [];
        resultsLocal.push(
            { componentCasNumber: "capex", value: calculationJob.capex },
            { componentCasNumber: "opex", value: calculationJob.opex },
            { componentCasNumber: "energy", value: calculationJob.energy }
        );
        calculationJob.results.map((item) => {
            resultsLocal.push({ componentCasNumber: item.caS_Number, value: item.value });
        });
        setResults(resultsLocal);
    }, []);

    return <div>
        <Table>
            <TableHeader>
                <TableRow key="table-header">
                    <TableHeaderCell key="parameter" style={{ width: "400px" }} className='table__cell--bold'>Parameter</TableHeaderCell>

                    <TableHeaderCell key="value" className='table__cell--bold'>Value</TableHeaderCell>

                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={false}
                columnCount={2} loadingMessage="Loading..."
                itemCount={results?.length ?? 0}
                noItemsMessage="No items found.">
                {!!results &&
                    results.map((item, index) => {
                        return <TableRow key={`output-param-${index}`}>
                            <TableCell>
                                <ResultOutputParameter componentCasNumber={item.componentCasNumber} separationMethod={experiment.separationMethod} />
                            </TableCell>
                            <TableCell>{+Number(item.value).toFixed(3)}</TableCell>

                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
    </div>
}