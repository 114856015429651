import { Label, Field, Input, Checkbox, dividerClassNames, InfoLabel, LabelProps } from "@fluentui/react-components";
import React from "react";
import { GLOBAL_STYLES } from "../../../../styles";
import { StreamCompositionModel, StreamModel } from "../../edit-experiment.models";
import { ICompositionValidation, IStreamValidation } from "../../edit-experiment.validation";
import { SeparationMethodOption } from "../../../../swagger-clients/ai-for-pfd-clients.service";
import { BoilingComponentSelector, BoilingComponentSelectorOption } from "./boiling-component-selector.component";

type StreamComponentProps = {
    stream: StreamModel;
    onChange(data: StreamModel): void;
    isDetails?: boolean;
    isFormSubmitted?: boolean;
    streamValidation?: IStreamValidation;
    disableEditComposition?: boolean;
    isEntrainer?: boolean;
    separationMethod: SeparationMethodOption;
}

export const StreamComponent: React.FC<StreamComponentProps> = (props) => {
    const { stream, isFormSubmitted, streamValidation } = props;
    const onCompositionChange = (indexToUpdate: number, value: string) => {
        const compositionsUpdated = stream.compositions.map((x, i) => {
            if (i == indexToUpdate) { return { ...x, value: value as any } as StreamCompositionModel; }
            return x;
        });
        props.onChange({ ...stream, compositions: compositionsUpdated });
    }

    const onCompositionBoilingCheckChanged = (indexToUpdate: number, isChecked: boolean, propertyName: "isLowBoiling" | "isHighBoiling") => {

        const compositionsUpdated = stream.compositions.map((x, i) => {
            if (i == indexToUpdate) { return { ...x, isLowBoiling: false, isHighBoiling: false, [propertyName]: isChecked } as StreamCompositionModel; }
            if (isChecked) {

                return { ...x, [propertyName]: false } as StreamCompositionModel;
            }
            return x;
        });
        props.onChange({ ...stream, compositions: compositionsUpdated });
    }

    const onBoilingComponentChange = (propertyName: "isLowBoiling" | "isHighBoiling", componentCasNumber?: string) => {

        const compositionsUpdated = stream.compositions.map((x, i) => {
            if (!!componentCasNumber && x.componentCasNr == componentCasNumber) {
                return { ...x, [propertyName]: true } as StreamCompositionModel;
            }

            return { ...x, [propertyName]: false } as StreamCompositionModel;


        });
        props.onChange({ ...stream, compositions: compositionsUpdated });

    }


    const boilingComponentOptions = props.isEntrainer ?
        stream.compositions.filter(x => !x.isEntrainer)
            .map((item) => ({ key: item.componentCasNr, text: item.displayName } as BoilingComponentSelectorOption))
        : [];

    const lowBoilingComponent = stream.compositions?.find(x => !!x.isLowBoiling);

    const highBoilingComponent = stream.compositions?.find(x => !!x.isHighBoiling);
    const constraintsValidation = props.streamValidation?.compositions[0];



    return <div className="input-form" style={{ paddingLeft: "10px" }}>
        <div className="input-form-item" style={{ marginBottom: props.isDetails ? 0 : undefined }}>
            <Label className="input-form-label input-form-label-sm" style={{ textAlign: "left" }}>
                Name:
            </Label>
            {props.isDetails && <span>{stream?.name}</span>}
            {!props.isDetails && <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                validationMessage={isFormSubmitted && !!streamValidation && !streamValidation.name.isValid && streamValidation.name.validationErrors[0]}
                validationState={isFormSubmitted && !!streamValidation && !streamValidation.name.isValid ? "error" : "none"}
            >
                <Input value={stream?.name ?? ""} onChange={(ev, data) => { props.onChange({ ...stream, name: data.value }) }} readOnly={props.isDetails} />
            </Field>}
        </div>

        <div className="input-form-item" style={{ marginBottom: props.isDetails ? 0 : undefined }}>
            <Label className="input-form-label input-form-label-sm" style={{ textAlign: "left" }}>
                Temperature:
            </Label>
            {props.isDetails && <span>{stream?.temperature}</span>}
            {!props.isDetails && <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                validationMessage={isFormSubmitted && !!streamValidation && !streamValidation.temperature.isValid && streamValidation.temperature.validationErrors[0]}
                validationState={isFormSubmitted && !!streamValidation && !streamValidation.temperature.isValid ? "error" : "none"}>
                <Input type="number"
                    value={stream?.temperature?.toString() ?? ""}
                    onChange={(ev, data) => { props.onChange({ ...stream, temperature: data.value as any }) }}
                    readOnly={props.isDetails} />
            </Field>
            }
            <span style={{ paddingLeft: "var(--spacingHorizontalS)" }}>K</span>
        </div>
        <div className="input-form-item" style={{ marginBottom: props.isDetails ? 0 : undefined }}>
            <Label className="input-form-label input-form-label-sm" style={{ textAlign: "left" }}>
                Pressure:
            </Label>
            {props.isDetails && <span>{stream?.pressure}</span>}
            {!props.isDetails && <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                validationMessage={isFormSubmitted && !!streamValidation && !streamValidation.pressure.isValid && streamValidation.pressure.validationErrors[0]}
                validationState={isFormSubmitted && !!streamValidation && !streamValidation.pressure.isValid ? "error" : "none"}>
                <Input type="number"
                    value={stream?.pressure?.toString() ?? ""}
                    onChange={(ev, data) => { props.onChange({ ...stream, pressure: data.value as any }) }}
                    readOnly={props.isDetails} />
            </Field>
            }
            <span style={{ paddingLeft: "var(--spacingHorizontalS)" }}>Pa</span>
        </div>
        <div className="input-form-item" style={{ marginBottom: props.isDetails ? 0 : undefined }}>
            <Label className="input-form-label input-form-label-sm input-form-label-center" style={{ textAlign: "left" }}>
                Flowrate
            </Label>
        </div>

        <div className="input-form-item"
            style={{
                marginBottom: props.isDetails ? 0 : undefined,
                display: props.isDetails && !stream.massFlowFlowrate ? "none" : undefined
            }}>
            <Label className="input-form-label input-form-label-sm" style={{ textAlign: "left", paddingLeft: "30px" }}>
                Mass Flow:
            </Label>
            {props.isDetails && <span style={{ marginLeft: "-30px" }}>{stream?.massFlowFlowrate}</span>}
            {!props.isDetails && <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`} style={{ marginLeft: "-30px" }}
                validationMessage={isFormSubmitted && !!streamValidation && !streamValidation.massFlowFlowrate.isValid && streamValidation.massFlowFlowrate.validationErrors[0]}
                validationState={isFormSubmitted && !!streamValidation && !streamValidation.massFlowFlowrate.isValid ? "error" : "none"}>
                <Input type="number"
                    value={stream?.massFlowFlowrate?.toString() ?? ""}
                    onChange={(ev, data) => { props.onChange({ ...stream, massFlowFlowrate: data.value as any, molarFlowFlowrate: undefined }) }}

                    disabled={!!stream?.molarFlowFlowrate} />
            </Field>
            }
            <span style={{ paddingLeft: "var(--spacingHorizontalS)" }}>kg/h</span>
        </div>
        <div className="input-form-item"
            style={{
                marginBottom: props.isDetails ? 0 : undefined,
                display: props.isDetails && !stream?.molarFlowFlowrate ? "none" : undefined
            }}>
            <Label className="input-form-label input-form-label-sm" style={{ textAlign: "left", paddingLeft: "30px" }}>
                Molar Flow:
            </Label>
            {props.isDetails && <span style={{ marginLeft: "-30px" }}>{stream?.molarFlowFlowrate}</span>}
            {!props.isDetails && <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`} style={{ marginLeft: "-30px" }}
                validationMessage={isFormSubmitted && !!streamValidation && !streamValidation.molarFlowFlowrate.isValid && streamValidation.molarFlowFlowrate.validationErrors[0]}
                validationState={isFormSubmitted && !!streamValidation && !streamValidation.molarFlowFlowrate.isValid ? "error" : "none"}
            >
                <Input type="number"
                    value={stream?.molarFlowFlowrate?.toString() ?? ""}
                    onChange={(ev, data) => { props.onChange({ ...stream, molarFlowFlowrate: data.value as any, massFlowFlowrate: undefined }) }}

                    disabled={!!stream?.massFlowFlowrate} />
            </Field>
            }
            <span style={{ paddingLeft: "var(--spacingHorizontalS)" }}>kmol/h</span>
        </div>


        <div className="input-form-item" style={{ marginBottom: props.isDetails ? 0 : undefined }}>
            <InfoLabel className="input-form-label input-form-label-sm input-form-label-center" style={{ textAlign: "left" }} info="Composition is set in mass fractions if Mass Flow is selected as Flowrate, and molar fractions if Molar Flow is selected." >
                Composition
            </InfoLabel>
        </div>

        {!!stream && stream.compositions.filter(x => !!x.isEntrainer == !!props.isEntrainer).map((compositon, index) => {

            const compositionValidation: ICompositionValidation | undefined = isFormSubmitted && !!streamValidation
                && !!streamValidation.compositions[index] ? streamValidation.compositions[index] : undefined;

            return <div className="input-form-item" style={{ marginBottom: props.isDetails ? 0 : undefined }}>
                <Label className="input-form-label input-form-label-sm" style={{ textAlign: "left", paddingLeft: "30px" }}>
                    {compositon.displayName}
                </Label>
                {props.isDetails && <span style={{ marginLeft: "-30px" }}>{compositon?.value}</span>}
                {!props.isDetails && <Field className={`input-form-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`} style={{ marginLeft: "-30px" }}
                    validationMessage={!!compositionValidation && !compositionValidation.value.isValid && compositionValidation.value.validationErrors[0]}
                    validationState={!!compositionValidation && !compositionValidation.value.isValid ? "error" : "none"} >
                    <Input type="number"
                        value={compositon?.value?.toString() ?? ""}
                        onChange={(ev, data) => { onCompositionChange(index, data.value) }}
                        disabled={props.disableEditComposition} />
                </Field>
                }

                {(props.separationMethod == SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation ||
                    props.separationMethod == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer
                ) && !props.isEntrainer &&
                    <>
                        {props.isDetails && compositon.isHighBoiling && <span style={{ marginLeft: "var(--spacingHorizontalMNudge)" }}>High-boiling</span>}
                        {props.isDetails && compositon.isLowBoiling && <span style={{ marginLeft: "var(--spacingHorizontalMNudge)" }}>Low-boiling</span>}
                        {!props.isDetails && <> <Field
                            style={{ marginRight: "var(--spacingHorizontalMNudge)" }}
                            validationMessage={!!compositionValidation && !compositionValidation.isLowBoiling.isValid && compositionValidation.isLowBoiling.validationErrors[0]}
                            validationState={!!compositionValidation && !compositionValidation.isLowBoiling.isValid ? "error" : "none"}
                        >
                            <Checkbox
                                label={<InfoLabel info={getInfoText(false, props.separationMethod)}>
                                    Low-boiling
                                </InfoLabel>}

                                checked={!!compositon.isLowBoiling}
                                onChange={(ev, data) => { onCompositionBoilingCheckChanged(index, !!data.checked, "isLowBoiling") }} />
                        </Field>
                            <Field
                                validationMessage={!!compositionValidation && !compositionValidation.isHighBoiling.isValid && compositionValidation.isHighBoiling.validationErrors[0]}
                                validationState={!!compositionValidation && !compositionValidation.isHighBoiling.isValid ? "error" : "none"}
                            >
                                <Checkbox
                                    label={<InfoLabel info={getInfoText(true, props.separationMethod)}>
                                        High-boiling
                                    </InfoLabel>}

                                    checked={!!compositon.isHighBoiling}
                                    onChange={(ev, data) => { onCompositionBoilingCheckChanged(index, !!data.checked, "isHighBoiling") }}
                                />
                            </Field></>}
                    </>
                }

            </div>
        })}




    </div >

}

const getInfoText = (isHighBoiling: boolean, separationMethod: SeparationMethodOption) => {
    if (separationMethod == SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation) {
        if (isHighBoiling) {
            return <span>Expected bottom product in the first column at the defined pressure.</span>;
        } else return <span>Expected bottom product in the second column after the pressure swing.</span>;
    }
    if (separationMethod == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer) {
        if (isHighBoiling) {
            return <span>Expected top product in the second column.</span>;
        } else return <span>Expected top product in the first column.</span>;
    }

    return "";


}