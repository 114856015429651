import { Button, Input } from "@fluentui/react-components";
import { Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent,  DialogActions } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { toast } from "react-toastify";
import { LoadingButton } from "../components/loading-button/loading-button.component";
import { getFiltersClient } from "../services/dashboard.service";
import { processServerError } from "../utils/helpers/error.helper";
import { useLoading } from "../utils/loading-indicator.component";

type DeleteFilterModalProps = {
    filterId?: number;
    filterName?: string;
    isOpened?: boolean,
    onSuccess: () => void,
    onClose?: () => void
};

export const DeleteFilterModal: React.FC<DeleteFilterModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [isLoading, loadingService] = useLoading();

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    const onDeleteClick = async () => {
        loadingService.showLoading("Archiving filter...", async (hideMessage) => {
            try {
                const client = getFiltersClient();
                await client.deleteFilter(props.filterId!);
                props.onSuccess();
                toast.success("Filter archived.");
            } catch (error) {
                processServerError(error, undefined, "An error occurred while archiving filter.");
            } finally {
                hideMessage();
            }
        });
    }

    const onModalClose = () => {
        setOpen(false);

        if (props.onClose) {

            props.onClose();
        }

    }


    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface>
            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Archive filter</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to archive '<b>{props.filterName}</b>' filter?</p>
                </DialogContent>
                <DialogActions>
                    <LoadingButton isLoading={isLoading} appearance="primary" type="submit" onClick={onDeleteClick} >Archive</LoadingButton>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>

}