import { TabList, Tab, MenuItem, TableColumnDefinition, createTableColumn, useTableFeatures, useTableColumnSizing_unstable, TableColumnSizingOptions, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell } from "@fluentui/react-components";
import React from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { ContextMenu, ContextMenuType } from "../../files/context-menu/context-menu.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import { JobProgressCount } from "../../components/job-progress-count/job-progress-count.component";
import { DeleteScenarioPostModel, ScenarioResultModel } from "../../swagger-clients/excel-runner-api-clients.service";
import { getScenariosClient } from "../../services/excel-runner.service";
import { AddRegular, CopyRegular, DeleteRegular, DocumentTextRegular } from "@fluentui/react-icons";
import { GetAnalysisStatus, GetProcessingStatus } from "../utilities/scenario.helpers";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { getDashboardSharingClient, getUserToken } from "../../services/dashboard.service";
import { toast } from "react-toastify";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "s365-dashboard-v2-file-picker/build/src/breadcrumbs/breadcrumbs";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { ScenarioExample } from "./scenario-example/scenario-example.component";
import { ShareDefinitionSourceType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { ScenarioDetailsModal } from "./scenario-details-modal/scenario-details-modal.component";
import { HubConnection } from "@microsoft/signalr";
import { CreateCopilotExperimentWizardModal, WizardApplication } from "../../components/create-experiment-wizard/create-experiment-wizard.modal";


type AllUserScenariosProps = {
    hubConnection: HubConnection;
}


export const AllUserScenarios: React.FC<AllUserScenariosProps> = (props) => {

    const [selectedScenario, setSelectedScenario] = React.useState<ScenarioResultModel>();
    const [scenarios, setScenarios] = React.useState<ScenarioResultModel[]>([]);
    const [showDeleteScenarioModal, setShowDeleteScenarioModal] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();
    const contextMenuRef = React.createRef<ContextMenuType>();
    const [currentPage, SetCurrentPage] = React.useState<number>(1);
    const [sizePerPage, SetSizePerPage] = React.useState<number>(25);
    const [totalRecords, setTotalRecords] = React.useState<number>(0);
    const [orderByIdDesc, setOrderByIdDesc] = React.useState<boolean>(true);
    const [exampleIds, setExampleIds] = React.useState<number[]>([]);
    const [showScenarioExampleModal, setShowScenarioExampleModal] = React.useState<boolean>(false);
    const [selectedExampleScenarioId, setSelectedExampleScenarioId] = React.useState<number>();
    const [showCreateNewExperimentWizardModal, setShowCreateNewExperimentWizardModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        getPublicExperiments();
    }, []);
    React.useEffect(() => {
        getScenarios();
    }, [currentPage, orderByIdDesc]);


    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        scenarioId: {
            defaultWidth: 100,
            minWidth: 50,
        },
        scenarioName: {
            minWidth: 100,
            defaultWidth: 300,
        },
        scenarioFile: {
            minWidth: 200,
            defaultWidth: 400,
        },
        analysisStatus: {
            minWidth: 100,
            defaultWidth: 200,
        },
        processingStatus: {
            minWidth: 100,
            defaultWidth: 200
        },
        jobs: {
            minWidth: 100,
            defaultWidth: 200
        },
        created: {
            minWidth: 100,
            defaultWidth: 100
        }
    });


    const items = scenarios ?? [];
    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<ScenarioResultModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    const getScenarios = async () => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getScenariosClient();

                const scenariosResp = await client.getAllUserScenarios((currentPage - 1) * sizePerPage, sizePerPage, orderByIdDesc);
                setScenarios(scenariosResp?.scenarios ?? []);
                setTotalRecords(scenariosResp?.totalRecords ?? 0);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting experiments.`);
            } finally {
                hideMessage();
            }
        });
    }

    const getPublicExperiments = () => {
        loadingService.showLoading("Loading examples...", async (hideMessage) => {
            try {
                const client = getDashboardSharingClient();

                const sharedDefinitions = await client.getPublicExamples(ShareDefinitionSourceType.DoEExperiment);
                const sharedDefinitionSourceIds = sharedDefinitions?.map(x => +x.sourceId) ?? [];
                setExampleIds(sharedDefinitionSourceIds);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting examples.`);
            } finally {
                hideMessage();
            }
        });
    }

    const onDeleteScenarioClick = () => {
        loadingService.showLoading("Deleting scenario...", async (hideMessage) => {
            try {


                const client = getScenariosClient();

                const accessToken: string = await getUserToken();

                const query = {
                    scenarioId: selectedScenario.id,
                    accessToken: accessToken
                } as unknown as DeleteScenarioPostModel;

                await client.deleteScenario(query);
                toast.success("Experiment deleted successfully.");
                setSelectedScenario(undefined);
                setShowDeleteScenarioModal(false);
                getScenarios();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to delete experiment.");
            } finally {
                hideMessage();
            }
        });
    }

    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        menuItems.push(<MenuItem icon={<DocumentTextRegular />} key="details"
            onClick={() => navigate(`/files/${selectedScenario?.fileDriveId}/doe/details/${selectedScenario?.id}`)} > Details</MenuItem>);
        menuItems.push(<MenuItem icon={<CopyRegular />} key="clone"
            onClick={() => navigate(`/files/${selectedScenario?.fileDriveId}/doe/edit?cloneId=${selectedScenario?.id}`)}> Clone</MenuItem>);

        if (!selectedScenario?.isDeleted) {
            menuItems.push(...[
                <MenuItem icon={<DeleteRegular />} key="delete" onClick={() => { setShowDeleteScenarioModal(true); }}> Delete</MenuItem>
            ]);
        }

        return menuItems;
    }

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "scenarioId": return true;
            case "analysisStatus": return true;
            case "processingStatus": return true;
            case "jobs": return true;
            case "created": return true;

            default: return false;
        }
    }
    const onNavigateToDetails = (item: ScenarioResultModel) => {
        if (!!item.flowsheetUniqueId) {
            navigate(`/files/${item.flowsheetUniqueId}/doe/details/${item.id}`);
        } else {
            navigate(`/files/${item.fileDriveId}/doe-xls/details/${item.id}`);
        }
    }

    const totalPages = totalRecords > sizePerPage ? Math.ceil(totalRecords / sizePerPage) : 1;

    return <div className="content-wrapper">

        <div className='toolbar__wrapper'>
            <Toolbar>

                <ToolbarButton icon={<AddRegular />} appearance="primary" onClick={() => { setShowCreateNewExperimentWizardModal(true); }}>New Experiment</ToolbarButton>


                <LoadingIndicator loadingService={loadingService} />

            </Toolbar>
        </div>

        <div className='groups-wrapper__breadcrumbs-wrapper'>
            <Breadcrumbs>
                <Breadcrumb key={`breadcrumb-applications`}>Applications</Breadcrumb>
                <Breadcrumb key={`breadcrumb-agents`} active={true}>Design of Experiments (DoE) Studies</Breadcrumb>
            </Breadcrumbs>
        </div>
        <div id="examples" style={{ display: "flex", gap: 20 }} >
            {!!exampleIds && exampleIds.length > 0 && exampleIds.map((scenarioId) => {

                return <ScenarioExample
                    scenarioId={scenarioId}
                    onClick={() => {
                        setSelectedExampleScenarioId(scenarioId);
                        setShowScenarioExampleModal(true);
                    }} />


            })}

        </div>

        <div className='s365-table__wrapper'>
            <Table sortable ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
                <TableHeader>
                    <TableRow>
                        {columns.map((column) => (
                            <TableHeaderCell
                                onDragStart={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                                style={{ cursor: (column.columnId as string) == "scenarioId" ? "pointer" : "default" }}
                                sortable={(column.columnId as string) == "scenarioId"}
                                sortDirection={(column.columnId as string) == "scenarioId" ? (orderByIdDesc ? "descending" : "ascending") : undefined}
                                onClick={() => { if ((column.columnId as string) == "scenarioId") { setOrderByIdDesc(!orderByIdDesc); } }}
                                key={column.columnId}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}

                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}

                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={7} loadingMessage="Loading..."
                    itemCount={scenarios ? scenarios.length : 0}
                    noItemsMessage="No items found.">
                    {scenarios && scenarios.length > 0 && scenarios.map((item) => {
                        return <TableRow
                            key={`study-${item.id}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => onNavigateToDetails(item)}
                            className={selectedScenario && selectedScenario.id == item.id ? "table-row-selected" : undefined}
                            onContextMenu={(ev) => { ev.preventDefault(); setSelectedScenario(item); contextMenuRef.current?.showMenu(ev); }}>
                            {columns.map((column) => (
                                <TableCell
                                    className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}

                        </TableRow>
                    })}
                </TableBodyWithLoading>
            </Table>
            <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me page-item'}
                breakLinkClassName={'page-link'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}

                onPageChange={(page) => { console.log("Selected page:", page); SetCurrentPage(page.selected + 1); }}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </div>


        <ContextMenu ref={contextMenuRef} >
            {getContextItems()}
        </ContextMenu>
        <ConfirmationDialog
            isOpened={showDeleteScenarioModal}
            title="Delete Scenario"
            confirmLabel="Delete"
            subText={
                `Are you sure you want to delete scenario '${selectedScenario?.name}'? `}
            onConfirm={() => {
                onDeleteScenarioClick();
            }}
            onClose={() => { setShowDeleteScenarioModal(false); }} />
        {showScenarioExampleModal && selectedExampleScenarioId && <ScenarioDetailsModal
            scenarioId={selectedExampleScenarioId}
            isOpened
            hubConnection={props.hubConnection}
            onClose={() => { setShowScenarioExampleModal(false); setSelectedExampleScenarioId(undefined); }}
        />}

        {showCreateNewExperimentWizardModal &&
            <CreateCopilotExperimentWizardModal
                title="Create Design of Experiment (DoE)"
                hubConnection={props.hubConnection}
                application={WizardApplication.DoE}
                isOpened={showCreateNewExperimentWizardModal}
                onClose={() => { setShowCreateNewExperimentWizardModal(false); }}
            />}
    </div>
}

const columns: TableColumnDefinition<ScenarioResultModel>[] = [
    createTableColumn<ScenarioResultModel>({
        columnId: "scenarioId",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>ID</div>,
        renderCell: (item: ScenarioResultModel) => {

            return <div style={{ textAlign: "center" }}>{item.id}</div>;

        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "scenarioName",
        renderHeaderCell: () => <>Name</>,
        renderCell: (item: ScenarioResultModel) => {
            return item.name;
        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "scenarioFile",
        renderHeaderCell: () => <>File</>,
        renderCell: (item: ScenarioResultModel) => {
            if (!!item.flowsheetUniqueId) {
                return <FileDisplayName fileUniqueIdentifier={item.flowsheetUniqueId} fileVersionNumber={undefined} />;

            }

            return <FileDisplayName fileUniqueIdentifier={item.fileDriveId} fileVersionNumber={item.fileVersion ? +item.fileVersion : undefined} />;

        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "analysisStatus",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>Analysis Status</div>,
        renderCell: (item: ScenarioResultModel) => {

            return <div style={{ textAlign: "center" }}>{GetAnalysisStatus(item.analysisStatus)}</div>;

        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "processingStatus",
        renderHeaderCell: () => <>Processing Status</>,
        renderCell: (item: ScenarioResultModel) => {

            return <div style={{ textAlign: "center" }}>{GetProcessingStatus(item.processingStatus)}</div>;
        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "jobs",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>Jobs</div>,
        renderCell: (item: ScenarioResultModel) => {

            return <div style={{ textAlign: "center" }}><JobProgressCount
                calculatedJobs={item.calculatedRows}
                failedJobs={item.failedRows}
                totalJobs={item.totalRows} /></div>;
        }
    }),
    createTableColumn<ScenarioResultModel>({
        columnId: "created",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>Created</div>,
        renderCell: (item: ScenarioResultModel) => {

            return <div style={{ textAlign: "center" }}><ShowLocalTime date={item.createdAt} /></div>;
        }
    }),

];


