import { Button, Field, Input, TableColumnDefinition, TableColumnSizingOptions, Tooltip, createTableColumn, useTableColumnSizing_unstable, useTableFeatures } from "@fluentui/react-components";
import { Dropdown, Table, TableBody, TableCell, Option, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";

import { ArrowReplyAll20Regular, Dismiss20Regular } from "@fluentui/react-icons";
import React from "react";
import { ConfirmationDialog } from "../../../components/confirmation-dialog/confirmation-dialog.component";
import { ParameterDisplay } from "../../../components/parameter-display/parameter-display.component";
import { TableBodyWithLoading } from "../../../components/table-body-with-loading/table-body-with-loading.component";
import { getParameterTypeText } from "../../../filters/filter-utilities";
import { FileModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { getDropdownText } from "../../../utils/helpers/dropdown.helpers";
import { IDropdownOption } from "../../../utils/shared.types";
import { StudyInputParamterType, StudyParamterRange } from "../../../swagger-clients/sensitivity-studies-api-clients.service";
import { calculateVariationsAndJobs, roundIfGreatherThanOne } from "../../utilities/study.utilities";
import { getMinMaxTotalVariationsCount, getStepsBeforeAfterVariationsCount } from "../../utilities/total-variations-calculator";
import { CustomStudyInputParameterType, SensitivityStudy, SensitivityStudyInputParameter } from "../edit-sensitivity-study.interfaces";
import { ValidateGraterOrEqualZero, ValidateGraterThanZero, ValidateMinMax, ValidateStudyParameterValue } from "../edit-sensitivity-study.validation";
import "./input-parameter-tab.styless.scss";
import { GLOBAL_STYLES } from "../../../styles";

type StudyInputParametersTabProps = {
    selectedStudy?: SensitivityStudy;
    selectedFile: FileModel;
    onSelectedStudyChanged?: (updatedStudy: SensitivityStudy) => void;
    isFormSubmitted: boolean;
    isLoading: boolean;
}

const parameterTypeDropdownOptions: IDropdownOption[] = [
    { key: 0, text: "Fixed value" },
    { key: 1, text: "Range" }
];

export const StudyInputParametersTab: React.FC<StudyInputParametersTabProps> = (props) => {
    const isMinMax = props.selectedStudy && props.selectedStudy.studyParamterRange == StudyParamterRange.MinMax;
    const items = props.selectedStudy ? [...props.selectedStudy.sensitivityStudyInputParameters] : [];
    const [columns, setColumns] = React.useState<TableColumnDefinition<SensitivityStudyInputParameter>[]>([]);

    const [selectedItemIndex, setSelectedItemIndex] = React.useState<number | undefined>(undefined);
    const [showSetFlowsheetValuesDialog, setShowSetFlowsheetValuesDialog] = React.useState<boolean>(false);
    React.useEffect(() => {
        const columnsResp = getColumns();
        setColumns(columnsResp);
    }, [items]);

    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({

        parameter: {
            minWidth: 100,
            defaultWidth: 300,
        },
        alias: {
            minWidth: 100,
            defaultWidth: 200,
        },
        parameterType: {
            minWidth: 100,
            defaultWidth: 200,
        },
        value: {
            minWidth: 50,
            defaultWidth: 100
        },
        min: {
            minWidth: 50,
            defaultWidth: 100
        },
        max: {
            minWidth: 50,
            defaultWidth: 100

        },
        stepBefore: {
            minWidth: 50,
            defaultWidth: 100

        },
        stepAfter: {
            minWidth: 50,
            defaultWidth: 100

        },
        stepSize: {
            minWidth: 50,
            defaultWidth: 100

        },
        totalVariations: {
            minWidth: 50,
            defaultWidth: 100

        },
        defaultValues: {
            minWidth: 50,
            defaultWidth: 100

        },
    });

    const onItemChanged = (index: number, newValue: string, column: string) => {

        let newParams = [...props.selectedStudy.sensitivityStudyInputParameters];
        (newParams[index] as any)[column] = newValue;

        const updatedStudy = calculateVariationsAndJobs(props.selectedStudy, newParams);
        props.onSelectedStudyChanged(updatedStudy);


    }
    const onClearItemValues = (index: number) => {
        let newParams = [...props.selectedStudy.sensitivityStudyInputParameters];
        newParams[index].value = undefined;
        newParams[index].minValue = undefined;
        newParams[index].maxValue = undefined;
        newParams[index].stepSize = undefined;
        newParams[index].stepsAfter = undefined;
        newParams[index].stepsBefore = undefined;
        newParams[index].totalVariations = undefined;

        const updatedStudy = calculateVariationsAndJobs(props.selectedStudy, newParams);
        props.onSelectedStudyChanged(updatedStudy);
    }
    const onSetFlowsheetItemValueClick = (index: number) => {
        setSelectedItemIndex(index);
        setShowSetFlowsheetValuesDialog(true);
    }
    const onSetFlowsheetItemValue = () => {
        let newParams = [...props.selectedStudy.sensitivityStudyInputParameters];
        const value = props.selectedStudy.sensitivityStudyInputParameters[selectedItemIndex].flowsheetParameterValue?.value;
        if (props.selectedStudy.sensitivityStudyInputParameters[selectedItemIndex].valueType == StudyInputParamterType.FixedValue) {
            newParams[selectedItemIndex].value = roundIfGreatherThanOne(value);
        } else {

            if (props.selectedStudy.studyParamterRange == StudyParamterRange.InitialValueWithSteps) {
                newParams[selectedItemIndex].value = roundIfGreatherThanOne(value);
                newParams[selectedItemIndex].stepsBefore = 1;
                newParams[selectedItemIndex].stepsAfter = 1;
                newParams[selectedItemIndex].stepSize = roundIfGreatherThanOne(value * 0.1);
            } else {
                newParams[selectedItemIndex].stepSize = roundIfGreatherThanOne(value * 0.1);
                newParams[selectedItemIndex].minValue = roundIfGreatherThanOne(value) - newParams[selectedItemIndex].stepSize;
                newParams[selectedItemIndex].maxValue = roundIfGreatherThanOne(value) + newParams[selectedItemIndex].stepSize;
            }

        }
        const updatedStudy = calculateVariationsAndJobs(props.selectedStudy, newParams);
        props.onSelectedStudyChanged(updatedStudy);
    }




    const onSelectFieldChanged = (index: number, option: IDropdownOption, column: string) => {
        let newParams = [...props.selectedStudy.sensitivityStudyInputParameters];
        (newParams[index] as any)[column] = option.key;

        if (option.key == StudyInputParamterType.FixedValue) {
            newParams[index].stepSize = undefined;
            newParams[index].stepsAfter = undefined;
            newParams[index].stepsBefore = undefined;
            newParams[index].minValue = undefined;
            newParams[index].maxValue = undefined;
        } else {
            newParams[index].value = undefined;
        }
        const updatedStudy = { ...props.selectedStudy, sensitivityStudyInputParameters: newParams } as SensitivityStudy;
        props.onSelectedStudyChanged(updatedStudy);
    }

    const getParameterNameCell = (item: SensitivityStudyInputParameter, index: number) => {
        const inputParamater = (item as any).inputFilterParameter;
        return <span style={{ verticalAlign: "middle" }}>{inputParamater && props.selectedFile ? <ParameterDisplay
            value={inputParamater.name}
            fileUniqueId={props.selectedFile.uniqueIdentifier}
            versionNumber={props.selectedFile.currentVersionNumber.toString()}

        /> : ""}</span>;
    }
    const getParameterAliasCell = (item: SensitivityStudyInputParameter, index: number) => {
        const inputParamater = (item as any).inputFilterParameter;
        return <span style={{ verticalAlign: "middle" }}>{inputParamater?.alias}</span>;
    }

    const getParameterTypeCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue) {
            return "Fixed value (set by filter)";
        }

        if (item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "Formula";
        }


        return <Field
            className={`table-input-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
        >
            <Dropdown value={getDropdownText(item.valueType!.toString(), parameterTypeDropdownOptions)}
                style={{ minWidth: "auto" }}
                onOptionSelect={(ev, data) => {
                    const selectedOption = parameterTypeDropdownOptions.find(x => x.key == data.optionValue);
                    onSelectFieldChanged(index, selectedOption, "valueType");
                }}>
                {parameterTypeDropdownOptions.map((option) => {
                    return <Option key={`param-type-${option.key}`} value={option.key?.toString()}>
                        {option.text}
                    </Option>;
                })}
            </Dropdown>
        </Field>
    }

    const getParameterValueCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return item.filterParameterValue ? item.filterParameterValue : "";
        }

        const validationResult = ValidateStudyParameterValue(item.value ? item.value.toString() : "", "Value", true, false);


        if (item.valueType == StudyInputParamterType.Range && props.selectedStudy.studyParamterRange == StudyParamterRange.MinMax) {
            return "";
        } else {

            return <Field
                className={`table-input-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                validationMessage={props.isFormSubmitted && !validationResult.isValid ? validationResult.validationErrors[0] : ""}
                validationState={props.isFormSubmitted && !validationResult.isValid ? "error" : "none"} >
                <Input
                    value={item.value ? item.value.toString() : ""}
                    onChange={(ev, data) => onItemChanged(index, data.value, "value")} />
            </Field>
        }
    }
    const getStepSizeCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }
        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {
            const validationResult = ValidateGraterThanZero(item.stepSize ? item.stepSize.toString() : "", "Step size", true, false);

            return <Field
                className={`table-input-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                validationMessage={props.isFormSubmitted && !validationResult.isValid ? validationResult.validationErrors[0] : ""}
                validationState={props.isFormSubmitted && !validationResult.isValid ? "error" : "none"} >
                <Input
                    value={item.stepSize ? item.stepSize.toString() : ""}
                    onChange={(ev, data) => onItemChanged(index, data.value, "stepSize")} />
            </Field>
        }
    }

    const getTotalVariationsCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }

        if (props.selectedStudy.studyParamterRange == StudyParamterRange.MinMax && item.valueType == StudyInputParamterType.Range) {
            if (item.minValue && item.maxValue && item.stepSize) {
                const totalVariations = getMinMaxTotalVariationsCount(item.minValue, item.maxValue, item.stepSize);
                return <p style={{ margin: 0, verticalAlign: "middle" }}>{totalVariations}</p>
            } else {
                return "";
            }
        }
        if (props.selectedStudy.studyParamterRange == StudyParamterRange.InitialValueWithSteps && item.valueType == StudyInputParamterType.Range) {
            if (item.stepsBefore && item.stepsAfter) {
                const totalVariations = getStepsBeforeAfterVariationsCount(item.stepsBefore, item.stepsAfter);
                return <p style={{ margin: 0, verticalAlign: "middle" }}>{totalVariations}</p>
            } else {
                return "";
            }
        }

        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {
            return <p style={{ margin: 0, verticalAlign: "middle" }}>{item.totalVariations ? item.totalVariations.toString() : ""}</p>
        }
    }

    const getStepsBeforeCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }
        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {

            const validationResult = ValidateGraterOrEqualZero(item.stepsBefore ? item.stepsBefore.toString() : "", "Steps before", false, true);
            const tabIndex = index * 10 + 3;
            return <Field
                className={`table-input-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                validationMessage={props.isFormSubmitted && !validationResult.isValid ? validationResult.validationErrors[0] : ""}
                validationState={props.isFormSubmitted && !validationResult.isValid ? "error" : "none"} >
                <Input
                    value={item.stepsBefore ? item.stepsBefore.toString() : ""}
                    onChange={(ev, data) => onItemChanged(index, data.value, "stepsBefore")} />
            </Field>
        }
    }

    const getStepsAfterCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }
        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {
            const validationResult = ValidateGraterOrEqualZero(item.stepsAfter ? item.stepsAfter.toString() : "", "Steps after", false, true);
            const tabIndex = index * 10 + 4;
            return <Field
                className={`table-input-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                validationMessage={props.isFormSubmitted && !validationResult.isValid ? validationResult.validationErrors[0] : ""}
                validationState={props.isFormSubmitted && !validationResult.isValid ? "error" : "none"} >
                <Input

                    value={item.stepsAfter ? item.stepsAfter.toString() : ""}
                    onChange={(ev, data) => onItemChanged(index, data.value, "stepsAfter")} />
            </Field>
        }
    }

    const getMinValueCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }
        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {
            const minValue = item.minValue ? item.minValue.toString() : "";
            const maxValue = item.maxValue ? item.maxValue.toString() : "";

            const validationResult = ValidateMinMax(minValue, minValue, maxValue, "Min", true, false);
            const tabIndex = index * 10 + 3;
            return <Field
                className={`table-input-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                validationMessage={props.isFormSubmitted && !validationResult.isValid ? validationResult.validationErrors[0] : ""}
                validationState={props.isFormSubmitted && !validationResult.isValid ? "error" : "none"} >
                <Input
                    value={item.minValue ? item.minValue.toString() : ""}
                    onChange={(ev, data) => onItemChanged(index, data.value, "minValue")} />
            </Field>
        }
    }
    const getMaxValueCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }
        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {
            const minValue = item.minValue ? item.minValue.toString() : "";
            const maxValue = item.maxValue ? item.maxValue.toString() : "";
            const validationResult = ValidateMinMax(maxValue, minValue, maxValue, "Max", true, false);

            const tabIndex = index * 10 + 4;
            return <Field
                className={`table-input-field ${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`}
                validationMessage={props.isFormSubmitted && !validationResult.isValid ? validationResult.validationErrors[0] : ""}
                validationState={props.isFormSubmitted && !validationResult.isValid ? "error" : "none"} >
                <Input
                    value={item.maxValue ? item.maxValue.toString() : ""}
                    onChange={(ev, data) => onItemChanged(index, data.value, "maxValue")} />
            </Field>
        }
    }
    const getFlowsheetDefaultValuesCell = (item: SensitivityStudyInputParameter, index: number) => {
        console.log("getFlowsheetDefaultValuesCell", item);
        if (!item.flowsheetParameterValue || item.flowsheetParameterValue.value === undefined)
            return null;

        if (item.parameterType == CustomStudyInputParameterType.FilterFormula || item.parameterType == CustomStudyInputParameterType.FixedFilterValue) {
            return null;
        }
        const unit = item.flowsheetParameterValue?.unit && item.flowsheetParameterValue.unit !== "unknown" ? item.flowsheetParameterValue.unit : "";
        return <div style={{ display: "flex" }}>
            <Tooltip positioning="above" content={<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <span>Insert Flowsheet Values</span>
                <span> {item.flowsheetParameterValue?.value} {unit}</span></div>}
                relationship="label">
                <Button appearance="transparent" onClick={() => onSetFlowsheetItemValueClick(index)} icon={<ArrowReplyAll20Regular />} />
            </Tooltip>
            <Tooltip content="Clear values" positioning="above" relationship="label">
                <Button appearance="transparent" onClick={() => onClearItemValues(index)} icon={<Dismiss20Regular />} />

            </Tooltip>

        </div>
    }



    const getColumns = () => {
        let columnsDef: TableColumnDefinition<SensitivityStudyInputParameter>[] = [
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "parameter",
                renderHeaderCell: () => <>Parameter</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                    return getParameterNameCell(item, index);

                }
            }),
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "alias",
                renderHeaderCell: () => <>Alias</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                    return getParameterAliasCell(item, index);

                }
            }),
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "parameterType",
                renderHeaderCell: () => <>Parameter Type</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                    return getParameterTypeCell(item, index);

                }
            }),
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "value",
                renderHeaderCell: () => <>Value</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                    return getParameterValueCell(item, index);

                }
            })
        ];

        if (isMinMax) {

            columnsDef.push(...[
                createTableColumn<SensitivityStudyInputParameter>({
                    columnId: "min",
                    renderHeaderCell: () => <>Min</>,
                    renderCell: (item: SensitivityStudyInputParameter) => {
                        const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                        return getMinValueCell(item, index);

                    }
                }),
                createTableColumn<SensitivityStudyInputParameter>({
                    columnId: "max",
                    renderHeaderCell: () => <>Max</>,
                    renderCell: (item: SensitivityStudyInputParameter) => {
                        const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                        return getMaxValueCell(item, index);
                    }
                })
            ]);

        } else {
            columnsDef.push(...[
                createTableColumn<SensitivityStudyInputParameter>({
                    columnId: "stepBefore",
                    renderHeaderCell: () => <>Step before</>,
                    renderCell: (item: SensitivityStudyInputParameter) => {
                        const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                        return getStepsBeforeCell(item, index);

                    }
                }),
                createTableColumn<SensitivityStudyInputParameter>({
                    columnId: "stepAfter",
                    renderHeaderCell: () => <>Step after</>,
                    renderCell: (item: SensitivityStudyInputParameter) => {
                        const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                        return getStepsAfterCell(item, index);

                    }
                })
            ]);
        }


        columnsDef.push(...[
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "stepSize",
                renderHeaderCell: () => <>Step size</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                    return getStepSizeCell(item, index);

                }
            }),
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "totalVariations",
                renderHeaderCell: () => <>Total variations</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                    return getTotalVariationsCell(item, index);
                }
            }),
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "defaultValues",
                renderHeaderCell: () => <>&nbsp;</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.inputFilterParameterId == item.inputFilterParameterId);
                    return getFlowsheetDefaultValuesCell(item, index);
                }
            })
        ]);

        return columnsDef;
    }



    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<SensitivityStudyInputParameter>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );


    return <div>

        <ConfirmationDialog
            isOpened={showSetFlowsheetValuesDialog}
            title='Set flowsheet values'
            subText="Existing values will be overwritten. Do you want to continue?"
            onConfirm={() => { onSetFlowsheetItemValue(); setShowSetFlowsheetValuesDialog(false); }}
            onClose={() => { setShowSetFlowsheetValuesDialog(false); setSelectedItemIndex(undefined); }}
        />
        <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
            <TableHeader>
                <TableRow key="table-header">
                    {columns.map((column) => (
                        <TableHeaderCell
                            onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            className={`table__cell--bold`}
                            {...columnSizing_unstable.getTableHeaderCellProps(
                                column.columnId
                            )}
                        >
                            {column.renderHeaderCell()}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>

            <TableBodyWithLoading isLoading={props.isLoading}
                columnCount={9} loadingMessage="Loading..."
                itemCount={items ? items.length : 0}
                noItemsMessage={`Select filter to view parameters.`}>
                {items &&
                    items.map((item, index) => {
                        return <TableRow key={`input-param-${index}`}>
                            {columns.map((column) => (
                                <TableCell
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
    </div>

}