import { JobActionTriggerOn, JobActionType } from "../swagger-clients/dispatcher-next-api-clients.service";

export const getActionType = (type: JobActionType): string => {
    switch (type) {
        case JobActionType.Email:
            return "Email";
        case JobActionType.WebHook:
            return "WebHook";
        default:
            return "";

    }
}
export const getActionTriggerOn = (triggerOn: JobActionTriggerOn): string => {
    switch (triggerOn) {
        case JobActionTriggerOn.Always:
            return "Always";
        case JobActionTriggerOn.Custom:
            return "Custom";
        case JobActionTriggerOn.OnError:
            return "On error";
        case JobActionTriggerOn.OnSuccess:
            return "On success";
        default:
            return "";
    }
}