import axios from "axios";
import { msalInstance } from "../index";
import { loginRequest } from "../auth/authConfig";
import {
    FilesClient, FiltersClient, LicensingClient, GroupsClient, TagsClient,
    ThumbnailsClient, SharingClient, UsersClient, FiltersCountClient, PrivacyPolicyClient,
    DirectoriesClient, SupportClient, OrganizationsClient, ContactsClient,
    FilterWebFormsClient, TourClient, DwsimProStartupActionsClient,
    ImpersonateUserClient,
    DownloadFilesClient
} from "../swagger-clients/s365-dashboard-v2-api-clients.service";
import { settings } from "../App";
import { FlowsheetsClient } from "../swagger-clients/dispatcher-next-api-clients.service";
import ImpersonateUserService from "./impersonate-user.service";

export const AxiosInstance = axios.create();
// Add a request interceptor
AxiosInstance.interceptors.request.use(async function (config) {

    const token = await getUserToken();
    if (token) {
        const authorization = `Bearer ${token}`;
        config.headers!.Authorization = authorization;
    }
    return config;
});

export const getUserToken = async () => {

    const impersonationToken = ImpersonateUserService.getUserToken();
    if (!!impersonationToken)
        return impersonationToken;

    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    return response.accessToken;
}


export const getFilesClient = () => {
    return new FilesClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getTagsClient = () => {
    return new TagsClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getThumbnailsClient = () => {
    return new ThumbnailsClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getFiltersClient = () => {
    return new FiltersClient(settings?.dashboardServiceUrl, AxiosInstance);
}

// For public page requests, without bearer token
export const getFilterWebFormsClient = () => {
    return new FilterWebFormsClient(settings?.dashboardServiceUrl);
}

export const getDispatcherFlowsheetsClient = () => {
    return new FlowsheetsClient(settings?.dispatcherServiceUrl, AxiosInstance);
}
export const getGroupsClient = () => {
    return new GroupsClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getDashboardSharingClient = () => {
    return new SharingClient(settings?.dashboardServiceUrl, AxiosInstance);
}
export const getUsersClient = () => {
    return new UsersClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getFiltersCountClient = () => {
    return new FiltersCountClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getPrivacyPolicyClient = () => {
    return new PrivacyPolicyClient(settings?.dashboardServiceUrl, AxiosInstance);
}


export const getDirectoriesClient = () => {
    return new DirectoriesClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getSupportClient = () => {
    return new SupportClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getOrganizationsClient = () => {
    return new OrganizationsClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getContactsClient = () => {
    return new ContactsClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getTourClient = () => {
    return new TourClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getLicensingClient = () => {
    return new LicensingClient(settings?.dashboardServiceUrl, AxiosInstance);
}
export const getDwsimProStartupActionsClient = () => {
    return new DwsimProStartupActionsClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getImpersonateUserClient = () => {
    return new ImpersonateUserClient(settings?.dashboardServiceUrl, AxiosInstance);
}

export const getDownloadFilesClient = () => {
    return new DownloadFilesClient(settings?.dashboardServiceUrl, AxiosInstance);
}