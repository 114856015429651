import { Field, Select, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import React from "react";
import { getJobsDetailsClient } from "../../services/dispatcher.service";
import { JobExecutionResponseModel, JobExecutionResultResponseModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { getSimulatorName } from "../../utils/helpers/simulator.helpers";
import { useLoading } from "../../utils/loading-indicator.component";
import { IDropdownOption } from "../../utils/shared.types";
import JobExecutionStatusComponent from "../job-execution-status/job-execution-status.component";
import { ParameterDisplay } from "../parameter-display/parameter-display.component";
import { TableBodyWithLoading } from "../table-body-with-loading/table-body-with-loading.component";


type JobResultsTableProps = {
    jobExecutionId?: number;
    executions: JobExecutionResponseModel[];
    fileUniqueId: string;
    fileVersionNumber: string;
}
export type JobResultsTableType = {
    getExecutionResults(): void
}

export const JobResultsTable = React.forwardRef<JobResultsTableType, JobResultsTableProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            getExecutionResults() {
                getExecutionResults();
            }
        }));
    const [executionResults, setExecutionResults] = React.useState<JobExecutionResultResponseModel[]>([]);
    const [isLoading, loadingService] = useLoading();
    const [selectedExecutionId, setSelectedExecutionId] = React.useState<number>();

    React.useEffect(() => {
        if (props.jobExecutionId) {
            setSelectedExecutionId(props.jobExecutionId);
        } else {
            if (props.executions && props.executions.length > 0) {
                setSelectedExecutionId(props.executions[0].id);
            }
        }
    }, []);
    React.useEffect(() => { getExecutionResults(); }, [selectedExecutionId]);
    React.useEffect(() => {
        if (props.jobExecutionId && props.jobExecutionId !== selectedExecutionId)
            setSelectedExecutionId(props.jobExecutionId);
    }, [props.jobExecutionId]);

    const getExecutionResults = async () => {
        if (selectedExecutionId) {
            loadingService.showLoading("Loading...", async (hideMessage) => {
                try {

                    console.log("Getting results for execution with  id:", selectedExecutionId);

                    const client = getJobsDetailsClient();
                    const executionResults = await client.getExecutionResults(selectedExecutionId);
                    var execRes = executionResults && executionResults.length > 0 ? executionResults : [];
                    setExecutionResults(execRes);
                }
                catch (error) {
                    processServerError(error, undefined, "An error occurred while trying to get execution results.");
                }
                finally {
                    hideMessage();
                }
            });
        }


    }


    const { executions } = props;
    const dropdownOptions = executions && executions.length > 0 ?
        executions.map(x => ({ key: x.id, text: x.id.toString() } as IDropdownOption)) : [];


    return <div>
        <div className="input-form">
            <div className="input-form-item">
                <label className="input-form-label">Results for execution:</label>
                <Field className="input-form-field">
                    <Select
                        value={selectedExecutionId?.toString() ?? undefined}
                        placeholder="Select an option"
                        onChange={(ev, data) => { setSelectedExecutionId(+data.value!); }}
                    >
                        {dropdownOptions.map((option) => {
                            return <option value={option.key?.toString()}>
                                {option.text}
                            </option>
                        })}
                    </Select>
                </Field>

            </div>
        </div>
        <Table>
            <TableHeader>
                <TableRow key="table-header">
                    <TableHeaderCell key="parameter" style={{ width: "300px" }} className='table__cell--bold'>Parameter</TableHeaderCell>
                    <TableHeaderCell key="parameterdesc" style={{ width: "230px" }} className='table__cell--bold'></TableHeaderCell>
                    <TableHeaderCell key="value" className='table__cell--bold'>Value</TableHeaderCell>


                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={isLoading}
                columnCount={4} loadingMessage="Loading..."
                itemCount={executionResults ? executionResults.length : 0}
                noItemsMessage="No items found.">
                {executionResults &&
                    executionResults.map((item, index) => {
                        return <TableRow key={`execution-${index}`}>
                            <TableCell> <ParameterDisplay
                                value={item.parameter}
                                fileUniqueId={props.fileUniqueId}
                                versionNumber={props.fileVersionNumber} /> </TableCell>
                            <TableCell>  </TableCell>
                            <TableCell> {item.value} </TableCell>


                        </TableRow>
                    })

                }

            </TableBodyWithLoading>
        </Table>
    </div>
});