import * as React from "react";
import { StudyStepStatus } from "../../swagger-clients/sensitivity-studies-api-clients.service";


export interface IStudyStepStatusProps {
    status: StudyStepStatus;
}

export const StudyStepStatusComponent: React.FC<IStudyStepStatusProps> = (props) => {

    const studyStepStatuses: { [status: string]: { color: string, name: string } } =
    {
        [StudyStepStatus.Ready.toString()]: { color: "#115ea3", name: "Not calculated" },
        [StudyStepStatus.Failed.toString()]: { color: "red", name: "Failed" },
        [StudyStepStatus.SuccessfullyFinished.toString()]: { color: "grey", name: "Successfully calculated" },
        [StudyStepStatus.InProgress.toString()]: { color: "green", name: "In progress" },
        [StudyStepStatus.Stopped.toString()]: { color: "purple", name: "Stopped" }
    };
    if (props.status === undefined) {
        return null;
    }

    const status = studyStepStatuses[props.status];


    return <span style={{ color: status.color }}>{status.name}</span>;


}

