import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Label, RadioGroup, Radio, DialogActions, useModalAttributes, DialogTrigger } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { UploadConflictAction } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";


type ConflictModalProps = {
    isOpened: boolean;
    modalTitle?: string;
    buttonLabel?: string;
    showExistingDirectoryMessage?: boolean;
    onSubmit: (conflictAction: UploadConflictAction) => void;
    onClose: () => void;
}

export type ConflictFormModel = {
    conflictAction: string;
};



export const ConflictModal: React.FC<ConflictModalProps> = (props) => {

    const { register, handleSubmit, watch, setError, formState: { errors }, setValue } = useForm<ConflictFormModel>({ defaultValues: { conflictAction: "keep" } });



    const onSubmit = (m: ConflictFormModel) => {
        props.onSubmit(mapConflictAction(m.conflictAction));
    }

    const mapConflictAction = (action: string) => {
        switch (action) {
            case "replace":
                return UploadConflictAction.Overwrite;
            case "keep":
                return UploadConflictAction.KeepBoth;
            default:
                return null;
        }
    };

    const onModalClose = () => {
        setOpen(false);

        if (props.onClose)
            props.onClose();
    }

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    const [open, setOpen] = React.useState(false);

    return <Dialog
        key={`conflict-modal`}
        open={open}
        modalType="alert"
        onOpenChange={(event, data) => {

            setOpen(data.open);
            if (data.open == false) {
                if (props.onClose)
                    props.onClose();
            }

        }}>

        <DialogSurface>
            <form onSubmit={handleSubmit(m => onSubmit(m))}>
                <DialogBody style={{ minHeight: "200px" }}>
                    <DialogTitle action={<Button appearance="transparent" onClick={() => { onModalClose(); }} icon={<Dismiss24Filled />} />}>
                        {props.modalTitle ?? "Upload options"}</DialogTitle>
                    <DialogContent>

                        {!props.showExistingDirectoryMessage && <Label id="label8">
                            Some of the files already exists.<br />
                            Do you want to replace the existing files with a new version or keep both files?
                        </Label>}
                        {!!props.showExistingDirectoryMessage && <Label id="label8">
                            The folder already exists in this directory.<br />
                            Do you want to replace the existing files in the folder with a new version or keep both files?
                        </Label>}
                        <RadioGroup aria-labelledby="label8" style={{ marginTop: "var(--spacingVerticalXL)", marginBottom: "var(--spacingVerticalXL)" }}  >
                            <Radio
                                {...register("conflictAction", { required: true })}
                                label="Keep both files"
                                value="keep"
                            />
                            <Radio
                                {...register("conflictAction", { required: true })}
                                label="Replace existing files"
                                value="replace"
                            />
                        </RadioGroup>

                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" appearance="primary" disabled={!!errors.conflictAction}>{props.buttonLabel ?? "Upload"}</Button>
                    </DialogActions>
                </DialogBody>
            </form>
        </DialogSurface>
    </Dialog>;

}