import { toast } from "react-toastify";
import { getImpersonateUserClient, getUserToken } from "./dashboard.service";

export type ImpersonateUserData = {
    displayName: string;
    userPrincipalName: string;
    token: string;
    tokenExpiresAtUtc: Date;
}

class ImpersonateUserService {
    public static UserData: ImpersonateUserData | undefined = undefined;

    public static IsImpersonatingUser = () => {
        return !!ImpersonateUserService.UserData?.displayName;

    }

    public static loadDataFromSession = () => {
        const userDataJson = window.sessionStorage.getItem('s365_impersonate_user');
        const userData = JSON.parse(userDataJson);

        const now = new Date();
        const utcNow = new Date(now.toUTCString());
        if (!userData || userData.tokenExpiresAtUtc < utcNow) {
            ImpersonateUserService.UserData = undefined;
        } else {
            ImpersonateUserService.UserData = userData;
        }
    }
    public static getUserToken = () => {
        ImpersonateUserService.loadDataFromSession();
        return ImpersonateUserService.UserData?.token;
    }

    public static stopImpersonating = () => {
        window.sessionStorage.removeItem('s365_impersonate_user');
        window.location.replace('/');
    }

    public static impersonateUser = async (userId: string) => {
        try {
            const client = getImpersonateUserClient();
            const userDataResp = await client.getImpersonationData(userId);
            if (!!userDataResp && !!userDataResp.userToken) {
                ImpersonateUserService.UserData = {
                    displayName: userDataResp.userDisplayName,
                    userPrincipalName: userDataResp.userPrincipalName,
                    token: userDataResp.userToken,
                    tokenExpiresAtUtc: userDataResp.expiresAtUtc
                };

                const impersonateDataJson = JSON.stringify(ImpersonateUserService.UserData);

                window.sessionStorage.setItem('s365_impersonate_user', impersonateDataJson);

               // toast.success(`Impersonation Successful! You are currently acting on behalf of ${userDataResp.userDisplayName}.`);
            }

        } catch (error) {
            console.log(`An error occurred while trying to impersonate user with id '${userId}'.`, error);
            toast.error("An error occurred while trying to impersonate user.");
        }
    }
}

export default ImpersonateUserService;