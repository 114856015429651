import { TabList, Tab, MenuItem, TableColumnDefinition, createTableColumn, useTableFeatures, TableColumnSizingOptions, useTableColumnSizing_unstable, SpinButton } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell } from "@fluentui/react-components";
import React, { useMemo } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { ContextMenu, ContextMenuType } from "../../files/context-menu/context-menu.component";
import { getSensitivityStudiesClient } from "../../services/sensitivity-studies.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import { JobProgressCount } from "../../components/job-progress-count/job-progress-count.component";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { ExamResultModel } from "../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { getExamsClient } from "../../services/take-home-exams.service";
import { DeleteExamModal } from "../delete-exam-modal.component";
import { ExamStatusComponent } from "../../components/exam-status/exam-status.component";
import { GroupTableResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { getGroupsClient } from "../../services/dashboard.service";
import { GroupDisplay } from "../../components/group-display/group-display.component";

type AllTeacherExamsProps = {

}

export const AllTeacherExams: React.FC<AllTeacherExamsProps> = (props) => {

    const [selectedExam, setSelectedExam] = React.useState<ExamResultModel>();
    const [exams, setExams] = React.useState<ExamResultModel[]>([]);
    const [showDeleteExamModal, setShowDeleteExamModal] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();
    const contextMenuRef = React.createRef<ContextMenuType>();
    const [currentPage, SetCurrentPage] = React.useState<number>(1);
    const [sizePerPage, SetSizePerPage] = React.useState<number>(25);
    const [totalRecords, setTotalRecords] = React.useState<number>(0);
    const [orderByIdDesc, setOrderByIdDesc] = React.useState<boolean>(true);
    const [groups, setGroups] = React.useState<GroupTableResultModel[]>([]);

    React.useEffect(() => {
        getUserOwnedGroups();
    }, []);

    React.useEffect(() => {
        getExams();
    }, [currentPage, orderByIdDesc]);

    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        name: {
            minWidth: 10,
            defaultWidth: 300,
        },
        file: {
            minWidth: 200,
            defaultWidth: 400,
        },
        status: {
            minWidth: 100,
            defaultWidth: 200,
        },
        group: {
            minWidth: 100,
            defaultWidth: 300
        },
        date: {
            minWidth: 100,
            defaultWidth: 150
        },
        startTime: {
            minWidth: 50,
            defaultWidth: 100

        },
        duration: {
            minWidth: 50,
            defaultWidth: 100

        },
        modified: {
            minWidth: 100,
            defaultWidth: 200

        }
    });
    

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "status": return true;
            case "date": return true;
            case "startTime": return true;
            case "duration": return true;
            case "modified": return true;

            default: return false;
        }
    }

    const items = exams ?? [];



    const getColumns = (): TableColumnDefinition<ExamResultModel>[] => [

        createTableColumn<ExamResultModel>({
            columnId: "name",
            renderHeaderCell: () => <>Name</>,
            renderCell: (item: ExamResultModel) => {
                return <span>{item.name}</span>;

            }
        }),
        createTableColumn<ExamResultModel>({
            columnId: "file",
            renderHeaderCell: () => <>File</>,
            renderCell: (item: ExamResultModel) => {

                return <FileDisplayName fileUniqueIdentifier={item.fileDriveId} fileVersionNumber={item.fileVersion ? +item.fileVersion : undefined} />;

            }
        }),
        createTableColumn<ExamResultModel>({
            columnId: "status",
            renderHeaderCell: () => <>Status</>,
            renderCell: (item: ExamResultModel) => {
                return <ExamStatusComponent status={item.status} />;

            }
        }),
        createTableColumn<ExamResultModel>({
            columnId: "group",
            renderHeaderCell: () => <>Group</>,
            renderCell: (item: ExamResultModel) => {
                return <GroupDisplay groupId={item.groupId} />;
            }
        }),
        createTableColumn<ExamResultModel>({
            columnId: "date",
            renderHeaderCell: () => <>Exam date</>,
            renderCell: (item: ExamResultModel) => {

                return <ShowLocalTime date={item.date}  />;

            }
        }),
        createTableColumn<ExamResultModel>({
            columnId: "startTime",
            renderHeaderCell: () => <>Start time</>,
            renderCell: (item: ExamResultModel) => {
                return item.startTime;
            }
        }),
        createTableColumn<ExamResultModel>({
            columnId: "duration",
            renderHeaderCell: () => <>Duration</>,
            renderCell: (item: ExamResultModel) => {

                return item.duration;
            }
        }),
        createTableColumn<ExamResultModel>({
            columnId: "modified",
            renderHeaderCell: () => <>Modified</>,
            renderCell: (item: ExamResultModel) => {

                return <ShowLocalTime date={item.createdAt} multiline />;
            }
        })
    ];

    const columns = useMemo(() => getColumns(), []);

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<ExamResultModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    const getUserOwnedGroups = async () => {
        loadingService.showLoading("Getting user owned groups...", async (hideMessage) => {
            try {
                const client = getGroupsClient();

                const groupsResp = await client.getUserOwnedGroups();

                setGroups(groupsResp ?? []);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get user owned groups.")
            } finally {
                hideMessage();
            }
        });

    }

    const getGroupName = (groupId: string) => {
        const group = groups.find(x => x.id == groupId);
        return group ? group.groupname : "";
    }


    const onShowDetailsClick = () => {
        navigate(`/files/${selectedExam.fileDriveId}/exams/details/${selectedExam?.id}`);
    }
    const getExams = async () => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getExamsClient();
                const skip = (currentPage - 1) * sizePerPage;
                const examsResp = await client.getAllUserExams(skip, sizePerPage, orderByIdDesc);
                setExams(examsResp?.exams ?? []);
                setTotalRecords(examsResp?.totalRecords ?? 0);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting exams.`);
            } finally {
                hideMessage();
            }
        });

    }

    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        if (!selectedExam?.isDeleted) {
            menuItems.push(<MenuItem key="edit" onClick={() => navigate(`/files/${selectedExam?.fileDriveId}/exams/edit/${selectedExam?.id}`)}> Edit</MenuItem>);

            menuItems.push(...[
                <MenuItem key="clone" onClick={() => navigate(`/files/${selectedExam?.fileDriveId}/exams/edit?cloneId=${selectedExam?.id}`)}> Clone</MenuItem>,
                <MenuItem key="delete" onClick={() => setShowDeleteExamModal(true)}> Delete</MenuItem>
            ]);
        }
        menuItems.push(<MenuItem key="details" onClick={() => onShowDetailsClick()} > Details</MenuItem>);

        return menuItems;
    }



    const totalPages = totalRecords > sizePerPage ? Math.ceil(totalRecords / sizePerPage) : 1;

    return <div className="content-wrapper">
        <div className='groups-wrapper__breadcrumbs-wrapper'>
            <Breadcrumbs>               
                <Breadcrumb key={`breadcrumb-applications`}>Applications</Breadcrumb>
                <Breadcrumb key={`breadcrumb-agents`} active={true}>Take-Home Exercise / Exam (THEE)</Breadcrumb>
            </Breadcrumbs>
        </div>

        <div className='s365-table__wrapper'>
            <Table sortable ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
                <TableHeader>
                    <TableRow>

                        {columns.map((column) => (
                            <TableHeaderCell
                                onDragStart={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                                style={{ cursor: (column.columnId as string) == "studyId" ? "pointer" : "default" }}
                                sortable={(column.columnId as string) == "studyId"}
                                sortDirection={(column.columnId as string) == "studyId" ? (orderByIdDesc ? "descending" : "ascending") : undefined}
                                onClick={() => { if ((column.columnId as string) == "studyId") { setOrderByIdDesc(!orderByIdDesc); } }}
                                key={column.columnId}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}

                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}

                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={7} loadingMessage="Loading..."
                    itemCount={exams ? exams.length : 0}
                    noItemsMessage="No items found.">
                    {exams && exams.length > 0 && exams.map((item) => {
                        return <TableRow
                            key={`study-${item.id}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/files/${item.fileDriveId}/exams/details/${item.id}`)}
                            className={selectedExam && selectedExam.id == item.id ? "table-row-selected" : undefined}
                            onContextMenu={(ev) => { ev.preventDefault(); setSelectedExam(item); contextMenuRef.current?.showMenu(ev); }}>
                            {columns.map((column) => (
                                <TableCell
                                    className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}

                        </TableRow>
                    })}
                </TableBodyWithLoading>
            </Table>
            <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me page-item'}
                breakLinkClassName={'page-link'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}

                onPageChange={(page) => { console.log("Selected page:", page); SetCurrentPage(page.selected + 1); }}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </div>


        <ContextMenu ref={contextMenuRef} >
            {getContextItems()}
        </ContextMenu>
        <DeleteExamModal
            examName={selectedExam?.name}
            isOpened={showDeleteExamModal}
            examId={selectedExam?.id}
            onSuccess={() => {
                setShowDeleteExamModal(false);
                setTimeout(() => { setSelectedExam(undefined); }, 300);
                getExams();
            }}
            onClose={() => { setShowDeleteExamModal(false); }}

        />

    </div>
}

