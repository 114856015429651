import { DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Dialog, Label, DialogActions, Field, Input } from "@fluentui/react-components";
import { Dismiss24Filled, EditRegular } from "@fluentui/react-icons";
import React from "react";
import { useForm } from "react-hook-form";
import { GLOBAL_STYLES } from "../../../styles";
import { CreateApplicationKeyPostModel } from "../../../swagger-clients/dispatcher-next-api-clients.service";


type CreateApplicationKeyModalProps = {
    isOpened?: boolean;
    onSubmit: (description: string) => void;
    onClose: () => void;
}

export const CreateApplicationKeyModal: React.FC<CreateApplicationKeyModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);

    const { register, handleSubmit, watch, setError, clearErrors, setValue, formState: { errors, isSubmitted }, reset } = useForm<CreateApplicationKeyPostModel>();
    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);
    const onModalClose = () => {
        setOpen(false);
        reset();
        if (props.onClose) {
            props.onClose();
        }

    }

    const onSubmit = (data: CreateApplicationKeyPostModel) => {
        props.onSubmit(data.description);

    }
    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}><DialogSurface style={{ maxWidth: "650px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogBody style={{ maxWidth: "inherit" }}>

                    <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                        <span>Add API key</span>
                    </DialogTitle>
                    <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                        <div className="input-from">
                            <Label className="input-form-label">Description</Label>
                            <Field
                                className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                                validationMessage={errors && errors.description ? errors.description.message : undefined}
                                validationState={errors && errors.description ? "error" : "none"}
                            >  <Input
                                    {...register("description", { required: { value: true, message: "Description is required." } })}
                                />
                            </Field>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button appearance="primary" type="submit">Create</Button>
                        <Button appearance="secondary" onClick={onModalClose} type="button">Cancel</Button>
                    </DialogActions>

                </DialogBody>
            </form>
        </DialogSurface>
    </Dialog>


}