import React from "react";
import { SeparationMethodOption, SpecificationProperty } from "../../swagger-clients/ai-for-pfd-clients.service";
import { GetSpecificationPropertyName, GetSpecificationPropertyUnit } from "../edit-experiment/edit-experiment.helpers";
import { tokens } from "@fluentui/react-components";

type ResultInputParameterProps = {
    separationMethod: SeparationMethodOption;
    parameter: string;
    unitInOneLine?: boolean;
    inline?: boolean;
    hideUnit?: boolean;
}

export const ResultInputParameter: React.FC<ResultInputParameterProps> = (props) => {

    const [component, setComponent] = React.useState<string>();
    const [parameter, setParameter] = React.useState<string>();
    const [unit, setUnit] = React.useState<string | JSX.Element>();

    React.useEffect(() => {
        const inputParamParts = InputParameters.filter(x => props.parameter.endsWith(x));

        if (!inputParamParts || inputParamParts.length !== 1) {
            console.log(`Parameter not found for result field '${props.parameter}'`);
        }

        let componentLocal = "";
        let parameterLocal = "";
        let unitLocal: string | JSX.Element = "";
        const splitedParam = props.parameter.split(inputParamParts[0]);

        const specification = getSpecificationPropertyFromString(inputParamParts[0]);
        parameterLocal = GetSpecificationPropertyName(specification, props.separationMethod);
        setParameter(parameterLocal);

        unitLocal = getUnit(inputParamParts[0], props.unitInOneLine);
        setUnit(unitLocal);

        componentLocal = splitedParam[0].replaceAll("_", " ");

        componentLocal = componentLocal.charAt(0).toUpperCase() + componentLocal.slice(1);
        setComponent(componentLocal);
    }, []);


    return <div style={{ display: "flex", flexDirection: !props.inline ? "column" : "row", wordBreak: "break-word" }}>
        {component && <span>
            <span style={{ height: "0.8rem", color: tokens.colorNeutralForeground3 }}>U:</span> {component}
        </span>}

        {!!parameter && <span>
            <span style={{ height: "0.8rem", color: tokens.colorNeutralForeground3 }}>P:</span> {parameter}
        </span>}
        {!props.hideUnit && <span style={{ color: tokens.colorNeutralForeground3 }}>({!!unit ? unit : "-"})</span>}
    </div>;
}

const InputParameters = [
    "product_flow_rate",
    "condenser_pressure",
    "gas_pressure",
    "heater_temperature",
    "n_stages",
    "n_stages_bottom_to_feed",
    "n_stages_feed_to_top",
    "reflux_ratio",
    "solvent_flowrate",
    "solvent_temperature",
    "swing_pressure",
    "n_stages_entrainer_to_top",
    "n_stages_feed_to_entrainer"
];

const getSpecificationPropertyFromString = (param: string) => {
    switch (param) {
        case "product_flow_rate":
            return SpecificationProperty.BottomProductFlowrate;
        case "condenser_pressure":
            return SpecificationProperty.CondenserPressure;
        case "gas_pressure":
            return SpecificationProperty.GasPressure;
        case "heater_temperature":
            return SpecificationProperty.HeaterTemperatures;
        case "n_stages":
            return SpecificationProperty.NumberOfStages;
        case "n_stages_bottom_to_feed":
            return SpecificationProperty.NumberOfStagesBottomToFeed;
        case "n_stages_feed_to_top":
            return SpecificationProperty.NumberOfStagesFeedToTop;
        case "reflux_ratio":
            return SpecificationProperty.RefluxRation;
        case "solvent_flowrate":
            return SpecificationProperty.SolventFlowrate;
        case "solvent_temperature":
            return SpecificationProperty.SolventTemperature;
        case "swing_pressure":
            return SpecificationProperty.SwingPressure;
        case "n_stages_entrainer_to_top":
            return SpecificationProperty.NumberOfStagesEntrainerToTop;
        case "n_stages_feed_to_entrainer":
            return SpecificationProperty.NumberOfStagesFeedToEntrainer;
        default:
            throw `Missing mapping for parameter '${param}'`;


    }
}

const getUnit = (param: string, unitInOneLine?: boolean) => {
    const specification = getSpecificationPropertyFromString(param);
    return GetSpecificationPropertyUnit(specification, unitInOneLine);
}
