import { FlowsheetObjectModel, FlowsheetObjectsResponseModel, FlowsheetObjectType, FlowsheetParameterModel } from "../../swagger-clients/dispatcher-next-api-clients.service";

const getFlowsheetObjectByParameter = (parameter: string, response: FlowsheetObjectsResponseModel) => 
{
    //let splitted = parameter.split('_');
    let splitted= [...parameter.matchAll(/(\S{1}\{[^\}]+})/ig) as any].map(o => o[0]);
    var result:FlowsheetObjectModel[]|FlowsheetParameterModel[] = [];

    // Find unit ops, streams and components
    for (let o of splitted) 
    {
        let flowsheetObjectMatch = o.match(/(u|s|c)\{([^\}]+)/);

        if (flowsheetObjectMatch) {
            let type = flowsheetObjectMatch[1];
            let uniqueId = flowsheetObjectMatch[2];
            let objectType = getFlowsheetObjectType(type);

            let foundObjects = response.flowsheetObjects.filter(f => f.objectType == objectType && f.uniqueIdentifier == uniqueId);
            if (!foundObjects || !foundObjects.length)
                throw `Flowsheet object of type '${type}' with unique id '${uniqueId}' not found.`;
            if (foundObjects.length > 1)
                throw `Found ${foundObjects.length} of type '${type}' with unique id '${uniqueId}'.`;

            result.push(foundObjects[0]);
        }
    }

    // Find parameter
    let foundParams = response.flowsheetParameters.filter(p => p.parameter == parameter);
    if (!foundParams || !foundParams.length)
        throw `Value for parameter '${parameter}' not found.`;
    if (foundParams.length > 1)
        throw `Found ${foundParams.length} values for parameter '${parameter}'.`;

    result.push(foundParams[0]);

    return result;
}

const getFlowsheetObjectType = (letter: string):FlowsheetObjectType => {
    switch(letter.toLowerCase()) {
        case "u":
            return FlowsheetObjectType.UnitOp;

        case "s":
            return FlowsheetObjectType.Stream;

        case "c":
            return FlowsheetObjectType.Component;

        default:
            throw `Can't convert '${letter}' to FlowsheetObjectType.`;
    }
}

export default getFlowsheetObjectByParameter;