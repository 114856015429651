import * as React from "react";
import { useTimer } from 'react-timer-hook';

interface ICountdownTimerProps {
    expiryDate: Date;
    onExpire?();
}

const CountdownTimer: React.FunctionComponent<ICountdownTimerProps> = (props) => {

    const {
        seconds,
        minutes,
        hours,
        days
    } = useTimer({ expiryTimestamp: props.expiryDate, onExpire: props.onExpire });
    
    if (!props.expiryDate) {
        return null;
    }

   


    return <div style={{ display: "flex", flexDirection: "row" }}>
        {days && days > 0 ? <span style={{ paddingRight: "5px" }}>{days} days</span> : null}

        {hours && (hours > 0 || days > 0) ? <span style={{ paddingRight: "5px" }}>{hours} hours</span> :
            days ? <span style={{ paddingRight: "5px" }}>0 hours</span> : null}

        {minutes && (minutes > 0 || hours > 0 || days > 0) ? <span style={{ paddingRight: "5px" }}>{minutes} minutes</span>
            : (hours || days) ? <span style={{ paddingRight: "5px" }}>0 minutes</span> : null}

        {seconds ? <span>{seconds} seconds</span> :
            (minutes || hours || days) ? <span>0 seconds</span> : <span>0 seconds</span> }
       
    </div>;
}

export default CountdownTimer