import { settings } from "../App";
import { AnalyzeFileClient, ExperimentsClient, ExportDataClient, ScenarioCountClient, ScenarioProcessingClient, ScenariosClient } from "../swagger-clients/excel-runner-api-clients.service";
import { AxiosInstance } from "./dashboard.service";




export const getAnalyzeFileClient = () => {
    return new AnalyzeFileClient(settings?.excelRunnerServiceUrl, AxiosInstance);
}
export const getScenariosClient = () => {
    return new ScenariosClient(settings?.excelRunnerServiceUrl, AxiosInstance);
}

export const getScenarioProcessingClient = () => {
    return new ScenarioProcessingClient(settings?.excelRunnerServiceUrl, AxiosInstance);
}

export const getScenarioCountClient = () => {
    return new ScenarioCountClient(settings?.excelRunnerServiceUrl, AxiosInstance);
}

export const getDoeExperimentsClient = () => {
    return new ExperimentsClient(settings?.excelRunnerServiceUrl, AxiosInstance);
}

export const getDoeExportDataClient = () => {
    return new ExportDataClient(settings?.excelRunnerServiceUrl, AxiosInstance);
}




