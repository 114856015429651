
import { MenuItem, TableColumnDefinition, createTableColumn, useTableFeatures, useTableColumnSizing_unstable, TableColumnSizingOptions, ToolbarButton, Toolbar } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell } from "@fluentui/react-components";
import React from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { ContextMenu, ContextMenuType } from "../../files/context-menu/context-menu.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import { AddRegular, CopyRegular, DeleteRegular, DocumentTextRegular } from "@fluentui/react-icons";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { getFiltersClient } from "../../services/dashboard.service";
import { toast } from "react-toastify";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "s365-dashboard-v2-file-picker/build/src/breadcrumbs/breadcrumbs";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { FilterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { CreateCopilotExperimentWizardModal, WizardApplication } from "../../components/create-experiment-wizard/create-experiment-wizard.modal";
import { HubConnection } from "@microsoft/signalr";

type AllUserWebFormsProps = {
    hubConnection: HubConnection;
}

export const AllUserWebForms: React.FC<AllUserWebFormsProps> = (props) => {

    const [selectedFilter, setSelectedFilter] = React.useState<FilterResultModel>();
    const [filters, setFilters] = React.useState<FilterResultModel[]>([]);
    const [showDeleteFilterModal, setShowDeleteFilterModal] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();
    const contextMenuRef = React.createRef<ContextMenuType>();
    const [currentPage, SetCurrentPage] = React.useState<number>(1);
    const [sizePerPage, SetSizePerPage] = React.useState<number>(25);
    const [totalRecords, setTotalRecords] = React.useState<number>(0);
    const [orderByIdDesc, setOrderByIdDesc] = React.useState<boolean>(true);
    const [showCreateNewWebFormWizardModal, setShowCreateNewWebFormWizardModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        getFiltersWithWebForm();
    }, [currentPage, orderByIdDesc]);


    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        filterId: {
            defaultWidth: 100,
            minWidth: 50,
        },
        name: {
            minWidth: 100,
            defaultWidth: 300,
        },
        file: {
            minWidth: 200,
            defaultWidth: 400,
        },
        created: {
            minWidth: 100,
            defaultWidth: 100
        }
    });


    const items = filters ?? [];
    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<FilterResultModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    const getFiltersWithWebForm = async () => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getFiltersClient();

                const filtersResp = await client.getAllFiltersWithWebForms((currentPage - 1) * sizePerPage, sizePerPage, orderByIdDesc);
                setFilters(filtersResp?.filters ?? []);
                setTotalRecords(filtersResp?.totalRecords ?? 0);

            } catch (error) {
                processServerError(error, undefined, `An error occcurred while getting filters with web forms.`);
            } finally {
                hideMessage();
            }
        });

    }

    const onDeleteFilterClick = () => {
        loadingService.showLoading("Deleting filter...", async (hideMessage) => {
            try {


                const client = getFiltersClient();


                await client.deleteFilter(selectedFilter.id);
                toast.success("Successfully deleted filter.");
                setSelectedFilter(undefined);
                setShowDeleteFilterModal(false);
                getFiltersWithWebForm();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to delete filter.");
            } finally {
                hideMessage();
            }
        });
    }

    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        menuItems.push(<MenuItem icon={<DocumentTextRegular />} key="details"
            onClick={() => navigate(`/files/${selectedFilter?.fileUniqueIdentifier}/filters/${selectedFilter?.id}/details`)} > Details</MenuItem>);
        menuItems.push(<MenuItem icon={<CopyRegular />} key="clone"
            onClick={() => navigate(`/files/${selectedFilter?.fileUniqueIdentifier}/filters/edit?cloneId=${selectedFilter?.id}`)}> Clone</MenuItem>);

        if (!selectedFilter?.isDeleted) {
            menuItems.push(...[
                <MenuItem icon={<DeleteRegular />} key="delete" onClick={() => { setShowDeleteFilterModal(true); }}> Delete</MenuItem>
            ]);
        }

        return menuItems;
    }

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "filterId": return true;
            case "analysisStatus": return true;
            case "processingStatus": return true;
            case "jobs": return true;
            case "created": return true;

            default: return false;
        }
    }

    const totalPages = totalRecords > sizePerPage ? Math.ceil(totalRecords / sizePerPage) : 1;

    return <div className="content-wrapper">

        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton icon={<AddRegular />} appearance="primary" onClick={() => { setShowCreateNewWebFormWizardModal(true); }}>
                    New Web Form
                </ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />
            </Toolbar>
        </div>

        <div className='groups-wrapper__breadcrumbs-wrapper'>
            <Breadcrumbs>               
                <Breadcrumb key={`breadcrumb-applications`}>Applications</Breadcrumb>
                <Breadcrumb key={`breadcrumb-web-form`} active={true}>Web Form</Breadcrumb>
            </Breadcrumbs>
        </div>

        <div className='s365-table__wrapper'>
            <Table sortable ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
                <TableHeader>
                    <TableRow>
                        {columns.map((column) => (
                            <TableHeaderCell
                                onDragStart={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                                style={{ cursor: (column.columnId as string) == "filterId" ? "pointer" : "default" }}
                                sortable={(column.columnId as string) == "filterId"}
                                sortDirection={(column.columnId as string) == "filterId" ? (orderByIdDesc ? "descending" : "ascending") : undefined}
                                onClick={() => { if ((column.columnId as string) == "filterId") { setOrderByIdDesc(!orderByIdDesc); } }}
                                key={column.columnId}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}

                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}

                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={7} loadingMessage="Loading..."
                    itemCount={filters ? filters.length : 0}
                    noItemsMessage="No items found.">
                    {filters && filters.length > 0 && filters.map((item) => {
                        return <TableRow
                            key={`study-${item.id}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/files/${item.fileUniqueIdentifier}/filters/${item.id}/details`)}
                            className={selectedFilter && selectedFilter.id == item.id ? "table-row-selected" : undefined}
                            onContextMenu={(ev) => { ev.preventDefault(); setSelectedFilter(item); contextMenuRef.current?.showMenu(ev); }}>
                            {columns.map((column) => (
                                <TableCell
                                    className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}

                        </TableRow>
                    })}
                </TableBodyWithLoading>
            </Table>
            <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me page-item'}
                breakLinkClassName={'page-link'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}

                onPageChange={(page) => { console.log("Selected page:", page); SetCurrentPage(page.selected + 1); }}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </div>


        <ContextMenu ref={contextMenuRef} >
            {getContextItems()}
        </ContextMenu>
        {showDeleteFilterModal && <ConfirmationDialog
            isOpened={showDeleteFilterModal}
            title="Delete Filter"
            confirmLabel="Delete"
            subText={
                `Are you sure you want to delete filter '${selectedFilter?.name}'? `}
            onConfirm={() => {
                onDeleteFilterClick();
            }}
            onClose={() => { setShowDeleteFilterModal(false); }}
        />}
        {showCreateNewWebFormWizardModal &&
            <CreateCopilotExperimentWizardModal
                title="Create Web Form"
                hubConnection={props.hubConnection}
                application={WizardApplication.WebForm}
                isOpened={showCreateNewWebFormWizardModal}
                onClose={() => { setShowCreateNewWebFormWizardModal(false); }}
            />}
    </div>
}

const columns: TableColumnDefinition<FilterResultModel>[] = [
    createTableColumn<FilterResultModel>({
        columnId: "filterId",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>ID</div>,
        renderCell: (item: FilterResultModel) => {

            return <div style={{ textAlign: "center" }}>{item.id}</div>;

        }
    }),
    createTableColumn<FilterResultModel>({
        columnId: "name",
        renderHeaderCell: () => <>Name</>,
        renderCell: (item: FilterResultModel) => {
            return item.name;
        }
    }),
    createTableColumn<FilterResultModel>({
        columnId: "file",
        renderHeaderCell: () => <>File</>,
        renderCell: (item: FilterResultModel) => {

            return <FileDisplayName fileUniqueIdentifier={item.fileUniqueIdentifier} fileVersionNumber={item.fileVersionNumber ? +item.fileVersionNumber : undefined} />;

        }
    }),

    createTableColumn<FilterResultModel>({
        columnId: "created",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>Created</div>,
        renderCell: (item: FilterResultModel) => {

            return <div style={{ textAlign: "center" }}><ShowLocalTime date={item.createdAt} /></div>;
        }
    }),

];