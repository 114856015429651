import { Toolbar, ToolbarButton, TabList, Tab, MenuItem, TableColumnDefinition, createTableColumn, TableColumnSizingOptions, useTableFeatures, useTableColumnSizing_unstable } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell } from "@fluentui/react-components";
import { Add20Regular, ArrowResetRegular, CopyRegular, DeleteRegular,  EditRegular } from "@fluentui/react-icons";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../components/breadcrumbs/breadcrumbs";
import { TableBodyWithLoading } from "../components/table-body-with-loading/table-body-with-loading.component";
import { ContextMenu, ContextMenuType } from "../files/context-menu/context-menu.component";
import { getFilesClient, getGroupsClient } from "../services/dashboard.service";
import { getExamsClient } from "../services/take-home-exams.service";
import { BreadcrumbItem, FileModel, GroupTableResultModel } from "../swagger-clients/s365-dashboard-v2-api-clients.service";
import { _copyAndSort } from "../utils/helpers/array.helpers";
import { processServerError } from "../utils/helpers/error.helper";
import { LoadingIndicator, useLoading } from "../utils/loading-indicator.component";
import { IExam } from "./shared/exams.models";
import "./exams.styless.scss";
import ShowLocalTime from "../components/show-local-time/show-local-time.component";
import { toast } from "react-toastify";
import { ExamStatusComponent } from "../components/exam-status/exam-status.component";
import { ExamStatus } from "../swagger-clients/s365-take-home-exams-v2-clients.service";
import { DeleteExamModal } from "./delete-exam-modal.component";
import { GroupDisplay } from "../components/group-display/group-display.component";

type ExamsProps = {
};
export type ExamsRouteParams = {
    uniquefileId: string,
    filterId?: string,
    examId?: string
};

export const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];

export const Exams: React.FC<ExamsProps> = (props) => {
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const routeParams = useParams<ExamsRouteParams>();
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedExam, setSelectedExam] = React.useState<IExam>();
    const [groups, setGroups] = React.useState<GroupTableResultModel[]>([]);
    const [exams, setExams] = React.useState<IExam[]>([]);
    const [selectedTab, setSelectedTab] = React.useState<string>("active");
    const [isLoading, loadingService] = useLoading();
    const [showDeleteExamDialog, setShowDeleteExamDialog] = React.useState<boolean>(false);
    const navigate = useNavigate();



    useEffect(() => {
        initializePage();
    }, []);

    const initializePage = async () => {
        const resp = await getFile();
        await getExams();
    }



    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        name: {
            minWidth: 10,
            defaultWidth: 300,
        },
        status: {
            minWidth: 100,
            defaultWidth: 200,
        },
        group: {
            minWidth: 100,
            defaultWidth: 300
        },
        date: {
            minWidth: 100,
            defaultWidth: 150
        },
        startTime: {
            minWidth: 50,
            defaultWidth: 100

        },
        duration: {
            minWidth: 50,
            defaultWidth: 100

        },
        modified: {
            minWidth: 100,
            defaultWidth: 200

        }
    });

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "status": return true;
            case "date": return true;
            case "startTime": return true;
            case "duration": return true;
            case "modified": return true;

            default: return false;
        }
    }

    const getFile = async () => {
        loadingService.showLoading("Getting file...", async (hideMessage) => {
            try {
                const client = getFilesClient();
                const resp = await client.getFileLatest(routeParams.uniquefileId!, true);
                if (resp) {
                    setSelectedFile(resp.file);
                    setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
                }
                return resp;

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting file information.");
            } finally {
                hideMessage();
            }
        });

    }
    const getExams = async () => {
        loadingService.showLoading("Getting exams...", async (hideMessage) => {
            try {
                const client = getExamsClient();
                let examsResponse = await client.getExams(routeParams.uniquefileId);
                let examsMapped: IExam[] = examsResponse.map(examResp => ({ ...examResp } as unknown as IExam));
                examsMapped = _copyAndSort<IExam>(examsMapped, "createdAt", true);
                console.log("examsResponse exams", examsResponse, examsMapped);
                setExams(examsMapped);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get exams.");
            } finally {
                hideMessage();
            }
        });

    }



    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }

    const onShowDetailsClick = (examId: number) => {
        navigate(`/files/${selectedFile?.uniqueIdentifier}/exams/details/${examId}`);
    }

    const onRestoreExamClick = async () => {
        try {
            const client = getExamsClient();
            await client.restoreExam(selectedExam.id);
            toast.success("Successfully restored Take-Home Exercise/Exam.");
            setSelectedExam(undefined);
            getExams();

        } catch (error) {

            toast.error("An error occurred while trying to restoring Take-Home Exercise/Exam.");
            console.log("An error occurred while trying to restoring Take-Home Exercise/Exam.", error);
        }
    }


    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        if (!selectedExam) {
            return [];
        }
        if (!selectedExam.isDeleted) {


            if (selectedExam && selectedExam.status == ExamStatus.Draft) {
                menuItems.push(<MenuItem icon={<EditRegular />} key="edit" onClick={() => navigate(`/files/${selectedFile?.uniqueIdentifier}/exams/edit/${selectedExam?.id}`)}> Edit</MenuItem>);
            }
            menuItems.push(...[
                <MenuItem icon={<CopyRegular />} key="clone" onClick={() => navigate(`/files/${selectedFile?.uniqueIdentifier}/exams/edit?cloneId=${selectedExam?.id}`)}> Clone</MenuItem>,
                <MenuItem icon={<DeleteRegular />} key="delete" onClick={() => { setShowDeleteExamDialog(true); }}> Archive</MenuItem>
            ]);
        } else {
            menuItems.push(<MenuItem key="restore" icon={<ArrowResetRegular />} onClick={() => { onRestoreExamClick(); }}> Restore</MenuItem >);
        }



        return menuItems;
    }


    const getColumns = (): TableColumnDefinition<IExam>[] => [

        createTableColumn<IExam>({
            columnId: "name",
            renderHeaderCell: () => <>Name</>,
            renderCell: (item: IExam) => {
                return <span>{item.name}</span>;

            }
        }),
        createTableColumn<IExam>({
            columnId: "status",
            renderHeaderCell: () => <>Status</>,
            renderCell: (item: IExam) => {
                return <ExamStatusComponent status={item.status} />;

            }
        }),
        createTableColumn<IExam>({
            columnId: "group",
            renderHeaderCell: () => <>Group</>,
            renderCell: (item: IExam) => {
                return <GroupDisplay groupId={item.groupId} />;
            }
        }),
        createTableColumn<IExam>({
            columnId: "date",
            renderHeaderCell: () => <>Exam date</>,
            renderCell: (item: IExam) => {

                return <ShowLocalTime date={item.date}  />;

            }
        }),
        createTableColumn<IExam>({
            columnId: "startTime",
            renderHeaderCell: () => <>Start time</>,
            renderCell: (item: IExam) => {
                return item.startTime;
            }
        }),
        createTableColumn<IExam>({
            columnId: "duration",
            renderHeaderCell: () => <>Duration</>,
            renderCell: (item: IExam) => {

                return item.duration;
            }
        }),
        createTableColumn<IExam>({
            columnId: "modified",
            renderHeaderCell: () => <>Modified</>,
            renderCell: (item: IExam) => {

                return <ShowLocalTime date={item.createdAt} multiline />;
            }
        })
    ];

    const columns = useMemo(() => getColumns(), []);

    const items = selectedTab == "active" ? exams.filter(exam => !exam.isDeleted) : exams.filter(exam => !!exam.isDeleted);
    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<IExam>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    const contextMenuRef = React.createRef<ContextMenuType>();


    return <div className='content-wrapper'>
        <div className="exams-wrapper">
            <div className='toolbar__wrapper'>
                <Toolbar>
                    <ToolbarButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/exams/edit`)}
                        appearance='primary'
                        icon={<Add20Regular />}>New Exam</ToolbarButton>
                    <LoadingIndicator loadingService={loadingService} />
                </Toolbar>
            </div>

            <div className='exams-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>
                    {breadcrumbs.map((item: BreadcrumbItem) => {
                        return <Breadcrumb
                            key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                            onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                    })}
                    {selectedFile &&
                        <Breadcrumb
                            key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                            onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/exams`)}
                        >{selectedFile.name}</Breadcrumb>}
                    <Breadcrumb key={`breadcrumb-exams`} active={true}>Exams</Breadcrumb>

                </Breadcrumbs>
            </div>



            <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { contextMenuRef.current?.hideMenu(); setSelectedTab(data.value as string); setSelectedExam(undefined); }}>
                <Tab key="active" value="active" >Active</Tab>
                <Tab key="deleted" value="deleted">Archived</Tab>
            </TabList>
            <div className="tab-content">
                <div className='exams-table__wrapper'>
                    <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
                        <TableHeader>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell
                                        onDragStart={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        className={`${isColumnCentered(column.columnId as string) ? 'column--center' : ''} exams-table__cell--bold`}
                                        key={column.columnId}
                                        {...columnSizing_unstable.getTableHeaderCellProps(
                                            column.columnId
                                        )}
                                    >
                                        {column.renderHeaderCell()}
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBodyWithLoading isLoading={isLoading}
                            columnCount={7} loadingMessage="Loading..."
                            itemCount={items ? items.length : 0}
                            noItemsMessage="No items found.">
                            {items && items.length > 0 && items.map((item) => {
                                return <TableRow
                                    key={`exam-${item.id}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { setSelectedExam(item); onShowDetailsClick(item.id!); }}
                                    className={selectedExam && selectedExam.id == item.id ? "table-row-selected" : undefined}
                                    onContextMenu={(ev) => { ev.preventDefault(); setSelectedExam(item); contextMenuRef.current?.showMenu(ev); }}>
                                    {columns.map((column) => (
                                        <TableCell
                                            className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                            {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                        >
                                            {column.renderCell(item)}
                                        </TableCell>
                                    ))}

                                </TableRow>
                            })}
                        </TableBodyWithLoading>
                    </Table> </div>


            </div>

        </div>
        <ContextMenu ref={contextMenuRef} hasIcons>
            {getContextItems()}
        </ContextMenu>

        <DeleteExamModal
            examName={selectedExam?.name}
            isOpened={showDeleteExamDialog}
            examId={selectedExam?.id}
            onSuccess={() => {
                setShowDeleteExamDialog(false);
                setTimeout(() => { setSelectedExam(undefined); }, 300);
                getExams();
            }}
            onClose={() => { setShowDeleteExamDialog(false); }}

        />

    </div >

}


