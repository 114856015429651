import { Checkbox } from "@fluentui/react-components";
import React from "react";
import { SeparationMethodOption } from "../../../swagger-clients/ai-for-pfd-clients.service";
import { GetSeparationMethodName } from "../edit-experiment.helpers";
import { IExperimentValidation } from "../edit-experiment.validation";
import { Alert } from "@fluentui/react-components/unstable";
import { generalSettings } from "../../../App";

type ExperimentMethodTabProps = {
    selected?: SeparationMethodOption;
    onChange?: (method?: SeparationMethodOption) => void;
    isDetails?: boolean;
    isValid?: boolean;
    validationMessage?: string;
    isLoading?: boolean;
}

export const ExperimentMethodTab: React.FC<ExperimentMethodTabProps> = (props) => {

    const onSelectionChange = (isChecked: boolean, method: SeparationMethodOption) => {
        if (!isChecked) {
            props.onChange(undefined);
        } else {
            props.onChange(method);
        }
    }
    const isDistillationChecked = () => {
        return !!props.selected &&
            (props.selected == SeparationMethodOption.IdealMixtureDistillation
                || props.selected == SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation
                || props.selected == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer);
    }


    return <div style={{ display: "flex", flexDirection: "column" }}>
        {!props.isValid && !props.isDetails &&
            <Alert intent="error" style={{ minHeight: "30px", marginBottom: "var(--spacingVerticalMNudge)" }}>
                {props.validationMessage}
            </Alert>
        }
        <Checkbox disabled={props.isLoading} label="Distillation" checked={isDistillationChecked()}  style={{ cursor: "default" }}/>
        <div style={{ paddingLeft: "var(--spacingHorizontalL)", display: "flex", flexDirection: "column" }}>
            <Checkbox
                style={{ cursor: props.isDetails ? "default" : "pointer" }}
                disabled={props.isLoading}
                label={GetSeparationMethodName(SeparationMethodOption.IdealMixtureDistillation)}
                checked={props.selected == SeparationMethodOption.IdealMixtureDistillation}
                onChange={(ev, data) => { if (!props.isDetails) onSelectionChange(!!data.checked, SeparationMethodOption.IdealMixtureDistillation) }} />
            <Checkbox 
             style={{ cursor: props.isDetails ? "default" : "pointer" }}
            disabled={props.isLoading}
                label={GetSeparationMethodName(SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation)}
                checked={props.selected == SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation}
                onChange={(ev, data) => { if (!props.isDetails) onSelectionChange(!!data.checked, SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation) }} />
         
            <Checkbox
             style={{ cursor: props.isDetails ? "default" : "pointer" }}
            disabled={props.isLoading}
                label={GetSeparationMethodName(SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer)}
                checked={props.selected == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer}
                onChange={(ev, data) => { if (!props.isDetails) onSelectionChange(!!data.checked, SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer) }} />
        </div>
        <Checkbox 
         style={{ cursor: props.isDetails ? "default" : "pointer" }}
        disabled={props.isLoading} label={GetSeparationMethodName(SeparationMethodOption.Absorption)} checked={props.selected == SeparationMethodOption.Absorption}
            onChange={(ev, data) => { if (!props.isDetails) onSelectionChange(!!data.checked, SeparationMethodOption.Absorption) }} />
    </div>
}