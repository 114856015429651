
import React, { useState } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { useNavigate, useParams } from 'react-router-dom';
import { FilesTable, FilesTableSort } from '../../files/files-table/files-table.component';
import { FileTableColumn, FileTableItem } from '../../files/models/file-table.models';
import { SidePanel } from '../../files/side-panel/side-panel';
import { ParentPathLink } from '../../files/parent-path-link/parent-path-link.component';
import { TagOption } from '../../files/tag-picker/tag-picker.component';
import { getDashboardSharingClient, getFilesClient, getTagsClient } from '../../services/dashboard.service';
import { BreadcrumbItem, FileModel, FileSortFields, FileSystemEntityType, ShareDefinitionSourceType } from '../../swagger-clients/s365-dashboard-v2-api-clients.service';
import { processServerError } from '../../utils/helpers/error.helper';
import { useLoading } from '../../utils/loading-indicator.component';
import TimeAgo from 'react-timeago';
import ShowLocalTime from '../../components/show-local-time/show-local-time.component';
import { MenuItem, createTableColumn } from '@fluentui/react-components';
import { DocumentTextRegular, PeopleAddRegular } from '@fluentui/react-icons';
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from 's365-dashboard-v2-file-picker';
import { ShareFileModal } from '../../files/share/share-file-modal.component';
import SharedByMeImage from '../../assets/SharedByMe.png';

type SharedByMeFilesPageProps = {
    hubConnection?: HubConnection
}

type ShareByMeRouteParams = {
    parentSourceId?: string;
}
const emptyBreadcrumbs = [{ name: "Shared by me" } as BreadcrumbItem];

function SharedByMeFilesPage(props: SharedByMeFilesPageProps) {

    const [files, setFiles] = React.useState<FileTableItem[]>([]);
    const [showTagInputs, setShowTagInputs] = useState<{ [key: string]: boolean }>({});
    const [selectedItem, setSelectedItem] = React.useState<FileTableItem>();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const [fileTagDefaultOptions, setFileTagDefaultOptions] = React.useState<TagOption[]>([]);
    const [commentValidationState, setCommentValidationState] = React.useState<'error' | 'warning' | 'success'>();
    const [commentValidationMessage, setCommentValidationMessage] = React.useState<string>();
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [showShareModal, setShowShareModal] = React.useState<boolean>(false);
    const [sortBy, setSortBy] = React.useState<FilesTableSort>({ field: FileSortFields.Name, isDescending: false });

    const routeParams = useParams<ShareByMeRouteParams>();
    const navigate = useNavigate();

    React.useEffect(() => {
        getFilesWithLoading();

    }, [routeParams.parentSourceId, sortBy]);

    React.useEffect(() => {
        if (commentValidationMessage) {
            setCommentValidationMessage(undefined);
        }
        if (commentValidationState) {
            setCommentValidationState(undefined);
        }
    }, [selectedItem]);



    const getFilesWithLoading = async () => {
        loadingService.showLoading("Loading...", (hideMessage) => {
            getFiles().finally(() => hideMessage());
        })
    }

    const getFiles = async () => {
        loadingService.showLoading("Loading files...", async (hideMessage) => {
            try {


                const client = getDashboardSharingClient();
                const resp = await client.getSharedByMeDocuments(routeParams.parentSourceId ?? "", sortBy.field, sortBy.isDescending);

                if (resp) {
                    setFiles(resp?.files ?? []);
                    setBreadcrumbs(resp?.breadcrumbItems ? [...emptyBreadcrumbs, ...resp.breadcrumbItems] : emptyBreadcrumbs);
                    if (selectedItem) {
                        // selected item is tracked in different object so it needs to be updated
                        const updatedSelectedItem = resp?.files.find(x => x.id == selectedItem.id && x.type == selectedItem.type);
                        console.log("updatedSelectedItem", updatedSelectedItem);
                        setSelectedItem({ ...updatedSelectedItem } as FileModel);
                    }

                }

            }
            catch (error) {
                processServerError(error, undefined, "An error occurred while getting files.");
            }
            finally {
                hideMessage();
            }
        });

    }


    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        if (!selectedItem) {
            return menuItems;
        }


        menuItems.push(...[

            <MenuItem key="share" icon={<PeopleAddRegular />} onClick={() => { setShowShareModal(true) }}> Share</MenuItem>
        ]);


        return menuItems;
    }




    const filePathColumn = {
        column: createTableColumn<FileTableItem>({
            columnId: "location",
            renderHeaderCell: () => <>Location</>,
        }),
        cellValue(fileItem) {
            return <ParentPathLink directoryPaths={fileItem.directoryPaths} />
        },

    } as FileTableColumn;

    const sharedColumn = {
        column: createTableColumn<FileTableItem>({
            columnId: "shared",
            renderHeaderCell: () => <>Shared</>,
        }),
        cellValue(fileItem) {
            return <ShowLocalTime date={fileItem.sharedAtUtc} multiline />
        }
    } as FileTableColumn;

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/shared-by-me/${parentDirectoryId ?? ''}`);
    }

    const contextMenuItems = getContextItems();

    return (<div className='page-wrapper'>
        <div className='files-wrapper'>


            <div className='files-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>
                    {breadcrumbs.map((test: BreadcrumbItem) => {
                        return <Breadcrumb
                            key={`breadcrumb-${test.uniqueIdentifier ?? "dashboard"}`}
                            onClick={() => { setShowDetails(false); onBreadcrumbItemClick(test.uniqueIdentifier); }}>{test.name}</Breadcrumb>
                    })}
                </Breadcrumbs>
            </div>
            <FilesTable
                isLoading={isLoading}
                loadingService={loadingService}
                //showLoadingRow
                files={files}
                readonly
                disableSubmitForAnalysis
                hideAnalyzedColumn
                hideAnalyticsColumn
                hideTagsColumn
                hideModifiedColumn
                basePagePath='/files/shared-by-me'
                additionalColumns={[sharedColumn, filePathColumn]}
                sortable
                sortBy={sortBy}
                onSortChange={(data) => { setSortBy(data); }}
                contextMenuItems={contextMenuItems}
                hubConnection={props.hubConnection}
                onSelectedItemChanged={(item) => { setSelectedItem(item) }}
                onShowTagInputsChanged={(value) => setShowTagInputs(value)}
                onReloadFilesEvent={() => getFilesWithLoading()}
                onShowDetails={(item) => { setSelectedItem(item); setShowDetails(true); }} />
                
                 {(!isLoading && !files?.length) && <div className='files-empty-directory'>
                <img className='files-empty-directory__image' src={SharedByMeImage} style={{ width: "256px" }} />
                <span className='files-empty-directory__text--main'>Share files</span>
                <span className='files-empty-directory__text--sub'>By right-clicking on a file/folder and choosing "Share".</span>
            </div>}

        </div>

        {/* Side panel */}
        <SidePanel
            isOpened={selectedItem && showDetails}
            selectedItem={selectedItem}
            readOnly
            fileTagDefaultOptions={fileTagDefaultOptions}
            hubConnection={props.hubConnection}
            loadingService={loadingService}
            onHide={() => setShowDetails(false)}
            onReloadFilesEvent={() => getFilesWithLoading()}
        />
        {selectedItem && showShareModal &&
            <ShareFileModal
                sourceType={selectedItem.type == FileSystemEntityType.File ? ShareDefinitionSourceType.File : ShareDefinitionSourceType.Directory}
                sourceName={selectedItem.name}
                sourceUniqueId={selectedItem.uniqueIdentifier}
                isOpened={showShareModal}
                onClose={() => { setShowShareModal(false); }}
            />}

    </div>
    );
}

export default SharedByMeFilesPage;