import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { DismissFilled, NextFrameRegular, PreviousFrameRegular } from "@fluentui/react-icons";
import React from "react";
import { TourIntro } from "./intro.content";
import { MyWorkTour } from "./my-work.content";
import { AnalyticsTour } from "./analytics.content";
import { ExamsTour } from "./exams.content";
import { ClusterTour } from "./cluster.content";
import { ConfigurationsTour } from "./configurations.content";
import { processServerError } from "../utils/helpers/error.helper";
import { getTourClient } from "../services/dashboard.service";
import { HeadbarTour } from "./headbar.content";

type TourGuideModalProps = {
    isOpened?: boolean;
    skipIntro?: boolean;
    onClose?: () => void;
}

const guideTabs: { title: string, content: React.ReactElement }[] = [
    { title: "My Work", content: <MyWorkTour /> },
    { title: "Analytics", content: <AnalyticsTour /> },
    { title: "Exams", content: <ExamsTour /> },
    { title: "Cluster", content: <ClusterTour /> },
    { title: "Configurations", content: <ConfigurationsTour /> },
    { title: "Headbar", content: <HeadbarTour /> }
]

export const TourGuideModal: React.FC<TourGuideModalProps> = (props) => {


    const [open, setOpen] = React.useState(false);
    const [hasAcceptedTour, setHasAcceptedTour] = React.useState<boolean>(false);
    const [currentStep, setCurrentStep] = React.useState<number>(1);

    React.useEffect(() => {
        if (!!props.skipIntro) {
            setHasAcceptedTour(true);
        }
    }, []);

    React.useEffect(() => {

        if (hasAcceptedTour) {
            onTourStatusChangeLogic();
        }

    }, [hasAcceptedTour]);

    const onTourStatusChangeLogic = async () => {
        const accepted = await getTourStatus();
        if (!accepted) {
            await updateTourStatus();
        }
    }

    const getTourStatus = async () => {
        try {
            const client = getTourClient();
            const tourResp = await client.getTourStatus();
            return tourResp?.hasSeenTour ?? false;
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting user tour status.");
        }
    }

    const updateTourStatus = async () => {
        try {
            const client = getTourClient();
            await client.userAcceptedTour();
        } catch (error) {
            processServerError(error, undefined, "An error occurred while updating user tour status.");
        }
    }


    React.useEffect(() => {
        setOpen(!!props.isOpened);


    }, [props.isOpened]);

    return <Dialog key={`tour-guide-modal`} open={open} modalType="alert" onOpenChange={(event, data) => {


        setOpen(data.open);
        if (data.open == false) {
            if (props.onClose)
                props.onClose();
        }

    }}>

        <DialogSurface>
            <DialogBody style={{ height: "450px" }}>
                <DialogTitle action={<Button appearance="transparent"
                    onClick={props.onClose}
                    icon={<DismissFilled />} />}>{!!hasAcceptedTour && `${guideTabs[currentStep - 1]?.title}`}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                        {!hasAcceptedTour && <TourIntro />}
                        {!!hasAcceptedTour && guideTabs[currentStep - 1]?.content}


                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "var(--spacingVerticalMNudge)",
                            marginBottom: "var(--spacingVerticalMNudge)",
                            gap: "20px"
                        }}>

                            {!hasAcceptedTour && <>
                                <Button appearance="primary" onClick={() => { setHasAcceptedTour(true) }}>Show now</Button>
                                <Button appearance="primary" onClick={() => { props.onClose(); }}>Show later</Button>
                            </>
                            }

                            {hasAcceptedTour && <>
                                {currentStep > 1 && <Button appearance="primary" icon={<PreviousFrameRegular />} onClick={() => { setCurrentStep(currentStep - 1) }}>
                                    Previous
                                </Button>}
                                {currentStep < guideTabs.length &&
                                    <Button appearance="primary"
                                        icon={<NextFrameRegular />}
                                        iconPosition="after"
                                        onClick={() => { setCurrentStep(currentStep + 1) }}>
                                        Next
                                    </Button>}
                                {currentStep == guideTabs.length &&
                                    <Button appearance="secondary"

                                        onClick={() => { props.onClose(); }}>
                                        Close
                                    </Button>}
                            </>}
                        </div>
                    </div>

                </DialogContent>

            </DialogBody>
        </DialogSurface>
    </Dialog>
}