import { SelectOption } from "../../components/has-access-side-people-picker/has-access-side-people-picker.component";

export function removeDuplicates(personas: SelectOption[], possibleDupes: SelectOption[]) {
    return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
}
export function listContainsPersona(persona: SelectOption, personas: SelectOption[]) {
    if (!personas || !personas.length || personas.length === 0) {
        return false;
    }
    return personas.filter(item => item.value === persona.value).length > 0;
}