import { Spinner } from '@fluentui/react-components';
import * as React from 'react';
import { toast } from 'react-toastify';
import { getUsersClient } from '../../services/dashboard.service';
import { UserResponseModel } from '../../swagger-clients/s365-dashboard-v2-api-clients.service';

declare global {
    interface Window {
        UserDisplayNamePromiseCache: { [userId: string]: Promise<UserResponseModel> };
    }
}

interface IUserDisplayNameProps {
    userId: string;
    isExample?: boolean;   
}

const getUserWithCache = async (userId: string): Promise<UserResponseModel> => {

    if (!window.UserDisplayNamePromiseCache || !window.UserDisplayNamePromiseCache.hasOwnProperty(userId)) {
        // Create cache object if it doesn't exist
        if (!window.UserDisplayNamePromiseCache) {
            window.UserDisplayNamePromiseCache = {};
        }

        const client = getUsersClient();
        window.UserDisplayNamePromiseCache[userId] = client.getUser(userId).then(user => {
            window.sessionStorage.setItem(`user-display-name-${userId}`, user.displayName);
            return user;
        });
    }

    return await window.UserDisplayNamePromiseCache[userId];
}

export const UserDisplayName: React.FC<IUserDisplayNameProps> = (props) => {

    const [userDisplayName, setUserDisplayName] = React.useState<string>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(props.isExample){
            setUserDisplayName("Simulate 365");

        }else  if (!!props.userId) {
            // Check if display name exists in session storage
            let existingData = window.sessionStorage.getItem(`user-display-name-${props.userId}`);
            if (existingData) {
                setUserDisplayName(existingData);
            }
            else {
                setIsLoading(true);
                getUserWithCache(props.userId)
                    .then(user => {
                        setUserDisplayName(user.displayName);
                        setIsLoading(false);
                    })
                    .catch(e => {
                        toast.error("An error occurred while getting user information.");
                        setIsLoading(false);
                    });
            }
        }
    }, [props.userId]);

    if (!isLoading) {
        return <span>{userDisplayName}</span>
    } else {
        return <Spinner />
    }
}