import { Combobox, Field, Option } from '@fluentui/react-components';
import React from 'react';
import { getFiltersClient, getUserToken } from '../../services/dashboard.service';
import { FilterQuery, FilterResultModel, IFilterQuery } from '../../swagger-clients/s365-dashboard-v2-api-clients.service';
import { _copyAndSort } from '../../utils/helpers/array.helpers';
import { IDropdownOption } from '../../utils/shared.types';
import { CreateFilterModal } from '../../filters/create-filter-modal.component';
import { HubConnection } from '@microsoft/signalr';


interface FilterPickerProps {

    filterId?: number;
    errorMessage?: string;
    filterVersionId?: number;
    fileId?: number;
    fileUniqueId?: string;
    documentCreatedFromFilterId?: number;
    documentCreatedFromFilterVersionId?: number;
    showCreateOption?: boolean;
    hubConnection?: HubConnection;
    disabled?: boolean;
    style?: React.CSSProperties;
    className?: string;
    onChange: (filterId: number) => void;
}

const FilterPicker: React.FunctionComponent<FilterPickerProps> = (props) => {


    const [filterOptions, setFilterOptions] = React.useState<IDropdownOption[]>([]);
    const [allFilters, setAllFilters] = React.useState<FilterResultModel[]>([]);
    const [errorMessage, setErrorMessage] = React.useState<string>(props.errorMessage);

    const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        getFilters().then(() => { });
    }, []);
    React.useEffect(() => {
        getFilters().then(() => { });
    }, [props.fileId]);

    React.useEffect(() => {
        ValidateFilter();


    }, [props.filterId, allFilters]);
    React.useEffect(() => {
        setErrorMessage(props.errorMessage);
    }, [props.errorMessage]);



    const getFilters = async () => {

        try {
            const client = getFiltersClient();


            const accessToken: string = await getUserToken();
            const query = {
                fileId: props.fileId,
            } as FilterQuery;
            //     console.log("FiltersQuery:", query);

            const filters = await client.getFilters(query);
            //      console.log("Filters", filters);

            setAllFilters(filters);

            const activeFilters = filters.filter((item) => item.isDeleted == false);

            const filtersSorted = _copyAndSort<FilterResultModel>(activeFilters, "createdAt", true);
            const options = filtersSorted.map((filter) => ({ key: filter.id, text: filter.name } as IDropdownOption));
            console.log("Filter options:", options);
            setFilterOptions(options);

        } catch (error) {
            console.log("An error occurred while getting filters for filter picker.", error);
        }

    }

    const onChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        // console.log("Filter picker on change");
        props.onChange(+option.key);
    }

    const ValidateFilter = (): void => {
        //  console.log("ValidateFilter called!", props.filterId);


        if (props.filterId) {
            const filter = allFilters.find(x => x.id == props.filterId);
            //     console.log("ValidateFilter filter", filter);
            if (filter && filter.isDeleted) {
                setErrorMessage("Filter is deleted and can't be selected!");
            } else if (filter && (filter.id == props.documentCreatedFromFilterId) && (+filter.currentVersionId) !== props.documentCreatedFromFilterVersionId) {
                setErrorMessage("Filter version has changed since the file was generated!");
            } else {
                setErrorMessage("");
            }
        } else if (props.errorMessage) {
            setErrorMessage(errorMessage);
        }
    }
    const onCreateFilterClick = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        ev.preventDefault();
        setShowCreateModal(true);
    }
    const onFilterCreated = (filterResp: FilterResultModel) => {
        setShowCreateModal(false);
        setFilterOptions([{ key: filterResp.id, text: filterResp.name } as IDropdownOption, ...filterOptions]);
        props.onChange(filterResp.id);
    }

    const selectedValue = props.filterId ? filterOptions?.find(x => x.key.toString() == props.filterId.toString()) : undefined;
    return <Field
        className={props.className}
        validationMessage={props.errorMessage ? props.errorMessage : ""}
        validationState={props.errorMessage ? "error" : "none"}>
        <Combobox
            style={props.style}
            value={selectedValue?.text}
            placeholder="Select filter"
            autoComplete="off"
            disabled={props.disabled}
            onOptionSelect={(ev, data) => { props.onChange(+data.optionValue) }}>
            {filterOptions.map((option) => {
                return <Option value={option.key?.toString()}>
                    {option.text}
                </Option>
            })}
        </Combobox>
        {props.showCreateOption && <small>Select existing filter or <a href='#' onClick={onCreateFilterClick}>Create new</a>.</small>}
        {showCreateModal && <CreateFilterModal
            isOpened={showCreateModal}
            fileUniqueId={props.fileUniqueId}
            hubConnection={props.hubConnection}
            onSuccess={onFilterCreated}
            onClose={() => { setShowCreateModal(false); }}
        />}
    </Field>
}


export default FilterPicker