import React, { useEffect } from "react";

import { emptyBreadcrumbs } from "../filters/filters.component";
import { BreadcrumbItem, ErrorResultModel, FileModel, ShareDefinitionSourceType } from "../swagger-clients/s365-dashboard-v2-api-clients.service";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from '../components/breadcrumbs/breadcrumbs';
import { ExperimentForListResponseModel, ExperimentProcessingStatus } from "../swagger-clients/ai-for-pfd-clients.service";
import { LoadingIndicator, useLoading } from "../utils/loading-indicator.component";
import { useNavigate, useParams } from "react-router-dom";
import { MenuItem, Tab, TabList, Table, TableCell, TableHeader, TableHeaderCell, TableRow, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Add20Regular, ArrowResetRegular, CopyRegular, DeleteRegular, DocumentTextRegular, EditRegular, PeopleAddRegular } from "@fluentui/react-icons";
import { ContextMenu, ContextMenuType } from "../files/context-menu/context-menu.component";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { getFilesClient, getUsersClient } from "../services/dashboard.service";
import { processServerError } from "../utils/helpers/error.helper";
import { getExperimentsClient } from "../services/ai-for-pfd.service";
import { _copyAndSort } from "../utils/helpers/array.helpers";
import { ExperimentStatusComponent } from "../components/experiment-status/experiment-status.component";
import { ConfirmationDialog } from "../components/confirmation-dialog/confirmation-dialog.component";
import { toast } from "react-toastify";
import ShowLocalTime from "../components/show-local-time/show-local-time.component";
import { ShareFileModal } from "../files/share/share-file-modal.component";

type FlowsheetExperimentsProps = {

}

export type ExperimentRouteParams = {
    uniquefileId: string,
    experimentId?: string
}

export const FlowsheetExperiments: React.FC<FlowsheetExperimentsProps> = (props) => {


    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const routeParams = useParams<ExperimentRouteParams>();
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedExperiment, setSelectedExperiment] = React.useState<ExperimentForListResponseModel>();
    const [experiments, setExperiments] = React.useState<ExperimentForListResponseModel[]>([]);
    const [selectedTab, setSelectedTab] = React.useState<string>("active");
    const [showDeleteExperimentDialog, setShowDeleteExperimentDialog] = React.useState<boolean>(false);
    const [showShareModal, setShowShareModal] = React.useState<boolean>(false);
    const [isUserExamplesAdmin, setIsUserExamplesAdmin] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();
    const contextMenuRef = React.createRef<ContextMenuType>();

    useEffect(() => {
        initializePage();
    }, []);

    const initializePage = async () => {
        checkIfUserIsExamplesAdmin();
        const resp = await getFile();
        await getExperiments();
    }

    const getFile = async () => {
        try {
            const client = getFilesClient();
            const resp = await client.getFileLatest(routeParams.uniquefileId!, true);
            if (resp) {
                setSelectedFile(resp.file);
                setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
            }
            return resp;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file information.");
        }
    }

    const getExperiments = async () => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getExperimentsClient();

                const experimentsResp = await client.getExperiments(routeParams.uniquefileId);

                const experimentsSorted = _copyAndSort<ExperimentForListResponseModel>(experimentsResp, "createdAt", true);

                setExperiments(experimentsSorted ?? []);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting copilot experiments.");
            } finally {
                hideMessage();
            }
        });

    }

    const onDeleteExperimentClick = () => {
        loadingService.showLoading("Archiving copilot experiment...", async (hideMessage) => {
            try {
                const client = getExperimentsClient();
                await client.deleteExperiment(selectedExperiment.id);
                toast.success("Successfully archived Copilot Experiment.");
                setTimeout(() => { setSelectedExperiment(undefined); }, 300);
                setShowDeleteExperimentDialog(false);
                getExperiments();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to archive Copilot Experiment.");
            } finally {
                hideMessage();
            }
        });
    }


    const onRestoreExperimentClick = () => {

        loadingService.showLoading("Restoring copilot experiment...", async (hideMessage) => {
            try {
                const client = getExperimentsClient();
                await client.restoreExperiment(selectedExperiment.id);
                toast.success("Successfully restored Copilot Experiment.");
                setSelectedExperiment(undefined);
                getExperiments();

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to restore Copilot Experiment.");
            } finally {
                hideMessage();
            }
        });

    }

    const checkIfUserIsExamplesAdmin = async () => {
        try {
            const client = getUsersClient();
            const resp = await client.isUserExamplesAdmin();
            setIsUserExamplesAdmin(resp?.isUserExamplesAdmin ?? false);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to check if user is Examples Admin.");
        }

    }

    const getContextItems = () => {

        let menuItems: JSX.Element[] = [];

        if (!selectedExperiment) {
            return [];
        }
        if (!selectedExperiment.isDeleted) {
            menuItems.push(...[
                <MenuItem key="edit" icon={<EditRegular />} onClick={() => { navigate(`/files/${routeParams.uniquefileId}/copilot/edit/${selectedExperiment.id}`) }}> Edit</MenuItem>,
                <MenuItem key="edit" icon={<CopyRegular />} onClick={() => { navigate(`/files/${routeParams.uniquefileId}/copilot/edit?cloneId=${selectedExperiment.id}`) }}> Clone</MenuItem>
            ]);
            if(isUserExamplesAdmin){
                menuItems.push(<MenuItem key="share" icon={<PeopleAddRegular />} onClick={() => { setShowShareModal(true) }}> Share</MenuItem>);
            }
            menuItems.push(<MenuItem key="details" icon={<DocumentTextRegular />} onClick={() => { navigate(`/files/${routeParams.uniquefileId}/copilot/details/${selectedExperiment.id}`) }} > Details</MenuItem>);
            menuItems.push(<MenuItem icon={<DeleteRegular />} key="delete" onClick={() => { setShowDeleteExperimentDialog(true); }}> Archive</MenuItem>);
        } else {
            menuItems.push(<MenuItem key="restore" icon={<ArrowResetRegular />} onClick={() => { onRestoreExperimentClick(); }}> Restore</MenuItem >);
        }


        return menuItems;
    }
    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }
    const tabExperiments = selectedTab == "active" ? experiments.filter(experiment => !experiment.isDeleted) : experiments.filter(experiment => !!experiment.isDeleted);

    return <div className="content-wrapper">
        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                    onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/copilot/edit`)}
                    appearance='primary'
                    icon={<Add20Regular />}>New Experiment</ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />

            </Toolbar>
        </div>

        <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/copilot`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-copilot`} active={true}>Flowsheet Copilot</Breadcrumb>

            </Breadcrumbs>
        </div>



        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { contextMenuRef.current?.hideMenu(); setSelectedTab(data.value as string); setSelectedExperiment(undefined); }}>
            <Tab key="active" value="active" >Active</Tab>
            <Tab key="deleted" value="deleted">Archived</Tab>
        </TabList>
        <div className="tab-content">
            <div className='table__wrapper'>
                <Table >
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell key="id" style={{ width: "100px" }} className='table__cell--bold'>ID</TableHeaderCell>
                            <TableHeaderCell key="name" className='table__cell--bold'>Name</TableHeaderCell>
                            <TableHeaderCell key="processing-status" className='table__cell--bold column--center'>Processing status</TableHeaderCell>
                            <TableHeaderCell key="created-at" className='table__cell--bold column--center'>Created</TableHeaderCell>

                        </TableRow>
                    </TableHeader>
                    <TableBodyWithLoading isLoading={isLoading}
                        columnCount={4} loadingMessage="Loading..."
                        itemCount={tabExperiments ? tabExperiments.length : 0}
                        noItemsMessage="No items found.">
                        {tabExperiments && tabExperiments.length > 0 && tabExperiments.map((item) => {
                            return <TableRow
                                key={`experiment-${item.id}`}
                                style={{ cursor: "pointer" }}
                                onClick={() => { navigate(`/files/${routeParams.uniquefileId}/copilot/details/${item.id}`); }}
                                className={selectedExperiment && selectedExperiment.id == item.id ? "table-row-selected" : undefined}
                                onContextMenu={(ev) => { ev.preventDefault(); setSelectedExperiment(item); contextMenuRef.current?.showMenu(ev); }}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell className="column--center"><ExperimentStatusComponent status={item.processingStatus} /></TableCell>
                                <TableCell className="column--center"><ShowLocalTime date={item.createdAt}  /></TableCell>
                            </TableRow>
                        })}
                    </TableBodyWithLoading>
                </Table> </div>

        </div>
        <ContextMenu ref={contextMenuRef} >
            {getContextItems()}
        </ContextMenu>
        <ConfirmationDialog
            isOpened={showDeleteExperimentDialog}
            title="Archive Experiment"
            confirmLabel="Archive"
            subText={
                `Are you sure you want to archive experiment '${selectedExperiment?.name}'? `}
            onConfirm={() => {
                onDeleteExperimentClick();
            }}
            onClose={() => { setShowDeleteExperimentDialog(false); }}

        />

        {
            selectedExperiment && showShareModal &&
            <ShareFileModal
                sourceType={ShareDefinitionSourceType.CopilotExperiment}
                sourceUniqueId={selectedExperiment.id?.toString()}
                sourceName={selectedExperiment.name}
                isOpened={showShareModal}
                onClose={() => { setShowShareModal(false); }}
            />
        }

    </div>


}
