import React, { createRef, useRef } from "react";
import { useClickAway } from 'react-use';
import "./editable-textarea-field.styless.scss"
import { Field, Textarea } from "@fluentui/react-components";

export type EditableTextAreaFieldProps = {
    key?: string;
    value?: string;
    validationMessage?: string;
    validationState?: 'error' | 'warning' | 'success',
    placeholder?: string,
    disabled?: boolean,
    rows?: number,
    focusOnInit?: boolean,
    onTextChange?(value: string): void,
    onClickAway?(value: string): void,
    onValidationMessageChange?(message: string): void,
    onValidationStateChange?(state: 'error' | 'warning' | 'success' | undefined): void,
    onLeaveEditMode?: () => void

}

export const EditableTextAreaField: React.FC<EditableTextAreaFieldProps> = (props) => {
    const [text, setText] = React.useState<string>("");

    const EditMessage = "Click away to save changes";

    const textArearef = useRef<HTMLTextAreaElement>(null);

    const handleClickAway = () => {
        if (!props.disabled) {
            props.onValidationMessageChange(undefined);
            props.onValidationStateChange(undefined);
            if (props.onLeaveEditMode) props.onLeaveEditMode();

            if (props.value !== text) {
                if (props.onClickAway)
                    props.onClickAway(text);
            }
        }

    }
    useClickAway(textArearef, handleClickAway);

    React.useEffect(() => {
        if (props.focusOnInit) {
            textArearef.current?.focus();

        }
    }, [props.focusOnInit]);


    React.useEffect(() => {
        console.log("EditableTextAreaField", props.value);
        setText(props.value);
    }, [props.value]);




    const onTextChanged = (value: string) => {
        props.onValidationStateChange(undefined);
        props.onValidationMessageChange(EditMessage);
        setText(value);
        if (props.onTextChange) {
            props.onTextChange(value);
        }
    }

    return <Field
        validationMessage={props.validationMessage}
        validationState={props.validationState}>
        <Textarea
            key={props.key}
            ref={textArearef}
            className={`editable-text-area-field ${props.disabled ? "editable-text-area-field-readonly" : ""}`}
            value={text}
            rows={props.rows}
            readOnly={props.disabled}
            placeholder={props.placeholder}
            onChange={(ev, data) => { onTextChanged(data.value); }} />
    </Field>

}