import { FlowsheetObjectsResponseModel } from "../swagger-clients/dispatcher-next-api-clients.service";
import { getFlowsheetsClient } from "./dispatcher.service";


class FlowsheetObjectService {
    private static __requestsCache: { [key: string]: Promise<FlowsheetObjectsResponseModel> } = {};
   
   public static  getFlowsheetObjectsPromise(driveId: string, itemId: string, versionNumber: string) {
        // Create Promise for loading data, it it doesn't exist already
        let cacheKey = `${driveId}-${itemId}-${versionNumber}`;
        if (FlowsheetObjectService.__requestsCache[cacheKey] === undefined) {
            let client = getFlowsheetsClient();
            FlowsheetObjectService.__requestsCache[cacheKey] = client.getFlowsheetObjects(driveId, itemId, versionNumber);
        }
    
        return FlowsheetObjectService.__requestsCache[cacheKey];
    }
}

export default FlowsheetObjectService;