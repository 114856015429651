import { Spinner } from "@fluentui/react-components";
import React from "react";
import { graphClient } from "../../../services/msgraph.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { useLoading } from "../../../utils/loading-indicator.component";
import { getGroupsClient } from "../../../services/dashboard.service";
type MembersCountProps = {
    groupId: string;
}

export const MembersCount: React.FC<MembersCountProps> = (props) => {

    const [isLoading, loadingService] = useLoading();
    const [count, setCount] = React.useState<number>();

    React.useEffect(() => {
        getMembersCount();
    }, []);


    const getMembersCount = async () => {
        loadingService.showLoading("Loading group members count", async (hideMessage) => {
            try {

                const client = getGroupsClient();
                const result = await client.getGroupMembersCount(props.groupId);
                setCount(result.count);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting members count.", false);
            } finally {
                hideMessage();
            }

        });

    }

    return <div>
        {isLoading && <Spinner size="small" />}
        {!isLoading && (count !== undefined ? count : "-")}

    </div>
}