import React from "react";
import { GroupTableResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import UserGroupsService from "../../services/user-groups.service";
import { toast } from "react-toastify";

type GroupDisplayProps = {
    groupId: string;
}

export const GroupDisplay: React.FC<GroupDisplayProps> = (props) => {

    const [groups, setGroups] = React.useState<GroupTableResultModel[]>([]);

    React.useEffect(() => {
        getGroups();
    }, [props.groupId]);

    const getGroups = async () => {
        try {
            const groupsResp = await UserGroupsService.getUserOwnedGroups();
            setGroups(groupsResp ?? []);
        } catch (error) {
            console.log("An error occurred while getting user owned groups;");
        }
    }

    const getGroupName = (groupId: string) => {

        const group = groups.find(x => x.id == groupId);
        return group ? group.groupname : "";
    }

    return <span>{getGroupName(props.groupId)}</span>
}