import { Button, Field, Input } from "@fluentui/react-components";
import { Combobox, OptionGroup, Option, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Dropdown } from "@fluentui/react-components";

import { ChevronDown16Regular, ChevronDownRegular, ChevronUp16Regular, ChevronUpRegular, Dismiss20Regular, SelectObjectSkewEditFilled } from "@fluentui/react-icons";
import React from "react";
import ParameterPickerNext, { ParameterAccessModel } from "../../components/paramater-picker-next/parameter-picker-next.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { FileModel, FilterParameterType, FilterParameterTypePostModel, FilterResultModel, InputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { IDropdownOption } from "../../utils/shared.types";
import { getParameterTypeText } from "../filter-utilities";
import { IValidationField } from "./edit-filter.types";
import { AliasIsNotSameAsParameterName, IsValidVariableName, ParameterValueIsUnique, ValidateFixedValue, ValidateFormulaValue, ValidateParameterName, ValidateParameterValue } from "./edit-filter.validation";
import "./parameters-tab.styless.scss";

type InputParametersTabProps = {
    filter?: FilterResultModel;
    selectedFile?: FileModel;
    setFilter: (filter: FilterResultModel) => void,
    isFormSubmitted: boolean,
    isLoading: boolean
};


const parameterTypeDropdownOptions: IDropdownOption[] = [{
    key: 0,
    text: "Expected input"
}, {
    key: 2,
    text: "Fixed value"
},
{
    key: 3,
    text: "Formula"
}];

export const InputParametersTab: React.FC<InputParametersTabProps> = (props) => {

    const onRemoveParameterClick = (itemIndex: number) => {
        let newParams = [...props.filter.inputFilterParameters];
        newParams = newParams.filter((value, index) => index !== itemIndex).map((x, i) => ({ ...x, order: i } as InputFilterParameterResultModel));
        const updatedFilter = { ...props.filter, inputFilterParameters: newParams } as FilterResultModel;
        props.setFilter(updatedFilter);
    }

    const onAddParameterClick = () => {
        let newParams = !!props.filter?.inputFilterParameters ? [...props.filter!.inputFilterParameters!] : [];
        const defaultParamterValue = { id: 0, parameterType: 0, fixedValue: 0, order: newParams.length, alias: "", formula: "", name: "" } as InputFilterParameterResultModel;
        newParams.push(defaultParamterValue);
        const updatedFilter = { ...props.filter, inputFilterParameters: newParams } as FilterResultModel;
        props.setFilter(updatedFilter);
    }

    const onParameterTypeChanged = (index: number, option: FilterParameterTypePostModel) => {
        let newParams = [...props.filter!.inputFilterParameters ?? []];
        newParams[index].parameterType = +option;
        if (option == FilterParameterTypePostModel.ExpectedInput) {
            newParams[index].fixedValue = 0;
            newParams[index].formula = "";
        } else if (option == FilterParameterTypePostModel.FixedValue) {

            newParams[index].formula = "";
        } else {
            newParams[index].fixedValue = 0;
        }

        const updatedFilter = { ...props.filter, inputFilterParameters: newParams } as FilterResultModel;
        props.setFilter(updatedFilter);
    }

    const onItemChanged = (index: number, newValue: string, column: string) => {

        let newParams = [...(props.filter?.inputFilterParameters ?? [])];
        const oldValue = (newParams[index] as any)[column] as any;
        if (oldValue !== newValue) {
            (newParams[index] as any)[column] = newValue;

            const updatedFilter = { ...props.filter, inputFilterParameters: newParams } as FilterResultModel;
            props.setFilter(updatedFilter);
        }
    }

    const moveItem = (item: InputFilterParameterResultModel, itemIndex: number, newItemIndex: number) => {
        let inputParams = [...(props.filter?.inputFilterParameters ?? [])];
        inputParams = inputParams.filter((x, i) => i !== itemIndex).map((x, i) => ({ ...x, order: i } as InputFilterParameterResultModel));
        console.log("moveItem inputparams before", inputParams);
        inputParams.splice(newItemIndex, 0, item);
        inputParams = inputParams.map((x, i) => ({ ...x, order: i } as InputFilterParameterResultModel));
        console.log("moveItem after", item, inputParams);
        const updatedFilter = { ...props.filter, inputFilterParameters: inputParams } as FilterResultModel;
        props.setFilter(updatedFilter);
    }

    const allowedVariableNames = props.filter.inputFilterParameters?.map(x => x.alias) ?? [];

    const getFormulaCell = (item: InputFilterParameterResultModel, index: number) => {

        if (item.parameterType == FilterParameterType.ExpectedInput) {
            return "";
        }
        if (item.parameterType == 2) {
            const validationResult = ValidateFixedValue(item.fixedValue?.toString() ?? "");
            return <div className="input-wrapper">
                <Field
                    validationMessageIcon={null}
                    validationMessage={props.isFormSubmitted ? validationResult.validationErrors[0] : undefined}
                    validationState={props.isFormSubmitted && validationResult.validationErrors[0] ? "error" : "none"}>
                    <Input
                        style={{ maxWidth: "250px" }}
                        value={item.fixedValue?.toString()}
                        onChange={(ev, newValue) => onItemChanged(index, newValue.value, "fixedValue")} />
                </Field>
            </div>
        } else {
            const validationResult = ValidateFormulaValue(item.formula ?? "", allowedVariableNames, "Formula is required!");

            return <div className="input-wrapper">
                <Field
                    validationMessageIcon={null}
                    validationMessage={props.isFormSubmitted ? validationResult.validationErrors[0] : undefined}
                    validationState={props.isFormSubmitted && validationResult.validationErrors[0] ? "error" : "none"} >
                    <Input
                        style={{ maxWidth: "250px" }}
                        value={item.formula}
                        onChange={(ev, newValue) => onItemChanged(index, newValue.value, "formula")} />
                </Field>
            </div>
        }
    }

    const getParameterNameCell = (item: InputFilterParameterResultModel, index: number) => {
        const { name } = item;
        const { selectedFile } = props;


        const validationResult = ValidateParameterName(name ?? "");
        const uniqueValidationResult =
            ParameterValueIsUnique(props.filter?.inputFilterParameters ?? [], item, "name", validationResult);

        return <div className="input-wrapper">
            <ParameterPickerNext
                key={name}
                fileUniqueId={selectedFile?.uniqueIdentifier}
                fileVersionNumber={selectedFile?.currentVersionNumber as any}
                value={name}
                onChange={v => onItemChanged(index, v, "name")}
                accessMode={ParameterAccessModel.Write} />
            {props.isFormSubmitted && !uniqueValidationResult.isValid && <span className="input-wrapper--error">{uniqueValidationResult.validationErrors[0]}</span>}
        </div>
    }

    const getAliasCell = (item: InputFilterParameterResultModel, index: number) => {
        let validationResult = { isValid: true, validationErrors: [] } as IValidationField;
        validationResult = ParameterValueIsUnique(props.filter?.inputFilterParameters ?? [],
            item, "alias", validationResult);

        validationResult = AliasIsNotSameAsParameterName(props.filter?.inputFilterParameters ?? [],
            item, validationResult);

        validationResult = IsValidVariableName(item.alias ?? "", validationResult);
        console.log("getAliasCell validationResult", validationResult, props.isFormSubmitted);

        return <div className="input-wrapper">
            <Field
                validationMessageIcon={null}
                validationMessage={props.isFormSubmitted ? validationResult.validationErrors[0] : undefined}
                validationState={props.isFormSubmitted && validationResult.validationErrors[0] ? "error" : "none"}>
                <Input
                    value={item.alias}
                    style={{ maxWidth: "100%" }}
                    onChange={(ev, data) => { onItemChanged(index, data.value as string, "alias") }} />
            </Field>
        </div>
    }

    const getMoveCell = (item: InputFilterParameterResultModel, itemIndex: number) => {
        const inputFilterParameters = props.filter?.inputFilterParameters;
        return <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
                key="chevron-up"
                appearance="transparent"
                className="chevron--button"
                style={{
                    display: itemIndex == 0 ? "none" : "inline"
                }}
                icon={<ChevronUpRegular />}
                onClick={() => { moveItem(item, itemIndex, itemIndex - 1) }}
            />
            <Button
                key="chevron-down"
                appearance="transparent"
                className="chevron--button"
                style={{
                    display: inputFilterParameters!.length == 1 || itemIndex == inputFilterParameters!.length - 1 ? "none" : "inline"
                }}
                icon={<ChevronDownRegular />}

                onClick={() => { moveItem(item, itemIndex, itemIndex + 1) }} />

        </div>
    }

    return <>
        <div className="s365-table__wrapper">
            <Table>
                <TableHeader>
                    <TableRow key="table-header">
                        <TableHeaderCell key="move-row" style={{ width: "20px" }}></TableHeaderCell>
                        <TableHeaderCell key="parameter" style={{ width: "400px" }} className='files-table__cell--bold'>Parameter</TableHeaderCell>
                        <TableHeaderCell key="alias" style={{ width: "220px" }} className='files-table__cell--bold'>Alias</TableHeaderCell>
                        <TableHeaderCell key="parameter-type" style={{ width: "180px" }} className='files-table__cell--bold'>Parameter Type</TableHeaderCell>
                        <TableHeaderCell key="value-formula-field" style={{ maxWidth: "250px" }} className='files-table__cell--bold'>Value / Formula / Field</TableHeaderCell>
                        <TableHeaderCell key="actions" ></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={props.isLoading}
                    columnCount={6} loadingMessage="Loading..."
                    itemCount={props.filter?.inputFilterParameters ? props.filter.inputFilterParameters.length : 0}
                    noItemsMessage={`You can add parameters by clicking on the "Add parameter" button.`}>
                    {props.filter && props.filter.inputFilterParameters &&
                        props.filter.inputFilterParameters.map((item, index) => {
                            return <TableRow key={`input-param-${index}`}>
                                <TableCell key={"move"} className="parameter-cell">
                                    {getMoveCell(item, index)}
                                </TableCell>
                                <TableCell key="name" className="parameter-cell">
                                    {getParameterNameCell(item, index)}
                                </TableCell>
                                <TableCell key="alias" className="parameter-cell">
                                    {getAliasCell(item, index)}
                                </TableCell>
                                <TableCell key="param-type" className="parameter-cell">
                                    <Dropdown style={{ minWidth: "180px" }} value={getParameterTypeText(item.parameterType)} onOptionSelect={(ev, data) => { onParameterTypeChanged(index, +data.optionValue!); }}>
                                        {parameterTypeDropdownOptions.map((option) => {
                                            return <Option key={`param-type-${option.key}`} value={option.key?.toString()}>
                                                {option.text}
                                            </Option>;
                                        })}
                                    </Dropdown> </TableCell>
                                <TableCell key="formula" className="parameter-cell">{getFormulaCell(item, index)}</TableCell>
                                <TableCell key="actions" >
                                    <Button
                                        style={{ float: "right" }}
                                        appearance="transparent"
                                        icon={<Dismiss20Regular />}
                                        onClick={() => { onRemoveParameterClick(index) }} /></TableCell>
                            </TableRow>
                        })

                    }

                </TableBodyWithLoading>
            </Table>
        </div>
        <Button key="add-param-btn" appearance="primary" style={{ marginTop: "10px" }} onClick={onAddParameterClick}>Add parameter</Button>
    </>;
}