import * as React from "react";
import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, makeStyles } from "@fluentui/react-components";
import { Button, Input } from "@fluentui/react-components";
import { useForm } from "react-hook-form";
import { getFilesClient } from "../../services/dashboard.service";
import { useParams } from "react-router-dom";
import { CreateDirectoryPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { toast } from "react-toastify";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { GLOBAL_STYLES } from "../../styles";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { useLoading } from "../../utils/loading-indicator.component";

type CreateDirectoryModalProps = {
    isOpened?: boolean;
    parentDirectoryUniqueId?: string;
    onSuccess: () => void;
    onClose?: () => void;
};

type CreateDirectoryModel = {
    directoryName: string,
    directoryNameExists?: string
}

function CreateDirectoryModal(props: CreateDirectoryModalProps) {

    const { register, handleSubmit, watch, setError, clearErrors, setValue, formState: { errors }, reset } = useForm<CreateDirectoryModel>({
        mode: "onSubmit"
    });
    const [open, setOpen] = React.useState(false);
    const [isLoading, loadingService] = useLoading();
    const inputRef = React.useRef<HTMLInputElement>();

    const onSubmit = async (data: CreateDirectoryModel) => {
        loadingService.showLoading("Creating new folder...", async (hideMessage) => {
            try {
                console.log("on create directory", data);


                const client = getFilesClient();
                var model = new CreateDirectoryPostModel();
                model.directoryName = data.directoryName;
                model.parentDirectoryUniqueId = props.parentDirectoryUniqueId;
                await client.createDirectory(model);
                toast.success("Folder was successfully created.");
                // setOpen(false);
                props.onSuccess();
                setValue("directoryName", "");

            } catch (error) {
                processServerError(error, setError, "An error occurred while creating directory.");
            } finally {
                hideMessage();
            }
        });

    }



    const onModalClose = () => {
        setOpen(false);
        reset();
        if (props.onClose)
            props.onClose();
    }
    React.useEffect(()=>{
      
       // console.log("Calling inpuRef focus.");
        inputRef.current?.focus();    
           
       
    },[open]);

    React.useEffect(() => {
        setOpen(!!props.isOpened);
        
      
    }, [props.isOpened]);



    
    return (<Dialog key={`create-directory-modal`} open={open} modalType="modal" onOpenChange={(event, data) => {
        reset();

        setOpen(data.open);
        if (data.open == false) {
            if (props.onClose)
                props.onClose();
        }

    }}>
        {props.isOpened == undefined ? <DialogTrigger disableButtonEnhancement>
            <Button style={{ margin: "10px 0px" }}>New folder</Button>
        </DialogTrigger> : <></>}
        <DialogSurface>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogBody>
                    <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Create a folder</DialogTitle>
                    <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                        <Field
                            className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                            validationState={(!!errors.directoryName || !!errors.directoryNameExists) ? "error" : "none"}
                            validationMessage={
                                <span>
                                    {errors.directoryName && <>Folder name is required.</>}
                                    {errors.directoryNameExists && <>{errors.directoryNameExists.message}</>}
                                </span>}>
                            <Input
                                required
                                id="directoryName"
                                tabIndex={0}
                                {...register("directoryName", { required: true })}
                                ref={inputRef}
                                placeholder="Enter your folder name"
                                onChange={(ev, data) => { clearErrors(["directoryNameExists", "directoryName"]); setValue("directoryName", data.value); }}
                            />
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={isLoading} type="submit" appearance="primary">Create</LoadingButton>
                    </DialogActions>
                </DialogBody>
            </form>
        </DialogSurface>
    </Dialog>);
}

export default CreateDirectoryModal;