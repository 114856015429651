import { toast } from "react-toastify";
import { getFilesClient } from "../../services/dashboard.service";
import { FileParameter, UploadConflictAction, UploadFileResponseModel, ValidateFileNamesPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { LoadingService } from "../../utils/loading-indicator.component";
import { FilesUploadConflictModel } from "./FilesUploadConflictModal";

export type CustomFileModel = {
    file: File;
    webkitRelativePath: string;
};

type ShowConflictModalActionType = (
    model: FilesUploadConflictModel
) => void;

export class FilesUploadService {
    private onConflictModalShowActions: ShowConflictModalActionType[] = [];

    constructor(private loadingService: LoadingService) { }

    public RegisterOnConflictModalShow(action: ShowConflictModalActionType) {
        this.onConflictModalShowActions.push(action);
    }

    private ShowConflictModal(files: CustomFileModel[], parentDirectoryUniqueId: string, hasExistingDirectory: boolean, resolve: (files: UploadFileResponseModel[]) => void, reject: (reason) => void) {
        for (let a of this.onConflictModalShowActions) {
            a({
                files,
                parentDirectoryUniqueId,
                hasExistingDirectory,
                resolve,
                reject
            });
        }
    }

    private async VerifyFilesAndUpload(
        files: CustomFileModel[],
        parentDirectoryUniqueId: string,
        conflictAction: UploadConflictAction
    ) {
        return new Promise<UploadFileResponseModel[]>(async (resolve, reject) => {
            try {

                // If conflict action is not defined, validate files on server
                if (conflictAction === undefined) {
                    const validationResp = await this.validateFileNamesAsync(files, parentDirectoryUniqueId);
                    console.log("FIle ValidationResp", validationResp);
                    // If conflict exists, show message
                    if (validationResp && validationResp!.existingFiles && validationResp!.existingFiles.length > 0) {
                        const hasExistingDirectory = !!validationResp.existingDirectories && validationResp.existingDirectories.length > 0;
                        this.ShowConflictModal(files, parentDirectoryUniqueId, hasExistingDirectory, resolve, reject);

                        // If validaiton fails, stop execution until aciton is choosen in modal
                        return;
                    }
                }

                //Upload files
                const uploadedFiles = await this.UploadFilesSendRequest(files, parentDirectoryUniqueId, conflictAction);
                resolve(uploadedFiles);

                console.log("Files uploaded.");
            }
            catch (error) {
                processServerError(error, undefined, "An error occurred while uploading files.");
                reject(`Verification failed.`);
            }
        });
    };

    private async validateFileNamesAsync(data: CustomFileModel[], parentDirectoryUniqueId: string) {
        try {

            if (!data)
                throw Error("No files selected.");
            const client = getFilesClient();

            const filenames = data.map(item => { return !!item.webkitRelativePath ? item.webkitRelativePath : item.file.name; });
            let model = new ValidateFileNamesPostModel();
            model.fileNames = filenames ?? [];
            model.parentDirectoryUniqueId = parentDirectoryUniqueId;
            const resp = await client.validateFileNames({ fileNames: filenames, parentDirectoryUniqueId: parentDirectoryUniqueId } as ValidateFileNamesPostModel);
            return resp;

        } catch (error: any) {
            processServerError(error, undefined, "An error occurred while validating file names.");
        }
    };

    private async UploadFilesSendRequest(files: CustomFileModel[], parentDirectoryUniqueId: string, conflictAction: UploadConflictAction) {

        let filesWithErrors = 0;
        let uploadedFiles: UploadFileResponseModel[] = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const messageId = this.loadingService.showMessage(`Uploading ${i + 1} of ${files.length} files...`);
            try {
                const client = getFilesClient();

                const fileMapped = [{
                    fileName: !!file.webkitRelativePath ? file.webkitRelativePath : file.file.name,
                    data: file.file
                } as FileParameter];

                const uploadFilesResp = await client.uploadFiles(conflictAction, parentDirectoryUniqueId, fileMapped, false, null, null, null, false);

                if (!!uploadFilesResp && uploadFilesResp.length > 0) {
                    uploadedFiles = [...uploadedFiles, ...uploadFilesResp];
                }
            }
            catch (error) {
                processServerError(error, undefined, "An error occurred while uploading files.");
                filesWithErrors++;
            } finally {
                this.loadingService.hideMessage(messageId);
                if (i == files.length - 1 && filesWithErrors == 0) {
                    toast.success("Files were successfully uploaded.");
                }

            }
        }
        return uploadedFiles;

    };



    public UploadFiles(files: CustomFileModel[], parentDirectoryUniqueId: string, conflictAction?: UploadConflictAction) {
        return new Promise<UploadFileResponseModel[]>((resolve, reject) => {
            this.UploadFilesWithCallbacks(files, parentDirectoryUniqueId, conflictAction, resolve, reject);
        });
    }

    public UploadFilesWithCallbacks(files: CustomFileModel[], parentDirectoryUniqueId: string, conflictAction: UploadConflictAction, onSuccess: (files: UploadFileResponseModel[]) => void, onError: (error) => void) {
        this.loadingService.showLoading(
            "Uploading files...",
            (hideMessage) => {
                this.VerifyFilesAndUpload(files, parentDirectoryUniqueId, conflictAction)
                    .then((files) => onSuccess(files))
                    .catch((error) => onError(error))
                    .finally(() => hideMessage());
            }
        );
    }
};

