import { Guid } from "js-guid";
import { type } from "os";
import React from "react";
import { useEffect } from "react"
import { generalSettings, settings } from "../../App";
import { getUserToken } from "../../services/dashboard.service";
enum ActivityType {
    ADLogin,
    DashboardAccess,
    VMDesktopAccess,
    VMAppAccess
}

type LoginTrackerProps = {
}

export const LoginTracker: React.FC<LoginTrackerProps> = (props) => {

    useEffect(() => {
        updateLoginStatus();
    }, []);


    const updateLoginStatus = async () => {
        

        const isAlreadySent = window.sessionStorage.getItem("s365-login-tracker-id");
        if (!isAlreadySent) {
            try {
                sessionStorage.setItem("s365-login-tracker-id", Guid.newGuid().toString());

                const apiPath = `${settings.usageTrackingServiceUrl}/api/user-logins/activity`;
                const userToken = await getUserToken();
                const response = await fetch(apiPath, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.     
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`
                    },
                    body: JSON.stringify({ activityType: ActivityType.DashboardAccess }) // body data type must match "Content-Type" header
                });
                if (response.status != 200) {
                    const responseJson = await response.json();

                    console.log("An error occurred while logging user login activity.", responseJson);
                    throw "An error occurred while logging user login activity.";
                }
            } catch (error) {
                console.log("An error occurred while logging user login activity.", error);
                sessionStorage.removeItem("s365-login-tracker-id");
            }
        }
    }

    return <></>
}