import React from "react";
import { CalculationJobResultModel, CalculationRunJobStatus, ExperimentResponseModel } from "../../swagger-clients/ai-for-pfd-clients.service";
import { Button, Label } from "@fluentui/react-components";
import { CalculationJobStatusComponent } from "../details-experiment/result-data/calculation-job-status.component";
import { ArrowDownloadRegular } from "@fluentui/react-icons";
import { getExperimentsClient } from "../../services/ai-for-pfd.service";
import saveAs from "file-saver";
import { processServerError } from "../../utils/helpers/error.helper";
import { SaveExperimentFileModal } from "./save-experiment-file-modal.component";

type CalculationDetailTabProps = {
    experiment: ExperimentResponseModel;
    calculationJob: CalculationJobResultModel;
}

export const CalculationDetailTab: React.FC<CalculationDetailTabProps> = (props) => {
    const { experiment, calculationJob } = props;

    const [showSaveExperimentModal, setShowSaveExperimentModal] = React.useState<boolean>(false);

    const onDownloadFlowsheet = async () => {

        try {
            const client = getExperimentsClient();
            var response = await client.downloadFlowsheet(experiment.experimentVersionId, calculationJob.sequence, calculationJob.flowsheetId);

            saveAs(response.data, `Sequence_${calculationJob.sequence}_FlowsheetId_${calculationJob.flowsheetId}.dwxmz`);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while downloading flowsheet.");
        }


    }


    return <div>
        <div className="input-form" style={{ marginBottom: "20px" }}>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label input-form-label-xs">
                    Experiment:
                </Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{experiment?.name}</p>
            </div>


            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label input-form-label-xs">
                    Sequence:
                </Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{calculationJob?.sequence}</p>
            </div>
            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label input-form-label-xs">
                    Flowsheet ID:
                </Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{calculationJob?.flowsheetId}</p>
            </div>

            <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label input-form-label-xs">
                    Status:
                </Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}><CalculationJobStatusComponent status={calculationJob?.status} /></p>
            </div>
            {/* {!!calculationJob?.note && <div className="input-form-item input-form-item--without-margin">
                <Label className="input-form-label input-form-label-xs">
                    Note:
                </Label>
                <p style={{ padding: "5px 0px", margin: "0 0 0 10px" }}>{calculationJob.note}</p>
            </div>
            } */}

            {!!calculationJob && calculationJob.status == CalculationRunJobStatus.Calculated &&

                <>
                    <div className="input-form-item input-form-item--without-margin">
                        <Label className="input-form-label input-form-label-xs">
                            Flowsheet:
                        </Label>
                        <Button
                            style={{ margin: "0 0 0 10px" }}
                            appearance="primary"
                            icon={<ArrowDownloadRegular />}
                            onClick={() => { setShowSaveExperimentModal(true); }}
                        >Download</Button>
                    </div>
                    {showSaveExperimentModal && <SaveExperimentFileModal
                        defaultName={`${experiment?.name}_${calculationJob.sequence}_${calculationJob.flowsheetId}`}
                        experimentVersionId={experiment?.experimentVersionId}
                        flowsheetId={calculationJob.flowsheetId}
                        sequence={calculationJob.sequence}
                        onHide={() => { setShowSaveExperimentModal(false); }}
                    />}
                </>
            }
        </div>

    </div>
}