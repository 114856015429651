import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions, LogLevel } from '@microsoft/signalr';
import { toast } from "react-toastify";
import { getUserToken } from "../../services/dashboard.service";

type HubConnectionComponentProps = {
    baseUrl: string;
    hasRegister?: boolean;
}
export type HubConnectionComponentType = {
    hubConnection: HubConnection;
}


export const HubConnectionComponent = React.forwardRef<HubConnectionComponentType, HubConnectionComponentProps>((props, ref) => {
    const [hubConnection, setHubConnection] = React.useState<HubConnection>();
    React.useImperativeHandle(
        ref,
        () => ({
            hubConnection: hubConnection

        }));

    useEffect(() => {
        if (props.baseUrl) {
            onComponentInit();
        }

    }, [props.baseUrl]);

    const onComponentInit = async () => {

        const connectionHub = await InitializeConnectionHub();
        setHubConnection(connectionHub);
        if (connectionHub) {
            await connectToDashboardHub(connectionHub);
        }
    }


    const connectToDashboardHub = async (hubConnection: HubConnection) => {
        try {
            console.log("Called connectToDashboardHub", hubConnection);
            const token = await getUserToken();

            if (hubConnection.state !== HubConnectionState.Connected) {

                await hubConnection.start().catch(err => console.error("error while starting dashboard connection!", err));;
                 if(props.hasRegister){
                    await hubConnection.invoke("Register", token);
                 }
              
                console.log("connected");


            }
        } catch (err) {
            console.log("An error occured while connecting to HUB.", err);
            setTimeout(() => connectToDashboardHub(hubConnection), 5000);
        }
    }

    const InitializeConnectionHub = async (): Promise<HubConnection> => {
        try {

            let connectionHub = new HubConnectionBuilder()
                .withUrl(`${props.baseUrl}`, { accessTokenFactory: async () => { const token = await getUserToken(); return token; } } as IHttpConnectionOptions)
                .configureLogging(LogLevel.Information)
                .build();

            connectionHub.onclose(async () => {
                await connectToDashboardHub(connectionHub);
            });

            return connectionHub;
        } catch (error) {
            toast.error("An error occcurred while initializing connection to HUB.");
            console.log("An error occcurred while initializing connection to HUB.", error);
            return undefined;
        }

    }

    return <></>;
});