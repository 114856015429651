import moment from "moment";
import React from "react";
import TimeAgo from 'react-timeago';

type S365TimeAgoProps = {
    date: Date | moment.Moment;
    titleFormat?: string;
    maxPeriod?: number;
}

export const S365TimeAgo: React.FC<S365TimeAgoProps> = (props) => {

    const titleFormat = !!props.titleFormat ? props.titleFormat : "DD.MM.YYYY HH:mm";

    return <TimeAgo date={moment(props.date).local()} title={`${moment(props.date).local().format(titleFormat)}`} maxPeriod={props.maxPeriod} />
}