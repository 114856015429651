import { Button } from "@fluentui/react-components";
import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from "@fluentui/react-components";
import { Dismiss24Filled, Document24Regular, DocumentArrowRight24Regular, DocumentError24Regular, DocumentTable24Regular, DocumentTableCheckmark24Regular } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { getFilesClient } from "../../services/dashboard.service";
import { AnalysisStatus, ResubmitForAnalysisPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { getFileExtension } from "../file-type-icon/file-type-icon.helpers";
import { flowsheetExtensions } from "../../global.variables";

type AnalysisStatusIconProps = {
    fileUniqueIdentifier: string,
    fileVersionNumber: number,
    filename: string,
    readonly?: boolean,
    hubConnection?: HubConnection,
    onAnalysisStatusChange?: (status: AnalysisStatus) => void;
}

const hasAnalysisStatus = (extension: string) => {

    return flowsheetExtensions.indexOf(extension) > -1;
}


export const AnalysisStatusIcon: React.FC<AnalysisStatusIconProps> = (props) => {

    const [hasStatus, setHasStatus] = React.useState<boolean>(false);
    const [analysisStatus, setAnalysisStatus] = React.useState<AnalysisStatus>();
    const [open, setOpen] = React.useState<boolean>(false);

    useEffect(() => {
        if (props.fileUniqueIdentifier && props.fileVersionNumber && props.filename) {
            const extension = getFileExtension(props.filename);
            if (extension && hasAnalysisStatus(extension)) {
                setHasStatus(true);
                getAnalysisStatus();
            }
        }

    }, [props.fileUniqueIdentifier, props.fileVersionNumber]);

    useEffect(() => {
        props.onAnalysisStatusChange?.(analysisStatus);
    }, [analysisStatus]);

    useEffect(() => {
        if (props.hubConnection) {
            props.hubConnection.on("OnFlowsheetAnalysisStatusChanged", (fileUniqueIdentifier: string, fileVersionNumber: number, status: AnalysisStatus) => {
                if (props.fileUniqueIdentifier == fileUniqueIdentifier && props.fileVersionNumber) {
                    setAnalysisStatus(status);
                }
            });
        }
    }, []);


    const getAnalysisStatus = async () => {

        try {
            const client = getFilesClient();
            const statusResp = await client.getFileAnalysisStatus(props.fileUniqueIdentifier, props.fileVersionNumber);
            setAnalysisStatus(statusResp);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file analysis status.", false);
        }
    }

    const onResubmitForAnalysisClick = async () => {
        try {
            const client = getFilesClient();
            const model = new ResubmitForAnalysisPostModel({ fileUniqueIdentifier: props.fileUniqueIdentifier, fileVersionNumber: props.fileVersionNumber });
            await client.resubmitForAnalysis(model);
            setOpen(false);
            toast.success("File was resubmitted for analysis.");

        } catch (error) {
            processServerError(error, undefined, "An error occurred while resubmitting file for analysis.");
        }
    }
    if (!hasStatus)
        return <></>;


    return <>
        <Dialog open={open} onOpenChange={(event, data) => {
            event.preventDefault();
            setOpen(data.open);

        }}>
            <DialogSurface>

                <DialogBody>
                    <DialogTitle action={<Button appearance="transparent" onClick={() => setOpen(false)} icon={<Dismiss24Filled />} />}>Resubmit for analysis</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you want to resubmit file <b>'{props.filename}'</b> for analysis?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button appearance="primary" onClick={onResubmitForAnalysisClick}>Resubmit</Button>
                    </DialogActions>
                </DialogBody>

            </DialogSurface>
        </Dialog>
        {
            analysisStatus === undefined &&
            <span title="Not submitted for analysis" style={{ display: "block", textAlign: "center" }}>
                <Button appearance="transparent"
                    style={{ width: "100%", cursor: props.readonly ? "auto" : "pointer" }}
                    disabled={props.readonly}
                    onClick={() => setOpen(true)} icon={<Document24Regular primaryFill="lightgray" />} />
            </span>
        }
        {analysisStatus !== undefined && +analysisStatus == +AnalysisStatus.Submitted &&
            <span title="Submitted for analysis" style={{ display: "block", textAlign: "center" }}>
                <Button appearance="transparent"
                    style={{ cursor: props.readonly ? "auto" : "pointer" }}
                    disabled={props.readonly}
                    onClick={() => setOpen(true)} icon={<DocumentArrowRight24Regular primaryFill="orange" />} />
            </span>}
        {analysisStatus !== undefined && +analysisStatus == +AnalysisStatus.Fail &&
            <span title="Analysis failed" style={{ display: "block", textAlign: "center" }}>
                <Button appearance="transparent"
                    style={{ cursor: props.readonly ? "auto" : "pointer" }}
                    disabled={props.readonly}
                    onClick={() => setOpen(true)} icon={<DocumentError24Regular primaryFill="red" />} />
            </span>}
        {analysisStatus !== undefined && +analysisStatus == +AnalysisStatus.Success &&
            <span title="Analyzed successfully" style={{ display: "block", textAlign: "center" }}>
                <Button appearance="transparent"
                    style={{ cursor: props.readonly ? "auto" : "pointer" }}
                    disabled={props.readonly}
                    onClick={() => setOpen(true)} icon={<DocumentTableCheckmark24Regular primaryFill="green" />} />

            </span>}
    </>;

}