import IdealMixtureDistillationImage from "./../../assets/images/examples/copilot/Copilot_Example_IdealMixtureDistillation.png";
import ExtractiveDistillationImage from "./../../assets/images/examples/copilot/Copilot_Example_Extractive Distillation.png";
import PressureSwingDistillationImage from "./../../assets/images/examples/copilot/Copilot_Example_PressureSwingDistillation.png";

export type CopilotExampleObject = {
    [scenarioId: string]: { image: string, title: string, index: number };
}
type Environment = "development" | "staging" | "production";

type CopilotExampleEnvironmentMapping = {
    [K in Environment]: CopilotExampleObject;
};
// Object.keys returns sorted list of ids for CopilotExampleMappings
export const CopilotExampleIds = {
    "development": [12, -2, 22],
    "staging": [42, 74, 72],
    "production": [10, 21, 18]
}

export const CopilotExampleMappings: CopilotExampleEnvironmentMapping = {
    "development": {
        "12": { title: "Ideal Mixture Distillation", image: IdealMixtureDistillationImage, index: 1 },
        "-2": { title: "Pressure Swing Distillation", image: PressureSwingDistillationImage, index: 2 },
        "22": { title: "Extractive Distillation", image: ExtractiveDistillationImage, index: 3 },
    },
    "staging": {
        "42": { title: "Ideal Mixture Distillation", image: IdealMixtureDistillationImage, index: 1 },
        "74": { title: "Pressure Swing Distillation", image: PressureSwingDistillationImage, index: 2 },
        "72": { title: "Extractive Distillation", image: ExtractiveDistillationImage, index: 3 }
    },
    "production": {
        "10": { title: "Ideal Mixture Distillation", image: IdealMixtureDistillationImage, index: 1 },
        "21": { title: "Pressure Swing Distillation", image: PressureSwingDistillationImage, index: 2 },
        "18": { title: "Extractive Distillation", image: ExtractiveDistillationImage, index: 3 }
    }
}