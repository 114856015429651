import React from "react";
import { SpecificationPropertyModel } from "../../edit-experiment.models";
import { Field, Input, Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { GetSpecificationPropertyName, GetSpecificationPropertyType, GetSpecificationPropertyUnit } from "../../edit-experiment.helpers";
import { IExperimentValidation } from "../../edit-experiment.validation";
import { SeparationMethodOption } from "../../../../swagger-clients/ai-for-pfd-clients.service";

type SpecificationTabProps = {
    specifications: SpecificationPropertyModel[];
    separationMethod: SeparationMethodOption;
    onChange?: (specifications: SpecificationPropertyModel[]) => void;
    isDetails?: boolean;
    isFormSubmitted?: boolean;
    experimentValidation?: IExperimentValidation;
}

export const SpecificationTab: React.FC<SpecificationTabProps> = (props) => {

    const onSpecificationChange = (index: number, property: string, value: string) => {
        const updatedSpecifications = props.specifications.map((item, i) => {
            if (index == i) {
                const isInt = GetSpecificationPropertyType(item.specificationProperty) == "int";
                const parsedValue = isInt && value !== undefined && value != "" ? parseInt(value) : value as any;

                return { ...item, [property]: parsedValue != "" ? parsedValue : undefined } as SpecificationPropertyModel;
            }
            return item;
        });
        props.onChange([...updatedSpecifications]);
    }

    console.log("Specifications", props.specifications);

    return <div>
        <Table>
            <TableHeader>
                <TableRow>

                    <TableHeaderCell key="name" className='table__cell--bold' style={{ width: "300px" }}>Name</TableHeaderCell>
                    <TableHeaderCell key="type" className='table__cell--bold column--center' style={{ width: "50px" }}>Type</TableHeaderCell>
                    <TableHeaderCell key="lowerbound" className='table__cell--bold column--center' style={{ width: "200px" }}>Lower bound</TableHeaderCell>
                    <TableHeaderCell key="default" className='table__cell--bold column--center' style={{ width: "200px" }}>Default</TableHeaderCell>
                    <TableHeaderCell key="upperbound" className='table__cell--bold column--center' style={{ width: "200px" }}>Upper bound</TableHeaderCell>
                    <TableHeaderCell key="unit" className='table__cell--bold' style={{ width: "200px" }}>Unit</TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBodyWithLoading isLoading={false}
                columnCount={7} loadingMessage="Loading..."
                itemCount={props.specifications ? props.specifications.length : 0}
                noItemsMessage="No specifications found.">
                {props.specifications && props.specifications.length > 0 && props.specifications.map((item, index) => {

                    const specificationValidation = props.experimentValidation?.specifications?.[index];

                    return <TableRow key={`specification-${item.specificationProperty}`} >

                        <TableCell>{GetSpecificationPropertyName(item.specificationProperty, props.separationMethod)}</TableCell>
                        <TableCell className="column--center">{GetSpecificationPropertyType(item.specificationProperty)}</TableCell>
                        <TableCell className="column--center">
                            {props.isDetails && <span>{item.lowerBoundValue}</span>}
                            {!props.isDetails && <Field
                                validationMessage={props.isFormSubmitted && !!specificationValidation &&
                                    !specificationValidation.lowerBoundValue.isValid && specificationValidation.lowerBoundValue.validationErrors[0]}
                                validationState={props.isFormSubmitted && !!specificationValidation &&
                                    !specificationValidation.lowerBoundValue.isValid ? "error" : "none"}
                            >
                                <Input
                                    type="number"
                                    value={item.lowerBoundValue?.toString() ?? ""}
                                    readOnly={props.isDetails}
                                    onChange={(ev, data) => { onSpecificationChange(index, "lowerBoundValue", data.value) }}
                                    style={{ width: "100%" }}
                                />
                            </Field>
                            }
                        </TableCell>
                        <TableCell className="column--center">
                            {props.isDetails && <span>{item.defaultValue}</span>}
                            {!props.isDetails && <Field
                                validationMessage={props.isFormSubmitted && !!specificationValidation &&
                                    !specificationValidation.defaultValue.isValid && specificationValidation.defaultValue.validationErrors[0]}
                                validationState={props.isFormSubmitted && !!specificationValidation &&
                                    !specificationValidation.defaultValue.isValid ? "error" : "none"}>
                                <Input
                                    type="number"
                                    value={item.defaultValue?.toString() ?? ""}
                                    readOnly={props.isDetails}
                                    onChange={(ev, data) => { onSpecificationChange(index, "defaultValue", data.value) }}
                                    style={{ width: "100%" }}
                                />
                            </Field>
                            }
                        </TableCell>
                        <TableCell className="column--center">
                            {props.isDetails && <span>{item.upperBoundValue}</span>}
                            {!props.isDetails && <Field
                                validationMessage={props.isFormSubmitted && !!specificationValidation &&
                                    !specificationValidation.upperBoundValue.isValid && specificationValidation.upperBoundValue.validationErrors[0]}
                                validationState={props.isFormSubmitted && !!specificationValidation &&
                                    !specificationValidation.upperBoundValue.isValid ? "error" : "none"}>
                                <Input
                                    type="number"
                                    value={item.upperBoundValue?.toString() ?? ""}
                                    readOnly={props.isDetails}
                                    onChange={(ev, data) => { onSpecificationChange(index, "upperBoundValue", data.value) }}
                                    style={{ width: "100%" }}
                                />
                            </Field>
                            }
                        </TableCell>
                        <TableCell>{GetSpecificationPropertyUnit(item.specificationProperty)}</TableCell>
                    </TableRow>
                })}
            </TableBodyWithLoading>
        </Table>
    </div>
}