import React from "react";
import { HideSidebarSectionInTour, ShowSidebarSectionInTour } from "./tour-guide.shared";

type ExamsTourProps = {

}

export const ExamsTour: React.FC<ExamsTourProps> = (props) => {
    React.useEffect(() => {

        ShowSidebarSectionInTour("exams_sidebar_link",false);

        return () => {
            // Close my work menu section
            HideSidebarSectionInTour("exams_sidebar_link",false);
        }
    }, [])
    return <div style={{   marginTop: "var(--spacingVerticalMNudge)",   marginBottom: "var(--spacingVerticalMNudge)" }}>
        <p>Access your Take-Home Exercises/Exams. This functionality is designed for universities and is a great tool for creating personalized simulation tasks.</p>
        <span style={{ fontWeight: "bold" }}>Power tip!</span>
        <br />
        <span>You can transform lectures and seminars from in-class teaching to online self-study and exam settings in a few easy steps.
             Check out our Examples in the My Work section and see Help for step-by-step tutorials.</span>
    </div>
}