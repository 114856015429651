import React from "react";
import { SelectOption } from "../../../components/has-access-side-people-picker/has-access-side-people-picker.component";
import ReactSelect, { ActionMeta, GroupBase, StylesConfig, components } from "react-select";
import { processServerError } from "../../../utils/helpers/error.helper";
import { getGroupsClient } from "../../../services/dashboard.service";
import { PersonDisplay } from "../../../components/person-display/person-display.component";
import { useLoading } from "../../../utils/loading-indicator.component";
import { GroupTableResultModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";

type UserOwnedGroupPickerProps = {
    style?: React.CSSProperties;
    placeholder?: string;
    onChange: (selectedOption: SelectOption) => void;
}

export const UserOwnedGroupPicker: React.FC<UserOwnedGroupPickerProps> = (props) => {

    const [currentValue, setCurrentValue] = React.useState<string>("");
    const [isLoading, loadingService] = useLoading();
    const [options, setOptions] = React.useState<SelectOption[]>([]);
    const [groups, setGroups] = React.useState<GroupTableResultModel[]>([]);


    React.useEffect(() => { getGroups(); }, []);


    const Option = (props) => {
        const person = props.data as SelectOption;

        return <components.Option {...props}>
            <PersonDisplay id={person.label} name={person.label} userPrincipalName={person.userPrincipalName} />

        </components.Option>
    }

    const onParametersChanged = async (newOption: SelectOption, actionMeta: ActionMeta<SelectOption>) => {
        if (actionMeta.action == "select-option") {
            props.onChange(newOption);
        } else {
            props.onChange(undefined);
        }

    }

    React.useEffect(() => {
        updateSuggestions();
    }, [groups]);

    const updateSuggestions = () => {
        const groupOptions = groups?.map((group) => ({ label: group.groupname, value: group.id } as SelectOption));

        setOptions(groupOptions);
    }

    const getGroups = async () => {
        loadingService.showLoading("Getting user groups...", async (hideMessage) => {
            try {


                const client = getGroupsClient();
                const groupsResp = await client.getUserOwnedGroups();
                const activeGroups = groupsResp?.filter(x => !x.isDeleted) ?? [];
                setGroups(activeGroups);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting user groups.");
            } finally {
                hideMessage();
            }
        });

    }


    return <div style={{ ...props.style }}>
        <ReactSelect
            isLoading={isLoading}
            isClearable
            placeholder={props.placeholder}
            options={options}
            onInputChange={(newValue: string) => { setCurrentValue(newValue); }}
            onChange={onParametersChanged}
            components={{ Option }}
            styles={ContactPickerStyless} />
    </div>

}
const ContactPickerStyless: StylesConfig<SelectOption, false, GroupBase<SelectOption>> = {
    control: (styles) => ({ ...styles, minHeight: "30px", borderBottomColor: "var(--colorNeutralStrokeAccessible) !important" }),
    dropdownIndicator: (styles) => ({
        ...styles,
        paddingTop: 5,
        paddingBottom: 5,
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display: "none"
    }),
    clearIndicator: (styles) => ({
        ...styles,
        paddingTop: 5,
        paddingBottom: 5,
    }),
    input: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    option: (styles) => ({
        ...styles,
        paddingTop: "4px",
        paddingBottom: "4px",
        lineHeight: "16px"
    }),
};

