import { IValidationResultData, ValidationResult } from "../../../utils/helpers/validation.helpers";
import { IExam, IExercise } from "../../shared/exams.models";

export const validate = (exam: IExam): ValidationResult => {

    let result = {
        fieldsErrors: {},
        globalErrors: []
    } as IValidationResultData;


    //Validate only exercise output parameters (their values)
    if (exam) {
        exam.exercises.forEach((exercise, exIndex) => {
            if (exercise.subExercises && exercise.subExercises.length > 0) {
                exercise.subExercises.forEach((subexercise, subExIndex) => {
                    ValidateExercise(subexercise, result, subExIndex);
                });
            } else {
                ValidateExercise(exercise, result, undefined);
            }




        });
    }

    return new ValidationResult(result);
}

const ValidateExercise = (exercise: IExercise, result: IValidationResultData, subExIndex?: number) => {
    exercise.outputParameters.forEach((outParam, outIndex) => {

        if (outParam.value && !/^\-{0,1}\d+(\.\d+)?$/.test(outParam.value.toString())) {
            let columnId = `ex${exercise.id}`;
            if (subExIndex !== undefined) {
                columnId += `_${subExIndex}`;
            }
            columnId += `_p${outParam.id}`;
            result.fieldsErrors[columnId] = "Incorrect number format.";
        }
    });
}