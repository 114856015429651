import React from "react";
import { FlowsheetParameterAccessMode } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { Tooltip, tokens } from "@fluentui/react-components";

export type FlowsheetParameterAccessModeComponentProps = {
    accessMode: FlowsheetParameterAccessMode;
}

export const FlowsheetParameterAccessModeComponent: React.FC<FlowsheetParameterAccessModeComponentProps> = (props) => {


    return <Tooltip content={getAccessModeTitle(props.accessMode)} relationship="label" {...props}>
        <span style={{color:tokens.colorNeutralForegroundDisabled}}>{getAccessModeLabel(props.accessMode)}</span>
    </Tooltip>
}

const getAccessModeTitle = (accessMode: FlowsheetParameterAccessMode) => {
    switch (accessMode) {
        case FlowsheetParameterAccessMode.ReadOnly:
            return "Read";
        case FlowsheetParameterAccessMode.WriteOnly:
            return "Write";
        case FlowsheetParameterAccessMode.ReadWrite:
            return "Read/Write";
        default:
            return "";
    }
}

const getAccessModeLabel = (accessMode: FlowsheetParameterAccessMode) => {
    switch (accessMode) {
        case FlowsheetParameterAccessMode.ReadOnly:
            return "R";
        case FlowsheetParameterAccessMode.WriteOnly:
            return "W";
        case FlowsheetParameterAccessMode.ReadWrite:
            return "RW";
        default:
            return "";
    }
}