import React from "react";
import FlowsheetObjectService from "../../services/flowsheet-objects.service";
import { FlowsheetObjectModel, FlowsheetObjectType, FlowsheetParameterModel } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { Tooltip, tokens } from "@fluentui/react-components";
import getFlowsheetObjectByParameter from "../paramater-picker-next/parameter-to-flowsheet-objects-maper";

type ParameterDisplayProps = {
    fileUniqueId: string;
    versionNumber: string;
    value: string;
    showTooltip?: boolean;
};

type ParameterDisplayState = {
    unitOperation?: FlowsheetObjectModel;
    stream?: FlowsheetObjectModel;
    component?: FlowsheetObjectModel;
    parameter?: FlowsheetParameterModel;
}

export const ParameterDisplay: React.FC<ParameterDisplayProps> = (props) => {

    const [unitOperation, setUnitOperation] = React.useState<FlowsheetObjectModel>();
    const [stream, setStream] = React.useState<FlowsheetObjectModel>();
    const [component, setComponent] = React.useState<FlowsheetObjectModel>();
    const [parameter, setParameter] = React.useState<FlowsheetParameterModel>();
    const [tooltipValue, setTooltipValue] = React.useState<string>();
    const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

    React.useEffect(() => {
        onInit();
    }, []);

    const onInit = async () => {

        try {
            const { fileUniqueId, versionNumber, value } = props;
            const flowsheetObjects = await FlowsheetObjectService.getFlowsheetObjectsPromise("s365v2", fileUniqueId, versionNumber);

            var objects = getFlowsheetObjectByParameter(value, flowsheetObjects);

            let unitOp, stream, component, parameter;

            objects.forEach(o => {
                if ((o as FlowsheetObjectModel).objectType !== undefined) {
                    if (o.objectType == FlowsheetObjectType.Component) {
                        component = o;
                    } else if (o.objectType == FlowsheetObjectType.Stream) {
                        stream = o;
                    } else if (o.objectType == FlowsheetObjectType.UnitOp) {
                        unitOp = o;
                    }
                }
                else if ((o as FlowsheetParameterModel).parameter !== undefined) {

                    parameter = o;
                    const parameterCasted = o as FlowsheetParameterModel;
                    const valueWithUnit = `${parameterCasted.value} ` + (!!parameterCasted.unit ? `${parameterCasted.unit}` : "");
                    setTooltipValue(valueWithUnit);
                }
            });
            setUnitOperation(unitOp);
            setComponent(component);
            setStream(stream);
            setParameter(parameter);


        } catch (error) {
            console.log("An error occurred while initializing Parameter display component.", error);
        }

    }
    // console.log("unitOperation,stream,component,parameter", unitOperation, stream, component, parameter);

    return <Tooltip relationship="description"
        positioning={"above"}
        content={tooltipValue}
        withArrow
        visible={showTooltip && props.showTooltip && !!tooltipValue}
        onVisibleChange={(ev, data) => { setShowTooltip(data.visible); }}
    >
        <div style={{ display: "flex", flexDirection: "column", wordBreak: "break-all" }}>
            {unitOperation && <span>
                <span style={{ height: "0.8rem", color: tokens.colorNeutralForeground3 }}>U:</span> {unitOperation.displayName}
            </span>}
            {stream && <span>
                <span style={{ height: "0.8rem", color: tokens.colorNeutralForeground3 }}>S:</span> {stream.displayName}
            </span>}
            {component && <span>
                <span style={{ height: "0.8rem", color: tokens.colorNeutralForeground3 }}>C:</span> {component.displayName}
            </span>}

            {parameter && <span>
                <span style={{ height: "0.8rem", color: tokens.colorNeutralForeground3 }}>P:</span> {parameter.displayName}
                {parameter.unit && <>&nbsp;<span style={{ color: tokens.colorNeutralForeground3 }}>({parameter.unit})</span></>}
            </span>}
        </div>
    </Tooltip>;
}