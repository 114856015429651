import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { toast } from "react-toastify";


type ConfirmationDialogProps = {
    title: string;
    subText: string | JSX.Element;
    isOpened?: boolean,
    confirmLabel?: string,
    cancelLabel?: string,
    isRequestInProgress?: boolean;
    onConfirm: () => void;
    onClose: () => void;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);



    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }

    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={!props.isRequestInProgress ? onModalClose : undefined}
                    icon={!props.isRequestInProgress ? <Dismiss24Filled /> : undefined} />}>{props.title}</DialogTitle>
                <DialogContent>
                    <p>{props.subText}</p>
                </DialogContent>
                <DialogActions>
                    <Button appearance="primary"
                        onClick={props.onConfirm}
                        disabled={props.isRequestInProgress}>
                        {props.confirmLabel ? props.confirmLabel : "Continue"}</Button>
                    <Button appearance="secondary"
                        disabled={props.isRequestInProgress}
                        onClick={onModalClose}>{props.cancelLabel ? props.cancelLabel : "Cancel"}</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}