import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input } from "@fluentui/react-components";
import { Alert } from "@fluentui/react-components/unstable";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { getFileNameWithoutExtension } from "../../files/file-type-icon/file-type-icon.helpers";
import { getFiltersClient } from "../../services/dashboard.service";
import { GLOBAL_STYLES } from "../../styles";
import { CreateExcelFilePostModel, FileModel, FilterResultModel, UploadFileResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { SaveFileModal, SaveFileModel } from "../../components/save-file-modal/save-file-modal.component";
import { GenerateExcelFileStatusModal } from "./generate-excel-file-status.component";
type GenerateExcelFileProps = {
    isOpened: boolean;
    parentDirectoryUniqueId: string;
    selectedFile: FileModel;
    selectedFilter: FilterResultModel;
    onOpenedChange: (isOpened: boolean) => void;

};


export const GenerateExcelFile: React.FC<GenerateExcelFileProps> = (props) => {
    const [isLoading, loadingService] = useLoading();
    const [defaultName, setDefaultName] = React.useState<string>();
    const [uploadResponse, setUploadResponse] = React.useState<UploadFileResponseModel>();
    const [isUploadError, setIsUploadError] = React.useState<boolean>(false);
    const [showUploadProgressModal, setShowUploadProgressModal] = React.useState<boolean>(false);


    React.useEffect(() => {
        if (props.selectedFile && !!props.selectedFilter?.name && !defaultName) {
            console.log("setting default name value");
            setDefaultName(`${getFileNameWithoutExtension(props.selectedFile.name)} - ${props.selectedFilter.name}`);

        }
    }, [props.selectedFile, props.selectedFilter]);


    const onSubmit = async (data: SaveFileModel) => {
        setIsUploadError(false);
        setShowUploadProgressModal(true);
        loadingService.showLoading("Generating file...", async (hideMessage) => {
            console.log("GenerateExcelFileModal data", data);
            try {

                const client = getFiltersClient();
                const model = new CreateExcelFilePostModel({
                    filename: data.name,
                    filterId: props.selectedFilter.id!,
                    parentDirectoryUniqueId: data.parentDirectoryUniqueId,
                    conflictAction: data.conflictAction
                });
                const resp = await client.createExcelFile(model);
                setUploadResponse(resp);
                toast.success("Excel file generated!");

            } catch (error) {
                processServerError(error, undefined, "An error occurred while generating excel file.",false);
                setIsUploadError(true);
            } finally {
                hideMessage();
            }
        });
    }


    return <>
        {props.isOpened &&
            <SaveFileModal
                isOpened={true}
                modalTitle={"Generate Excel file"}
                fileExtension="xlsx"
                defaultName={defaultName}
                fileExtensionText="Excel Workbook (*.xlsx)"
                parentDirectoryUniqueId={props.parentDirectoryUniqueId}
                onHide={() => { props.onOpenedChange(false); }}
                onSuccess={onSubmit} />}

        {showUploadProgressModal && <GenerateExcelFileStatusModal
            isError={isUploadError}
            isLoading={isLoading}
            isOpened={showUploadProgressModal}
            uploadedFile={uploadResponse}
            onHide={() => { setShowUploadProgressModal(false); setUploadResponse(undefined); setIsUploadError(false); props.onOpenedChange(false); }}
        />}
    </>

}