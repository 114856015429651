import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, Label } from "@fluentui/react-components";
import { Code20Regular, Copy20Regular, Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { getFiltersClient } from "../../services/dashboard.service";
import { toast } from "react-toastify";
import { processServerError } from "../../utils/helpers/error.helper";
import { useParams } from "react-router-dom";
import { FiltersRouteParams } from "../filters.component";
import nl2br from 'nl2br';
import { Text } from "@fluentui/react-components";

type EmbedOrShareWebFormModalProps = {
    isOpened?: boolean;
    onClose?: () => void;
}

export const EmbedOrShareWebFormModal: React.FC<EmbedOrShareWebFormModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const routeParams = useParams<FiltersRouteParams>();
    const [webFormUrl, setWebFormUrl] = React.useState<string>();
    const [embedCode, setEmbedCode] = React.useState<string>();

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    React.useEffect(() => {
        getWebFormUrl();
    }, []);


    React.useEffect(() => {

        const code = `<script>    
        window.addEventListener('message', function(e) {      
            let message = e.data;
            if (message.type === 'update-s365-webform' && message.height) {
              let iframe = document.querySelector("#s365-webform-iframe");  
              iframe.height = Math.ceil(message.height) + 'px';
            }
          },
          false
        );
      </script>
      <iframe id="s365-webform-iframe"
        src="${webFormUrl}"
        width="100%"
        height="720"
        style="border: 0"
      ></iframe>`;
        setEmbedCode(code);

    }, [webFormUrl]);

    const onCopyWebFormUrl = async () => {
        if (!!webFormUrl) {
            navigator.clipboard.writeText(webFormUrl);
            toast.success("Web Form URL copied.", { autoClose: 2000 });
        }
    }

    const onCopyEmbedCode = async () => {
        if (!!embedCode) {
            navigator.clipboard.writeText(embedCode);
            toast.success("Embed code copied.", { autoClose: 2000 });
        }
    }

    const getWebFormUrl = async () => {

        try {
            const client = getFiltersClient();
            const webFormIdResp = await client.getPublicWebFormId(+routeParams.filterId!);
            if (!!webFormIdResp?.id) {
                let baseUrl = window.location.origin;
                const url = `${baseUrl}/wf/${encodeURIComponent(webFormIdResp.id)}`
                setWebFormUrl(url);
            }

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting web form unique ID.");
        }
    }

    const onModalClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    }


    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface style={{ maxWidth: "700px" }}>
            <DialogBody style={{ maxWidth: "inherit" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                    Embed or Share Web Form
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", gap: "20px", marginTop:"10px" }}>
                        <Button icon={<Copy20Regular />} onClick={onCopyWebFormUrl}>Copy Web Form URL</Button>
                        <Button icon={<Code20Regular />} onClick={onCopyEmbedCode}>Copy Embed code</Button>
                    </div>
                    <br/>
                    <Label style={{fontWeight:"bold"}}>Embed Code:</Label>
                    <Text style={{ whiteSpace: 'pre-wrap', margin:"5px", backgroundColor:"var(--colorNeutralBackground1Hover)" }}>
                       <div style={{ whiteSpace: 'pre-wrap', padding:"7px", backgroundColor:"var(--colorNeutralBackground1Hover)" }}>{embedCode}</div> 
                    </Text>
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}

