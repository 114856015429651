import { Table, TableBody, TableCell, TableColumnDefinition, TableColumnSizingOptions, TableHeader, TableHeaderCell, TableRow, createTableColumn, useTableColumnSizing_unstable, useTableFeatures } from "@fluentui/react-components";
import React from "react";
import { ParameterDisplay } from "../../components/parameter-display/parameter-display.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { getParameterTypeText } from "../../filters/filter-utilities";
import { FileModel, FilterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { IDropdownOption } from "../../utils/shared.types";
import { StudyInputParamterType, StudyParamterRange } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { CustomStudyInputParameterType, SensitivityStudy, SensitivityStudyInputParameter } from "../edit-sensitivity-study/edit-sensitivity-study.interfaces";
import { getMinMaxTotalVariationsCount, getStepsBeforeAfterVariationsCount } from "../utilities/total-variations-calculator";
import { SignificantFigures } from "../../components/significant-figures/significant-figures.component";

type StudyInputParametersDetailsTabProps = {
    selectedStudy: SensitivityStudy;
    filter: FilterResultModel;
    selectedFile: FileModel;
    isLoading: boolean;
}
const parameterTypeDropdownOptions: IDropdownOption[] = [{
    key: 0,
    text: "Fixed value"
},
{
    key: 1,
    text: "Range"
}];

export const StudyInputParametersDetailsTab: React.FunctionComponent<StudyInputParametersDetailsTabProps> = (props) => {
    const isMinMax = props.selectedStudy && props.selectedStudy.studyParamterRange == StudyParamterRange.MinMax;
    const [columns, setColumns] = React.useState<TableColumnDefinition<SensitivityStudyInputParameter>[]>([]);

    const items = props.selectedStudy ? [...props.selectedStudy.sensitivityStudyInputParameters] : [];
    
    React.useEffect(() => {
        const columnsResp = getColumns();
        setColumns(columnsResp);
    }, [items]);

    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({

        parameter: {
            minWidth: 100,
            defaultWidth: 300,
        },
        alias: {
            minWidth: 100,
            defaultWidth: 200,
        },
        parameterType: {
            minWidth: 100,
            defaultWidth: 200,
        },
        value: {
            minWidth: 50,
            defaultWidth: 100
        },
        min: {
            minWidth: 50,
            defaultWidth: 100
        },
        max: {
            minWidth: 50,
            defaultWidth: 100

        },
        stepBefore: {
            minWidth: 50,
            defaultWidth: 100

        },
        stepAfter: {
            minWidth: 50,
            defaultWidth: 100

        },
        stepSize: {
            minWidth: 50,
            defaultWidth: 100

        },
        totalVariations: {
            minWidth: 50,
            defaultWidth: 100

        },
    });



    const getParameterNameCell = (item: SensitivityStudyInputParameter, index: number) => {
        const inputParamater = (item as any).inputFilterParameter;
        return <span style={{ verticalAlign: "middle" }}>{inputParamater && props.filter ? <ParameterDisplay
            value={inputParamater.name}
            fileUniqueId={props.selectedFile.uniqueIdentifier}
            versionNumber={props.selectedFile.currentVersionNumber.toString()}

        /> : ""}</span>;
    }
    const getParameterAliasCell = (item: SensitivityStudyInputParameter, index: number) => {
        const inputParamater = (item as any).inputFilterParameter;
        return <span style={{ verticalAlign: "middle" }}>{inputParamater?.alias}</span>;
    }

    const getParameterTypeCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue) {
            return "Fixed value (set by filter)";
        }

        if (item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "Formula";
        }


        const selectedParamType = parameterTypeDropdownOptions.find(paramType => paramType.key == item.valueType);
        return <p style={{ margin: 0 }}>{selectedParamType ? selectedParamType.text : ""} </p>
    }

    const getParameterValueCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return item.filterParameterValue ? item.filterParameterValue : "";
        }
        if (item.valueType == StudyInputParamterType.Range && props.selectedStudy.studyParamterRange == StudyParamterRange.MinMax) {
            return "";
        } else {
            return <p style={{ margin: 0 }}>{item.value ? <SignificantFigures value={+item.value} significantFigures={5} /> : ""}</p>
        }
    }
    const getStepSizeCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }
        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {

            return <p style={{ margin: 0 }}>{item.stepSize}</p>
        }
    }

    const getTotalVariationsCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }

        if (props.selectedStudy.studyParamterRange == StudyParamterRange.MinMax && item.valueType == StudyInputParamterType.Range) {
            if (item.minValue && item.maxValue && item.stepSize) {
                const totalVariations = getMinMaxTotalVariationsCount(item.minValue, item.maxValue, item.stepSize);
                return <p style={{ margin: 0 }}>{totalVariations}</p>
            } else {
                return "";
            }
        }
        if (props.selectedStudy.studyParamterRange == StudyParamterRange.InitialValueWithSteps && item.valueType == StudyInputParamterType.Range) {
            if (item.stepsBefore && item.stepsAfter) {
                const totalVariations = getStepsBeforeAfterVariationsCount(item.stepsBefore, item.stepsAfter);
                return <p style={{ margin: 0 }}>{totalVariations}</p>
            } else {
                return "";
            }
        }

        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {
            return <p style={{ margin: 0 }}>{item.totalVariations ? item.totalVariations.toString() : ""}</p>
        }
    }

    const getStepsBeforeCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }
        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {
            return <p style={{ margin: 0 }}>{item.stepsBefore ? <SignificantFigures value={+item.stepsBefore} significantFigures={5} /> : ""}</p>
        }
    }

    const getStepsAfterCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }
        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {

            return <p style={{ margin: 0 }}>{item.stepsAfter ? <SignificantFigures value={+item.stepsAfter} significantFigures={5} /> : ""}</p>
        }
    }

    const getMinValueCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }
        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {

            return <p style={{ margin: 0 }}>{item.minValue ? <SignificantFigures value={+item.minValue} significantFigures={5} /> : ""}</p>
        }
    }
    const getMaxValueCell = (item: SensitivityStudyInputParameter, index: number) => {
        if (item.parameterType == CustomStudyInputParameterType.FixedFilterValue || item.parameterType == CustomStudyInputParameterType.FilterFormula) {
            return "";
        }
        if (item.valueType === StudyInputParamterType.FixedValue) {
            return "";
        } else {
            return <p style={{ margin: 0 }}>{item.maxValue ? <SignificantFigures value={+item.maxValue} significantFigures={5} /> : ""}</p>
        }

    }

    const getColumns = () => {
        let columnsDef: TableColumnDefinition<SensitivityStudyInputParameter>[] = [
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "parameter",
                renderHeaderCell: () => <>Parameter</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.id == item.id);
                    return getParameterNameCell(item, index);

                }
            }),
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "alias",
                renderHeaderCell: () => <>Alias</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.id == item.id);
                    return getParameterAliasCell(item, index);

                }
            }),
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "parameterType",
                renderHeaderCell: () => <>Parameter Type</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.id == item.id);
                    return getParameterTypeCell(item, index);

                }
            }),
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "value",
                renderHeaderCell: () => <>Value</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.id == item.id);
                    return getParameterValueCell(item, index);

                }
            })
        ];

        if (isMinMax) {

            columnsDef.push(...[
                createTableColumn<SensitivityStudyInputParameter>({
                    columnId: "min",
                    renderHeaderCell: () => <>Min</>,
                    renderCell: (item: SensitivityStudyInputParameter) => {
                        const index = items.findIndex(x => x.id == item.id);
                        return getMinValueCell(item, index);

                    }
                }),
                createTableColumn<SensitivityStudyInputParameter>({
                    columnId: "max",
                    renderHeaderCell: () => <>Max</>,
                    renderCell: (item: SensitivityStudyInputParameter) => {
                        const index = items.findIndex(x => x.id == item.id);
                        return getMaxValueCell(item, index);
                    }
                })
            ]);

        } else {
            columnsDef.push(...[
                createTableColumn<SensitivityStudyInputParameter>({
                    columnId: "stepBefore",
                    renderHeaderCell: () => <>Step before</>,
                    renderCell: (item: SensitivityStudyInputParameter) => {
                        const index = items.findIndex(x => x.id == item.id);
                        return getStepsBeforeCell(item, index);

                    }
                }),
                createTableColumn<SensitivityStudyInputParameter>({
                    columnId: "stepAfter",
                    renderHeaderCell: () => <>Step after</>,
                    renderCell: (item: SensitivityStudyInputParameter) => {
                        const index = items.findIndex(x => x.id == item.id);
                        return getStepsAfterCell(item, index);

                    }
                })
            ]);
        }


        columnsDef.push(...[
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "stepSize",
                renderHeaderCell: () => <>Step size</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.id == item.id);
                    return getStepSizeCell(item, index);

                }
            }),
            createTableColumn<SensitivityStudyInputParameter>({
                columnId: "totalVariations",
                renderHeaderCell: () => <>Total variations</>,
                renderCell: (item: SensitivityStudyInputParameter) => {
                    const index = items.findIndex(x => x.id == item.id);
                    return getTotalVariationsCell(item, index);
                }
            })
        ]);

        return columnsDef;
    }
    
    

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<SensitivityStudyInputParameter>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    return <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
        <TableHeader>
            <TableRow key="table-header">
                {columns.map((column) => (
                    <TableHeaderCell
                        onDragStart={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        className={`table__cell--bold`}
                        {...columnSizing_unstable.getTableHeaderCellProps(
                            column.columnId
                        )}
                    >
                        {column.renderHeaderCell()}
                    </TableHeaderCell>
                ))}
            </TableRow>
        </TableHeader>
        <TableBodyWithLoading isLoading={props.isLoading}
            columnCount={8} loadingMessage="Loading..."
            itemCount={items ? items.length : 0}
            noItemsMessage="No items found.">
            {items &&
                items.map((item, index) => {
                    return <TableRow key={`input-param-${index}`}>
                        {columns.map((column) => (
                            <TableCell
                                {...columnSizing_unstable.getTableCellProps(column.columnId)}
                            >
                                {column.renderCell(item)}
                            </TableCell>
                        ))}

                    </TableRow>
                })

            }

        </TableBodyWithLoading>
    </Table>
}