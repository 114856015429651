import { Dropdown, Option, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Field, Button, Input } from "@fluentui/react-components";


import { ChevronDownRegular, ChevronUpRegular, Dismiss20Regular } from "@fluentui/react-icons";
import React from "react";
import ParameterPickerNext, { ParameterAccessModel } from "../../components/paramater-picker-next/parameter-picker-next.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { FileModel, FilterParameterType, FilterParameterTypePostModel, FilterResultModel, OutputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { IDropdownOption } from "../../utils/shared.types";
import { getParameterTypeText } from "../filter-utilities";
import { IValidationField } from "./edit-filter.types";
import { AliasIsNotSameAsParameterName, IsValidVariableName, ParameterValueIsUnique, ValidateFormulaValue, ValidateParameterName, ValidateParameterValue } from "./edit-filter.validation";

type OutputParametersTabProps = {
    filter?: FilterResultModel;
    selectedFile?: FileModel;
    setFilter: (filter: FilterResultModel) => void;
    isFormSubmitted: boolean;
    isLoading: boolean;
};

const parameterTypeDropdownOptions: IDropdownOption[] = [{
    key: 1,
    text: "Expected Output"
},
{
    key: 3,
    text: "Formula"
}];

export const OutputParametersTab: React.FC<OutputParametersTabProps> = (props) => {


    const onAddParameterClick = () => {
        let newParams = [...(props.filter?.outputFilterParameters ?? [])];
        const defaultParamterValue = { id: 0, parameterType: 1, order: newParams.length, alias: "", formula: "", name: "" } as OutputFilterParameterResultModel;

        newParams.push(defaultParamterValue);
        const updatedFilter = { ...props.filter, outputFilterParameters: newParams } as FilterResultModel;
        props.setFilter(updatedFilter);
    }

    const onRemoveParameterClick = (itemIndex: number) => {
        let newParams = [...props.filter.outputFilterParameters];
        newParams = newParams.filter((value, index) => index !== itemIndex).map((x, i) => ({ ...x, order: i } as OutputFilterParameterResultModel));
        const updatedFilter = { ...props.filter, outputFilterParameters: newParams } as FilterResultModel;
        props.setFilter(updatedFilter);
    }

    const onParameterTypeChanged = (index: number, option: number, column: string) => {
        let newParams = [...(props.filter?.outputFilterParameters ?? [])];
        (newParams[index] as any)[column] = option;
        if (column == "parameterType" && option == FilterParameterTypePostModel.ExpectedOutput) {
            newParams[index].formula = "";
        }
        const updatedFilter = { ...props.filter, outputFilterParameters: newParams } as FilterResultModel;
        props.setFilter(updatedFilter);
    }

    const onItemChanged = (index: number, newValue: string, column: string) => {

        let newParams = [...(props.filter?.outputFilterParameters ?? [])];
        const oldValue = (newParams[index] as any)[column];

        if (oldValue !== newValue) {
            (newParams[index] as any)[column] = newValue;
           
            const updatedFilter = { ...props.filter, outputFilterParameters: newParams } as FilterResultModel;
            console.log("updated output parameter", props.filter, updatedFilter);
            props.setFilter(updatedFilter);
        }
    }

    const moveItem = (item: OutputFilterParameterResultModel, itemIndex: number, newItemIndex: number) => {
        let outputParams = [...(props.filter?.outputFilterParameters ?? [])];
        outputParams = outputParams.filter((x, i) => i !== itemIndex).map((x, i) => ({ ...x, order: i } as OutputFilterParameterResultModel));

        outputParams.splice(newItemIndex, 0, item);
        outputParams = outputParams.map((x, i) => ({ ...x, order: i } as OutputFilterParameterResultModel));

        const updatedFilter = { ...props.filter, outputFilterParameters: outputParams } as FilterResultModel;
        props.setFilter(updatedFilter);
    }
    let allowedVariableNames = props.filter.inputFilterParameters?.map(x => x.alias) ?? [];
    const outParameterNames = props.filter.outputFilterParameters?.map(x => x.alias) ?? [];
    if (outParameterNames?.length > 0 ?? false) {
        allowedVariableNames.push(...outParameterNames);
    }
    const getFormulaCell = (item: OutputFilterParameterResultModel, index: number) => {
        if (item.parameterType == FilterParameterType.ExpectedOutput) {
            return "";
        } else {
            const validationResult = ValidateFormulaValue(item.formula ?? "", allowedVariableNames, "Formula is required!");
            return <div className="input-wrapper">
                <Field
                    validationMessageIcon={null}
                    validationMessage={props.isFormSubmitted ? validationResult.validationErrors[0] : undefined}
                    validationState={props.isFormSubmitted && validationResult.validationErrors[0] ? "error" : "none"} >
                    <Input
                        value={item.formula}
                        onChange={(ev, newValue) => onItemChanged(index, newValue.value as string, "formula")} />
                </Field>
            </div>
        }
    }

    const getMoveCell = (item: OutputFilterParameterResultModel, itemIndex: number) => {
        const outputFilterParameters = props.filter?.outputFilterParameters;
        return <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
                appearance="transparent"
                className="chevron--button"
                style={{
                    display: itemIndex == 0 ? "none" : "inline"
                }}
                icon={<ChevronUpRegular />}
                onClick={() => { moveItem(item, itemIndex, itemIndex - 1) }}
            />
            <Button
                appearance="transparent"
                className="chevron--button"
                style={{
                    display: outputFilterParameters!.length == 1 || itemIndex == outputFilterParameters!.length - 1 ? "none" : "inline"
                }}
                icon={<ChevronDownRegular />}
                onClick={() => { moveItem(item, itemIndex, itemIndex + 1) }} />

        </div>
    }

    const getParameterNameCell = (item: OutputFilterParameterResultModel, index: number) => {
        const { name } = item;
        const { selectedFile } = props;
        if (item.parameterType == FilterParameterType.ExpectedOutput) {

            let validationResult = ValidateParameterName(item.name ?? "");
            validationResult = ParameterValueIsUnique(props.filter?.outputFilterParameters ?? [],
                item, "name", validationResult);


            return <div className="input-wrapper">
                <ParameterPickerNext
                    key={name}
                    fileUniqueId={selectedFile?.uniqueIdentifier}
                    fileVersionNumber={selectedFile?.currentVersionNumber as any}
                    value={name}
                    onChange={v => onItemChanged(index, v, "name")}
                    accessMode={ParameterAccessModel.Read} />

                {props.isFormSubmitted && !validationResult.isValid && <span className="input-wrapper--error">{validationResult.validationErrors[0]}</span>}
            </div>
        } else {
            return <span>-</span>;
        }
    }

    const getAliasCell = (item: OutputFilterParameterResultModel, index: number) => {
        let validationResult = { isValid: true, validationErrors: [] } as IValidationField;
        if (item.parameterType == FilterParameterType.Formula) {
            validationResult = ValidateParameterValue(item.alias ?? "", "Alias is required!");
        }
        validationResult = ParameterValueIsUnique(props.filter?.outputFilterParameters ?? [],
            item, "alias", validationResult);


        validationResult = AliasIsNotSameAsParameterName(props.filter?.outputFilterParameters ?? [],
            item, validationResult);

        validationResult = IsValidVariableName(item.alias, validationResult);


        return <div className="input-wrapper">
            <Field
                validationMessageIcon={null}
                validationMessage={props.isFormSubmitted ? validationResult.validationErrors[0] : undefined}
                validationState={props.isFormSubmitted && validationResult.validationErrors[0] ? "error" : "none"}>
                <Input
                    value={item.alias}
                    style={{ maxWidth: "100%" }}
                    onChange={(ev, data) => { onItemChanged(index, data.value as string, "alias") }} />
            </Field>
        </div>
    }

    return <div>
        <div className="s365-table__wrapper">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell key="move-row" style={{ width: "20px" }}></TableHeaderCell>
                        <TableHeaderCell key="parameter" style={{ width: "400px" }} className='files-table__cell--bold'>Parameter</TableHeaderCell>
                        <TableHeaderCell key="alias" style={{ width: "220px" }} className='files-table__cell--bold'>Alias</TableHeaderCell>
                        <TableHeaderCell key="parameter-type" style={{ width: "180px" }} className='files-table__cell--bold'>Parameter Type</TableHeaderCell>
                        <TableHeaderCell key="value-formula-field" style={{ width: "250px" }} className='files-table__cell--bold'>Formula</TableHeaderCell>
                        <TableHeaderCell key="actions-field" ></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={props.isLoading}
                    columnCount={6} loadingMessage="Loading..."
                    itemCount={props.filter?.outputFilterParameters ? props.filter.outputFilterParameters.length : 0}
                    noItemsMessage={`You can add parameters by clicking on the "Add parameter" button.`}>

                    {props.filter && props.filter.outputFilterParameters &&
                        props.filter.outputFilterParameters.map((item, index) => {
                            return <TableRow key={`output-param-${index}`}>
                                <TableCell className="parameter-cell">
                                    {getMoveCell(item, index)}
                                </TableCell>
                                <TableCell className="parameter-cell">
                                    {getParameterNameCell(item, index)}

                                </TableCell>
                                <TableCell className="parameter-cell">
                                    {getAliasCell(item, index)}
                                </TableCell>
                                <TableCell className="parameter-cell">
                                    <Dropdown style={{ minWidth: "180px" }}
                                        value={getParameterTypeText(item.parameterType)}
                                        onOptionSelect={(ev, data) => { onParameterTypeChanged(index, +data.optionValue!, "parameterType"); }}>
                                        {parameterTypeDropdownOptions.map((option) => {
                                            return <Option value={option.key?.toString()}>
                                                {option.text}
                                            </Option>;
                                        })}
                                    </Dropdown> </TableCell>
                                <TableCell className="parameter-cell">{getFormulaCell(item, index)}</TableCell>
                                <TableCell key="actions" >
                                    <Button
                                        style={{ float: "right" }}
                                        appearance="transparent"
                                        icon={<Dismiss20Regular />}
                                        onClick={() => { onRemoveParameterClick(index) }} /></TableCell>
                            </TableRow>
                        })

                    }
                </TableBodyWithLoading>
            </Table>
        </div>
        <Button appearance="primary" style={{ marginTop: "10px" }} onClick={onAddParameterClick}>Add parameter</Button>
    </div>;
}