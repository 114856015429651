import { Button, Field, Input, Select, TableColumnDefinition, TableColumnSizingOptions, createTableColumn, useTableColumnSizing_unstable, useTableFeatures } from "@fluentui/react-components";
import { CalendarLtrRegular, DismissRegular } from "@fluentui/react-icons";
import { Table, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-table";
import moment from "moment";
import React, { forwardRef } from "react";
import ReactPaginate from "react-paginate";
import { ApplicationName } from "../../components/application-name/application-name.component";
import { ApplicationPicker } from "../../components/application-picker/application-picker.component";
import { DispatcherJobDetails } from "../../components/dispatcher-job-details/dispatcher-job-details.component";
import { FileDisplayName } from "../../components/file-display-name/file-display-name.component";
import JobStatus from "../../components/job-status/job-status.component";
import JobTypeComponent from "../../components/job-type/job-type.component";
import SimulatorTypeComponent from "../../components/simulator-type/simulator-type.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { getSearchClient } from "../../services/dispatcher.service";
import { ApplicationResponseModel, JobProcessingStatus, JobSearchResponseModel, JobType, SimulatorType, UserJobsQuery } from "../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { IDropdownOption } from "../../utils/shared.types";
import DatePicker from "react-datepicker";
import "./jobs-table.styless.scss";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { useNavigate } from "react-router-dom";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";

type JobsTableProps = {

}

type JobFilter = {
    jobId?: number;
    applicationId?: number;
    processingStatus?: JobProcessingStatus;
    jobType?: JobType;
    simulatorType?: SimulatorType;
    dateFrom?: Date;
    dateTo?: Date;

}

export const JobsTable: React.FC<JobsTableProps> = (props) => {
    const [currentPage, SetCurrentPage] = React.useState<number>(1);
    const [sizePerPage, SetSizePerPage] = React.useState<number>(25);
    const [totalRecords, setTotalRecords] = React.useState<number>(0);
    const [isLoading, loadingService] = useLoading();
    const [jobs, setJobs] = React.useState<JobSearchResponseModel[]>([]);
    const [filter, setFilter] = React.useState<JobFilter>();
    const [showJobDetailsModal, setShowJobDetailsModal] = React.useState<boolean>(false);
    const [selectedJobId, setSelectedJobId] = React.useState<number>();
    // const [simulatorTypeDrodownOptions, setSimulatorTypeDropdownOptions] = React.useState<IDropdownOption[]>([{ key: null, text: "Select Simulator" }])
    const navigate = useNavigate();

    // React.useEffect(() => {
    //     getSimulatorTypes();
    // }, []);

    React.useEffect(() => {
        getUserJobs(filter);
    }, [currentPage]);


    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        id: {
            minWidth: 50,
            defaultWidth: 100,
        },
        created: {
            minWidth: 100,
            defaultWidth: 150,
        },
        flowsheet: {
            minWidth: 200,
            defaultWidth: 400,
        },
        jobType: {
            minWidth: 100,
            defaultWidth: 150,
        },
        simulator: {
            minWidth: 50,
            defaultWidth: 120
        },
        status: {
            minWidth: 80,
            defaultWidth: 150
        },
        application: {
            minWidth: 100,
            defaultWidth: 100
        },
        agentCalculationDuration: {
            minWidth: 100,
            defaultWidth: 200
        }
    });



    // const getSimulatorTypes = () => {
    //     loadingService.showLoading("Getting simulator types...", async (hideMessage) => {

    //         try {
    //             const client = getSearchClient();
    //             const simulatorsResp = await client.getUserJobSimulators();
    //             const simulatorOptions = simulatorsResp.map(x => ({ key: x, text: getSimulatorName(x) } as IDropdownOption));
    //             const simulatorOptionsSorted = simulatorOptions.sort((a, b) => a.text.localeCompare(b.text));
    //             setSimulatorTypeDropdownOptions(s => ([...s, ...simulatorOptionsSorted]));

    //         } catch (error) {
    //             processServerError(error, undefined, "An error occurred while getting simulator types.");

    //         } finally {
    //             hideMessage();
    //         }

    //     });

    // }

    const getUserJobs = async (passedFilter: JobFilter) => {
        loadingService.showLoading("Getting jobs...", async (hideMessage) => {
            try {

                const client = getSearchClient();

                const query = {
                    jobId: passedFilter && passedFilter.jobId && passedFilter.jobId > 0 ? passedFilter.jobId : undefined,
                    applicationId: passedFilter && passedFilter.applicationId && passedFilter.applicationId > 0 ? passedFilter.applicationId : undefined,
                    processingStatus: passedFilter && passedFilter.processingStatus !== undefined ? passedFilter.processingStatus : undefined,
                    jobType: passedFilter && passedFilter.jobType !== undefined ? passedFilter.jobType : undefined,
                    simulatorType: passedFilter && passedFilter.simulatorType !== undefined ? passedFilter.simulatorType : undefined,
                    dateFrom: passedFilter && passedFilter.dateFrom ? (new Date(moment(passedFilter.dateFrom).format("YYYY-MM-DD [00:00:00 GMT+0000]"))) : undefined,
                    dateTo: passedFilter && passedFilter.dateTo ? (new Date(moment(passedFilter.dateTo).format("YYYY-MM-DD [00:00:00 GMT+0000]"))) : undefined,
                    skip: (currentPage - 1) * sizePerPage,
                    take: sizePerPage
                } as UserJobsQuery;
                const response = await client.getUserJobs(query);
                setTotalRecords(response?.totalRecords ?? 0);
                setJobs(response?.items ?? []);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting user jobs.");

            } finally {
                hideMessage();
            }
        });


    }

    const CustomDateInputField = forwardRef((props: any, ref: any) => (
        <Input
            value={props.value}
            onClick={props.onClick}
            contentAfter={<CalendarLtrRegular onClick={props.onClick} />}
            ref={ref} />

    ));


    const items = jobs ?? [];

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<JobSearchResponseModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    const totalPages = totalRecords > sizePerPage ? Math.ceil(totalRecords / sizePerPage) : 1;
    return <div className="content-wrapper">
        <div className='groups-wrapper__breadcrumbs-wrapper'>
            <Breadcrumbs>               
                <Breadcrumb key={`breadcrumb-cluster`} onClick={() => navigate(`/cluster`)}>Cluster</Breadcrumb>
                <Breadcrumb key={`breadcrumb-jobs`} active={true}>Jobs</Breadcrumb>
            </Breadcrumbs>
        </div>


        <div className="filter-group" style={{flexWrap:"wrap"}}>
            <div className="filter-option">
                <span>Job Id</span>
                <Input
                    type="number"
                    value={filter?.jobId?.toString() ?? ""}
                    onChange={(ev, data) => { setFilter(s => ({ ...s, jobId: +data.value })) }}
                />
            </div>

            <div className="filter-option">
                <span>From date:</span>
                <DatePicker
                    selected={filter ? filter.dateFrom : undefined}
                    maxDate={new Date()}
                    dateFormat="dd.MM.yyyy."
                    onChange={(date) => { setFilter(s => ({ ...s, dateFrom: date })) }}
                    customInput={<CustomDateInputField />} />

            </div>
            <div className="filter-option">
                <span>To date:</span>
                <DatePicker
                    selected={filter ? filter.dateTo : undefined}
                    minDate={filter ? filter.dateFrom : undefined}
                    maxDate={new Date()}
                    dateFormat="dd.MM.yyyy."
                    onChange={(date) => { setFilter(s => ({ ...s, dateTo: date })) }}
                    customInput={<CustomDateInputField />} />

            </div>

           

            <div className="filter-option">
                <span>Job Type</span>
                <Field className="input-form-field">
                    <Select
                        value={filter && filter.jobType !== undefined ? filter.jobType.toString() : ""}
                        placeholder="Select Job type"
                        onChange={(ev, data) => { setFilter(s => ({ ...s, jobType: data.value ? +data.value : undefined })) }}
                    >
                        {jobTypeDropdownOptions.map((option) => {
                            return <option value={option.key?.toString()}>
                                {option.text}
                            </option>
                        })}
                    </Select>
                </Field>
            </div>

            <div className="filter-option">
                <span>Status</span>
                <Field className="input-form-field">
                    <Select
                        value={filter && filter.processingStatus !== undefined ? filter.processingStatus.toString() : ""}
                        placeholder="Select Status"
                        onChange={(ev, data) => { setFilter(s => ({ ...s, processingStatus: data.value ? +data.value : undefined })) }}
                    >
                        {processingStatusDropdownOptions.map((option) => {
                            return <option value={option.key?.toString()}>
                                {option.text}
                            </option>
                        })}
                    </Select>
                </Field>

            </div>
            {/* <div className="filter-option">
                <span>Application</span>
                <ApplicationPicker
                    selectedApplicationId={filter?.applicationId?.toString() ?? ""}
                    hideInfo
                    hideCreateNew
                    onChange={(item: ApplicationResponseModel) => {
                        setFilter(s => ({ ...s, applicationId: item && item.id > 0 ? item.id : undefined }));
                    }}
                />
            </div> */}


            {/* <div className="filter-option">
                <span>Simulator</span>
                <Field className="input-form-field">
                    <Select
                        value={filter && filter.simulatorType !== undefined ? filter.simulatorType.toString() : ""}
                        placeholder="Select Simulator"
                        onChange={(ev, data) => { setFilter(s => ({ ...s, simulatorType: data.value ? +data.value : undefined })) }}
                    >
                        {simulatorTypeDrodownOptions.map((option) => {
                            return <option value={option.key?.toString()}>
                                {option.text}
                            </option>
                        })}
                    </Select>
                </Field>


            </div> */}

           
            <div className="filter-option">
                <Button appearance="primary" style={{ marginTop: "19px" }}
                    onClick={() => {
                        if (currentPage !== 1) {
                            SetCurrentPage(1);
                        } else {
                            getUserJobs(filter);
                        }
                    }}
                >Search</Button>

            </div>


            <div className="filter-option" style={{ margin: "5px 0px" }}>
                <Button appearance="subtle" style={{ marginTop: "19px" }}
                    icon={<DismissRegular />}
                    onClick={() => {
                        if (currentPage !== 1) {
                            SetCurrentPage(1);
                            setFilter({});
                        } else {
                            setFilter({});
                            setTimeout(() => getUserJobs({}), 500);
                        }
                    }}
                />

            </div>

        </div>

        <div className='s365-table__wrapper'>
            <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
                <TableHeader>
                    <TableRow>
                        {columns.map((column) => (
                            <TableHeaderCell
                                onDragStart={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className={`s365-table__cell--bold column--center`}
                                key={column.columnId}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}
                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={isLoading}
                    columnCount={8} loadingMessage="Loading..."
                    itemCount={jobs ? jobs.length : 0}
                    noItemsMessage="No items found.">
                    {jobs && jobs.length > 0 && jobs.map((item) => {
                        return <TableRow key={`job-${item.id}`}
                            onClick={() => {
                                setSelectedJobId(item.id);
                                setShowJobDetailsModal(true);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            {columns.map((column) => (
                                <TableCell
                                    className={`column--center`}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    })}
                </TableBodyWithLoading>
            </Table>
        </div>
        <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me page-item'}
            breakLinkClassName={'page-link'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            onPageChange={(page) => { console.log("Selected page:", page); SetCurrentPage(page.selected + 1); }}
            containerClassName={'pagination'}
            activeClassName={'active'}
        />

        {showJobDetailsModal &&
            <DispatcherJobDetails
                jobId={selectedJobId}
                isOpened={showJobDetailsModal}
                onClose={() => { setShowJobDetailsModal(false); setSelectedJobId(undefined); }} />}


    </div>
}

const processingStatusDropdownOptions: IDropdownOption[] = [
    {
        key: null,
        text: "Select Status"
    },
    {
        key: JobProcessingStatus.Failed,
        text: "Failed"
    },
    {
        key: JobProcessingStatus.Finished,
        text: "Finished"
    },
    {
        key: JobProcessingStatus.NotCalculated,
        text: "Not Calculated"
    },
    {
        key: JobProcessingStatus.Cancelled,
        text: "Cancelled"
    },
];

const jobTypeDropdownOptions: IDropdownOption[] = [
    {
        key: null,
        text: "Select Job type"
    },
    {
        key: JobType.AnalyzeFlowsheet,
        text: "Analyze"
    },
    {
        key: JobType.RunFlowsheet,
        text: "Run"
    },
    {
        key: JobType.RunFlowsheetStepByStep,
        text: "Run step by step"
    }
];

const getSimulatorName = (simulator?: SimulatorType) => {

    if (!simulator) {
        return null;
    }

    switch (simulator) {
        case SimulatorType.Chemcad7:
            return "Chemcad 7";
        case SimulatorType.Chemcad8:
            return "Chemcad 8";
        case SimulatorType.DWSIM:
            return "DWSIM Pro";

    }


}

const columns: TableColumnDefinition<JobSearchResponseModel>[] = [
    createTableColumn<JobSearchResponseModel>({
        columnId: "id",
        renderHeaderCell: () => <div style={{ textAlign: "center" }}>ID</div>,
        renderCell: (item: JobSearchResponseModel) => {

            return <div style={{ textAlign: "center" }}>{item.id}</div>;

        }
    }),
    createTableColumn<JobSearchResponseModel>({
        columnId: "created",
        renderHeaderCell: () => <>Created</>,
        renderCell: (item: JobSearchResponseModel) => {

            return <ShowLocalTime date={item.createdAt} multiline />

        }
    }),
    createTableColumn<JobSearchResponseModel>({
        columnId: "flowsheet",
        renderHeaderCell: () => <>Flowsheet</>,
        renderCell: (item: JobSearchResponseModel) => {

            return <FileDisplayName fileUniqueIdentifier={item.flowsheetItemId} fileVersionNumber={+item.flowsheetVersion} />;

        }
    }),
    createTableColumn<JobSearchResponseModel>({
        columnId: "jobType",
        renderHeaderCell: () => <>Job Type</>,
        renderCell: (item: JobSearchResponseModel) => {

            return <JobTypeComponent value={item.jobType} />;

        }
    }),
    // createTableColumn<JobSearchResponseModel>({
    //     columnId: "simulator",
    //     renderHeaderCell: () => <>Simulator</>,
    //     renderCell: (item: JobSearchResponseModel) => {

    //         return <SimulatorTypeComponent style={{ width: "20px", height: "20px" }} value={item.simulatorType} />;
    //     }
    // }),
    createTableColumn<JobSearchResponseModel>({
        columnId: "status",
        renderHeaderCell: () => <>Status</>,
        renderCell: (item: JobSearchResponseModel) => {

            return <JobStatus value={item.processingStatus} />;
        }
    }),   
    createTableColumn<JobSearchResponseModel>({
        columnId: "agentCalculationDuration",
        renderHeaderCell: () => <>Agent / Calculation Duration</>,
        renderCell: (item: JobSearchResponseModel) => {

            return <span>{item.agentName} {item.calculationDuration ? <> <br /> ({item.calculationDuration})</> : ""}</span>;
        }
    }),

];
