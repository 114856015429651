import React from "react";
import { HideSidebarSectionInTour, ShowSidebarSectionInTour } from "./tour-guide.shared";

type HeadbarTourProps = {

}

export const HeadbarTour: React.FC<HeadbarTourProps> = (props) => {
    React.useEffect(() => {

        ShowSidebarSectionInTour("headbar_list", false, true);

        return () => {
            // Close my work menu section
            HideSidebarSectionInTour("headbar_list", false, true);
        }
    }, [])
    return <div style={{ marginTop: "var(--spacingVerticalMNudge)", marginBottom: "var(--spacingVerticalMNudge)" }}>
        <p>Connect to DWSIM Pro in a VM. Access your personal support platform. View Help for Tutorials. Get or extend your product licenses in the online shop.</p>
        <span style={{ fontWeight: "bold" }}>Power tip!</span>
        <br />
        <span>You need help with a flowsheet or Dashboard feature? Right-click on your file to send it directly from your Dashboard to support@simulate365.com.</span>
    </div>
}