import React from "react";
import { ConfirmationDialog } from "../components/confirmation-dialog/confirmation-dialog.component";
import { getExamsClient } from "../services/take-home-exams.service";
import { toast } from "react-toastify";
type DeleteExamModalProps = {
    isOpened: boolean;
    examId: number;
    examName?: string;
    onClose: () => void;
    onSuccess: () => void;
}

export const DeleteExamModal: React.FC<DeleteExamModalProps> = (props) => {


    const onDeleteExamClick = async () => {
        try {
            const client = getExamsClient();
            await client.deleteExam(props.examId);
            toast.success("Successfully archived Take-Home Exercise/Exam.");
            props.onSuccess();

        } catch (error) {

            toast.error("An error occurred while trying to archive Take-Home Exercise/Exam.");
            console.log("An error occurred while trying to archive Take-Home Exercise/Exam.", error);
        }
    }

    return <ConfirmationDialog
        isOpened={props.isOpened}
        title="Archive Exam"
        confirmLabel="Archive"
        subText={
            `Are you sure you want to archive exam '${props.examName}'? `}
        onConfirm={() => {
            onDeleteExamClick();
        }}
        onClose={() => { props.onClose(); }}

    />
}