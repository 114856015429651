import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbItem, ErrorResultModel, FileModel, ShareDefinitionSourceType } from "../swagger-clients/s365-dashboard-v2-api-clients.service";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from '../components/breadcrumbs/breadcrumbs';
import { emptyBreadcrumbs } from "../filters/filters.component";
import { processServerError } from "../utils/helpers/error.helper";
import { getFilesClient, getUsersClient, getUserToken } from "../services/dashboard.service";
import { SensitivityStudyQuery, SensitivityStudyResultModel, StudyType } from "../swagger-clients/sensitivity-studies-api-clients.service";
import { getSensitivityStudiesClient } from "../services/sensitivity-studies.service";
import { _copyAndSort } from "../utils/helpers/array.helpers";
import { ContextMenu, ContextMenuType } from "../files/context-menu/context-menu.component";
import { Table, TableBody, TableCell, TableColumnDefinition, TableColumnSizingOptions, TableHeader, TableHeaderCell, TableRow, createTableColumn, useTableColumnSizing_unstable, useTableFeatures } from "@fluentui/react-components";
import { TabList, Tab, MenuItem, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Add20Regular, ArrowDownloadRegular, ArrowResetRegular, CopyRegular, DeleteRegular, EditRegular, PeopleAddRegular } from "@fluentui/react-icons";
import { GetStudyStepText } from "./utilities/study.utilities";
import "./sensitivity-studies-list.styless.scss";
import { LoadingIndicator, useLoading } from "../utils/loading-indicator.component";
import { TableBodyWithLoading } from "../components/table-body-with-loading/table-body-with-loading.component";
import { DeleteSensitivityStudyModal } from "./delete-sensitivity-study-modal/delete-sensitivity-study-modal.component";
import { JobProgressCount } from "../components/job-progress-count/job-progress-count.component";
import { StudyStepStatusComponent } from "./study-step-status/study-step-status.component";
import { settings } from "../App";
import saveAs from "file-saver";
import { toast } from "react-toastify";
import ShowLocalTime from "../components/show-local-time/show-local-time.component";
import { ShareFileModal } from "../files/share/share-file-modal.component";

type SensitivityStudiesListProps = {
    isOptimumTester?: boolean
};

export type SensitivityStudiesRouteParams = {
    uniquefileId: string,
    studyId?: string
}

export const SensitivityStudiesList: React.FC<SensitivityStudiesListProps> = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const routeParams = useParams<SensitivityStudiesRouteParams>();
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedStudy, setSelectedStudy] = React.useState<SensitivityStudyResultModel>();
    const [studies, setStudies] = React.useState<SensitivityStudyResultModel[]>([]);
    const [showDeleteStudyModal, setShowDeleteStudyModal] = React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState<string>("active");
    const [showShareModal, setShowShareModal] = React.useState<boolean>(false);
    const [isUserExamplesAdmin, setIsUserExamplesAdmin] = React.useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const navigate = useNavigate();


    useEffect(() => {
        initializePage();
    }, []);

    const initializePage = async () => {
        checkIfUserIsExamplesAdmin();
        const resp = await getFile();
        await getStudies();
    }

    const checkIfUserIsExamplesAdmin = async () => {
        try {
            const client = getUsersClient();
            const resp = await client.isUserExamplesAdmin();
            setIsUserExamplesAdmin(resp?.isUserExamplesAdmin ?? false);
        } catch (error) {
            processServerError(error, undefined, "An error occurred while trying to check if user is Examples Admin.");
        }

    }



    const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
        id: {
            minWidth: 50,
            defaultWidth: 100,
        },
        name: {
            minWidth: 100,
            defaultWidth: 400,
        },
        currentStep: {
            minWidth: 100,
            defaultWidth: 200
        },
        currentStepStatus: {
            minWidth: 100,
            defaultWidth: 200
        },
        jobs: {
            minWidth: 100,
            defaultWidth: 200

        },
        created: {
            minWidth: 100,
            defaultWidth: 200

        }
    });

    const isColumnCentered = (columnId: string) => {

        switch (columnId) {
            case "currentStep": return true;
            case "currentStepStatus": return true;
            case "jobs": return true;
            case "created": return true;

            default: return false;
        }
    }



    const getFile = async () => {
        try {
            const client = getFilesClient();
            const resp = await client.getFileLatest(routeParams.uniquefileId!, true);
            if (resp) {
                setSelectedFile(resp.file);
                setBreadcrumbs([...emptyBreadcrumbs, ...(resp.breadcrumbItems ?? [])]);
            }
            return resp;

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file information.");
        }
    }
    const getStudies = async () => {
        loadingService.showLoading("Loading...", async (hideMessage) => {
            try {
                const client = getSensitivityStudiesClient();
                const query = {
                    flowsheetDriveId: routeParams.uniquefileId,
                    flowsheetsListId: "s365v2",
                    siteId: "s365v2",
                    studyType: props.isOptimumTester ? StudyType.OptimumTest : StudyType.SensitivityStudy
                } as SensitivityStudyQuery;

                const studiesResp = await client.getSensitivityStudies(query);

                const studiesSorted = _copyAndSort<SensitivityStudyResultModel>(studiesResp, "createdAt", true);

                setStudies(studiesSorted ?? []);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting studies.");
            } finally {
                hideMessage();
            }
        });
    }

    const downloadPdf = async () => {
        try {
            var accessToken = await getUserToken();
            var data = {
                studyId: selectedStudy.id,
                sensitivityStudyServiceUrl: settings.sensitivityStudiesServiceUrl,
                studyName: selectedStudy.name,
                accessToken: accessToken
            };

            var requestOptions = {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            } as RequestInit;

            const response = await fetch(`${settings.pdfGeneratorServiceUrl}/api/studies/pdf-converter`, requestOptions);
            if (response.status == 200) {
                console.log("Pdf resp", response);
                //  const stream= new Response(response as any);
                const blob = await response.blob();
                saveAs(blob, `${selectedStudy.name}.pdf`);
            } else {
                var respText = await response.json();
                console.log("respText", respText);
                const error = respText as ErrorResultModel;
                if (error && error.errors && error.errors.length > 0) {
                    throw error;
                } else
                    throw "An error occurred while downloading PDF.";
            }
        } catch (error) {
            processServerError(error, undefined, "An error occurred while downloading PDF.");
        }
    }

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }

    const onShowDetailsClick = () => {
        navigate(`/files/${selectedFile.uniqueIdentifier}/${!props.isOptimumTester ? 'sensitivity-studies' : "optimum-tester"}/details/${selectedStudy.id}`);
    }

    const onRestoreScenarioClick = () => {

        loadingService.showLoading(`Restoring ${!props.isOptimumTester ? 'sensitivity study' : "optimum test"}...`, async (hideMessage) => {

            try {

                const client = getSensitivityStudiesClient();
                await client.restoreStudy(selectedStudy.id);
                toast.success(`Successfully restored ${!props.isOptimumTester ? 'Sensitivity Study' : "Optimum Test"}.`);
                await getStudies();

            } catch (error) {
                processServerError(error, undefined, `An error occurred while restoring ${!props.isOptimumTester ? 'sensitivity study' : "optimum test"}.`);
            } finally {
                hideMessage();
            }

        });

    }

    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        if (!selectedStudy) {
            return [];
        }

        if (!selectedStudy.isDeleted) {
            menuItems.push(...[
                <MenuItem icon={<EditRegular />} key="edit" onClick={() => navigate(`/files/${selectedFile?.uniqueIdentifier}/${!props.isOptimumTester ? 'sensitivity-studies' : "optimum-tester"}/edit/${selectedStudy?.id}`)}> Edit</MenuItem>,
                <MenuItem icon={<CopyRegular />} key="clone" onClick={() => navigate(`/files/${selectedFile?.uniqueIdentifier}/${!props.isOptimumTester ? 'sensitivity-studies' : "optimum-tester"}/edit?cloneId=${selectedStudy?.id}`)}> Clone</MenuItem>])
            if (isUserExamplesAdmin) {
                menuItems.push(<MenuItem key="share" icon={<PeopleAddRegular />} onClick={() => { setShowShareModal(true) }}> Share</MenuItem>);
            }
            menuItems.push(...[
                <MenuItem icon={<ArrowDownloadRegular />} key="download-pdf" onClick={() => { downloadPdf(); }}> Download PDF</MenuItem>,
                <MenuItem icon={<DeleteRegular />} key="delete" onClick={() => setShowDeleteStudyModal(true)}> Archive</MenuItem>
            ]);
        } else {
            menuItems.push(<MenuItem key="restore" icon={<ArrowResetRegular />} onClick={() => { onRestoreScenarioClick(); }}> Restore</MenuItem >);
        }


        return menuItems;
    }


    const contextMenuRef = React.createRef<ContextMenuType>();

    const items = selectedTab == "active" ? studies.filter(study => !study.isDeleted) : studies.filter(study => !!study.isDeleted);
    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures<SensitivityStudyResultModel>(
        {
            columns,
            items,
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );


    return <div className="content-wrapper">
        <div className='toolbar__wrapper'>
            <Toolbar>
                <ToolbarButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                    onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/${!props.isOptimumTester ? 'sensitivity-studies' : "optimum-tester"}/edit`)}
                    appearance='primary'
                    icon={<Add20Regular />}>{!props.isOptimumTester ? "New Study" : "New Optimum Test"}</ToolbarButton>
                <LoadingIndicator loadingService={loadingService} />

            </Toolbar>
        </div>

        <div className='breadcrumbs-wrapper'>
            <Breadcrumbs>
                {breadcrumbs.map((item: BreadcrumbItem) => {
                    return <Breadcrumb
                        key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                        onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                })}
                {selectedFile &&
                    <Breadcrumb
                        key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                        onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/${props.isOptimumTester ? "optimum-tester" : "sensitivity-studies"}`)}
                    >{selectedFile.name}</Breadcrumb>}
                <Breadcrumb key={`breadcrumb-sensitivity-studies`} active={true}>{props.isOptimumTester ? "Optimum Test" : "Multivariate Sensitivity Study"}</Breadcrumb>

            </Breadcrumbs>
        </div>



        <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => { contextMenuRef.current?.hideMenu(); setSelectedTab(data.value as string); setSelectedStudy(undefined); }}>
            <Tab key="active" value="active" >Active</Tab>
            <Tab key="deleted" value="deleted">Archived</Tab>
        </TabList>
        <div className="tab-content">
            <div className='studies-table__wrapper'>
                <Table ref={tableRef} as="table" {...columnSizing_unstable.getTableProps()}>
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => (
                                <TableHeaderCell
                                    onDragStart={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    className={`${isColumnCentered(column.columnId as string) ? 'files-table__cell--center' : ''} files-table__cell--bold`}
                                    key={column.columnId}
                                    {...columnSizing_unstable.getTableHeaderCellProps(
                                        column.columnId
                                    )}
                                >
                                    {column.renderHeaderCell()}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBodyWithLoading isLoading={isLoading}
                        columnCount={6} loadingMessage="Loading..."
                        itemCount={items ? items.length : 0}
                        noItemsMessage="No items found.">
                        {items && items.length > 0 && items.map((item) => {
                            return <TableRow
                                key={`study-${item.id}`}
                                style={{ cursor: "pointer" }}
                                onClick={() => { navigate(`/files/${selectedFile.uniqueIdentifier}/${props.isOptimumTester ? "optimum-tester" : "sensitivity-studies"}/details/${item.id}`); }}
                                className={selectedStudy && selectedStudy.id == item.id ? "table-row-selected" : undefined}
                                onContextMenu={(ev) => { ev.preventDefault(); setSelectedStudy(item); contextMenuRef.current?.showMenu(ev); }}>
                                {columns.map((column) => (
                                    <TableCell
                                        className={`${isColumnCentered(column.columnId as string) ? ' column--center' : ''}`}
                                        {...columnSizing_unstable.getTableCellProps(column.columnId)}
                                    >
                                        {column.renderCell(item)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        })}
                    </TableBodyWithLoading>
                </Table> </div>

        </div>
        <ContextMenu ref={contextMenuRef} >
            {getContextItems()}
        </ContextMenu>

        {selectedStudy && showDeleteStudyModal && <DeleteSensitivityStudyModal
            study={selectedStudy}
            isOpened={showDeleteStudyModal}
            isOptimumTester={props.isOptimumTester}
            onClose={() => setShowDeleteStudyModal(false)}
            onSuccess={() => { setShowDeleteStudyModal(false); getStudies(); }}
        />}
        {
            selectedStudy && showShareModal &&
            <ShareFileModal
                sourceType={ShareDefinitionSourceType.MssStudy}
                sourceUniqueId={selectedStudy.id?.toString()}
                sourceName={selectedStudy.name}
                isOpened={showShareModal}
                onClose={() => { setShowShareModal(false); }}
            />
        }
    </div>


}

const columns: TableColumnDefinition<SensitivityStudyResultModel>[] = [
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "id",
        renderHeaderCell: () => <>ID</>,
        renderCell: (item: SensitivityStudyResultModel) => {
            return <span>{item.id}</span>;

        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "name",
        renderHeaderCell: () => <>Name</>,
        renderCell: (item: SensitivityStudyResultModel) => {
            return <span>{item.name}</span>;

        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "currentStep",
        renderHeaderCell: () => <>Current step</>,
        renderCell: (item: SensitivityStudyResultModel) => {
            return GetStudyStepText(item.currentStep);
        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "currentStepStatus",
        renderHeaderCell: () => <>Current step status</>,
        renderCell: (item: SensitivityStudyResultModel) => {

            return <StudyStepStatusComponent status={item.currentStepStatus} />;

        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "jobs",
        renderHeaderCell: () => <>Jobs</>,
        renderCell: (item: SensitivityStudyResultModel) => {

            return <JobProgressCount totalJobs={item.totalJobs} calculatedJobs={item.calculatedRows} failedJobs={item.failedRows} />;

        }
    }),
    createTableColumn<SensitivityStudyResultModel>({
        columnId: "created",
        renderHeaderCell: () => <>Created</>,
        renderCell: (item: SensitivityStudyResultModel) => {

            return <ShowLocalTime date={item.createdAt} />;
        }
    })
];