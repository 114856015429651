import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllFileEntries, getFile } from '../../utils/helpers/folder-upload.helpers';
import { LoadingService, useLoading } from '../../utils/loading-indicator.component';
import { FilesUploadConflictModal } from './FilesUploadConflictModal';
import { CustomFileModel, FilesUploadService } from './FilesUploadService';

export type FilesDropZoneProps = {
    className?: string,
    disabled?: boolean,
    children: JSX.Element | JSX.Element[],
    loadingService: LoadingService,
    parentDirectoryUniqueId: string,
    filesUploadService: FilesUploadService,
    onFilesUploaded: () => void
};

export const FilesDropZone = ({ filesUploadService, ...props }: FilesDropZoneProps) => {

    const pageWrapperRef = React.createRef<HTMLDivElement>();

    const onPageDropFile = async (ev: any) => {
        ev.preventDefault();
        console.log("onTableDropFile", ev);

        if (ev.dataTransfer.files) {
            try {
                // Use DataTransferItemList interface to access the file(s)
                console.log("ev.dataTransfer.files", ev.dataTransfer.files);
                const fileEntries = await getAllFileEntries(ev.dataTransfer.items);
                console.log("draggedFolders,draggedFiles", fileEntries);
                let allFiles: CustomFileModel[] = [];

                for (let i = 0; i < fileEntries.length; i++) {
                    const file = await getFile(fileEntries[i]) as any;
                    let someOther = { file: file, webkitRelativePath: fileEntries[i].fullPath };
                    allFiles.push(someOther);
                }

                console.log("allFiles", allFiles, allFiles.length > 0);

                if (allFiles.length > 0) {
                    filesUploadService.UploadFiles(allFiles, props.parentDirectoryUniqueId)
                        .then(() => props.onFilesUploaded(), (error) => { console.log(`On reject ${error};`); })
                        .catch((error) => { console.log(`Upload files error ${error}`); });
                }

            } catch (error) {
                console.log("Drag and drop error", error);

            } finally {
                onPageDragLeave(ev);
            }
        }
    }

    const onPageDragEnter = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();

        if (!pageWrapperRef.current!.classList.contains("page-wrapper--dragenter")) {
            pageWrapperRef.current!.classList.add("page-wrapper--dragenter");
        }
    }

    const onPageDragLeave = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();

        pageWrapperRef.current!.classList.remove("page-wrapper--dragenter");
    }


    if (!!props.disabled) {
        return <div className={props?.className}>
            {props.children}
        </div>
    }

    return (
        <div className={props?.className}
            ref={pageWrapperRef}
            onDrop={onPageDropFile}
            onDragOver={(ev) => ev.preventDefault()}
            onDragEnter={onPageDragEnter}>

            <div className='page-wrapper__dropzone' onDragLeave={onPageDragLeave}>Drop file(s) to upload...</div>

            {props.children}

            <FilesUploadConflictModal filesUploadService={filesUploadService} />
        </div>
    );
}