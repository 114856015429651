import * as React from 'react';
import { Spinner } from '@fluentui/react-components';
import { useState } from 'react';

type OnMessageChangedAction = (message: string) => void;
type OnRequestInProgressChangedAction = (newValue: boolean) => void;

class LoadingService {
    private onMessageChangedHandlers: OnMessageChangedAction[] = [];
    private onRequestInProgressChangedHandlers: OnRequestInProgressChangedAction[] = [];

    private messages: { key: string, message: string }[] = [];

    constructor() {
        console.log("Creating loading service.");
    }

    onMessageChanged(action: OnMessageChangedAction) {
        this.onMessageChangedHandlers.push(action);
    }

    onRequestInProgressChanged(action: OnRequestInProgressChangedAction) {
        this.onRequestInProgressChangedHandlers.push(action);
    }

    showLoading(message: string, action: (hideMessage: () => void) => void) {
        let messageId = this.addMessage(message);
        action(() => { this.removeMessage(messageId) });
    }
    showMessage(message: string) {
        return this.addMessage(message);
    }
    hideMessage(messageId: string) {
        this.removeMessage(messageId);
    }


    private addMessage(message: string) {
        let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);

        this.messages.push({
            key: uniqueId,
            message: message
        });

        this.notifyMessageChanged();

        return uniqueId;
    }

    private removeMessage(messageId: string) {
        if (this.messages) {
            this.messages = this.messages.filter(m => m.key != messageId);
        }

        this.notifyMessageChanged();
    }

    private notifyMessageChanged() {
        // Execute on message changed handlers
        if (this.onMessageChangedHandlers && this.onMessageChangedHandlers.length > 0) {
            let message: string | undefined = undefined;

            if (this.messages && this.messages.length > 0) {
                message = this.messages[this.messages.length - 1].message;
            }

            for (let action of this.onMessageChangedHandlers) {
                try {
                    action(message!);
                }
                catch (e) {
                    console.error(e);
                }
            }
        }

        // Execute on request in progress changed handlers
        if (this.onRequestInProgressChangedHandlers && this.onRequestInProgressChangedHandlers.length > 0) {
            for (let action of this.onRequestInProgressChangedHandlers) {
                let isRequestInProgress = (this.messages && this.messages.length > 0);
                action(isRequestInProgress);
            }
        }
    }
}

type LoadingIndicatorProps = {
    loadingService: LoadingService
};

class LoadingIndicator extends React.Component<LoadingIndicatorProps, { loadingMessage: string }>
{
    constructor(props: LoadingIndicatorProps) {
        super(props);

        this.state = {
            loadingMessage: ""
        };
    }

    componentDidMount() {
        this.props.loadingService.onMessageChanged((newMessage) => {
            this.setState(s => ({ loadingMessage: newMessage }));
        });
    }

    render() {
        if (this.state.loadingMessage)
            return <Spinner style={{ marginLeft: "5px", marginRight: "5px" }} label={this.state.loadingMessage} size="tiny" />;
        else
            return <></>;
    }
}

function useLoading(intialState: boolean = false): [boolean, LoadingService] {
    const [isLoading, setIsLoading] = useState(intialState);

    const loadingService = React.useMemo(() => new LoadingService(), []);

    React.useEffect(() => {
        loadingService.onMessageChanged((newMessage) => {
            setIsLoading(!!newMessage);
        })
    }, []);

    return [isLoading, loadingService];
}

export { LoadingService, LoadingIndicator, useLoading };