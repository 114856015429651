import React from "react";
import { ExperimentResponseModel, SeparationMethodOption } from "../../swagger-clients/ai-for-pfd-clients.service";
import { tokens } from "@fluentui/react-components";
import { DwsimComponentDisplayName } from "../../components/dwsim-component-display-name/dwsim-component-display-name.component";

type ResultOutputParameterProps = {
    componentCasNumber: string;
    separationMethod: SeparationMethodOption;
    inline?: boolean;
    hideUnit?: boolean;
}

const standardOutputParams = { energy: { unit: "kW" }, capex: { unit: "€" }, opex: { unit: "€/year" } };

export const ResultOutputParameter: React.FC<ResultOutputParameterProps> = (props) => {
    const [parameter, setParameter] = React.useState<string | JSX.Element>();
    const [unit, setUnit] = React.useState<string | JSX.Element>();

    React.useEffect(() => {
        if (!props.componentCasNumber) return;

        if (!!standardOutputParams[props.componentCasNumber]) {
            const parameterLocal = props.componentCasNumber.charAt(0).toUpperCase() + props.componentCasNumber.slice(1);
            setParameter(parameterLocal);

            const unitLocal = standardOutputParams[props.componentCasNumber]?.unit ?? "-";
            setUnit(unitLocal);
            return;
        }
        if (props.separationMethod == SeparationMethodOption.Absorption) {
            setParameter(<span>Recovery in Gas Outlet: <DwsimComponentDisplayName cas_number={props.componentCasNumber} /></span>);
        } else {
            setParameter(<span>Mass Fraction (<DwsimComponentDisplayName cas_number={props.componentCasNumber} />)</span>);
        }
    }, [props.componentCasNumber]);

    return <div style={{ display: "flex", flexDirection: !props.inline ? "column" : "row", wordBreak: "break-word" }}>

        <span>
            <span style={{ height: "0.8rem", color: tokens.colorNeutralForeground3 }}>P:</span> {parameter}
        </span>
        {!props.hideUnit && <span style={{ color: tokens.colorNeutralForeground3 }}>({!!unit ? unit : "-"})</span>}
    </div>;
}