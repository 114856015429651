import { Menu, MenuItem, MenuList, MenuPopover, PositioningImperativeRef, PositioningVirtualElement } from "@fluentui/react-components";
import React, { createRef, useReducer, useRef } from "react";

type ContextMenuProps = {
    hasIcons?: boolean;
    children?: JSX.Element | JSX.Element[],
    scrollableElement?: React.MutableRefObject<HTMLElement>
}

export type ContextMenuType = {
    showMenu: (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void,
    hideMenu: () => void
}

type ContextMenuState = {
    showMenu: boolean
}

export const ContextMenu = React.forwardRef<ContextMenuType, ContextMenuProps>((props, ref) => {

    const [maxMenuHeight, setMaxMenuHeight] = React.useState<string>(undefined);

    React.useImperativeHandle(
        ref,
        () => ({
            showMenu: (ev) => {
                showMenu(ev);
            },
            hideMenu: () => {
                setState({
                    showMenu: false
                });
            }
        }));

    React.useEffect(() => {
        let onElementScroll = () => {
            // console.log("Context menu scrolled");
            positioningRef.current?.updatePosition();
        }

        let scrollableElement = props.scrollableElement?.current || window;
        //console.log("scrollableElement",scrollableElement);
        scrollableElement.addEventListener('scroll', onElementScroll);

        return () => {
            scrollableElement.removeEventListener('scroll', onElementScroll);
        }
    }, []);

    const [state, setState] = React.useState<ContextMenuState>({
        showMenu: false
    });

    const positioningRef = React.useRef<PositioningImperativeRef>(null);

    const showMenu = (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {



        let parentElement = ev.target as any;
        const boundingClient = parentElement.getBoundingClientRect();
        const yCordinate = (boundingClient.y + (boundingClient.height / 2));
       // const isGoingTop = (window.innerHeight / 2) < boundingClient.y;
        let maxHeight = yCordinate;
        if ((window.innerHeight - yCordinate) > maxHeight) {
            maxHeight = window.innerHeight - yCordinate - 20;
        } else {
            maxHeight = yCordinate - 20;
        }
        setMaxMenuHeight(maxHeight);
        positioningRef.current?.setTarget({
            getBoundingClientRect: () => {
                const boundingClient = parentElement.getBoundingClientRect();
              
                return {
                    x: 0,
                    y: 0,
                    top: boundingClient.y + (boundingClient.height / 2),
                    left: ev.pageX,
                    bottom: 0,
                    right: 0,
                    width: 0,
                    height: 0
                };
            }
        });

        setState({
            showMenu: true
        });
    }
    // console.log("ContextMenu children", props.children);

    return !!props.children ? <Menu open={state.showMenu && !!props.children} onOpenChange={(e, data) => { setState({ ...state, showMenu: data.open }) }} positioning={{ positioningRef }}>
        <MenuPopover>
            <MenuList hasIcons={props.hasIcons} style={{ maxHeight: maxMenuHeight }}>
                {props.children}
            </MenuList>
        </MenuPopover>
    </Menu> : null
});

type ContextMenuWithDataProps = {
    hasIcons?: boolean;
    children: (data: any) => JSX.Element | JSX.Element[]
}

export type ContextMenuWithDataType = {
    showMenu: (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data?: any) => void
}

type ContextMenuWithDataState = {
    showMenu: boolean,
    data?: any
}

export const ContextMenuWithData = React.forwardRef<ContextMenuWithDataType, ContextMenuWithDataProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            showMenu: (ev, data?: any) => {
                showMenu(ev, data);
            }
        }));

    //const [, forceUpdate] = useReducer(x => x + 1, 0);

    const [state, setState] = React.useState<ContextMenuWithDataState>({
        showMenu: false
    });

    const positioningRef = React.useRef<PositioningImperativeRef>(null);

    const showMenu = (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data?: any) => {
        setState({
            showMenu: true,
            data: data
        });

        const boundingClient = (ev.target as any).getBoundingClientRect();
        //console.log("getBoundingClientRect", ev.pageX, ev.pageY, boundingClient);
        positioningRef.current?.setTarget({
            getBoundingClientRect: () => ({
                x: 0,
                y: 0,
                top: (boundingClient.y + (boundingClient.height / 2)),
                left: ev.pageX,
                bottom: 0,
                right: 0,
                width: 0,
                height: 0
            })
        });
    }

    return <Menu open={state.showMenu} onOpenChange={(e, data) => { setState({ ...state, showMenu: data.open }) }} positioning={{ positioningRef }}>
        <MenuPopover>
            <MenuList hasIcons={props.hasIcons}>
                {props.children(state.data)}
            </MenuList>
        </MenuPopover>
    </Menu>
});

