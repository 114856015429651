import { SeparationMethodOption, SpecificationProperty } from "../../swagger-clients/ai-for-pfd-clients.service";
import React from "react";

export const GetSpecificationPropertyType = (property: SpecificationProperty) => {
    switch (property) {
        case SpecificationProperty.NumberOfStagesFeedToTop:
            return "int";
        case SpecificationProperty.NumberOfStagesBottomToFeed:
            return "int";
        case SpecificationProperty.NumberOfStagesEntrainerToTop:
            return "int";
        case SpecificationProperty.NumberOfStagesFeedToEntrainer:
            return "int";
        case SpecificationProperty.CondenserPressure:
            return "int";
        case SpecificationProperty.RefluxRation:
            return "num";
        case SpecificationProperty.BottomProductFlowrate:
            return "num";
        case SpecificationProperty.HeaterTemperatures:
            return "num";
        case SpecificationProperty.NumberOfStages:
            return "int";
        case SpecificationProperty.GasPressure:
            return "int";
        case SpecificationProperty.SolventTemperature:
            return "int";
        case SpecificationProperty.SolventFlowrate:
            return "num";
        case SpecificationProperty.SwingPressure:
            return "num";
        default:
            return "-";
    }
}

export const GetSpecificationPropertyName = (property: SpecificationProperty, separationMethod: SeparationMethodOption) => {
    switch (property) {
        case SpecificationProperty.NumberOfStagesFeedToTop:
            if (separationMethod == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer)
                return "Number of Stages: Feed to Top (Column 2)";
            return "Number of Stages: Feed to Top";
        case SpecificationProperty.NumberOfStagesBottomToFeed:
            if (separationMethod == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer)
                return "Number of Stages: Bottom to Feed (Both)";
            return "Number of Stages: Bottom to Feed";
        case SpecificationProperty.NumberOfStagesEntrainerToTop:
            return "Number of Stages: Entrainer to Top (Column 1)"; 
            case SpecificationProperty.NumberOfStagesFeedToEntrainer:
                return "Number of Stages: Feed to Entrainer (Column 1)";   
        case SpecificationProperty.CondenserPressure:
            return "Condenser Pressure";
        case SpecificationProperty.RefluxRation:
            return "Reflux Ratio";
        case SpecificationProperty.BottomProductFlowrate:
            return "Bottom Product Flowrate";
        case SpecificationProperty.HeaterTemperatures:
            return "Heater Temperatures";
        case SpecificationProperty.NumberOfStages:
            return "Number of Stages";
        case SpecificationProperty.GasPressure:
            return "Gas Pressure";
        case SpecificationProperty.SolventTemperature:
            return "Solvent Temperature";
        case SpecificationProperty.SolventFlowrate:
            return "Solvent Flowrate";
        case SpecificationProperty.SwingPressure:
            return "Swing Pressure";
        default:
            return "-";
    }
}

export const GetSeparationMethodName = (option: SeparationMethodOption) => {
    switch (option) {
        case SeparationMethodOption.Absorption:
            return "Absorption";
        case SeparationMethodOption.IdealMixtureDistillation:
            return "Ideal Mixture Distillation";
        case SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer:
            return "Minimum-Boiling Azeotropes with Extractive Distillation";
        case SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation:
            return "Minimum-Boiling Azeotropes with Pressure Swing Distillation";
        default:
            return "-";

    }
}

export const GetSpecificationPropertyUnit = (specificationProperty: SpecificationProperty, unitInOneLine?: boolean): string | JSX.Element => {
    switch (specificationProperty) {
        case SpecificationProperty.CondenserPressure:
            return "Pa";
        case SpecificationProperty.SwingPressure:
            return "Pa";
        case SpecificationProperty.HeaterTemperatures:
            return "K";
        case SpecificationProperty.SolventTemperature:
            return "K";
        case SpecificationProperty.SolventFlowrate:
            return <span>kmol/h or kg/h {!unitInOneLine && <br />} (Flowrate of Feed)</span>;
        case SpecificationProperty.BottomProductFlowrate:
            return <span>kmol/h or kg/h {!unitInOneLine && <br />} (Flowrate of Feed)</span>;
        default:
            return "";
    }

}