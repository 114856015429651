import { Spinner } from "@fluentui/react-spinner";
import { TableBody, TableBodyProps, TableCell, TableRow } from "@fluentui/react-table";
import React from "react";
import "./table-body-with-loading.styless.scss";

export type TableBodyWithLoadingProps = TableBodyProps & {
    columnCount: number,
    isLoading: boolean,
    loadingMessage?: string,
    itemCount?: number,
    noItemsMessage?: string

}

export const TableBodyWithLoading: React.FC<TableBodyWithLoadingProps> = (props) => {
    const loadingCellStyle = {
        paddingBottom: "var(--spacingHorizontalXXXL)",
        paddingTop: "var(--spacingHorizontalXXXL)",
        textAlign: "center"
    } as React.CSSProperties;
   // console.log("TableBodyWithLoading", props);


    return <TableBody {...props}>
        {!props.isLoading && (props.itemCount === undefined || props.itemCount > 0) && <>{props.children}</>}
        {props.isLoading &&
            <TableRow className="no-hover border-bottom-0"  key="loading-table-row" >
                <TableCell style={loadingCellStyle} colSpan={props.columnCount}><Spinner size="small" label={props.loadingMessage}></Spinner></TableCell>
            </TableRow>}
        {!props.isLoading && props.itemCount !== undefined && props.itemCount == 0 && props.noItemsMessage && <TableRow className="no-hover border-bottom-0"  key="no-items-table-row" >
            <TableCell style={loadingCellStyle} colSpan={props.columnCount}>{props.noItemsMessage}</TableCell>
        </TableRow>}
    </TableBody>
}