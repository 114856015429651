import { Button, Field, Input, Menu, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, SplitButton, Tab, TabList } from "@fluentui/react-components";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableBody } from "@fluentui/react-components";

import { DismissRegular } from "@fluentui/react-icons";
import React, { useState } from "react";
import { ConfirmationDialog } from "../../../components/confirmation-dialog/confirmation-dialog.component";
import ShowLocalTime from "../../../components/show-local-time/show-local-time.component";
import { TableBodyWithLoading } from "../../../components/table-body-with-loading/table-body-with-loading.component";
import { getUserToken } from "../../../services/dashboard.service";
import { GLOBAL_STYLES } from "../../../styles";
import { ValidationResult } from "../../../utils/helpers/validation.helpers";
import { LoadingService } from "../../../utils/loading-indicator.component";
import { ExamData, ExamDataType } from "../../exam-data/exam-data.component";
import { IExercise, IExerciseParameter } from "../../shared/exams.models";
import { ExerciseTemplateEditor, defaultPlaceholders } from "./exercise-template-editor/exercise-template-editor.component";
import "./exercises-table.styless.scss";
import MathJaxDisplay from "../../../components/mathjax-display/mathjax-display";

interface IExercisesTableProps {
    examId?: number;
    validationResult?: ValidationResult;
    isFormSubmitted: boolean;
    isRequestInProgress: boolean;
    isDetails?: boolean;
    exercises: IExercise[];
    loadingService: LoadingService;
    introductionTemplate?: string;
    isLoading: boolean;
    onChange?(exercises: IExercise[]);
    onIntroductionTemplateChange?(data: string);
}
enum ExamParameterType {
    Input,
    Output
}
export type ExcerciseTableType = {
    getGeneratedData: () => void;
}

const useGlobalOrderCounter = () => {
    const [globalOrderCounter, setGlobalOrderCounter] = useState(0);

    return [
        (): number => {
            let newValue = globalOrderCounter + 1;
            setGlobalOrderCounter(newValue);
            return newValue;
        },
        (value: number): void => setGlobalOrderCounter(value)
    ] as const;
};

export const ExercisesTable = React.forwardRef<ExcerciseTableType, IExercisesTableProps>((props, ref) => {
    React.useImperativeHandle(
        ref,
        () => ({
            getGeneratedData() {
                getGeneratedData();
            }
        }));

    const [selectedExerciseIndex, setSelectedExerciseIndex] = React.useState<number | undefined>(-1);
    const [selectedSubExerciseIndex, setSelectedSubExerciseIndex] = React.useState<number | undefined>(0);
    const [showDeleteExercise, setShowDeleteExercise] = React.useState<boolean>(false);
    const [isDeleteExercise, setIsDeleteExercise] = React.useState<boolean>(false);
    const [accessToken, setAccessToken] = React.useState<string>();
    const ExamDataRef = React.useRef<ExamDataType>();

    const [getNewGlobalOrder, setGlobalOrderCounter] = useGlobalOrderCounter();

    React.useEffect(() => {
        onInit();
    }, []);

    React.useEffect(() => {
        // Get current max global order counter
        let existingOrders = props.exercises.map((e, i) => {
            if (e.subExercises && e.subExercises.length > 0) {
                return [e.order, e.subExercises.length * (i + 1), ...e.subExercises.map(s => s.subExerciseOrder)];
            } else {
                return e.order;
            }
        }).flat();
        let currentMaxOrder = Math.max(props.exercises.length, ...existingOrders);
        setGlobalOrderCounter(currentMaxOrder);
        console.log("setGlobalOrderCounter", currentMaxOrder, existingOrders);
    }, [props?.exercises, props?.exercises?.length]);

    const onInit = async () => {
        const userToken = await getUserToken();
        setAccessToken(userToken);
    }

    const getGeneratedData = () => {
        ExamDataRef.current?.getExamData();
    }

    const onAddExerciseClick = () => {
        const { exercises } = props;

        const newExercise = {
            examId: props.examId,
            order: getNewGlobalOrder(),
            inputParameters: [],
            outputParameters: [],
            subExercises: []
        } as IExercise;

        const updatedExercises = [...exercises, newExercise];
        setSelectedExerciseIndex(updatedExercises.length - 1);

        props.onChange(updatedExercises);
    }


    const onExerciseTemplateChanged = (data: string) => {

        const { exercises } = props;
        const updatedExercises = exercises.map((exercise, index) => {
            if (index == selectedExerciseIndex) {
                if (exercise.subExercises.length == 0) {
                    return { ...exercise, template: data };
                } else {
                    const subExercises = exercise.subExercises.map((subEx, subExIndex) => {
                        if (subExIndex == 0) {
                            return { ...subEx, template: data };
                        } else {
                            return subEx;
                        }
                    });
                    return { ...exercise, subExercises: subExercises }
                }

            }
            return exercise;

        });
        props.onChange(updatedExercises);
    }

    const onAddSubExerciseClick = (exerciseIndex: number, subExerciseIndex: number) => {
        let { exercises } = props;
        
        

        const newExercise = {
            examId: props.examId,
            order: selectedExercise.order ,
            subExerciseOrder: (selectedExercise.subExercises?.length ?? 0) + 1,
            inputParameters: [],
            outputParameters: [],
            subExercises: []
        } as IExercise;

        if (selectedExercise.subExercises.length == 0) {

            selectedExercise.subExercises = [{
                ...selectedExercise,
                inputParameters: selectedExercise.inputParameters.map((inparam, index) => ({
                    ...inparam,
                    exerciseId: exerciseIndex,
                    generatedName: getGeneratedName({ ...inparam, subexerciseIndex: 0 }),
                    subexerciseIndex: 0
                })),
                outputParameters: selectedExercise.outputParameters.map((outparam, index) => ({
                    ...outparam,
                    exerciseId: exerciseIndex,
                    generatedName: getGeneratedName({ ...outparam, subexerciseIndex: 0 }),
                    subexerciseIndex: 0
                })),

                subExerciseOrder: getNewGlobalOrder()
            },
            { ...newExercise, subExerciseOrder: getNewGlobalOrder() }];
            selectedExercise.inputParameters = [];
            selectedExercise.outputParameters = [];
        } else {
            selectedExercise.subExercises = [...selectedExercise.subExercises, newExercise];
        }

        //  exercises[exerciseIndex] = exercise;
        setSelectedSubExerciseIndex(subExerciseIndex);
        props.onChange([...exercises]);

        console.log("onAddSubExerciseClick called", exerciseIndex, subExerciseIndex);

    }

    const onDeleteExerciseClick = (exerciseIndex: number, subexIndex?: number) => {
        const { exercises } = props;
        let reOrderedExercises = [];
        if (subexIndex !== undefined && !isDeleteExercise) {

            exercises[exerciseIndex].subExercises.splice(subexIndex, 1);
            exercises[exerciseIndex].subExercises = exercises[exerciseIndex].subExercises.map((x, index) => {

                let exercise = { ...x, subExerciseOrder: index + 1 } as IExercise;
                exercise.inputParameters = exercise.inputParameters.map((inparam, paramIndex) => {
                    return { ...inparam, subexerciseIndex: index }
                });
                exercise.outputParameters = exercise.outputParameters.map((outparam, paramIndex) => {
                    return { ...outparam, subexerciseIndex: index }
                });

                return exercise;
            });
            reOrderedExercises = exercises;

        } else {

            exercises.splice(exerciseIndex, 1);
            reOrderedExercises = exercises.map((x, index) => {
                let exercise = { ...x, order: index + 1 };
                exercise.inputParameters = exercise.inputParameters.map((inparam, paramIndex) => {
                    return { ...inparam, exerciseIndex: index }
                });
                exercise.outputParameters = exercise.outputParameters.map((outparam, paramIndex) => {
                    return { ...outparam, exerciseIndex: index }
                });

                return exercise;
            });
        }



        props.onChange(reOrderedExercises);
    }


    const onAddParameterClick = (exerciseIndex: number, parameterType: ExamParameterType, isEdit: boolean, subExIndex?: number) => {
        let { exercises } = props;
        let exercise: IExercise = exercises[exerciseIndex];
        let subExercise = exercise.subExercises[subExIndex];
        if (exercise) {
            if (subExIndex == undefined || !subExercise) {
                if (parameterType == ExamParameterType.Input) {
                    exercise.inputParameters.push({
                        exerciseIndex: exerciseIndex,
                        exerciseId: exercise.id,
                        order: exercise.inputParameters.length,
                        rnd: Math.random().toString(36).slice(2, 7)
                    } as IExerciseParameter);
                } else {
                    exercise.outputParameters.push({
                        exerciseIndex: exerciseIndex,
                        exerciseId: exercise.id,
                        order: exercise.outputParameters.length,
                        rnd: Math.random().toString(36).slice(2, 7)
                    } as IExerciseParameter);
                }
            } else {

                if (parameterType == ExamParameterType.Input) {

                    subExercise.inputParameters.push({
                        exerciseIndex: exerciseIndex,
                        exerciseId: exercise.id,
                        subexerciseIndex: subExIndex,
                        order: subExercise.inputParameters.length,
                        rnd: Math.random().toString(36).slice(2, 7)
                    } as IExerciseParameter);
                } else {
                    subExercise.outputParameters.push({
                        exerciseIndex: exerciseIndex,
                        exerciseId: exercise.id,
                        subexerciseIndex: subExIndex,
                        order: subExercise.outputParameters.length,
                        rnd: Math.random().toString(36).slice(2, 7)
                    } as IExerciseParameter);
                }

            }


            props.onChange(exercises);
        }

    }

    const onTextPropertyChange = (ev: any, newValue: string, property: string, exerciseIndex: number, rowIndex: number, parameterType: ExamParameterType, isEdit: boolean, subExIndex?: number) => {
        let { exercises } = props;
        if (parameterType == ExamParameterType.Input) {
            if (subExIndex == undefined) {
                let inputParameter: IExerciseParameter = exercises[exerciseIndex].inputParameters[rowIndex];
                if (inputParameter) {
                    inputParameter[property] = newValue;
                    inputParameter.generatedName = getGeneratedName(inputParameter);
                    exercises[exerciseIndex].inputParameters[rowIndex] = inputParameter;

                    props.onChange(exercises);
                }
            } else {
                let inputParameter: IExerciseParameter = exercises[exerciseIndex].subExercises[subExIndex].inputParameters[rowIndex];
                if (inputParameter) {
                    inputParameter[property] = newValue;
                    inputParameter.generatedName = getGeneratedName(inputParameter);
                    exercises[exerciseIndex].subExercises[subExIndex].inputParameters[rowIndex] = inputParameter;

                    props.onChange(exercises);
                }
            }

        } else {
            if (subExIndex == undefined) {
                let outputParameter: IExerciseParameter = exercises[exerciseIndex].outputParameters[rowIndex];

                if (outputParameter) {
                    outputParameter[property] = newValue;
                    outputParameter.generatedName = getGeneratedName(outputParameter);
                    exercises[exerciseIndex].outputParameters[rowIndex] = outputParameter;
                    props.onChange(exercises);
                }
            } else {
                let outputParameter: IExerciseParameter = exercises[exerciseIndex].subExercises[subExIndex].outputParameters[rowIndex];

                if (outputParameter) {
                    outputParameter[property] = newValue;
                    outputParameter.generatedName = getGeneratedName(outputParameter);
                    exercises[exerciseIndex].subExercises[subExIndex].outputParameters[rowIndex] = outputParameter;
                    props.onChange(exercises);
                }
            }

        }
    }


    const getGeneratedName = (parameter: IExerciseParameter) => {

        let generatedName = `EX${parameter.exerciseIndex + 1}`;

        if (parameter.subexerciseIndex !== undefined) {
            const subExChar = String.fromCharCode(97 + parameter.subexerciseIndex);
            generatedName += `_${subExChar}`;
        }

        if (parameter.column && parameter.column !== "") {
            generatedName += `_${parameter.column}`;
        }
        if (parameter.alias && parameter.alias !== "") {
            generatedName += `_${parameter.alias}`;
        }

        return generatedName;

    }

    const onRemoveParameterClick = (exerciseIndex: number, parameterIndex: number, parameterType: ExamParameterType, isEdit: boolean, subExIndex?: number) => {

        const { exercises } = props;
        let exercise: IExercise = exercises[exerciseIndex];
        if (subExIndex !== undefined) {
            exercise = exercises[exerciseIndex].subExercises[subExIndex];
        }
        if (exercise) {
            if (parameterType == ExamParameterType.Input) {
                exercise.inputParameters = exercise.inputParameters.filter((x, index) => index != parameterIndex);
                exercise.inputParameters = exercise.inputParameters.map((inputParam, index) => {
                    return {
                        ...inputParam,
                        order: index
                    }
                });

            } else {
                exercise.outputParameters = exercise.outputParameters.filter((x, index) => index != parameterIndex);
                exercise.outputParameters = exercise.outputParameters.map((outputParam, index) => {
                    return {
                        ...outputParam,
                        order: index
                    }
                });
            }

            props.onChange(exercises);
        }

    }

    const onCloneExerciseClick = (exIndex: number, subexIndex?: number) => {
        console.log("onCloneExerciseClick", exIndex, subexIndex);
        try {
            let { exercises } = props;
            let newExercise = { ...exercises[exIndex] };

            if (newExercise && subexIndex == undefined) {
                newExercise.id = undefined;
                newExercise.examId = props.examId;
                newExercise.order = exercises.length + 1;
                newExercise.template = undefined;

                newExercise.inputParameters = exercises[exIndex].inputParameters.map(inputParam => {
                    return {
                        ...inputParam,
                        id: undefined,
                        exerciseId: undefined,
                        exerciseIndex: exercises.length,
                        generatedName: getGeneratedName({ ...inputParam, exerciseIndex: exercises.length })
                    }
                });
                newExercise.outputParameters = exercises[exIndex].outputParameters.map(outParam => {
                    return {
                        ...outParam,
                        id: undefined,
                        exerciseId: undefined,
                        exerciseIndex: exercises.length,
                        generatedName: getGeneratedName({ ...outParam, exerciseIndex: exercises.length })

                    }
                });

                if (newExercise.subExercises && newExercise.subExercises.length > 0) {
                    newExercise.subExercises = exercises[exIndex].subExercises.map(subExercise => {

                        return {
                            ...subExercise,
                            template: undefined,
                            id: undefined,
                            examId: undefined,
                            order: exercises.length + 1,
                            inputParameters: subExercise.inputParameters && subExercise.inputParameters.length > 0 ?
                                subExercise.inputParameters.map(inParam => {

                                    return {
                                        ...inParam,
                                        id: undefined,
                                        exerciseId: undefined,
                                        exerciseIndex: exercises.length,
                                        generatedName: getGeneratedName({ ...inParam })
                                    };
                                }) : [],
                            outputParameters: subExercise.outputParameters && subExercise.outputParameters.length > 0 ?
                                subExercise.outputParameters.map(outParam => {

                                    return {
                                        ...outParam,
                                        id: undefined,
                                        exerciseId: undefined,
                                        exerciseIndex: exercises.length,
                                        generatedName: getGeneratedName({ ...outParam })
                                    };
                                }) : []
                        } as IExercise;


                    });
                }

                exercises.push(newExercise);
            } else {
                const subexercise = exercises[exIndex].subExercises[subexIndex];
                newExercise = {
                    ...subexercise,
                    template: undefined,
                    id: undefined,
                    examId: props.examId,
                    subExerciseOrder: exercises[exIndex].subExercises.length + 1
                };

                newExercise.inputParameters = subexercise.inputParameters.map(inputParam => {
                    return {
                        ...inputParam,
                        id: undefined,
                        exerciseId: undefined,
                        exerciseIndex: subexercise.order - 1,
                        subexerciseIndex: exercises[exIndex].subExercises.length,
                        generatedName: getGeneratedName(inputParam)
                    }
                });
                newExercise.outputParameters = subexercise.outputParameters.map(outParam => {
                    return {
                        ...outParam,
                        id: undefined,
                        exerciseId: undefined,
                        exerciseIndex: subexercise.order - 1,
                        subexerciseIndex: exercises[exIndex].subExercises.length,
                        generatedName: getGeneratedName(outParam)

                    }
                });


                exercises[exIndex].subExercises.push(newExercise);
            }

            const selectedSubEx = subexIndex !== undefined ? subexIndex : 0;
            setSelectedExerciseIndex(exIndex);
            setSelectedSubExerciseIndex(selectedSubEx);
            props.onChange([...exercises]);




        } catch (error) {
            console.log("An error occurred while  cloning exercise.", error);
        }

    }

    const onAddExerciseRenderLink = (text: string) => {
        return <Button appearance="primary" >{text}</Button>
    }

    const onRenderItemLink = (exIndex: number, validationResult: ValidationResult, isFormSubmitted: boolean): JSX.Element => {

        var errors = isFormSubmitted && validationResult ? validationResult.getPartialFieldValidationMessages(`ex_${exIndex}`, isFormSubmitted) : [];
        const hasErrors = errors && errors.length > 0 ? true : false;
        if (props.isDetails) {
            return <span> {`Exercise ${exIndex + 1}`}</span>
        }

        return <Menu positioning="below-end">
            <MenuTrigger disableButtonEnhancement>
                {(triggerProps: MenuButtonProps) =>
                    <SplitButton menuButton={triggerProps} appearance="transparent" className="split-button-without-line" >
                        <span style={{ color: hasErrors ? "red" : "black" }}> {`Exercise ${exIndex + 1}`}</span>
                    </SplitButton>}
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem onClick={() => { onCloneExerciseClick(exIndex, undefined) }}>Clone Exercise</MenuItem>
                    <MenuItem onClick={() => {
                        setIsDeleteExercise(true);
                        setSelectedExerciseIndex(exIndex);
                        setShowDeleteExercise(true);
                    }}>Delete Exercise</MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>


    }

    const onSubExerciseRenderItemLink = (exIndex: number, subexIndex: number, validationResult: ValidationResult, isFormSubmitted: boolean): JSX.Element => {

        if (props.isDetails) {
            return <span> {`Sub-exercise ${exIndex + 1}.${String.fromCharCode(97 + subexIndex)}`}</span>
        }

        return <Menu positioning="below-end">
            <MenuTrigger disableButtonEnhancement>
                {(triggerProps: MenuButtonProps) =>
                    <SplitButton menuButton={triggerProps} appearance="transparent" className="split-button-without-line" >
                        <span> {`Sub-exercise ${exIndex + 1}.${String.fromCharCode(97 + subexIndex)}`}</span>
                    </SplitButton>}
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem onClick={() => { onCloneExerciseClick(exIndex, subexIndex) }}>Clone Exercise</MenuItem>
                    <MenuItem onClick={() => {
                        setIsDeleteExercise(false);
                        setSelectedExerciseIndex(exIndex);
                        setSelectedSubExerciseIndex(subexIndex);
                        setShowDeleteExercise(true);
                    }}>Delete Exercise</MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    }

    const getParameterDeleteCellValue = (item: IExerciseParameter, index, parameterType: ExamParameterType) => {
        if (!props.isDetails) {
            const isEdit: boolean = item.id > 0;
            return <Button appearance="transparent" onClick={() => { onRemoveParameterClick(item.exerciseIndex, index, parameterType, isEdit, item.subexerciseIndex) }} icon={<DismissRegular />} />

        } else {
            return "";
        }
    }


    const { exercises } = props;
    const selectedExercise = exercises && selectedExerciseIndex > -1 && exercises.length > 0 ? exercises[selectedExerciseIndex] : undefined;
    const selectedSubExercise = selectedExercise && selectedSubExerciseIndex > -1 && selectedExercise.subExercises.length > 0 ? selectedExercise.subExercises[selectedSubExerciseIndex] : selectedExercise;
    console.log("selectedExercise,selectedSubExerciseIndex", selectedExercise, selectedSubExerciseIndex);
    let placeholders = [...defaultPlaceholders];
    if (selectedExercise) {
        if (selectedExercise.inputParameters && selectedExercise.inputParameters.length > 0) {
            selectedExercise.inputParameters.forEach(inParam => {
                if (inParam.generatedName) {
                    placeholders.push(inParam.generatedName);
                }
            });
        }
        selectedExercise.subExercises.forEach(subEx => {
            if (subEx.inputParameters) {
                subEx.inputParameters.forEach(inParam => {
                    if (inParam.generatedName) {
                        placeholders.push(inParam.generatedName);
                    }
                });
            }
        });

    }

    return <>
        <TabList selectedValue={selectedExerciseIndex !== undefined ? selectedExerciseIndex.toString() : undefined}
            onTabSelect={(ev, data) => {
                if (!props.isDetails && exercises.length.toString() == data.value) {
                    onAddExerciseClick();
                }
                setSelectedExerciseIndex(+data.value);
                const ex = exercises[+data.value];
                if (ex && ex.subExercises.length > 0) {
                    setSelectedSubExerciseIndex(0);
                } else {
                    setSelectedSubExerciseIndex(-1);
                }

            }}>
            <Tab key="-1" value="-1">Introduction</Tab>

            {
                exercises.map((exercise, exIndex) => {
                    const isEdit = exercise.id > 0;
                    return <Tab key={exIndex.toString()} value={exIndex.toString()} >{onRenderItemLink(exIndex, props.validationResult, props.isFormSubmitted)}</Tab>
                })
            }


            {!props.isDetails && <Tab key={exercises.length.toString()} value={exercises.length.toString()} >{onAddExerciseRenderLink("Add Exercise")}</Tab>}
            {props.isDetails && exercises.length > 0 && <Tab key={exercises.length.toString()} value={exercises.length.toString()} >Generated Data</Tab>}
        </TabList>
        <div id="exercise-tab-content">
            {selectedExerciseIndex == -1 && accessToken && !props.isLoading && <>
                {props.isDetails &&
                    <div className="exam-pdf-wrapper" style={{ marginTop: 'var(--spacingVerticalM)' }}>
                        <MathJaxDisplay html={highlightPlaceholders(props.introductionTemplate)} />
                    </div>
                }
                {!props.isDetails && <ExerciseTemplateEditor enablePlaceholder
                    accessToken={accessToken}
                    placeholders={defaultPlaceholders}
                    value={props.introductionTemplate}
                    onValueChange={(data) => props.onIntroductionTemplateChange(data)} />}
            </>
            }

            {selectedExerciseIndex > -1 && <div>
                <TabList selectedValue={selectedExerciseIndex !== undefined && selectedSubExerciseIndex !== undefined ? `${selectedExerciseIndex.toString()}_${selectedSubExerciseIndex.toString()}` : undefined}
                    onTabSelect={(ev, data) => {
                        console.log("on subExercise tab click", data.value);

                        const keys = data.value.toString().split("_");
                        const exerciseIndex = +keys[0];
                        const subExerciseIndex = +keys[1];
                        const pivotExercise: IExercise = exercises[exerciseIndex];

                        if (!props.isDetails && (!pivotExercise || pivotExercise.subExercises.length.toString() == subExerciseIndex.toString())) {
                            onAddSubExerciseClick(exerciseIndex, subExerciseIndex);
                        }

                        setSelectedSubExerciseIndex(subExerciseIndex);


                    }}>

                    {
                        selectedExercise && selectedExercise.subExercises.map((subexercise, subExIndex) => {

                            return <Tab
                                key={`${selectedExerciseIndex.toString()}_${subExIndex.toString()}`}
                                value={`${selectedExerciseIndex.toString()}_${subExIndex.toString()}`} >
                                {onSubExerciseRenderItemLink(selectedExerciseIndex, subExIndex, props.validationResult, props.isFormSubmitted)}</Tab>
                        })
                    }
                    {selectedExercise && selectedExercise.subExercises.length == 0 && <Tab
                        key={`${selectedExerciseIndex.toString()}_-1`}
                        value={`${selectedExerciseIndex.toString()}_-1`} >
                        Parameters</Tab>}


                    {selectedExercise && <Tab key={`${selectedExerciseIndex.toString()}_${(selectedExercise?.subExercises?.length > 0 ? selectedExercise?.subExercises?.length + 1 : 1).toString()}`}
                        value={`${selectedExerciseIndex.toString()}_${(selectedExercise?.subExercises?.length > 0 ? selectedExercise?.subExercises?.length + 1 : 1).toString()}`}>Template</Tab>}
                    {!props.isDetails && selectedExercise && <Tab
                        key={`${selectedExerciseIndex.toString()}_${(selectedExercise?.subExercises?.length > 0 ? selectedExercise?.subExercises?.length : 0).toString()}`}
                        value={`${selectedExerciseIndex.toString()}_${(selectedExercise?.subExercises?.length > 0 ? selectedExercise?.subExercises?.length : 0).toString()}`} >
                        {onAddExerciseRenderLink("Add Sub Exercise")}</Tab>}
                </TabList>

                <div id="sub-exercise-tab">
                    {selectedSubExercise && selectedSubExerciseIndex <= selectedExercise.subExercises.length &&
                        <>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontSize: "var(--fontSizeBase500)", fontWeight: "var(--fontWeightSemibold)", marginTop: "var(--spacingVerticalL)", marginBottom: "var(--spacingVerticalS)" }}>Input Parameters</span>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell key="alias" className='exams-table__cell--bold'>Alias</TableHeaderCell>
                                            <TableHeaderCell key="column" className='exams-table__cell--bold'>Column</TableHeaderCell>
                                            <TableHeaderCell key="generatedName" className='exams-table__cell--bold column--center'>Generated name</TableHeaderCell>
                                            {!props.isDetails && <TableHeaderCell key="delete" className='exams-table__cell--bold'></TableHeaderCell>}
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {selectedSubExercise && selectedSubExercise.inputParameters.length > 0 && selectedSubExercise.inputParameters.map((param, paramIndex) => {
                                            return <TableRow key={`EX_${selectedExerciseIndex}_Sub_${selectedSubExerciseIndex}_inparam_${paramIndex}`}>
                                                <TableCell><CustomCellValue
                                                    item={param}
                                                    fieldName="alias"
                                                    index={paramIndex}
                                                    parameterType={ExamParameterType.Input}
                                                    onTextPropertyChange={onTextPropertyChange}
                                                    {...props}
                                                /></TableCell>
                                                <TableCell>
                                                    <CustomCellValue
                                                        item={param}
                                                        fieldName="column"
                                                        index={paramIndex}
                                                        parameterType={ExamParameterType.Input}
                                                        onTextPropertyChange={onTextPropertyChange}
                                                        {...props}
                                                    />
                                                </TableCell>
                                                <TableCell className="column--center">{param.generatedName}</TableCell>
                                                {!props.isDetails && <TableCell>{getParameterDeleteCellValue(param, paramIndex, ExamParameterType.Input)}</TableCell>}
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </div>

                            {!props.isDetails && <Button appearance="secondary"
                                style={{ marginTop: "var(--spacingVerticalMNudge)", marginBottom: "var(--spacingVerticalMNudge)" }}
                                onClick={() => onAddParameterClick(selectedExerciseIndex, ExamParameterType.Input, selectedExercise.id > 0, selectedSubExerciseIndex)}
                            >Add Input Parameter</Button>}

                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontSize: "var(--fontSizeBase500)", fontWeight: "var(--fontWeightSemibold)", marginTop: "var(--spacingVerticalL)", marginBottom: "var(--spacingVerticalS)" }}>Output Parameters</span>

                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell key="alias" className='exams-table__cell--bold'>Alias</TableHeaderCell>
                                            <TableHeaderCell key="column" className='exams-table__cell--bold'>Column</TableHeaderCell>
                                            <TableHeaderCell key="generatedName" className='exams-table__cell--bold column--center'>Generated name</TableHeaderCell>
                                            <TableHeaderCell key="description" className='exams-table__cell--bold'>Description</TableHeaderCell>
                                            <TableHeaderCell key="points" className='exams-table__cell--bold'>Points</TableHeaderCell>
                                            {!props.isDetails && <TableHeaderCell key="delete" className='exams-table__cell--bold'></TableHeaderCell>}
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {selectedSubExercise && selectedSubExercise.outputParameters.length > 0 && selectedSubExercise.outputParameters.map((param, paramIndex) => {
                                            return <TableRow key={`EX_${selectedExerciseIndex}_Sub_${selectedSubExerciseIndex}_outparam_${paramIndex}`}>
                                                <TableCell><CustomCellValue
                                                    item={param}
                                                    fieldName="alias"
                                                    index={paramIndex}
                                                    parameterType={ExamParameterType.Output}
                                                    onTextPropertyChange={onTextPropertyChange}
                                                    {...props}
                                                /></TableCell>
                                                <TableCell>
                                                    <CustomCellValue
                                                        item={param}
                                                        fieldName="column"
                                                        index={paramIndex}
                                                        parameterType={ExamParameterType.Output}
                                                        onTextPropertyChange={onTextPropertyChange}
                                                        {...props}
                                                    />
                                                </TableCell>
                                                <TableCell className="column--center">{param.generatedName}</TableCell>
                                                <TableCell><CustomCellValue
                                                    item={param}
                                                    fieldName="description"
                                                    index={paramIndex}
                                                    parameterType={ExamParameterType.Output}
                                                    onTextPropertyChange={onTextPropertyChange}
                                                    {...props}
                                                /></TableCell>
                                                <TableCell><CustomCellValue
                                                    item={param}
                                                    fieldName="points"
                                                    index={paramIndex}
                                                    parameterType={ExamParameterType.Output}
                                                    onTextPropertyChange={onTextPropertyChange}
                                                    {...props}
                                                /></TableCell>
                                                {!props.isDetails && <TableCell>{getParameterDeleteCellValue(param, paramIndex, ExamParameterType.Output)}</TableCell>}
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </div>

                            {!props.isDetails && <Button appearance="secondary"
                                style={{ marginTop: "var(--spacingVerticalMNudge)", marginBottom: "var(--spacingVerticalMNudge)" }}
                                onClick={() => onAddParameterClick(selectedExerciseIndex, ExamParameterType.Output, selectedExercise.id > 0, selectedSubExerciseIndex)}
                            >Add Output Parameter</Button>}
                        </>

                    }
                    {selectedExercise && selectedSubExerciseIndex == (selectedExercise.subExercises?.length ?? 0) + 1 && accessToken && !props.isLoading &&
                        <>
                            {props.isDetails && <div className="exam-pdf-wrapper" style={{ marginTop: 'var(--spacingVerticalM)' }}>
                                <MathJaxDisplay html={selectedExercise && selectedExercise.subExercises.length > 0 ?
                                    highlightPlaceholders(selectedExercise.subExercises[0].template) : highlightPlaceholders(selectedExercise.template)} />

                            </div>
                            }
                            {!props.isDetails && <ExerciseTemplateEditor
                                accessToken={accessToken}
                                enablePlaceholder
                                placeholders={placeholders}
                                value={selectedExercise && selectedExercise.subExercises.length > 0 ?
                                    selectedExercise.subExercises[0].template : selectedExercise.template}
                                onValueChange={(data) => onExerciseTemplateChanged(data)} />}
                        </>


                    }

                </div>
                {props.isDetails && exercises.length > 0 && selectedExerciseIndex == exercises.length &&
                    <ExamData ref={ExamDataRef} loadingService={props.loadingService} isLoading={props.isLoading} examId={props.examId} exercises={props.exercises} />}
            </div>}
        </div>
        <ConfirmationDialog
            isOpened={showDeleteExercise}
            title="Delete Exercise"
            subText={isDeleteExercise == false ?
                `Are you sure that you want to delete sub-exercise ${selectedExerciseIndex + 1}.${String.fromCharCode(97 + selectedSubExerciseIndex)}?` :
                `Are you sure that you want to delete Exercise ${selectedExerciseIndex + 1}?`}
            onConfirm={() => {
                onDeleteExerciseClick(selectedExerciseIndex, selectedSubExerciseIndex);
                if (isDeleteExercise) {
                    setSelectedExerciseIndex(0);
                }
                setSelectedSubExerciseIndex(0);
                setIsDeleteExercise(false);
                setShowDeleteExercise(false);
            }}
            onClose={() => { setIsDeleteExercise(false); setShowDeleteExercise(false); }}

        />
    </>;
});

type CustomCellValueProps = {
    item: IExerciseParameter,
    index: number;
    parameterType: ExamParameterType,
    fieldName: string;
    isDetails?: boolean,
    validationResult?: ValidationResult,
    isFormSubmitted: boolean,
    isRequestInProgress: boolean,
    onTextPropertyChange(ev: any, newValue: string, property: string, exerciseIndex: number, rowIndex: number, parameterType: ExamParameterType, isEdit: boolean, subExIndex?: number)
}

const CustomCellValue: React.FC<CustomCellValueProps> = (props) => {
    const { item, index, parameterType } = props;
    let parameterId = `ex_${item.exerciseIndex}`;
    if (item.subexerciseIndex !== undefined) {
        parameterId += `_${item.subexerciseIndex}`;
    }
    parameterId += `_row_${index}_${props.fieldName}`;


    let columnId = parameterType == ExamParameterType.Input ? `ip_${parameterId}` : `op_${parameterId}`;
    const isEdit = item.id > 0;

    if (props.isDetails) {
        return <p style={{ margin: 0 }}>{item[props.fieldName]}</p>
    }

    const validationMessage = props.validationResult.getFieldValidationMessage(columnId, props.isFormSubmitted);

    return <Field
        className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
        validationMessage={validationMessage}
        validationState={props.isFormSubmitted && validationMessage ? "error" : "none"}>
        <Input
            key={`${item.rnd}_${props.fieldName}`}
            defaultValue={item[props.fieldName]}

            disabled={props.isRequestInProgress}
            onChange={(ev, data) => { props.onTextPropertyChange(ev, data.value, props.fieldName, item.exerciseIndex, index, parameterType, isEdit, item.subexerciseIndex) }} />
    </Field>


}




export const highlightPlaceholders = (text: any) => {
    return text?.replace(/\[\[(.*?)\]\]/g, '<span class="text-highlight">[[$1]]</span>');
}