
import { Input, Select } from "@fluentui/react-components";
import * as React from "react";
import { ParameterDisplay } from "../../../components/parameter-display/parameter-display.component";
import { FileModel, FilterResultModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { IDropdownOption } from "../../../utils/shared.types";
import { SensitivityStudyResultModel } from "../../../swagger-clients/sensitivity-studies-api-clients.service";

import "./copilot-parameter-picker.component.scss";
import ReactSelect, { ActionMeta, components, DropdownIndicatorProps, MultiValue, SingleValueProps } from "react-select";

import { ChevronDown20Regular, Options16Filled } from "@fluentui/react-icons";
import { DwsimComponentDisplayName } from "../../../components/dwsim-component-display-name/dwsim-component-display-name.component";
import { ResultInputParameter } from "../../components/result-input-parameter.component";
import { SeparationMethodOption } from "../../../swagger-clients/ai-for-pfd-clients.service";
import { ResultOutputParameter } from "../../components/result-output-parameter.component";
interface ICopilotFilterParameterPickerProps {
    value?: ICopilotFilterParamData;
    separationMethod: SeparationMethodOption;
    options: CopilotParamSelectOption[];
    onChange?(data: ICopilotFilterParamData);
}


enum FilterOperation {
    Equals = 0,
    Between = 1
}

export interface ICopilotFilterParamData {
    parameterId: string;
    operation: FilterOperation;
    equalsValue?: string;
    endValue?: string;
}
export type CopilotParamSelectOption = {
    value: string | number;
    label: string;
}


const CopilotFilterParameterPicker: React.FC<ICopilotFilterParameterPickerProps> = (props, ref) => {



    const [selectedParameter, setSelectedParameter] = React.useState<string>(props.value?.parameterId ?? "-1");
    const [parameterOptions, setParameterOptions] = React.useState<CopilotParamSelectOption[]>([{ value: "-1", label: "All" }]);
    const [selectedOperation, setSelectedOperation] = React.useState<FilterOperation>(props.value?.operation ?? FilterOperation.Equals);
    // equals and startValue depending on selectedOperation
    const [equalsValue, setEqualsValue] = React.useState<string>(props.value?.equalsValue ?? "");
    const [endValue, setEndValue] = React.useState<string>(props.value?.endValue ?? "");

    React.useEffect(() => {

        setParameterOptions([{ value: "-1", label: "All" }, ...props.options]);
    }, [props.options]);



    React.useEffect(() => {
        setSelectedParameter(props.value?.parameterId ?? "-1");
        setSelectedOperation(props.value?.operation ?? FilterOperation.Equals);
        setEqualsValue(props.value?.equalsValue ?? "");
        setEndValue(props.value?.endValue ?? "");
    }, [props.value]);



    const onDataChanged = (data: ICopilotFilterParamData) => {
        props.onChange(data);
    }


    const Option = (option: CopilotParamSelectOption | any): JSX.Element => {


        let name: any = option.label;
        console.log("option.label", option.label);


        if (option.value.toString().includes("parameters.")) {
            name = <ResultInputParameter parameter={option.label} hideUnit separationMethod={props.separationMethod} />;
        } else {
            if (option.value.toString().includes("purities.") || ["opex", "capex", "energy"].indexOf(option.label) > -1)
                name = <ResultOutputParameter componentCasNumber={option.label} separationMethod={props.separationMethod} hideUnit />;

        }



        return (
            <components.Option {...(option as any)}>
                <div className="copilot-filter-param-text option-test">
                    {name}
                </div></components.Option>

        );
    }
    const onParametersChanged = async (selectedOption: CopilotParamSelectOption | MultiValue<CopilotParamSelectOption>, actionMeta: ActionMeta<CopilotParamSelectOption>) => {
        if (!Array.isArray(selectedOperation)) {
            setSelectedParameter((selectedOption as CopilotParamSelectOption).value.toString());
            const value = (selectedOption as CopilotParamSelectOption).value.toString();
            if (value == "-1") {
                setSelectedOperation(FilterOperation.Equals);
                setEndValue("");
                setEqualsValue("");
                onDataChanged({
                    operation: FilterOperation.Equals,
                    parameterId: undefined,
                    endValue: "",
                    equalsValue: ""
                } as ICopilotFilterParamData);
            } else {
                onDataChanged({
                    operation: selectedOperation,
                    parameterId: value,
                    endValue: endValue,
                    equalsValue: equalsValue
                } as ICopilotFilterParamData);
            }
        }

    }

    const SingleValue = ({
        children,
        ...singleValueProps
    }: SingleValueProps<CopilotParamSelectOption>) => {

        const option = parameterOptions.find(x => x.value == selectedParameter);

        let name: any = option.label;

        if (option.value.toString().includes("parameters.")) {
            name = <ResultInputParameter parameter={option.label} hideUnit separationMethod={props.separationMethod} />;
        } else {
            if (option.value.toString().includes("purities."))
                name = <ResultOutputParameter componentCasNumber={option.label} separationMethod={props.separationMethod} hideUnit />;

        }

        return <components.SingleValue {...singleValueProps}>
            <div className="copilot-filter-param-text singlevalue-test">
                {name}
            </div>
        </components.SingleValue>
    }


    const DropdownIndicator = (
        props: DropdownIndicatorProps<CopilotParamSelectOption, true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <ChevronDown20Regular />
            </components.DropdownIndicator>
        );
    };


    const operationOptions: IDropdownOption[] = [
        {
            key: FilterOperation.Equals, text: "Equals"
        },
        {
            key: FilterOperation.Between, text: "Between"
        }];

    const selectedParameterOption = parameterOptions.find(x => x.value == selectedParameter);

    return <div style={{ display: "flex", flexDirection: "row", marginRight: "10px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginRight: "10px" }}>Parameter:</span>
            <ReactSelect
                isSearchable={false}
                value={selectedParameterOption}
                isClearable={false}
                options={parameterOptions}
                styles={{
                    ...customStyles

                }
                }
                onChange={onParametersChanged}
                components={{ Option: Option, SingleValue: SingleValue, DropdownIndicator }}

            />
        </div>
        {selectedParameter !== "-1" && <>
            <Select
                style={{ alignSelf: "end", marginRight: "5px", minWidth: "100px" }}
                value={selectedOperation}
                onChange={(ev, data) => {
                    if (data.value == FilterOperation.Equals.toString()) {
                        setEndValue("");
                    }
                    setSelectedOperation(+data.value);
                    onDataChanged({
                        operation: +data.value,
                        parameterId: selectedParameter,
                        endValue: endValue,
                        equalsValue: equalsValue
                    } as ICopilotFilterParamData);
                }}
            >
                {operationOptions.map((option) => {
                    return <option value={option.key}>{option.text}</option>
                })}
            </Select>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginRight: "10px" }}>{selectedOperation == FilterOperation.Equals ? "Value" : "Start"}</span>
                <Input
                    type="number"
                    style={{ minWidth: "100px", marginRight: selectedOperation == FilterOperation.Between ? "5px" : "0px" }}
                    value={equalsValue}
                    onChange={(ev, data) => {
                        setEqualsValue(data.value);
                        onDataChanged({
                            operation: selectedOperation,
                            parameterId: selectedParameter,
                            endValue: endValue,
                            equalsValue: data.value
                        } as ICopilotFilterParamData);
                    }}
                />
            </div>
            {selectedOperation == FilterOperation.Between &&
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginRight: "10px" }}>End</span>
                    <Input
                        type="number"
                        value={endValue}
                        onChange={(ev, data) => {
                            setEndValue(data.value);
                            onDataChanged({
                                operation: selectedOperation,
                                parameterId: selectedParameter,
                                endValue: data.value,
                                equalsValue: equalsValue
                            } as ICopilotFilterParamData);
                        }}
                        style={{ minWidth: "100px" }}
                    />
                </div>


            }
        </>
        }



    </div >;
}

const customStyles = {
    container: (base, state) => ({
        ...base,
        marginRight: "10px",
        borderBottomStyle: "outset",
        borderBottomColor: "buttonborder",
        borderWidth: "0.1px",
        borderRadius: "4px"
    }),
    control: (base, state) => ({
        ...base,
        borderRadius: '4px',
        height: '32px',
        lineHeight: '32px',
        padding: '0',
        minHeight: "0",
        border: '1px solid #ccc',
        borderColor: "#ccc !important",
        boxShadow: "none !important",
        backgroundColor: 'white',
        minWidth: "400px"
    }),
    valueContainer: base => ({
        ...base,
        height: '31px',
        lineHeight: '31px',
        marginBottom: "5px",
        padding: "0",
        borderWidth: "0",
        paddingLeft: '12px',
    }),
    indicatorSeparator: base => ({
        ...base,
        display: "none"
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        padding: '6px',
        marginBottom: "5px",
        color: "var(--colorNeutralStrokeAccessible) !important"

    }),
    menu: base => ({
        ...base,
        borderRadius: '4px',
        padding: "0",
        margin: "0",
        backgroundColor: 'white',
        border: '1px solid #ccc',
    }),
    option: (base, state) => ({
        ...base,
        color: '#000',
        height: "32px",
        padding: '6px 8px',
        backgroundColor: state.isFocused ? '#f2f2f2' : state.isSelected ? undefined : 'white',
        whiteSpace: 'normal'
    })
};

export default CopilotFilterParameterPicker;