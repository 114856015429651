import React from "react";
import { useForm } from "react-hook-form";
import { CreateContactPostModel, ICreateContactPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { useLoading } from "../../utils/loading-indicator.component";
import { getContactsClient } from "../../services/dashboard.service";
import { toast } from "react-toastify";
import { processServerError } from "../../utils/helpers/error.helper";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, Input } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { GLOBAL_STYLES } from "../../styles";

type AddContactDialogProps = {
    isOpened?: boolean;
    onSuccess: () => void;
    onClose?: () => void;
}

export const AddContactDialog: React.FC<AddContactDialogProps> = (props) => {

    const { register, handleSubmit, watch, setError, clearErrors, setValue, formState: { errors }, reset } = useForm<ICreateContactPostModel>({
        mode: "onSubmit"
    });
    const [isLoading, loadingService] = useLoading();

    const onSubmit = async (data: ICreateContactPostModel) => {
        loadingService.showLoading("Creating new folder...", async (hideMessage) => {
            try {
                console.log("on create directory", data);


                const client = getContactsClient();
                var model = new CreateContactPostModel();
                model.emailOrUserPrincipalName = data.emailOrUserPrincipalName;
                const resp = await client.addContact(model);
                if (resp.sentInvitation) {
                    toast.success(<div>
                        <span>The invitation was sent. <br />
                            The contact will be added to the list once your invitation has been accepted.</span>
                    </div>);
                }
                if (resp.acceptedAutomatically) {
                    toast.success("Contact is added to list of contacts.");
                }

                // setOpen(false);
                props.onSuccess();


            } catch (error) {
                processServerError(error, setError, "An error occurred while adding contact.");
            } finally {
                hideMessage();
            }
        });

    }

    const onModalClose = () => {
        setOpen(false);
        reset();
        if (props.onClose)
            props.onClose();
    }

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const [open, setOpen] = React.useState(false);

    return (<Dialog open={open} onOpenChange={(event, data) => {
        reset();

        setOpen(data.open);
        if (data.open == false) {
            if (props.onClose)
                props.onClose();
        }

    }}>
        <DialogSurface>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogBody>
                    <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Add Contact</DialogTitle>
                    <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                        <Field
                            className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                            validationState={!!errors.emailOrUserPrincipalName ? "error" : "none"}
                            validationMessage={
                                <span>
                                    {errors.emailOrUserPrincipalName && <>{errors.emailOrUserPrincipalName.message}</>}
                                </span>}>
                            <Input
                                id="directoryName"
                                {...register("emailOrUserPrincipalName", { required: { value: true, message: "Email or user principal name is required." } })}
                                placeholder="Enter email or user principal name"
                                onChange={(ev, data) => { clearErrors(["emailOrUserPrincipalName"]); setValue("emailOrUserPrincipalName", data.value); }}
                            />
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={isLoading} type="submit" appearance="primary">Add</LoadingButton>


                    </DialogActions>
                </DialogBody>
            </form>
        </DialogSurface>
    </Dialog>);
}