import moment from "moment";

export function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending: boolean = false): T[] {
  const key = columnKey as keyof T;
  if (columnKey.split('.').length == 2) {
    const splitKey = columnKey.split('.');
    return items.slice(0).sort((a: T, b: T) => (compareItems(a[splitKey[0]]?.[splitKey[1]], b[splitKey[0]]?.[splitKey[1]], isSortedDescending)));
  }
  return items.slice(0).sort((a: T, b: T) => (compareItems(a[key], b[key], isSortedDescending)));
}

function compareItems(a: any, b: any, isSortedDescending: boolean): number {
 // console.log("compareItems:", a, b);
  if (a === null) a = undefined;
  if (b === null) b = undefined;

  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a !== undefined && b === undefined)
    return -1;

  if (a === undefined && b !== undefined)
    return 1;

  if (typeof a == "boolean") {
    return (isSortedDescending ? Number(a) < Number(b) : Number(a) > Number(b)) ? 1 : -1;
  }
  if (/^\d*(\.\d+)?$/.test(a)) {
    //  console.log("sorting numbers", a, b, isSortedDescending);
    return (isSortedDescending ? Number(a) < Number(b) : Number(a) > Number(b)) ? 1 : -1;
  }
  else if (moment(a, moment.RFC_2822).isValid()) {
    //  console.log("sorting dates", a, b, isSortedDescending);
    const first = Date.parse(a);
    const second = Date.parse(b);
    return (isSortedDescending ? first < second : first > second) ? 1 : -1;


  }
  else {
    //  console.log("sorting strings", a, b, isSortedDescending);
    return (isSortedDescending ? new String(a).toLowerCase() < new String(b).toLowerCase()
      : new String(a).toLowerCase() > new String(b).toLowerCase()) ? 1 : -1;

  }
}

export const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);