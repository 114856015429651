import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions, Label, RadioGroup, Radio } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { ErrorResponse } from "@remix-run/router";
import React from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { settings } from "../../App";
import { FilePicker } from "s365-dashboard-v2-file-picker";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { getDirectoriesClient, getFilesClient, getUserToken } from "../../services/dashboard.service";
import { ErrorResponseModel, FileModel, FileSystemEntityType, MoveFilePostModel, UploadConflictAction, ValidateFileNamesPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { FileTableItem } from "../models/file-table.models";
import { ConflictFormModel, ConflictMoveResponse, MoveFileConflictModal } from "./move-conflict-dialog.component";
import { getFileExtension } from "../file-type-icon/file-type-icon.helpers";

type MoveFileDialogProps = {
    fileToMove: FileTableItem;
    isOpened: boolean;
    onClose: () => void;
    onSuccess: () => void;
}


export const MoveFileDialog: React.FC<MoveFileDialogProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [accessToken, setAccessToken] = React.useState<string>();
    const [selectedFolder, setSelectedFolder] = React.useState<FileModel>();
    const [isLoading, loadingService] = useLoading();
    const [showMoveFileConflictDialog, setShowMoveFileConflictDialog] = React.useState<boolean>(false);

    const { register, handleSubmit, watch, setError, formState: { errors }, setValue } = useForm<ConflictFormModel>();

    React.useEffect(() => { getAccessToken(); }, []);

    React.useEffect(() => { setOpen(!!props.isOpened); }, [props.isOpened]);

    const getAccessToken = async () => {

        try {
            const token = await getUserToken();
            setAccessToken(token);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting user token.");
        }
    }
    const onModalClose = () => {
        setOpen(false);
        props.onClose();
    }

    const onMoveClick = async () => {

        try {
            if (props.fileToMove.type == FileSystemEntityType.Directory) {
                if (await checkDirectoryExists(selectedFolder?.uniqueIdentifier, props.fileToMove.name)) {
                    setShowMoveFileConflictDialog(true);

                } else {
                    moveFile();
                }
            } else {

                if (await checkFileExists(selectedFolder?.uniqueIdentifier, props.fileToMove.name)) {
                    setShowMoveFileConflictDialog(true);
                } else {
                    moveFile();
                }

            }

        } catch (error) {
            const fileType = props.fileToMove.type == FileSystemEntityType.File ? "file" : "folder";

            processServerError(error, undefined, `An error occurred while moving ${fileType}.`);
        }
    }
    const moveFile = async (conflictResponse?: ConflictMoveResponse) => {
        const messageId = loadingService.showMessage("Moving...");

        try {
            const client = getFilesClient();

            let filename = conflictResponse ? conflictResponse.newName : props.fileToMove.name;
            // add file extension if document is file
            if (conflictResponse && props.fileToMove.type == FileSystemEntityType.File) {
                const extension = getFileExtension(props.fileToMove.name);
                filename += `.${extension}`;
            }

            const model = new MoveFilePostModel({
                name: filename,
                sourceUniqueIdentifier: props.fileToMove.uniqueIdentifier,
                destinationUniqueIdentifier: selectedFolder?.uniqueIdentifier,
                fileType: props.fileToMove.type,
                conflictAction: conflictResponse?.conflictAction
            });

            await client.moveFileOrFolder(model);
            props.onSuccess();
        } catch (error) {
            throw error;
        } finally {
            loadingService.hideMessage(messageId);
        }

    }

    const checkDirectoryExists = async (parentUniqueId: string, directoryName: string) => {
        const messageId = loadingService.showMessage("Checking if folder exists in destination...");
        try {
            const client = getDirectoriesClient();

            const directory = await client.getDirectoryByName(parentUniqueId, directoryName);

            return !!directory;
        } catch (error) {
            const errorModel = error as ErrorResponseModel;
            if (errorModel?.errors?.length > 0 && errorModel.errors[0].indexOf("not found in parent") > -1) {
                return false;
            } else {
                throw error;
            }
        } finally {
            loadingService.hideMessage(messageId);
        }


    }
    const checkFileExists = async (parentUniqueId: string, filename: string) => {
        const messageId = loadingService.showMessage("Checking if file exists in destination...");
        try {
            const client = getFilesClient();
            const model = new ValidateFileNamesPostModel({
                parentDirectoryUniqueId: parentUniqueId,
                fileNames: [filename]

            });
            const fileExists = await client.validateFileNames(model);
            return fileExists && fileExists.existingFiles && fileExists.existingFiles.length > 0;
        } catch (error) {
            throw error;
        } finally {
            loadingService.hideMessage(messageId);
        }



    }



    return <>
        <Dialog open={open} onOpenChange={(event, data) => {

            setOpen(data.open);
            if (!data.open) {
                onModalClose();
            }


        }}>
            <DialogSurface style={{ width: "70vw", maxWidth: "100%" }}>

                <DialogBody style={{ maxWidth: "100%" }}>
                    <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Select folder</DialogTitle>
                    <DialogContent style={{ height: "80vh" }}>
                        <FilePicker
                            dashboardServiceUrl={settings.dashboardServiceUrl}
                            excelRunnerServiceUrl={settings.excelRunnerServiceUrl}
                            sensitivityStudiesServiceUrl={settings.sensitivityStudiesServiceUrl}
                            takeHomeExamServiceUrl={settings.takeHomeExamsServiceUrl}
                            userAccessToken={accessToken}
                            // by adding this file type we remove all files, and leave folders
                            filterFileTypes={["notfiletype"]}
                            // prevent user to move directory in itself
                            filterDirectoryUniqueIds={props.fileToMove && props.fileToMove.type == FileSystemEntityType.Directory ? [props.fileToMove.uniqueIdentifier] : undefined}
                            onSelectedFolderChanged={(folder: FileModel) => { setSelectedFolder(folder); }}
                        />

                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={isLoading} message="Moving..." appearance="primary" onClick={() => { onMoveClick(); }}>Move</LoadingButton>
                        <Button appearance="secondary" onClick={onModalClose}>Cancel</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
        {showMoveFileConflictDialog &&
            <MoveFileConflictModal
                fileToMove={props.fileToMove}
                destinationDirectoryUniqueId={selectedFolder?.uniqueIdentifier}
                onMove={(conflictData) => { setShowMoveFileConflictDialog(false); moveFile(conflictData); }}
                onDismiss={() => { setShowMoveFileConflictDialog(false); }} />}
    </>
}