import { Avatar, Slot } from "@fluentui/react-components";
import React from "react";
import "./person-display.styless.scss";

type PersonDisplayProps = {
    id: string;
    name: string;
    icon?: Slot<'span'>;
    userPrincipalName: string;
}

export const PersonDisplay: React.FC<PersonDisplayProps> = (props) => {

    return <div className="person-details" style={{ display: "flex", flexDirection: "row", alignItems: "center" }} key={`person-${props.id}`}>
        <Avatar className="persona-display-avatar" icon={props.icon} name={!props.icon ? props.name : undefined} color={!props.icon ? "colorful" : undefined} />
        <div style={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
            <span className="persona-display-name">{props.name}</span>
            <small className="persona-principal-name" >{props.userPrincipalName}</small>
        </div>
    </div>
}