import { Combobox, Field, Option } from "@fluentui/react-components";
import * as React from "react";
import { getGroupsClient } from "../../services/dashboard.service";
import { IDropdownOption } from "../../utils/shared.types";

interface IGroupPickerProps {
    userId: string;
    disabled?: boolean;
    value?: string;
    onChange?(value: string): any;
    required?: boolean;
    isSubmitted?: boolean;
    style?: React.CSSProperties;
}




const GroupPicker: React.FC<IGroupPickerProps> = (props) => {

    const [groupOptions, SetGroupOptions] = React.useState<IDropdownOption[]>([]);

    React.useEffect(() => {
        getUserOwnedGroups();
    }, []);


    const getUserOwnedGroups = async () => {
        try {
            const client = getGroupsClient();

            const groupsResp = await client.getUserOwnedGroups();
            const groupOptionsMapped = groupsResp.filter(x => !x.isDeleted).map((group) => ({ key: group.id, text: group.groupname } as IDropdownOption));
            SetGroupOptions(groupOptionsMapped);

        } catch (error) {
            console.log("An error occurred while trying to get user owned groups.", error)
        }
    }





    const { value, disabled } = props;
    const isValid = value && value.length > 0;
    const errorMessage = props.required ? "Group is required." : "";

    const selectedValue = groupOptions?.find(x => x.key == value) ?? undefined;

    return <Field
        className="input-form-field"
        validationMessage={props.isSubmitted && !isValid ? errorMessage : ""}
        validationState={props.isSubmitted && !isValid ? "error" : "none"}>
        <Combobox
            value={selectedValue?.text ?? undefined}
            placeholder="Select group"
            style={props.style}
            onOptionSelect={(ev, data) => { props.onChange(data.optionValue); }}>

            {groupOptions.map((option) => {

                return <Option key={`group-${option.key}`} value={option.key?.toString()}>
                    {option.text}
                </Option>
            })}
        </Combobox>

    </Field>

}


export default GroupPicker