import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, Input } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { getFilesClient } from "../../services/dashboard.service";
import { GLOBAL_STYLES } from "../../styles";
import { RenameDirectoryPostModel, RenameFilePostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { getFileExtension } from "../../files/file-type-icon/file-type-icon.helpers";

type RestoreFileRenameDialogProps = {
    currentName: string,
    isFolder: boolean,
    isOpened?: boolean,
    onSuccess: (newName: string) => void,
    onClose?: () => void
};

type RestoreFileModel = {
    name: string,
    extension?: string,
    nameExists?: string
};

export const RestoreFileRenameDialog: React.FC<RestoreFileRenameDialogProps> = (props) => {

    const { register, handleSubmit, watch, setError, clearErrors, setValue, formState: { errors }, reset } = useForm<RestoreFileModel>();
    const [open, setOpen] = React.useState(false);
    const [isLoading, loadingService] = useLoading();


    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    React.useEffect(() => {

        if (props.currentName) {
            const extension = getFileExtension(props.currentName);
            if (!!extension) {

                setValue("extension", extension);
            } else {

                setValue("extension", undefined);
            }


        }


    }, [props.currentName, props.isOpened]);


    const onSubmit = async (data: RestoreFileModel) => {

        if (!!props.isFolder) {
            if (props.currentName == data.name) {
                setError("name", { message: "Name needs to be changed." });
                return;
            }

            props.onSuccess(data.name);
        }
        else {
            const newName = data.name + (data.extension ? `.${data.extension}` : "");
            if (props.currentName == newName) {
                setError("name", { message: "Name needs to be changed." });
                return;
            }


            props.onSuccess(newName);
        }
    }

    const fileExtension = watch("extension");
    const onModalClose = () => {
        setOpen(false);
        reset();
        if (props.onClose)
            props.onClose();
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {
        reset();

        setOpen(data.open);
        if (data.open == false) {
            if (props.onClose)
                props.onClose();
        }
    }}>
        <DialogSurface>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogBody>
                    <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Rename and restore</DialogTitle>
                    <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                        <span>{`${props.isFolder ? "Folder" : "File"} with same name already exists in destination. Please specify new name.`}</span>
                        <Field
                            className={GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}
                            validationState={(errors.name || errors.nameExists) ? "error" : "none"}
                            validationMessage={
                                <span>
                                    {errors.name && (errors.name.message)
                                    }
                                    {errors.nameExists && <>{errors.nameExists.message}</>}
                                </span>}>
                            <Input
                                id="name"

                                contentAfter={fileExtension && <span>.{fileExtension}</span>}
                                {...register("name", { required: { value: true, message: props.isFolder ? `Folder name is required.` : `File name is required.` } })}
                                onChange={(ev, data) => { clearErrors('nameExists'); setValue("name", data.value) }}
                            />
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={isLoading} appearance="primary" type="submit" >Restore</LoadingButton>
                    </DialogActions>
                </DialogBody>
            </form>
        </DialogSurface>
    </Dialog>);
}