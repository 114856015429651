import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, TabList, Tab, DialogActions, Label, RadioGroup, Radio, Field, Input } from "@fluentui/react-components";

import { Dropdown, Option } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getAgentGroupsClient, getApplicationsClient } from "../../../services/dispatcher.service";
import { AgentGroupPostModel } from "../../../swagger-clients/dispatcher-next-api-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { useLoading } from "../../../utils/loading-indicator.component";
import { IChoiceGroupOption, IDropdownOption } from "../../../utils/shared.types";
import "./edit-agent-group-modal.styless.scss";
import { InputList } from "./input-list/input-list-component";


type EditAgentGroupModalProps = {
    agentGroupId?: number;
    isOpened: boolean;
    onClose?: () => void;
    onSuccess: (groupId?: number) => void;
}

type OptionOnSelectData = {
    optionValue: string | undefined;
    selectedOptions: string[];
};

const yesNoOptions: IChoiceGroupOption[] = [
    { key: "1", text: 'Yes' },
    { key: "0", text: 'No' }
];


export const EditAgentGroupModal: React.FC<EditAgentGroupModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [applicationOptions, setApplicationOptions] = React.useState<IDropdownOption[]>([]);
    const [isLoading, loadingService] = useLoading();
    const { register, handleSubmit, watch, setError, clearErrors, setValue, formState: { errors, isSubmitted }, reset } = useForm<AgentGroupPostModel>(

        {
            defaultValues: {
                name: "",
                disableIfHostIsBusy: true,
                saveCalculatedFlowsheets: false,
                runAllJobs: true,
                runApplications: [],
                licensingServers: []
            }
        }
    );

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const onModalClose = () => {
        setOpen(false);
        reset();
        if (props.onClose)
            props.onClose();
    }

    const onInit = async () => {
        await getApplications();
        if (props.agentGroupId) {
            await getAgentGroup();
        }
    }

    React.useEffect(() => {
        onInit();
    }, []);

    const getAgentGroup = async () => {
        loadingService.showLoading("Getting agent group...", async (hideMessage) => {
            try {
                const client = getAgentGroupsClient();
                const agentGroup = await client.getAgentGroup(props.agentGroupId);
                //console.log("Loaded agent group", agentGroup);

                setValue("disableIfHostIsBusy", agentGroup.disableIfHostIsBusy);
                setValue("id", agentGroup.id);
                setValue("licensingServers", [...agentGroup.licensingServers]);
                setValue("name", agentGroup.name);
                setValue("runAllJobs", agentGroup.runAllJobs);
                setValue("runApplications", [...agentGroup.runApplications]);
                setValue("saveCalculatedFlowsheets", agentGroup.saveCalculatedFlowsheets);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while trying to get agent group.");
            }
            finally {
                hideMessage();
            }
        });
    }

    const getApplications = async () => {
        loadingService.showLoading("Getting applications...", async (hideMessage) => {
            try {
                const client = getApplicationsClient();
                const applications = await client.getApplications();
                const applicationOptionsResp = applications.filter(x => !x.isDeleted).map(app => ({ key: app.id.toString(), text: app.name } as IDropdownOption));
                setApplicationOptions(applicationOptionsResp);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting applications.");
            }
            finally {
                hideMessage();
            }

        });
    }

    const getSelectedApplicationNames = () => {
        let names: string[] = [];
        const selectedOptions = applicationOptions.filter(x => !!runApplications?.find(y => y == x.key));
        names = selectedOptions?.map(x => x.text!) ?? [];
        return names;

    }

    const onApplicationSelected = (event: any, data: OptionOnSelectData) => {

        const alreadySelected = runApplications?.find(x => x == +data!.optionValue!);


        if (!alreadySelected) {
            setValue("runApplications", [...runApplications ?? [], +data!.optionValue!]);
        } else {
            const agentsItCanRunOnUpdated = runApplications?.filter(x => x !== (+data!.optionValue!)) ?? [];
            setValue("runApplications", [...agentsItCanRunOnUpdated]);
        }
    }

    const onSubmit = async (data: AgentGroupPostModel) => {
        console.log("onSubmit", data);
        loadingService.showLoading("Saving...", async (hideMessage) => {
            try {


                const client = getAgentGroupsClient();
                let model = {
                    name: data.name,
                    disableIfHostIsBusy: !!data.disableIfHostIsBusy,
                    runAllJobs: !!data.runAllJobs,
                    saveCalculatedFlowsheets: !!data.saveCalculatedFlowsheets,
                    licensingServers: data.licensingServers,
                    runApplications: data.runApplications
                } as AgentGroupPostModel;
                if (!data.id) {
                    let agentGroupResult = await client.createAgentGroup(model);
                    toast.success("Agent group created.");
                    setValue("id", agentGroupResult.id);
                    props.onSuccess(agentGroupResult.id);
                } else {
                    model.id = data.id;
                    await client.updateAgentGroup(model.id, model);
                    toast.success("Agent group updated.");
                    props.onSuccess();
                }

            } catch (error) {

                processServerError(error, undefined, `An error occurred while trying to ${data.id ? "updating" : "saving"} agent group.`);

            } finally {
                hideMessage();
            }
        });

    }
    const runAllJobs = watch("runAllJobs");
    const saveCalculatedFlowsheets = watch("saveCalculatedFlowsheets");
    const disableIfHostIsBusy = watch("disableIfHostIsBusy");
    const runApplications = watch("runApplications");
    const licensingServers = watch("licensingServers");
    const name = watch("name");

    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }
    }}>
        <DialogSurface style={{ maxWidth: "700px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogBody style={{ maxWidth: "inherit" }}>
                    <DialogTitle action={<Button appearance="transparent" disabled={isLoading} onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                        {props.agentGroupId ? "Edit Agent Group" : "Create Agent Group"}
                    </DialogTitle>
                    <DialogContent className="edit-agent-group">
                        <div className="input-form">
                            <div className="input-form-item">
                                <Label className="input-label">Name:</Label>
                                <Field
                                    validationState={errors.name ? "error" : "none"}
                                    validationMessage={errors.name ? "Name is required." : undefined}
                                >
                                    <Input id="name"
                                        className="input-field"
                                        value={name}
                                        {...register("name", { required: true })} />
                                </Field>

                            </div>
                            <div className="input-form-item">
                                <Label className="input-label">Run all jobs:</Label>
                                <RadioGroup
                                    className="input-field-radio"
                                    value={runAllJobs ? "1" : "0"}
                                    {...register("runAllJobs")}

                                >
                                    {yesNoOptions.map((option) => {
                                        return <Radio
                                            label={option.text}
                                            value={option.key?.toString()}
                                        />
                                    })}

                                </RadioGroup>

                            </div>

                            {!runAllJobs && <div className="input-form-item" style={{ marginBottom: "20px" }}>
                                <Label className="input-label">Run applications:</Label>
                                <Dropdown
                                    className="input-field"
                                    placeholder="Select agents"
                                    value={getSelectedApplicationNames().join(', ')}
                                    selectedOptions={runApplications.map(x => x.toString())}
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onOptionSelect={onApplicationSelected}
                                    multiselect
                                >
                                    {applicationOptions?.map((item) => {
                                        return <Option value={item.key?.toString()} >
                                            {item.text}
                                        </Option>
                                    })}

                                </Dropdown>

                            </div>
                            }
                            <div className="input-form-item">
                                <Label className="input-label">Save calculated flowsheet:</Label>
                                <RadioGroup
                                    className="input-field-radio"
                                    value={saveCalculatedFlowsheets ? "1" : "0"}
                                    {...register("saveCalculatedFlowsheets")}
                                >
                                    {yesNoOptions.map((option) => {
                                        return <Radio
                                            label={option.text}
                                            value={option.key?.toString()}
                                        />
                                    })}

                                </RadioGroup>


                            </div>
                            <div className="input-form-item">
                                <Label className="input-label">Disable if host busy:</Label>
                                <RadioGroup
                                    value={disableIfHostIsBusy ? "1" : "0"}
                                    className="input-field-radio"
                                    {...register("disableIfHostIsBusy")}
                                >
                                    {yesNoOptions.map((option) => {
                                        return <Radio
                                            label={option.text}
                                            value={option.key?.toString()}
                                        />
                                    })}

                                </RadioGroup>
                            </div>

                            <InputList
                                isSubmitted={isSubmitted}
                                label="Licensing servers:"
                                values={licensingServers}
                                onValuesChanged={(servers) => { setValue("licensingServers", servers) }} />

                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button appearance="primary" disabled={isLoading} type="submit">Save</Button>
                        <Button appearance="secondary" disabled={isLoading} onClick={onModalClose}>Cancel</Button>
                    </DialogActions>
                </DialogBody>
            </form>
        </DialogSurface>
    </Dialog>
}


