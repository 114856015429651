import React from "react";
import { BestResultCriteria, BestResultsQuery, CalculationJobResultModel, ComponentMinMaxResponseModel, ExperimentResponseModel, IOutputParameterFilter, OutputParameterFilter, SeparationMethodOption } from "../../../swagger-clients/ai-for-pfd-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { getBestResultsClient, getExperimentElasticSearchClient } from "../../../services/ai-for-pfd.service";
import { Button, Field, InfoLabel, Input, Label, Select, Table, TableBody, TableCell, TableColumnDefinition, TableHeader, TableHeaderCell, TableRow, createTableColumn } from "@fluentui/react-components";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import { GLOBAL_STYLES } from "../../../styles";
import { ArrowForward16Regular, ArrowForwardRegular, ArrowRightRegular } from "@fluentui/react-icons";
import { IPlotly, IPlotlyData } from "../../../utils/shared.types";
import { calculateAverage, getPopoverText } from "../result-graph/sequence-result-graph.component";
import Plot from 'react-plotly.js';
import { generateColorPalette } from "../../../utils/helpers/color.helper";
import { CalculationJobDetailsModal } from "../../calculation-job-details-modal/calculation-job-details-modal.component";
import { LoadingService } from "../../../utils/loading-indicator.component";
import { getSignificantFigures } from "../../../utils/helpers/significant-figures";

type ExperimenBestResultsProps = {
    experiment: ExperimentResponseModel;
    isLoading: boolean;
    loadingService: LoadingService;
    isVisible: boolean;

}

type ComponentRange = {
    name?: string;
    componentCasNr: string;
    min?: number;
    max?: number;
    factor?: number;
    isPurity?: boolean;
    unit?: string;
};

export type ExperimenBestResultsType = { getData(): void; };
export type CalculationJob = {
    sequence: number;
    flowsheetId: number;
}

export const ExperimenBestResults = React.forwardRef<ExperimenBestResultsType, ExperimenBestResultsProps>((props, ref) => {

    const [defaultComponentRanges, setDefaultComponentRanges] = React.useState<ComponentMinMaxResponseModel[]>([]);
    const [componentRanges, setComponentRanges] = React.useState<ComponentRange[]>([]);
    const [comparisonCriteria, setComparisonCriteria] = React.useState<BestResultCriteria>(BestResultCriteria.Purity);
    const [showResults, setShowResults] = React.useState<boolean>(false);
    const [showCalculationJobDetails, setShowCalculationJobDetails] = React.useState<boolean>(false);
    const [selectedCalculationJob, setSelectedCalculationJob] = React.useState<CalculationJob>();


    React.useImperativeHandle(
        ref,
        () => ({
            getData() {
                // getData();
            }
        }));

    React.useEffect(() => {
        getComponentRanges();
        getMinMaxValues();
    }, []);



    // updates component range base by results
    React.useEffect(() => {
        //  console.log("updates component range base by results", componentRanges);
        if (!!defaultComponentRanges && defaultComponentRanges.length > 0) {
            const updatedComponentRanges = componentRanges.map((item) => {
                const defaultRange = defaultComponentRanges.find(x => x.casNr == item.componentCasNr);
                if (defaultRange) {
                    return {
                        ...item,
                        min: +defaultRange.min,
                        max: +defaultRange.max,
                        name: defaultRange.name
                    };
                }
                return item;
            });

            setComponentRanges(updatedComponentRanges);
        }


    }, [defaultComponentRanges]);

    // default values
    const getComponentRanges = () => {
        let componentRangesLocal: ComponentRange[] = [
            { name: "Capex", componentCasNr: "capex", factor: 1, isPurity: false, unit: "€" },
            { name: "Opex", componentCasNr: "opex", factor: 1, isPurity: false, unit: "€/year" },
            { name: "Energy", componentCasNr: "energy", factor: 1, isPurity: false, unit: "kW" }
        ];

        if (!!props.experiment.components && props.experiment.components.length > 0) {
            props.experiment.components.filter(x => !x.isEntrainer && !x.isSolvent).forEach((item) => {
                const componentRange = {
                    name: item.name,
                    componentCasNr: item.componentCasNr,
                    isPurity: true,
                    factor: 1
                } as ComponentRange;
                componentRangesLocal.push(componentRange);
            });
        }

        setComponentRanges(componentRangesLocal);
    }


    const getMinMaxValues = async () => {
        try {
            const client = getBestResultsClient();
            const minMaxValuesResp = await client.getMinMaxOutputValues(props.experiment.id, props.experiment.experimentVersionId);
            const minMaxValueTruncated = minMaxValuesResp.map(x => (new ComponentMinMaxResponseModel({ ...x, min: truncateToFixed(Number(x.min), 3), max: ceilToFixed(Number(x.max), 3) })));

            setDefaultComponentRanges(minMaxValueTruncated ?? []);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting min-max values.");
        }
    }

    const onRangeChange = (componentCasNr: string, column: keyof (ComponentRange), value?: number) => {

        const updatedComponentRanges = componentRanges.map(item => {
            if (item.componentCasNr == componentCasNr) {

                return { ...item, [column]: value } as ComponentRange;
            }
            return item;
        });

        setComponentRanges(updatedComponentRanges);

    }
    const onResetToDefaultClick = (componentCasNr: string) => {
        const defaultValue = defaultComponentRanges.find(x => x.casNr == componentCasNr);
        if (!!defaultValue) {
            const updatedComponentRanges = componentRanges.map((item) => {
                if (item.componentCasNr == componentCasNr) {
                    return { ...item, min: defaultValue.min, max: defaultValue.max, factor: 1 };
                }
                return item;

            });
            setComponentRanges(updatedComponentRanges);
        }

    }

    const getColumns = () => {
        const separationMethod = props.experiment.separationMethod;

        let columns: TableColumnDefinition<ComponentRange>[] = [
            createTableColumn<ComponentRange>({
                columnId: "name",
                renderHeaderCell: () => <div>Name</div>,
                renderCell: (item: ComponentRange) => {

                    return <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            {item.isPurity && (separationMethod == SeparationMethodOption.Absorption ?
                                <span>Recovery in Gas Outlet: {item.name}</span> :
                                <span>Mass Fraction ({item.name})</span>)
                            }
                            {!item.isPurity && <span>{item.name}</span>}
                        </div>
                        <Button
                            appearance="transparent"
                            icon={<ArrowRightRegular />}
                            title="Reset to default"
                            onClick={() => onResetToDefaultClick(item.componentCasNr)}
                        ></Button>
                    </div>;

                }
            }),
            createTableColumn<ComponentRange>({
                columnId: "min",
                renderHeaderCell: () => <>Min</>,
                renderCell: (item: ComponentRange) => {
                    return <Field className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`} >
                        <Input
                            type="number"
                            value={item.min?.toString() ?? ""}
                            onChange={(ev, data) => { onRangeChange(item.componentCasNr, "min", !!data && !!data.value ? +data.value : undefined); }} />
                    </Field>;
                }
            }),
            createTableColumn<ComponentRange>({
                columnId: "max",
                renderHeaderCell: () => <>Max</>,
                renderCell: (item: ComponentRange) => {
                    return <Field className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`} >
                        <Input
                            type="number"
                            value={item.max?.toString() ?? ""}
                            onChange={(ev, data) => { onRangeChange(item.componentCasNr, "max", !!data && !!data.value ? +data.value : undefined) }} />
                    </Field>;
                }
            })
        ];

        if (comparisonCriteria == BestResultCriteria.Pareto) {
            columns.push(createTableColumn<ComponentRange>({
                columnId: "Factor",
                renderHeaderCell: () => <InfoLabel info={getFactorInfo()}>Factor</InfoLabel>,
                renderCell: (item: ComponentRange) => {
                    return <Field className={`${GLOBAL_STYLES.INPUT_FIELD_FULL_WIDTH}`} >
                        <Input
                            type="number"
                            value={item.factor?.toString() ?? ""}
                            onChange={(ev, data) => { onRangeChange(item.componentCasNr, "factor", !!data && !!data.value ? +data.value : 0) }} />
                    </Field>;
                }
            }));
        }

        columns.push(createTableColumn<ComponentRange>({
            columnId: "unit",
            renderHeaderCell: () => <>Unit</>,
            renderCell: (item: ComponentRange) => {
                return item.unit;
            }
        }));

        return columns;

    }

    const columns = getColumns();
    const [chartData, setChartData] = React.useState<IPlotly>({

        layout: {
            autosize: true,
            uirevision: 'true',
            config: { displaySpinner: true }
        }
    } as IPlotly);


    const onSearchClick = async () => {
        props.loadingService.showLoading("Searching...", async (hideMessage) => {
            try {
                //  console.log("componentRanges", componentRanges);

                const filterRanges = componentRanges.map((item) => {
                    return new OutputParameterFilter({
                        componentCasNr: item.componentCasNr,
                        min: item.min,
                        max: item.max,
                        factor: item.factor

                    } as IOutputParameterFilter)
                });

                const client = getExperimentElasticSearchClient();
                const query = new BestResultsQuery({
                    criteria: comparisonCriteria,
                    experimentVersionId: props.experiment.experimentVersionId,
                    filterRanges: filterRanges
                });
                const responseData = await client.getExperimentBestResults(query);
                const mappedResults = MapCalculationResultsToIPloty(comparisonCriteria, responseData, props.experiment);
                setChartData(mappedResults);
                setShowResults(true);

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting best results.");
            }
            finally {
                hideMessage();
            }
        });


    }
    const onPlotlyClick = (data: any) => {
        //console.log("onPlotlyClick", data);
        const pointText = data?.points?.[0]?.text;
        if (!!pointText) {
            const sequence = getSequence(pointText);
            const flowsheetId = getFlowsheetId(pointText);

            setSelectedCalculationJob({ sequence: sequence, flowsheetId: flowsheetId });
            setShowCalculationJobDetails(true);
        }
    }



    return <div style={{ display: props.isVisible ? "block" : "none" }}>
        <p><b><u>Define results criteria:</u></b></p>

        <Field
            style={{ width: "350px", marginTop: "var(--spacingVerticalM)" }}
            label="Sort results by:"
            orientation="horizontal"
        >
            <Select

                value={comparisonCriteria}
                style={{ width: "200px" }}
                onChange={(ev, data) => {
                    setComparisonCriteria(+data.value);
                }}>
                <option value={BestResultCriteria.Purity}>Purity</option>
                <option value={BestResultCriteria.Pareto}>Pareto</option>

            </Select>
        </Field>

        <div className='table__wrapper'>
            <Table>
                <TableHeader>
                    <TableRow>
                        {columns.map((column) => (
                            <TableHeaderCell key={column.columnId}>
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {!!componentRanges && componentRanges.length > 0 && componentRanges.map((item) => {
                        return <TableRow
                            key={`component-range-${item.componentCasNr}`}
                        >
                            {columns.map((column) => (
                                <TableCell>
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </div>
        <br />
        <Button appearance="primary" disabled={props.isLoading} onClick={() => { onSearchClick() }}>Search</Button>
        <br />
        <br />
        {showResults && <>
            <p><b><u>Best results:</u></b></p>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Plot data={chartData.data} layout={chartData.layout} onClick={onPlotlyClick} />
            </div>
            {showCalculationJobDetails && selectedCalculationJob &&
                <CalculationJobDetailsModal
                    experimentId={props.experiment.id}
                    experimentVersionId={props.experiment.experimentVersionId}
                    uniquefileId={props.experiment.flowsheetUniqueId}
                    flowsheetId={selectedCalculationJob.flowsheetId}
                    sequence={selectedCalculationJob.sequence}
                    isOpened={showCalculationJobDetails}
                    onClose={() => { setSelectedCalculationJob(undefined); setShowCalculationJobDetails(false); }}
                />
            }

        </>}

    </div>;

});



const getFactorInfo = () => {

    const name = "Mass Fraction";

    return <div>
        <p>The Weighting Factors can be used to define a multicriterial evaluation function.</p>
        <p>The evaluation function follows this structure and can include Capex, Opex and all component product mass fractions:</p>
        <p> score = f1*Capex + f2*Opex + f3*{name} (Product): Component 1 + f4*{name} (Product): Component 2 + ...</p>
        <p> min(score)</p>
    </div>
}

const MapCalculationResultsToIPloty = (
    comparisonCriteria: BestResultCriteria,
    results: CalculationJobResultModel[],
    experiment: ExperimentResponseModel
) => {

    let groupedBySequence = {};

    results.forEach(item => {
        if (!groupedBySequence[item.sequence]) {
            groupedBySequence[item.sequence] = [];
        }
        groupedBySequence[item.sequence].push(item);
    });

    let plotlyData: IPlotlyData[] = [];

    const sequenceCount = Object.keys(groupedBySequence).length;
    const colors = generateColorPalette(sequenceCount);

    let i = 0;
    for (let sequence in groupedBySequence) {
        if (groupedBySequence.hasOwnProperty(sequence)) {
            // Access the array of items for the current sequence
            let itemsForSequence = groupedBySequence[sequence];

            plotlyData.push({
                x: itemsForSequence.map((x: CalculationJobResultModel) => calculateAverage(x.results)),
                y: itemsForSequence.map(x => x.capex + x.opex),
                type: 'scatter',
                mode: 'markers',
                marker: { color: `${colors[i]}`, size: 10 },
                name: `Sequence ${sequence}`,
                hoverinfo: 'text',
                text: itemsForSequence.map(x => getPopoverText(x, experiment)), // Customize each point individually
            });
            i++;
        }
    }


    let plotly = {
        data: plotlyData,
        layout: {
            autosize: true,
            uirevision: 'true',
            config: { displaySpinner: false },
            xaxis: { title: { text: "Average purity" } },
            yaxis: { title: { text: "Capex + Opex" } },
            showlegend: true
        }
    } as IPlotly;

    return plotly;
}
const getSequence = (text: string) => {
    const sequenceRegex = /Sequence: (\d+)/;

    const sequenceMatch = text.match(sequenceRegex);

    if (sequenceMatch) {
        return Number(sequenceMatch[1]);
    }
    return undefined;
}
const getFlowsheetId = (text: string) => {

    const flowsheetIdRegex = /Flowsheet ID: (\d+)/;
    const flowsheetIdMatch = text.match(flowsheetIdRegex);


    if (flowsheetIdMatch) {
        return Number(flowsheetIdMatch[1]);
    }
    return undefined;
}

function truncateToFixed(num: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    return +(Math.floor(num * factor) / factor).toFixed(decimalPlaces);
}
function ceilToFixed(num: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    const ceiledNum = Math.ceil(num * factor) / factor;
    return +ceiledNum.toFixed(decimalPlaces);
}