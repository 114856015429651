import React from "react";
import { processServerError } from "../../../utils/helpers/error.helper";
import { getExperimentElasticSearchClient } from "../../../services/ai-for-pfd.service";
import { SequenceResultGraph, SequenceResultGraphType } from "./sequence-result-graph.component";
import { ExperimentComponentResponseModel, ExperimentResponseModel, SeparationMethodOption } from "../../../swagger-clients/ai-for-pfd-clients.service";
import { Select } from "@fluentui/react-components";

type ExperimentResultGraphsProps = {
    experimentVersionId: number;
    components: ExperimentComponentResponseModel[];
    separationMethod: SeparationMethodOption;
    experiment:ExperimentResponseModel;
}

export type ExperimentResultGraphsType = { refreshData(): void; };

export const ExperimentResultGraphs = React.forwardRef<ExperimentResultGraphsType, ExperimentResultGraphsProps>((props, ref) => {

    React.useImperativeHandle(
        ref,
        () => ({
            async refreshData() {
                await getExperimentResultSequneces();
                sequenceResultGraphRef?.current?.refreshData();
            }
        }));

    const [sequences, setSequences] = React.useState<number[]>([]);
    const [selectedSequence, setSelectedSequence] = React.useState<number>(null);

    const sequenceResultGraphRef = React.useRef<SequenceResultGraphType>();

    React.useEffect(() => {
        getExperimentResultSequneces();
    }, []);

    React.useEffect(() => {
        if (!!sequences && sequences.length > 0) {
            setSelectedSequence(sequences[0]);
        }
    }, [sequences]);

    const getExperimentResultSequneces = async () => {
        try {
            const client = getExperimentElasticSearchClient();
            const sequencesResp = await client.getExperimentResultSequenceList(props.experimentVersionId);
            setSequences(sequencesResp?.sequences ?? []);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting sequences in experiment results.");
        }
    }

    return <div className="graphs" style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div>
            <Select
                value={selectedSequence}
                placeholder="Select sequence"
                style={{ maxWidth: "300px" }}
                onChange={(ev, data) => { setSelectedSequence(+data.value!); }} >
                {sequences.map((sequence) => {
                    return <option value={sequence.toString()}>
                        Sequence {sequence}
                    </option>
                })}
            </Select>
        </div>

        {!!selectedSequence && <SequenceResultGraph
            ref={sequenceResultGraphRef}
            sequnceNumber={selectedSequence}
            experimentVersionId={props.experimentVersionId}
            components={props.components}
            separationMethod={props.separationMethod}
            experiment={props.experiment}
        />}
    </div>;
});