import { Label } from "@fluentui/react-components";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import React from "react";
import { ParameterDisplay } from "../../components/parameter-display/parameter-display.component";
import { PrismCode } from "../../components/prism-code/prism-code.component";
import { TableBodyWithLoading } from "../../components/table-body-with-loading/table-body-with-loading.component";
import { FileModel, FilterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { IDropdownOption } from "../../utils/shared.types";
import { SensitivityStudy, SensitivityStudyOutputParameter } from "../edit-sensitivity-study/edit-sensitivity-study.interfaces";

type StudyOutputParametersDetailsTabProps = {
    selectedStudy?: SensitivityStudy;
    filter: FilterResultModel;
    selectedFile: FileModel;
    isLoading: boolean;

};

const searchForDropdownOptions = [{ key: 0, text: "Min" }, { key: 1, text: "Max" }] as IDropdownOption[];

export const StudyOutputParametersDetailsTab: React.FC<StudyOutputParametersDetailsTabProps> = (props) => {


    const getParameterCell = (item: (SensitivityStudyOutputParameter | any), index: number) => {
        if (item.isFilterFormula === true) {
            return <p style={{ margin: 0 }}>Formula</p>;
        }
        const outputFilterParam = item ? item.outputFilterParameter : undefined;
        const selectedFilter = props.selectedStudy.filter;
        return <p style={{ margin: 0 }}>

            <ParameterDisplay
                value={outputFilterParam.name}
                fileUniqueId={props.selectedFile.uniqueIdentifier}
                versionNumber={props.selectedFile.currentVersionNumber.toString()}

            />
        </p>
    }

    const getAliasCell = (item: (SensitivityStudyOutputParameter | any), index: number) => {
        const outputFilterParam = item ? item.outputFilterParameter : undefined;
        return <p style={{ margin: 0 }}>{outputFilterParam ? outputFilterParam.alias : ""}</p>
    }

    const getFormulaCell = (item: (SensitivityStudyOutputParameter | any), index: number) => {
        if (item.isFilterFormula === true) {
            return <p style={{ margin: 0 }}>{item.filterFormulaValue ? item.filterFormulaValue : "-"}</p>;
        } else {
            return <p style={{ margin: 0 }}>-</p>;
        }

    }



    const outputParams = [...(props.selectedStudy?.sensitivityStudyOutputParameters ?? [])];
    const selectedSearchFor = searchForDropdownOptions.find(x => x.key == props.selectedStudy.searchFor);

    return <div>
        <div className="input-form">
            <div className="input-form-item">
                <Label style={{ fontWeight: 600, width: "30%", textAlign: "right", maxWidth: "250px", paddingRight: "22px" }}>Search for:</Label>
                <p style={{ margin: "0px" }}>{selectedSearchFor ? selectedSearchFor.text : ""}</p>

            </div>
            <div className="input-form-item">
                <Label
                    style={{
                        fontWeight: 600,
                        width: "30%",
                        textAlign: "right",
                        maxWidth: "250px",
                        paddingRight: "22px"
                    }}>Optimization function:</Label>

                <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                    <PrismCode code={props.selectedStudy.optimizationFunction || ""} language="js" />
                </div>
            </div>

            <div className="input-form-item">
                <Label
                    style={{
                        fontWeight: 600,
                        width: "30%",
                        textAlign: "right",
                        maxWidth: "250px",
                        paddingRight: "22px"

                    }}>Output constraining function:</Label>

                <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                    <PrismCode code={props.selectedStudy.outputConstraints || ""} language="js" />
                </div>
            </div>
        </div>

        <div>

            <Table>
                <TableHeader>
                    <TableRow key="table-header">
                        <TableHeaderCell key="parameter" style={{ width: "300px" }} className='table__cell--bold'>Parameter</TableHeaderCell>
                        <TableHeaderCell key="alias" className='table__cell--bold'>Alias</TableHeaderCell>
                        <TableHeaderCell key="formula" className='table__cell--bold'>Formula</TableHeaderCell>

                    </TableRow>
                </TableHeader>
                <TableBodyWithLoading isLoading={props.isLoading}
                    columnCount={3} loadingMessage="Loading..."
                    itemCount={outputParams ? outputParams.length : 0}
                    noItemsMessage="No items found.">
                    {outputParams &&
                        outputParams.map((item, index) => {
                            return <TableRow key={`input-param-${index}`}>
                                <TableCell>
                                    {getParameterCell(item, index)}
                                </TableCell>
                                <TableCell >
                                    {getAliasCell(item, index)}
                                </TableCell>
                                <TableCell>
                                    {getFormulaCell(item, index)}
                                </TableCell>


                            </TableRow>
                        })

                    }

                </TableBodyWithLoading>
            </Table>
        </div>
    </div>
}