import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React from "react";
import { settings } from "../../App";
import { getUserToken } from "../../services/dashboard.service";
import { FileModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { FilePicker } from "s365-dashboard-v2-file-picker";

type DialogFilePickerProps = {
    isOpened: boolean;
    baseFolder?: string;
    filterFileTypes?: string[];
    onClose: () => void;

    onFileSelected: (file: FileModel) => void;
}

export const DialogFilePicker: React.FC<DialogFilePickerProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [accessToken, setAccessToken] = React.useState<string>();
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();

    React.useEffect(() => { setOpen(!!props.isOpened); }, [props.isOpened]);

    React.useEffect(() => { getAccessToken(); }, []);
    const getAccessToken = async () => {

        try {
            const token = await getUserToken();
            setAccessToken(token);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting user token.");
        }
    }
    const onModalClose = () => {
        setOpen(false);
        props.onClose();
    }

    return <Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }


    }}>
        <DialogSurface style={{ width: "70vw", maxWidth: "100%" }}>

            <DialogBody style={{ maxWidth: "100%" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Select flowsheet</DialogTitle>
                <DialogContent style={{ height: "80vh" }}>
                    <FilePicker
                        dashboardServiceUrl={settings.dashboardServiceUrl}
                        excelRunnerServiceUrl={settings.excelRunnerServiceUrl}
                        sensitivityStudiesServiceUrl={settings.sensitivityStudiesServiceUrl}
                        takeHomeExamServiceUrl={settings.takeHomeExamsServiceUrl}
                        userAccessToken={accessToken}
                        baseFolder={props.baseFolder}
                        filterFileTypes={props.filterFileTypes}
                        onSelectedFileChanged={(file: FileModel) => { setSelectedFile(file); }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button appearance="primary" onClick={() => { props.onFileSelected(selectedFile); }}>Select</Button>
                    <Button appearance="secondary" onClick={onModalClose}>Cancel</Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}