import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import React, { useRef } from "react";
import { getDwsimProStartupActionsClient, getLicensingClient, getUsersClient } from "../../services/dashboard.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { CreateStartupActionPostModel, DwsimProAction, GetLicenseInfoPostModel, UserResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";

type DwsimProLicenseCheckerModalProps = {
    isOpened?: boolean;
    fileUniqueIdentifier?: string;
    filename?: string;
    onClose: () => void;
}

const trialLicenseCreatedMessages: string[] = [
    "Assigning DWSIM Pro license...",
    "Setting up the virtual licensing environment...",
    "Finalizing setup..."
];

export const DwsimProLicenseCheckerModal: React.FC<DwsimProLicenseCheckerModalProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [trialLicenseCreated, setTrialLicenseCreated] = React.useState<boolean>(false);
    const [notEligibleForTrial, setNotEligibleForTrial] = React.useState<boolean>(false);
    const [trialLicenseCreatedMessage, setTrialLicenseCreatedMessage] = React.useState<string>("");
    const [openingMessage, setOpeningMessage] = React.useState<string>();
    const trialLicenseCreatedMessageCount = React.useRef<number>(0);
    const [showInfoMessage, setShowInfoMessage] = React.useState<boolean>(false);

    const redirectAnchorRef = useRef<HTMLAnchorElement | undefined>();
    React.useEffect(() => {
        onInit();
    }, []);

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    React.useEffect(() => {
        if (trialLicenseCreated) {
            setTrialLicenseCreatedMessage(trialLicenseCreatedMessages[trialLicenseCreatedMessageCount.current++]);
            const intervalRef = window.setInterval(() => {
                if (trialLicenseCreatedMessageCount.current < trialLicenseCreatedMessages.length) {
                    setTrialLicenseCreatedMessage(trialLicenseCreatedMessages[trialLicenseCreatedMessageCount.current++]);
                } else {
                    RedirectToDwsimPro();
                    window.clearInterval(intervalRef)
                }

            }, 5000);
            return () => window.clearInterval(intervalRef);
        }

    }, [trialLicenseCreated]);




    const onInit = async () => {
        try {
            setIsLoading(true);
            const client = getLicensingClient();
            const licensingResponse = await client.getLicenseInfo(new GetLicenseInfoPostModel({ source: "dashboard" }));
            if (licensingResponse?.hasExistingLicense ?? false) {
                RedirectToDwsimPro();
            } else {
                setTrialLicenseCreated(licensingResponse?.trialLicenseCreated ?? false);
                setNotEligibleForTrial(licensingResponse?.notEligibleForTrial ?? false);
            }


        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting DWSIM Pro license status.");
            onModalClose();
        } finally {
            setIsLoading(false);
        }
    }
    const RedirectToDwsimPro = async () => {
        setOpeningMessage("Opening DWSIM Pro...");
        if (!!props.fileUniqueIdentifier) {
            await SaveDwsimProStartupAction();
        }
        redirectAnchorRef?.current?.click();

        // const newWindow = window.open("https://vm.simulate365.com", '_blank', 'noopener,noreferrer')
        // if (newWindow) newWindow.opener = null
        //  window.open("https://vm.simulate365.com", '_blank').focus();
        setShowInfoMessage(true);

    }
    const SaveDwsimProStartupAction = async () => {
        try {
            const client = getDwsimProStartupActionsClient();
            const postModel = new CreateStartupActionPostModel({
                actions: [new DwsimProAction({ action: "load-flowsheet", data: { "fileUniqueIdentifier": props.fileUniqueIdentifier, "filename": props.filename } })]
            });
            await client.addStartupAction(postModel);


        } catch (error) {
            processServerError(error, undefined, "An error occurred while saving DWSIM Pro startup action.");
        }
    }


    const onModalClose = () => {
        setOpen(false);
        if (props.onClose)
            props.onClose();
    }

    return (<Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }

    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>DWSIM Pro</DialogTitle>
                <DialogContent>
                    <a ref={redirectAnchorRef} href="https://vm.simulate365.com" target="_blank" style={{display:"none"}}></a>
                    {isLoading && <div style={{ display: "flex", justifyContent: "center", gap: "5px" }} ><Spinner size="tiny" /> Checking for DWSIM Pro license...</div>}
                    {!isLoading && notEligibleForTrial && <p>You don't have an active DWSIM Pro license. Please go to  <a href="https://simulate365.com/?option=oauthredirect&app_name=simulate365aad" target="_blank">Shop</a> to get a license.</p>}
                    {!isLoading && trialLicenseCreated && !openingMessage && <div style={{ display: "flex", justifyContent: "center", gap: "5px" }} ><Spinner size="tiny" /> {trialLicenseCreatedMessage}</div>}
                    {!!openingMessage && !showInfoMessage && <div style={{ display: "flex", justifyContent: "center", gap: "5px" }} ><Spinner size="tiny" /> {openingMessage}</div>}
                    {!!showInfoMessage && <div>
                        <p>Remote Desktop client should be opened in browsers new tab.</p>
                        <p>If the new tab doesn't open after a few seconds, click <a href="https://vm.simulate365.com" target="_blank">here</a>.</p>
                        <p>Double-click on the DWSIM Pro icon to launch a session in your browser. </p>
                    </div>}
                </DialogContent>
                <DialogActions>
                    <Button appearance="secondary" onClick={onModalClose}>Close</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);
}

