import { components } from "react-select";
import { ValidateIsNotNullOrWhitespace } from "../../sensitivity-studies/edit-sensitivity-study/edit-sensitivity-study.validation";
import { ExperimentComponentPostModel, ExperimentPostModel, ExperimentStreamCompositionPostModel, ExperimentStreamPostModel, ExperimentStreamType, SeparationMethodOption } from "../../swagger-clients/ai-for-pfd-clients.service";

export type IExperimentValidation = {
    name: IValidationField;
    propertyPackage: IValidationField;
    separationMethod: IValidationField;
    convergenceTimeout: IValidationField;
    internalTolerance: IValidationField;
    externalTolerance: IValidationField;
    maximumCost: IValidationField;
    components: IComponentValidation[];
    componentsGlobal: IValidationField;

    streamsGlobal: IValidationField;
    streams: IStreamValidation[];

    specificationsGlobal: IValidationField;
    specifications: ISpecificationValidation[];


}

const ValidField: IValidationField = {
    isValid: true,
    validationErrors: []
};

export interface IValidationField {
    isValid: boolean;
    validationErrors: string[];
}
interface IComponentValidation {
    displayName: IValidationField;
    targetValue: IValidationField;
}

export interface IStreamValidation {
    name: IValidationField;
    streamType: IValidationField;
    temperature: IValidationField;
    pressure: IValidationField;
    massFlowFlowrate: IValidationField;
    molarFlowFlowrate: IValidationField;
    compositionsGlobal: IValidationField;
    compositions: ICompositionValidation[];
}

export interface ICompositionValidation {
    componentCasNr: IValidationField;
    value: IValidationField;
    isLowBoiling: IValidationField;
    isHighBoiling: IValidationField;
}
interface ISpecificationValidation {
    specificationProperty: IValidationField;
    lowerBoundValue: IValidationField;
    upperBoundValue: IValidationField;
    defaultValue: IValidationField;
}


export const ValidateExperiment = (experiment: ExperimentPostModel): IExperimentValidation => {
    let experimentValidation = {} as IExperimentValidation;
    experimentValidation.name = ValidateName(experiment.name);
    experimentValidation.propertyPackage = ValidateFieldRequired("Property package", experiment.propertyPackage);
    experimentValidation.separationMethod = ValidateFieldRequired("Separation method", experiment.separationMethod);
    experimentValidation.convergenceTimeout = ValidateFieldRequired("Convergence timeout", experiment.convergenceTimeout);
    experimentValidation.internalTolerance = ValidateFieldRequired("Internal tolerance", experiment.internalTolerance);
    experimentValidation.externalTolerance = ValidateFieldRequired("External tolerance", experiment.externalTolerance);
    experimentValidation.maximumCost = ValidateFieldRequired("Maximum cost", experiment.maximumCost);
    experimentValidation.components = ValidateComponents(experiment);
    experimentValidation.componentsGlobal = ValidateComponentsGlobal(experiment);
    experimentValidation.streamsGlobal = ValidateStreamsGlobal(experiment);
    experimentValidation.streams = ValidateStreams(experiment);
    experimentValidation.specificationsGlobal = ValidateSpecificationsGlobal(experiment);
    experimentValidation.specifications = ValidateSpecifications(experiment);

    return experimentValidation;

}

const ValidateName = (name?: string): IValidationField => {
    let validationResult = { isValid: true, validationErrors: [] } as IValidationField;

    if (!name) {
        validationResult.isValid = false;
        validationResult.validationErrors.push("Name is required.");
        return validationResult;

    }

    validationResult.isValid = /^[a-zA-Z][\w\d]*$/.test(name);
    if (!validationResult.isValid) {
        validationResult.validationErrors.push("Name can contain letters, digits or underscore and needs to start with letter.");
    }
    return validationResult;
}
const ValidateFieldRequired = (fieldName: string, value?: number | string, prevValidationResult?: IValidationField): IValidationField => {
    let validationResult = !!prevValidationResult ? prevValidationResult : { isValid: true, validationErrors: [] } as IValidationField;

    if (value === undefined || value == "") {
        validationResult.isValid = false;
        validationResult.validationErrors.push(`${fieldName} is required.`);
    }

    return validationResult;
}

const ValidateMinMax = (fieldName: string, value?: number | string, minValue?: number, maxValue?: number, prevValidationResult?: IValidationField) => {
    let validationResult = !!prevValidationResult ? prevValidationResult : { isValid: true, validationErrors: [] } as IValidationField;
    if (Number(value) < minValue || Number(value) > maxValue) {
        validationResult.isValid = false;
        validationResult.validationErrors.push(`${fieldName} needs to be between ${minValue} and ${maxValue}.`);
    }
    return validationResult;
}

const ValidateComponentsGlobal = (experiment: ExperimentPostModel): IValidationField => {
    let validationResult = { isValid: true, validationErrors: [] } as IValidationField;
    const separationComponents = experiment.components?.filter(x => !!x.isSeparation);
    if (separationComponents?.length < 2 ?? true) {
        validationResult.isValid = false;
        validationResult.validationErrors.push("Minimum number of components is 2.");
    }
    if (separationComponents?.length > 2 && experiment.separationMethod == SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation) {
        validationResult.isValid = false;
        validationResult.validationErrors.push("Maximum number of components is 2.");
    }

    if (experiment.separationMethod == SeparationMethodOption.Absorption) {
        const solvent = experiment.components.find(x => x.isSolvent);
        if (!solvent) {
            validationResult.isValid = false;
            validationResult.validationErrors.push("Solvent is required.");
        }
    }

    if (experiment.separationMethod == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer) {
        const entrainer = experiment.components.find(x => x.isEntrainer);
        if (!entrainer) {
            validationResult.isValid = false;
            validationResult.validationErrors.push("Entrainer is required.");
        }
    }

    return validationResult;
}
const ValidateComponents = (experiment: ExperimentPostModel): IComponentValidation[] => {
    console.log("ValidateComponents", experiment.components);
    let validationResults: IComponentValidation[] = [];
    for (let i = 0; i < experiment.components.length; i++) {
        const component = experiment.components[i];
        let validationResult = {} as IComponentValidation;
        if (component.isSeparation) {
            validationResult.targetValue = ValidateFieldRequired("Value", component.targetValue);
            validationResult.targetValue = ValidateMinMax("Value", component.targetValue, 0, 1, validationResult.targetValue);
        } else {
            validationResult.targetValue = ValidField;
        }
        if (!component.name) {
            validationResult.displayName = ValidateFieldRequired("Component", component.componentCasNr);
        }


        validationResults.push(validationResult);
    }

    return validationResults;
}

const ValidateStreamsGlobal = (experiment: ExperimentPostModel): IValidationField => {
    let validationResult = { isValid: true, validationErrors: [] } as IValidationField;
    if (experiment.streams?.length == 0 ?? true) {
        validationResult.isValid = false;
        validationResult.validationErrors.push("Stream are required.");
    }

    const feedStream = experiment.streams.filter((x => x.streamType == ExperimentStreamType.Feed));
    if (!feedStream) {
        validationResult.isValid = false;
        validationResult.validationErrors.push("Feed stream is required.");
    }

    if (experiment.separationMethod == SeparationMethodOption.Absorption) {
        const solvent = experiment.streams.find(x => x.streamType == ExperimentStreamType.Solvent);
        if (!solvent) {
            validationResult.isValid = false;
            validationResult.validationErrors.push("Absorption needs to have solvent stream.");
        }
    }

    if (experiment.separationMethod == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer) {
        const entrainer = experiment.streams.find(x => x.streamType == ExperimentStreamType.Entrainer);
        if (!entrainer) {
            validationResult.isValid = false;
            validationResult.validationErrors.push("Absorption needs to have entrainer stream.");
        }
    }

    return validationResult;
}

const ValidateStreams = (experiment: ExperimentPostModel): IStreamValidation[] => {
    let validationResults: IStreamValidation[] = [];

    for (let i = 0; i < experiment.streams.length; i++) {
        const stream = experiment.streams[i];
        let validationResult = {} as IStreamValidation;
        validationResult.name = ValidateName(stream.name);
        validationResult.streamType = ValidateFieldRequired("Stream type", stream.streamType);
        validationResult.pressure = ValidateFieldRequired("Pressure", stream.pressure);
        validationResult.temperature = ValidateFieldRequired("Temperature", stream.temperature);
        validationResult.molarFlowFlowrate = ValidateMassMolarFlow(stream);
        validationResult.massFlowFlowrate = ValidateMassMolarFlow(stream);
        validationResult.compositionsGlobal = ValidateCompositionsGlobal(stream.compositions);
        validationResult.compositions = ValidateCompositions(stream.compositions, experiment.separationMethod);

        validationResults.push(validationResult);

    }

    return validationResults;
}

const ValidateMassMolarFlow = (stream: ExperimentStreamPostModel): IValidationField => {
    let validationResult = { isValid: true, validationErrors: [] } as IValidationField;
    if (!!stream.molarFlowFlowrate && !!stream.massFlowFlowrate) {
        validationResult.isValid = false;
        validationResult.validationErrors.push("Specify either molar or mass flow.");
    }
    if (!stream.molarFlowFlowrate && !stream.massFlowFlowrate) {
        validationResult.isValid = false;
        validationResult.validationErrors.push("Specify either molar or mass flow.");
    }

    return validationResult;


}

const ValidateCompositionsGlobal = (compositions: ExperimentStreamCompositionPostModel[]): IValidationField => {
    let validationResult: IValidationField = { isValid: true, validationErrors: [] };

    if (!compositions || compositions.length == 0) {
        validationResult.isValid = false;
        validationResult.validationErrors.push("At least 1 composition is required.");
    }

    return validationResult;
}

const ValidateCompositions = (compositions: ExperimentStreamCompositionPostModel[], separationMethod: SeparationMethodOption): ICompositionValidation[] => {
    let validationResults: ICompositionValidation[] = [];



    for (let i = 0; i < compositions.length; i++) {
        const composition = compositions[i];
        let validationResult = {} as ICompositionValidation;
        validationResult.componentCasNr = ValidateFieldRequired("Component CAS Nr.", composition.componentCasNr);
        validationResult.value = ValidateFieldRequired("Value", composition.value);
        validationResult.isHighBoiling = { isValid: true, validationErrors: [] };
        validationResult.isLowBoiling = { isValid: true, validationErrors: [] };

        if (separationMethod == SeparationMethodOption.HomogeneousMinimumBoilingAzeotropesWithPressureSwingDistillation
            || separationMethod == SeparationMethodOption.HeterogeneousAzeotropeDistillationWithEntrainer) {
            const highBoilingComps = compositions.filter(x => x.isHighBoiling === true);
            const lowBoilingComps = compositions.filter(x => x.isLowBoiling === true);

            if (highBoilingComps.length == 0) {
                validationResult.isHighBoiling.isValid = false;
                validationResult.isHighBoiling.validationErrors.push("One High-boiling component is required.");
            }
            if (lowBoilingComps.length == 0) {
                validationResult.isLowBoiling.isValid = false;
                validationResult.isLowBoiling.validationErrors.push("One Low-boiling component is required.");
            }
        }

        validationResults.push(validationResult);
    }

    return validationResults;
}

const ValidateSpecifications = (experiment: ExperimentPostModel): ISpecificationValidation[] => {
    let validationResults: ISpecificationValidation[] = [];
    for (let i = 0; i < experiment.specifications.length; i++) {
        const specification = experiment.specifications[i];
        let validationResult = {} as ISpecificationValidation;

        validationResult.specificationProperty = ValidateFieldRequired("Specification property", specification.specificationProperty);
        validationResult.lowerBoundValue = ValidateFieldRequired("Lower bound value", specification.lowerBoundValue);
        validationResult.upperBoundValue = ValidateFieldRequired("Upper bound value", specification.upperBoundValue);
        if (validationResult.lowerBoundValue.isValid && validationResult.upperBoundValue.isValid) {
            if (Number(specification.lowerBoundValue) > Number(specification.upperBoundValue)) {
                validationResult.upperBoundValue.isValid = false;
                validationResult.upperBoundValue.validationErrors = [`Upper bound must be equal or higher than the lower bound.`];
            }
        }
        validationResult.defaultValue = ValidateFieldRequired("Default value", specification.upperBoundValue);
        if (validationResult.defaultValue.isValid && validationResult.lowerBoundValue.isValid && validationResult.upperBoundValue.isValid) {

            if (Number(specification.defaultValue) < Number(specification.lowerBoundValue) 
                || Number(specification.defaultValue) > Number(specification.upperBoundValue)) {
                validationResult.defaultValue.isValid = false;
                validationResult.defaultValue.validationErrors = [`Default value not in range.`];
            }

        }

        validationResults.push(validationResult);
    }

    return validationResults;
}
const ValidateSpecificationsGlobal = (experiment): IValidationField => {
    let validationResult: IValidationField = { isValid: true, validationErrors: [] };

    if (!experiment.specifications || experiment.specifications.length == 0) {
        validationResult.isValid = false;
        validationResult.validationErrors.push("At least 1 specification is required.");
    }
    return validationResult;
}

export const IsValidExperimentValidation = (experimentValidation: IExperimentValidation): boolean => {

    return experimentValidation.name.isValid &&
        experimentValidation.propertyPackage.isValid &&
        experimentValidation.separationMethod.isValid &&
        experimentValidation.convergenceTimeout.isValid &&
        experimentValidation.internalTolerance.isValid &&
        experimentValidation.externalTolerance.isValid &&
        experimentValidation.maximumCost.isValid &&
        IsComponentsValid(experimentValidation) &&
        IsStreamsValid(experimentValidation) &&
        IsSpecificationsValid(experimentValidation);
}

export const IsMethodValid = (experimentValidation: IExperimentValidation) => {
    return experimentValidation.separationMethod.isValid;
}

export const IsComponentsValid = (experimentValidation: IExperimentValidation): boolean => {

    if (!experimentValidation.componentsGlobal.isValid)
        return false;

    for (let i = 0; i < experimentValidation.components.length; i++) {
        const component = experimentValidation.components[i];
        if (!component.targetValue.isValid) {
            return false;
        }
    }
    return true;

}

export const IsStreamsValid = (experimentValidation: IExperimentValidation): boolean => {

    if (!experimentValidation.streamsGlobal.isValid)
        return false;

    for (let i = 0; i < experimentValidation.streams.length; i++) {
        const stream = experimentValidation.streams[i];
        if (
            !stream.massFlowFlowrate.isValid ||
            !stream.molarFlowFlowrate.isValid ||
            !stream.name.isValid ||
            !stream.pressure.isValid ||
            !stream.streamType.isValid ||
            !stream.temperature.isValid ||
            !IsCompositionsValid(stream)
        ) {
            return false;
        }
    }
    return true;

}

export const IsCompositionsValid = (stream: IStreamValidation): boolean => {

    if (!stream.compositionsGlobal.isValid)
        return false;
    for (let i = 0; i < stream.compositions.length; i++) {
        const composition = stream.compositions[i];
        if (!composition.componentCasNr.isValid || !composition.value.isValid)
            return false;
    }
    return true;
}

export const IsSpecificationsValid = (experimentValidation: IExperimentValidation): boolean => {

    if (!experimentValidation.specificationsGlobal.isValid)
        return false;
    for (let i = 0; i < experimentValidation.specifications.length; i++) {
        const specification = experimentValidation.specifications[i];
        if (!specification.specificationProperty.isValid
            || !specification.lowerBoundValue.isValid
            || !specification.defaultValue.isValid
            || !specification.upperBoundValue.isValid) {
            return false;
        }
    }
    return true;
}
export const IsOptimizerSettingsValid = (experimentValidation: IExperimentValidation): boolean => {


    return experimentValidation.convergenceTimeout.isValid
        && experimentValidation.internalTolerance.isValid
        && experimentValidation.externalTolerance.isValid
        && experimentValidation.maximumCost.isValid;
}