import { Button, ButtonProps, Spinner } from "@fluentui/react-components";
import React from "react";

type LoadingButtonProps = ButtonProps & {
    isLoading: boolean,
    message?: string,
    /**
    * The size of the spinner.
    * @default 'small'
    */
    spinnerSize?: 'tiny' | 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large' | 'huge',

}

export const LoadingButton: React.FC<LoadingButtonProps> = (props) => {

    const [spinnerSize, setSpinnerSize] = React.useState<"tiny" | "extra-small" | "small" | "medium" | "large" | "extra-large" | "huge">("tiny");
    const [loadingMessage, setLoadingMessage] = React.useState<string>();

    React.useEffect(() => {
        if (props.spinnerSize) {
            setSpinnerSize(props.spinnerSize);
        } else {
            setSpinnerSize("tiny");
        }
    }, [props.spinnerSize]);


    React.useEffect(() => {
        setLoadingMessage(props.message);
    }, [props.message]);

    return <div style={{ display: "flex" }}>
        {props.isLoading && <Spinner size={spinnerSize} label={loadingMessage} style={{ marginLeft: "var(--spacingHorizontalS)", marginRight: "var(--spacingHorizontalS)" }}></Spinner>}
        <Button {...props} disabled={props.isLoading || props.disabled}>{props.children}</Button>
    </div>
}