import * as React from "react";
import moment from "moment";


interface IShowLocalTimeProps {
    date: Date;
    hideTime?: boolean;
    dateFormat?: string;
    timeFormat?: string;
    multiline?: boolean;
}

const ShowLocalTime: React.FunctionComponent<IShowLocalTimeProps> = (props) => {

    const dateFormat: string = props.dateFormat ? props.dateFormat : "DD.MM.YYYY";
    const timeFormat: string = props.timeFormat ? props.timeFormat : "HH:mm";
    let format = `${dateFormat}${props.hideTime ? "" : ` ${timeFormat}`}`;


    if (props.date) {
        if (!props.multiline) {
            return <span>{moment.utc(moment(props.date).format("YYYY-MM-DD HH:mm:ss")).local().format(format)}</span>;
        } else {
            return <span>{moment.utc(moment(props.date).format("YYYY-MM-DD HH:mm:ss")).local().format(dateFormat)}
                <br />{moment.utc(moment(props.date).format("YYYY-MM-DD HH:mm:ss")).local().format(timeFormat)}  </span>;
        }
    }
    return null;
}


export default ShowLocalTime;